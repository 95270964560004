import React, {useMemo} from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import OrgLogo from "../../tree_view/header/OrgLogo";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import {isAdmin, isMember} from "../../helpers/home_helpers";
import ActionsDropdown from "./ActionsDropdown";
import HamburgerDropdown from "./HamburgerDropdown";
import Tabs from "./Tabs";
import ScenarioName from "./ScenarioName";
import BenchmarkScenario from "./BenchmarkScenario";
import SettingsButton from "./SettingsButton";
import { deleteWarning } from "../../helpers/alert_helpers";
import {
  loadForecastSimulatorDBenchmarkScenario,
  removeScenario, updateViewOptions, updateScenario, updateScenarioData, resetScenarioData
} from "../../store/forecast_simulator_scenario/actions";
import { resetForecastData } from "../../store/homepage/actions";
import { setSimulationSettingsOpen } from "../../store/sidebar/actions";
import { successActions } from "../../helpers/common";
import { clearForecastCookies } from "../helpers/ag_grid_cookies";

const Header = ({
                  activeTab,
                  gridRef, forecastScenario,
                  current_org, current_user,
                  forecast_simulator_scenario,
                  removeScenario, resetForecastData, loadForecastSimulatorDBenchmarkScenario, updateViewOptions,
                  scenarioIsLoaded, openSimulationSettingsSideBar, sidebar, updateScenario, updateScenarioData,
                  resetScenarioData
                }) => {
  const history = useHistory();
  const isAdminOrMember = useMemo(() => isAdmin(current_user) || isMember(current_user), [current_user]);
  const processBackClick = () => {
    successActions(true, [closeSimulationSidebar, clearFiltersFromCookies, resetScenarioData]);
    redirectToHomeCallback(true, history);
  }
  const closeSimulationSidebar = () => openSimulationSettingsSideBar(false);
  const clearFiltersFromCookies = () => clearForecastCookies(forecastScenario)

  const deleteCallback = () =>
    deleteWarning(confirmed => {
      if (!confirmed) return;

      removeScenario(forecast_simulator_scenario.scenario_id, success => {
        successActions(success, [closeSimulationSidebar, resetScenarioData, resetForecastData, clearFiltersFromCookies]);
        redirectToHomeCallback(success, history)
      })
    }, { custom_description: 'delete this scenario permanently' })

  return <header className="decision-tree-header forecast-simulator-header ">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo current_org={current_org} processBackClick={processBackClick} />
        </div>
      </div>
      <Tabs {...{ activeTab, forecast_simulator_scenario }} />
      <ScenarioName {...{ forecast_simulator_scenario, scenarioIsLoaded }} />
      <BenchmarkScenario {...{
        gridRef, forecast_simulator_scenario, forecastScenario,
        loadForecastSimulatorDBenchmarkScenario, updateViewOptions, updateScenario, updateScenarioData
      }} />
      <div className="navbar-nav ms-auto" hidden={!isAdminOrMember}>
        <div className='d-lg-none'>
          <HamburgerDropdown {...{ processBackClick, deleteCallback, sidebar, openSimulationSettingsSideBar }} />
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          <li className="nav-item ms-2">
            <SettingsButton onClick={() => openSimulationSettingsSideBar(!sidebar.simulationSettingsSidebar)} />
          </li>
          <li className="nav-item ms-2">
            <ActionsDropdown btnId="header-actions-dropdown" {...{ deleteCallback }} />
          </li>
        </ul>
      </div>
    </nav>
  </header>;
}

const mapStateToProps = ({ sidebar, current_org, current_user, forecast_simulator_scenario }) => ({
  current_org, current_user, forecast_simulator_scenario, sidebar
});
export default connect(mapStateToProps, {
  openSimulationSettingsSideBar: setSimulationSettingsOpen,
  resetForecastData,
  removeScenario,
  loadForecastSimulatorDBenchmarkScenario,
  updateViewOptions,
  updateScenario,
  updateScenarioData,
  resetScenarioData
})(Header);
