import React from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../helpers/common";
import NAElement from "../../../template_preview/side_panel/sections/NAElement";
import { RichTextSection } from "../../../common/RichTextEditor";

const NoNotesSection = () =>
  <span className="text-muted">No notes entered.</span>

const NoteSection = ({ value, sidePanelStyle, isDecision, ...opts }) => {
  if (isBlank(value) && sidePanelStyle) return  <NAElement />;
  if (isBlank(value)) return <NoNotesSection />;

  return <RichTextSection className="me-auto overflow-auto note_paragraph" text={value || ''} showMoreLess={isDecision} {...opts}/>
}

const SubSection = ({ header, value, sidePanelStyle = false, sectionMarginBottom = 'mb-3', classParams, ...opts }) =>
  <div className={classParams}>
    {header && <h3 className={classParams ? 'm-0' : '' }>{header}</h3>}
    <div className={`${ sidePanelStyle ? 'text-gray mb-2' : sectionMarginBottom }`}>
      <NoteSection {...{value, sidePanelStyle}} {...opts} />
    </div>
  </div>

export const ObjectivePBSection = ({ playbook_notes, showNA = true, ...opts }) => {
  if (isBlank(playbook_notes.objective) && !showNA) return null;

  return <SubSection header={'Objective'} value={playbook_notes.objective} {...opts} />
}

export const HowToMakeDecisionPBSection = ({ playbook_notes, showNA = true, ...opts }) => {
  if (isBlank(playbook_notes.how_to) && !showNA) return null;

  return <SubSection header={'How to make this decision'} value={playbook_notes.how_to} {...opts} />
}

const TreeStructurePBSection = ({ playbook_notes }) =>
  <SubSection header={'Tree & structure'} value={playbook_notes.tree_structure} />

export const CollaboratorsPBSection = ({ playbook_notes, showNA = true, sidePanelStyle = false, ...opts }) => {
  if (isBlank(playbook_notes.collaborators) && !showNA) return null;

  return <SubSection header={ sidePanelStyle ? null : 'Who should be involved' }
                     value={playbook_notes.collaborators}
                     sidePanelStyle={sidePanelStyle}
                     {...opts}/>
}

export const DataSourcesPBSection = ({ playbook_notes, showNA = true, sidePanelStyle = false, hidden = false, ...opts}) => {
  if (isBlank(playbook_notes.data_sources) && !showNA || hidden) return null;

  return <SubSection header={ sidePanelStyle ? null : 'Data sources' }
                     value={playbook_notes.data_sources}
                     sidePanelStyle={sidePanelStyle}
                     {...opts}/>
}

export const CategorizationPBSection = ({ classParams, playbook_notes, showNA = true, sidePanelStyle, ...opts }) => {
  if (isBlank(playbook_notes.categorization) && !showNA) return null;

  return <SubSection header={ sidePanelStyle ? null : "Categories" }
                     value={playbook_notes.categorization}
                     sidePanelStyle
                     classParams={classParams}
                     {...opts}/>
}

const OtherNotesPBSection = ({ playbook_notes }) =>
  <SubSection header={'Other notes'} value={playbook_notes.other} sectionMarginBottom={'mb-0'} />

export const PlaybookNotesSection = ({ playbook_notes }) =>
  <div>
    <ObjectivePBSection playbook_notes={playbook_notes} />
    <HowToMakeDecisionPBSection playbook_notes={playbook_notes} />
    <TreeStructurePBSection playbook_notes={playbook_notes} />
    <CollaboratorsPBSection playbook_notes={playbook_notes} />
    <DataSourcesPBSection playbook_notes={playbook_notes} />
    <OtherNotesPBSection playbook_notes={playbook_notes} />
  </div>

const mapStateToProps = ({ playbook_notes }) => ({ playbook_notes });
export default connect(mapStateToProps)(PlaybookNotesSection);
