import {pageHeader} from "./sections/PageHeader";
import {pageFooter} from "./sections/PageFooter";
import {pageProperties} from "../index";
import {isBlank} from "../../helpers/common";
import React from "react";
import {wrapLink} from "./sections/wrappers";

const driverBlock = ({driver, children}, driversRow, depth = 0) => {
  if (isBlank(driver.question)) return null;

  driversRow.push(wrapLink({text: driver.question, anchor: `driver-${driver.slug}`, id: '', depth, driver}))
  children.forEach(d => driverBlock({...d}, driversRow, depth + 1))

  return driversRow
}

const driversBlock = (drivers) => {
  const driversRow = []
  drivers.forEach(d => driverBlock({...d}, driversRow))

  return driversRow
}

export const DriversSection = (decision, drivers) => {
  if (isBlank(drivers)) return [];

  return [
    wrapLink({text: decision.description, anchor: 'anchorForContinuousPage', id: 'anchorForDriversPage'}),
    ...driversBlock(drivers)
  ]
}

export const DriversPage = (format, decision, drivers, extraImages) => {
  return {
    ...pageProperties({format}),
    ...pageHeader({head: 'Drivers', description: decision.description, _, anchor: 'anchorForDriversPage'}),
    ...pageFooter(extraImages),
    children: [
      ...DriversSection(decision, drivers)
    ]
  }
}
