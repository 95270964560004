import React, { Fragment } from 'react';

export default () =>
  <Fragment>
    <p>Create a new decision from scratch, D-Sight analysis, or a template.</p>
    <p>
      Decisions powered by D-Sight include assessment and recommendations from AI analysis of insights, KPIs,
      and metrics. Select <b>Show D-Sight analysis</b> to view the recommendations and contributing factors. Chat with
      customer success to add, modify, or remove D-Sight decisions.
    </p>
    <p>
      Templates are an easy way to create decisions. Templates are pre-populated and structured so they are consistent
      and don’t require extensive setup each time you make the decision. Templates can be added, modified, or removed
      by admins.
    </p>
    <p>Select <b>Preview decision</b> to view the pre-populated decision information.</p>
  </Fragment>
