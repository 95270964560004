import React from "react";
import PlusIcon from "../shared/PlusIcon";

export default ({ description, onClick = () => {}, clickable = true }) =>
  <div onClick={onClick} className={`${clickable ? 'pointer' : 'cursor-default'} d-inline-flex w-100 my-auto`}>
    <div className="note_paragraph fw-bolder text-ellipsis">
      {description}
      <PlusIcon clickable={clickable} />
    </div>
  </div>
