import React, { Fragment, useCallback } from 'react';
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import Image from "react-bootstrap/Image";
import { signOut } from "../../store/homepage/actions";
import { redirectToSignInSuccessCallback } from "../../helpers/decision_helpers";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Routes } from "../../routes";
import BaseHamburgerBtn, { BaseHamburgerLink } from "../../common/BaseHamburgerBtn";
import UserAvatarImage from "../../common/UserAvatarImage";

export const ProfileMenu = ({ current_user, signOut }) => {
  const signOutHandler = useCallback(() => {
    signOut(redirectToSignInSuccessCallback)
  }, [])

  const user_name = <span className={`fw-normal ms-1 d-none d-xl-inline`}>{current_user.full_name}</span>

  return <Fragment>
    <Dropdown as={ButtonGroup} bsPrefix="dropdown profile-actions btn p-0">
      <Dropdown.Toggle variant="" bsPrefix={'dropdown-toggle p-0 after shadow-none'}>
        <UserAvatarImage user={current_user} size='md' />
        {user_name}
      </Dropdown.Toggle>
      <Dropdown.Menu bsPrefix={'dropdown-menu'} align={'end'}>
        <BaseHamburgerLink className={''} to={Routes.profile} title="Manage profile" />
        <BaseHamburgerBtn onSelect={signOutHandler} className={'user_name'} title="Log out" />
      </Dropdown.Menu>
    </Dropdown>

  </Fragment>
}
const mapStateToProps = ({ current_user }) => ({ current_user });
const mapDispatchToProps = (dispatch) => ({
  signOut: (callback) => {
    dispatch(signOut(callback))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
