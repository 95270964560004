import React from "react";
import { connect } from "react-redux";
import {currencyFormat, isBlank, isPresent, qSortArray} from "../helpers/common";
import SourceItem from "../report_wizard/data_sources/Item"
import { reportSelectedProperties, reportSelectedOpenProperties } from "../models/report";
import { RichTextSection } from "../common/RichTextEditor";

const hidePropertiesSection = (report) => isBlank(report.property_options) &&
  isBlank(report.open_property_options) && isBlank(report.cost)

const SortedDataSources = ({ report }) => {
  const filterSourcesCriteria = (source) => !source.created && !source.hidden_on_report_view;
  const data_sources = qSortArray(report.data_sources.filter(filterSourcesCriteria), true, (s) => s.created_at)

  return data_sources.map(data_source =>
    <SourceItem source={data_source}
                users={report.users}
                forceEditMode={data_source.just_created}
                hideMenu={true}
                key={`report-data-source-${data_source.slug}-${data_source.key}`} />
  )
}

const ReportDataSources = ({ report }) => <div className="col-12" hidden={isBlank(report.data_sources)}>
  <div className={`bg-white rounded p-3 pb-2`}>
    <SortedDataSources report={report} />
  </div>
</div>

const ReportNotes = ({ report }) =><div className="col-12" hidden={isBlank(report.notes)} >
  <div className={`bg-white rounded p-3`}>
    <h3>Notes</h3>
    <RichTextSection text={report.notes}/>
  </div>
</div>

const PropertyBlockRow = ({property, selectedPropertyOptionIds}) => {
  const selectedOptions = selectedPropertyOptionIds.map(id => property.options.find(h => h.id === id)).filter(h => isPresent(h))
  return <div className="d-table-row">
    <div className="d-table-cell pb-1 text-muted white-space-nowrap">
      <span>{property.name}</span>
    </div>
    <div className="d-table-cell ps-2 pb-1">
      {selectedOptions.map(option => option.description).join(', ')}
    </div>
  </div>;
}

const OpenPropertyBlockRow = ({ property, selectedPropertyOptionsData }) => {
  const optionData = selectedPropertyOptionsData.find(data => property.options.map(h => h.id).includes(data.id))
  if(isBlank(optionData)) return null;

  return <div className="d-table-row">
    <div className="d-table-cell pb-1 text-muted white-space-nowrap">
      <span>{property.name}</span>
    </div>
    <div className="d-table-cell ps-2 pb-1">
      {optionData.description}
    </div>
  </div>;
}

const CostBlockRow = ({ report }) => {
  if(isBlank(report.cost)) return null;

  return <div className="d-table-row">
    <div className="d-table-cell pb-1 text-muted white-space-nowrap">
      <span>Cost</span>
    </div>
    <div className="d-table-cell ps-2 pb-1">
      {currencyFormat(report.cost)}
    </div>
  </div>;
}

const ReportProperties = ({ report, org_report_properties }) => {
  const selectedProperties = reportSelectedProperties(report.property_options, org_report_properties)
  const selectedOpenProperties = reportSelectedOpenProperties(report.open_property_options, org_report_properties)

  return <div className={`h-100`}>
    <div className="h-inherit bg-white rounded p-3">
      <h3>Properties</h3>
      <div className={`d-table report-properties`}>
        {
          selectedOpenProperties.map(property =>
            <OpenPropertyBlockRow {...{property, selectedPropertyOptionsData: report.open_property_options }} key={`open-property-block-row-${property.slug}`}/>
          )
        }
        {
          selectedProperties.map(property =>
            <PropertyBlockRow {...{property, selectedPropertyOptionIds: report.property_options }} key={`property-block-row-${property.slug}`}/>
          )
        }
        <CostBlockRow report={report} />
      </div>
    </div>
  </div>
}

const isReportSpacing = (report) => isPresent(report.data_sources) || isPresent(report.notes) || !hidePropertiesSection(report) ? '' : 'mt-2'

const ReportDataSection = ({ report, org_report_properties }) => <div className="row mb-3 gy-3">
  <div hidden={isBlank(report.data_sources) && isBlank(report.notes)} className={`col ${isBlank(report.property_options) ? '' : 'col-xl-8'}`}>
    <div className="row g-3">
      <ReportDataSources report={report} />
      <ReportNotes report={report} />
    </div>
  </div>
  <div hidden={hidePropertiesSection(report)}
       className={`col ${isBlank(report.data_sources) && isBlank(report.notes) ? '' : 'col-xl-4'}`}>
    <ReportProperties report={report} org_report_properties={org_report_properties} />
  </div>
</div>

const mapStateToProps = ({ report, current_user, org_report_properties }) => ({
  report, current_user, org_report_properties
});
const mapDispatchToProps = (dispatch) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportDataSection);
