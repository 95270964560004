import React from 'react';
import { connect } from "react-redux";
import CategoryInput from "./CategoryInput";
import { isBlank } from "../helpers/common";

const RemoveSelectedCategory = ({ category, onRemoveCategory }) =>
  <span className="pointer btn btn-light btn-sm btn-sm-round text-danger ms-2"
        onClick={() => onRemoveCategory(category.slug)}>
    <i className="fas fa-times w-100"/>
  </span>

const CategoryRequiredFields = ({ category, required, onChangeCategoryRequired }) =>
  <div className="mt-2 lh-checkbox">
    <input type="checkbox"
           id={`required-checkbox-${category.slug}`}
           className="custom-checkbox"
           checked={required}
           name="required"
           title="Required"
           onChange={() => onChangeCategoryRequired(category.slug, !required)}/>
    <label htmlFor={`required-checkbox-${category.slug}`} className="mb-0">Required</label>
  </div>

export const CategoryFormInput = ({
                                    className, category,
                                    requireInput = false, hideRequiredTitle = false,
                                    required, addClass = '',
                                    ...opts
                                  }) =>
  isBlank(category) ? null :
    <div className={className}>
      <h4>
        {category.name}&nbsp;
        <span hidden={requireInput || !required || hideRequiredTitle} className="required-muted-text text-muted">(required)</span>
      </h4>
      <div className={`${addClass} d-flex align-items-center`}>
        <div className="w-100">
          <CategoryInput key={`category-input-${category.slug}`} {...{...opts, category}} />
        </div>
        { required && !requireInput ? null : <RemoveSelectedCategory {...{...opts, category}} />}
      </div>
      { requireInput ? <CategoryRequiredFields {...{ ...opts, category, required }} />: null }
    </div>

const SelectedCategory = ({ org_categories, slug, ...opts }) => {
  const category = org_categories.available_categories.find(c => c.slug === slug)
  return <CategoryFormInput {...{ ...opts, category }} />
}

export const CategoriesForm = ({ categorizationNotesRow = null, selectedCategories=  [], ...opts }) =>
  <>
    {categorizationNotesRow}
    {
      selectedCategories.map(({ slug, ...hash }) =>
        <SelectedCategory key={`category-input-${slug}-wrapper`} {...{ ...opts, ...hash, slug }} />
      )
    }
  </>
const mapStateToProps = ({ org_categories }) => ({ org_categories });
export default connect(mapStateToProps)(CategoriesForm);
