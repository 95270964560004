import React from "react"
import {connect} from "react-redux";
import {closeModal} from "../../store/modals/actions";
import SectionWrapper from "../../tree_view/side_panel/sections/SectionWrapper";
import DecisionHeaderSection from "../../tree_view/side_panel/sections/DecisionHeaderSection";
import RationaleSection from "../../tree_view/side_panel/sections/RationaleSection";
import ExpectedResultsSection from "../../tree_view/side_panel/sections/ExpectedResultsSection";
import BuyInSection from "../../tree_view/side_panel/sections/BuyInSection";
import BuyInDetailsSection from "../../tree_view/side_panel/sections/BuyInDetailsSection";
import DecidedBySection from "../../tree_view/side_panel/sections/DecidedBySection";
import Decision from "../../models/decision";

const DetailsSection = ({decision, current_user}) => {
  const decisionObject = new Decision(decision);
  if (decisionObject.hideDetailsSection) return null;

  return <SectionWrapper>
    <DecisionHeaderSection user={current_user} object={decisionObject} description={decision.description}
                           headerText={'What was decided'} isLegacy={true}/>
    <RationaleSection object={decision}/>
    <ExpectedResultsSection object={decision}/>
    <DecidedBySection object={decisionObject} deciding_user={decision.deciding_user}
                      decided_at={decision.decided_at}/>
    <BuyInSection isLegacy={true}/>
    <BuyInDetailsSection isLegacy={true}/>
  </SectionWrapper>
}

const mapStateToProps = ({ decision, current_user }) => ({ decision, current_user });
export default connect(mapStateToProps, {closeModal})(DetailsSection);