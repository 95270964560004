import React, { useEffect, useState } from 'react';
import ConfidenceBubble from "./ConfidenceBubble";

export const CONFIDENCE_LEVELS = [
  { className: 'not-at-all-confident', id: 'notAtAllConfident', description: 'Not at all confident', isFirst: true, isLast: false, value: '1' },
  { className: 'not-so-confident', id: 'notSoConfident', description: 'Slightly confident', isFirst: false, isLast: false, value: '2' },
  { className: 'somewhat-confident', id: 'somewhatConfident', description: 'Moderately confident', isFirst: false, isLast: false, value: '3' },
  { className: 'very-confident', id: 'veryConfident', description: 'Very confident', isFirst: false, isLast: false, value: '4' },
  { className: 'extremely-confident', id: 'extremelyConfident', description: 'Extremely confident', isFirst: false, isLast: true, value: '5' },
]

export default ({
                  confidence, disabled = false, slug,
                  addClass = 'px-3', showTitle = true,
                  onChangeConfidence = () => null,
                  setConfidence = () => null
                }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(!loading) {
      onChangeConfidence(confidence)
    }
    setLoading(false);
  }, [confidence])

  return <div className={`mb-3 ${addClass}`}>
    {showTitle && <h3 htmlFor="driverConfidence">Confidence level</h3>}
    <div className="text-muted mb-2">Rate how confident you are in the accuracy of your
      answer.
    </div>
    <div className="radio-pillbox w-100">
      <div className="radio-container d-flex justify-content-between">
        {CONFIDENCE_LEVELS.map((hash) =>
          <ConfidenceBubble confidence={confidence} disabled={disabled} slug={slug}
                            setConfidence={setConfidence} {...hash} key={`confidence-bubble-${hash.id}-${slug}`}/>
        )}
      </div>
    </div>
  </div>
}
