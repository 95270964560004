export const DecisionDueDateStyles = {
  arrowOnInputContainer: () => ({
    position: 'relative',
  }),
  arrowIcon: () => ({
    position: 'absolute',
    width: '.53rem',
    height: '.53rem',
    content: '',
    color: '#2487BF',
    border: 'solid',
    borderWidth: '0 .2em .2em 0',
    transform: 'rotateZ(45deg)',
    top: '42%',
    right: '15px',
    '&:hover': {
      color: 'hsl(0, 0%, 60%)'
    }
  }),
  input: () => ({
    textShadow: '0 0 0 #495057',
    color: 'transparent'
  })
};
