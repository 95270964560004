import { LOAD_SCENARIO_FAILED, LOAD_SCENARIO_STARTED, UPDATE_SCENARIO_DATA, RESET_SCENARIO_DATA } from "./types";

const initialState = {
  scenario_id: null,
  benchmark_id: null,
  loaded: false,
  loading: false,
  view_loading: false,
  scenario_loaded: false,
  scenario_loading: false,
  scenario_error: false,
  scenario_hint: false,
  imported_scenario_loaded: false,
  imported_scenario_loading: false,
  imported_scenario_error: false,
  imported_scenario_hint: false,
  benchmark_loading: false,
  benchmark_loaded: false,
  benchmark_error: false,
  benchmark_hint: false,
  config: {},
  scenario: {},
  config_scenarios: [],
  cmus: [],
  benchmark_scenario: {},
  error: '',
  large_scale_input: {},
  run_model: false,
  run_model_new_rows: [],
  run_model_new_rows_cmus: [],
  loading_component_message: null,
  import_values: false,
  import_values_new_rows: [],
  imported_scenario_id: null,
  ag_grid_rendered: false
};

export function forecastSimulatorScenarioReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case UPDATE_SCENARIO_DATA: {
      return {
        ...state,
        ...action.payload
      };
    }
    case LOAD_SCENARIO_STARTED: {
      return {
        ...state,
        ...action.payload,
        error: '',
        loading: true
      }
    }
    case LOAD_SCENARIO_FAILED: {
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    }
    case RESET_SCENARIO_DATA: {
      return {
        ...state,
        ...initialState
      }
    }
    default:
      return state;
  }
}
