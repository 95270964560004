import { FETCH_DECISION_ADMISSIONS_DATA, LOAD_FAILED } from "./types";

const initialState = {
  fetch: false,
  error: null,
  decision_admissions: [],
  loaded: false,
  loading: false,
};

export function decisionAdmissionsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_FAILED: {
      return {
        ...state,
        fetch: false,
        ...action.payload,
      };
    }
    case FETCH_DECISION_ADMISSIONS_DATA: {
      return {
        ...state,
        fetch: true,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
