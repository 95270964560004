import React from 'react';
import { connect } from "react-redux";
import { saveTemplateWizardStepData } from "../../../store/wizard/actions";
import TemplateDriverDetailsModal, { showTemplateDriverDetailsModal } from "../../modals/TemplateDriverDetailsModal";
import TemplateDecisionDetailsModal, {
  showTemplateDecisionDetailsModal
} from "../../modals/TemplateDecisionDetailsModal";
import { closeModal, resetModal } from "../../../store/modals/actions";
import { successActions } from "../../../helpers/common";
import { submitBuilderStepDataCallback } from "../../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import { stepWithDrivers } from "../../../helpers/wizard_helpers";
import TemplateWizardPreviewTree from "../../../tree_view/accordion_tree/TemplateWizardPreviewTree";

export const TreePreviewStep = ({
                                  tree, wizard, template, modal,
                                  stepRef,
                                  saveTemplateWizardStepData, closeModal, resetModal
                               }) => {
  const submitStep = submitBuilderStepDataCallback({
    drivers: tree.drivers,
    template: { description: template.description },
    wizard: { step_index: wizard.step_index }
  }, stepWithDrivers(wizard), saveTemplateWizardStepData)

  stepRef.current = {
    submitStep: (additional_data = {}, callback = () => {}) => {
      submitStep(additional_data, (success, wizardData) => callback(success, false, wizardData))
    }
  }
  const onClose = () => {
    successActions(true, [closeModal, resetModal])
  }
  return <div>
    <div className="modals">
      <TemplateDecisionDetailsModal shown={showTemplateDecisionDetailsModal(modal)} onClose={onClose} showTitleNotes={true} />
      <TemplateDriverDetailsModal shown={showTemplateDriverDetailsModal(modal)} onClose={onClose} ref={stepRef} />
    </div>
    <TemplateWizardPreviewTree />
  </div>
}
const mapStateToProps = ({ tree, wizard, template, modal }) => ({ tree, wizard, template, modal });
const wrapper = React.forwardRef((props, ref) => <TreePreviewStep {...props} stepRef={ref} />)
export default connect(mapStateToProps, { saveTemplateWizardStepData, closeModal, resetModal }, null, { forwardRef: true })(wrapper);
