import Recommendation from "../../../models/recommendation";
import Decision from "../../../models/decision";
import { isCollaborator, isDecider } from "../../../helpers/user_helpers";

export const decisionObjects = (decision) => {
  const decisionObject = new Decision(decision);
  const recommendation = decision.recommendation;
  const recommendationObject = new Recommendation(recommendation);

  return {
    recommendation, recommendationObject, decisionObject
  };
};
export const isNonUser = (current_user) => !isDecider(current_user) && !isCollaborator(current_user);
