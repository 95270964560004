import React, {Fragment, useEffect, useState} from "react";
import { connect } from "react-redux";
import AlertsSection from "../alerts";
import SidebarTreeNavigationTemplateSet from "../template_set_view/navigation";
import SetHeader from "./header";
import DecisionSetModal, { showDecisionSetModal } from "../homepage/modals/DecisionSetModal";
import { closeModal } from "../store/modals/actions";
import { loadTemplateSet } from "../store/template_set/actions";
import { updateTemplateSetData } from "../store/template_set/common_actions";
import TemplatesSection from "../template_set_view/TemplatesSection";
import TemplateSetDetailsPanel from "../template_set_view/side_panel/TemplateSetDetailsPanel";
import DecisionOrderPanel from "../template_set_view/side_panel/DecisionOrderPanel";
import { resetTemplate } from "../store/template/common_actions";
import { checkLoadingTemplateSet } from "../template_set_view";
import {
  setDataSourcesSidebarOpen,
  setDecisionOrderSidebarOpen,
  setDecisionSidebarOpen,
  setMembersSidebarOpen
} from "../store/sidebar/actions";
import { resetTree } from "../store/tree/common_actions";
import MembersPanel from "../template_set_view/side_panel/MembersPanel";
import CollaboratorsSection from "../template_set_view/side_panel/sections/CollaboratorsSection";
import TemplateSet from "../models/template_set";
import DataSourcesPanel from "../tree_view/side_panel/DataSourcesPanel";
import DataSourcesSection from "../template_set_view/side_panel/sections/DataSourcesSection";
import {decisionDataSources} from "../models/decision";
import {TemplateSetSourcesTypes} from "../models/data_source";

const Sidebars = (opts) =>
  <Fragment>
    <div className="sidebars">
      <TemplateSetDetailsPanel preview={true} />
      <MembersPanel>
        <CollaboratorsSection {...opts} preview={true}/>
      </MembersPanel>
      <DataSourcesPanel>
        <DataSourcesSection addClass="px-3 pb-3" preview={true} hideMenu={true} />
      </DataSourcesPanel>
      <DecisionOrderPanel addClass="left-side-panel-position tree-page" preview={true} />
    </div>
  </Fragment>

const Wrapper = ({ children, modal, closeModal, ...opts }) =>
  <Fragment>
    <AlertsSection/>
    <SetHeader />
    <div className="d-flex">
      <SidebarTreeNavigationTemplateSet isDecisionSet={true} preview={true} {...opts}/>
      {children}
    </div>
    <Sidebars {...opts}/>
    <div className="modals">
      <DecisionSetModal shown={showDecisionSetModal(modal)} onClose={closeModal} />
    </div>
  </Fragment>

const TemplateSetPreview = ({ template_set, ...opts }) => {
  checkLoadingTemplateSet({ ...opts, template_set })
  const templateSetObj = new TemplateSet(template_set);
  const membersInvitesLength = templateSetObj.collaborator_admissions.length;
  const isBlankDataSources = decisionDataSources(template_set.data_sources, [], TemplateSetSourcesTypes).length === 0;

  return <Wrapper {...opts} {...{membersInvitesLength, isBlankDataSources}}>
      <div className="w-100 wrapper-container width-fit-content">
        <div className="home container mt-3">
          <TemplatesSection preview={true}/>
        </div>
      </div>
    </Wrapper>
}

const mapStateToProps = ({ home, template_set, template, modal }) => ({ home, template_set, template, modal });
const mapDispatchToProps = (dispatch) => ({
  closeDecisionOrderSideBar: () => dispatch(setDecisionOrderSidebarOpen(false)),
  loadTemplateSet: () => dispatch(loadTemplateSet()),
  resetTemplate: () => dispatch(resetTemplate()),
  resetTree: () => dispatch(resetTree()),
  updateTemplateSetData: (data) => { dispatch(updateTemplateSetData(data)) },
  openMembersSidebar: () => { dispatch(setMembersSidebarOpen(true)) },
  openDecisionSidebar: () => { dispatch(setDecisionSidebarOpen(true)) },
  openDataSourcesSidebar: () => { dispatch(setDataSourcesSidebarOpen(true)) },
  closeModal: () => { dispatch(closeModal()) }
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateSetPreview);
