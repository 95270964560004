import {pageHeader} from "./sections/PageHeader";
import {pageFooter} from "./sections/PageFooter";
import {pageProperties} from "../index";
import {isBlank} from "../../helpers/common";
import {Bookmark, ImageRun, Paragraph} from "docx";
import {MAX_WIDTH} from "./sections/DataSources";
import {PTS_4} from "../styles/docx_styles";

export const imageRow = (data_source) =>
  new ImageRun({
    data: Buffer.from(`${data_source.base64}`, "base64"),
    transformation: {
      width: MAX_WIDTH,
      height: MAX_WIDTH * (data_source.dimensions.height/data_source.dimensions.width),
    },
  })

const dataSourceImage = (data_source) => {
  return new Paragraph({
    spacing: { before: PTS_4, after: PTS_4 }, // eq 4.5pts,
    children: [
      new Bookmark({
        id: `data-source-${data_source.slug}`,
        children: [
          imageRow(data_source)
        ],
      }),
    ],
  });
};

const ImagesSupplementPage = (data_source, extraImages, format) => {
  return {
    ...pageProperties({format}),
    ...pageHeader(
      {
        head: 'Data sources',
        description: data_source.title || data_source.filename,
        isContinuousSection: false,
        anchor: 'anchorForDetailsPage',
        id: '',
        skipFirstPage: true
      }
    ),
    ...pageFooter(extraImages),
    children: [
      dataSourceImage(data_source)
    ]
  }
}

export const ImagesSupplement = (format, data_sources, extraImages) => {
  if (isBlank(data_sources)) return [];

  return data_sources.map(data_source => ImagesSupplementPage(data_source, extraImages, format))
}
