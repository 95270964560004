import { arrayLastElement, isBlank, isPresent, qSortArray, uniqArray } from "../../helpers/common";

export const MAX_TIME_PERIODS = 12;
export const CHART_COLORS = ['#0077BB', '#33BBEE', '#009988', '#EE7733', '#CC3311', '#DDCC77', '#AAAAAA', '#AA4499', '#3355BB', '#55AAEE']

export const POINT_HEADER = '<span style="font-size:12px">{point.key}</span><hr style="margin:0.375rem 0"/>'
export const SERIES_NAME_ROW = '<td style="color:{series.color};padding:0">{series.name}:&nbsp;</td>';
export const POINT_SIGNS_ROW = '<td style="padding:0"><b>{point.y:.1f}{series.options.custom.measure}</b>{point.sigUp}{point.sigDown}</td>'
export const POINT_SAMPLES_ROW = '<td style="padding:0;color:#8a949f">&nbsp;{point.nValText}</td>'

const TIME_NODS_SORT_ORDER = {
  wave: 1,
  quarter: 2,
  year: 3,
  period: 4
}

const timeNodeSortValue = (timeNode) => TIME_NODS_SORT_ORDER[timeNode]

export const BASE_CHARTS_OPTIONS = {
  title: { text: '' },
  credits: { enabled: false },
}
export const TIME_NODE_INDEXES = {
  '-1': 'wave',
  '-2': 'quarter',
  '-3': 'year',
  '-4': 'period'
}

export const SigUp = '<span style="font-size:0.85rem;color:limegreen">&#9650;</span>'
export const SigDown = '<span style="font-size:0.85rem;color:red">&#9660;</span>'

export const isSegmentationSupported = ({ attribute_segments = [] }) => isPresent(attribute_segments)

export const isBrandFunnel = (metric) => metric.chartType === 'brandFunnel' || metric.model === 'brandFunnel';
export const isNPSChart = (metric) => metric.chartType === 'nps' || metric.model?.includes('nps_') || metric.model?.includes('nps');
export const isEmotionalConnectionAttr = (metric) => metric.chainName === 'attr_emo_conn'
export const isAttributesMetric = (metric) => metric.chainName === 'associat_attr'
export const isImpressionDifference = (metric) => metric.chainName === 'ins_impression_diff'


export const basesChartHash = (nVal) => ({
  nVal,
  nValText: nVal > 0 ? `n=${nVal}` : ''
})

export const findNodeValue = (nodes, node_index = null) => {
  if (node_index != null && nodes[node_index]) {
    return nodes[node_index];
  }
  return nodes.find(node => isPresent(node) && node > 0) || 0;
}

export const findProcessDataFor = (process_data, node_id, timeNodeIndex) =>
  process_data.find(p => p.nodes.includes(node_id) && p.node_index === timeNodeIndex)

export const surveyProcessDataIndex = (survey) => parseInt(survey?.value) - 1
export const getChatDataFor = (survey, processData) => processData?.time_periods[surveyProcessDataIndex(survey)]
export const getSignificanceChangeDataFor = (survey, processData) => processData?.significance_changes[surveyProcessDataIndex(survey)]

const getBasesDataFor = (survey, processData) => processData?.bases[surveyProcessDataIndex(survey)]

export const chartValueFor = (survey, processData, { isPercentage = false } = {}, toFixed = 1) => {
  const value = getChatDataFor(survey, processData);
  if (value == null || value === 0.0) return null;

  if (isPercentage) {
    return parseFloat((value * 100).toFixed(toFixed));
  } else {
    return parseFloat(value.toFixed(1));
  }
}
export const chartSignificanceChangeValueFor = (survey, processData) => {
  const value = getSignificanceChangeDataFor(survey, processData);
  if (value === null) return null;

  return parseFloat((value * 100).toFixed());
}
export const chartBasesValueFor = (survey, processData) => {
  const value = getBasesDataFor(survey, processData);
  if (value == null) return null;

  return parseInt(value);
}

export const hashWithSignificance = (hash, { curValue, mainValue, curSignificance, mainSignificance = 0 }) => {
  const change = curValue - mainValue
  const significanceThreshold = curSignificance > mainSignificance ? curSignificance : mainSignificance

  if (significanceThreshold === null || Math.abs(change) < significanceThreshold) return { ...hash, sigUp: '', sigDown: '' };

  hash.sigUp = change > 0 ? SigUp : ''
  hash.sigDown = change < 0 ? SigDown : ''

  return hash;
}

export const dataRowWithSignificancePerPeriod = ({ data, samples, significance_changes }, s) => {
  const index = surveyProcessDataIndex(s)
  const result = { y: data[index], ...basesChartHash(samples[index]) }
  if (index < 1 || isBlank(significance_changes)) return { ...result, sigUp: '', sigDown: '' };

  return hashWithSignificance(result, {
    curValue: data[index],
    mainValue: data[index - 1],
    curSignificance: significance_changes[index]
  })
}
export const dataRowWithSignificancePerCompetitor = (competitorData, mainBrandData, s) => {
  const index = surveyProcessDataIndex(s)
  const result = {
    y: competitorData.data[index],
    ...basesChartHash(competitorData.samples[index])
  }
  if (competitorData === mainBrandData) return { ...result, sigUp: '', sigDown: '' };

  return hashWithSignificance(result, {
    curValue: competitorData.data[index],
    mainValue: mainBrandData.data[index],
    curSignificance: competitorData.significance_changes[index],
    mainSignificance: mainBrandData.significance_changes[index]
  })
}

export const sortBrands = (mainTarget, brands) => {
  const isMainTarget = (brand) => brand.toString() === mainTarget;
  const sortValueFunction = (brand) => [isMainTarget(brand) ? 0 : 1, brand]

  return qSortArray(brands, true, sortValueFunction);
}

const sortedSurveys = (d_sight_goal) => qSortArray(d_sight_goal.surveys, true, s => parseInt(s.value))
const surveysByType = (d_sight_goal, type) => sortedSurveys(d_sight_goal).filter(s => TIME_NODE_INDEXES[s.node_index] === type)

export const analysisSurveyNodes = (d_sight_goal) => qSortArray(uniqArray(d_sight_goal.surveys.map(s => TIME_NODE_INDEXES[s.node_index])), true, timeNodeSortValue)
export const lastSurveys = (d_sight_goal, type) => surveysByType(d_sight_goal, type).slice(-1 * MAX_TIME_PERIODS)
export const getLastSurvey = (surveys) => arrayLastElement(surveys)
export const getDefaultTimeNode = (timeNodes, default_time_scale) => {
  if (isPresent(default_time_scale)) {
    return default_time_scale.name;
  }
  return isBlank(timeNodes) ? null : timeNodes[timeNodes.length - 1]
}

export const filteredProcessDataRecord = ({ demographic_id }, { selected_demographic }) =>
  (isBlank(demographic_id) && isBlank(selected_demographic)) ||
  parseInt(demographic_id) === parseInt(selected_demographic)

export const uniqDemographics = ({ process_data }) =>
  uniqArray(process_data.map(({demographic_id}) => demographic_id))

export const filteredProcessData = ({ process_data }, opts) =>
  process_data.filter(p => filteredProcessDataRecord(p, opts))

export const processDataForTypeNode = ({ process_data }, timeNode) =>
  process_data.filter(p => TIME_NODE_INDEXES[p.node_index.toString()] === timeNode)

export const filterSurveysWithChartData = (surveys, chartData, timeNode) =>
  surveys.filter(s => getChatDataFor(s, processDataForTypeNode(chartData, timeNode)[0]) !== null)
