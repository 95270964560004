import React from 'react';
import Modal from "react-bootstrap/Modal";
import DoneButton, { DoneBtnW100 } from "./DoneButton";
import CloseIcon from "./CloseIcon";

export const ModalBodyWithClose = ({ bsPrefix = '', header, onClose, children, headerChildren = null }) =>
  <Modal.Body bsPrefix={`modal-body ${bsPrefix}`}>
    <div className="d-flex w-100">
      <h2 className="me-auto">{header}</h2>
      {headerChildren}
      <CloseIcon onClose={onClose} />
    </div>
    {children}
  </Modal.Body>

export const ModalDoneFooter = ({ onClose, ...opts }) =>
  <Modal.Footer bsPrefix={`modal-footer border-top-0 p-3`}>
    <DoneButton onClose={onClose} {...opts} />
  </Modal.Footer>

const ModalDoneFooterP = ({ onClose, paddingClass = '', ...opts }) =>
  <Modal.Footer className={`modal-footer border-top-0 ${paddingClass}`}>
    <DoneBtnW100 onClose={onClose} {...opts} />
  </Modal.Footer>

export const ModalDoneFooterP0 = (props) => <ModalDoneFooterP paddingClass="pt-0" {...props} />
export const ModalDoneFooterP3 = (props) => <ModalDoneFooterP paddingClass="p-3" {...props} />
