import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {removeReport, resetReportData} from "../../store/report/actions";
import { resetSidebars } from "../../store/sidebar/actions";
import BaseHamburgerBtn, {BaseDropdownBtn, DeleteItemElement} from "../../common/BaseHamburgerBtn";
import { createReportWizardData } from "../../store/wizard/actions";
import { forceReloadHomepageReportInsights } from "../../store/homepage/actions";
import {deleteReportCallback, editCallback} from "./ActionsDropdown";
import {HamburgerMenuIconBtn} from "../../common/dropdowns";

export const HamburgerDropdown = ({
                                    processBackClick, report, removeReport, resetSidebars,
                                    resetReport, createReportWizard, forceReloadHomepageReportInsights
                                  }) => {
  const history = useHistory();

  const deleteHandler = useCallback(
    deleteReportCallback({
      removeReport, history, slug: report.slug,
      actions: [resetSidebars, resetReport, forceReloadHomepageReportInsights]
    }), [report.slug] )

  const editHandler = editCallback( {
    history, slug: report.slug, createReportWizard,
    actions: [resetSidebars]
  })

  return <HamburgerMenuIconBtn>
    <BaseHamburgerBtn onSelect={editHandler} title="Edit Report" />
    <BaseDropdownBtn onSelect={deleteHandler}>
      <DeleteItemElement name="Report" />
    </BaseDropdownBtn>
    <BaseHamburgerBtn onSelect={processBackClick} title="Go to homepage" />
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({ report }) => ({ report });
const mapDispatchToProps = (dispatch) => ({
  removeReport: (slug, callback) => dispatch(removeReport(slug, callback)),
  resetSidebars: () => dispatch(resetSidebars()),
  resetReport: () => dispatch(resetReportData()),
  createReportWizard: (callback) => dispatch(createReportWizardData(callback)),
  forceReloadHomepageReportInsights: () => dispatch(forceReloadHomepageReportInsights())
});
export default connect(mapStateToProps, mapDispatchToProps)(HamburgerDropdown);