import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import { deleteDriverWarning } from "../helpers/alert_helpers";
import { attachEventEffect } from "../helpers/html_events_helpers";

export default ({ treeNode, deleteCallback }) => {
  if (treeNode.isDecision) return null;

  const ref = useRef();
  const onRemove = () => deleteDriverWarning(deleteCallback)
  attachEventEffect(ref, onRemove)

  return <Button className="btn btn-light btn-sm btn-sm-round bg-light-gray text-danger" ref={ref}>
    <i className="fa fa-solid fa-times bg-light-gray text-danger fa-2x w-100"/>
  </Button>
}
