import React, {useEffect, useMemo, useState} from 'react';
import Decision from "../../../models/decision";
import {connect} from "react-redux";
import {DriverHeader} from "./ChoicesDriverInput";
import {
  onChangeDriverValue,
  onDriverDoneCallback,
} from "./DriverRichTextInput";
import NumericInput from "./NumericInput";
import {isPresent} from "../../../helpers/common";
import Driver from "../../../models/driver";
import {CheckForm} from "../../../tree_wizard/helpers/DecisionFlowRow";

const MIN_NUMBERING_CHILDREN = 1;

const SumSubDriversCheckbox = ({ driverData, updateDriver }) => {
  const { driver } = driverData;
  const { slug, auto_calculate_sum } = driver;
  const [sumAutoCalculated, setSumAutoCalculated] = useState(auto_calculate_sum);

  const filteredNumberingChildren = driverData.children.filter(child => {
    const driverObj = new Driver(child.driver);
    return isPresent(child.driver.answer) && isPresent(child.driver.question) && driverObj.isNumbering && !driverObj.isDraft;
  });

  const isPresentAlienDrivers = driverData.children.some(child =>
    isPresent(child.driver.question) && (!new Driver(child.driver).isNumbering || child.driver.number_format !== driver.number_format)
  );

  if (isPresentAlienDrivers || filteredNumberingChildren.length < MIN_NUMBERING_CHILDREN) return null;

  const sumSubDrivers = (e) => {
    const isChecked = e.target.checked;
    const callback = (success) => success && setSumAutoCalculated(isChecked);

    updateDriver({ slug, auto_calculate_sum: isChecked }, callback);
  };

  return (
    <CheckForm
      className={'mt-2'}
      checked={sumAutoCalculated}
      type={'checkbox'}
      label={'Calculate response total'}
      onClick={sumSubDrivers}
    />
  );
};

const NumberInputComponent = ({driverData, decision, drivers, innerRef, updateDriver, autoFocus = false, setAutoFocus}) => {
  const decisionObject = useMemo(() => new Decision(decision, drivers), [decision]);
  if (!decisionObject.isRateAndCompareDriverChoicesHidden) return null;

  const {driver} = driverData;
  const [answer, setAnswer] = useState(driver.answer || '');
  const [prevAnswer, setPrevAnswer] = useState(answer);

  const onChangeValue = (callback) => onChangeDriverValue(callback, prevAnswer, answer, updateDriver, () => {}, driverData)
  onDriverDoneCallback(innerRef, onChangeValue)

  const onFocusField = () => {
    setAutoFocus(true)
    setPrevAnswer(answer)
  }

  useEffect(() => {
    const focusElement = document.getElementById(`driver-numbering-${driver.slug}`);
    autoFocus && focusElement && focusElement.focus();
    setAutoFocus(false)
  }, []);

  return <div className="mb-3 px-3" onFocus={onFocusField}>
    <DriverHeader driver={driver} />
    <NumericInput
      driver={driver}
      onBlur={onChangeValue}
      value={answer}
      setValue={setAnswer}
    />
    <SumSubDriversCheckbox  driverData={driverData} updateDriver={updateDriver} />
  </div>
}

const mapStateToProps = ({ decision, tree }) => ({ decision, drivers: tree.drivers })
const wrapper = React.forwardRef((props, ref) => <NumberInputComponent {...props} innerRef={ref} />)

export default connect(mapStateToProps, null, null, { forwardRef: true })(wrapper);
