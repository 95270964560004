import React from 'react';
import { connect } from "react-redux";
import {
  setChatGptDecisionSummarizeSidebarOpen,
  setChatGptDriverSummarizeSidebarOpen,
  setChatGptRecommendationSummarizeSidebarOpen
} from "../../../store/sidebar/actions";
import Button from "react-bootstrap/Button";
import {isBlank} from "../../../helpers/common";
import Decision from "../../../models/decision";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import {answeredChildrenDrivers, answeredChildrenRatingDrivers} from "../../../helpers/gpt_helpers";

const ChatGptDriverSummarizeButton = ({ sidebar, setChatGptDriverSummarizeSidebarOpen, driverData, decision,
                                        isRoot = false, type, setChatGptRecommendationSummarizeSidebarOpen,
                                        setChatGptDecisionSummarizeSidebarOpen, current_org, addClass = ''}) => {
  if (isBlank(driverData) || !current_org.allow_gpt_suggestions) return null;

  const decisionObject = new Decision(decision, sidebar.drivers);
  const isRated = !decisionObject.isRateAndCompareDriverChoicesHidden
  if (isDSightDecision(decision)) return null;

  if (!isRated && answeredChildrenDrivers(driverData, isRoot).length < 2) return null;
  if (isRated && answeredChildrenRatingDrivers(driverData, isRoot).length < 2) return null;

  const openDriverSummarizePanel = () => {
    if (type === 'decision') setChatGptDecisionSummarizeSidebarOpen(true)
    if (type === 'recommendation') setChatGptRecommendationSummarizeSidebarOpen(true)
    if (type === 'driver') setChatGptDriverSummarizeSidebarOpen(true, sidebar.driverSlug)
  }

  return <div className={`${addClass}`}>
    <Button onClick={openDriverSummarizePanel} className="btn-secondary btn w-100 h-auto mb-2" style={{minHeight: 48}}>
      Summarize contributing responses with CloverpopAI
    </Button>
  </div>
}
const mapStateToProps = ({ sidebar, decision, current_org }) => ({ sidebar, decision, current_org })

export default connect(mapStateToProps, {
  setChatGptDriverSummarizeSidebarOpen, setChatGptDecisionSummarizeSidebarOpen, setChatGptRecommendationSummarizeSidebarOpen
})(ChatGptDriverSummarizeButton);
