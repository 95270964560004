import { AnalysisMetric } from "../../../../../models/d_sight/Analysis";
import {
  chartBasesValueFor,
  chartValueFor,
  filteredProcessData,
  findProcessDataFor,
  uniqDemographics
} from "../../../../../models/d_sight/metric_chart_helpers";
import { sortBrandsBySubMetrics } from "../brand/helpers";

const passiveMetric = new AnalysisMetric({
  chain_data: {
    display_name: 'Passive',
    has_chart_data: true,
    layer: "0.0",
    measure: "%",
    model: "mod",
    name: "promoRes",
    node_index: 0,
    output: true,
    view: "view"
  }
})

export const NPS_COLORS = ['#009988', '#AAAAAA', '#CC3311'];

export const prepareNpsMetricsData = ({
                                        d_sight_goal, chartData, metric, selectedSurvey, addAvailableOptions
                                      }) => {
  const trendMetricsData = metric.trendMetrics.map(metric => chartData.metrics.find(m => m.metric_id === metric.id))
  const sortedNodeNames = sortBrandsBySubMetrics(d_sight_goal, trendMetricsData)
  const subMetrics = trendMetricsData.map(({ metric_id, process_data }) => {
    const childMetric = metric.metrics.find(m => m.id === metric_id)
    addAvailableOptions(uniqDemographics({ process_data }))
    const filtered_process_data = filteredProcessData({ process_data }, d_sight_goal)
    const targetProcessData = findProcessDataFor(filtered_process_data, parseInt(d_sight_goal.target.name), selectedSurvey.node_index)
    return {
      metric: childMetric,
      process_data: sortedNodeNames.map(node => findProcessDataFor(filtered_process_data, node, selectedSurvey.node_index)),
      mainTargetValue: chartValueFor(selectedSurvey, targetProcessData, childMetric),
      mainTargetBasesValue: chartBasesValueFor(selectedSurvey, targetProcessData)
    }
  })
  const npsMetrics = [
    ...subMetrics.slice(0, 1),
    {
      metric: passiveMetric,
      process_data: subMetrics[0].process_data.map((hash, index) => ({
        ...hash,
        significance_changes: hash.significance_changes.map(() => null),
        time_periods: hash.time_periods.map((_tm, sub_index) =>
          (1 - subMetrics.reduce((result, m) => (
            result + m.process_data[index].time_periods[sub_index]
          ), 0.0))
        )
      })),
      mainTargetValue: subMetrics[0].mainTargetValue,
      mainTargetBasesValue: subMetrics[0].mainTargetBasesValue
    },
    ...subMetrics.slice(1)
  ]

  return {
    sortedNodeNames, subMetrics, npsMetrics
  }
}
