import ShareData from "../../../models/share_data";
import {Typeahead} from "react-bootstrap-typeahead";
import React from "react";
import {connect} from "react-redux";
import {
  DEFAULT_TYPEHEAD_ATTRIBUTES, filteredOrgItems, findItemByEmailCallback,
  renderContactItem, renderTag
} from "../../../tree_view/modals/helpers/share_helpers";
import {isBlank} from "../../../helpers/common";
import {filterNewShared, onChangeSharedCallback, onShareKeyDown} from "../../../tree_view/modals/ShareDropdownModal";

const AdmissionShareInput = ({decisionAdmissionsSelection, setDecisionAdmissionsSelection, share_data}) => {
  const shareObject = new ShareData(share_data);
  const ref = React.createRef()

  const findItemByEmail = (email) => findItemByEmailCallback(shareObject)(email) || { label: email, uniq_field: email, admittable_type: 'NewOrgUser', email }
  const onChangeShared = (selected) => onChangeSharedCallback(selected, findItemByEmail, setDecisionAdmissionsSelection)
  const addNewOrgShare = () => {
    const newShare = filterNewShared([...decisionAdmissionsSelection, shareObject.entireShareOrg])
    setDecisionAdmissionsSelection(newShare)
  }
  const onKeyDown = (e) => onShareKeyDown(e, ref, findItemByEmail, decisionAdmissionsSelection, setDecisionAdmissionsSelection)

  return <div className="w-100 mb-3">
    <h3 className='mb-1'>Who else can see this decision?</h3>
    <div className="mb-2 text-gray" hidden={isBlank(shareObject.entireShareOrg)}>
      <a className="pointer" onClick={addNewOrgShare}>Click here</a> to add “{shareObject.entireShareOrg.label}”.
    </div>
    <Typeahead
      {...DEFAULT_TYPEHEAD_ATTRIBUTES}
      allowNew={true}
      ref={ref}
      id={"decision_admissions"}
      selected={decisionAdmissionsSelection}
      onChange={onChangeShared}
      onKeyDown={onKeyDown}
      options={filteredOrgItems(shareObject, decisionAdmissionsSelection)}
      renderMenuItemChildren={renderContactItem}
      renderToken={renderTag}
    />
  </div>
}

const mapStateToProps = ({share_data}) => ({share_data});
export default connect(mapStateToProps, {})(AdmissionShareInput);