import React from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { isBlank } from "../../helpers/common";
import { ModalDoneFooter } from "../../common/modals";
import { RichTextSection } from "../../common/RichTextEditor";
import ModalHeaderTitle from "../../common/ModalHeaderTitle";

export const showPreviewDecisionDetailsModal = (modal) => modal.type === "PreviewDecisionDetailsModal" && modal.shown

const BaseNoteSection = ({ playbook_notes, header, field }) =>
  isBlank(playbook_notes[field]) ? null :
    <div>
      <h3>{header}</h3>
      <RichTextSection className="note_paragraph lh-sm" text={playbook_notes[field]} />
    </div>

export const TitleNotesSection = (props) => <BaseNoteSection {...props} header="Title notes" field="decision_title" />
export const HowToNotesSection = (props) => <BaseNoteSection {...props} header="How to make this decision" field="how_to" />

const PreviewDecisionDetailsModal = ({
                                       template, playbook_notes,
                                       shown, onClose
                                    }) =>
  <Modal size="lg" backdrop="static" show={shown} onHide={onClose}>
    <Modal.Body>
      <ModalHeaderTitle {...{ onClose, title: 'Decision' }} />
      <div className="h3 mb-3">{template.description}</div>
      <TitleNotesSection playbook_notes={playbook_notes} />
      <div className="mt-3">
        <HowToNotesSection playbook_notes={playbook_notes} />
      </div>
    </Modal.Body>
    <ModalDoneFooter onClose={onClose} />
  </Modal>

const mapStateToProps = ({ modal, playbook_notes }) => ({
  modal, template: modal.decision, playbook_notes
})
export default connect(mapStateToProps)(PreviewDecisionDetailsModal);
