import { RESET_REPORT_DATA, UPDATE_REPORT_DATA, UPDATE_REPORT_FAILED } from "./types";
import { BASE_SECTION } from "../homepage/reducers"

const initialState = {
  loaded: false,
  loading: false,
  name: '',
  notes: '',
  slug: '',
  completed_at: '',
  created_at: '',
  decisions_count: 0,
  data_sources: [],
  uploading_sources: [],
  users: [],
  cost: '',
  property_options: [],
  open_property_options: [],
  decisions: BASE_SECTION,
  statistics: [],
  current_page: 1,
  total_pages: 1,
  total_count: 0,
  sort_order: 'most_recent_activity',
  error: ''
};

export function reportReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case UPDATE_REPORT_DATA: {
      return {
        ...state,
        ...action.payload
      }
    }
    case UPDATE_REPORT_FAILED: {
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    }
    case RESET_REPORT_DATA: {
      return {
        ...state,
        ...initialState
      }
    }
    default:
      return state;
  }
}
