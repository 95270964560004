import React, {useState} from 'react';
import { connect } from "react-redux";
import {isBlank, isPresent} from "../../../helpers/common";
import {
  createRecommendationConsideredChoice,
  destroyChoice,
  updateRecommendationChoice
} from "../../../store/sidebar/actions";
import Recommendation from "../../../models/recommendation";
import {addNewChoiceCallback, defaultChoices, onChangeChoiceCallback} from "../../modals/entry_modal/helpers";
import ConsideredChoiceSection from "../sections/ConsideredChoiceSection";
import {deleteConsideredChoiceCallback} from "../decision_input/OtherConsideredChoicesInput";

export const onSaveConsideredChoiceCallback = (description, slug, newChoice, choices, prevConsideredChoices, setPrevConsideredChoices,
                                               setChoices, createConsideredChoice, updateChoice, setSubmitted, mouseOverRemoveSlug) => {
  if(isBlank(description) || newChoice) return null;

  const consideredChoice = prevConsideredChoices.find(consideredChoice => consideredChoice.slug === slug)
  if(isPresent(consideredChoice) && !slug.includes('new-')) {
    if(consideredChoice.description !== description) {
      updateChoice(consideredChoice.slug, {choice: {description: description, ignore_line_breaks: true }});
      setPrevConsideredChoices(choices)
    }
  } else {
    setSubmitted(true)
    const callback = (success, consideredChoices) => {
      if(success) {
        if (prevConsideredChoices.length === 0) {
          setChoices([...consideredChoices, ...defaultChoices().slice(0, 2)]);
        } else if (prevConsideredChoices.length === 1) {
          setChoices([...consideredChoices, defaultChoices()[0]]);
        } else {
          setChoices(consideredChoices);
        }
        setPrevConsideredChoices(consideredChoices)
        setSubmitted(false)
      }
    }
    if (mouseOverRemoveSlug.includes('new-')) {
      setSubmitted(false)
    } else {
      createConsideredChoice({choice: {description: description}}, callback);
    }
  }
}

const OtherConsideredChoicesInput = ({ decision, createRecommendationConsideredChoice,
                                       destroyChoice, updateRecommendationChoice
                                     }) => {
  if (isBlank(decision) || isBlank(decision.recommendation) || !new Recommendation(decision.recommendation).isOpenEnded) return null;

  const consideredChoices = decision.recommendation.considered_choices
  const [prevConsideredChoices, setPrevConsideredChoices] = useState(consideredChoices)
  const [choices, setChoices] = useState(isPresent(prevConsideredChoices) ? prevConsideredChoices : defaultChoices())
  const [newChoice, setNewChoice] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const onChangeChoice = onChangeChoiceCallback(choices, setChoices);
  const addNewChoice = addNewChoiceCallback(setNewChoice, choices, setChoices)
  const [mouseOverRemoveSlug, setMouseOverRemoveSlug] = useState('');

  const onSaveConsideredChoice = (description, slug) =>
    onSaveConsideredChoiceCallback(description, slug, newChoice, choices, prevConsideredChoices, setPrevConsideredChoices,
                                   setChoices, createRecommendationConsideredChoice, updateRecommendationChoice, setSubmitted, mouseOverRemoveSlug)

  const deleteConsideredChoice = (slug) => deleteConsideredChoiceCallback(slug, consideredChoices, destroyChoice, setChoices, prevConsideredChoices)

  return <ConsideredChoiceSection {...{choices, newChoice, onChangeChoice, setNewChoice, onSaveConsideredChoice,
                                       deleteConsideredChoice, addNewChoice, submitted, prevConsideredChoices, setMouseOverRemoveSlug }} />
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { createRecommendationConsideredChoice, destroyChoice, updateRecommendationChoice })(OtherConsideredChoicesInput);
