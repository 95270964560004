import React, {useMemo} from "react";
import {connect} from "react-redux";
import { HowToMakeDecisionPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";
import AssignmentSection from "./../sections/AssignmentSection";
import {isBlank, isPresent} from "../../../helpers/common";
import Decision from "../../../models/decision";
import ChatGptDriverSummarize from "../driver_input/ChatGptDriverSummarize";
import {answeredChildrenDrivers, answeredChildrenRatingDrivers} from "../../../helpers/gpt_helpers";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import SummarizeFilesButton, {
  filterAndSortDataSources,
  getFilteredDataSources
} from "../driver_input/SummarizeFilesButton";

export const isShownGptButtons = (decision, current_org, decisionObj, drivers, dataSources) => {
  if (isDSightDecision(decision) || !current_org.allow_gpt_suggestions) return false;

  const hasDataSources = dataSources.length > 0;
  const isRated = !decisionObj.isRateAndCompareDriverChoicesHidden
  const children = isRated ?
    answeredChildrenRatingDrivers(drivers, true):
    answeredChildrenDrivers(drivers, true);

  return children.length > 1 || hasDataSources;
};


const AssignedToSection = ({sidebar, playbook_notes, tree, current_org, dataSources}) => {
  if(isBlank(sidebar.decision)) return null;

  const decisionObj = new Decision(sidebar.decision, sidebar.drivers);
  const assignedUser = useMemo(() => decisionObj.recommendationAssignedUser, [sidebar.decision])
  const assignedCollaboratorEmail = useMemo(() => decisionObj.recommendation.assignedCollaboratorUser, [sidebar.decision])
  const shownGptButtons = () => isShownGptButtons(sidebar.decision, current_org, decisionObj, tree.drivers, dataSources)

  return <div className={`px-3 ${isPresent(playbook_notes.how_to) || isPresent(assignedUser) || isPresent(assignedCollaboratorEmail) || shownGptButtons() ? 'border-bottom mb-3' : ''}`}>
    <AssignmentSection assignedUser={assignedUser} assignedCollaboratorEmail={assignedCollaboratorEmail} modalType='RecommendationAssignModal'/>
    <HowToMakeDecisionPBSection playbook_notes={playbook_notes} showNA={false} isDecision={true}/>
    <div className='mb-2'>
      <ChatGptDriverSummarize driverData={tree.drivers} isRoot={true} type={'recommendation'} />
      <SummarizeFilesButton driverData={tree.drivers} type={'recommendation'} dataSources={dataSources} />
    </div>
  </div>
}

const mapStateToProps = ({ sidebar, playbook_notes, tree, current_org }) => {
  const allDataSources = (tree.data_sources) || [];
  const allowedDataSources = filterAndSortDataSources(allDataSources);
  const dataSources = getFilteredDataSources(allowedDataSources, 'hide_in_recommendation_panel')

  return {
    sidebar, playbook_notes, tree, current_org, dataSources
  };
};
export default connect(mapStateToProps, {})(AssignedToSection);
