import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { updateRecordDecision } from "../../../store/sidebar/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import CurrencyInput from "../../../common/CurrencyInput";
import { InputCurrencyHeader } from "../../../tree_wizard/steps_wizard/steps/HistoricalDecisionStep"
import AccordionWrapper from "../../../common/AccordionWrapper";

const ExpectedInvestmentInput = ({ decision, updateRecordDecision }) => {
  if (isBlank(decision)) return null;

  const [expectedInvestment, setExpectedInvestment] = useState(decision.expected_investment);
  useEffect(() => {
    if (expectedInvestment !== decision.expected_investment) {
      setExpectedInvestment(decision.expected_investment)
    }
  },[decision.expected_investment])

  const onChangeExpectedInvestment = (_e) => { updateRecordDecision({ expected_investment: expectedInvestment }); };

  const accordionObject = {
    header: <InputCurrencyHeader headerText={'Expected investment or cost'}/>,
    body: <CurrencyInput key={'decision-currency-input-investment'}
                         value={expectedInvestment || ''}
                         onChange={(value) => setExpectedInvestment(value || '')}
                         placeholder={'Enter a dollar amount'}
                         name={'expected-investment'}
                         id={'expected-investment'} />,
    defaultExpand: isPresent(decision.expected_investment)
  };

  return <div onBlur={onChangeExpectedInvestment}>
    <AccordionWrapper accordionObject={accordionObject} className='px-3 mb-3' />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecordDecision })(ExpectedInvestmentInput);
