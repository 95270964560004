import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import OrgLogo from "../../../../../tree_view/header/OrgLogo";
import { HamburgerMenuIconBtn } from "../../../../../common/dropdowns";
import BaseHamburgerBtn from "../../../../../common/BaseHamburgerBtn";
import { useParams, useHistory } from "react-router-dom";
import { loadDSightData } from "../../../../../store/homepage/actions";
import { redirectToHomeCallback } from "../../../../../helpers/decision_helpers";

const Header = ({
  // redux
  home,
  current_org,
  loadDSightData
}) => {
  const history = useHistory();
  const { goal_id: goalId } = useParams();
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [label, setLabel] = useState('');

  useEffect(() => {
    loadDSightData({goal_id: goalId});
  }, [])

  useEffect(() => {
    if(home.dsight.loaded && !home.dsight.data.ask_cloverpop_enabled) {
      redirectToHomeCallback(true, history, 'dsight');
      return;
    }

    const goal =  home.dsight.data.goals?.find((g) => {
      return g.id.toString() === goalId
    });
    const l = home.dsight.data.products?.find((p) => p.value.toString() === goalId)?.label || ''
    setSelectedGoal(goal);
    setLabel(l);
  }, [home.dsight.loading, home.dsight.loaded, goalId]);

  const handleBackClick = () => {
    redirectToHomeCallback(true, history, 'dsight');
  }

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar p-3 m-0">
      <div className="d-flex align-items-center active w-100">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo current_org={current_org} processBackClick={handleBackClick} />
        </div>
        <div className="d-lg-flex w-100">
          <div className="h3 decision-description text-capitalize">{selectedGoal?.goal}</div>
          <div className="ms-auto">
            <span>Category: </span>
            <span className="fw-bolder">{label}</span>
          </div>
        </div>
        <div className="d-block d-lg-none ms-auto">
          <HamburgerMenuIconBtn>
            <BaseHamburgerBtn onSelect={handleBackClick} title="Go to homepage" />
          </HamburgerMenuIconBtn>
        </div>
      </div>
    </nav>
  </header>;
};

const mapStateToProps = ({ home, current_org }) => ({ home, current_org });
export default connect(mapStateToProps, {loadDSightData})(Header);
