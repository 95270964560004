import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { ChoiceEntryStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import { isBlank, isPresent, qSortArray } from "../../../helpers/common";
import { updateViewOptions } from "../../../store/forecast_simulator_scenario/actions";

const MetricsSelect = ({
                         selectedDecompMetric, setDecompMetric,
                         config, timeScale,
                         updateViewOptions,
                         loading = false
                       }) => {
  const metricsForSelect = useMemo(() => {
    const decompDrivers = config.driversColumns.filter(dr => isPresent(dr.decompRules))
    const filteredOutputs = config.outputColumns.filter(out =>
      decompDrivers.some(dr => isPresent(dr.decompRules[out.id]?.visible))
    )
    return qSortArray(filteredOutputs, true, metric => metric.id).map(dr => dr.metricSelectHash)
  }, [config])
  const onChangeMetric = (selectedHash) => {
    updateViewOptions({ selectedDecompMetric: selectedHash.value }, (status) => {
      if(status) setDecompMetric(selectedHash.value)
    })
  }
  useEffect(() => {
    if (isBlank(selectedDecompMetric) || !metricsForSelect.some(hash => hash.value === selectedDecompMetric)) {
      setDecompMetric(metricsForSelect[0].value)
    }
  }, [config, selectedDecompMetric])
  return <>
  <div>
    <div className="mb-1">Metrics</div>
    <Select onChange={onChangeMetric}
              defaultValue={metricsForSelect.find(hash => hash.value === selectedDecompMetric)}
              components={{ IndicatorSeparator:() => null }}
              options={metricsForSelect}
              className ={"DriversSelect"}
              classNamePrefix={"DriversSelect"}
              value={metricsForSelect.find(hash => hash.value === selectedDecompMetric)}
              isDisabled={loading}
              styles={ChoiceEntryStyle} />
    </div>
  </>;
}
const mapStateToProps = ({ forecast_simulator_scenario }) => ({ forecast_simulator_scenario });
export default connect(mapStateToProps, { updateViewOptions })(MetricsSelect);
