import React, {useState} from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';

const RenderRateBuyInButton = ({ setShowRateBuyInModal}) =>
  <Button onClick={() => setShowRateBuyInModal(true)}
          className="btn btn-primary w-100 d-none d-lg-flex align-items-center">
    Rate your level of support
  </Button>

const mapStateToProps = ({ current_user, decision, sidebar }) => ({
  current_user, decision, sidebar
});
export default connect(mapStateToProps, null)(RenderRateBuyInButton);
