import React from 'react';
import { isBlank } from "../../../helpers/common";
import { RichTextSection } from "../../../common/RichTextEditor";

export const RationaleSection = ({ object }) => {
  if (isBlank(object.final_decision_reasons)) return null;

  return <div className="px-3 mb-3">
    <h3>Rationale</h3>
    <RichTextSection text={object.final_decision_reasons}/>
  </div>;
}
export default RationaleSection;
