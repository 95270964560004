import React, {useState, useEffect} from 'react';
import DetailsItem from "../../tree_view/navigation/DetailsItem";
import PlaybookTemplateItem from "../../template_wizard/navigation/PlaybookTemplateItem";
import DecisionSetItem from "../../template_set_view/navigation/DecisionSetItem";
import RatingsAndWeightsItem from "../../tree_view/navigation/RatingsAndWeightsItem";
import DecisionTreeItem from "../../tree_view/navigation/DecisionTreeItem";
import { TREE_MODES } from "../../helpers/drivers_helpers";
import CollaboratorsItem from "../../tree_view/navigation/CollaboratorsItem";
import { Template } from "../../models/template";
import DataSourcesItem from "../../tree_view/navigation/DataSourcesItem";
import MoreActionsItem from "../../tree_view/navigation/MoreActionsItem";

const NavigationItems = ({ template, isTemplateLoaded = false, showDecisionSet = false, isDsight = false }) => {
  const [collaboratorsInvitesLength, setCollaboratorsInvitesLength] = useState(0)
  const templateObj = new Template(template);

  useEffect(() => {
    if (isTemplateLoaded)
      setCollaboratorsInvitesLength(templateObj.collaborator_admissions.length)
  }, [isTemplateLoaded, template?.admissions]);

  return <>
    <div className="sidebar-top">
      <ul className="list-unstyled m-0 p-3">
        <RatingsAndWeightsItem isTemplate={true} />
        <DetailsItem />
        {!isDsight && <CollaboratorsItem {...{collaboratorsInvitesLength}}/>}
        <DataSourcesItem />
        {isDsight ? null : <DecisionTreeItem treeMode={TREE_MODES.edit} isTemplate={true}/>}
        <PlaybookTemplateItem />
        { showDecisionSet ? <DecisionSetItem /> : null }
        <MoreActionsItem />
      </ul>
    </div>
  </>
}

export const SidebarTreeNavigationTemplate = (opts) =>
  <div id="sidebar-navigation" className="tree-sidebar bg-light-gray d-none d-lg-block">
    <NavigationItems {...opts} />
  </div>

export default SidebarTreeNavigationTemplate;
