import React from "react";
import DecisionDate, { DATEPICKER_TYPES } from "../../../common/DecisionDate";
import { isBlank } from "../../../helpers/common";
import * as moment from "moment";

const PollDateInput = ({ decision, pollDueDate, setPollDueDate }) => {
  if (isBlank(decision)) return null;

  const onChangeDate = (date) => {
    setPollDueDate(moment(date))
  };

  return <div className="w-100 mb-3">
    <DecisionDate id={DATEPICKER_TYPES.poll_due_date}
                  name={DATEPICKER_TYPES.poll_due_date}
                  minDate={new Date()}
                  header="Due date"
                  date={pollDueDate} onChangeDate={onChangeDate}/>
  </div>
}

export default (PollDateInput);