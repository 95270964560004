import React from 'react';
import { connect } from "react-redux";
import { setDecisionSidebarOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import ContextSection from "./sections/ContextSection";
import DecisionDueDateSection from "./sections/DecisionDueDateSection";
import CategoriesSection from "./sections/CategoriesSection";
import ScheduledFollowupContext from "../header/ScheduledFollowupContext";
import PanelHeader from "./PanelHeader";
import { isMobile } from "react-device-detect";
import SettingsSection from "./sections/SettingsSection";

export const DecisionDetailsPanel = ({ sidebar, tree, openSideBar }) =>
  <SlidingPanel
    type="left"
    isOpen={tree.loaded && sidebar.decisionDetailsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper decision-panel details-panel-width left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
    size={52}
  >
    <PanelHeader headerName={'Details'} openSideBar={openSideBar} preview={true} />
    <ContextSection />
    <DecisionDueDateSection />
    <ScheduledFollowupContext />
    <CategoriesSection isDecision={true}/>
  </SlidingPanel>
const mapStateToProps = ({ sidebar, tree }) => ({ sidebar, tree });
export default connect(mapStateToProps, { openSideBar: setDecisionSidebarOpen })(DecisionDetailsPanel);
