import React, { useCallback, useEffect, useState } from 'react';
import { isBlank, isPresent } from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CloseIcon from "../../common/CloseIcon";
import { NAME_LIMIT } from '../../models/decision_category';
import { duplicatedCategoryEntered } from './AddCategoryModal';
import { DESCRIPTION_LIMIT } from "../../models/category_option";
import CharCounter from "../../common/CharCounter";
import { resetErrorsEffect } from "../../helpers/callbacks_helpers";

export const buildCategoryOptions = (categoryOptions) =>
  Object.assign({}, ...categoryOptions.map((s, i) => ({[i]: { id: s.id, description: s.description, order: s.order } })))

export default ({
                  shown,
                  allCategories = [],
                  onClose = () => {},
                  onSubmit = () => {},
                  category,
                  sortedCategoryOptions
                }) => {
  if(isBlank(category)) return null;

  const slug = category.slug;
  const focusElement = React.useRef(null);
  useEffect(() => {
    if (shown && focusElement.current) focusElement.current.focus()
  }, [shown, focusElement])

  useEffect(() => {
    setName(category.name)
    setSettings({ multiple_selections: category.multiple_selections })
    setCategoryOptions(sortedCategoryOptions)
  }, [category]);
  const [categoryOptions, setCategoryOptions] = useState(sortedCategoryOptions);
  const [name, setName] = useState(category.name);
  const [submit, setSubmit] = useState(false);
  const [newCategoryOption, setNewCategoryOption] = useState(false);
  const [settings, setSettings] = useState({ multiple_selections: category.multiple_selections });
  const [errors, setErrors] = useState({})
  const [showChar, setShowChar] = useState(false);
  const [showCharSelection, setShowCharSelection] = useState([]);

  resetErrorsEffect(setErrors, {}, name);

  const removeCategoryOption = (index, option) => {
    if (isPresent(option?.d_sight)) return null;

    $(`#editCategoryOption-${index}`).removeClass('d-flex').addClass('d-none');
    onChangeCategoryOptionCallback('', index)
  }

  const onChangeCategoryOptionCallback = (value, index) => {
    let newCategoryOptions = [...categoryOptions];
    newCategoryOptions[index].description = value;
    setCategoryOptions(newCategoryOptions)
  };

  const onSettingsChange = (event) => {
    const newSettings = { ...settings };
    newSettings[event.target.name] = event.target.checked;
    setSettings(newSettings);
  };

  const addNewCategoryOptionCallback = () => {
    setNewCategoryOption(true)
    let newCategoryOptions = [...categoryOptions];
    newCategoryOptions.push({ id: 'new-', description: '', order: categoryOptions.length + 1 });
    setCategoryOptions(newCategoryOptions);
  };

  const availableOtherCategories = () => {
    return allCategories.filter((c) => c.slug !== slug)
  };

  const submitUpdateCategoryModal = () => {
    if (duplicatedCategoryEntered(availableOtherCategories(), name)) {
      setErrors({'name': 'The category name entered already exists. Please enter a new name.'})
    } else {
      setSubmit(true);
      onSubmit(slug, name, settings, buildCategoryOptions(categoryOptions), (success, errors) => {
        setSubmit(false)
        if (success) {
          onClose();
        } else if (isPresent(errors) && isPresent(Object.keys(errors))) {
          setErrors(errors)
        }
      })
    }
  };

  const anySelections = useCallback(() =>{
    return categoryOptions.some((categoryOption) => isPresent(categoryOption.description))
  }, [categoryOptions]);

  const changeShowCharSelection = (index, value) => {
    let newSelection = [...showCharSelection];
    newSelection[index] = value;

    setShowCharSelection(newSelection);
  }

  return <Modal size="md" backdrop="static" show={shown} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>Edit category</h2>
      <p className="text-muted">Editing the category name or a selection will update all decisions where it was previously used.</p>
      <h3>Name</h3>
      <CharCounter show={showChar} field={name} limit={NAME_LIMIT} id={`addBadgePill-${name}`} />
      <div className="mb-3">
        <p className="text-muted" hidden={isBlank(category?.d_sight)}>
          Category names used by D-Sight can not be edited.
        </p>
        <input className={`form-control ${errors['name'] ? 'is-invalid' : ''}`}
               placeholder="Enter the name of this category"
               ref={focusElement} value={name}
               autoFocus={shown} disabled={submit || isPresent(category?.d_sight)} maxLength={NAME_LIMIT}
               onBlur={() => setShowChar(false)}
               onFocus={() => setShowChar(true)}
               onChange={(e) => setName(e.target.value)}/>
        <span className={`d-block mt-1 ${ errors['name'] ? 'text-danger' : 'hide-text' }`}>
          {errors['name']}
        </span>
      </div>

      <h3>Settings</h3>
      <div className="mb-3 lh-checkbox">
        <input type="checkbox"
               id="multipleSelections-checkbox"
               className="custom-checkbox"
               checked={settings.multiple_selections}
               name="multiple_selections"
               title="Allow multiple selections"
               onChange={onSettingsChange}/>
        <label htmlFor="multipleSelections-checkbox" className="mb-0">Allow multiple selections</label>
      </div>
      <h3>Selections</h3>
      <div className="mb-3">
        <p className="text-muted" hidden={isBlank(category?.d_sight)}>
          Category selections used by D-Sight can not be edited.
        </p>
        {
           categoryOptions.map((option, index) => <div key={`edit-category-option-${index}`}
                                                       id={`editCategoryOption-${index}`}
                                                       className="d-flex mt-1 align-items-center mb-2">
            <div className="w-100">
              <CharCounter show={showCharSelection[index]} field={option.description} limit={DESCRIPTION_LIMIT} id={`selectionEditBadgePill-${index}`} />
              <input className="form-control"
                    placeholder="Enter a selection"
                    readOnly={submit} disabled={isPresent(option?.d_sight)}
                    value={option.description}
                    onChange={(e) => onChangeCategoryOptionCallback(e.target.value, index)}
                    maxLength={DESCRIPTION_LIMIT}
                    onBlur={() => changeShowCharSelection(index, false)}
                    onFocus={() => changeShowCharSelection(index, true)}
                    ref={inputElement => {
                      if (inputElement && newCategoryOption) {
                        inputElement.focus();
                        setNewCategoryOption(false)
                      }
                    }}/>
            </div>
             <span className={`btn btn-light btn-sm btn-sm-round text-danger ms-2 px-2 ${isPresent(option?.d_sight) ? 'hidden' : ''}`}
                   onClick={() => removeCategoryOption(index, option)}>
                 <i className="fas fa-times w-100"/>
             </span>
          </div>)
        }
        <Button onClick={addNewCategoryOptionCallback} disabled={submit} className="btn-secondary w-100">Add another</Button>
      </div>
      <Button onClick={submitUpdateCategoryModal} className="w-100 mt-3" disabled={isBlank(name) || !anySelections() || submit}>
        Done
      </Button>
    </Modal.Body>
  </Modal>
}
