import React from 'react';
import { Link } from "react-router-dom";
import { Routes as routes } from "../../routes";
import { TABS_URLS } from "../../helpers/home_helpers";

export const DSightHelpText = () =>
  <p>
    Select <b>Edit template users</b> to limit who can view this template on
    the <Link to={`${routes.homepage}/${TABS_URLS.create_decision}`}>Create a decision</Link> page and start decisions
    from it. Chat with customer success to add, modify, or remove D-Sight decisions.
  </p>

export default DSightHelpText;
