import React from "react";
import DueBy from "./../shared/DueBy";
import { isPresent } from "../../../helpers/common";
import { AssignedToYou, BulletSplitter } from "../driver/DriverInfo";

export default ({ decision, decisionObject, user }) => {
  const isAssignedToUser = user.email === decision.assigned_collaborator_email;
  const hasDueDate = isPresent(decision.due_date);
  if(!isAssignedToUser && !hasDueDate) return null;

  const needBullet = isAssignedToUser && hasDueDate;
  return <div className="d-flex">
    { isAssignedToUser ? <AssignedToYou /> : null }
    { needBullet ? <BulletSplitter /> : null }
    <DueBy {... { object: decision, isOverdue: decisionObject.isOverdue }}/>
  </div>
}
