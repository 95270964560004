import {
  WIZARD_LOAD_AI_DATA_FAILED,
  WIZARD_LOAD_DATA,
  WIZARD_LOAD_DATA_FAILED,
  WIZARD_LOAD_DATA_STARTED,
  WIZARD_RESET_DATA,
  WIZARD_UPDATE_DATA
} from "./types";
import {
  createDriversFromSuggestions,
  loadWizardDataRequest,
  saveTemplateWizardStep,
  saveWizardStep,
  loadReportWizardDataRequest,
  saveReportWizardStep,
  createReportWizardRequest,
  removeReportWizardRequest,
  destroyWizardDataSource,
  createWizardDataSource,
  replaceWizardDataSource,
  updateWizardDataSource
} from "../../utils/Api";
import {updateDecisionData} from "../decision/common_actions";
import { updateReport } from "../report/actions";
import EntryPoint from "../../EntryPoint";
import { updateUserData } from "../current_user/actions";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import {wizardStepDataBy, wizardStepKey, stepWithDrivers} from "../../helpers/wizard_helpers";
import { updateTreeData } from "../tree/common_actions";
import { isPresent, uniqueBy } from "../../helpers/common";
import { loadTemplateSuccess, updateTemplateData } from "../template/common_actions";
import { updateShareData } from "../share/actions";
import { loadPlaybookNotesSuccess } from "../playbook_notes/actions";
import { updateReportData } from "../report/actions";
import { dispatchTreeDriversDataSources } from "../tree/actions";
import { updateModal } from "../modals/actions"
import {updateSetsFailure} from "../decision_set/common_actions";
import { resetReportsData } from "../homepage/actions";
import { reloadOrgReports } from "../current_org/actions";
import { recalculateDriversCompletion } from "../decision_set/helper_actions";

export const loadWizardData = () => (dispatch) => {
  const { objectSlug, controllerName } = EntryPoint.instance;
  dispatch(loadWizardStarted({ loaded: false, loading: true }));
  loadWizardDataRequest({ controllerName, objectSlug }).then((response) => {
    const { data } = response;
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure });

    const { decision, template, current_user, data_sources, share_data, status, ...wizardData } = data;
    const playbookNotes = isPresent(template) ? template.playbook_notes : decision.playbook_notes
    if (isPresent(template)) dispatch(loadTemplateSuccess({ ...template }));
    if (isPresent(decision)) dispatch(updateDecisionData({ ...decision }));
    if (isPresent(current_user)) dispatch(updateUserData({ ...current_user }));
    if (isPresent(share_data)) dispatch(updateShareData({ ...share_data }));
    if (isPresent(data_sources))  dispatch(updateTreeData({ data_sources }));
    dispatch(loadPlaybookNotesSuccess({ ...(playbookNotes || {}), loaded: true }));
    dispatch(loadWizardSuccess({ ...wizardData, loaded: true, loading: false }));
  })
}

export const loadReportWizardData = () => (dispatch) => {
  const { objectSlug } = EntryPoint.instance;
  dispatch(loadWizardStarted({ loaded: false, loading: true }));
  loadReportWizardDataRequest({ objectSlug }).then((response) => {
    const { data } = response;
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure });

    const { report, current_user, ...wizardData } = data;
    if (isPresent(report)) dispatch(updateReportData({ ...report }));
    if (isPresent(current_user)) dispatch(updateUserData({ ...current_user }));
    dispatch(loadWizardSuccess({ ...wizardData, loaded: true, loading: false }));
  })
}

// Report wizard actions
export const createReportWizardData = (callback = () => {}) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance;
  dispatch(loadWizardSuccess({ submit: true }));
  createReportWizardRequest(objectSlug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure }, callback)

    const { data } = response;
    const { report, status, ...wizardData } = data;
    dispatch(updateReport({ ...report }));
    dispatch(loadWizardSuccess({ ...wizardData, submit: false }));
    dispatch(resetReportsData())
    dispatch(reloadOrgReports())
    callback(true)
  })
}

export const removeReportWizardData = (callback = () => {}) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance;
  dispatch(loadWizardSuccess({ submit: true }));
  removeReportWizardRequest(objectSlug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure }, callback)
    dispatch(resetReportsData())
    dispatch(reloadOrgReports())
    callback(true)
  })
}

export const saveReportWizardStepData = (step, data, callback = () => {}) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  dispatch(loadWizardSuccess({ submit: true }));
  saveReportWizardStep(objectSlug, step, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure }, callback)

    const { data } = response;
    const { report, status, ...wizardData } = data;
    dispatch(updateReport({ ...report }));
    dispatch(loadWizardSuccess({ ...wizardData, submit: false }));
    dispatch(reloadOrgReports())
    callback(true)
  })
}

export const prevReportWizardStep = () => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance
  const { wizard } = getState()

  dispatch(loadWizardSuccess({ submit: true }));

  saveReportWizardStep(objectSlug, wizardStepKey(wizard), { previous_step: true }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure });

    const new_step_index = wizard.step_index - 1
    const step_index = new_step_index < 0 ? 0 : new_step_index
    dispatch(loadWizardSuccess({ step_index, tree_preview: false, submit: false }));
  })
}

// Decision wizard actions
export const saveWizardStepData = (step, data, callback = () => {}) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  dispatch(loadWizardSuccess({ submit: true }));
  saveWizardStep(objectSlug, step, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure }, callback)

    const { data } = response;
    const { decision, status, ...wizardData } = data;
    const decisionUsers = uniqueBy([...(getState().decision.users || []), ...(decision.users || [])], 'email')
    dispatch(updateDecisionData({ ...decision, users: decisionUsers }));
    dispatch(loadWizardSuccess({ ...wizardData, submit: false }));
    callback(true, wizardData)
  })
}
export const prevWizardStep = () => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance
  const { wizard } = getState()

  dispatch(loadWizardSuccess({ submit: true }));

  saveWizardStep(objectSlug, wizardStepKey(wizard), { previous_step: true }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure });

    const new_step_index = wizard.step_index - 1
    const step_index = new_step_index < 0 ? 0 : new_step_index
    dispatch(loadWizardSuccess({ step_index, tree_preview: false, submit: false }));
  })
}
export const nextWizardStep = () => (dispatch, getState) => {
  const { wizard } = getState()
  const new_step_index = wizard.step_index + 1
  const step_index = new_step_index >= wizard.flow_steps.length ? wizard.flow_steps.length - 1 : new_step_index
  dispatch(loadWizardSuccess({ step_index }));
}

// Template wizard actions
export const saveTemplateWizardStepData = (step, data, callback = () => {}) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance;
  dispatch(loadWizardSuccess({ submit: true }));
  saveTemplateWizardStep(objectSlug, step, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure }, callback)

    const { data } = response;
    const { template, status, ...wizardData } = data;
    dispatch(updateTemplateData({ ...template }));
    dispatch(loadPlaybookNotesSuccess({ ...template.playbook_notes }));
    dispatch(loadWizardSuccess({ ...wizardData, submit: false }));
    callback(true, wizardData)
  })
}
export const prevTemplateWizardStep = () => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance
  const { wizard } = getState()

  dispatch(loadWizardSuccess({ submit: true }));

  saveTemplateWizardStep(objectSlug, wizardStepKey(wizard), { previous_step: true }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure });

    const new_step_index = wizard.step_index - 1
    const step_index = new_step_index < 0 ? 0 : new_step_index
    dispatch(loadWizardSuccess({ step_index, tree_preview: false, submit: false }));
  })
}
export const nextTemplateWizardStep = () => (dispatch, getState) => {
  const { wizard } = getState()
  const new_step_index = wizard.step_index + 1
  const step_index = new_step_index >= wizard.flow_steps.length ? wizard.flow_steps.length - 1 : new_step_index
  dispatch(loadWizardSuccess({ step_index }));
}

// Create drivers from ChatGPT suggestions
export const createWizardDriversSuggestions = (data, callback) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance;
  dispatch(loadWizardSuccess({ submit: true }));
  createDriversFromSuggestions(objectSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure }, callback);
    const wizardData = response.data;
    const findStepIndex = (steps, stepName) => {
      return steps.findIndex(step => step.step === stepName);
    };
    const treeBuilderIndex = findStepIndex(wizardData.flow_steps, 'tree_builder');

    const drivers = (treeBuilderIndex !== -1) ? wizardData.flow_steps[treeBuilderIndex].drivers : wizardData.flow_steps[0].drivers;

    dispatch(loadWizardSuccess({ ...wizardData, submit: false }));
    dispatch(updateTreeData({ drivers }));
    dispatch(recalculateDriversCompletion({ drivers }));
    callback(true, drivers);
  })
}

// Wizard tree actions
export const previewWizardTree = () => (dispatch) => {
  dispatch(loadWizardSuccess({ tree_preview: true }));
}
export const buildWizardTree = () => (dispatch) => {
  dispatch(loadWizardSuccess({ tree_preview: false }));
}

// Data Sources
export function destroySource(slug, data, updateOnlySources = false, callback = () => {}) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    destroyWizardDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure }, callback);

      handleSourceAction(dispatch, response, getState, callback, updateOnlySources);
    })
  }
}

export function createSource(data, config = {}, callback = () => {}, updateOnlySources = false) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    createWizardDataSource({ controllerName, objectSlug, data, config }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure }, callback)

      handleSourceAction(dispatch, response, getState, callback, updateOnlySources);
    })
  }
}

export function replaceSource(slug, data, config = {}, callback = () => {}, updateOnlySources = false) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    replaceWizardDataSource({ controllerName, objectSlug, slug, data, config }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure }, callback)

      handleSourceAction(dispatch, response, getState, callback, updateOnlySources);
    })
  }
}
export function updateSource(slug, data, updateOnlySources = false) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    updateWizardDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure });

      handleSourceAction(dispatch, response, getState, () => {}, updateOnlySources);
    })
  }
}

// Attach Report to Decision
export function attachReport(data, updateOnlySources = false, callback) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    createWizardDataSource({ controllerName, objectSlug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure }, callback);

      handleSourceAction(dispatch, response, getState, callback, updateOnlySources);
    })
  }
}

// Detach Report to Decision
export function detachReport(reportSlug, data, updateOnlySources = false, slug = '') {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    destroyWizardDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure });

      handleSourceAction(dispatch, response, getState, () => {}, updateOnlySources);
    })
  }
}

// Attach Forecast Simulator Scenario to Decision
export const attachScenario = (data, updateOnlySources = false, callback) => (dispatch, getState) => {
  const { objectSlug, controllerName } = EntryPoint.instance;

  createWizardDataSource({ controllerName, objectSlug, data }).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure }, callback);

    handleSourceAction(dispatch, response, getState, callback, updateOnlySources);
  })
}

// Detach Forecast Simulator Scenario to Decision
export const detachScenario = (scenarioId, data, updateOnlySources = false, slug = '') => (dispatch, getState) => {
  const { objectSlug, controllerName } = EntryPoint.instance;

  destroyWizardDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardFailure });

    handleSourceAction(dispatch, response, getState, () => {}, updateOnlySources);
  })
}

export function addSourceToDecision(data, callback, updateOnlySources = false) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    createWizardDataSource({ controllerName, objectSlug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

      handleSourceAction(dispatch, response, getState, callback, updateOnlySources);
    })
  }
}

const handleSourceAction = (dispatch, response, getState, callback, updateOnlySources) => {
  const { data } = response;
  const { data_sources, flow_steps } = data;
  const drivers = wizardStepDataBy({flow_steps}, stepWithDrivers({flow_steps}))?.drivers || []
  if(getState().modal.shown) {
    dispatch(updateModal({data_sources, drivers}));
  } else {
    dispatchTreeDriversDataSources(dispatch, getState, { data_sources, drivers }, updateOnlySources);
  }
  dispatch(loadWizardSuccess({ flow_steps, submit: false }));
  callback(true, drivers);
}

export const resetWizard = () => (dispatch) => { dispatch(resetWizardState()); }

// Wizard helpers
export const loadWizardSuccess = (data) => ({
  type: WIZARD_LOAD_DATA,
  payload: {
    ...data
  }
});
export const loadWizardStarted = (query = {}) => ({
  type: WIZARD_LOAD_DATA_STARTED,
  payload: {
    ...query
  }
});
export const updateWizardData = (data) => ({
  type: WIZARD_UPDATE_DATA,
  payload: {
    ...data
  }
});
export const loadWizardFailure = error => ({
  type: WIZARD_LOAD_DATA_FAILED,
  payload: {
    error
  }
});

export const loadWizardAiFailure = errorAi => ({
  type: WIZARD_LOAD_AI_DATA_FAILED,
  payload: {
    errorAi
  }
});
export const resetWizardState = () => ({
  type: WIZARD_RESET_DATA
});

