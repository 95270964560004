import React from 'react'

const {env} = process || {};

const MsTeamsFragment = ({ appId = null }) =>
  <>
    Please<a href={`https://teams.microsoft.com/l/app/${appId || env.MS_TEAMS_APP_STORE_ID || env.MS_TEAMS_APP_ID}`} title="Cloverpop app" className={'px-1'}>add the Cloverpop app</a>
    to your organization to receive Teams notifications. If the app has already been added, please remove and reinstall the app.
  </>

export default ({ type, appId = null}) =>
  type === 'ms_teams' && <MsTeamsFragment appId={appId} />
