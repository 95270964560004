import { isBlank } from "../../helpers/common";
import EntryPoint from "../../EntryPoint";
import Decision from "../../models/decision";
import { updateDecisionSetData } from "./common_actions";

export const recalculateDriversCompletion = ({ drivers }) => (dispatch, getState) => {
  if (isBlank(getState().decision_set)) return null;

  const { objectSlug, controllerName } = EntryPoint.instance;
  if (controllerName !== 'decisions') return null;

  const newDecisionsData = getState().decision_set.decisions.map(decisionHash => {
    if (decisionHash.slug === objectSlug) {
      const decisionObj = new Decision(decisionHash, drivers)
      return {
        ...decisionHash,
        drivers: {
          total_count: 1,
          total_drivers: drivers.length,
          tree_completion: parseFloat(decisionObj.answeringProgressValue.toFixed(2))
        }
      }
    }

    return decisionHash;
  })
  dispatch(updateDecisionSetData({ decisions: newDecisionsData }));
}
