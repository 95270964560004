import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Template } from "../../models/template";
import { ModalDoneFooterP0 } from "../../common/modals";
import {updateTemplate} from "../../store/modals/actions";
import {RichTextEditor} from "../../common/RichTextEditor";
import ModalHeaderTitle from "../../common/ModalHeaderTitle";

export const TemplatePollNotesModal = ({
                                     template,
                                     shown = false, onClose = () => {},
                                     updateTemplate
                                  }) => {
  const templateObj = new Template(template)
  if(templateObj.isOpenEnded) return null;

  const [pollNotes, setPollNotes] = useState(template.poll_notes)
  const close = () => onClose()

  const submitData = () => {
    const callback = (success) => {
      if (success) close()
    }
    updateTemplate({ template: {poll_notes: pollNotes} }, callback)
  }

  useEffect(() => {
    if (template.loaded) {
      setPollNotes(template.poll_notes)
    }
  }, [template.loaded, template.poll_notes])

  return <Modal size="lg" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <form>
        <ModalHeaderTitle {...{ onClose, title: 'Decision poll' }} />
        <h3>Notes</h3>
        <RichTextEditor quillId={'PollNotes'} value={pollNotes} setValue={setPollNotes}
                        placeholder="Enter any comments or instructions for completing the poll" />
      </form>
    </Modal.Body>
    <ModalDoneFooterP0 onClose={submitData} />
  </Modal>
}
const mapStateToProps = ({ template }) => ({ template });

export default connect(mapStateToProps, {updateTemplate})(TemplatePollNotesModal);
