import React from "react";
import { isBlank } from "../../../helpers/common";
import * as moment from "moment";

export default ({ decision }) => {
  const {poll_due_date} = decision
  if(isBlank(poll_due_date)) return null;

  const isOverdue = moment(poll_due_date).isSameOrBefore(moment());

  return <div className={`d-inline-flex ${isOverdue ? 'text-danger' : 'text-muted' }`}>
    Due by {moment(poll_due_date).format('DD MMM, yyyy')}
  </div>
}
