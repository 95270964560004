import React, {Fragment} from 'react'
import TreeHeader from "./header";
import AlertsSection from "../alerts";
import Body from "./body"

export const Wrapper = ({ children }) => <Fragment>
   <AlertsSection/>
   <TreeHeader/>
   <div className="d-flex overflow-hidden">
      {children}
   </div>
</Fragment>


export default () => <Wrapper>
   <Body />
</Wrapper>