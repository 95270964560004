import React from 'react';
import { isBlank } from "../../../helpers/common";
import Driver from "../../../models/driver";
import TopChoice from "./choices/TopChoice";
import PickyChoices from "./choices/PickyChoices";

const ChoicesSection = ({ driver, isHistoricalDecision = false, setChoices = () => {}, choices,
                          updateDriverFinalChoices = () => {} }) => {
  if (isBlank(driver)) return null;

  const driverObj = new Driver(driver);

  if (driverObj.isPicky) {
    return <PickyChoices {...{driver, isHistoricalDecision, setChoices, choices, updateDriverFinalChoices }} />
  } else if(driverObj.isTopChoice) {
    return <TopChoice {...{driver, isHistoricalDecision, setChoices, choices, updateDriverFinalChoices }} />
  } {
    return null;
  }
}

export default ChoicesSection;
