import React from 'react';
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import { setHelpPanelOpen } from "../store/sidebar/actions";
import PanelHeader from "../tree_view/side_panel/PanelHeader";
import { isMobile } from "react-device-detect";

const BaseHelpPanel = ({ sidebar, openSideBar, children, headerName = 'Help', position = 'left', addClass,  ...opts }) =>
  <SlidingPanel
    type={position}
    isOpen={sidebar.helpSidebar}
    panelContainerClassName={`sidebar-panel-wrapper help-panel-wrapper ${addClass}`}
    panelClassName={`${isMobile ? '' : 'd-flex flex-column'} sidebar-panel-wrapper-content help-panel-content`}
    size={50}
    {...opts}>
    <PanelHeader headerName={headerName} openSideBar={openSideBar} preview={true} />
    {children}
  </SlidingPanel>

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setHelpPanelOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(BaseHelpPanel);
