import React from "react";
import {useHistory} from "react-router";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";

const SET_DATA = {
  decision_set: { headerText: 'Add a decision', targetLocation: 'decision_sets', buttonText: 'Go to Decision flow' },
  template_set: { headerText: 'Add a decision', targetLocation: 'template_sets', buttonText: 'Go to Decision flow templates' }
}

export default ({isDecisionSet = true, set}) => {
  const history = useHistory();
  const { targetLocation, headerText, buttonText } = SET_DATA[isDecisionSet ? 'decision_set' : 'template_set'];

  const backAction = () => history.push(`/${targetLocation}/${set.slug}`);

  return <>
    <div className="d-flex align-items-center active w-100">
      <div className="btn btn-secondary btn-lg-round d-none d-lg-flex justify-content-around me-2" onClick={backAction}>
        <i className="fas fa-lg fa-arrow-left"/>
      </div>
      <h5>{headerText}</h5>
      <div className='d-lg-none ms-auto'>
        <HamburgerMenuIconBtn>
          <BaseHamburgerBtn onSelect={backAction} title={buttonText} />
        </HamburgerMenuIconBtn>
      </div>
    </div>
  </>
};
