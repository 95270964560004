import React from 'react';
import { connect } from "react-redux";
import { BtnDropdownToggleInlineShadowText } from "../../../../common/dropdowns";
import { isBlank, isPresent } from "../../../../helpers/common";
import { BaseDropdownBtn } from "../../../../common/BaseHamburgerBtn";
import { findSelectedDemographic } from "../helpers";

const DEFAULT_SEGMENT = {
  id: null,
  name: 'vs_competition',
  display_name: 'All responders'
}
const prepareSegment = ({ name, options, ...hash}, availableDemographics) =>
  ({
    ...hash, options, name,
    display_name: `All by ${name.toLowerCase()}`,
    disabled: !options.some(o => availableDemographics.every(arr => arr.includes(o.id)))
  })

const findSelectedDemographicOption = (d_sight_goal) => {
  const { segment, option } = findSelectedDemographic(d_sight_goal)
  if (isBlank(segment)) return {}

  return {
    segment,
    option: {
      id: null,
      name: option.name,
      display_name: option.display_name,
      select_display_name: `${segment.display_name} ${option.display_name}`
    }
  }
}


const DemographicSegmentDropdown = ({
                                      d_sight_goal,
                                      selectedDemographicSegment, setSelectedDemographicSegment,
                                      availableDemographics
                                    }) => {
  const segments = d_sight_goal.demographics.filter(({ options }) => isPresent(options) )

  if (isBlank(segments)) return null;

  const { segment, option } = findSelectedDemographicOption(d_sight_goal)
  const defaultOption = option || DEFAULT_SEGMENT

  const availableSegments = [
    defaultOption,
    ...segments.map(opts => prepareSegment(opts, availableDemographics))
  ]

  const selectedSegment = availableSegments.find(({ id }) =>
    parseInt(id) === parseInt(selectedDemographicSegment)
  ) || defaultOption

  return <div className="d-block d-md-inline-block text-md-end me-2 mb-2 mb-md-0">
    {
      isBlank(selectedDemographicSegment) && isPresent(segment) ?
        <div className="d-inline-flex text-nowrap me-1">{segment.display_name}:</div> :
        null
    }
    <BtnDropdownToggleInlineShadowText id="segment-dropdown"
                                       title={selectedSegment.display_name}
                                       onSelect={setSelectedDemographicSegment} bsPrefix="text-capitalize pe-0">
      {availableSegments.map(segments =>
        <BaseDropdownBtn key={`segment-${segments.id}`}
                         eventKey={segments.id}
                         title={segments.select_display_name || segments.display_name}
                         active={selectedSegment.id===segments.id}
                         disabled={segments.disabled}
                         bsPrefix="text-capitalize"/>
      )}
    </BtnDropdownToggleInlineShadowText>
  </div>
}
export const mapStateToProps = ({ d_sight_goal }) => ({ d_sight_goal });
export default connect(mapStateToProps)(DemographicSegmentDropdown);
