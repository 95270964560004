import {isBlank, qSortArray, uniqueBy} from "../../../helpers/common";
import {approvalWithUser} from "../../../helpers/decision_helpers";
import * as moment from "moment/moment";
import {baseHeadParagraph, baseRichBlock, paragraphBorder} from "./wrappers";
import {HeadingLevel} from "docx";
import {enteredBySection} from "./commonBlocks";

const approvalRow = ({approved, approved_comment, user, created_at}, approvalsBody) =>
  approvalsBody.push(
    baseHeadParagraph ({text: approved ? 'Approved' : 'Not approved', heading: HeadingLevel.HEADING_4}),
    ...baseRichBlock({text: approved_comment, instance: 7, isJodit: false}),
    enteredBySection({user: user, time: created_at, space: 0})
  )

export const ApprovalSection = (decision) => {
  const notCanceledApprovals = decision.user_approvals.filter(u_a => !u_a.canceled)
  if (isBlank(notCanceledApprovals)) return [];

  const collectApprovals = uniqueBy(notCanceledApprovals.map(approval => approvalWithUser(approval, decision)), 'user_email');
  const approvals = qSortArray(collectApprovals, true, (o) => moment(o.created_at));
  const approvalsBody = []
  approvals.forEach(approval => approvalRow({...approval}, approvalsBody));

  return [
    baseHeadParagraph ({text: 'Approval'}),
    ...approvalsBody,
    paragraphBorder(),
  ]
}
