import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { updateRecordDecision } from "../../../store/sidebar/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import AccordionWrapper from "../../../common/AccordionWrapper";
import { JoditTextEditor } from '../../../common/JoditTextEditor';

const NextStepsInput = ({ decision, updateRecordDecision }) => {
  if (isBlank(decision)) return null;

  const description = decision.next_steps_description
  const onChangeNextStepsDescription = (nextStepsDescription) => { updateRecordDecision({ next_steps_description: nextStepsDescription }) };
  const accordionObject = {
    header: <h3 className="mb-0"> Next steps</h3>,
    body: <JoditTextEditor className="jodit-container-default"
                           value={description}
                           placeholder="Enter any actions to take"
                           setValue={onChangeNextStepsDescription}
                           autofocus={false} />,
    defaultExpand: isPresent(description)
  };

  return <div onBlur={onChangeNextStepsDescription}>
    <AccordionWrapper accordionObject={accordionObject} className='px-3 mb-3' />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecordDecision })(NextStepsInput);
