import React, { useMemo } from 'react';
import DecisionSet from "../models/decision_set";
import ProgressBar from "react-bootstrap/ProgressBar";
import { isBlank } from "../helpers/common";

export default ({ set }) => {
  const { decisions, completed, completedDecisionsNumber, totalDecisionsNumber, slug } = new DecisionSet(set);

  const progressPercent = useMemo(() => {
    if(isBlank(decisions) || completed || totalDecisionsNumber === 0) {
      return 0;
    }
    return (completedDecisionsNumber/totalDecisionsNumber * 100).toFixed(0);
  }, [decisions, completed, completedDecisionsNumber, totalDecisionsNumber])

  return <ProgressBar now={progressPercent} />
}
