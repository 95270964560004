import React from 'react';
import { connect } from "react-redux";
import { isPresent } from "../../../helpers/common";
import { updateContext } from "../../../store/template/actions";
import { saveTemplateWizardStepData } from "../../../store/wizard/actions";
import {
  ContextEditForm,
  contextSectionState,
  DisplayContext
} from "../../../tree_view/side_panel/sections/ContextSection";

export const ContextSection = ({ template, updateContextData, saveTemplateWizardStepData }) => {
  const {
    value, editMode,
    openEditForm, closeEditForm, saveForm,
    ...args
  } = contextSectionState({
    context: template.collaboration_context,
    saveFormCallback: (value, callback) => {
      if (isPresent(template.completed_at)) {
        updateContextData(value, callback);
      } else {
        saveTemplateWizardStepData(null, {
          template: { collaboration_context: value }
        }, callback)
      }
    }
  })

  return <div className="px-3 pb-3">
    {
      editMode ?
        <ContextEditForm value={value} saveForm={saveForm} onCancel={closeEditForm} {...args} /> :
        <DisplayContext text={template.collaboration_context} onClick={openEditForm} />
    }
  </div>
}
const mapStateToProps = ({ template }) => ({ template });
const mapDispatchToProps = (dispatch) => ({
  updateContextData: (value, callback) => { dispatch(updateContext(value, callback)); },
  saveTemplateWizardStepData: (step, data, callback) => { dispatch(saveTemplateWizardStepData(step, data, callback)) }
});
export default connect(mapStateToProps, mapDispatchToProps)(ContextSection);
