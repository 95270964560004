import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from "highcharts/highcharts-more.js"
import { daysCounts, formatAvgTime } from "../../../homepage/tabs/learning/AllDecisionsStatisticsPanel";
import { filterChartsData, xAxisByMonth } from "./TotalDecisions";
import { CHART_COLORS, POINT_HEADER, SERIES_NAME_ROW } from "../../../models/d_sight/metric_chart_helpers";

highchartsMore(Highcharts);

const BASE_CHART_OPTIONS = {
  chart: {
    type: 'column',
    spacing: [5, 0, 0, 5],
    style: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },
  credits: { enabled: false },
  yAxis: {
    title: { text: null },
    labels: {
      format: '{text} days',
      style: {
        fontSize: '0.75rem',
          color: '#1F2F3F'
        }
      },
  },
    tooltip: {
      headerFormat: `${POINT_HEADER}<table>`,
      pointFormat: `<tr>${SERIES_NAME_ROW}<td style="padding:0"><b>{point.y} days</b></td></tr>`,
      footerFormat: '</table>',
      shared: true,
      style: {
        color: '#1F2F3F',
      },
      useHTML: true
    },
    legend: {
      enabled: false,
      itemStyle: {
        color: '#1F2F3F',
        fontWeight: '500',
        fontSize: '0.875rem'
      }
  }
}

export const AvgDecisionTimeName = ({ learn }) =>
  <div>
    <div className="icon no-pointer mb-2">
      <div className="d-flex justify-content-center align-items-center bg-light text-gray rounded-circle mx-auto">
        <i className="far fa-clock fa-lg" />
      </div>
    </div>
    <h3 className="mb-0">{formatAvgTime(learn.statistics?.all_statistics)} </h3>
    <div className="text-gray lh-sm">Avg time to decision</div>
  </div>

export const AvgDecisionTimeChart = ({ learn }) => {
  const dataByMonth = filterChartsData(learn.statistics.by_month)
  const data = dataByMonth.map(hash => parseFloat(daysCounts(hash.statistics.avg_decision_time)))

  const chartOptions = {
    ...BASE_CHART_OPTIONS,
    colors: CHART_COLORS,
    xAxis: xAxisByMonth(dataByMonth),
    title: {
      text: 'Avg time to decision',
      align: 'left',
      style: {
        fontSize: '1.125rem',
        color: '#1F2F3F',
        fontWeight: 500
      }
    },
    series: [{
      name: 'Avg time to decision',
        data,
    }]
  }
  return <HighchartsReact highcharts={Highcharts} options={chartOptions}/>
}
