import React, {useRef} from "react";
import {AI_ALLOWED_TYPES} from "../../../models/data_source";
import {convertBase64} from "../../../helpers/common";
import {openUploadDecisionModal} from "../../modals/UploadDecisionDocumentModal";
import {openModal} from "../../../store/modals/actions";
import {connect} from "react-redux";
import Alerts from "../../../../js/alerts";

const MAX_SIZE_BYTES = 100 * 1024 * 1024; // 100MB in bytes

const UploadDecisionDocumentButton = ({openModal, hidden}) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > MAX_SIZE_BYTES) {
      Alerts.error({
        title: 'Oops!',
        text: 'The file can not be uploaded because it exceeds the size limit of 100MB.'
      });
    } else {
      convertBase64(file).then(uploadedFileBlob => {
        const uploadedFile = {blob: uploadedFileBlob, name: file.name}
        openUploadDecisionModal({openModal, uploaded_file: uploadedFile});
        resetFileInput();
      });
    }
  };

  const resetFileInput = () => {
    fileInputRef.current.value = null;
  };

  return (
    <div className={`col-md-auto ms-md-auto mb-2`}>
      <button className={`btn btn-primary ${hidden ? 'd-none' : ''}`} onClick={handleButtonClick}>
        Upload decision document
      </button>
      <input
        type="file"
        ref={fileInputRef}
        className={`d-none`}
        accept={AI_ALLOWED_TYPES.join(',')}
        onChange={handleFileChange}
      />
    </div>
  );
};

const mapStateToProps = ({ current_org }) => ({ current_org });
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(UploadDecisionDocumentButton);