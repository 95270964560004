import React, { useState } from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../helpers/common";
import {isCollaborator, isDecider, isVisitor} from "../../../helpers/user_helpers";
import Button from 'react-bootstrap/Button';
import Decision from "../../../models/decision";
import Recommendation from "../../../models/recommendation";
import { copyChoices, setDecisionInputSidebarOpen } from "../../../store/sidebar/actions";
import RequestApprovalModal from "../../modals/RequestApprovalModal";
import {openModal} from "../../../store/modals/actions";

const RecordRecommendationActionsSection = ({
                                              user, decision,
                                              setDecisionInputSidebarOpen, copyChoices, setShowEnterApprovalModal,
                                              openModal
                                            }) => {
  if (!isDecider(user) && !isCollaborator(user)) return null;
  if (isBlank(decision) || isBlank(decision.recommendation)) return null;

  const decisionObject = new Decision(decision);
  const recommendationObject = new Recommendation(decision.recommendation);

  const editFollowup = decisionObject.isDecisionWithResults

  const [showRequestApprovalModal, setShowRequestApprovalModal] = useState(false)

  const onDecisionEntryOpen = () => {
    if(isBlank(decisionObject.decisionChoices) && !recommendationObject.isOpenEnded) {
      copyChoices((success) => {
        if (success) setDecisionInputSidebarOpen(true)
      })
    } else {
      setDecisionInputSidebarOpen(true)
    }
  }

  return <div className="px-3 mb-3">
    <Button onClick={() => { setShowRequestApprovalModal(true) }} className="btn btn-secondary w-100 mb-2">Request approval</Button>
    <div hidden={decisionObject.isWithApprovalInvites || decisionObject.isWithApproval || isVisitor(user)} className={'mb-2'}>
      <Button onClick={() => { setShowEnterApprovalModal(true) }} className="btn btn-secondary w-100">
        Enter approval
      </Button>
    </div>
    <Button onClick={onDecisionEntryOpen} className="btn btn-secondary w-100 mb-2">Enter what was decided</Button>
    <div hidden={editFollowup}>
      <Button onClick={() => openModal({ type: 'FollowupResultsModal'})} className="btn btn-secondary w-100">Enter the results & actions taken</Button>
    </div>
    <div className="modals">
      <RequestApprovalModal shown={showRequestApprovalModal} onClose={() => setShowRequestApprovalModal(false)} />
    </div>
  </div>
};
const mapStateToProps = ({ current_user, decision }) => ({
  decision, user: current_user
});
export default connect(mapStateToProps, { setDecisionInputSidebarOpen, copyChoices, openModal})(RecordRecommendationActionsSection);
