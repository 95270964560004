import React, {useEffect, useMemo, useRef, useState} from 'react';
import { connect } from "react-redux";
import {
  loadDecisionsSection, needToLoadHomepageSection, isHomepageSectionLoading, isHomepageSectionLoaded
} from "../../../store/homepage/actions";
import {isBlank, paginateArray, qSortArray} from "../../../helpers/common";
import { Loader } from "../../../common/Loader";
import DecisionTile from "../tiles/DecisionTile";
import {refreshPaginationCallback, TABS_PER_PAGE} from "./AssignedDriversSection";
import {Pagination} from "../../../common/Pagination";
import { isLegacySlackMsTeamsFlow } from "../../../helpers/home_helpers";
import { useSectionVisibility } from "../../../helpers/cookie_helpers";

const sortTileField = (d) => d.created_at

export const scrollTopSection = (refHeader) => {
  if (refHeader.current) {
    refHeader.current.scrollIntoView({ behavior: 'smooth' });
  }
}

const DecisionsSection = ({
                            home,
                            section, title, sortField = sortTileField,
                            loadDecisionsSection, isDecisionSet = false,
                            current_user, isApprovalNeeded = false
                          }) => {
  useEffect(() => {
    if (needToLoadHomepageSection(home, section)) {
      loadDecisionsSection(section)
    }
  }, [home[section].loaded])

  const filteredData = home[section].data.filter(decision => !(isLegacySlackMsTeamsFlow(decision)))
  const tiles = qSortArray(filteredData,false, d => sortField(d))
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const refHeader = useRef(null)

  const recordsPerPage = useMemo(() => paginateArray(tiles, page, TABS_PER_PAGE), [page, tiles])

  const [isSectionVisible, setIsSectionVisible] = useSectionVisibility(current_user.email, section);

  refreshPaginationCallback(tiles, setTotalPages, page, setPage)

  if (isBlank(tiles) && home[section].loaded) return null;

  return (
    <div>
      <div className="row scroll-margin-tab" ref={refHeader}>
        <div className="col">
          <h1 className={`text-nowrap dropdown-toggle pointer ${isSectionVisible ? 'open' : ''}`} onClick={() => setIsSectionVisible(!isSectionVisible)}>
            {title}
          </h1>
        </div>
      </div>
      {isSectionVisible && (
        <>
          <div className="row" hidden={isHomepageSectionLoading(home, section)}>
            {
              recordsPerPage.map((t) =>
                <DecisionTile decision={t} isDecisionSet={isDecisionSet} isApprovalNeeded={isApprovalNeeded} current_user={current_user} section={'decisions'} key={`decisions-center-decision-tile-${t.slug}`} />
              )
            }
          </div>
          <div className="row mb-3" hidden={home[section].loading || totalPages < 2} onClick={() => scrollTopSection(refHeader)}>
            <Pagination page={page} totalPages={totalPages} setPage={setPage} totalCount={tiles.length} perPage={TABS_PER_PAGE} />
          </div>
        </>
      )}
      <div className="row" hidden={isHomepageSectionLoaded(home, section)}>
        <Loader />
      </div>
    </div>
  );  
}
const mapStateToProps = ({ home, current_user }) => ({ home, current_user });
export default connect(mapStateToProps, { loadDecisionsSection })(DecisionsSection);
