import React, { useState } from 'react';
import Button from "react-bootstrap/Button";
import ChoiceRow from "../helpers/ChoiceRow";
import { addNewChoiceCallback, onChangeChoiceCallback, onPasteChoiceCallback } from "./helpers";

export default ({ choices, setChoices, hidden, submitted, ...opts }) => {
  const [newChoice, setNewChoice] = useState(false);
  const onChangeChoice = onChangeChoiceCallback(choices, setChoices);
  const addNewChoice = addNewChoiceCallback(setNewChoice, choices, setChoices);
  const onPasteChoice = onPasteChoiceCallback(choices, setChoices, setNewChoice, onChangeChoice);

  return <div className="w-100 mt-3" hidden={hidden}>
    <h3>Choices</h3>
    {
      choices.map(({ slug, ...hash }) =>
        <ChoiceRow key={`rowDecisionChoice-${slug}`} {...{ ...opts, ...hash, slug, submitted, newChoice, onChangeChoice, setNewChoice, onPasteChoice }} />
      )
    }
    <Button onClick={addNewChoice} disabled={submitted} className="btn-secondary w-100">Add another choice</Button>
  </div>
}
