import React from 'react';
import { connect } from "react-redux";
import { setHelpPanelOpen } from "../../store/sidebar/actions";

export const Help = ({ sidebar, openSideBar, addClass = '' }) => {
  return <button className={`btn btn-primary btn-sm btn-sm-round ${addClass}`} onClick={() => { openSideBar(!sidebar.helpSidebar) }}>
    <i className="fas fa-question text-white h3" />
  </button>;
}
const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setHelpPanelOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Help);
