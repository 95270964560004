import React from 'react';
import {Image, View, Text, Link} from '@react-pdf/renderer';
import styles from "../../styles/common";
import imageStyles from "../../styles/images";
import { protectedImageSrc } from "./UserImage";

const DocumentFooter = ({ org }) =>
  <View fixed style={[styles.footer]}>
    <View style={[styles.row, {width: '100%'}]}>
      <View style={[{width: '40%'}]}>
        <Image style={[imageStyles.footer_image]} src={protectedImageSrc(org.logo_horizontal)} />
      </View>
      <View style={[{width: '20%'}]}>
        <Text style={[styles.pageNumber, {marginHorizontal: 'auto', marginTop: 'auto'}]}
              render={({ pageNumber, _totalPages }) => `${pageNumber - 1}`} fixed />
      </View>
      <View style={[{width: '40%'}]}>
        <Text style={[styles.pageNumber, {marginLeft: 'auto', marginTop: 'auto'}]}>www.cloverpop.com</Text>
      </View>
    </View>
  </View>

export default DocumentFooter;
