import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { submitCategories } from "../../store/template/actions";
import CategoriesForm from "../../decision_categories/CategoriesForm";
import { onChangeCategoryCallback } from "../../tree_wizard/steps_wizard/steps/TreeDecisionCategorizationStep";
import { submitCategoriesData } from "../../tree_view/modals/DecisionCategoriesModal";
import { updateTemplateNotes } from "../../store/playbook_notes/actions";
import { ModalBodyWithClose, ModalDoneFooterP0 } from "../../common/modals";
import SelectCategory from "../../tree_wizard/helpers/SelectCategory";
import { categorizationNotesState } from "../../common/CategorizationNotesRow";
import { fetchDefaultSortedSelectedCategories, selectCategoryState } from "../../helpers/categories_helpers";

export const TemplateCategoriesModal = ({
                                          template, playbook_notes, org_categories,
                                          shown, onClose = () => {},
                                          submitCategories, updatePlaybook
                                       }) => {
  const [categoriesData, setCategoriesData] = useState(template.category_options)
  const [submitState, setSubmitState] = useState(false)

  const {
    selectedCategories, setSelectedCategories, onSelectCategory, ...hash
  } = selectCategoryState({
    selected_categories: template.selected_categories, org_categories, categoriesData, setCategoriesData,
  })
  const { categorizationNotes, categorizationNotesRow } = categorizationNotesState(playbook_notes)
  useEffect(() => {
    if (shown) resetData()
  }, [shown])

  const resetData = () => {
    setCategoriesData(template.category_options)
    setSelectedCategories(
      fetchDefaultSortedSelectedCategories(org_categories, template.selected_categories)
    )
  }
  const reset = () => {
    resetData()
    onClose()
  }
  const submitData = () => {
    submitCategoriesData({
      categories: categoriesData,
      selected_categories: selectedCategories
    }, submitCategories, setSubmitState, onClose)
    updatePlaybook({ categorization: categorizationNotes })
  }
  const onChangeCategory = onChangeCategoryCallback(categoriesData, setCategoriesData)

  return (
    <Modal enforceFocus={false} size="md" backdrop="static" show={shown} onHide={reset}>
      <ModalBodyWithClose bsPrefix="categories-modal" onClose={reset} header="Categories">
        <SelectCategory selected={selectedCategories}
                        onSelect={onSelectCategory}
                        submitState={submitState}
        />
        <CategoriesForm className={'w-100 mb-3'}
                        {...{
                          ...hash,
                          categoriesData, submitState, selectedCategories, onChangeCategory,
                          requireInput: true,
                          categorizationNotesRow
                        }}
        />
      </ModalBodyWithClose>
      <ModalDoneFooterP0 disabled={submitState} onClose={submitData} />
    </Modal>
  );
}
const mapStateToProps = ({ template, playbook_notes, org_categories }) => ({ template, playbook_notes, org_categories });
const mapDispatchToProps = (dispatch) => ({
  submitCategories: (data, callback) => {
    dispatch(submitCategories(data, callback))
  },
  updatePlaybook: (data) => {
    dispatch(updateTemplateNotes(data))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateCategoriesModal);
