import React, {useMemo} from 'react';
import { connect } from "react-redux";
import { updateRecordDecision } from "../../../store/sidebar/actions";
import {isBlank} from "../../../helpers/common";
import { JoditTextEditor } from '../../../common/JoditTextEditor';

const RationaleInput = ({ decision, updateRecordDecision, sidebar }) => {
  if (isBlank(decision)) return null;
  const onChangeReasons = (reasons) => { updateRecordDecision({ final_decision_reasons: reasons }); };

  return <div className="mb-3 px-3">
    <h3>Rationale</h3>
    <JoditTextEditor className="jodit-container-default"
                     value={decision.final_decision_reasons}
                     key={`decision-rationale-${sidebar.rationaleTimeStamp}`}
                     placeholder="Enter a detailed explanation"
                     setValue={onChangeReasons}
                     autofocus={false} />
  </div>
};

const mapStateToProps = (state) => ({ decision: state.sidebar.decision, sidebar: state.sidebar});
export default connect(mapStateToProps, { updateRecordDecision })(RationaleInput);
