import React, {useMemo, useCallback, useEffect} from 'react';
import { Accordion, Card } from 'react-bootstrap'
import CategoryOptionsTypehead from "../../../common/CategoryOptionsTypehead";
import ContextAwareToggle from "./ContextAwareToggle";
import {isBlank, isPresent} from "../../../helpers/common";
import SearchIcon from "./SearchIcon";
import { setFilterAutofocus } from "../../../helpers/filter_helpers";

const fetchFilterTextArray = (category, selectedCategoryOptions) => {
  if (isPresent(category)) {
    const selectedCategoryOptionsData = selectedCategoryOptions.find(optionData => optionData.category.slug === category.slug);
    return selectedCategoryOptionsData?.options?.map((o) => o.description)
  }
  return [];
}

export default ({
                  category, selectedCategoryOptions, setSelectedCategoryOptions,
                  eventKey, submit,
                  openedSection, setOpenedSection
                }) => {
  const setAutofocus = useCallback(() => setFilterAutofocus(setOpenedSection, category.name), [category.name])

  const setCategoryOptions = (category, options) => {
    const newSelectedOptionsArray = options.length > 0 
        ? selectedCategoryOptions.filter((option) => option.category.slug !== category.slug).concat([{ category, options }])
        : selectedCategoryOptions.filter((option) => option.category.slug !== category.slug);
    setSelectedCategoryOptions(newSelectedOptionsArray);
  };

  const filterTextArray = fetchFilterTextArray(category, selectedCategoryOptions)

  const values = useMemo(() =>
      selectedCategoryOptions.find(optionData => optionData.category.slug === category.slug)?.options || []
    , [selectedCategoryOptions, category.slug])

  return <Card className="shadow">
    <Card.Header className="bg-white border-0 pointer p-0" onClick={setAutofocus}>
      <ContextAwareToggle {...{ eventKey, filterTextArray }}>
        {category.name}
      </ContextAwareToggle>
    </Card.Header>
    <Accordion.Collapse eventKey={eventKey} className={`${isPresent(filterTextArray) ? 'show' : ''}`}>
      <Card.Body className="pt-0 px-3 pb-3">
        <SearchIcon />
        <CategoryOptionsTypehead
          {...{ disabled: submit, values, setValues: setCategoryOptions, category }}
          autoFocus={openedSection === category.name}
          id={`category-options-input-${eventKey}`}
          flip={false}
        />
      </Card.Body>
    </Accordion.Collapse>
  </Card>
}
