import React from 'react';
import { connect } from "react-redux";
import Switcher from "../../../../common/Switcher";
import { isBlank } from "../../../../helpers/common";
import { flow } from "../../../../helpers/decision_flows";

const RateSupportSwitcher = ({ decision, flowEnum, rateSupport, setRateSupport }) => {
  if (isBlank(decision)) return null;

  if(flowEnum === flow.D_SIGHT_RECOMMENDATION) return null

  return <div className="mb-3 pb-1 mt-2">
    <Switcher label={'Allow collaborators to rate their level of support for what was decided.'}
              value={rateSupport} onChange={(checked) => setRateSupport(checked)} />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps)(RateSupportSwitcher);
