import React from 'react';
import ChoicesSection from "./ChoicesSection";
import SelectedChoiceEntryType from "./SelectedChoiceEntryType";
import DecisionHeader from "../helpers/DecisionHeader";

export default (opts) =>
  <div className="mb-2 px-3">
    <DecisionHeader {...opts} classNames={'mb-1'} descriptionClassNames={'m-0'}/>
    <SelectedChoiceEntryType />
    <ChoicesSection {...opts}/>
  </div>
