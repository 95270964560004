import React, { useState, useMemo, useEffect } from 'react';
import { isBlank, isPresent } from "../../../../helpers/common";
import {
  getLastSurvey
} from "../../../../models/d_sight/metric_chart_helpers";
import FilteredChartPlaceholder from "./FilteredChartPlaceholder";
import { filtersSurveysBySubMetrics } from "../helpers";
import LineChart from "./LineChart";
import BarChart from './components/NpsBarChart'
import { prepareNpsMetricsData } from "./nps/helpers";

export default ({
                  d_sight_goal, metric, chartData,
                  addAvailableOptions,
                  timeNode,
                  ...opts
                }) => {
  if (isBlank(chartData) || chartData.metric_id !== metric.id) return null;

  const surveys = filtersSurveysBySubMetrics(d_sight_goal, timeNode, chartData.metrics)
  const [selectedSurvey, setSelectedSurvey] = useState(getLastSurvey(surveys))
  useEffect(() => setSelectedSurvey(getLastSurvey(surveys)), [timeNode])

  const {
    sortedNodeNames, subMetrics, npsMetrics
  } = useMemo(() => prepareNpsMetricsData({
    d_sight_goal, chartData, metric, selectedSurvey, addAvailableOptions
  }), [d_sight_goal, chartData.metrics.length, metric, selectedSurvey])

  if (!subMetrics.some(({ process_data }) => isPresent(process_data))) return <FilteredChartPlaceholder/>

  return <>
    <LineChart {...{ ...opts, d_sight_goal, chartData, metric, addAvailableOptions, timeNode }} />
    <BarChart {...{
      ...opts,
      selectedSurvey, setSelectedSurvey, surveys,
      metric, chartData, d_sight_goal,
      categories: sortedNodeNames,
      data: npsMetrics
    }} />
  </>;
}
