export const Y_AXIS_LABEL = {
  style: {
    fontSize: '0.75rem',
    color: '#1F2F3F'
  }
}
export const Y_AXIS = {
  gridLineColor: '#DCE8F3'
}
export const X_AXIS = {
  crosshair: {
    snap: true
  },
  labels: {
    style: {
      fontSize: '0.75rem',
      color: '#1F2F3F'
    }
  }
}
export const TOOLTIP = {
  headerFormat: '<span style="font-size:12px">{point.key}</span><hr/><table>',
  footerFormat: '</table>',
  shared: true,
  split: false,
  style: {
    color: '#1F2F3F',
  },
  useHTML: true
}

export const fetchDataForCharts = (scenario, {  }) => {

}
