import React from 'react';
import { isBlank, isPresent, qSortArray } from "../../../../../helpers/common";
import { fetchDynamicChainsNodeNames } from "../helpers/table_helpers";
import TableMetric from "../components/TableMetric";

export default ({
                  d_sight_goal, chartData, timeNode, selectedSurvey, metric,
                  header, footer,
                  ...opts
                }) => {
  const rows = qSortArray(
    chartData.dynamic_chains.map(({ answer }) => answer),
    true, a => a)

  if (isBlank(rows)) return null;

  const sortedNodeNames = fetchDynamicChainsNodeNames(chartData, d_sight_goal, metric)

  const subChainsWIthExtData = chartData.dynamic_chains.filter(dc => isPresent(dc.extended_data))

  return <div className={`bg-white rounded p-3 mb-3`}>
    {header()}
    <div className={`answers-table-max-h overflow-auto`}>
      <TableMetric {...{
        ...opts,
        d_sight_goal, chartData, timeNode, selectedSurvey,
        rows, sortedNodeNames
      }} />
    </div>
    {footer()}
  </div>
}
