import { isPresent } from "../../helpers/common";
import { parseRowCellId, rowCellIdKey, extractDriverName } from "./common";
import { EDITABLE_COMPARISON_HEADERS, NOT_EDITABLE_COMPARISON_HEADERS, SCENARIO_ROW_ID_SEPARATOR } from "./ag_grid_vars";

const TEXT_COLOR = '#2477B2';
const EDITABLE_BACKGROUND_COLOR = '#E5F5FF';
const EDITABLE_CELL_STYLES = { backgroundColor: EDITABLE_BACKGROUND_COLOR };
const EDITED_FACT_CELL_STYLES = { backgroundColor: EDITABLE_BACKGROUND_COLOR, fontStyle: 'italic', color: TEXT_COLOR };
const REQUIRED_RUN_MODEL_CELL_STYLES = { backgroundColor: '#FBF5D8', fontStyle: 'italic', color: TEXT_COLOR };
const UPDATED_CELL_STYLES = { backgroundColor: '#D7F6ED', fontStyle: 'italic', color: TEXT_COLOR };
const isUpdatedCell = (params, updatedCellsIds, config) => {
  const outerPeriodId = params.colDef.colId;
  return updatedCellsIds.includes(rowCellIdKey(params)) ||
    updatedCellsIds.some(updatedCellsId => {
      const [rowId, innerPeriodId] = updatedCellsId.split(SCENARIO_ROW_ID_SEPARATOR);
      return params.node.id === rowId && config.isPeriodInOuterPeriod(Number(innerPeriodId), outerPeriodId);
    });
}

const isUpdatedFactTotalCell = (forecastScenario, params, config, timeScale, editedCellsIds, editedCell) => {
  return isEditableFactTotalCell(forecastScenario, params, config, timeScale) && editedCell
}

const isNotEditableFactHeader = (cellFactHeader) => NOT_EDITABLE_COMPARISON_HEADERS.includes(cellFactHeader);

const isEditableFactHeaders = (cellFactHeader) => EDITABLE_COMPARISON_HEADERS.includes(cellFactHeader);

const isEditableFactCell = (params, config, timeScale) => {
  if(isNotEditableFactHeader(params.value)) return false;

  const subFactHeader = params.data?.sub_fact || params.node?.data?.sub_fact;
  if(isNotEditableFactHeader(subFactHeader)) return false;

  const factHeader = extractDriverName(params.data?.Facts || params.node?.data?.Facts);
  return config.isEditableDriver(timeScale,  extractDriverName(params.node.key) || factHeader)
}

const isEditableFactTotalCell = (forecastScenario, params, config, timeScale) => {
  if(isNotEditableFactHeader(params.value)) return false;

  const subFactHeader = params.data?.sub_fact || params.node?.data?.sub_fact;
  if(isNotEditableFactHeader(subFactHeader)) return false;

  if(forecastScenario.isEditableTimeScale) {
    return config.isEditableDriver(timeScale, extractDriverName(params.node.key))
  } else {
    return true
  }
}

export const isEditableCell = (params, config, timeScale) => {
  if(isNotEditableFactHeader(params.value)) return false;

  const subFactHeader = params.data?.sub_fact || params.node?.data?.sub_fact;
  if(isNotEditableFactHeader(subFactHeader)) return false;

  const factHeader = extractDriverName(params.data?.Facts || params.node?.data?.Facts);
  return (config.isEditableDriver(timeScale,  factHeader) ||
    (isEditableFactHeaders(params.value) && config.isEditableDriver(timeScale,  extractDriverName(params.node?.parent?.key))) ||
    (isEditableFactHeaders(subFactHeader) && config.isEditableDriver(timeScale, factHeader)))
}

const isRequiredRunModel = (params, runModelCellsIds, config) => {
  const outerPeriodId = params.colDef.colId;
  return runModelCellsIds.includes(rowCellIdKey(params)) ||
    runModelCellsIds.some(runModelCellsId => {
      const [rowId, innerPeriodId] = runModelCellsId.split(SCENARIO_ROW_ID_SEPARATOR);
      return params.node.id === rowId && config.isPeriodInOuterPeriod(Number(innerPeriodId), outerPeriodId);
    });
}

const isEditedCell = (params, editedCellsIds, config) =>
  params.node.allLeafChildren?.some(child =>
    editedCellsIds.includes(rowCellIdKey({ node: child, colDef: params.colDef })) ||
    editedCellsIds.some(editedCellsId => {
      const { periodId } = parseRowCellId(editedCellsId)
      return editedCellsId.includes(child.id) && config.isPeriodInOuterPeriod(Number(periodId), params.colDef.colId);
    })
  ) || (isPresent(editedCellsIds) && editedCellsIds.includes(rowCellIdKey(params)))


const isEditedFactCell = (params, editedCellsRowIds) =>
  params.node.allLeafChildren?.some(child => editedCellsRowIds.includes(child.id)) ||
  editedCellsRowIds.includes(params.node.id)

export const styledPeriodColumn = (forecastScenario, params, editedCells, editedCellsIds, updatedCellsIds, config, timeScale, runModelCellsIds) => {
  if (isUpdatedCell(params, updatedCellsIds, config)) {
    return UPDATED_CELL_STYLES;
  }
  if (isRequiredRunModel(params, runModelCellsIds, config)) {
    return REQUIRED_RUN_MODEL_CELL_STYLES;
  }
  const editedCell = isEditedCell(params, editedCellsIds, config);
  if (isUpdatedFactTotalCell(forecastScenario, params, config, timeScale, editedCellsIds, editedCell)) {
    return UPDATED_CELL_STYLES;
  }
  if (isEditableCell(params, config, timeScale) && editedCell) {
    return EDITED_FACT_CELL_STYLES;
  }
  if (isEditableCell(params, config, timeScale)) {
    return EDITABLE_CELL_STYLES;
  }
  if (!isEditableCell(params, config, timeScale) && editedCell) {
    return EDITED_FACT_CELL_STYLES;
  }
}

export const styledFactsColumn = (params, editedCellsRowIds, config, timeScale) => {
  if (isEditableFactCell(params, config, timeScale) && isEditedFactCell(params, editedCellsRowIds)) {
    return EDITED_FACT_CELL_STYLES;
  }
  if (isEditableFactCell(params, config, timeScale)) {
    return EDITABLE_CELL_STYLES;
  }
}
