import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Select from 'react-select';
import { BorderedFilterStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import { ForecastCoefficientColumn } from "../../../models/forecast/ForecastColumn";
import { isBlank, isPresent } from "../../../helpers/common";

const optionLabel = (fact) => {
  const name = [fact.name, fact.measure].filter(isPresent).join(', ')
  return `${name} (${fact instanceof ForecastCoefficientColumn ? 'Market' : 'Product' } driver)`
}

// NOTE: adjust this function to include coefficients
const initOptions = (config, forecastScenario) => {
  return config.driversColumns.filter(column =>
    column.isViewInTable && column.visibleFor(forecastScenario.timeScale)
  ).map(fact =>
    ({
      label: optionLabel(fact),
      value: fact.name,
      id: fact.id,
      measure: fact.measure,
      decimal: fact.decimal
    })
  )
}

const FactToUpdate = ({ sidebar, config, forecastScenario, setFactToUpdate }) => {
  const options = initOptions(config, forecastScenario);
  const defaultOption = options.find(option => option.id === Number(sidebar.largeScaleInputSelectedDriver));
  useEffect(() => {
    if(isBlank(defaultOption)) {
      setFactToUpdate(options[0])
    }
  }, [defaultOption])
  return <div className="mt-3 mb-0">
    <h3>Fact to update</h3>
    <Select
      defaultValue={defaultOption || options[0]}
      onChange={setFactToUpdate}
      components={{ IndicatorSeparator: () => null }}
      styles={BorderedFilterStyle}
      placeholder="Make a selection"
      options={options} />
  </div>
};
const mapStateToProps = ({ sidebar }) => ({ sidebar });
export default connect(mapStateToProps)(FactToUpdate);
