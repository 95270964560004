import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from "highcharts/highcharts-more.js"
import ChartSourcesLabels from "../ChartSourcesLabels";
import { TimePeriodDropdown } from "../TimePeriodDropdown";
import {
  BASE_CHARTS_OPTIONS, basesChartHash,
  chartBasesValueFor,
  chartValueFor,
  hashWithSignificance, POINT_HEADER, POINT_SAMPLES_ROW, POINT_SIGNS_ROW, SERIES_NAME_ROW
} from "../../../../../models/d_sight/metric_chart_helpers";
import { NPS_COLORS } from "../nps/helpers";
import { significanceHashData } from "../brand/helpers";

highchartsMore(Highcharts);

const BASE_BAR_CHART_OPTIONS = {
  ...BASE_CHARTS_OPTIONS,
  chart: {
    type: 'bar',
    spacing: [5, 0, 0, 5],
    style: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },

  colors: NPS_COLORS,

  yAxis: {
    min: 0,
    max: 100,
    tickAmount: 5,
    title: {
      text: ''
    },
    labels: {
      style: {
        fontSize: '0.75rem',
        color: '#1F2F3F'
      }
    },
    gridLineColor: '#DCE8F3'
  },

  legend: {
    reversed: true,
    itemStyle: {
      color: '#1F2F3F',
      fontWeight: '500',
      fontSize: '0.875rem'
    }
  },

  tooltip: {
    headerFormat: `${POINT_HEADER}<table>`,
    pointFormat: `<tr>${SERIES_NAME_ROW}${POINT_SIGNS_ROW}${POINT_SAMPLES_ROW}</tr>`,
    footerFormat: '</table>',
    shared: true,
    style: { color: '#1F2F3F' },
    useHTML: true
  },

  plotOptions: {
    series: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
        style: {
          fontWeight: '500',
          fontSize: '0.875rem'
        },
        format: '{point.y:.1f}{series.options.custom.measure}{point.sigUp}{point.sigDown}'
      }
    }
  }
}

const dataBarRowWithSignificance = (lastSurvey, { metric, process_data }) => {
  const mainBrandData = process_data[0];
  return {
    name: metric.name,
    custom: { measure: metric.measure },
    data: process_data.map(row => {
      const result = {
        y: chartValueFor(lastSurvey, row, metric),
        ...basesChartHash(chartBasesValueFor(lastSurvey, row))
      }
      if (row === mainBrandData) return { ...result, sigUp: '', sigDown: '' };

      return hashWithSignificance(result, significanceHashData(lastSurvey, row, mainBrandData, metric))
    })
  }
}

export default ({ d_sight_goal, metric, selectedSurvey, categories, data, sourceLabels, chartData, ...opts }) => {
  const chartOptions = {
    ...BASE_BAR_CHART_OPTIONS,

    xAxis: {
      categories: categories.map(node_id => d_sight_goal.nodes.find(hash => hash.id === node_id)?.display_name),
      labels: {
        style: {
          fontSize: '0.75rem',
          color: '#1F2F3F'
        }
      }
    },

    series: data.map((row) => dataBarRowWithSignificance(selectedSurvey, row))
  };

  return <div className="row mb-3">
    <div className="col">
      <div className={'h-100 bg-white rounded p-3'}>
        <TimePeriodDropdown {...{ ...opts, selectedSurvey }} header={'Vs. competitors'} />
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        {
          sourceLabels ? <ChartSourcesLabels chartData={chartData} surveys={[selectedSurvey]} /> : null
        }
      </div>
    </div>
  </div>
}
