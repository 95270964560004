import React from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {
  DSightAnalysisAnswerBlock,
  DSightAnalysisHeaderLink,
  DSightAnalysisInsightsBlock
} from "../../../tree_view/modals/DriverShowModal";
import Analysis from "../../../models/d_sight/Analysis";
import { isBlank } from "../../../helpers/common";
import { HowToNotesSection, TitleNotesSection } from "../../../tree_view/modals/PreviewDecisionDetailsModal";
import { ModalDoneFooter } from "../../../common/modals";
import ModalHeaderTitle from "../../../common/ModalHeaderTitle";

export const openDSightDecisionModal = ({ openModal, template }) => openModal({ decision: template, drivers: [], slug: template.slug, type: 'DSightDecisionModal' })
export const showDSightDecisionModal = (modal) => modal.type === "DSightDecisionModal" && modal.shown

const DSightDecisionModal = ({
                               template, shown, scope = 'decisions',
                               playbook_notes,
                               onClose
                            }) => {
  if(isBlank(template)) return null;

  const insightsData = new Analysis(template.d_sight).insightData

  return <Modal size="lg" backdrop="static" show={shown} onHide={onClose}>
    <Modal.Body>
      <ModalHeaderTitle {...{ onClose, title: 'Decision' }} />
      <h3>{template.description}</h3>
      <TitleNotesSection playbook_notes={playbook_notes} />
      <HowToNotesSection playbook_notes={playbook_notes} />
      <div className="mt-3">
        <DSightAnalysisHeaderLink linkTo={`/dsight/${scope}/${template.slug}/analysis`} containerClass={'mb-2'} headerClass={'h3'} />
        <DSightAnalysisAnswerBlock insightsData={insightsData} />
        <DSightAnalysisInsightsBlock insightsData={insightsData} keyPreffix={'dsight-decision-modal'} decision={template} />
      </div>
    </Modal.Body>
    <ModalDoneFooter onClose={onClose} />
  </Modal>
}
const mapStateToProps = ({ modal, playbook_notes }) => ({
  modal, template: modal.decision, playbook_notes
})
export default connect(mapStateToProps)(DSightDecisionModal);
