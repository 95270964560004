import React, {Fragment, useEffect} from 'react'
import {checkLoadingEffect, isStoreLoading} from "../helpers/callbacks_helpers";
import {Loader} from "../common/Loader";
import {isPresent} from "../helpers/common";
import TreeHeader from "./header";
import {useHistory} from "react-router";
import {updateEntryPointData} from "../EntryPoint";
import {loadLegacyDecision} from "../store/decision/actions";
import {loadOrgCategories} from "../store/org_categories/actions";
import {
  setDecisionSidebarOpen
} from "../store/sidebar/actions";
import {closeModal, openModal} from "../store/modals/actions";
import {loadContacts} from "../store/contacts/actions";
import {connect} from "react-redux";
import AlertsSection from "../alerts";
import HelpPanel from "./side_panel/HelpPanel";
import DecisionDetailsPanel from "./side_panel/DecisionDetailsPanel";
import Body from "./body"
import DetailsItem from "../tree_view/navigation/DetailsItem";

export const Sidebars = () =>
  <Fragment>
    <div className="sidebars">
      <DecisionDetailsPanel key="decision-details-side-panel"/>
      {/*<div className="help-side-panels">*/}
        {/*<HelpPanel/>*/}
      {/*</div>*/}
    </div>
  </Fragment>

export const Wrapper = ({ children, modal, closeModal, ...opts }) =>
  <Fragment>
    <AlertsSection/>
    <TreeHeader/>
    <div className="d-flex vertical-tree-container">
      <div id="sidebar-navigation" className="tree-sidebar bg-light-gray d-none d-lg-block">
        <div className="sidebar-top">
          <ul className="list-unstyled m-0 p-3">
            <DetailsItem />
          </ul>
        </div>
      </div>
      {children}
    </div>
    <Sidebars {...opts} />
  </Fragment>

const DecisionState = ({
                        modal, decision, match, closeModal,contactsData, loadContacts, loadLegacyDecision,
                      }) => {
  checkLoadingEffect(contactsData, loadContacts)
  checkLoadingEffect(decision, loadLegacyDecision)

  useEffect(() => {
    updateEntryPointData(match, 'decisions')
  }, [match?.params?.id, decision])

  if (isStoreLoading(decision)) {
    return <Wrapper {...{ modal, closeModal }}>
      <Loader/>
    </Wrapper>;
  } else {
    return <Wrapper {...{ modal, closeModal, showDecisionSet: isPresent(decision.decision_set_slug) }}>
      <Body />
    </Wrapper>;
  }
};
export const mapStateToProps = ({ modal, tree, org_categories, decision, current_user, signIn, org_driver_types, decision_set, contacts }) => ({
  modal, tree, org_categories, decision, current_user, signIn, org_driver_types, decision_set, contactsData: contacts
});
export const mapDispatchToProps = (dispatch) => ({
  loadLegacyDecision: (callback) => { dispatch(loadLegacyDecision(callback)); },

  loadOrgCategories: (data = {}) => {
    dispatch(loadOrgCategories(data));
  },
  openDecisionDetailsSideBar: () => {
    dispatch(setDecisionSidebarOpen(true));
  },
  openModal: (data = {}) => {
    dispatch(openModal(data));
  },
  loadContacts: () => dispatch(loadContacts()),
  closeModal: () => { dispatch(closeModal()) }
});

export default connect(mapStateToProps, mapDispatchToProps)(DecisionState);