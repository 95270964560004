import React from "react";
import { isFuturePeriod } from "../../models/forecast/ForecastScenario";
import { isBlank, isPresent } from "../../helpers/common";
import FactsCellRenderer from "./FactsCellRenderer";
import { extractDriverName, findDriverColumn } from "./common";
import { isEditableCell } from "./ag_grid_cell_style";
import { validatedResult } from "./ag_grid_formulas";
import {
  FACTS_HEADER,
  HIDDEN_COLUMNS,
  ROW_DRIVER_ID_KEY,
  BENCHMARK_HEADER,
  SIMULATED_FACT_HEADER,
  GROUP_COL_ID_SUFFIX
} from "./ag_grid_vars";

const VALUE_SALES_FACT = 'Value Sales';
const BASE_HEADER_OPTIONS = {
  sortable: true,
  filter: true,
  resizable: true
}
const CHOOSE_COLUMN_ITEM = 'columnChooser';
const ROW_GROUP_MENU_ITEM = 'rowGroup';
const ROW_UNGROUP_MENU_ITEM = 'rowUnGroup';
const EXPAND_ALL_MENU_ITEM = 'expandAll';
const CONTRACT_ALL_MENU_ITEM = 'contractAll';
const EXCLUDE_MENU_ITEMS = [
  CHOOSE_COLUMN_ITEM, ROW_GROUP_MENU_ITEM, ROW_UNGROUP_MENU_ITEM
];

export const timePeriodNameParse = (name) => {
  const splitNameArray = name.split('_');
  if (splitNameArray.length !== 2) return name;

  return splitNameArray.reverse().join(' ');
}


export const valueFormatter = (value, { metric = null, decimal = 2 }, space = '') => {
  const numericValue = typeof value === 'number' ? value : parseFloat(value);
  const roundedValue = numericValue.toFixed(decimal);
  switch (metric) {
    case '$':
      return `$${roundedValue}`
    default:
      return isBlank(metric) && isBlank(space) ? roundedValue : [roundedValue, metric].filter(isPresent).join(space)
  }
};

const parseComparisonRow = (params, forecastScenario, config) => {
  if (params.node.data.sub_fact.includes(BENCHMARK_HEADER)) {
    return parseRow(params.node.data[ROW_DRIVER_ID_KEY], forecastScenario, config);
  } else {
    return { decimal: 1 };
  }
}

const parseRow = (rowDriverId, forecastScenario, config) => {
  const driverColumn = findDriverColumn(rowDriverId, forecastScenario, config);
  return { decimal: driverColumn.decimal }
}

const parseFormatData = (params, forecastScenario, config) => {
  try {
    if (isPresent(params.node?.data?.sub_fact) && params.node.data.sub_fact !== SIMULATED_FACT_HEADER) return parseComparisonRow(params, forecastScenario, config);
    const rowDriverId = params.node?.data?.rowDriverId || params.rowNode.allLeafChildren[0].data.rowDriverId;

    return parseRow(rowDriverId, forecastScenario, config);
  } catch (e) {
    console.error('Error while parsing format data', e)
  }
  return { decimal: 0 };
}

export const valueSalesSum = (params, forecastScenario, config) => {
  if(params.rowNode.leafGroup) return {
    sum: params.values[0],
    toString: () => valueFormatter(params.values[0], parseFormatData(params, forecastScenario, config))
  };
  let sum = 0;
  // We do not want to include other Facts that Value Sales into aggregation
  const values = params.rowNode.allLeafChildren?.filter(leaf =>
    extractDriverName(leaf.data.Facts) === VALUE_SALES_FACT && (isBlank(leaf.data.sub_fact) || leaf.data.sub_fact === SIMULATED_FACT_HEADER));
  values.forEach((data) => {
    const value = data.data[params.colDef.field]
    const groupNode = value !== null && value !== undefined && typeof value === 'object';
    if (groupNode) {
      // we are aggregating groups, so we take the
      // aggregated values to calculated a weighted average
      sum += value.sum;
    } else {
      // skip values that are not numbers (ie skip empty values)
      if (typeof value === 'number') {
        sum += value;
      }
    }
  });
  // the result will be an object. when this cell is rendered, only the avg is shown.
  // however when this cell is part of another aggregation, the count is also needed
  // to create a weighted average for the next level.
  return {
    sum: sum,
    toString: () => valueFormatter(sum, parseFormatData(params, forecastScenario))
  };
}

const factsMenuItems = (params) => {
  const menuItems = [];
  const excludeItems = [...EXCLUDE_MENU_ITEMS, EXPAND_ALL_MENU_ITEM, CONTRACT_ALL_MENU_ITEM];
  params.defaultItems.forEach((item) => {
    if (excludeItems.indexOf(item) < 0) {
      menuItems.push(item);
    }
  });
  return menuItems;
}

const customMenuItems = (params) => {
  console.log(params.defaultItems);
  return params.defaultItems.filter(item => !EXCLUDE_MENU_ITEMS.includes(item)).map(item => {
    if ([EXPAND_ALL_MENU_ITEM, CONTRACT_ALL_MENU_ITEM].includes(item)) {
      const isExpanded = item === EXPAND_ALL_MENU_ITEM;
      const name = isExpanded ? 'Expand All Rows Groups' : 'Collapse All Rows Groups';
      return {
        name,
        action: () => {
          params.api.forEachNode(node => {
            if (node.field === params.column.colDef.headerName) {
              params.api.setRowNodeExpanded(node, isExpanded);
            }
          });
        }
      };
    }
    return item;
  });
}

const groupedColDefs = ({ forecastScenario, config, forecastBenchmarkScenario }) => {
  const colDefs = forecastScenario.groupFields.map(groupField =>
    ({
      headerName: groupField,
      showRowGroup: groupField,
      colId: `${groupField}-${GROUP_COL_ID_SUFFIX}`,
      cellRenderer: 'agGroupCellRenderer',
      filter: 'agGroupColumnFilter',
      cellRendererParams: { suppressCount: true },
      width: 170,
      sort: 'asc',
      mainMenuItems: customMenuItems
    })
  )
  colDefs.push(
    {
      headerName: 'Facts',
      colId: `Facts-${GROUP_COL_ID_SUFFIX}`,
      minWidth: 250,
      showRowGroup: 'Facts',
      filter: 'agGroupColumnFilter',
      cellRenderer: (params) => <FactsCellRenderer {...{ params, config, forecastScenario, forecastBenchmarkScenario } } />,
      cellRendererParams: { suppressCount: true },
      type: 'styledFactsColumn',
      aggFunc: 'first',
      valueGetter: (params) => {
        if (params.data) {
          return params.data.sub_fact;
        }
      },
      mainMenuItems: factsMenuItems
    }
  )
  return colDefs;
}

export const getPeriodId = (periods = [], field = '') => periods.find(p => p.name === field).id;

export const preparedColDefs = ({ forecastScenario, timeScale, config, scenariosLoaded, ...opts }) => {
  const periods = forecastScenario.periods();
  return groupedColDefs({ forecastScenario, config, ...opts }).concat(forecastScenario.viewHeaders.map(field => {
      let definitions = { field, ...BASE_HEADER_OPTIONS };
      if (HIDDEN_COLUMNS.includes(field)) {
        definitions.hide = true;
      }
      if (forecastScenario.groupFields.includes(field)) {
        definitions.hide = true;
        definitions.rowGroup = true;
      }
      if(FACTS_HEADER === field) {
        definitions.rowGroup = true;
      }
      if (forecastScenario.periodHeadersNames.includes(field) && isFuturePeriod(timeScale, field)) {
        definitions.editable = (params) =>
          scenariosLoaded &&
          isEditableCell(params, config, timeScale);
        definitions.type = ['editableColumn', 'styledPeriodColumn'];
      }
      if(forecastScenario.periodHeadersNames.includes(field)) {
        definitions.headerName = timePeriodNameParse(field);
        definitions.colId = getPeriodId(periods, field);
        definitions.valueFormatter = (params) => {
          if(isBlank(params.node.data)) return validatedResult(params.value)

          return validatedResult(valueFormatter(params.value, parseFormatData(params, forecastScenario)))
        }
        definitions.cellRenderer = 'agAnimateShowChangeCellRenderer';
        definitions.aggFunc = 'valueSalesSum';
        definitions.filter = 'agNumberColumnFilter';
        definitions.width = 120;
      }
      return definitions;
    }
  )).flat()
}
