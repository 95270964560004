import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CloseIcon from "../../common/CloseIcon";
import { NAME_LIMIT } from '../../models/decision_category'
import { DESCRIPTION_LIMIT } from "../../models/category_option";
import CharCounter from "../../common/CharCounter";
import { resetErrorsEffect } from "../../helpers/callbacks_helpers";

export const DEFAULT_SELECTIONS = ['', '', ''];
export const DEFAULT_SETTINGS = { multiple_selections: false };

export const duplicatedCategoryEntered = (allCategories, name) => {
  return allCategories.some(g => g.name.toLowerCase() === name.trim().toLowerCase())
};

export const AddCategoryModal = ({
                                   shown,
                                   allCategories = [],
                                   onClose = () => {},
                                   onSubmit = () => {}
                                 }) => {
  const focusElement = React.useRef(null);
  useEffect(() => {
    if (shown && focusElement.current) focusElement.current.focus()
  }, [shown, focusElement])

  const [categoryOptions, setCategoryOptions] = useState(DEFAULT_SELECTIONS);
  const [name, setName] = useState('');
  const [submit, setSubmit] = useState(false);
  const [newCategoryOption, setNewCategoryOption] = useState(false);
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);
  const [errors, setErrors] = useState({})
  const [showChar, setShowChar] = useState(false);
  const [showCharSelection, setShowCharSelection] = useState([]);

  resetErrorsEffect(setErrors, {}, name);

  const removeCategoryOption = (index) => {
    $(`#categoryOption-${index}`).removeClass('d-flex').addClass('d-none');
    onChangeCategoryOptionCallback('', index)
  }

  const setDefaultValues = useCallback(() => {
    setCategoryOptions(DEFAULT_SELECTIONS);
    setSettings(DEFAULT_SETTINGS);
    setName('');
  }, []);

  const onChangeCategoryOptionCallback = (value, index) => {
    const newCategoryOptions = [...categoryOptions];
    newCategoryOptions[index] = value
    setCategoryOptions(newCategoryOptions)
  };

  const onSettingsChange = (event) => {
    const newSettings = { ...settings };
    newSettings[event.target.name] = event.target.checked;
    setSettings(newSettings);
  };

  const addNewCategoryOptionCallback = () => {
    setNewCategoryOption(true)
    setCategoryOptions([...categoryOptions, ''])
  };

  const submitAddCategoryModal = () => {
    if (duplicatedCategoryEntered(allCategories, name)) {
      setErrors({'name': 'The category name entered already exists. Please enter a new name.'})
    } else {
      setSubmit(true)
      onSubmit(name, settings, categoryOptions, (success, errors) => {
        setSubmit(false)
        if (success) {
          close();
        } else if (isPresent(errors) && isPresent(Object.keys(errors))) {
          setErrors(errors)
        }
      })
    }
  }

  const close = () => {
    setDefaultValues();
    onClose()
  }

  const anySelections = () => {
    return categoryOptions.some((categoryOption) => isPresent(categoryOption))
  }

  const changeShowCharSelection = (index, value) => {
    let newSelection = [...showCharSelection];
    newSelection[index] = value;

    setShowCharSelection(newSelection);
  }

  return <Modal size="md" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <CloseIcon onClose={close} />
      <h2>Add category</h2>
      <h3>Name</h3>
      <CharCounter show={showChar} field={name} limit={NAME_LIMIT} id={`addBadgePill-${name}`}  />
      <div className="mb-3">
        <input className={`form-control ${errors['name'] ? 'is-invalid' : ''}`}
               placeholder="Enter the name of this category"
               ref={focusElement} value={name}
               autoFocus={shown} disabled={submit} maxLength={NAME_LIMIT}
               onChange={(e) => setName(e.target.value)}
               onBlur={() => setShowChar(false)}
               onFocus={() => setShowChar(true)}
        />
        <span className={`d-block mt-1 ${ errors['name'] ? 'text-danger' : 'hide-text' }`}>
          {errors['name']}
        </span>
      </div>

      <h3>Settings</h3>
      <div className="mb-3 lh-checkbox">
        <input type="checkbox"
               id="multipleSelections-checkbox"
               className="custom-checkbox"
               checked={settings.multiple_selections}
               name="multiple_selections"
               title="Allow multiple selections"
               onChange={onSettingsChange}/>
        <label htmlFor="multipleSelections-checkbox" className="mb-0">Allow multiple selections</label>
      </div>
      <h3>Selections</h3>
      <div className="mb-3">
        {
          categoryOptions.map((name, index) => <div key={`category-option-${index}`}
                                                    id={`categoryOption-${index}`}
                                                    className="d-flex mt-1 align-items-center mb-2">
            <div className="w-100">
              <CharCounter show={showCharSelection[index]} field={name} limit={DESCRIPTION_LIMIT}  />
              <input className="form-control"
                    placeholder="Enter a selection"
                    readOnly={submit}
                    value={name}
                    onChange={(e) => onChangeCategoryOptionCallback(e.target.value, index)}
                    maxLength={DESCRIPTION_LIMIT}
                    onBlur={() => changeShowCharSelection(index, false)}
                    onFocus={() => changeShowCharSelection(index, true)}
                    ref={inputElement => {
                      if (inputElement && newCategoryOption) {
                        inputElement.focus();
                        setNewCategoryOption(false)
                      }
                    }}/>
            </div>
            <span className="btn btn-light btn-sm btn-sm-round text-danger ms-2 px-2" onClick={() => removeCategoryOption(index)}>
              <i className="fas fa-times w-100"/>
            </span>
          </div>)
        }
        <Button onClick={addNewCategoryOptionCallback} disabled={submit} className="btn-secondary w-100">Add another selection</Button>
      </div>
      <Button onClick={submitAddCategoryModal} className="w-100 mt-3" disabled={isBlank(name) || !anySelections() || submit}>
        Create category
      </Button>
    </Modal.Body>
  </Modal>
}
const mapStateToProps = ({}) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AddCategoryModal);
