import React, {useMemo, useState} from "react";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import {isBlank} from "../../helpers/common";
import { copyDriver } from "../../store/tree/actions";
import { updateTreeData } from "../../store/tree/common_actions";
import { closeModal, openModal, resetModal } from "../../store/modals/actions";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {MIN_DRIVERS_FOR_GPT_REQUEST} from "../../helpers/drivers_helpers";
import Tooltip from "react-bootstrap/Tooltip";
import GptSuggestionsModal from "../../tree_view/modals/GptSuggestionsModal";
import { stepWithDrivers } from "../../helpers/wizard_helpers";

export const onCopyDriver = (tree, updateTreeData) => updateTreeData({ copied_ztree_node: tree.selected_ztree_node });
export const disableCopyBtn = (tree) => isBlank(tree.selected_ztree_node) || tree.selected_ztree_node.isDecision
export const resetCopyPaste = (updateTreeData) => updateTreeData({ copied_ztree_node: {}, selected_ztree_node: {} })

export const ActionButtons = ({ tree, onAddDriver, onPasteDriver, updateTreeData, isHistoricalDecision = false,
                                drivers, setShowGptSuggestionModal, isWizard = false, isTemplate = false, current_org,
                                saveWizardStepData = () => {}, wizard = {}, saveTemplateWizardStepData = () => {},
                                setIsPastDriver = () => {}, isPastDriver = false}) => {
  const tooltip = <Tooltip>Please add two or more top-level drivers to provide context</Tooltip>
  const isMinDriversForGptRequest = drivers.length > MIN_DRIVERS_FOR_GPT_REQUEST

  const showSuggestionModal = () => {
    if (!isMinDriversForGptRequest) return;
    if (isBlank(wizard) || !isPastDriver) return setShowGptSuggestionModal(true)

    const saveData = (data, callback) => {
      const saveFunction = isTemplate ? saveTemplateWizardStepData : saveWizardStepData;
      setIsPastDriver(false)
      saveFunction(stepWithDrivers(wizard), data, callback);
    };

    saveData({ drivers, finish_later: true }, success => {
      success && setShowGptSuggestionModal(true);
    });
  };


  return <div className="row">
    <div className="col mw-fit-content mb-2 p-0">
      <Button className="btn-secondary w-100" onClick={onAddDriver}>Add a driver</Button>
    </div>
    <div className="col-12 col-md-auto mw-fit-content mb-2 p-0 px-md-2" hidden={isHistoricalDecision}>
      <Button className="btn-secondary w-100" disabled={disableCopyBtn(tree)} onClick={() => onCopyDriver(tree, updateTreeData)}>Copy</Button>
    </div>
    <div className="col-12 col-md-auto mw-fit-content mb-2 p-0" hidden={isHistoricalDecision}>
      <Button className="btn-secondary w-100" disabled={isBlank(tree.copied_ztree_node)} onClick={onPasteDriver}>Paste</Button>
    </div>
    {
      current_org.allow_gpt_suggestions ?
        <OverlayTrigger placement='top' overlay={isMinDriversForGptRequest ? <></> : tooltip}>
          <Button className={`btn-secondary w-100 ${isWizard ? '' : 'mb-2'} ${isMinDriversForGptRequest ? '' : 'disabled'}`}
                  onClick={showSuggestionModal} hidden={isHistoricalDecision}>
            Get driver suggestions with CloverpopAI
          </Button>
        </OverlayTrigger>:
        null
    }
  </div>
}

const DriverActions = ({ tree,
                         decision,
                         openModal,
                         updateTreeData,
                         copyDriver,
                         current_org
}) => {
  const [showGptSuggestionModal, setShowGptSuggestionModal] = useState(false)
  const emptyRootDriver = useMemo(() =>
    tree.drivers.find((driverData) => isBlank(driverData.driver.question))
    , [tree])

  const onAddDriver = () => openModal({
    decision,
    drivers: tree.drivers,
    data_sources: tree.data_sources,
    slug: emptyRootDriver.driver.slug,
    type: 'TreePanelAddDriverDetailsModal'
  })

  const onPasteDriver = () => {
    const { childrenEmptyDriver } = tree.selected_ztree_node
    const { slug } = tree.copied_ztree_node
    copyDriver({ slug: childrenEmptyDriver.driver.slug, copied_slug: slug }, (success) => {
      if(success) resetCopyPaste(updateTreeData)
    });
  }

  return <>
    <ActionButtons tree={tree}
                   drivers={tree.drivers}
                   setShowGptSuggestionModal={setShowGptSuggestionModal}
                   onAddDriver={onAddDriver}
                   onPasteDriver={onPasteDriver}
                   updateTreeData={updateTreeData}
                   current_org={current_org} />
    <div className="modals">
      <GptSuggestionsModal shown={showGptSuggestionModal} onClose={() => setShowGptSuggestionModal(false)}
                           drivers={tree.drivers} />
    </div>
  </>

}

const mapStateToProps = ({ current_org, decision, tree, template, modal },  { isTemplate }) => ({
  current_org, modal, tree, decision: isTemplate ? template : decision
});
export default connect(mapStateToProps, {
  openModal, closeModal, resetModal, updateTreeData, copyDriver
})(DriverActions);
