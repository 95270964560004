import React, { useCallback } from 'react';
import { useHistory } from "react-router-dom";
import DetailsHamburgerButton from "../../template_view/header/DetailsHamburgerButton";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import HelpHamburgerButton from "../../template_view/header/HelpHamburgerButton";
import {redirectToHomepageCallback} from "../../tree_view/header/HamburgerDropdown";

export default ({ current_user, ...opts }) => {
  const history = useHistory();
  const processGoToHomepage = useCallback(redirectToHomepageCallback({
    current_user, history, ...opts
  }), [current_user])

  return <HamburgerMenuIconBtn>
    <DetailsHamburgerButton />
    {/*<HelpHamburgerButton />*/}
    <BaseHamburgerBtn onSelect={processGoToHomepage} title="Go to homepage" />
  </HamburgerMenuIconBtn>
}
