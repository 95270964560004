import React from 'react';

export default () =>
  <>
    <p>
      Categories are used to find, analyze, and report on decisions.
    </p>
    <p>
      Click the <b>Add category</b> button to add a category and the selections for it.
    </p>
    <p>
      When viewing a category, click the menu button to edit or delete it.
    </p>
    <p>
      To change the order categories are displayed in Cloverpop, click the <b>Sort categories</b> button.
    </p>
  </>
