import React from 'react';

export default () =>
  <>
    <p>
      Report insights allow tracking the usage of reports sourced from vendors or created internally.
    </p>
    <p>
      Reports can be used to kick off decisions or record actions taken as a result of the insights and recommendations provided.
    </p>
    <p>
      Add reports to decisions as a data source to include it in the value summary section.
    </p>
  </>
