import React, {Fragment, useEffect, useRef} from "react";
import {connect} from "react-redux";
import {updateEntryPointData} from "../EntryPoint";
import {checkLoadingEffect} from "../helpers/callbacks_helpers";
import {Loader} from "../common/Loader";
import StepsWizard from "./steps_wizard";
import { loadReportWizardData } from "../store/wizard/actions";
import { loadOrgReportProperties } from "../store/org_report_properties/actions";
import AlertsSection from "../alerts";
import WizardHeader from "./header";
import Help from "./help";
import HelpPanel from "./side_panel/HelpPanel";
import { resetReportData } from "../store/report/actions";

const Sidebars = () => <Fragment>
  <Help sticky={true}/>
  <div className="sidebars">
    <div className="help-side-panels">
      <HelpPanel />
    </div>
  </div>
</Fragment>

const Wrapper = ({children, wizard, stepRef}) =>
  <Fragment>
    <AlertsSection />
    <WizardHeader ref={stepRef}/>
    <div className={`d-flex ${wizard.step_index === 1 ? '' : 'overflow-hidden'}`}>
      {children}
    </div>
    <Sidebars wizard={wizard} stepRef={stepRef} />
  </Fragment>

const ReportWizard = ({
                          wizard, report, org_report_properties,
                          loadReportWizardData, loadOrgReportProperties, resetReport,
                          match
                        }) => {
  useEffect(() => {
    updateEntryPointData(match, 'reports', () => {})
  }, [match?.params?.id])
  useEffect(() => {
    if (report.loaded && report.slug !== match?.params?.id) {
      resetReport()
    }
  }, [match?.params?.id, report])

  checkLoadingEffect(org_report_properties, loadOrgReportProperties)
  useEffect(() => { loadReportWizardData() }, []);

  const stepRef = useRef(null)

  if (wizard.loading) {
    return <Wrapper wizard={wizard} stepRef={stepRef}>
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper wizard={wizard} stepRef={stepRef}>
      <StepsWizard ref={stepRef} />
    </Wrapper>;
  }
};
const mapStateToProps = ({ wizard, report, current_user, org_report_properties }) => ({
  wizard, report, current_user, org_report_properties
});
const mapDispatchToProps = (dispatch) => ({
  loadReportWizardData: () => { dispatch(loadReportWizardData()) },
  loadOrgReportProperties: () => { dispatch(loadOrgReportProperties()) },
  resetReport: () => dispatch(resetReportData())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportWizard);
