import React from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { resetSidebars } from "../../../store/sidebar/actions";
import HamburgerDropdown from './HamburgerDropdown'
import { redirectToHomeCallback } from "../../../helpers/decision_helpers";
import { resetTree } from "../../../store/tree/common_actions";
import { openModal } from "../../../store/modals/actions";
import { resetTemplate } from "../../../store/template/common_actions";
import { successActions } from "../../../helpers/common";
import OrgLogo from "../../../tree_view/header/OrgLogo";

const TemplateViewHeader = ({
                              tree, template, current_org,
                              resetSidebars, resetTree, resetTemplate, openModal,
                            }) => {
  const { error } = tree;
  if (error || !template.loaded) return <nav className="navbar"/>;

  const history = useHistory();
  const processBackClick = () => {
    successActions(true, [resetSidebars, resetTree, resetTemplate]);
    redirectToHomeCallback(true, history, 'templates');
  }
  const editTemplateUsers = () => openModal({type: 'TemplateUsersModal'})

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo current_org={current_org} processBackClick={processBackClick} />
        </div>
        <h5>Template</h5>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerDropdown isDsight={true}/>
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          <li className="nav-item ms-2">
            <Button onClick={() => editTemplateUsers()} className="btn-secondary btn">
              Edit template users
            </Button>
          </li>
        </ul>
      </div>
    </nav>
  </header>;
}

const mapStateToProps = ({ tree,  current_org, template }) => ({ tree, template, current_org });
const mapDispatchToProps = (dispatch) => ({
  resetSidebars: () => dispatch(resetSidebars()),
  resetTemplate: () => dispatch(resetTemplate()),
  resetTree: () => dispatch(resetTree()),
  openModal: (data) => dispatch(openModal(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateViewHeader);
