import React from 'react';
import { setDecisionSidebarOpen } from "../../store/sidebar/actions";
import { connect } from "react-redux";

export const DetailsItem = ({sidebar, openDecisionDetailsSideBar}) => {
  return <li className={sidebar.decisionDetailsSidebar ? 'active' : ''}>
    <div className="side-row text-nowrap pointer" onClick={() => openDecisionDetailsSideBar(!sidebar.decisionDetailsSidebar)}>
      <span className="icon d-inline-block text-center ">
        <i className="fas fa-info-circle fa-lg" />
      </span>
      <span className="d-none d-lg-inline tab-title">Details</span>
    </div>
  </li>
}

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
    openDecisionDetailsSideBar: (flag) => dispatch(setDecisionSidebarOpen(flag))
});
export default connect(mapStateToProps, mapDispatchToProps)(DetailsItem);
