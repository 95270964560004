import { RESET_TEMPLATE_SET_DATA, UPDATE_TEMPLATE_SET_DATA } from "./types";

const initialState = {
  loaded: false,
  loading: false,
  slug: '',
  name: '',
  description: '',
  objects_sequence: false,
  user: {},
  users: [],
  templates: [],
  admissions: [],
  sections: [],
  data_sources: [],
  uploading_sources: [],
  error: ''
};

const lastAddedTemplate = {
  added_template_slug: ''
}

export function templateSetReducer(
  state = { ...initialState, ...lastAddedTemplate },
  action
) {
  switch (action.type) {
    case UPDATE_TEMPLATE_SET_DATA: {
      return {
        ...state,
        ...action.payload
      };
    }
    case RESET_TEMPLATE_SET_DATA: {
      return {
        ...state,
        ...initialState
      }
    }
    default:
      return state;
  }
}
