import React from 'react';

export default ({
                  classParams = 'float-end',
                  onClose = () => {},
                  isFinalPanel = false,
                  isRecommendationPanel = false,
                  isDecisionInputPanel = false,
                  danger = false, disabled = false
                }) =>
  <div className={
    `${classParams} ${isFinalPanel || isRecommendationPanel || isDecisionInputPanel ? 'ms-2-negative' : ''}`
  }>
    <a className={`btn btn-sm btn-sm-round btn-light ${danger ? 'text-danger' : ''} ${disabled ? 'disabled' : ''}`} onClick={() => (disabled ? null : onClose())}>
      <i className="fas fa-times w-100" aria-hidden="true"/>
    </a>
  </div>
