import React from 'react';
import { connect } from "react-redux";
import DecisionHeaderSection from "./DecisionHeaderSection";
import RationaleSection from "./RationaleSection";
import ExpectedResultsSection from "./ExpectedResultsSection";
import OtherChoicesSection from "./OtherChoicesSection";
import DecidedBySection from "./DecidedBySection";
import Decision, { decisionDataSources } from "../../../models/decision";
import Button from 'react-bootstrap/Button';
import FinalDataSourcesRow from "./data_sources/FinalDataSourcesRow";
import ExpectedOpportunitySection from "./ExpectedOpportunitySection";
import ExpectedInvestmentSection from "./ExpectedInvestmentSection";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import NextStepsSection from "./NextStepsSection";
import {isDeciderReadOnly, isPublicVisitor, isVisitor} from "../../../helpers/user_helpers";
import OtherConsideredChoicesSection from "./OtherConsideredChoicesSection";
import RecordRecommendationActionsSection from "../sections/RecordRecommendationActionsSection";
import SectionWrapper from "./SectionWrapper";
import { getRecommenderDetails, isBlank } from "../../../helpers/common";

export const RecommendationSections = ({ user, decision, editSideBar, recommendation_data_sources, setShowEnterApprovalModal }) => {
  const decisionObject = new Decision(decision);
  if(!decisionObject.withRecommendation || (!decisionObject.isRecommendationFlow && !decisionObject.isTreeHistorical && !isDSightDecision(decision))) return null;

  const recommendation = decision.recommendation;
  const recommendationObject = decisionObject.recommendation;
  const { recommenderEmail, recommenderUser, text } = getRecommenderDetails(decision, recommendation);
  const decidedAt = recommendation.recomended_at;

  if(recommendationObject.isEnteredRecommendation) {
    return <SectionWrapper>
      <DecisionHeaderSection user={user} object={recommendationObject} headerText={'Recommendation'} onEdit={() => editSideBar(true)} />
      <RationaleSection object={recommendation} />
      <NextStepsSection object={recommendation} />
      <ExpectedResultsSection object={recommendation} />
      <ExpectedOpportunitySection object={recommendation} />
      <ExpectedInvestmentSection object={recommendation} />
      <FinalDataSourcesRow data_sources={recommendation_data_sources} />
      <DecidedBySection object={recommendationObject} deciding_user={recommenderUser} decided_at={decidedAt} deciding_email={recommenderEmail} text={text}/>
      <OtherConsideredChoicesSection object={recommendationObject} recommendation={recommendation} />
      <OtherChoicesSection object={recommendationObject} decision={decision} />
      {
        !decisionObject.isDecisionRecorded ?
          <RecordRecommendationActionsSection setShowEnterApprovalModal={setShowEnterApprovalModal} /> : null
      }

    </SectionWrapper>
  } else if(!isPublicVisitor(user) && !isVisitor(user) && !isDeciderReadOnly(user) && !(decisionObject.isTreeHistorical && isBlank(recommendation?.slug))) {
    return <SectionWrapper>
      <div className="mx-3 mb-3">
        <Button onClick={() => editSideBar(true)} className="btn btn-secondary w-100">Enter what was recommended</Button>
      </div>
    </SectionWrapper>
  } else {
    return null;
  }
}
const mapStateToProps = ({ decision, tree, current_user }) => {
  const recommendationRelatedDataSources = decisionDataSources(tree.data_sources.filter((source) => source.hide_in_recommendation_panel === false))

  return {
    recommendation_data_sources: recommendationRelatedDataSources,
    user: current_user,
    decision
  }
};
export default connect(mapStateToProps, {})(RecommendationSections);

