import {pageHeader} from "./sections/PageHeader";
import {pageFooter} from "./sections/PageFooter";
import {DecisionSection} from "./sections/DecisionSection";
import {ContextSection} from "./sections/commonBlocks";
import {AdditionSection, RecommendationAdditionSection} from "./sections/AdditionalSections";
import {RecommendationSection} from "./RecommendationSection";
import {pageProperties} from "../index";
import Decision from "../../models/decision";
import {isBlank} from "../../helpers/common";
import {ApprovalSection} from "./sections/ApprovalSection";
import {ResultsSection} from "./sections/ResultsSection";

export const ContinuousPage = (format, decision, drivers, data_sources, extraImages) => {
  const recommendation = decision.recommendation;
  const decisionObj = new Decision(decision, drivers);
  const hideDecisionRecommendationContainer = decisionObj.isDecisionRecorded || !decisionObj.isRecordedOrRecommended || isBlank(recommendation.recommended_at)

  return {
    ...pageProperties({format, isContinuousSection: true}),
    ...pageHeader({head: 'Decision', description: decision.description, isContinuousSection: true, anchor: 'anchorForContinuousPage', id: 'anchorForContinuousPage'}),
    ...pageFooter(extraImages),
    children: [
      ...ContextSection(decision),
      ...DecisionSection(decision, drivers, data_sources),
      ...AdditionSection(decision, drivers, hideDecisionRecommendationContainer),
      ...RecommendationSection(decision, drivers, data_sources),
      ...RecommendationAdditionSection(decision, drivers, hideDecisionRecommendationContainer),
      ...ApprovalSection(decision),
      ...ResultsSection(decision),
    ]
  }
}
