import React from 'react';
import { connect } from "react-redux";
import { isPresent } from "../../helpers/common";
import { Loader } from "../../common/Loader";
import { loadLearnTemplateData } from "../../store/learn/actions";
import { Wrapper } from "../details";
import PageContent from "../details/PageContent";
import { checkLoadingEffect, isStoreLoading } from "../../helpers/callbacks_helpers";

export const LearnTemplateDetails = ({ learn, loadLearnTemplateData, match }) => {
  if (isPresent(match.params.id)) {
    checkLoadingEffect(learn, () => loadLearnTemplateData(match.params.id))
  }

  if (isStoreLoading(learn)) {
    return <Wrapper>
      <Loader/>
    </Wrapper>;
  } else {
    return <Wrapper>
      <PageContent isIndividualDecision={true} />
    </Wrapper>;
  }
}
const mapStateToProps = ({ learn }) => ({ learn })
export default connect(mapStateToProps, { loadLearnTemplateData })(LearnTemplateDetails);
