import React, {Fragment, useState} from 'react';
import { connect } from "react-redux";
import {
  setDecisionInputSidebarOpen,
  setRecommendationInputSidebarOpen
} from "../../../store/sidebar/actions";
import DecisionHeaderSection from "../../side_panel/sections/DecisionHeaderSection";
import RationaleSection from "../../side_panel/sections/RationaleSection";
import ExpectedResultsSection from "../../side_panel/sections/ExpectedResultsSection";
import OtherChoicesSection from "../../side_panel/sections/OtherChoicesSection";
import DecidedBySection from "../../side_panel/sections/DecidedBySection";
import FinalDecisionActionsSection from "../../side_panel/sections/FinalDecisionActionsSection";
import FinalResultsSection from "../../side_panel/sections/FinalResultsSection";
import BuyInSection from "../../side_panel/sections/BuyInSection";
import RateBuyInSection from "../../side_panel/sections/RateBuyInSection";
import BuyInDetailsSection from "../../side_panel/sections/BuyInDetailsSection";
import Decision, { decisionDataSources } from "../../../models/decision";
import RecommendationSections from "../../side_panel/sections/RecommendationSections";
import { DSightAnalysis } from "../../modals/DriverShowModal";
import FinalDataSourcesRow from "../../side_panel/sections/data_sources/FinalDataSourcesRow";
import { isDSightDecision } from "../../../helpers/home_decision_helpers";
import ExpectedOpportunitySection from "../../side_panel/sections/ExpectedOpportunitySection";
import ExpectedInvestmentSection from "../../side_panel/sections/ExpectedInvestmentSection";
import DecisionDateSection from "../../side_panel/sections/DecisionDateSection";
import ApprovalSection from "../../side_panel/sections/ApprovalSection";
import NextStepsSection from "../../side_panel/sections/NextStepsSection";
import EnterApprovalModal from "../../modals/EnterApprovalModal";
import OtherConsideredChoicesSection from "../../side_panel/sections/OtherConsideredChoicesSection";
import SectionWrapper from "../../side_panel/sections/SectionWrapper";
import {getDeciderDetails} from "../../../helpers/common";

export const RecordedDecisionCard = ({
                                       decision, user, editDecisionSideBar,
                                       editRecommendationSideBar, data_sources
                                     }) => {
  const decisionObject = new Decision(decision);
  if (!decisionObject.isDecisionRecorded) return null;

  const [showEnterApprovalModal, setShowEnterApprovalModal] = useState(false)
  const { deciderEmail, deciderUser, decidedAt, text } = getDeciderDetails(decision);

  return <Fragment>
    <div>
      <h1 className="vertical-tree-elements">{decision.description}</h1>

      <FinalResultsSection />
      <ApprovalSection setShowEnterApprovalModal={setShowEnterApprovalModal}/>
      <SectionWrapper>
        <DecisionHeaderSection user={user} object={decisionObject}
                               headerText={'What was decided'} onEdit={() => editDecisionSideBar(true)}/>
        <RationaleSection object={decision}/>
        <NextStepsSection object={decision}/>
        <ExpectedResultsSection object={decision}/>
        <ExpectedOpportunitySection object={decision}/>
        <ExpectedInvestmentSection object={decision}/>
        <DecisionDateSection decision={decision}/>
        <FinalDataSourcesRow data_sources={data_sources}/>
        <DecidedBySection object={decisionObject} deciding_user={deciderUser} decided_at={decidedAt} deciding_email={deciderEmail} text={text}/>
        <OtherConsideredChoicesSection object={decisionObject} decision={decision} />
        <OtherChoicesSection object={decisionObject} decision={decision}/>
        <FinalDecisionActionsSection setShowEnterApprovalModal={setShowEnterApprovalModal}/>
        <BuyInSection/>
        <RateBuyInSection/>
        <BuyInDetailsSection/>
      </SectionWrapper>
      <RecommendationSections editSideBar={editRecommendationSideBar} />
      <div hidden={!isDSightDecision(decision)}>
        <SectionWrapper>
          <div className="px-3">
            <DSightAnalysis headerClass="h3"
                            showDecisionDescription={true}
                            decision={decision}
                            keyPreffix={'final-decision-panel'} />
          </div>
        </SectionWrapper>
      </div>
    </div>
    <div className="modals">
      <EnterApprovalModal shown={showEnterApprovalModal} onClose={() => setShowEnterApprovalModal(false)} />
    </div>
  </Fragment>
}
const mapStateToProps = ({ decision, tree, current_user }) => {
  const finalRelatedDataSources = decisionDataSources(tree.data_sources.filter((source) => source.hide_in_decision_panel === false))

  return {
    data_sources: finalRelatedDataSources,
    user: current_user,
    decision
  }
};
const mapDispatchToProps = (dispatch) => ({
  editDecisionSideBar: (options) => {
    dispatch(setDecisionInputSidebarOpen(options));
  },
  editRecommendationSideBar: (options) => {
    dispatch(setRecommendationInputSidebarOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(RecordedDecisionCard);
