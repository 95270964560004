export function getBasePath() {
  return window.location.origin;
}

export function getDecisionDetailsPath(slug) {
  return `${getBasePath()}/decisions/${slug}`
}

export function assignPath(url) {
  window.location.assign(url);
}
