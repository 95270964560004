import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {
  BASE_CHARTS_OPTIONS,
  CHART_COLORS,
  dataRowWithSignificancePerPeriod, POINT_HEADER, POINT_SAMPLES_ROW, POINT_SIGNS_ROW, SERIES_NAME_ROW
} from "../../../../../models/d_sight/metric_chart_helpers";
import ChartSourcesLabels from "../ChartSourcesLabels";
import { findSelectedDemographic } from "../../helpers";
import { capitalizeAllWords, isBlank, isPresent } from "../../../../../helpers/common";
import DemographicFilter from "../../filters/DemographicFilter";
import { BtnDropdownToggleInlineShadowText } from "../../../../../common/dropdowns";
import { BaseDropdownBtn } from "../../../../../common/BaseHamburgerBtn";
import { chartYAxis } from "../../../../../helpers/metric_helpers";

const baseLineChartOptions = (metric) => ({
  ...BASE_CHARTS_OPTIONS,

  ...chartYAxis(metric),
  chart: {
    type: 'line',
    spacing: [5, 0, 0, 5],
    style: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },
  tooltip: {
    headerFormat: `${POINT_HEADER}<table>`,
    pointFormat: `<tr>${SERIES_NAME_ROW}${POINT_SIGNS_ROW}${POINT_SAMPLES_ROW}</tr>`,
    footerFormat: '</table>',
    shared: true,
    style: { color: '#1F2F3F' },
    useHTML: true
  },

  legend: {
    itemStyle: {
      color: '#1F2F3F',
      fontWeight: '500',
      fontSize: '0.875rem'
    }
  },
  plotOptions: {
    series: {
      lineWidth: 3
    }
  },
})

const SelectedDemSegmentDropdown = ({ d_sight_goal, availableDemographics }) => {
  const { segment } = findSelectedDemographic(d_sight_goal)
  if (isBlank(segment)) return null;

  return  <DemographicFilter {...segment}
                             availableDemographics={availableDemographics}
                             className="d-block d-md-inline-block text-md-end mb-2 mb-md-0 me-2" />
}

const TimeNodeDropdown = ({ timeNode, setTimeNode, timeNodes }) => {
  if (timeNodes.length < 2) return null;

  return <div className="d-block d-md-inline-block text-md-end mb-2 mb-md-0">
    <div className="d-inline-flex text-nowrap me-1">Time period:</div>
    <BtnDropdownToggleInlineShadowText id="time-period-dropdown" title={capitalizeAllWords(timeNode)} onSelect={(node_value) => { setTimeNode(node_value) }} bsPrefix="text-capitalize pe-0">
      {timeNodes.map(node =>
        <BaseDropdownBtn key={`time-period-${node}`} eventKey={node} bsPrefix="text-capitalize" title={capitalizeAllWords(node)} active={timeNode===node}/>
      )}
    </BtnDropdownToggleInlineShadowText>
  </div>
}

export default ({
                  surveys, metric, data,
                  sourceLabels, chartData,
                  ...opts
                }) => {
  const chartOptions = {
    ...baseLineChartOptions(metric),
    colors: CHART_COLORS,

    xAxis: {
      categories: surveys.map(s => s.short_name),
      labels: {
        style: {
          fontSize: '0.75rem',
          color: '#1F2F3F'
        }
      }
    },

    series: data.map(({ name, custom, ...row }) => ({
      name, custom,
      data: surveys.map(s => dataRowWithSignificancePerPeriod(row, s))
    }))
  };

  return <div className="row mb-3">
    <div className="col">
      <div className="h-100 bg-white rounded p-3">
        <div className="d-block d-md-flex">
          <h3 className="me-2">
            {[`${metric.name} over time`, metric.subTitle].filter(isPresent).join(' - ')}
          </h3>
          <div className="text-nowrap ms-auto">
            <SelectedDemSegmentDropdown {...opts}  />
            <TimeNodeDropdown {...opts} />
          </div>
        </div>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} key={`metric-line-chart-${metric.id}`}/>
        {
          sourceLabels ? <ChartSourcesLabels chartData={chartData} surveys={surveys} legendHint={'last time period'} /> : null
        }
      </div>
    </div>
  </div>
}
