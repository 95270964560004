import React from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent, qSortArray } from '../../../helpers/common'
import DecisionTile from "../tiles/DecisionTile";

const DecisionHistorySection = ({ home, products, selectedProduct, selectedGoal }) => {
  const { data } = home.dsight
  const selectedProductHash = products.find(h => h.value === selectedProduct)
  if (isBlank(selectedProductHash)) return null;

  const filteredDecisions = (data.decisions || []).filter(d => d.product_category === selectedProductHash?.category_option_id)
  const tiles = qSortArray(filteredDecisions, false, d => d.created_at)

  if(isBlank(tiles)) return null;

  return <div>
    <h1>Decision history</h1>
    <div className="row tiles-section">
      {
        tiles.map(tile =>
          <DecisionTile decision={tile} key={`dsight-decision-tile-${tile.slug}`} section={'dsight_history'} />
        )
      }
    </div>
  </div>
}
const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps)(DecisionHistorySection);
