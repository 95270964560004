import { BLANK_SEMAPHORES, INSIGHT_MESSAGE_TYPE, INSIGHT_TYPE, SEMAPHORES } from "./insight";
import { capitalizeAllWords, isBlank, isPresent, qSortArray, uniqArray, uniqueBy } from "../../helpers/common";
import { advertisingDimension, brandDimension } from "./dimensions";

const sortInsightValue = (insight) => [
  (999 - parseInt(insight.importance)).toString().padStart(4, '0'),
  parseInt(insight.sentences_order).toString().padStart(2, '0'),
  parseInt(insight.id).toString().padStart(10, '0')
]

const filterInsightByWaveType = (insight, insightType = INSIGHT_TYPE['wave']) => insight.insight_type === insightType
const filterInsightByDemographic = (insight, demographic) => insight.demographic_id == demographic

const BRAND_NODE_TYPE = 'brand';

export class InsightsData {
  constructor(data, insightType= null, selectedDemographic = null) {
    this.data = data;
    this.insightType = insightType || INSIGHT_TYPE['wave'];
    this.selectedDemographic = selectedDemographic
    this._checkInitInsightType()
  }

  get id() {
    return this.data?.id;
  }

  get display_name() {
    return this.data?.display_name;
  }

  get subTitle() {
    return this.data?.sub_title;
  }

  get goal() {
    return capitalizeAllWords(this.data.goal);
  }

  get name() {
    return this.data?.name;
  }

  get layer() {
    return parseInt(this.data?.layer);
  }

  get output() {
    return this.data?.output;
  }

  get measure() {
    return this.data?.measure.toString();
  }

  get hasChartData() {
    return this.data?.has_chart_data;
  }

  get answerInsight() {
    return this._allInsights.filter(i => filterInsightByWaveType(i, this.insightType))
                            .filter(i => filterInsightByDemographic(i, null))
                            .find(i => i.message_type === INSIGHT_MESSAGE_TYPE.answer);
  }

  get answer() {
    return this.answerInsight?.insight || '';
  }

  get questionInsight() {
    return qSortArray(
      (this._allInsights.filter(i => i.message_type === INSIGHT_MESSAGE_TYPE.question) || []),
      true, sortInsightValue
    )[0];
  }

  get question() {
    return this.questionInsight?.insight || '';
  }

  orderedInsights(filterDemographic = false) {
    const filteredInsights = this._allInsights.filter(i => [INSIGHT_MESSAGE_TYPE.message, INSIGHT_MESSAGE_TYPE.decision].includes(i.message_type))
                                 .filter(i => filterInsightByWaveType(i, this.insightType))
    const filteredAllSlice = filteredInsights.filter(i => filterInsightByDemographic(i, null))
    const filteredSlice = filterDemographic ? filteredInsights.filter(i => filterInsightByDemographic(i, this.selectedDemographic)) : []
    return uniqueBy(qSortArray(filteredSlice.length > 0  ? filteredSlice : filteredAllSlice, true, sortInsightValue), 'insight')
  }

  get orderedAnotherInsights() {
    const orderedInsights = this.orderedInsights();
    const filteredInsights = this._allAnotherInsights.filter(i => [INSIGHT_MkESSAGE_TYPE.message, INSIGHT_MESSAGE_TYPE.decision].includes(i.message_type))
      .filter(i => filterInsightByWaveType(i, this.insightType))
      .filter(i => filterInsightByDemographic(i, null))
      .filter(i => !orderedInsights.some(ii => ii.insight === i.insight)) || []
    return uniqueBy(qSortArray(filteredInsights, true, sortInsightValue), 'insight')
  }

  get chartsData() {
    return this.data?.process_data || [];
  }

  get semaphore() {
    if (isPresent(this.answerInsight) && !BLANK_SEMAPHORES.includes(this.answerInsight.semaphore)) {
      return this.answerInsight.semaphore;
    }
    return this.orderedInsights()[0]?.semaphore ||
      this._allInsights[0]?.semaphore ||
      SEMAPHORES.grey
  }

  get semaphoreAnotherPath() {
    return this.orderedAnotherInsights[0]?.semaphore ||
      this._allAnotherInsights[0]?.semaphore ||
      SEMAPHORES.grey
  }

  get hasDynamicChains() {
    return isPresent(this.data.dynamic_chains);
  }

  isBrandMetric(goal) {
    return brandDimension(goal)?.node_index === this.node_index;
  }

  isNonBrandMetric(goal) {
    return brandDimension(goal)?.node_index !== this.node_index;
  }

  isAdvertisingMetric(goal) {
    return advertisingDimension(goal)?.node_index === this.node_index;
  }

  get view() {
    return this.data.view;
  }

  get node_index() {
    return this.data.node_index;
  }

  get _allInsights() {
    return this.data?.insights || [];
  }

  get _allDisplayInsights() {
    return this._allInsights.filter(({message_type}) => message_type !== INSIGHT_MESSAGE_TYPE.question)
  }


  get _allAnotherInsights() {
    return this.data?.another_insights || [];
  }

  _checkInitInsightType() {
    const displayInsightType = uniqArray(this._allDisplayInsights.map(({insight_type}) => insight_type))
    if (displayInsightType.length > 0 && !displayInsightType.includes(this.insightType)) {
      this.insightType = displayInsightType[0]
    }
  }
}
