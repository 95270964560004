import Cookies from "js-cookie";
import { GROUP_COL_ID_SUFFIX } from "./ag_grid_vars";

export const saveFilters = (event, forecastScenario) => {
  const filterModel = event.api.getFilterModel();
  Cookies.set(`forecast-scenario-filters-${forecastScenario.local_id}`, JSON.stringify(filterModel));
}

export const restoreFilters = (event, forecastScenario) => {
  const filterModel = Cookies.get(`forecast-scenario-filters-${forecastScenario.local_id}`);
  if(filterModel) event.api.setFilterModel(JSON.parse(filterModel));
}

export const clearForecastCookies = (forecastScenario) => {
  Cookies.remove(`forecast-scenario-filters-${forecastScenario?.local_id}`);
  Cookies.remove(`forecast-scenario-collapsed-groups-${forecastScenario?.local_id}`);
  Cookies.remove(`forecast-scenario-pinned-data-${forecastScenario?.local_id}`);
}

export const saveCollapsedGroups = (event, forecastScenario) => {
  let collapsedGroups = Cookies.get(`forecast-scenario-collapsed-groups-${forecastScenario.local_id}`);
  collapsedGroups = collapsedGroups ? JSON.parse(collapsedGroups) : [];

  if (!event.node.expanded) {
    collapsedGroups.push(event.node.id);
  } else {
    collapsedGroups = collapsedGroups.filter(id => id !== event.node.id);
  }
  Cookies.set(`forecast-scenario-collapsed-groups-${forecastScenario.local_id}`, JSON.stringify(collapsedGroups));
}

export const restoreCollapsedGroups = (event, forecastScenario) => {
  const collapsedGroups = Cookies.get(`forecast-scenario-collapsed-groups-${forecastScenario.local_id}`);
  if (!collapsedGroups) return;

  JSON.parse(collapsedGroups).forEach(id => {
    const node = event.api.getRowNode(id);
    if (node) node.setExpanded(false);
  });
}

export const saveColumnState = (event, forecastScenario) => {
  let pinnedColumns = Cookies.get(`forecast-scenario-pinned-data-${forecastScenario.local_id}`);
  pinnedColumns = pinnedColumns ? JSON.parse(pinnedColumns) : {};

  pinnedColumns[event.column.colId] = event.pinned;
  Cookies.set(`forecast-scenario-pinned-data-${forecastScenario.local_id}`, JSON.stringify(pinnedColumns));
}

export const restoreColumnState = (event, forecastScenario) => {
  const columnState = Cookies.get(`forecast-scenario-pinned-data-${forecastScenario.local_id}`);
  if(columnState) {
    const parsedColumnState = JSON.parse(columnState);
    Object.keys(parsedColumnState).forEach(colId => {
      event.api.setColumnsPinned([colId], parsedColumnState[colId]);
    });
  } else {
    initPinnedColumns(event);
  }
}

export const restoreForecastCookies = (event, forecastScenario) => {
  restoreFilters(event, forecastScenario);
  restoreCollapsedGroups(event, forecastScenario);
  restoreColumnState(event, forecastScenario);
}

export const initPinnedColumns = (event) => {
  const columnState = event.api.getColumnState();
  columnState.forEach(column => {
    if(column.rowGroup) {
      event.api.setColumnsPinned([`${column.colId}-${GROUP_COL_ID_SUFFIX}`], 'left')
    }
  })
}
