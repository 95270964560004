import React, { Fragment } from 'react';
import { connect } from "react-redux";
import HelpButtons from "../../common/HelpButtons";

export const Index = ({ wizard }) => {
  return <Fragment>
    <div className="px-3 mb-3">
      <p hidden={wizard.step_index === 1}>
        Enter the report name, add links & files, and enter any notes that apply.
      </p>
      <p hidden={wizard.step_index === 0}>
        Properties are used to find and analyze reports. Make selections that best fit this report.
      </p>
    </div>
    <HelpButtons />
  </Fragment>;
}
const mapStateToProps = ({ wizard }) => ({
  wizard
});
export default connect(mapStateToProps)(Index);
