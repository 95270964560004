import React from 'react';
import Button from 'react-bootstrap/Button';

export const DoneBtnW100 = ({ onClose = () => {}, ...opts }) =>
  <Button className="w-100 m-0" onClick={onClose} {...opts}>Done</Button>

export default ({ onClose = () => {}, children = 'Done', ...opts }) =>
  <Button onClick={onClose} bsPrefix="btn btn-primary w-100 m-0" {...opts}>
    {children}
  </Button>
