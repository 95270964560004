import React from 'react';
import { uniqArray } from "../../../../../helpers/common";
import { findNodeValue } from "../../../../../models/d_sight/metric_chart_helpers";
import OverTimeChart from "../components/OverTimeChart";
import LastPeriodChart from "../components/LastPeriodChart";
import { prepareChartData } from "../helpers/line_helpers";
import { sortNodes } from "./helpers";

export const NonBrandLineChart = ({
                                    d_sight_goal, process_data, surveys, timeNode,
                                    metric, viewMetric,
                                    ...opts
                                  }) => {
  const sortedNodeNames = sortNodes(uniqArray(process_data.map(p => findNodeValue(p.nodes, metric.node_index))))
  const preparedData = prepareChartData({
    process_data, surveys, timeNode, sortedNodeNames, d_sight_goal, metric
  })

  return <>
    <OverTimeChart metric={viewMetric || metric} {...{...opts, ...preparedData, timeNode, d_sight_goal}} />
    <LastPeriodChart metric={viewMetric || metric}
                     {...{...opts, ...preparedData, timeNode, d_sight_goal, sortedNodeNames}}  />
  </>
}
