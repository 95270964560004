import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import CloseIcon from "../../common/CloseIcon";
import { isBlank } from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CharCounter from "../../common/CharCounter";

export const GROUP_NAME_LIMIT = 40

export const UpdateGroupModal = ({
                                   shown, slug, name,
                                   onClose = () => {}, onSubmit = () => {},
                                 }) => {
  if (isBlank(slug)) return null;

  // State
  const [groupName, setGroupName] = useState(name)
  const [submit, setSubmit] = useState(false)
  const [showChar, setShowChar] = useState(false);

  const focusElement = React.useRef(null);
  useEffect(() => {
    if (shown && focusElement.current) focusElement.current.focus()
  }, [shown, focusElement])
  useEffect(() => {
    setGroupName(name)
  }, [name])

  // Callback
  const submitModal = () => {
    setSubmit(true)
    onSubmit(slug, { name: groupName }, success => {
      setSubmit(false)
      if (success) onClose()
    })
  }
  const setDefaultValues = () => {
    setGroupName(name)
  }
  const close = () => {
    setDefaultValues();
    onClose()
  }

  // Render
  return <Modal size="md" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <CloseIcon onClose={close} />
      <h2>Edit name</h2>
      <div className="mb-3">
        <CharCounter show={showChar} field={groupName} limit={GROUP_NAME_LIMIT} extraClassName={'float-end'} />
        <input className="form-control"
               value={groupName} ref={focusElement}
               placeholder="Enter the name of this group"
               autoFocus={shown} disabled={submit} maxLength={GROUP_NAME_LIMIT}
               onChange={(e) => setGroupName(e.target.value)}
               onBlur={() => setShowChar(false)}
               onFocus={() => setShowChar(true)}
        />
      </div>
      <Button onClick={submitModal} className="w-100 mt-3" disabled={isBlank(groupName) || submit}>Done</Button>
    </Modal.Body>
  </Modal>
}
const mapStateToProps = ({}) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(UpdateGroupModal);
