import React from 'react';
import { connect } from "react-redux";
import {
  destroyChoice,
  updateChoice
} from "../../../../store/sidebar/actions";
import {PickListChoiceItem} from "../../decision_input/choices/ChoiceItem";
import Driver from "../../../../models/driver";
import {sortedRatedRankedChoices} from "../../../../tree_wizard/steps_wizard/steps/sections/HistoricalChoicesSection";

export const updateChoicesWithoutSave = (setChoices, isTopChoice, choice, selected, isRankChoice, choices) => {
  const updatedChoices = []

  const finalRankedChoices = choices.filter(choice => choice.final_decision && choice.final_decision_rank)
  choices.forEach((prevChoice) => {
    if (prevChoice.slug === choice.slug) {
      if (isRankChoice && !selected) return updatedChoices.push({ ...prevChoice, final_decision_rank: finalRankedChoices.length + 1, final_decision: !selected});

      updatedChoices.push({ ...prevChoice, final_decision: !selected });
    } else {
      if (isTopChoice && !selected) return updatedChoices.push({ ...prevChoice, final_decision: false });

      updatedChoices.push(prevChoice);
    }
  });

  setChoices(isRankChoice ? sortedRatedRankedChoices(updatedChoices) : updatedChoices);
}

export const generateChoiceItemState = ({ driver, choice, selected, updateDriverFinalChoices, notSaveChoice, setChoices, choices }) => {
  const driverObj = new Driver(driver);
  const isTopChoice = driverObj.isTopChoice

  return {
    onSelectChoice: () => {
      if (notSaveChoice) {
        updateChoicesWithoutSave(setChoices, isTopChoice, choice, selected, false, choices)
      } else {
        updateDriverFinalChoices(driver.slug, choice.slug, {
          description: choice.description,
          final_decision: !selected,
          reset_final: true
        });
      }
    }
  };
};

export const sortedDriverChoices = (driver) => new Driver(driver).sortedChoices()

export const ChoiceItem = ({
                      choice, selected = false, key = '',
                      updateDriverFinalChoices = () => {}, driver, notSaveChoice,
                      setChoices = () => {}, choices,
                      ...opts
                   }) => {
  const driverObj = new Driver(driver)
  const { ...choiceProps } = generateChoiceItemState({driver, choice, selected, updateDriverFinalChoices, notSaveChoice, setChoices, choices, ...opts })

  return (
    <PickListChoiceItem
      choice={choice}
      selected={selected}
      boxType={driverObj.isPicky ? "checkbox" : "radio"}
      {...choiceProps}
    />
  )
};
export default connect(null, { destroyChoice, updateChoice })(ChoiceItem);
