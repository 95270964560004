import React from "react";
import { connect } from "react-redux";
import { removeTreeNode } from "../../../../store/tree/actions";
import { resetDriverInputSidebar } from "../../../../store/sidebar/common_actions";
import BaseDriverRemove from "../../../../common/BaseDriverRemove";

const DriverRemove = ({ treeNode, removeTreeNode, resetDriverInputSidebar }) => {
  const deleteCallback = confirmed => {
    if (confirmed) {
      resetDriverInputSidebar()
      setTimeout(() => removeTreeNode(treeNode.slug), 300)
    }
  }
  return <BaseDriverRemove treeNode={treeNode} deleteCallback={deleteCallback} />
}
export default connect(null, { removeTreeNode, resetDriverInputSidebar })(DriverRemove);
