import React from 'react';
import Button from 'react-bootstrap/Button';
import { DEFAULT_SCOPES, SCOPES_LABELS } from "../../helpers/scopes_helpers";

export default ({ defaultScope, setDefaultScope, disabled = false }) =>
  <div className="px-3 mt-2 mb-3">
    <Button onClick={() => setDefaultScope(defaultScope === DEFAULT_SCOPES.visible ? DEFAULT_SCOPES.simulated : DEFAULT_SCOPES.visible )}
            disabled={disabled}
            className="btn-secondary w-100">
      Default to {SCOPES_LABELS[defaultScope]}
    </Button>
  </div>
