import React, { Fragment } from 'react';
import { connect } from "react-redux";
import EntryPoint from "../../EntryPoint";
import HelpButtons from "../../common/HelpButtons";

export const Index = ({current_org}) => {
  const { objectSlug, controllerName } = EntryPoint.instance;

  return <Fragment>
    <div className="px-3 mb-3">
      <h3>How does the tree work?</h3>
      <p>Start with the questions on the left and work your way to the right to make a well-informed decision.</p>
    </div>
    <div className="px-3 mb-3">
      <h3>How do data sources work?</h3>
      <p>
        The best answers will often be backed by research and studies. Data sources can be added to the decision or
        to questions. Search by name and select links or files added previously. Alternatively, add your own link or file to the
        decision or driver. Rename it to make the search and selection more clear to others.
      </p>
    </div>
    <div className="px-3 mb-3">
      <h3>What is the confidence level?</h3>
      <p>The confidence level is the self-rated certainty in the answers supporting the decision.</p>
    </div>
    <div className="px-3 mb-3">
      <h3>How do I add more collaborators?</h3>
      <p>
        Go to the Collaborators and click on the field at the bottom of the listed collaborators.
        When you start to type, you will see a list of people and groups you can select from.
        To invite someone who is not on the list, type the email address.
        A notification will be sent to the invited collaborator directing them to the decision.
      </p>
    </div>
    <HelpButtons />
  </Fragment>;
}
const mapStateToProps = ({current_org}) => ({current_org});
export default connect(mapStateToProps)(Index);
