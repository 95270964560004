import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateHomepageCurrentTab } from "../../store/homepage/actions";
import { hideReportInsightsTab, isReportInsightsTab, TABS, TABS_URLS } from "../../helpers/home_helpers";

export const ReportInsightsItem = ({
                            displayCurrent,
                            home, current_user, current_org,
                            updateHomepageCurrentTab
                          }) => {
  if (hideReportInsightsTab(current_user, current_org)) return null;

  return <li className={displayCurrent && isReportInsightsTab(home, current_user, current_org) ? 'active' : ''}>
    <Link to={`/homepage/${TABS_URLS.report_insights}`}
          onClick={() => updateHomepageCurrentTab(TABS.report_insights)}
          className="text-nowrap">
      <span>
        <span className="icon d-inline-block text-center">
          <i className="fas fa-files fa-lg" />
        </span>
        <span className="d-none d-lg-inline tab-title">Report insights</span>
      </span>
    </Link>
  </li>
}

const mapStateToProps = ({ home, current_user, current_org }) => ({ home, current_user, current_org });
export default connect(mapStateToProps, { updateHomepageCurrentTab })(ReportInsightsItem);
