import { isEmail, isPresent, uniqueByCallback } from "../../../helpers/common";
import { itemIdentifier } from "../../../models/share_data";
import { findItemByEmailCallback } from "../../../tree_view/modals/helpers/share_helpers";

export const filterSharedItems = (items) => items.filter(item => isEmail(item) || isEmail(item.label) || isPresent(item.admittable_id))

export const filterNewShared = (newShared) => uniqueByCallback(filterSharedItems(newShared), (a) => itemIdentifier(a))

export const onChangeUseStateCallback = (setParameterInUseState, shareObject) => (selected) => {
  const newShared = [];
  selected.forEach(item => {
    if (typeof item === "string") {
      newShared.push(findItemByEmailCallback(shareObject)(item))
    } else {
      newShared.push({ ...item })
    }
  })
  setParameterInUseState(filterNewShared(newShared))
}
