import React, { Fragment } from 'react';
import SavedChats from "./components/SavedChats";
import AlertsSection  from "../../../../alerts";
import Header from "./components/Header";
import Chat from "./components/Chat";

const AskCloverpop = () => {
  return (
    <Fragment>
      <AlertsSection />
      <Header />
      <div className="home container mb-3 mx-auto">
        <h1 className="mt-3">Ask Cloverpop</h1>
        <div className="bg-white rounded h-100 p-3">
          <Chat />
        </div>
        <SavedChats />
      </div>
    </Fragment>
  );
};

export default AskCloverpop;
