import React from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import {headerDispatchProps} from "../../tree_wizard/header";
import {headerActionsDispatchProps, startDecisionCallback} from "../../tree_wizard/header/ActionsDropdown";
import {useHistory} from "react-router-dom";
import {wizardSetCallbacks} from "../../tree_wizard/header/helpers";

const DoneHeaderButton = ({ wizard, decision,
                            stepRef, isTemplate = false,
                            forceReloadHomepageDecisions, forceReloadHomepageTemplates, updateTreeData,
                            resetSidebars, resetWizard, resetTree, resetPlaybookNotes, resetSearchAndFilters}) => {

  const history = useHistory();
  const {
    resetSetCallback, setObjectSlug, objectSlug
  } = wizardSetCallbacks({ decision })

  const completeWizardHandler = startDecisionCallback( {
    stepRef, history, isTemplate, setSlug: setObjectSlug(), wizard, slug: objectSlug(),
    actions: [
      isTemplate ? forceReloadHomepageTemplates : forceReloadHomepageDecisions,
      resetSearchAndFilters, resetSidebars, resetWizard, resetTree, resetPlaybookNotes,
      resetSetCallback, () => updateTreeData({ loaded: false })
    ]
  })

  return <li className="nav-item ms-2">
    <Button onClick={completeWizardHandler}
            disabled={wizard.submit || wizard.disabledSubmit}
            className="btn-primary px-3 nav-item width-100 btn">
      Done
    </Button>
  </li>
}

const mapStateToProps = ({ wizard, decision }) => ({ wizard, decision });
const mapDispatchToProps = (dispatch) => ({
  ...headerDispatchProps(dispatch),
  ...headerActionsDispatchProps(dispatch)
});
const wrapper = React.forwardRef((props, ref) => <DoneHeaderButton {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
