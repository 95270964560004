import React from 'react';
import { connect } from "react-redux";
import ChoiceItem, { sortedRatedChoices } from "./ChoiceItem";

const TopChoice = ({decision, drivers, object}) =>
  <div>
    {
      sortedRatedChoices(decision, drivers).map((choice) =>
        <ChoiceItem choice={choice} key={`top-choice-${choice.slug}`} selected={choice.final_decision} object={object}/>
      )}
  </div>

const mapStateToProps = ({ sidebar, tree }) => ({ decision: sidebar.decision, drivers: tree.drivers });
export default connect(mapStateToProps)(TopChoice);
