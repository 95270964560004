import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { updateDecisionAssignedDecider } from "../../../store/sidebar/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import DecisionAssignToBlock from "../../modals/helpers/DecisionAssignToBlock";
import Decision from "../../../models/decision";

export const assignAssignedUserCallback = ({
                                             skipUpdate = false, isFinalChoices, current_user,
                                             assignedToUser, setAssignedToUser,
                                             updateAssignedAction = (_) => null
                                           }) => {
  useEffect(() => {
    if(skipUpdate) return;

    if (isFinalChoices) {
      if (isPresent(assignedToUser)) return;

      setAssignedToUser(current_user.email);
      updateAssignedAction(current_user.email);
    } else if (current_user.email === assignedToUser) {
      setAssignedToUser(null);
      updateAssignedAction(null);
    }
  }, [isFinalChoices])
}

const AssignedDeciderInput = ({ decision, updateDecisionAssignedDecider, current_user }) => {
  const [assignedToUser, setAssignedToUser] = useState(decision?.assigned_decider_email)
  const decisionObj = decision && new Decision(decision);
  const isFinalChoices = decision?.choices?.some(choice => choice.final_decision);
  const skipUpdateDecider = decisionObj.isOpenEnded || decisionObj.isDecisionRecorded
  const deciderEmail = decision?.assigned_decider_email

  assignAssignedUserCallback({
    skipUpdate: skipUpdateDecider, isFinalChoices, current_user,
    assignedToUser, setAssignedToUser,
    updateAssignedAction: updateDecisionAssignedDecider
  })

  useEffect(() => {
    setAssignedToUser(deciderEmail)
  }, [deciderEmail]);

  if (isBlank(decision)) return null;

  return <div className="w-100 mb-3 px-3">
    <DecisionAssignToBlock {...{
      assignedToUser, setAssignedToUser,
      updateAction: (email) => updateDecisionAssignedDecider(email),
      className: '', title: 'Decider'
    }} />
  </div>
}
const mapStateToProps = ({ sidebar, current_user }) => ({ decision: sidebar.decision, current_user });
export default connect(mapStateToProps, { updateDecisionAssignedDecider })(AssignedDeciderInput);
