import React from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { loadDashboardTemplateAdditionalData } from "../../store/homepage/actions";
import { startDecision } from "../../store/decision_set/actions";
import { loadOrgCategories } from "../../store/org_categories/actions";
import { ModalDoneFooter } from "../../common/modals";
import { StartDecisionModalBody, startDecisionModalState } from "../../homepage/modals/StartDecisionModal";
import { resetSidebars } from "../../store/sidebar/actions";

export const showStartUnstartedDecisionModal = (modal) => modal.type === "StartUnstartedDecisionModal" && modal.shown;
export const openStartUnstartedDecisionModal = ({ openModal, ...opts }) => openModal({ ...opts, type: 'StartUnstartedDecisionModal' })

const StartUnstartedDecisionModal = ({ shown, decision, startDecision, decision_set, ...opts }) => {
  const onSubmit = (data, callback) => {
    startDecision({
      ...data,
      slug: decision.slug,
      scope: 'decision_sets',
      setSlug: decision_set.slug
    }, (success) => {
      if (success) {
        callback(decision.slug)
      } else {
        callback(success)
      }
    })
  }
  const {
    submit,
    onCloseModal, onCreate, isFormFilled,
    ...stateOpts
  } = startDecisionModalState({
    ...opts, shown, onSubmit
  })

  return <Modal backdrop="static" size="md" show={shown} onHide={onCloseModal}>
    <StartDecisionModalBody {...{ ...stateOpts, onCloseModal, submit }}/>
    <ModalDoneFooter onClose={onCreate} disabled={!isFormFilled() || submit} />
  </Modal>
}
const mapStateToProps = ({ current_user, modal, org_categories, home, decision_set }) => ({
  current_user, org_categories, decision_set,
  decision: modal.decision,
  template: modal.template,
  additionalTemplatesData: home.create_decision_additional_data
});
export const mapDispatchToProps = (dispatch) => ({
  startDecision: (data = {}, callback) => dispatch(startDecision(data, callback)),
  loadDashboardTemplateAdditionalData: (slug) => dispatch(loadDashboardTemplateAdditionalData(slug)),
  loadOrgCategories: () => dispatch(loadOrgCategories()),
  resetSidebars: () => dispatch(resetSidebars())
})
export default connect(mapStateToProps, mapDispatchToProps)(StartUnstartedDecisionModal);
