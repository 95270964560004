import React from 'react';
import { connect } from "react-redux";
import { setDecisionSidebarOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import { isMobile } from "react-device-detect";
import SetDescriptionSection from "../../template_set_view/side_panel/sections/SetDescriptionSection";
import { updateDecisionSet } from "../../store/decision_set/actions";

const DecisionSetDetailsPanel = ({ sidebar, decision_set, openSideBar, updateDecisionSet, ...opts }) =>
  <SlidingPanel
    type="left"
    isOpen={sidebar.decisionDetailsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper details-panel-width template-panel left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
    size={52}
  >
    <PanelHeader headerName={'Details'} openSideBar={openSideBar} preview={true} />
    <SetDescriptionSection updateSet={updateDecisionSet}
                           set={decision_set}
                           set_key='decision_set'
                           classNames='mb-2'
                           {...opts} />
  </SlidingPanel>

const mapStateToProps = ({ sidebar, decision_set }) => ({ sidebar, decision_set });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => { dispatch(setDecisionSidebarOpen(options)); },
  updateDecisionSet: (value, callback) => { dispatch(updateDecisionSet(value, callback)) },
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionSetDetailsPanel);
