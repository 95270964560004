import React from "react";
import Image from "react-bootstrap/Image";
import { isBlank } from "../helpers/common";
import TreeProfile from "../../../../assets/images/tree_profile.png";
import { userInitials, userName } from "../helpers/user_helpers";

export const DEFAULT_AVATAR_URL = 'assets/tree_profile'

const AVATAR_SIZE = {
  sm: 'sm-avatar fs-5',
  md: 'md-avatar fs-3',
  lg: 'lg-avatar display-4'
};

export const isDefaultAvatarUrl = (url) => url.includes(DEFAULT_AVATAR_URL)

const isDefaultAvatar = (user, avatarField) =>
  isBlank(user[avatarField]) || user[avatarField].toString().indexOf(DEFAULT_AVATAR_URL) > -1;

const PendingUserAvatar = ({email, size = 'sm', hideTitle}) =>
  <Image className={`${AVATAR_SIZE[size]}`} roundedCircle src={TreeProfile} title={hideTitle ? '' : email} alt={email} />

const isPendingUserContact = (user) => {
  return isBlank(user) || (Object.keys(user).join('-') === 'email') || user?.org_role === 'pending'
}

export default ({
                  user, avatarField = 'avatar_url', size = 'sm', src = null,
                  isPendingUser = false, pendingUserEmail = '', hideTitle = false, classNames = ''
                }) => {
  if (isPendingUserContact(user) || isPendingUser) return <PendingUserAvatar {...{ email: (user?.email || pendingUserEmail), hideTitle, size }} />

  const title = hideTitle ? null : userName(user)
  return (isDefaultAvatar(user, avatarField) && isBlank(src)) ?
    <div className={`d-inline-flex rounded-circle text-dark justify-content-center align-items-center ${classNames} ${AVATAR_SIZE[size]} bg-light-gray`} title={title}>
      { userInitials(user) }
    </div> :
    <Image className={`${AVATAR_SIZE[size]} ${classNames}`} roundedCircle src={src || user[avatarField]} alt={title} title={title}/>
}
