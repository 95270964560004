import React from 'react';
import { connect } from "react-redux";
import { CHOICE_ENTRY_TYPE_DATA } from "../../../models/recommendation";

const SelectedChoiceEntryType = ({decision}) => {
  const recommendation = decision.recommendation;
  return <div className="mb-2 text-muted">
    {CHOICE_ENTRY_TYPE_DATA[recommendation.choice_entry_widget_type]}
  </div>
}

const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps)(SelectedChoiceEntryType);
