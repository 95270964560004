import React, { useState } from "react";
import { connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooterP0 } from "../../common/modals";
import { isBlank } from "../../helpers/common";
import { NameRow } from "../../homepage/modals/TemplateSetModal";

export const SECTION_NAME_INPUT_LIMIT = 50

export const showAddSectionModal = (modal) => modal.type === "SetSectionsPanelAddSectionModal" && modal.shown

const AddSectionModal = ({ key, title, show, onClose, createSectionAction = () => {} }) => {
  const [name, setName] = useState('');
  const [submit, setSubmit] = useState(false);

  const onSubmit = () => {
    setSubmit(true);
    createSectionAction({ section: { name } }, (status) => {
      if (status) {
        setName('');
        onClose();
      }
      setSubmit(false);
    })
  }

  return <Modal backdrop="static" key={key} size="md" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>{title || 'Add a section'}</h2>
      <NameRow name={name} setName={setName} disabled={submit} nameLimit={SECTION_NAME_INPUT_LIMIT} placeholder={'Enter the name of the section'} />
    </Modal.Body>
    <ModalDoneFooterP0 onClose={onSubmit} disabled={isBlank(name) || submit} />
  </Modal>
}
const mapStateToProps = ({ modal }) => ({ modal });
export default connect(mapStateToProps)(AddSectionModal);
