import React from 'react';
import { NumericFormat } from 'react-number-format';
import {NUMBER_FORMAT, NUMBER_FORMAT_PLACEHOLDER} from "../../../models/driver";
import {parseFormattedNumber} from "../../../helpers/drivers_helpers";
import {isBlank} from "../../../helpers/common";

export default ({ driver, onBlur = __ => {}, value, setValue }) => {
  if (isBlank(driver)) return null;

  return <NumericFormat
    id={`driver-numbering-${driver.slug}`}
    onBlur={onBlur}
    className={'form-control'}
    value={parseFormattedNumber(value)}
    onValueChange={(input) => setValue(input.formattedValue)}
    placeholder={NUMBER_FORMAT_PLACEHOLDER[driver.number_format]}
    {...(driver.number_format !== NUMBER_FORMAT.PERCENTAGE ? { thousandSeparator: ',' } : {})}
    {...(driver.number_format === NUMBER_FORMAT.DOLLAR ? { prefix: '$' } : {})}
    {...(driver.number_format === NUMBER_FORMAT.DOLLAR ? { decimalScale: 2 } : {})}
    {...(driver.number_format === NUMBER_FORMAT.PERCENTAGE ? { suffix: '%' } : {})}
  />
};
