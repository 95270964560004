import React from 'react';
import { setPlaybookSidebarOpen } from "../../store/sidebar/actions";
import { connect } from "react-redux";
import { isPlaybookNotesPresent } from "../../helpers/playbook_helpers";

export const PlaybookWizardItem = ({ decision, sidebar, openPlaybookSideBar}) => {
  if (!isPlaybookNotesPresent(decision))  return null

  return <li className={sidebar.playbookSidebar ? 'active' : ''}>
    <div className="side-row text-nowrap pointer" onClick={() => openPlaybookSideBar(!sidebar.playbookSidebar)}>
      <span className="icon d-inline-block text-center ">
        <i className="fas fa-book-open fa-lg"/>
      </span>
        <span className="d-none d-lg-inline tab-title">Playbook</span>
    </div>
  </li>
}

const mapStateToProps = ({ decision, sidebar }) => ({ decision, sidebar });
const mapDispatchToProps = (dispatch) => ({
  openPlaybookSideBar: (flag) => dispatch(setPlaybookSidebarOpen(flag))
});
export default connect(mapStateToProps, mapDispatchToProps)(PlaybookWizardItem);
