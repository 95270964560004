import React, {useMemo} from 'react';
import { connect } from "react-redux";
import { updateDriver } from "../../../store/sidebar/actions";
import DriverRichTextInput, { ANSWER_DEFAULT_HEADER } from "./DriverRichTextInput";
import Driver from "../../../models/driver";
import ChoicesDriverInput from "./ChoicesDriverInput";
import Decision from "../../../models/decision";
import NumberInputComponent from "./NumberInputComponent";

const AnswerInput = ({ innerRef, decision, drivers, setAutoFocus, ...opts }) => {
  const driver = opts.driverData.driver
  const driverObj = new Driver(driver);
  const decisionObject = useMemo(() => new Decision(decision, drivers), [decision]);

  const CachedAnswerInput = useMemo(() => () => driverObj.isOpen ?
    <div onBlur={() => setAutoFocus(false)}>
      <DriverRichTextInput header={ANSWER_DEFAULT_HEADER}
                           field="answer"
                           ref={innerRef}
                           decision={decision}
                           hideForRateChoices={true}
                           {...opts} {...{setAutoFocus}} /></div> :
      driverObj.isNumbering ?
        <NumberInputComponent ref={innerRef} {...opts} {...{setAutoFocus}} /> :
        <ChoicesDriverInput driver={driver} />,
    [driver?.choice_entry_widget_type, driver?.driver_response_choices, decisionObject.isRateAndCompareDriverChoicesHidden]
  )

  return <CachedAnswerInput />
}
const mapStateToProps = ({ decision, tree }) => ({ decision, drivers: tree.drivers })
const wrapper = React.forwardRef((props, ref) => <AnswerInput {...props} innerRef={ref} />)
export default connect(mapStateToProps, { updateDriver }, null, { forwardRef: true })(wrapper);
