import React, { useCallback, useState } from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CloseIcon from "../../common/CloseIcon";
import ContactsTypehead from "../../common/ContactsTypehead";

export const AddGroupUsersModal = ({
                                     shown,
                                     slug, group_users,
                                     onClose = () => {}, onSubmit = () => {}
                                  }) => {
  const [users, setUsers] = useState([])
  const [submit, setSubmit] = useState(false)

  const setDefaultValues = useCallback(() => { setUsers([]) }, [])

  const submitAddUsersModal = () => {
    setSubmit(true)
    onSubmit(slug, users.map(u => u.value), (success) => {
      setSubmit(false)
      if (success) close();
    })
  }

  const close = () => {
    setDefaultValues();
    onClose()
  }

  return <Modal size="md" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <CloseIcon onClose={close} />
      <h2>Add users</h2>
      <div className="mb-3">
        <ContactsTypehead
          autoFocus={shown} disabled={submit}
          placeholder={"Enter users to add to this group"}
          values={users} excludeValues={group_users}
          setValues={setUsers}
          minLength={0}
        />
      </div>
      <Button onClick={submitAddUsersModal} className="w-100 mt-3" disabled={isBlank(users) || submit}>Add to group</Button>
    </Modal.Body>
  </Modal>
}
const mapStateToProps = ({}) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AddGroupUsersModal);
