import { UPDATE_USER_DATA, UPDATE_USER_FAILED } from "./types";
import { loadCurrentUserData } from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import { updateOrgData } from "../current_org/actions";
import { isPresent } from "../../helpers/common";
import { updateSignInUserData } from "../sign_in/actions";

export const loadCurrentUser = () => (dispatch) => {
  dispatch(updateUserData({ loading: true }))
  dispatch(updateSignInUserData({ loading: true }))

  loadCurrentUserData().then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateUserFailure })

    const { data } = response;
    const { user, current_org } = data;
    dispatch(updateUserData({ ...user, loaded: true, loading: false }));
    dispatch(updateOrgData({ ...current_org, loaded: true }))
    dispatch(updateSignInUserData({ loading: false, signedIn: isPresent(user) }))
  })
}

export const updateUserData = (data) => ({
  type: UPDATE_USER_DATA,
  payload: {
    ...data
  }
});
export const updateUserFailure = error => ({
  type: UPDATE_USER_FAILED,
  payload: {
    error
  }
});
