import React from 'react';
import { isBlank } from "../../../helpers/common";
import { RichTextSection } from "../../../common/RichTextEditor";

export const ExpectedResultsSection = ({ object }) => {
  if (isBlank(object.expected_results)) return null;

  return <div className="px-3 mb-3">
    <h3>Expected results</h3>
    <RichTextSection text={object.expected_results}/>
  </div>;
}
export default ExpectedResultsSection;
