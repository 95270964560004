import React from 'react';
import ChoiceItem from './ChoiceItem';
import {sortChoicesByCreatedAt} from "../../../models/decision_choices";
import {qSortArray} from "../../../helpers/common";

const PickyChoices = ({object, choicesVotes, setChoicesVotes, user}) =>
  <div className='px-3'>
    {
      qSortArray(choicesVotes, true, sortChoicesByCreatedAt).map((choice) =>
        <ChoiceItem choice={choice} choicesVotes={choicesVotes} setChoicesVotes={setChoicesVotes}
                    object={object} key={`picky-choice-${choice.slug}`} selected={choice.vote?.picked} user={user} />
      )}
  </div>

export default (PickyChoices);
