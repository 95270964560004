import React from 'react';
import { connect } from "react-redux";
import {isBlank, isPresent} from "../../../../helpers/common";
import CurrencyInput from "../../../../common/CurrencyInput";
import { InputCurrencyHeader } from "../../../../tree_wizard/steps_wizard/steps/HistoricalDecisionStep"
import AccordionWrapper from "../../../../common/AccordionWrapper";

const ExpectedInvestmentInput = ({ object, decision, expectedInvestment, setExpectedInvestment }) => {
  if (isBlank(decision)) return null;

  const accordionObject = {
    header: <InputCurrencyHeader headerText={'Expected investment or cost'}/>,
    body:<CurrencyInput key={'dsight-decision-currency-input-investment'}
                        value={expectedInvestment}
                        onChange={(value) => setExpectedInvestment(value || '')}
                        placeholder={'Enter a dollar amount'}
                        name={'expected-investment'}
                        id={'expected-investment'} />,
    defaultExpand: isPresent(object.expected_investment)
  };

  return <AccordionWrapper accordionObject={accordionObject} className='mb-3' />
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps)(ExpectedInvestmentInput);
