import React from 'react'
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { isBlank } from "../../../../helpers/common";
import { updateRecordDecision } from "../../../../store/sidebar/actions";
import { flow } from "../../../../helpers/decision_flows";

const DecisionFlowSwitcher = ({ decision, flowEnum, setFlowEnum, setRateSupport }) => {
  if (isBlank(decision)) return null;

  const onChangeFlowEnum = (newFlow) => {
    setFlowEnum(newFlow);
    if(newFlow === flow.D_SIGHT_RECOMMENDATION) setRateSupport(false)
  };
  return <div className="mb-3">
    <h3>Objective</h3>
    <div className="row">
      <div className="col-12 col-sm-6 pe-sm-1 mb-2 mb-sm-0" >
        <Button className={`w-100 ${flowEnum === flow.D_SIGHT_RECOMMENDATION ? 'btn-primary' : 'btn-secondary'}`} onClick={() => onChangeFlowEnum(flow.D_SIGHT_RECOMMENDATION)}>Enter a recommendation</Button>
      </div>
      <div className="col-12 col-sm-6 ps-sm-1">
        <Button className={`w-100 ${flowEnum === flow.D_SIGHT ? 'btn-primary' : 'btn-secondary'}`} onClick={() => onChangeFlowEnum(flow.D_SIGHT)}>Enter what was decided</Button>
      </div>
    </div>
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecordDecision })(DecisionFlowSwitcher);
