import { useEffect } from "react";
import { BASIS_CHANGE_OPTIONS_KEYS } from "../side_panel/large_scale_input/BasisChange";
import {
  parseRowCellId,
  updateCells,
  onCellValueChanged,
  runDelayedUpdateData,
  rowCmus,
  calculateScopedCmusYearAgoChanges
} from "./common"
import { calcYARootFromPrevSimVsYa, calcRootFromSimVs, calcSimVsValue } from "./ag_grid_formulas";
import { ROW_DRIVER_ID_KEY, CHANGE_TYPE_TABS } from "./ag_grid_vars";
import { isPresent } from "../../helpers/common";
import { MESSAGES, showOverlayWithMessage } from "./custom_loading_overlay";
import { selectedCmuCombinations } from "./scopes_helpers";
import { calculateYearAgoChanges } from "../tabs/components/ScenariosChart";

const buildNewValue = (changeType, amount, prevValue) =>
  changeType === CHANGE_TYPE_TABS.increaseDecreaseCurrentValue ? (Number(prevValue) + Number(amount)) : amount

const addToList = (list, childRowNode, prevValue, period, newValue) => {
  list.push({ node: { ...childRowNode, prevValue: prevValue }, colDef: { field: period.name, colId: period.id }, newValue: newValue })
}

const addToUpdateData = (delayedUpdateData, childRowNode, period, newValue) => {
  delayedUpdateData.push({ node: childRowNode, key: period.name, value: newValue })
}

const replaceCurrentValue = (list, rowNode, periods, factToUpdate, cmusCombinations, forecastScenario, changeType, amount, delayedUpdateData) => {
  const childRowNode = rowNode.allLeafChildren[0];
  const { driverId } = parseRowCellId(childRowNode.data[ROW_DRIVER_ID_KEY])
  const cmus = rowCmus(rowNode)
  if(matchFilters(factToUpdate, cmusCombinations, driverId, cmus)) {
    periods.forEach(period => {
      const newValue = buildNewValue(changeType, amount, childRowNode.data[period.name]);
      addToList(list, childRowNode, childRowNode.data[period.name], period, newValue);
      addToUpdateData(delayedUpdateData, childRowNode, period, newValue)
    })
  }
}

const matchFilters = (factToUpdate, cmusCombinations, driverId, cmus) => {
  return factToUpdate.id === Number(driverId) &&
    cmusCombinations.some(applyToCmuIds => applyToCmuIds.every(cmuId => cmus.includes(Number(cmuId))))
}

const prepareReplaceData = (rowNode, forecastScenario) => {
  const childRowNode = rowNode.allLeafChildren[0];
  const { driverId } = parseRowCellId(childRowNode.data[ROW_DRIVER_ID_KEY])
  return {
    childRowNode,
    driverId,
    driverColumn: forecastScenario.config?.allFactsColumns.find(column => column.id === Number(driverId)),
    cmus: rowCmus(rowNode)
  }
}

const replaceSimVsBenchmark = (list, rowNode, periods, factToUpdate, cmusCombinations, forecastScenario, changeType, amount, delayedUpdateData, forecastBenchmarkScenario) => {
  const { childRowNode, driverId, driverColumn, cmus } = prepareReplaceData(rowNode, forecastScenario);
  if(matchFilters(factToUpdate, cmusCombinations, driverId, cmus)) {
    periods.forEach(period => {
      const benchmarkValue = forecastBenchmarkScenario.aggregateBy({ cmus, period, driver: driverColumn });
      const prevValue = childRowNode.data[period.name];
      const simVsBmValue = calcSimVsValue(prevValue, benchmarkValue);
      const newSimVsBmValue = buildNewValue(changeType, amount, simVsBmValue);
      const newValue = calcRootFromSimVs(benchmarkValue , newSimVsBmValue);
      addToList(list, childRowNode, prevValue, period, newValue);
      addToUpdateData(delayedUpdateData, childRowNode, period, newValue)
    })
  }
}

const replaceSimVsYa = (list, rowNode, periods, factToUpdate, cmusCombinations, forecastScenario, changeType, amount, delayedUpdateData) => {
  const { childRowNode, driverId, driverColumn, cmus } = prepareReplaceData(rowNode, forecastScenario);
  if(matchFilters(factToUpdate, cmusCombinations, driverId, cmus)) {
    const allPeriods = forecastScenario.allTimeScalePeriods();
    periods.forEach(period => {
      const prevYaValue = childRowNode.data[period.name];
      const prevSimVsYa = calculateScopedCmusYearAgoChanges({
        currentValue: prevYaValue, scenario: forecastScenario,
        period, cmus, driverColumn, allPeriods
      })
      const yARootValue = calcYARootFromPrevSimVsYa(prevYaValue, prevSimVsYa);
      const newSimVsYaValue = buildNewValue(changeType, amount, prevSimVsYa);
      const newYaValue = calcRootFromSimVs(yARootValue, newSimVsYaValue);
      addToList(list, childRowNode, prevYaValue, period, newYaValue)
      addToUpdateData(delayedUpdateData, childRowNode, period, newYaValue)
    })
  }
}

const relatedCell = (editedCells, nodeId, periodId) =>
  editedCells.find(cell => cell.nodeId === nodeId && cell.periodId === periodId)

const handleCurrentBasisChange = (list, amount, periods, row, driverId, changeType, editedCells) => {
  periods.forEach(period => {
    const driverData = row.fetchDriverData({ id: driverId });
    const relatedEditedCell = relatedCell(editedCells, row.cmusDriverId, period.id);
    const actualPrevValue = relatedEditedCell?.value || driverData.base;
    const newValue = buildNewValue(changeType, amount, actualPrevValue);
    addToList(list, { id: row.cmusDriverId }, actualPrevValue, period, newValue);
  })
}

const handleSimVsBenchmarkBasisChange = (list, amount, periods, row, driverId, changeType, forecastBenchmarkScenario, editedCells) => {
  periods.forEach(period => {
    const benchmarkRow = forecastBenchmarkScenario.findRowBy(row.cmus, period.id, row.selectedDriver.id);
    const benchmarkDriverData = benchmarkRow.fetchDriverData({ id: benchmarkRow.selectedDriver.id });
    const driverData = row.fetchDriverData({ id: driverId });
    const relatedEditedCell = relatedCell(editedCells, row.cmusDriverId, period.id);
    const actualPrevValue = relatedEditedCell?.value || driverData.base;
    const prevSimValue = calcSimVsValue(actualPrevValue, benchmarkDriverData.base);
    const newSimValue = buildNewValue(changeType, amount, prevSimValue);
    const newValue = calcRootFromSimVs(benchmarkDriverData.base, newSimValue);
    addToList(list, { id: row.cmusDriverId }, actualPrevValue, period, newValue);
  })
}

const handleSimVsYaBasisChange = (list, amount, periods, row, driverId, changeType, forecastScenario, allPeriods, editedCells) => {
  periods.forEach(period => {
    const currentValue = forecastScenario.aggregateBy({
      cmus: row.cmus,
      period: period,
      driver: row.selectedDriver
    })
    const relatedEditedCell = relatedCell(editedCells, row.cmusDriverId, period.id);
    const actualPrevValue = relatedEditedCell?.value || currentValue;
    const { value: prevSimVsYa, prevYearValue } = calculateYearAgoChanges({
      actualPrevValue, scenario: forecastScenario,
      period, cmuId: row.cmus[0], driverColumn: row.selectedDriver, allPeriods
    })
    const newSimVsYaValue = buildNewValue(changeType, amount, prevSimVsYa);
    const newYaValue = calcRootFromSimVs(prevYearValue, newSimVsYaValue);
    addToList(list, { id: row.cmusDriverId }, actualPrevValue, period, newYaValue);
  })
}


const applyNotEditableLargeScaleInput = ({
                                           gridRef,
                                           basisChange,
                                           factToUpdate,
                                           changeType,
                                           amount,
                                           periods,
                                           cmusCombinations,
                                           forecastScenario,
                                           forecastBenchmarkScenario,
                                           editedCells,
                                           updateScenario,
                                           timeScale,
                                           runModelCells,
                                           callback
                                         }) => {
  let list = [];
  const allPeriods = forecastScenario.allTimeScalePeriods(timeScale);
  const periodIds = periods.map(period => period.id);
  forecastScenario.rows.filter(row => periodIds.includes(row.attributes.tp_id)).forEach(row => {
    const driverId = row.selectedDriver?.id;
    const cmus = row.cmus;
    if(matchFilters(factToUpdate, cmusCombinations, driverId, cmus)) {
      switch (basisChange) {
        case BASIS_CHANGE_OPTIONS_KEYS.current:
          handleCurrentBasisChange(list, amount, periods, row, driverId, changeType, editedCells)
          break;
        case BASIS_CHANGE_OPTIONS_KEYS.simVsBenchmark:
          handleSimVsBenchmarkBasisChange(list, amount, periods, row, driverId, changeType, forecastBenchmarkScenario, editedCells)
          break;
        case BASIS_CHANGE_OPTIONS_KEYS.simVsYearAgo:
          handleSimVsYaBasisChange(list, amount, periods, row, driverId, changeType, forecastScenario, allPeriods, editedCells)
          break;
      }
    }
  })
  updateCells(gridRef, editedCells, forecastScenario, runModelCells, list, timeScale, updateScenario, callback)
}

const applyEditableLargeScaleInput = ({
                                        gridRef,
                                        basisChange,
                                        factToUpdate,
                                        changeType,
                                        amount,
                                        periods,
                                        cmusCombinations,
                                        forecastScenario,
                                        forecastBenchmarkScenario,
                                        editedCells,
                                        updateScenario,
                                        timeScale,
                                        runModelCells,
                                        callback
                                      }) => {
  let list = [];
  let delayedUpdateData = [];

  gridRef.current.api.forEachNode((rowNode) => {
    if(rowNode.leafGroup) {
      switch (basisChange) {
        case BASIS_CHANGE_OPTIONS_KEYS.current:
          replaceCurrentValue(list, rowNode, periods, factToUpdate, cmusCombinations, forecastScenario, changeType, amount, delayedUpdateData)
          break;
        case BASIS_CHANGE_OPTIONS_KEYS.simVsBenchmark:
          replaceSimVsBenchmark(list, rowNode, periods, factToUpdate, cmusCombinations, forecastScenario, changeType, amount, delayedUpdateData, forecastBenchmarkScenario)
          break;
        case BASIS_CHANGE_OPTIONS_KEYS.simVsYearAgo:
          replaceSimVsYa(list, rowNode, periods, factToUpdate, cmusCombinations, forecastScenario, changeType, amount, delayedUpdateData, forecastBenchmarkScenario)
          break;
      }
    }
  })
  onCellValueChanged(forecastScenario, [], list, gridRef, editedCells, updateScenario, timeScale, runModelCells, () => {
    runDelayedUpdateData(gridRef, delayedUpdateData);
    callback();
  })
}

export const applyLargeScaleInput = ({ forecastScenario, largeScaleInput, ...opts }, callback) => {
  const { from, to, scopes, ...largeScaleInputOpts } = largeScaleInput;
  const timeScale = forecastScenario.editableTimeScale;
  const periods = forecastScenario.periods({ from, to }, timeScale);
  const cmusCombinations = selectedCmuCombinations(scopes)
  const isEditableTimeScaleSelected = timeScale === forecastScenario.timeScale;
  const builtOpts = { periods, cmusCombinations, forecastScenario, timeScale }
  if(!isEditableTimeScaleSelected) return applyNotEditableLargeScaleInput({
    ...opts,
    ...largeScaleInputOpts,
    ...builtOpts,
    callback
  })

  applyEditableLargeScaleInput({
    ...opts,
    ...largeScaleInputOpts,
    ...builtOpts,
    callback
  })
}

export const useApplyLargeScaleInputEffect = ({ gridRef, forecast_simulator_scenario, updateScenarioData, ...opts }) => {
  useEffect(() => {
    if(isPresent(forecast_simulator_scenario.large_scale_input)) {
      showOverlayWithMessage(gridRef.current?.api, updateScenarioData, MESSAGES.updating_scenario);
      setTimeout(() => {
        applyLargeScaleInput({
            gridRef,
            largeScaleInput: forecast_simulator_scenario.large_scale_input,
            ...opts
          },
          () => updateScenarioData({ large_scale_input: {} }));
      }, 0)
    }
  }, [forecast_simulator_scenario.large_scale_input])
}
