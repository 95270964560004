import React from 'react';
import { uniqArray } from "../../../../../helpers/common";
import { findNodeValue } from "../../../../../models/d_sight/metric_chart_helpers";
import { sortNodes } from "./helpers";
import { prepareChartData } from "../helpers/line_helpers";
import OverTimeChart from "../components/OverTimeChart";
import LastPeriodChart from "../components/LastPeriodChart";
import NormChart from "../components/NormChart";

export const AdvLineChart = ({
                               d_sight_goal, process_data, surveys, timeNode,
                               metric, viewMetric,
                               ...opts
                             }) => {
  const sortedNodeNames = sortNodes(
    uniqArray(process_data.map(p => findNodeValue(p.nodes, metric.node_index))),
    d_sight_goal
  )
  const preparedData = prepareChartData({
    process_data, surveys, timeNode, sortedNodeNames, d_sight_goal, metric
  })

  const passOptions = {
    metric: viewMetric || metric,
    timeNode, d_sight_goal
  }

  return <>
    <OverTimeChart {...{...opts, ...preparedData, ...passOptions}} />
    <LastPeriodChart {...{...opts, ...preparedData, ...passOptions, sortedNodeNames}}  />
    <NormChart {...{...opts, ...preparedData, ...passOptions}}  />
  </>
}
