import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import SidebarNavigation from "./navigation";
import DecisionsCenterTab from "./tabs/DecisionsCenterTab";
import SearchDecisionTab from "./tabs/SearchDecisionTab";
import GroupsTab from "./tabs/GroupsTab";
import UsersTab from "./tabs/UsersTab";
import CreateDecisionTab from "./tabs/CreateDecisionTab";
import TemplatesTab from "./tabs/TemplatesTab";
import CategoriesTab from "./tabs/CategoriesTab";
import DSightTab from "./tabs/DSightTab";
import ReportInsightsTab from "./tabs/ReportInsightsTab";
import ForecastSimulatorTab from "./tabs/ForecastSimulatorTab";
import {
  isCategoriesCenter,
  isCreateDecisionCenter,
  isDecisionsCenter,
  isDSightTab,
  isGroupsCenter,
  isGuest,
  isLearningTab,
  isReportInsightsTab,
  isSearchDecisions,
  isTemplatesCenter,
  isUsersCenter,
  isForecastSimulatorTab,
  TABS_URLS
} from "../helpers/home_helpers";
import { isBlank } from "../helpers/common";
import LearningTab from "./tabs/LearningTab";
import EntryPoint from "../EntryPoint";

const TabContent = ({ home, current_user, current_org, match }) => {
  if (isGuest(current_user) || isSearchDecisions(home)) return <SearchDecisionTab  />
  if (isGroupsCenter(home, current_user)) return <GroupsTab match={match}/>
  if (isUsersCenter(home, current_user)) return <UsersTab/>
  if (isCreateDecisionCenter(home, current_user)) return <CreateDecisionTab/>
  if (isTemplatesCenter(home, current_user)) return <TemplatesTab/>
  if (isDecisionsCenter(home)) return <DecisionsCenterTab/>
  if (isCategoriesCenter(home, current_user)) return <CategoriesTab match={match}/>
  if (isDSightTab(home, current_user, current_org)) return <DSightTab match={match} />
  if (isLearningTab(home, current_user)) return <LearningTab />
  if (isReportInsightsTab(home, current_user, current_org)) return <ReportInsightsTab />
  if (isForecastSimulatorTab(home, current_user, current_org)) return <ForecastSimulatorTab />
  return null;
}

const isInvalidTab = (home, current_user, current_org) => {
  if (isSearchDecisions(home)) return false;
  if (isGroupsCenter(home, current_user)) return false;
  if (isDSightTab(home, current_user, current_org)) return false;
  if (isUsersCenter(home, current_user)) return false;
  if (isCreateDecisionCenter(home, current_user)) return false;
  if (isTemplatesCenter(home, current_user)) return false;
  if (isCategoriesCenter(home, current_user)) return false;
  if (isDecisionsCenter(home)) return false;
  if (isReportInsightsTab(home, current_user, current_org)) return false;
  if (isForecastSimulatorTab(home, current_user, current_org)) return false;

  return !isLearningTab(home, current_user);
}

export const TabManager = ({home, current_user, current_org, match}) => {
  if (isBlank(home.loaded)) return null;
  if (isBlank(current_user.org_role)) return null;

  const history = useHistory();

  useEffect(() => {
    if (home.loaded) {
      if (isGuest(current_user)) {
        if (!isSearchDecisions(home)) {
          history.push(`/homepage/${TABS_URLS.search_decisions}`);
        }
      } else if (isInvalidTab(home, current_user, current_org)) {
        history.push(`/homepage/${TABS_URLS.decisions_center}`);
      }
    }
  }, [home.loaded, current_org])

  const fullWidthContainer = () => {
    if (isUsersCenter(home, current_user))
      return true;

    return false;
  }

  return <div className="d-flex w-100 overflow-auto">
    <SidebarNavigation displayCurrent={true}/>
    <div className="wrapper-container width-fit-content overflow-auto w-100">
      <div className={`home container mt-3 ${fullWidthContainer() ? 'mx-auto' : ''}`}>
        <TabContent home={home} current_user={current_user} current_org={current_org} match={match} />
      </div>
    </div>
  </div>
}
const mapStateToProps = ({ home, current_user, current_org }) => ({ home, current_user, current_org });
export default connect(mapStateToProps)(TabManager);
