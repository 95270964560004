import {
  DSIGHT_METRICS_LOAD_DATA,
  DSIGHT_METRICS_LOAD_DATA_FAILED,
  DSIGHT_METRICS_LOAD_DATA_STARTED,
  DSIGHT_METRICS_RESET_DATA,
  DSIGHT_METRICS_UPDATE_DATA
} from "./types";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import { loadGoalMetricData, loadGoalMetricsData } from "../../utils/Api";

export const loadGoalMetrics = (slug) => (dispatch) => {
  dispatch(loadMetricsStarted())
  loadGoalMetricsData({ slug }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadMetricsFailure });

    const { data } = response;
    const { metrics_data } = data
    dispatch(loadMetricsSuccess({ ...metrics_data, slug, loaded: true }))
  })
}

export const loadGoalMetric = (metricSlug, callback) => (dispatch, getState) => {
  const slug = getState().metrics.slug
  loadGoalMetricData({slug, metricSlug}).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadMetricsFailure }, callback);

    const { data } = response;
    const { chartsData } = data;
    const metric_charts = { ...getState().metrics.metric_charts }
    metric_charts[slug] = chartsData
    dispatch(updateMetricsData({ metric_charts }));
    callback(chartsData)
  })
}

export const resetMetricsChartData = () => (dispatch) => {
  dispatch(updateMetricsData({ metric_charts: [] }))
};

// Helpers
export const resetMetrics = (data = {}) => ({
  type: DSIGHT_METRICS_RESET_DATA,
  payload: {
    ...data
  }
});
export const loadMetricsSuccess = (data) => ({
  type: DSIGHT_METRICS_LOAD_DATA,
  payload: {
    ...data
  }
});
export const updateMetricsData = (data) => ({
  type: DSIGHT_METRICS_UPDATE_DATA,
  payload: {
    ...data
  }
});
export const loadMetricsStarted = (query = {}) => ({
  type: DSIGHT_METRICS_LOAD_DATA_STARTED,
  payload: {
    ...query
  }
});
export const loadMetricsFailure = error => ({
  type: DSIGHT_METRICS_LOAD_DATA_FAILED,
  payload: {
    error
  }
});
