import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { agGridInit } from "../helpers/ag_grid_helpers";
import ActionsRow from "./components/ActionsRow";
import { allContacts } from "../../helpers/filter_helpers";
import { isStoreLoading } from "../../helpers/callbacks_helpers";
import { Loader } from "../../common/Loader";
import { runModel, updateScenario, updateScenarioData, syncAgGridRows } from "../../store/forecast_simulator_scenario/actions";
import { setLargeScalePanelOpen } from "../../store/sidebar/actions";

const ScenarioForecastData = ({
                                forecast_simulator_scenario, forecastScenario, forecastBenchmarkScenario,
                                contactsData, current_user, gridRef,
                                ...opts
                              }) => {
  const [runModelActive, setRunModelActive] = useState(false);
  const { onRunModel, ...agGridOpts } = agGridInit({
    forecast_simulator_scenario,
    forecastScenario,
    forecastBenchmarkScenario,
    setRunModelActive,
    gridRef,
    ...opts
  })
  const allContactsData = useMemo(() => allContacts(contactsData, current_user), [contactsData.contacts, current_user]);
  const userEmail = forecast_simulator_scenario.scenario.data.attributes.user_email;
  const scenarioUser = allContactsData.find(i => i.email === userEmail) || { email: userEmail, value: userEmail  }

  if (isStoreLoading(forecast_simulator_scenario)) return <Loader />

  return <>
    <ActionsRow {...{ gridRef, forecastScenario, forecast_simulator_scenario, user: scenarioUser, runModelActive, onRunModel }} />
    <div className="row">
      <div className="col ag-theme-quartz ag-quartz-styles">
        <AgGridReact {...{...agGridOpts}} ref={gridRef} />
      </div>
    </div>
  </>
}

const mapStateToProps = ({forecast_simulator_scenario, contacts, current_user}) => ({
  forecast_simulator_scenario, contactsData: contacts, current_user
});
const mapDispatchToProps = (dispatch) => ({
  updateScenario: (scenario_id, updateData, callback) =>
    dispatch(updateScenario(scenario_id, updateData, callback, false)),
  runModel: (scenario_id, data, callback) => dispatch(runModel(scenario_id, data, callback)),
  updateScenarioData: (data) => dispatch(updateScenarioData(data)),
  syncAgGridRows: (scenario_id, data, callback) => dispatch(syncAgGridRows(scenario_id, data, callback)),
  setLargeScalePanelOpen: (open, selectedDriverId) => dispatch(setLargeScalePanelOpen(open, selectedDriverId))
});
export default connect(mapStateToProps, mapDispatchToProps)(ScenarioForecastData);
