import DecisionCategory from "./decision_category";

export const OPEN = 'open';
export const PICK_ONE_STATIC = 'pick_one_static';
export const PICK_ONE_TYPEAHEAD = 'pick_one_typeahead';
export const PICK_ALL_THAT_APPLY = 'pick_all_that_apply';

export const NAME_LIMIT = 30;

export const REPORT_PROPERTY_TYPES = {
  open: OPEN,
  pick_one_static: PICK_ONE_STATIC,
  pick_one_typeahead: PICK_ONE_TYPEAHEAD,
  pick_all_that_apply: PICK_ALL_THAT_APPLY
}

export default class ReportProperty extends DecisionCategory {
  constructor(data) {
    super(data);
  }
  get isPickOne() {
    return this.input_type === PICK_ONE_STATIC;
  }

  get isPickOneTypehead() {
    return this.input_type === PICK_ONE_TYPEAHEAD;
  }

  get isPickAllThatApply() {
    return this.input_type === PICK_ALL_THAT_APPLY;
  }
  get isOpen() {
    return this.input_type === OPEN;
  }

  get openInputOption() {
    return this.options[0];
  }
}
