import { isPresent, qSortArray } from "../../helpers/common";
import { ForecastBaseModel } from "./Base";
import * as moment from "moment";

export const generateCustomPeriod = ({ fromPeriod, toPeriod, timeScale }) => {
  const customPeriod = new ForecastTimePeriod({
    id: 'custom',
    attributes: {
      start_date: fromPeriod.start_date,
      end_date: toPeriod.end_date
    }
  })
  customPeriod.timeScale = timeScale
  return customPeriod;
}

export class ForecastTimePeriod extends ForecastBaseModel {
  get name() {
    return this.attributes.name;
  }
  get timeScaleId() {
    return parseInt(this.attributes.ts_id);
  }
  get startDate() {
    return moment(this.start_date);
  }
  get start_date() {
    return this.attributes.start_date
  }
  get endDate() {
    return moment(this.end_date);
  }
  get end_date() {
    return this.attributes.end_date;
  }
  get timeScaleKey() {
    return this.timeScale?.key;
  }
}

export class ForecastTimeScale extends ForecastBaseModel {

  fillPeriods(periods) {
    this.timePeriods = qSortArray(
      periods.filter(period => period.timeScaleId === this.id),
      true, (period) => period.startDate
    )
    this.timePeriods.forEach(period => period.timeScale = this);
  }

  get fromPeriod() {
    return this.timePeriods[0];
  }

  get toPeriod() {
    return this.timePeriods[this.timePeriods.length - 1];
  }

  get displayName() {
    return this.attributes.display_name;
  }
  get key() {
    return this.attributes.config_key;
  }
  get isVisible() {
    return isPresent(this.attributes.visible);;
  }

  get isInitData() {
    return isPresent(this.attributes.config_loaded);
  }
}
