import React, { Fragment, useState, useRef } from 'react';
import { connect } from "react-redux";
import { isDecider, isDeciderReadOnly } from "../../../helpers/user_helpers";
import { CheckForm } from "../../../tree_wizard/helpers/DecisionFlowRow";
import {
  setDecisionInputSidebarOpen, setDriverInputSidebarOpen,
  setRecommendationInputSidebarOpen,
  updateRecordDecision
} from "../../../store/sidebar/actions";
import { updateUserData } from "../../../store/current_user/actions";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";

const SettingsSection = ({ user, decision, updateRecordDecision, updateUserData, setRecommendationInputSidebarOpen,
                           setDecisionInputSidebarOpen, setDriverInputSidebarOpen }) => {

  const isDeciderUser = isDecider(user)
  const isDeciderReadOnlyUser = isDeciderReadOnly(user)

  if (!isDeciderUser && !isDeciderReadOnlyUser || isDSightDecision(decision)) return null;

  const [submit, setSubmit] = useState(true);
  const checkboxRef = useRef(null);

  const onMakeReadOnly = () => {
    setSubmit(false);
    const callback = (success, read_only) => {
      if (success) {
        setRecommendationInputSidebarOpen(false);
        setDecisionInputSidebarOpen(false);
        setDriverInputSidebarOpen(false);
        updateUserData({ decision_role: read_only ? 'decider_read_only' : 'decider' });
        checkboxRef.current && checkboxRef.current.scrollIntoView({ behavior: 'smooth' });
        setSubmit(true);
      }
    };
    updateRecordDecision({ read_only: !decision.read_only }, callback);
  };

  return <div className={'my-2'}>
    <h3>Settings</h3>
    <CheckForm
      label={'Make this decision read-only'}
      disabled={!submit}
      type={'checkbox'}
      className='mb-0 mt-2'
      checked={decision.read_only === true}
      onClick={onMakeReadOnly}
      ref={checkboxRef}
    />
  </div>
};

const mapStateToProps = ({ current_user, decision }) => ({ user: current_user, decision });
export default connect(mapStateToProps, {
  updateRecordDecision, updateUserData, setRecommendationInputSidebarOpen,
  setDecisionInputSidebarOpen, setDriverInputSidebarOpen
})(SettingsSection);
