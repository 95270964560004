import { RESET_CHANNELS_DATA, UPDATE_CHANNELS_DATA } from "./types";

export const updateTreeChannelData = (treeData) => (dispatch) => {
  dispatch(updateChannelData({ tree: treeData }));
}

// Common functions
export const updateChannelData = (data) => ({
  type: UPDATE_CHANNELS_DATA,
  payload: {
    ...data
  }
});

export const resetChannelsData = () => ({
  type: RESET_CHANNELS_DATA
});
