import { isPresent, successActions } from "../../helpers/common";
import { deleteWarning } from "../../helpers/alert_helpers";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";

export const duplicateTemplateCallback = (createNewTemplate, template, actions, history, additionalData = {}) => () => {
  history.replace({ search: '' });
  const additionalDataHash = {
    ...additionalData,
    ...{ description: `${template.description} (copy)`, complete: true }
  };
  createNewTemplate({ template_id: template.slug, ...additionalDataHash }, (slug, completed = false) => {
    if (isPresent(slug)) {
      successActions(true, actions);
      setTimeout(() => history.push(`/templates/${slug}${completed ? '' : '/wizard'}`), 100);
    }
  });
}
export const deleteTemplateCallback = (deleteTemplate, actions, history, templateSetSlug) => () => {
  const isFromTemplateSet = history.location.search.includes('template_set');

  deleteWarning(confirmed => {
    if (!confirmed) return;

    deleteTemplate((success) => {
      if (success) {
        if (templateSetSlug && isFromTemplateSet) {
          history.push(`/template_sets/${templateSetSlug}`);
        } else {
          redirectToHomeCallback(success, history, 'templates');
        }
      }
      successActions(success, actions);
    });
  }, { isTemplate: true });
}
export const removeFromTemplateSetCallback = (slug, removeFromTemplateSet, history, { template_set_slug }, actions = []) => () => {
  removeFromTemplateSet(slug , (success) => {
    if (isPresent(success)) {
      successActions(success, actions);
      setTimeout(() => history.push(`/template_sets/${template_set_slug}`), 100);
    }
  });
}
