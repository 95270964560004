import React, { useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import Help from "../../help";
import { saveWizardStepData, updateWizardData } from "../../../store/wizard/actions";
import { Table } from 'react-bootstrap'
import {
  isHistoricalFlow,
  isLastStep,
  isWizardV2, isWizardV3, stepKeyWithCategories,
  wizardStepDataBy
} from "../../../helpers/wizard_helpers";
import { isBlank, isPresent } from "../../../helpers/common";
import CategoriesForm from "../../../decision_categories/CategoriesForm";
import { RichMutedText } from "../../../common/RichTextEditor";
import {
  fetchDefaultSortedSelectedCategories,
  fetchRequiredCategories,
  selectCategoryState
} from "../../../helpers/categories_helpers";
import SelectCategory from "../../helpers/SelectCategory";
import SubmitStepButton, { PreviewBtn, processStep, StartBtn } from "./SubmitStepButton";
import { submitPreviewStepParam, submitTreeStep } from "../../helpers/decision_wizard_helpers";

const getDefaultValues = (org_categories, selected = []) => {
  const result = [];
  org_categories.available_categories
    .filter(c => selected.some(h => c.slug === h.slug))
    .forEach(category => {
      category.options.filter(o => o.default_value).forEach(option => result.push(option.id))
    })
  return result;
}
export const getSavedCategoriesData = (stepData) => isBlank(stepData['categories']) ? [] : stepData['categories']
export const getInitialCategoriesData =
  (org_categories, stepData) => isPresent(stepData['selected']) ?
    getSavedCategoriesData(stepData) :
    getDefaultValues(org_categories, stepData?.selected_categories || [])

export const onChangeCategoryCallback = (categoriesData, setCategoriesData) => (categoryObject, data) => {
  const categoryOptionIds = categoryObject.options.map(option => option.id)
  const resetIds = categoriesData.filter(id => !categoryOptionIds.includes(id));
  if (isBlank(data)) {
    setCategoriesData([...resetIds])
  } else if (categoryObject.isPickAllThatApply) {
    setCategoriesData([...resetIds, ...data.map(o => o.value)])
  } else {
    setCategoriesData([...resetIds, data.value])
  }
}

export const isRequiredCategoriesEmpty = (requiredCategories, categoriesData) => {
  if (isBlank(requiredCategories)) return false;

  return requiredCategories.some(category => !category.options.some(option => categoriesData.includes(option.id)))
}

export const categorizationStepState = ({ wizard, org_categories }) => {
  const stepData = wizardStepDataBy(wizard, stepKeyWithCategories(wizard)) || {}
  const [categoriesData, setCategoriesData] = useState(getInitialCategoriesData(org_categories, stepData))
  const {
    selectedCategories, setSelectedCategories, ...hash
  } = selectCategoryState({
    selected_categories: stepData.stepData, org_categories, categoriesData, setCategoriesData
  })

  const requiredCategories = useMemo(() => fetchRequiredCategories(org_categories, selectedCategories), [org_categories, selectedCategories])

  useEffect(() => {
    if (!org_categories.loaded) return;

    setSelectedCategories(
      fetchDefaultSortedSelectedCategories(org_categories, stepData.selected_categories)
    )
    setCategoriesData(getInitialCategoriesData(org_categories, stepData));
  }, [org_categories.loaded])

  const disabledStep = () => isRequiredCategoriesEmpty(requiredCategories, categoriesData)
  const onChangeCategory = onChangeCategoryCallback(categoriesData, setCategoriesData)
  const submitCategoriesData = () => ({
    categories: categoriesData,
    selected_categories: selectedCategories
  })

  return {
    ...hash,
    selectedCategories, setSelectedCategories,
    stepData, submitCategoriesData,
    categoriesData, setCategoriesData,
    onChangeCategory, disabledStep
  }
}


const V1Header = ({}) =>
  <>
    <span className="text-primary">Let’s make a decision! </span>
    First, categorize this decision.
  </>

const V2Header = ({}) =>
  <>
    <span className="text-primary">Almost there! </span>
    Categorize this decision.
  </>

const Header = ({ wizard }) =>
  <Table borderless className="mb-2">
    <tbody>
    <tr>
      <td className="d-flex p-0">
        <h1 className="d-table-cell pe-2 mb-0">
          { isLastStep(wizard) ? <V2Header/> : <V1Header/> }
        </h1>
        <div className="d-table-cell ms-auto">
          <Help />
        </div>
      </td>
    </tr>
    </tbody>
  </Table>

export const TreeDecisionCategorizationStep = ({
                                                 wizard, org_categories, playbook_notes,
                                                 saveWizardStepData, updateWizardData,
                                                 stepRef
                                               }) => {
  const {
    categoriesData, disabledStep,
    selectedCategories, onSelectCategory, submitCategoriesData,
    ...hash
  } = categorizationStepState({ wizard, org_categories })

  const [submitState, setSubmitState] = useState(false)

  const submitStep = (nav_data = {}, callback = () => {}) => {
    setSubmitState(true)
    saveWizardStepData(stepKeyWithCategories(wizard), {
      preview: submitPreviewStepParam(wizard),
      finish_later: submitPreviewStepParam(wizard),
      complete: isWizardV2(wizard) || (isWizardV3(wizard) && isHistoricalFlow(wizard)),
      ...nav_data,
      ...submitCategoriesData(),
      response: { step_index: wizard.step_index },
      next_step: true
    }, callback)
  }

  stepRef.current.submitStep = (additional_data = {}, callback = () => {}) => {
    if (disabledStep()) {
      additional_data.finish_later = true
    }
    submitStep(additional_data, (success, wizardData) => {
      setSubmitState(false)
      callback(success, additional_data.finish_later, wizardData)
    })
  };
  useEffect(() => {
    updateWizardData({ disabledSubmit: disabledStep() })
  }, [categoriesData])

  return <div className="bg-white rounded p-3 my-3 mx-auto tree_wizard">
    <Header wizard={wizard} />
    <div className="w-100 mb-2">
      <RichMutedText text={playbook_notes.categorization} />
    </div>
    <CategoriesForm className={'w-100 mb-2'}
                    {...{...hash, submitState, selectedCategories, categoriesData}} />
    <SelectCategory selected={selectedCategories}
                    onSelect={onSelectCategory}
                    submitState={submitState}
    />
    <div className="w-100">
      <SubmitStepButton onClick={() => submitTreeStep(wizard, stepRef)} disabled={wizard.submit || submitState || disabledStep()}>
        {
          (isWizardV2(wizard) || isWizardV3(wizard)) ?
            isHistoricalFlow(wizard) ?
              <>Done</> :
              isWizardV3(wizard) ?
                <PreviewBtn /> : <StartBtn wizard={wizard} /> :
            processStep(wizard)
        }
      </SubmitStepButton>
    </div>
  </div>
}
const mapStateToProps = ({ wizard, org_categories, playbook_notes }) => ({
  wizard, org_categories, playbook_notes
});
const mapDispatchToProps = (dispatch) => ({
  saveWizardStepData: (step, data, callback) => {
    dispatch(saveWizardStepData(step, data, callback))
  },
  updateWizardData: (data) => dispatch(updateWizardData(data))
});
const wrapper = React.forwardRef((props, ref) => <TreeDecisionCategorizationStep {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
