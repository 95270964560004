import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooterP0 } from "../../common/modals";
import { NameRow } from "../../homepage/modals/TemplateSetModal";
import { isBlank } from "../../helpers/common";

export const showModal = (modal, { slug }) => modal.type === "SetNameModal" && modal.shown && modal.slug === slug;
export const openModalCallback = (openModal, { slug }) => () => openModal({slug, type: 'SetNameModal'})

export const SetNameModal = ({
                               set, set_key, setTitle, shown = false,
                               onClose = () => {}, updateSet
                            }) => {
  const [name, setName] = useState(set.name)
  const [submit, setSubmit] = useState(false)

  // callbacks
  useEffect(() => {
    if (set.loaded && shown) setName(set.name)
  }, [set.loaded, shown])

  const close = () => onClose()
  const reset = () => {
    setName(set.name)
    setSubmit(false)
    close()
  }
  const submitData = () => {
    setSubmit(true)
    const data = {}
    data[set_key] = { name }
    updateSet(data, success => {
      setSubmit(false)
      if (success) close();
    })
  }

  return <Modal size="md" backdrop="static" show={shown} onHide={reset}>
    <Modal.Body>
      <CloseIcon onClose={reset} />
      <h2>Edit decision flow name</h2>
      <NameRow name={name} setName={setName} disabled={submit} />
    </Modal.Body>
    <ModalDoneFooterP0 disabled={submit || isBlank(name)} onClose={submitData} />
  </Modal>
}
export default SetNameModal;
