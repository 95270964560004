import { LEFT_PANELS } from "../store/sidebar/common_actions";

// NOTE: Tricky way to handle decision_input/recommendation_input panels
// immediate submission with only entered open ended choice
// https://clearboxdecisions.atlassian.net/browse/CP-3725
export const setDoneClicked = (sidebarPanel) => {
  const data = { doneClicked: true };
  const expiration = new Date().getTime() + 5000; // 5 seconds from now
  const dataWithExpiration = { data, expiration };
  localStorage.setItem(`${sidebarPanel}-Done-clicked`, JSON.stringify(dataWithExpiration))
}

export const isDoneClicked = (sidebarPanel) => {
  const storedData = localStorage.getItem(`${sidebarPanel}-Done-clicked`);
  if (storedData) {
    const { data, expiration } = JSON.parse(storedData);
    const currentTime = new Date().getTime();

    if (currentTime < expiration) {
      localStorage.removeItem(`${sidebarPanel}-Done-clicked`)
      return true;
    } else {
      localStorage.removeItem(`${sidebarPanel}-Done-clicked`);
      return false;
    }
  }
}

export const isLeftSidebarOpen = (sidebar) =>
  Object.keys(LEFT_PANELS).some(key => sidebar[key])

export const sidebarWidth = () => document.getElementsByClassName('right-sidebar-panel-wrapper')[0].style.width
