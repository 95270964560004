import * as React from 'react';
import { render } from 'react-dom';
import configureStore from "./store";
import { Provider } from "react-redux";
import { isPresent } from "./helpers/common";
import { LicenseManager } from "ag-grid-enterprise"

export const updateEntryPointData = (match, controller= 'decisions', afterCallback = () => null) => {
  if (isPresent(match?.params?.id)) {
    EntryPoint.instance.objectSlug = match?.params?.id
    EntryPoint.instance.controllerName = controller
    setTimeout(afterCallback, 50)
  }
}

class EntryPoint {
  constructor(data) {
    this.data = data;
    this.objectSlug = data.objectSlug;
    this.controllerName = data.controllerName;
    this.TreeChannelSubscription = null;

    this.flash = data.flash;

    this.org_default_horizontal_logo = data.org_default_horizontal_logo;
    this.org_default_square_logo = data.org_default_square_logo;
    this.org_default_alt_text = data.org_default_alt_text;

    this.sign_in_microsoft_path = data.sign_in_microsoft_path;
    this.sign_in_google_path = data.sign_in_google_path;
    this.slack_channel_id = data.slack_channel_id;
    this.slack_bone_client_id = data.slack_bone_client_id,
    this.slack_bone_bot_user_scopes = data.slack_bone_bot_user_scopes,
    this.slack_bone_user_scope = data.slack_bone_user_scope

    this.pre_fill_data = data.pre_fill_data;
    if(isPresent(data.ag_grid_license)) {
      LicenseManager.setLicenseKey(data.ag_grid_license);
    }
  }

  renderRootPage(page) {
    if (this.rootElement == null) return;

    this.store = configureStore();
    render(<Provider store={this.store}>{page}</Provider>, this.rootElement)
  }

  get rootElement() {
    return document.getElementById('root')
  }

  static initInstance(options) {
    this._instance = new EntryPoint(options);
    return this._instance;
  }

  static get instance() {
    return this._instance;
  }
}

export default EntryPoint;
