import React from 'react';
import { connect } from "react-redux";
import InviteRow from "./InviteRow";
import { uniqueBy } from "../../../helpers/common";
import { inviteWithUser } from "../../../helpers/decision_helpers";
import InviteInput from "./InviteInput";

export const collectInvitesData = (decision_set) =>
  decision_set.invites.filter(i => i.email !== decision_set.user_email)
                      .map(invite => inviteWithUser(invite, decision_set))

const InvitesSection = ({ decision_set, preview= false, isManager = true}) => {
  if (preview) return null;

  const decisionSetInvites = uniqueBy(collectInvitesData(decision_set), 'email');
  return <div className="px-3 pb-3">
    <InviteRow user={ decision_set.user } email={decision_set.user_email} slug={'manager'} isManager={isManager} />
    { decisionSetInvites.map(invite => <InviteRow {...invite} key={`invite-row-${invite.slug}`} isManager={isManager} />) }
    { isManager ? <InviteInput /> : null }
  </div>
}
const mapStateToProps = ({ decision_set }) => ({ decision_set });
export default connect(mapStateToProps)(InvitesSection);
