import React from 'react';
import { connect } from "react-redux";
import { updateChoicesDraft, setDecisionInputSidebarOpen, updateFinalChoices} from "../../../store/sidebar/actions";
import DoneButton from '../../../common/DoneButton';
import { isMobile } from "react-device-detect";
import Decision from "../../../models/decision";
import {isBlank, isPresent} from "../../../helpers/common";
import {setDoneClicked} from "../../../helpers/sidebar_helpers";

const Footer = ({
                  decision,
                  setDecisionInputSidebarOpen, updateFinalChoices, updateChoicesDraft
                }) => {
  if (isBlank(decision)) return null;

  const decisionObj = new Decision(decision);
  const openEndedChoice = decisionObj.openEndedChoice[0];
  const needChoiceSubmission = () => isPresent(openEndedChoice) && !decisionObj.isDecisionRecorded && decisionObj.isOpenEnded;
  const submitChoices = (callback) =>{
    updateFinalChoices(openEndedChoice.slug, {
      final_decision: true,
      reset_final: false,
      ignore_line_breaks: true,
      draft: false
    });
    callback();
  }

  const updateChoicesDraftOnClose = (callback) => {
    if (!decisionObj.isOpenEnded) {
      updateChoicesDraft({draft: false})
    }
    callback();
  }

  const onClose = () => {
    setDoneClicked('decision-input');
    const updateAction = needChoiceSubmission() ? submitChoices : updateChoicesDraftOnClose
    updateAction(() => {
      setDecisionInputSidebarOpen(false)
    });
  }

  return <div className={`${isMobile ? 'mb-5' : 'side-panel bottom'} py-3 px-3`} style={{zIndex: 0}}>
    <DoneButton onClose={onClose} />
  </div>
}
const mapStateToProps = ({ sidebar }) => ({ decision: sidebar.decision });
export default connect(mapStateToProps, { setDecisionInputSidebarOpen, updateFinalChoices, updateChoicesDraft })(Footer);
