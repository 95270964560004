import React, { useCallback } from 'react';
import { connect } from "react-redux";
import Decision from "../../../models/decision";
import OpenEndedDecisionInput from "./OpenEndedDecisionInput";
import ChoicesDecisionInput from "./ChoicesDecisionInput";
import { isBlank } from "../../../helpers/common";
import DecisionEntryModal from "../../modals/DecisionEntryModal";
import MultiEntryModal from "../../modals/MultiEntryModal";
import { openModal, transferDataAndCloseModal, updateDecision } from "../../../store/modals/actions";

export const DecisionModal = ({ decisionObj, modal, transferDataAndCloseModal, updateDecision }) =>
  decisionObj.isRecordedOrRecommended || decisionObj.isTreeHistorical ?
    <DecisionEntryModal key={`decision-entry-modal-${modal.slug}`}
                        show={modal.type === "PanelDecisionEntryModal" && modal.shown}
                        onClose={transferDataAndCloseModal}
                        updateDecision={updateDecision}/> :
    <MultiEntryModal key={`multi-entry-modal-${modal.slug}`}
                     show={modal.type === "PanelDecisionEntryModal" && modal.shown}
                     onClose={transferDataAndCloseModal} />

const DecisionInputBody = ({ decision, openModal, ...opts }) => {
  if (isBlank(decision)) return null;

  const decisionObj = new Decision(decision);
  const CachedDecisionInput = useCallback(() =>
      decisionObj.isOpenEnded ?
        <OpenEndedDecisionInput decision={decision} /> :
        <ChoicesDecisionInput decision={decision} />
    , [decision.choice_entry_widget_type, decision.description]
  )

  return <CachedDecisionInput />
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision, modal: state.modal });
export default connect(mapStateToProps, {
  transferDataAndCloseModal, updateDecision, openModal
})(DecisionInputBody);
