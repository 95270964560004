import React from 'react';
import CloseIcon from "../../common/CloseIcon";
import {EditIcon} from "../../common/EditIcon";

export default ({
                  headerName, openSideBar,
                  showMenu = false, isSetUser = false,
                  isMember = false, isTemplateSet = false,
                  addClass = '', setSetDecisionsSidebarOpen,
                  ...opts
                }) =>
  <div className="side-panel header">
    <div className="d-flex justify-content-between pt-2 px-3">
      <h2 className={`${addClass}`}>{headerName}</h2>
      {!isMember || !showMenu ? null :
        <div className="ms-auto">
          <div className="ms-2">
            <EditIcon onClick={() => {
              setSetDecisionsSidebarOpen(true)
            }}/>
          </div>
        </div>
      }
      <div className="ms-2">
        <CloseIcon onClose={() => openSideBar(false)}/>
      </div>
    </div>
  </div>
