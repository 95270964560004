import { UPDATE_ORG_DATA, UPDATE_ORG_FAILED } from "./types";
import { loadReportsRequest } from "../../utils/Api";
import { loadForecastSimulatorScenarios } from "../../utils/Api";
import { failedResponseHandler, failedResponseHandlerWithDispatchCallback, isResponseFailed } from "../../helpers/store_helpers";
import { isPresent } from "../../helpers/common";

export const reloadOrgReports = () => (dispatch, getState) => {
  if (isPresent(getState().current_org.reports_loading)) return;

  updateOrgData({ reports_loading: true })
  loadReportsRequest().then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: updateOrgFailure });

    const { data } = response;
    const { reports } = data;
    dispatch(updateOrgData({ reports, reports_loading: false }));
  })
}

export const reloadOrgForecastScenariosData = () => (dispatch, getState)=> {
  if (isPresent(getState().current_org.forecast_simulator_loading)) return;

  updateOrgData({ forecast_simulator_loading: true })
  loadForecastSimulatorScenarios({ cached: true }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateOrgFailure });

    const { scenarios } = response.data;
    dispatch(updateOrgData({ forecast_simulator_scenarios: scenarios, forecast_simulator_loading: false }))
  })
}

export const updateOrgData = (data) => ({
  type: UPDATE_ORG_DATA,
  payload: {
    ...data
  }
});
export const updateOrgFailure = error => ({
  type: UPDATE_ORG_FAILED,
  payload: {
    error
  }
});
