import React from "react";
import Toggle from "../../../common/Toggle";
import { isPresent } from "../../../helpers/common";

export const TABS = {
  all_scenarios: 'all_scenarios',
  your_scenarios: 'your_scenarios'
}
const itemTabs = [
  {
    value: TABS.all_scenarios,
    body: 'All scenarios'
  },
  {
    value: TABS.your_scenarios,
    body: 'Your scenarios'
  },
];

export default ({ viewTab, setViewTab }) => <Toggle items={itemTabs}
                                                    selectedItem={viewTab}
                                                    onClick={setViewTab}
                                                    padding={'px-2'}/>


