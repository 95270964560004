import React from 'react';
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Loader } from "../../../common/Loader";
import {
  analysisInitCallbacks,
  BreadcrumbDelimiter,
  GoalBreadcrumb,
  mapDispatchToProps,
  mapStateToProps,
  Wrapper
} from "../index";
import Analysis, { AnalysisGoal } from "../../../models/d_sight/Analysis";
import { isBlank } from "../../../helpers/common";
import ContributingKpiContent from "./ContributingKpiContent";
import { analysisLink } from "../../../helpers/dsight_helpers";
import { isStoresLoading } from "../../../helpers/callbacks_helpers";

export const DSightAnalysisInsightKpi = ({
                                           analysis, d_sight_goal, match, ...opts
                                         }) => {
  analysisInitCallbacks({ analysis, d_sight_goal, match, ...opts })

  if (isStoresLoading(analysis, d_sight_goal)) {
    return <Wrapper>
      <Loader/>
    </Wrapper>;
  } else {
    const analysisObj = new Analysis(analysis, null, null, d_sight_goal.selected_demographic)
    const goalObj = new AnalysisGoal(d_sight_goal.goal_chain)
    const insightId = parseInt(match?.params?.insight_id);
    const kpiId = parseInt(match?.params?.kpi_id);

    const contributingInsightData = analysisObj.contributingInsights.find(record => record.insightData.id === insightId);
    if (isBlank(contributingInsightData)) {
      useHistory().push(analysisLink(analysisObj.link));
      return null;
    }

    const contributingKpiData = contributingInsightData.kpis.find(record => record.insightData.id === kpiId);
    if (isBlank(contributingKpiData)) {
      useHistory().push(analysisLink(contributingInsightData.link));
      return null;
    }

    const breadcrumbs = <>
      <GoalBreadcrumb {...{ ...opts, d_sight_goal, goalObj }} />
      <BreadcrumbDelimiter/>
      <Link to={analysisLink(analysisObj.link)}>
        {analysisObj.name}
      </Link>
      <BreadcrumbDelimiter/>
      <Link to={analysisLink(contributingInsightData.link)}>
        {contributingInsightData.name}
      </Link>
      <BreadcrumbDelimiter/>
      <span>{contributingKpiData.insightData.display_name}</span>
    </>

    return <Wrapper breadcrumbs={breadcrumbs} currentTreeNode={contributingKpiData.insightData.id}>
      <ContributingKpiContent {...{ kpi: contributingKpiData, kpiId, analysisObj, d_sight_goal }}/>
    </Wrapper>;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DSightAnalysisInsightKpi);
