import isEmpty from 'ramda/es/isEmpty'
import AvatarImg from "./images/profile.png";
import {IUser} from "../interfaces/IDecision";

const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

export const inTeams = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getQueryVariable = (variable): string|null => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (const varPairs of vars) {
    const pair = varPairs.split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
};

export const queryToObject = ():object => {
  const result = {};
  const parseValue = (value:string):object|string => {
    try {
      return JSON.parse(value);
    } catch(e) {
      return value;
    }
  };
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (const varPairs of vars) {
    const pair = varPairs.split('=');
    const value = decodeURIComponent(pair[1]);
    result[decodeURIComponent(pair[0])] = parseValue(value);
  }
  return result;
}

export const pageFontSize = ():number => {
  let sizeStr = window.getComputedStyle(document.getElementsByTagName('html')[0]).getPropertyValue('font-size');
  sizeStr = sizeStr.replace('px', '');
  let fontSize = parseInt(sizeStr, 10);
  if (!fontSize) fontSize = 14;
  return fontSize;
};

export const isBlank = (value:any):boolean => {
  return value == null || isEmpty(value) || value === false;
};

export const isPresent = (value:any):boolean => {
  return !isBlank(value);
};

export const compactObject = (object:Object):Object => {
  Object.keys(object).forEach((k) => { if (!object[k]) delete object[k]; });
  return object;
};

export function compact<T>(array:T[]):T[] {
  return array.filter(v => isPresent(v));
}
export function maxObjectBy<T>(array:T[], field:string) {
  return array.reduce((a, b) => a[field] > b[field] ? a : b);
}
export function qSortArray<T>(array:T[], asc:boolean, valueFunction: Function):T[] {
  const mapped = array.map((el, i) => ({ index: i, value: valueFunction(el) }));
  const sortOrder = asc ? 1 : -1;
  mapped.sort((a, b) => a.value > b.value ? 1*sortOrder : (a.value < b.value ? -1*sortOrder : 0));
  return mapped.map((el) => array[el.index]);
}
export const pluralize = (val, word:string, plural:string = word + 's'):string => {
  return `${val} ${[1, -1].includes(Number(val)) ? word : plural}`;
};
export const ordinalize = (i:number):string => {
  const j = i % 10, k = i % 100;
  if (j == 1 && k != 11) return `${i}st`;
  if (j == 2 && k != 12) return `${i}nd`;
  if (j == 3 && k != 13) return `${i}rd`;
  return `${i}th`;
};
export function uniqueBy<T>(array:T[], property:string):T[] {
  const uniqValues = array.reduce((a, d) => {
    if (!a.includes(d[property])) { a.push(d[property]); }
    return a;
  }, []);
  return uniqValues.map(value => array.find(item => item[property] == value));
}
export const toSentence = (collection:string[], {comma = ", ", last_word_selector = " and "} = {}):string => {
  const b = (collection || (collection = [])).pop();
  return (b ? collection.length ? [collection.join(comma), b] : [b] : collection).join(last_word_selector);
};
export const presenceValue = (value) => {
  if (value == null) return '';

  return value;
};
export const truncateText = (text:string, length:number, truncateEnding:string = '...') => {
  if (isBlank(text)) return text;
  if (text.length < length) return text;

  return text.substr(0, length - truncateEnding.length) + truncateEnding;
};
export const getUserAvatar = (user:IUser):string => {
  const avatar = [user.avatar_url, AvatarImg].find(a => isPresent(a));
  return String(avatar);
}
export const zeroPad = (num:number|string, places:number):String => String(num).padStart(places, '0');

export const objectToQuery = (object:object):string => {
  const str = [];
  for (const p in object) {
    if (object.hasOwnProperty(p)) {
      const value = typeof object[p] === 'object' ? JSON.stringify(object[p]) : object[p];
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(value))
    }
  }
  return str.join("&");
}

export const autoLinkUrls = (message:string):string => {
  if (isBlank(message)) return '';

  return message.replace(urlRegex, (url) => {
    let href = url;
    if (!href.match('^https?:\/\/')) href = `https://${href}`;

    return `[${url}](${href})`
  });
}
