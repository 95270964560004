import React, { useState, useRef, useEffect } from 'react';
import { isBlank } from "../helpers/common"
import { renderToString } from 'react-dom/server';

const MIN_SCROLL_HEIGHT = 63;
const WAIT_TIME = 100;

export default ({ textMuted, maxRows = 3, showMoreLess = false, isRichText = false }) => {
  if (isBlank(textMuted)) return <></>;
  if (isBlank(showMoreLess)) return <div className={`note_paragraph ${isRichText ? 'ql-editor' : ''}`}>{textMuted}</div>;

  const text = renderToString(textMuted);
  const [showFullText, setShowFullText] = useState(false);
  const [isOverflown, setIsOverflown] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setTimeout(() => {setIsOverflown(contentRef.current?.scrollHeight > MIN_SCROLL_HEIGHT)}, WAIT_TIME)
    }
  }, [textMuted]);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <>
      <div
        ref={contentRef}
        style={{
          maxHeight: showFullText ? 'none' : `${maxRows * 20}px`,
          overflow: 'hidden'
        }}
        className={`note_paragraph ${isRichText ? 'ql-editor' : ''}`}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
      {isOverflown && (
        <div onClick={toggleText}>
          <span className="text-primary pointer" hidden={!showFullText}>
            Show less <i className={`fa-solid fa-chevron-up fa-sm`} />
          </span>
          <span className="text-primary pointer" hidden={showFullText}>
            Show more <i className="fa-solid fa-chevron-down fa-sm" />
          </span>
        </div>
      )}
    </>
  );
};
