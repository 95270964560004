import React from 'react';
import { connect } from "react-redux";
import HelpButtons from "../../common/HelpButtons";
import { isDSightTemplate } from "../../helpers/template_helpers";
import RegularHelpText from "./RegularHelpText";
import DSightHelpText from "./DSightHelpText";
import BaseHelpPanel from "../../common/BaseHelpPanel";

const HelpPanel = ({ template }) =>
  <BaseHelpPanel addClass="left-side-panel-position tree-page">
    <div className="px-3 mb-3">
      {isDSightTemplate(template) ? <DSightHelpText/> : <RegularHelpText/>}
    </div>
    <HelpButtons />
  </BaseHelpPanel>

const mapStateToProps = ({ template }) => ({ template });
export default connect(mapStateToProps)(HelpPanel);
