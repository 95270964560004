import React from 'react';
import ChoiceItem from "./ChoiceItem";
import {sortChoicesByCreatedAt} from "../../../models/decision_choices";
import {qSortArray} from "../../../helpers/common";

const TopChoice = ({object, choicesVotes, setChoicesVotes, user}) => {
return <div className='px-3'>
  {
    qSortArray(choicesVotes, true, sortChoicesByCreatedAt).map((choice) =>
      <ChoiceItem choice={choice} choicesVotes={choicesVotes} setChoicesVotes={setChoicesVotes}
                  object={object} key={`top-choice-${choice.slug}`} selected={choice.vote?.picked} user={user} />
    )}
  </div>
}

export default (TopChoice);
