export const BENCHMARK_HEADER = 'Benchmark';
export const SIM_VS_BENCHMARK_HEADER = 'Sim vs BM, %';
export const SIM_VS_YA_HEADER = 'Sim vs YA, %';
export const BM_VS_YA_HEADER = 'BM vs YA, %';
export const BENCHMARK_KEY = 'benchmark';
export const SIM_VS_BENCHMARK_KEY = 'simVsBm';
export const SIM_VS_YA_KEY = 'simVsYa';
export const BM_VS_YA_KEY = 'bmVsYa';
export const SIMULATED_FACT_HEADER = 'Simulated';
export const FACT_COMPARISON_HEADERS = [{
  name: BENCHMARK_HEADER,
  key: BENCHMARK_KEY
}, {
  name: SIM_VS_BENCHMARK_HEADER,
  key: SIM_VS_BENCHMARK_KEY
}, {
  name: SIM_VS_YA_HEADER,
  key: SIM_VS_YA_KEY
}, {
  name: BM_VS_YA_HEADER,
  key: BM_VS_YA_KEY
}];
export const FACT_COMPARISON_HEADERS_NAMES = FACT_COMPARISON_HEADERS.map(header => header.name);
export const FACT_COMPARISON_HEADERS_KEYS = FACT_COMPARISON_HEADERS.map(header => header.key);
export const EDITABLE_COMPARISON_HEADERS = [SIM_VS_BENCHMARK_HEADER, SIM_VS_YA_HEADER];
export const EDITABLE_COLUMN_KEYS = [SIM_VS_BENCHMARK_KEY, SIM_VS_YA_KEY];
export const DEFAULT_SIM_VS_VALUE = 0.0;

export const NOT_EDITABLE_COMPARISON_HEADERS = [BENCHMARK_HEADER, BM_VS_YA_HEADER];


export const FACTS_HEADER = 'Facts';
export const YEAR_HEADER_KEY = 'y';
export const ROW_DRIVER_ID_KEY = 'rowDriverId';
export const SUB_FACT_HEADER = 'sub_fact';
export const HIDDEN_COLUMNS = [ROW_DRIVER_ID_KEY, SUB_FACT_HEADER, FACTS_HEADER];

export const TIME_SCALES = ['y', 'q', 'm'];

export const N_A_AG_GRID_VALUE = '-';

export const GROUP_COL_ID_SUFFIX = 'gr';

export const SALES_FACTS = ['Value Sales', 'Volume Sales', 'Unit Sales'];

export const SCENARIO_ROW_ID_SEPARATOR = '|';

export const CHANGE_TYPE_TABS = {
  replaceCurrentValue: 'replaceCurrentValue',
  increaseDecreaseCurrentValue: 'increaseDecreaseCurrentValue',
};