import React, { useState, useEffect, useMemo } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {
  BASE_CHARTS_OPTIONS,
  chartValueFor,
  dataRowWithSignificancePerPeriod,
  POINT_HEADER,
  POINT_SAMPLES_ROW,
  POINT_SIGNS_ROW,
  processDataForTypeNode,
  SERIES_NAME_ROW, surveyProcessDataIndex
} from "../../../../../models/d_sight/metric_chart_helpers";
import ChartSourcesLabels from "../ChartSourcesLabels";
import { capitalizeAllWords, isBlank, isPresent, uniqArray } from "../../../../../helpers/common";
import { BtnDropdownToggleInlineShadowText } from "../../../../../common/dropdowns";
import { BaseDropdownBtn } from "../../../../../common/BaseHamburgerBtn";
import { chartYAxis, getNodeName } from "../../../../../helpers/metric_helpers";
import { advertisingDimension } from "../../../../../models/d_sight/dimensions";

export const CHART_COLORS = ['#0077BB', '#ACACAC']

export const baseLineChartOptions = (metric) => ({
  ...BASE_CHARTS_OPTIONS,

  ...chartYAxis(metric),
  chart: {
    type: 'line',
    spacing: [5, 0, 0, 5],
    style: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },
  tooltip: {
    headerFormat: `${POINT_HEADER}<table>`,
    pointFormat: `<tr>${SERIES_NAME_ROW}${POINT_SIGNS_ROW}${POINT_SAMPLES_ROW}</tr>`,
    footerFormat: '</table>',
    shared: true,
    style: { color: '#1F2F3F' },
    useHTML: true
  },

  legend: {
    itemStyle: {
      color: '#1F2F3F',
      fontWeight: '500',
      fontSize: '0.875rem'
    }
  },
  plotOptions: {
    series: {
      lineWidth: 3
    }
  },
})

const AdvertisingDropdown = ({ ads, d_sight_goal, selectedAdv, setSelectedAdv }) => {
  if (ads.length < 2) return null;

  return <div className="d-block d-md-inline-block text-md-end mb-2 mb-md-0">
    <div className="d-inline-flex text-nowrap me-1">Advertising:</div>
    <BtnDropdownToggleInlineShadowText id="adv-dropdown"
                                       title={capitalizeAllWords(getNodeName(d_sight_goal, selectedAdv))}
                                       onSelect={(adv) => { setSelectedAdv(parseInt(adv)) }} bsPrefix="text-capitalize pe-0">
      {ads.map(node =>
        <BaseDropdownBtn key={`adv-${node}`} eventKey={node} bsPrefix="text-capitalize"
                         title={capitalizeAllWords(getNodeName(d_sight_goal, node))}
                         active={selectedAdv===node}/>
      )}
    </BtnDropdownToggleInlineShadowText>
  </div>
}

export const generateChartOpts = ({ metric, filteredSurveys, chartSeriesData }) => ({
  ...baseLineChartOptions(metric),
  colors: CHART_COLORS,

  xAxis: {
    categories: filteredSurveys.map(s => s.short_name),
    labels: {
      style: {
        fontSize: '0.75rem',
        color: '#1F2F3F'
      }
    }
  },

  series: chartSeriesData.map(({ name, custom, ...row }) => ({
    name, custom,
    data: filteredSurveys.map(s => dataRowWithSignificancePerPeriod(row, s))
  }))
})

const fetchAvailableAds = ({ d_sight_goal, chartData, timeNode }) => {
  const advDimension = advertisingDimension(d_sight_goal)
  const extended_data = processDataForTypeNode({ process_data: chartData.extended_data }, timeNode)
  const ads = uniqArray(extended_data.map(hash => hash.nodes[advDimension.node_index]))
  return {
    advDimension, extended_data, ads
  }
}

export const generateNormChartData = (dataByAdv, normDataByAdv, { surveys, metric }) =>
  ({
    name: 'Norm',
    custom: dataByAdv.custom,
    data: surveys.reduce((result, s) => {
      result[surveyProcessDataIndex(s)] = chartValueFor(s, normDataByAdv, metric)
      return result;
    }, []),
    significance_changes: [],
    samples: []
  })

export default ({
                  surveys, metric, data,
                  sourceLabels, chartData,
                  d_sight_goal, timeNode
                }) => {
  const [selectedAdv, setSelectedAdv] = useState(0)
  const {
    advDimension, extended_data, ads
  } = useMemo(() =>
      fetchAvailableAds({ d_sight_goal, chartData, timeNode })
    , [d_sight_goal, metric.id, timeNode])

  useEffect(() => {
    setSelectedAdv(ads[0])
  }, [metric.id, ads.length])

  if (isBlank(selectedAdv)) return null;

  const normDataByAdv = extended_data.find(hash => hash.nodes[advDimension.node_index] === selectedAdv)
  const dataByAdv = data.find(({ node_id }) => node_id === selectedAdv)

  if (isBlank(dataByAdv)) return null;
  const chartSeriesData = [
    dataByAdv,
    generateNormChartData(dataByAdv, normDataByAdv, { surveys, metric })
  ]

  const filteredSurveys = surveys.filter(s => {
    const index = surveyProcessDataIndex(s)
    return chartSeriesData.some(({ data }) => isPresent(data[index]))
  })

  const chartOptions = generateChartOpts({ metric, filteredSurveys, chartSeriesData })

  return <div className="row mb-3">
    <div className="col">
      <div className="h-100 bg-white rounded p-3">
        <div className="d-block d-md-flex">
          <h3 className="me-2">{ metric.name } norms - {getNodeName(d_sight_goal, selectedAdv)}</h3>
          <div className="text-nowrap ms-auto">
            <AdvertisingDropdown {...{ ads, d_sight_goal, selectedAdv, setSelectedAdv }} />
          </div>
        </div>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} key={`adv-metric-line-chart-${metric.id}`}/>
        {
          sourceLabels && <ChartSourcesLabels {...{ chartData, surveys: filteredSurveys, hideSignificanceLegend: true, legendHint: 'norms' }} />
        }
      </div>
    </div>
  </div>
}
