import React from 'react'
import SearchRow from "../homepage/tabs/helpers/SearchRow";
import {BaseDropdownBtn} from "./BaseHamburgerBtn";
import {BtnDropdownToggleInlineShadowText} from "./dropdowns";

export default ({
                  selectedOption, setSelectedOption,
                  searchQuery, setSearchQuery,
                  options, onClick = (_) => null
                }) =>
  <BtnDropdownToggleInlineShadowText bsPrefix="text-dark fw-normal pe-0 h-auto"
                                     onSelect={setSelectedOption}
                                     title={selectedOption || ''}>
    <div className="px-2 lh-base">
      <SearchRow {...{ searchQuery, setSearchQuery }} bordered hideResults
                 rowStyles={'pb-1'} placeholder={'Search scenarios'}
                 resetSearchAction={() => setSearchQuery('')} />
    </div>
    <div className="dropdown-menu-scroll">
      {
        options.map(({ id, display_name }) =>
          <BaseDropdownBtn key={id} eventKey={id} title={display_name} onClick={() => onClick(id)} />
        )
      }
    </div>
  </BtnDropdownToggleInlineShadowText>
