import React, { useCallback, useMemo } from 'react';
import { connect } from "react-redux";
import { Highlighter, Token, Typeahead } from 'react-bootstrap-typeahead';
import { loadContacts } from "../store/contacts/actions";
import { isGroupContact, isWebGroupContact } from "../helpers/decision_helpers";
import { isBlank, isPresent, qSortArray, uniqueByCallback } from "../helpers/common";
import { isEnter } from "../helpers/keys_helpers";
import { allContacts } from "../helpers/filter_helpers";
import { autoFocusRef } from "./BaseTypehead";
import { checkLoadingEffect } from "../helpers/callbacks_helpers";

const renderNewItemTag = (option, { onRemove, tabIndex, disabled = false }, index) =>
  <Token {...{ onRemove, tabIndex, option, key: index, disabled }}>
    {option.full_name}
  </Token>

const renderNewItem = (option, { text }, _index) =>
  <Highlighter search={text}>{option.full_name}</Highlighter>

const isUserInGroup = (group, allGroups) => {
  if (allGroups || group.type === 'Org') return true;

  return isPresent(group.with_user);
}

const ShareToContactsTypehead = ({
                                   allowGroups = true,
                                   allGroups = false,
                                   allowUsers = false,
                                   multiple = true,
                                   autoFocus = false,
                                   disabled = false,
                                   placeholder = '',
                                   id="share-to-input",
                                   values = [],
                                   excludeValues = [],
                                   setValues = (_) => {},
                                   setLoaded = () => {},
                                   contactsData, loadContacts,
                                   current_user, current_org,
                                   minLength = 1,
                                   errors = {},
                                   setErrors = () => {},
                                   flip = true
                                 }) => {
  checkLoadingEffect(contactsData, loadContacts)

  const allContactsData = useMemo(() =>
    qSortArray(allContacts(contactsData, current_user, current_org),true, (v) => v.value)
      .filter(contact => {
        if (isGroupContact(contact))
          return isWebGroupContact(contact) && allowGroups && isUserInGroup(contact, allGroups)
        if (excludeValues.some(slug => slug === contact.slug)) return false;

        return allowUsers;
      })
    , [contactsData.contacts, current_user]);

  const ref = autoFocusRef({ autoFocus, setLoaded })

  const findItemByName = useCallback(name =>
    allContactsData.find(i => i.full_name.trim() === name.trim()) ||
      allContactsData.find(i => i.full_name.trim().toLowerCase() === name.trim().toLowerCase())
    , [allContactsData])

  const excludedItem = useCallback(item =>
    excludeValues.some(slug => slug === item.slug.toLowerCase())
  , [excludeValues])

  const filterItems = useCallback((newItems) =>
      uniqueByCallback(newItems.filter(item => !excludedItem(item)), (a) => a.value.toLowerCase())
  , [excludeValues])

  const parseInputText = useCallback(() => {
    const value = ref.current.getInput().value;
    if (isBlank(value)) return;
    const new_values = [];
    value.split(',').filter(val => val.trim()).forEach(row => {
      const item = findItemByName(row.trim())
      if (isPresent(item)) {
        new_values.push(item)
        values.push(item)
      }
    })
    setErrors({ ...errors });
    if (isPresent(new_values)) {
      setTimeout(() => setValues(filterItems(values)), 50)
    }
    ref.current.clear()
  }, [ref, contactsData, values, setValues])

  const onKeyDown = useCallback((e) => {
    if (isEnter(e.keyCode)) {
      parseInputText()
    }
  }, [ref, contactsData, values, setValues])

  const onBlur = useCallback(parseInputText, [ref, contactsData, values, setValues])

  const onChangeNewItems = useCallback((selected) => {
    const newItems = [];
    selected.forEach(item => {
      if (typeof item === "string") {
        newItems.push(findItemByName(item))
      } else if (isGroupContact(item) && allowGroups) {
        newItems.push(item)
      }
    })
    setValues(filterItems(newItems))
    setErrors({});
  }, [contactsData.contacts])

  const filteredNewUsersItems = useCallback(() =>
      allContactsData.filter(contact =>
        !values.some(value => [contact.value, contact.slug].includes(value.toString()))
      )
  , [contactsData, allowGroups, excludeValues, values, allGroups])

  return <Typeahead
    {...{ multiple, placeholder, id, ref, onKeyDown, onBlur, disabled, minLength, flip }}
    labelKey="full_name" filterBy={["full_name"]}
    selected={values} options={filteredNewUsersItems()}
    onChange={onChangeNewItems}
    renderMenuItemChildren={renderNewItem}
    renderToken={renderNewItemTag} />
}
const mapStateToProps = ({ contacts, current_user, current_org }) => ({
  contactsData: contacts, current_user, current_org
});
export default connect(mapStateToProps, { loadContacts })(ShareToContactsTypehead);
