import React from 'react';
import OrgSwitcher from "./OrgSwitcher";
import ProfileMenu from "./ProfileMenu";
import NavbarMenu from './NavbarMenu';

const HomepageHeader = ({}) =>
  <header className="homepage-header decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <OrgSwitcher />
      <div className='d-lg-none ms-auto'>
        <NavbarMenu />
      </div>
      <div className="d-none d-lg-flex navbar-nav ms-auto user_image">
        <ProfileMenu />
      </div>
    </nav>
  </header>

export default HomepageHeader;
