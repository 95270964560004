import React from 'react';
import { connect } from "react-redux";
import { setRatingsAndWeightsOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import { isMobile } from "react-device-detect";
import Decision from "../../models/decision";
import WeightedRatingOfChoicesSection from "./sections/WeightedRatingOfChoicesSection";
import DriverWeightsSection from "./sections/DriverWeightsSection";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";

const RatingsAndWeightsPanel = ({ sidebar, decision, wizard, openSideBar, isTemplate = false }) => {
  if (new Decision(decision).isRateAndCompareDriverChoicesHidden) return null;
  if (!wizard.loaded) return null;

  return <SlidingPanel
    type="left"
    isOpen={sidebar.ratingsAndWeightsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper decision-panel details-panel-width left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
    size={52}
  >
    <PanelHeader headerName={'Ratings & weights'} openSideBar={openSideBar} preview={true} />
    <WeightedRatingOfChoicesSection />
    <DriverWeightsSection isTemplate={isTemplate} />
  </SlidingPanel>
}
const mapStateToProps = ({ sidebar, decision, template, wizard }, { isTemplate = false }) => ({
  sidebar, decision: isTemplate ? template : decision, wizard
});
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => dispatch(setRatingsAndWeightsOpen(options))
});
export default connect(mapStateToProps, mapDispatchToProps)(RatingsAndWeightsPanel);
