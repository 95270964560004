import { useEffect } from 'react';

export const NEW_COMMENT_KEY = 'new';

export const initLastOpenedFieldUseEffect = ({
                                               lastOpenedField, fieldKey, submitAction,
                                               showCondition = true
                                             }) => {
  useEffect(() => {
    if (showCondition && lastOpenedField && lastOpenedField !== fieldKey) {
      submitAction()
    }
  }, [showCondition, lastOpenedField, fieldKey])
}
