import React from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { redirectToHomeCallback } from "../../../helpers/decision_helpers";
import { isInIframeOfOwnOrigin, isPresent, successActions } from "../../../helpers/common";
import HamburgerDropdown from "./HamburgerDropdown";
import { AnalysisGoal } from "../../../models/d_sight/Analysis";
import { isStoreLoading } from "../../../helpers/callbacks_helpers";
import OrgLogo from "../../../tree_view/header/OrgLogo";

const MetricsHeader = ({ d_sight_goal, current_org }) => {
  if(isInIframeOfOwnOrigin()) return null;
  if (isStoreLoading(d_sight_goal)) return <nav className="navbar"/>;

  const goalObj = new AnalysisGoal(d_sight_goal.goal_chain)

  const history = useHistory();
  const processBackClick = () => {
    successActions(true, []);
    redirectToHomeCallback(true, history);
  }

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo current_org={current_org} processBackClick={processBackClick} />
        </div>
        <h5>{[d_sight_goal.product?.name, goalObj.goalName, 'Metrics'].filter(isPresent).join(' ')}</h5>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerDropdown />
        </div>
      </div>
    </nav>
  </header>;
}

const mapStateToProps = ({ current_org, d_sight_goal, sidebar }) => ({
  d_sight_goal, current_org, sidebar
});
export default connect(mapStateToProps)(MetricsHeader);
