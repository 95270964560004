import React, { useEffect, useMemo } from 'react';
import { getKeyByValue, isBlank, isPresent } from "../../../../../helpers/common";
import { getNodeName } from "../../../../../helpers/metric_helpers";
import FilteredChartPlaceholder from "../FilteredChartPlaceholder";
import {
  chartBasesValueFor,
  chartValueFor,
  filteredProcessData,
  findProcessDataFor,
  getChatDataFor,
  getSignificanceChangeDataFor,
  hashWithSignificance,
  processDataForTypeNode,
  TIME_NODE_INDEXES, uniqDemographics
} from "../../../../../models/d_sight/metric_chart_helpers";
import { columnBgColor, DEFAULT_CELL, DEFAULT_COLUMN, Table, WHITE_COLUMN } from "../../../../../common/Table";
import { formatPercentage } from "../../../../../homepage/tabs/learning/AllDecisionsStatisticsPanel";
import ReactHtmlParser from 'react-html-parser';

const initialState = { sortBy: [{ id: 'answer', desc: false }] }

const FIRST_COLUMN = {
  Header: 'Answer',
  accessor: 'answer',
  cellClassName: WHITE_COLUMN,
  Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${DEFAULT_COLUMN}`}>{row.original.answer}</div>
}

const generateColumn = (d_sight_goal, data, nodeId, targetNodeName = null) => {
  const nodeName = getNodeName(d_sight_goal, nodeId)
  const nVal = data.map(row => row.nodes[nodeName].sample).find(val => val > 0)
  return {
    Header: <div>
      {nodeName}
      {isPresent(nVal) && <span style={{ fontWeight: 'normal' }}><br/>n={nVal}</span>}
    </div>,
    accessor: nodeId.toString(),
    cellClassName: WHITE_COLUMN,
    sortDescFirst: true,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${columnBgColor(row.original.nodes[nodeName]?.value)}`}>
      {formatPercentage(row.original.nodes[nodeName]?.value)} {displaySignificanceChange(row, nodeName, targetNodeName)}
    </div>
  }
}

const displaySignificanceChange = (row, currentNode, targetNode) => {
  if (isBlank(targetNode)) return '';

  const displayOptions = hashWithSignificance({}, {
    curValue: row.original.nodes[currentNode]?.value,
    mainValue: row.original.nodes[targetNode]?.value,
    curSignificance: row.original.nodes[currentNode]?.significance,
    mainSignificance: row.original.nodes[targetNode]?.significance
  })
  if (isPresent(displayOptions.sigUp)) return ReactHtmlParser(displayOptions.sigUp);
  if (isPresent(displayOptions.sigDown)) return ReactHtmlParser(displayOptions.sigDown);

  return '';
}

export default ({
                  d_sight_goal, chartData, timeNode, selectedSurvey,
                  rows, sortedNodeNames, targetNodeName = null,
                  addAvailableOptions = (_) => {},
                  setData = (_data) => {}
                }) => {
  if (isBlank(sortedNodeNames)) return <FilteredChartPlaceholder/>;

  const data = useMemo(() =>
    rows.map(answer => {
      const answerData = chartData.dynamic_chains.find((hash) => hash.answer === answer)
      addAvailableOptions(uniqDemographics(answerData))
      const process_data = filteredProcessData(answerData, d_sight_goal)
      const filtered_process_data = processDataForTypeNode({ process_data }, timeNode)
      const timeNodeKey = parseInt(getKeyByValue(TIME_NODE_INDEXES, timeNode))
      const row = { answer, nodes: [] }
      sortedNodeNames.forEach(nodeId => {
        const nodeName = getNodeName(d_sight_goal, nodeId)
        const processData = findProcessDataFor(filtered_process_data, nodeId, timeNodeKey);
        row.nodes[nodeName] = {
          value: getChatDataFor(selectedSurvey, processData),
          sample: chartBasesValueFor(selectedSurvey, processData),
          significance: getSignificanceChangeDataFor(selectedSurvey, processData)
        }
        row[nodeId.toString()] = chartValueFor(selectedSurvey, processData, { isPercentage: true }, 0)
      })
      if (sortedNodeNames.every(nodeId => row[nodeId] === null)) return null;

      return row;
    }).filter(isPresent)
  , [rows, sortedNodeNames, chartData, timeNode, selectedSurvey, d_sight_goal.loaded])

  useEffect(() => { setData(data) }, [data.length])

  if (isBlank(data)) return null;

  const columns = [
    FIRST_COLUMN,
    ...sortedNodeNames.map(nodeId =>
      generateColumn(d_sight_goal, data, nodeId, targetNodeName)
    )
  ]

  return <div>
    <Table {...{ columns, data, initialState }} responsive stickyHeader={true} />
  </div>
}
