import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../helpers/common";
import { Loader } from "../../../common/Loader";
import {
  StatisticBlock,
  formatTotalCount,
  formatCost
} from "../learning/AllDecisionsStatisticsPanel";

const StatisticsPanel = ({ statistics, current_org }) =>
  isBlank(statistics) ? <Loader /> :
    <div className="px-2">
      <div className="row all-decisions-row rounded bg-white mb-3 p-3">
        <StatisticBlock number={formatTotalCount(statistics)}
                        label={'Decisions <br/>using reports'} />
        <StatisticBlock number={formatCost({ total_cost: statistics.decision_value })}
                        label={'Decision<br/>value'} />
        <StatisticBlock number={formatCost({ total_cost: statistics.avg_decision_value })}
                        label={'Avg value<br/>per decision'} />
        {
          current_org.allow_report_cost ?
            <StatisticBlock number={formatCost(statistics)}
                            label={'Report<br/>costs'} /> : null
        }
      </div>
    </div>

export const AllReportsStatisticsPanel = ({ home, current_org }) => {
  const { report_statistics_data } = home.report_insights.data;

  return <Fragment>
    <div className="row">
      <div className="col">
        <h1>Report value summary</h1>
      </div>
    </div>
    <StatisticsPanel statistics={report_statistics_data} current_org={current_org} />
  </Fragment>
}

const mapStateToProps = ({ home, current_org }) => ({ home, current_org });
export default connect(mapStateToProps)(AllReportsStatisticsPanel);
