import React from 'react'
import { valueFormatter } from "../../helpers/ag_grid_col_defs";

const TotalsBlock = ({
                       driver, startValue, endValue
                     }) => {
  return <div className="w-100 bg-light rounded-12 py-3 px-2">
    <div className="mb-3">
      <div className="fs-6 mb-2 lh-1">Starting value</div>
      <div className="fw-bolder lh-normal">
        {
          valueFormatter(startValue, { metric: driver?.measure, decimal: driver?.decimal }, ' ')
        }
      </div>
    </div>
    <div className="">
      <div className="fs-6 mb-2 lh-1">Ending value</div>
      <div className="fw-bolder lh-normal">
        {
          valueFormatter(endValue, { metric: driver?.measure, decimal: driver?.decimal }, ' ')
        }
      </div>
    </div>
  </div>
}
export default TotalsBlock;
