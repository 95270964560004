import React, { useCallback, useMemo, useState } from 'react';
import { connect } from "react-redux";
import Select from 'react-select';
import ReportProperty from "../../../models/report_property";
import { ChoiceEntryStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import { isPresent } from "../../../helpers/common";

const PropertyOpenInput = ({ wizard, submitState, openInputDescription, onChangeOpenProperty, propertyObject, setOpenInputDescription }) => {
  const onChangeOpenInput = (value) => {
    onChangeOpenProperty(propertyObject, { value: propertyObject.openInputOption.id, description: value })
    setOpenInputDescription(value)
  }

  return <input className="form-control"
                id="reportPropertyName"
                placeholder="Enter the property"
                value={openInputDescription}
                readOnly={wizard.submit || submitState}
                onChange={(e) => onChangeOpenInput(e.target.value)} />
}

export const PropertyInput = ({ wizard, property, propertiesData, submitState, onChangeProperty, openPropertiesData, onChangeOpenProperty }) => {
  const propertyObject = useMemo(() => new ReportProperty(property), [])
  const openProperty = useMemo(() => openPropertiesData.find(data => data.id === propertyObject.openInputOption?.id), [openPropertiesData, propertyObject])
  const [openInputDescription, setOpenInputDescription] = useState(openProperty?.description || '')

  const PropertySelectOneInput = useCallback(({}) => {
    const propertyObject = new ReportProperty(property)
    return <Select onChange={option => onChangeProperty(propertyObject, option)}
                   defaultValue={propertyObject.sortedOptions.filter(h => propertiesData.includes(h.value))}
                   isDisabled={wizard.submit || submitState} isClearable
                   components={{ IndicatorSeparator:() => null }}
                   options={propertyObject.sortedOptions} placeholder={'Make a selection'}
                   styles={ChoiceEntryStyle} />
  }, [wizard, submitState, propertiesData])

  const PropertySelectOneTypeheadInput = useCallback(({}) => {
    const propertyObject = new ReportProperty(property)
    return <Select onChange={option => onChangeProperty(propertyObject, option)}
                   defaultValue={propertyObject.sortedOptions.filter(h => propertiesData.includes(h.value))}
                   isDisabled={wizard.submit || submitState}
                   components={{ IndicatorSeparator:() => null }}
                   options={propertyObject.sortedOptions} styles={ChoiceEntryStyle}
                   isSearchable isClearable placeholder={'Make a selection'}/>
  }, [wizard, submitState, propertiesData])

  const PropertySelectAllThatApplyInput = useCallback(({}) => {
    const propertyObject = new ReportProperty(property)
    const optionsData = propertyObject.sortedOptions
    return <Select onChange={option => onChangeProperty(propertyObject, option)}
                   defaultValue={propertiesData.map(id => optionsData.find(h => h.value === id)).filter(h => isPresent(h))}
                   isDisabled={wizard.submit || submitState}
                   components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                   options={optionsData} styles={ChoiceEntryStyle}
                   isSearchable isMulti isClearable placeholder={'Select all that apply'} />
  }, [wizard, submitState, propertiesData])

  if (propertyObject.isPickOne) {
    return <PropertySelectOneInput />
  } else if (propertyObject.isPickOneTypehead) {
    return <PropertySelectOneTypeheadInput />
  } else if (propertyObject.isPickAllThatApply) {
    return <PropertySelectAllThatApplyInput />
  } else if (propertyObject.isOpen) {
    return <PropertyOpenInput propertyObject={propertyObject}
                              onChangeOpenProperty={onChangeOpenProperty}
                              wizard={wizard}
                              submitState={submitState}
                              openInputDescription={openInputDescription}
                              setOpenInputDescription={setOpenInputDescription} />
  } else
  {
    return null;
  }
}
const mapStateToProps = ({ wizard }) => ({ wizard });
export default connect(mapStateToProps)(PropertyInput);

