import React from 'react';
import Switch from "react-switch";

export const UNCHECKED_COLOR = '#9AA9B8';
export const CHECKED_COLOR = '#39D4A6';

export default ({ label, value, onChange }) =>
  <label className="m-0 d-flex align-items-center">
    {label}
    <Switch className={`switch-shadow-none ps-1`}
            onChange={onChange} checked={value}
            offColor={UNCHECKED_COLOR} onColor={CHECKED_COLOR}
    />
  </label>
