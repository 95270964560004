import React from "react";
import Toggle from "../../../common/Toggle";
import Amount from "./Amount";
import { CHANGE_TYPE_TABS } from "../../helpers/ag_grid_vars";


const itemTabs = [
  {
    value: CHANGE_TYPE_TABS.replaceCurrentValue,
    body: 'Replace current value',
  },
  {
    value: CHANGE_TYPE_TABS.increaseDecreaseCurrentValue,
    body: 'Increase/decrease current value',
  },
];

const ChangeType = ({ selectedItem, handleToggle, amount, setAmount, factToUpdate, basisChange }) =>
  <>
    <div className="mt-3 mb-0">
      <h3>Change type</h3>
      <Toggle items={itemTabs} selectedItem={selectedItem} onClick={handleToggle} fullWidth={true}/>
    </div>
    <Amount {...{ selectedItem, amount, setAmount, factToUpdate, basisChange } } />
  </>
export default ChangeType;
