import {
  createNewTemplateRequest, createTemplateCollaborators,
  deleteTemplateRequest,
  loadDecisionDriversData,
  removeFromTemplateSetRequest,
  updateTemplateCategories,
  updateTemplateDataRequest,
  updateTemplateUsers,
  removeAdmission
} from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import EntryPoint from "../../EntryPoint";
import { updateTreeData } from "../tree/common_actions";
import { resetDecisionState, updateDecisionData } from "../decision/common_actions";
import {isPresent, uniqueBy} from "../../helpers/common";
import { updateShareData } from "../share/actions";
import { loadPlaybookNotesSuccess } from "../playbook_notes/actions";
import {updateTemplateSetData} from "../template_set/common_actions";
import { loadTemplateFailure, loadTemplateStarted, loadTemplateSuccess, resetTemplate } from "./common_actions";

export const loadTemplate = (data = {}, callback = () => {}) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  dispatch(loadTemplateStarted({}));
  loadDecisionDriversData({controllerName, objectSlug, data}).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTemplateFailure }, callback);

    const { data } = response;
    const { decision, drivers, data_sources, default_user_avatar_url, share_data } = data;
    const { playbook_notes, ...templateData } = decision
    if (isPresent(share_data)) dispatch(updateShareData({ ...share_data }))
    dispatch(loadPlaybookNotesSuccess({ ...(playbook_notes || {}), loaded: true }));
    dispatch(updateTreeData({ drivers, data_sources, default_user_avatar_url }))
    dispatch(resetDecisionState({}));
    dispatch(updateDecisionData({ ...templateData }));
    dispatch(loadTemplateSuccess({ ...templateData, loaded: true }));
    callback(true)
  })
}

// Context
export function updateContext(collaboration_context, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance
    updateTemplateDataRequest(objectSlug, { template: { collaboration_context }}).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTemplateFailure }, callback);

      const { data } = response;
      const { template } = data;
      dispatch(loadTemplateSuccess({ ...template }));
      callback(true)
    })
  }
}
// Template admissions
export const submitTemplateUsers = (data, callback) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance
  updateTemplateUsers(objectSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTemplateFailure }, callback);

    const { data } = response;
    const { all_admissions } = data;
    dispatch(loadTemplateSuccess({ admissions: [...all_admissions] }));
    callback(true)
  })
}

// Template categories
export const submitCategories = (data, callback) =>
  (dispatch) => {
    const { objectSlug } = EntryPoint.instance
    updateTemplateCategories(objectSlug, data).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTemplateFailure }, callback);

      const { data } = response;
      const { category_options, selected_categories } = data;
      dispatch(loadTemplateSuccess({ category_options, selected_categories }));
      callback(true)
    })
  }

// Base template actions
export const createNewTemplate = (data = {}, callback) =>
  (dispatch) => {
    createNewTemplateRequest(data).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTemplateFailure }, callback);

      const { data } = response;
      const { slug, completed = false } = data;
      callback(slug, completed)
    })
  }
export const deleteTemplate = (callback) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance
  deleteTemplateRequest(objectSlug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTemplateFailure }, callback);

    const { template_set_slug } = getState().template
    if (isPresent(template_set_slug) && getState().template_set.slug === template_set_slug) {
      removeTemplateFromSetData(dispatch, getState, objectSlug)
    }

    dispatch(resetTemplate());
    callback(true)
  })
}
export const removeFromTemplateSet = (templateSlug, callback) => (dispatch, getState) => {
  removeFromTemplateSetRequest(templateSlug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTemplateFailure }, callback);

    const { data } = response;
    const { template_set_slug } = data;
    dispatch(resetTemplate());
    removeTemplateFromSetData(dispatch, getState, templateSlug)
    callback(template_set_slug)
  })
}

const removeTemplateFromSetData = (dispatch, getState, templateSlug) => {
  const templates = getState().template_set.templates.filter(({ slug }) => slug !== templateSlug )
  const sections = getState().template_set.sections.map((section) => {
    return { ...section, templates: section.templates.filter(({ slug }) => slug !== templateSlug ) }
  })
  dispatch(updateTemplateSetData({ ...getState().template_set, templates, sections }));
}

export const addCollaborators = (data, callback) => (dispatch, getState) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  createTemplateCollaborators(controllerName, objectSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTemplateFailure }, callback);

    const { data } = response;
    const { new_admissions, new_users } = data;
    const { admissions, users } = getState().template
    dispatch(loadTemplateSuccess({
      admissions: uniqueBy([...admissions, ...new_admissions], 'slug'),
      users: uniqueBy([...users, ...new_users], 'email')
    }));
    callback(true)
  })
}
export const removeCollaborator = (slug, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  removeAdmission(controllerName, objectSlug, slug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTemplateFailure }, callback);

    const { data } = response;
    const { drivers, template } = data;
    dispatch(updateTreeData({ drivers }));
    dispatch(loadTemplateSuccess({ ...template }));
    callback(true)
  })
}