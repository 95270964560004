import React, { useMemo } from 'react';
import { connect } from "react-redux";
import EditGreyPencil from "../shared/EditGreyPencil";
import {TooltipWrapper} from "../../../bubble_tooltip/BubbleTooltip";
import { DECISION_STATES_DATA } from "../../../models/decision_state";

const Icon = ({ decisionObject }) => <>
  <div className={`d-flex ${DECISION_STATES_DATA.completed.color} ${decisionObject.state === DECISION_STATES_DATA.completed.state ? '' : 'd-none'}`}>
    <i className={DECISION_STATES_DATA.completed.icon_classes} />
  </div>
  <div className={`d-flex ${DECISION_STATES_DATA.unstarted.color} ${decisionObject.state === DECISION_STATES_DATA.unstarted.state ? '' : 'd-none'}`}>
    <i className={DECISION_STATES_DATA.unstarted.icon_classes} />
  </div>
  <div className={`d-flex ${DECISION_STATES_DATA.in_flight.color} ${decisionObject.state === DECISION_STATES_DATA.in_flight.state ? '' : 'd-none'}`}>
    <i className={DECISION_STATES_DATA.in_flight.icon_classes} />
  </div>
</>

const DecisionStatus = ({ sidebar, decisionObject }) => {
  const editMode =  useMemo(() =>
      (sidebar.decisionInputSidebar || sidebar.recommendationInputSidebar),
    [sidebar.decisionInputSidebar, sidebar.recommendationInputSidebar])
  if(editMode) return <EditGreyPencil />

  return <TooltipWrapper bubbleIcon={`decision-status-${decisionObject.decision.slug}`}
                         tooltipText={decisionObject.statusTooltipText} isDriver={true}>
    <div className="mt-1 me-2">
      <Icon decisionObject={decisionObject} />
    </div>
  </TooltipWrapper>
}
const mapStateToProps = ({ sidebar }) => ({ sidebar });
export default connect(mapStateToProps, {})(DecisionStatus);