import React, { useEffect } from 'react';
import Select from 'react-select';
import { BorderedFilterStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import { isBlank } from "../../../helpers/common";
import { getMemorizedOptions } from "../../helpers/period_helpers";

const PeriodSelectors = ({ to, setTo, from, setFrom, options, disabled = false }) => {
  const { validToOptions, validFromOptions } = getMemorizedOptions({ from, to, options })
  useEffect(() => {
    if (validToOptions.length > 0 && isBlank(validToOptions.find(option => option.value === to))) {
      setTo(validToOptions[0].value)
    }
  }, [validToOptions, to]);

  return <>
    <div className="col-3 col-lg-3 px-1 mb-2">
      <div className="mb-1">From</div>
      <Select
        defaultValue={validFromOptions.find(option => option.value === from)}
        onChange={(option) => setFrom(option.value)}
        components={{ IndicatorSeparator: () => null }}
        styles={BorderedFilterStyle}
        placeholder="Make a selection"
        value={validFromOptions.find(opt => opt.value === from)}
        isDisabled={disabled}
        options={validFromOptions}/>
    </div>
    <div className="col-3 col-lg-3 px-1 mb-2">
      <div className="mb-1">To</div>
      <Select
        defaultValue={validToOptions.find(option => option.value === to)}
        onChange={(option) => setTo(option.value)}
        components={{ IndicatorSeparator: () => null }}
        styles={BorderedFilterStyle}
        placeholder="Make a selection"
        value={validToOptions.find(opt => opt.value === to)}
        isDisabled={disabled}
        options={validToOptions}/>
    </div>
  </>
}
export default PeriodSelectors;
