import React from 'react';
import { isBlank, isPresent } from "../../helpers/common";
import filesize from 'filesize';
import { userName } from "../../helpers/user_helpers";

const sourceOwner = ({ source, users, isTemplate = false }) => {
  if (isTemplate || isBlank(source.owner)) return '';

  const owner = users.find((user) => user.email === source.owner)
  return userName(owner) || source.owner || '';
}

const SourceInfo = ({ sourceObj, ...opts }) => {
  const source = sourceObj.data_source;
  const owner = sourceOwner({ source, ...opts });
  if (sourceObj.isLink) {
    return owner;
  }
  if (sourceObj.isFile) {
    return `${filesize(source.byte_size)}${isBlank(owner) ? '' : ` • ${owner}`}`;
  }
  return null;
};

const SourceTitle = ({ source }) =>
  <div className="source-title text-truncate text-dark">
    {isPresent(source.title) ? source.title : source.link_url}
  </div>

export default ({ sourceObj, allowDSight = true, ...opts }) => {
  if (sourceObj.isDSight && allowDSight) {
    return <SourceTitle source={sourceObj.data_source}/>
  } else {
    return <>
      <SourceTitle source={sourceObj.data_source}/>
      <div className="source-info text-muted">
        {/*need for hidden download iframes*/}
        <div id={`iframeHide-${sourceObj.data_source.slug}`} hidden={true}></div>
        <SourceInfo sourceObj={sourceObj} {...opts}  />
      </div>
    </>
  }
};
