import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { saveWizardStepData } from "../../store/wizard/actions";
import {generateRandomSlug, isPresent, uniqueBy} from "../../helpers/common";
import {WIZARD_STEPS} from "../../helpers/wizard_helpers";
import {ChoiceRow} from "../../template_wizard/steps_wizard/steps/FramingDecisionStep";
import { ModalBodyWithClose, ModalDoneFooterP0 } from "../../common/modals";
import { updateDecisionData } from "../../store/decision/common_actions";
import {Button} from "react-bootstrap";
import {sortedRatedRankedChoices} from "../steps_wizard/steps/sections/HistoricalChoicesSection";
import Decision from "../../models/decision";
import * as moment from "moment";

const buildNewChoice = (choices) => {
  const lastChoice = choices[choices.length - 1];
  const order_rank = lastChoice?.order_rank + 1;
  const slug = generateRandomSlug(12)
  const created_at = moment()
  return {order_rank, slug, created_at}
}

export const onPasteChoiceCallback = (choices, setChoices, setNewChoice) => (event) => {
  event.preventDefault();
  const pastedText = event.clipboardData.getData('text');
  const lines = pastedText.split(/\r?\n/).map(line => line.trim()).filter(line => line);
  if (lines.length === 0) return;

  const newChoices = lines.map(newDescription => {
    const {order_rank, slug, created_at} = buildNewChoice(choices)
    return {slug, description: newDescription, final_decision: false, order_rank, created_at}
  })

  let updatedChoices = []
  choices.forEach(choice => {
    const activeElement = document.activeElement;
    const slug = activeElement.id;
    updatedChoices = choice.slug === slug ?
      [...updatedChoices, ...newChoices]:
      [...updatedChoices, choice]
  })
  setChoices(updatedChoices);
  setNewChoice(true);
};

const HistoricalChoicesModal = ({
                                  shown = false, wizard, isRecommendation = false,
                                  setShowChoicesModal, saveWizardStepData, choices, setChoices,
                                  decision, setIsRecommendation
                              }) => {

  const [submit, setSubmit] = useState(false)
  const [newChoice, setNewChoice] = useState(false)
  const [choicesData, setChoicesData] = useState(choices)

  const onChangeChoice = (value, index) => {
    setChoicesData(prevChoices => {
      return prevChoices.map((prevChoice, i) => {
        if (i === index) {
          return { ...prevChoice, description: value, final_decision: false };
        } else {
          return { ...prevChoice, final_decision: false };
        }
      });
    });
  }
  const onPasteChoice = onPasteChoiceCallback(choicesData, setChoicesData, setNewChoice);

  const removeChoice = (choiceSlug) => {
    setChoicesData(prevChoices => {
      const resetChoices = prevChoices.map((prevChoice) => {
        return { ...prevChoice, final_decision: false };
      });
      return resetChoices.filter(c => c.slug !== choiceSlug)
    });
  }

  const addNewChoice = () => {
    const {order_rank, slug, created_at} = buildNewChoice(choices)
    setNewChoice(true)
    setChoicesData([...choicesData, {slug, description: '', final_decision: false, order_rank, created_at}])
  }

  const closeModal = () => {
    setShowChoicesModal(false);
    setIsRecommendation(false)
  }

  const submitData = () => {
    setSubmit(true);
    const filteredChoices = uniqueBy(choicesData, 'description')

    const data = {
      decision_choices: filteredChoices,
      response: { step_index: wizard.step_index },
      next_step: false,
      finish_later: true,
      decision: {
        description: decision.description
      }
    };
    saveWizardStepData(WIZARD_STEPS.tree_historical.key, data, success => {
      setSubmit(false);
      if (success) {
        closeModal()
        setChoices(uniqueBy(choicesData.filter(c => isPresent(c.description)), 'description'))
      }
    });
  };

  const disableDone = () => {
    const filteredChoices = choicesData.filter(c => isPresent(c.description))
    return filteredChoices.length === 0
  }

  useEffect(() => {
    setChoicesData(new Decision(decision).isRankedList ? sortedRatedRankedChoices(choices) : choices)
  }, [choices, shown])

  return <Modal size="lg" backdrop="static" show={shown}>
    <ModalBodyWithClose bsPrefix="categories-modal" onClose={closeModal} header="Edit choices">
      <div className="w-100 mb-3">
        <h3>Choices</h3>
        {
          choicesData.map((choice, index) =>
            <ChoiceRow key={`historical-choice-${choice.slug}`}
                       {...{ wizard, index, description: choice.description, newChoice, setNewChoice,
                         onChangeChoice, removeChoice, onPasteChoice, choiceSlug: choice.slug }}/>
          )
        }
        <Button onClick={addNewChoice} className="btn-secondary w-100">Add another choice</Button>
      </div>
    </ModalBodyWithClose>
    <ModalDoneFooterP0 disabled={submit || disableDone()} onClose={submitData} />
  </Modal>
}
const mapStateToProps = ({modal, wizard}) => ({modal, wizard})
export default connect(mapStateToProps, { saveWizardStepData, updateDecisionData })(HistoricalChoicesModal);
