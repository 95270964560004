import React from 'react';
import Decision from "../../../models/decision";
import Recommendation from "../../../models/recommendation";
import * as moment from "moment";
import UserAvatarImage from "../../../common/UserAvatarImage";
import { userName } from "../../../helpers/user_helpers";

export const DecidedBySection = ({ object, deciding_user, decided_at, deciding_email, text = 'Entered' }) => {
  if(object instanceof Decision && !object.isDecisionRecorded) return null;
  if(object instanceof Recommendation && !object.isEnteredRecommendation) return null;

  return <div className="px-3 mb-3">
    <div className="d-flex align-items-center" key={`decided-${deciding_email}`}>
      <UserAvatarImage user={deciding_user} />
      <span className="ms-2 text-muted">{text} by {userName(deciding_user, deciding_email)} {moment(decided_at).format('DD MMM, yyyy')}</span>
    </div>
  </div>;
}
export default DecidedBySection;
