import React from 'react';

export default () =>
  <>
    <p>
      Click the <b>Add users</b> button to add people to Cloverpop.
    </p>
    <p>
      Use the search field to find people by name or email address. Use the menu next to the search field to filter by permissions or pending status.
    </p>
    <p>
      Click the user menu button to change their permissions, manage groups they belong to, or remove them from Cloverpop.
    </p>
    <h3>Permissions</h3>
    <p>
      <b>Admins:</b> Have the same permissions as users. In addition, admins can manage groups, users, and templates.
    </p>
    <p>
      <b>Users:</b> Can view decisions shared with them or their organization and create new decisions.
    </p>
    <p>
      <b>Guests:</b> Can only view decisions they are invited to. Guests are added when decision managers invite someone to a decision with an email address that was not added by an admin.
    </p>
    <h3>Pending users</h3>
    <p>
      The <i>Pending</i> status is shown for users invited to Cloverpop that have not visited the app and completed their profile.  Click the user menu button to re-send or revoke the invitation.
    </p>
  </>
