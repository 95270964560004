import React, { useEffect, useState } from 'react';
import { isBlank } from "../../../../../helpers/common";
import { analysisSurveyNodes, getDefaultTimeNode } from "../../../../../models/d_sight/metric_chart_helpers";
import { Loader } from "../../../../../common/Loader";

export const LoaderBlock = () =>
  <div className={`mt-2`}>
    <Loader />
  </div>

export const chartDataState = ({ metrics, metric, parentLoading, loadFunction }) => {
  const [chartData, setChartData] = useState(metrics.metric_charts[metric?.id])
  const [loading, setLoading] = useState(false)

  useEffect(() => setChartData(metrics.metric_charts[metric.id]), [metric.id])
  useEffect(() => {
    if (metrics.metric_charts.length < 1) {
      setChartData(metrics.metric_charts[metric.id])
    }
  }, [metrics.metric_charts.length])
  useEffect(() => {
    if (isBlank(chartData) && !parentLoading) {
      setLoading(true)
      loadFunction()(metric.id, (newChartData) => {
        setChartData(newChartData)
        setLoading(false)
      })
    }
  }, [chartData, parentLoading])

  return {
    chartData, loading
  }
}

export const timeNodesState = ({ d_sight_goal }) => {
  const timeNodes = d_sight_goal.loaded ? analysisSurveyNodes(d_sight_goal) : []
  const [timeNode, setTimeNode] = useState(getDefaultTimeNode(timeNodes, d_sight_goal.default_time_scale))
  useEffect(() => {
    setTimeNode(d_sight_goal.loaded ? getDefaultTimeNode(timeNodes, d_sight_goal.default_time_scale) : null)
  }, [timeNodes.length, d_sight_goal.loaded])

  return {
    timeNode, setTimeNode, timeNodes
  }
}
