import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import Select from 'react-select';
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { NoBorderFilterStyle } from "../../tree_wizard/styles/choice_entry_styles";
import { hideUsersTab, isAdmin, isBypassSSO, isGuest, isMember } from "../../helpers/home_helpers";
import { isBlank, isPresent, paginateArray, pluralize } from "../../helpers/common";
import {
  inviteOrgUsers,
  isHomepageSectionLoaded,
  loadDashboardUsers,
  needToLoadHomepageSection,
  removeOrgUser,
  resendOrgInvite,
  revokeOrgInvite,
  updateOrgUser,
  updatePendingUser
} from "../../store/homepage/actions";
import { Pagination } from "../../common/Pagination";
import {
  guestMakeUserAlert,
  guestMakeUserSSOAlert,
  userMakeGuestAlert,
  userRemoveAlert,
  userResendInviteSuccess,
  userSelfRemovingAdminRoleAlert
} from "../../helpers/alert_helpers";
import InviteUsersModal from "../modals/InviteUsersModal";
import ManageUserGroupsModal from "../modals/ManageUserGroupsModal";
import { Loader } from "../../common/Loader";
import { reloadContacts } from "../../store/contacts/actions";
import { BaseDropdownBtn } from "../../common/BaseHamburgerBtn";
import { BtnDropdownToggleInlineShadowText, IconBtnDropdown } from "../../common/dropdowns";
import SearchIcon from "./filters/SearchIcon";
import UserAvatarImage from "../../common/UserAvatarImage";

const USER_FILTERS = {
  none: '',
  admins: 'admins',
  users: 'users',
  guests: 'guests',
  pending: 'pending'
}
const UsersFilters = [
  { label: 'Show all', value: USER_FILTERS.none },
  { label: 'Admins', value: USER_FILTERS.admins },
  { label: 'Users', value: USER_FILTERS.users },
  { label: 'Guests', value: USER_FILTERS.guests },
  { label: 'Pending', value: USER_FILTERS.pending }
]

export const SORT_BY_TYPES = {
  first_name: 'first_name',
  last_name: 'last_name',
  email: 'email',
};
const SortByData = [
  { value: SORT_BY_TYPES.first_name, label: 'First name' },
  { value: SORT_BY_TYPES.last_name, label: 'Last name' },
  { value: SORT_BY_TYPES.email, label: 'Email address' },
];
export const USERS_PER_PAGE = 24

const SEARCH_FIELDS = ['first_name', 'last_name', 'full_name', 'email']

const byPassSSOParam = (home, user, hash, confirm_sso) => {
  if (!isSSOOrg(home)) return;
  if (!isGuest(user)) return;
  if (confirm_sso !== null) {
    hash.bypass_sso = isBlank(confirm_sso)
  }
}

export const isExistedUser = (userData) => userData.type === 'org_user'
export const isPendingUser = (userData) => userData.type === 'pending_invite'
export const calculateUserSortData = (userData, sortOrder) => {
  switch(sortOrder) {
    case SORT_BY_TYPES.email: return userData.email.toLowerCase();
    case SORT_BY_TYPES.first_name: return [isExistedUser(userData) ? '0' : '1', userData.first_name, userData.last_name, userData.email].filter(a => isPresent(a)).join(' ').toLowerCase()
    case SORT_BY_TYPES.last_name: return [isExistedUser(userData) ? '0' : '1', userData.last_name, userData.first_name, userData.email].filter(a => isPresent(a)).join(' ').toLowerCase()
  }
  return userData.email.toLowerCase();
}
export const sortUsersCallback = (sortOrder) => (a, b) => {
  const aValue = calculateUserSortData(a, sortOrder)
  const bValue = calculateUserSortData(b, sortOrder)

  if (aValue > bValue) return 1
  if (aValue < bValue) return -1

  return 0;
}
const userTypeName = (userData) => isAdmin(userData) ? 'Admin' : isGuest(userData) ? 'Guest' : 'User'
const isSSOOrg = (home) => isPresent(home.org_sso)

export const UsersTab = ({
                           home, current_user, loadDashboardUsers,
                           updateOrgUser, removeOrgUser,
                           inviteOrgUsers, updatePendingUser, resendOrgInvite, revokeOrgInvite,
                           reloadContacts
}) => {
  if (hideUsersTab(current_user)) return null;

  const refreshPagination = (users) => {
    if (isBlank(users)) {
      setTotalPages(1)
      setPage(1)
    } else {
      const pages = users.length / USERS_PER_PAGE;
      const newTotalPages = pages > parseInt(pages.toString()) ? parseInt(pages.toString()) + 1 : pages
      setTotalPages(newTotalPages)
      if (page > newTotalPages) setPage(1)
    }
  }

  useEffect(() => {
    if (needToLoadHomepageSection(home, 'users')) loadDashboardUsers({ groups_data: true })
    if (isHomepageSectionLoaded(home, 'users')) refreshPagination(home.users.data)
  }, [home.users.loaded])

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [usersFiler, setUsersFilter] = useState(UsersFilters[0])
  const [sortOrder, setSortOrder] = useState(SORT_BY_TYPES.first_name)
  const [submitState, setSubmitState] = useState(false)
  const [addModalShown, setAddModalShown] = useState(false)
  const [manageGroupsModalState, setManageGroupsModalState] = useState({ shown: false, user: null  })

  const filtersUsed = isPresent(searchQuery);

  const resetSearchAction = useCallback(() => {
    setSearchQuery('');
  }, []);

  const onChangeUsersFilter = useCallback((option) => {
    setUsersFilter(option?.value || UsersFilters[0].value)
  }, [home])

  const openAddUsersModal = useCallback(() => {
    setAddModalShown(true)
  }, [home])

  const PageHeader = useCallback(() =>
    <div className="row">
      <div className="col">
        <h1>Users</h1>
      </div>
      <div className="col">
        <Button className="h5 float-end h-36 py-1" onClick={openAddUsersModal}>
          <i className="fas fa-plus fa-lg me-1" />
          <span>Add users</span>
        </Button>
      </div>
    </div>
  , [home])

  const filterUserData = (userData) => {
    if (isPresent(searchQuery)) {
      if (!SEARCH_FIELDS.some(field => isPresent(userData[field]) && userData[field].toString().toLowerCase().indexOf(searchQuery.toLowerCase()) > -1))
        return false;
    }
    if (isPresent(usersFiler)) {
      switch(usersFiler) {
        case USER_FILTERS.admins:
          if (!isAdmin(userData)) return false;
          break;
        case USER_FILTERS.guests:
          if (!isGuest(userData)) return false;
          break;
        case USER_FILTERS.pending:
          if (!isPendingUser(userData)) return false;
          break;
        case USER_FILTERS.users:
          if (!isMember(userData)) return false;
          break;
      }
    }
    return true;
  }

  const filteredRecords = useMemo(() =>
    home.users.data.filter(userData => filterUserData(userData))
  , [home.users, searchQuery, usersFiler])

  useEffect(() => {
    refreshPagination(filteredRecords)
  }, [filteredRecords])

  const sortedRecords = useMemo(() =>
    filteredRecords.sort(sortUsersCallback(sortOrder))
  , [filteredRecords, sortOrder])

  const recordsPerPage = useMemo(() => paginateArray(sortedRecords, page, USERS_PER_PAGE), [sortedRecords, page, sortOrder])

  const submitCallback = _ => { setSubmitState(false) }
  const isLastAdmin = useCallback((user) =>
    !home.users.data.some(u => isExistedUser(u) && isAdmin(u) && u.slug !== user.slug)
  , [home])

  const onSubmitAddUsersModal = useCallback((emails, groups, permissions, notify_data, callback) => {
    setSubmitState(true)
    inviteOrgUsers({
      ...permissions,
      ...notify_data,
      contacts: emails.join(','),
      group_slugs: groups.map(g => g.slug)
    }, (success) => {
      callback(success)
      if (success) reloadContacts()
      setSubmitState(false)
    })
  }, [home])
  const onSubmitManageGroupsModal = useCallback((user, groups, callback) => {
    setSubmitState(true)
    const method = isExistedUser(user) ? updateOrgUser : updatePendingUser
    method(user.slug, {
      group_slugs: groups.map(g => g.slug),
      old_group_slugs: user.group_slugs
    }, (success) => {
      callback(success)
      setSubmitState(false)
    })
  }, [home])
  const onCloseManageGroupsModal = useCallback(() => {
    setManageGroupsModalState({ shown: false, user: null  })
  }, [home])

  const manageUserGroups = useCallback((data) => {
    setManageGroupsModalState({ shown: true, user: data })
  }, [home])

  const makeExistedOrgUserAdminPermission = useCallback((user) => {
    const callback = (confirm_sso = false) => {
      setSubmitState(true)
      const hash = { admin: true }
      byPassSSOParam(home, user, hash, confirm_sso)
      updateOrgUser(user.slug, hash, success => {
        submitCallback(success)
        if (success) reloadContacts()
      })
    }
    if (isGuest(user)) {
      if (isSSOOrg(home)) {
        guestMakeUserSSOAlert(user, 'admin').then(success => callback(success), () => callback(false))
      } else {
        guestMakeUserAlert(user, 'admin').then(confirmed => { if (confirmed) callback() })
      }
    } else {
      callback()
    }
  }, [home])
  const makeExistedOrgUserGuestPermission = useCallback((user) => {
    const callback = () => {
      setSubmitState(true)
      updateOrgUser(user.slug, { guest: true }, success => {
        submitCallback(success)
        if (success) reloadContacts()
      })
    }
    if (current_user.email === user.email) {
      userSelfRemovingAdminRoleAlert().then(confirmed => { if(confirmed) callback() })
    } else {
      userMakeGuestAlert(user).then(confirmed => { if(confirmed) callback() })
    }
  }, [home])
  const makeExistedOrgUserMemberPermission = useCallback((user) => {
    const callback = (confirm_sso = null) => {
      const hash = { member: true }
      byPassSSOParam(home, user, hash, confirm_sso)
      setSubmitState(true)
      updateOrgUser(user.slug, hash, success => {
        submitCallback(success)
        if (success) reloadContacts()
      })
    }
    if (current_user.email === user.email) {
      userSelfRemovingAdminRoleAlert().then(confirmed => { if(confirmed) callback() })
    } else if (isGuest(user)) {
      if (isSSOOrg(home)) {
        guestMakeUserSSOAlert(user, 'user').then(success => callback(success), () => callback(false))
      } else {
        guestMakeUserAlert(user, 'user').then(confirmed => { if (confirmed) callback() })
      }
    } else {
      callback()
    }
  }, [home])
  const makeBypassSSOOrgUser = useCallback((user) => {
    setSubmitState(true)
    updateOrgUser(user.slug, { bypass_sso: true }, submitCallback)
  }, [home])
  const makeRequireSSOOrgUser = useCallback((user) => {
    setSubmitState(true)
    updateOrgUser(user.slug, { bypass_sso: false }, submitCallback)
  }, [home])
  const removeExistedOrgUser = useCallback((user) => {
    userRemoveAlert(user).then(confirmed => {
      if (!confirmed) return;

      setSubmitState(true)
      removeOrgUser(user.slug, success => {
        submitCallback(success)
        if (success) reloadContacts()
      })
    })
  }, [home])
  const UserTile = useCallback(({user, disabled}) => {
    return <div className="col-12 mb-2 p-0">
      <div className="bg-white d-flex align-items-center user-tile p-2">
        <span className="me-2 d-none d-sm-inline-flex">
          <UserAvatarImage user={user} />
        </span>
        <div className="me-2 text-truncate lh-sm">
          {user.full_name}<br/>
          <span className="text-muted">{user.email || 'Not provided'}</span>
        </div>
        <span className="ms-auto text-nowrap">
          { userTypeName(user) }
        </span>
        <IconBtnDropdown id={`org-user-menu-dropdown-${user.slug}`}
                         disabled={disabled} className="d-inline ms-3">
          <BaseDropdownBtn eventKey={user.slug} onSelect={() => makeExistedOrgUserMemberPermission(user)} hidden={isMember(user) || isLastAdmin(user)} title="Make user" />
          <BaseDropdownBtn eventKey={user.slug} onSelect={() => makeExistedOrgUserAdminPermission(user)} hidden={isAdmin(user)} title="Make admin" />
          <BaseDropdownBtn eventKey={user.slug} onSelect={() => makeExistedOrgUserGuestPermission(user)} hidden={isGuest(user) || isLastAdmin(user)} title="Make guest" />
          <BaseDropdownBtn eventKey={user.slug} onSelect={() => makeBypassSSOOrgUser(user)} hidden={!isSSOOrg(home) || isGuest(user) || isBypassSSO(user)} title="Bypass SSO" />
          <BaseDropdownBtn eventKey={user.slug} onSelect={() => makeRequireSSOOrgUser(user)} hidden={!isSSOOrg(home) || isGuest(user) || !isBypassSSO(user)} title="Require SSO" />
          <BaseDropdownBtn eventKey={user.slug} onSelect={() => manageUserGroups(user)} title="Manage groups" />
          <BaseDropdownBtn eventKey={user.slug} onSelect={() => removeExistedOrgUser(user)}>
            <span className="text-danger">Remove user</span>
          </BaseDropdownBtn>
        </IconBtnDropdown>
      </div>
    </div>
  }, [home])

  const updatePendingOrgUser = useCallback((invite, updateData = {}) => {
    setSubmitState(true)
    updatePendingUser(invite.slug, updateData, success => {
      submitCallback(success)
      if (success) reloadContacts()
    })
  }, [home])
  const resendPendingOrgUseInviter = useCallback((invite) => {
    setSubmitState(true)
    resendOrgInvite(invite.slug, (success) => {
      submitCallback(success)
      if (success) {
        userResendInviteSuccess(invite)
      }
    })
  }, [home])
  const revokePendingOrgUser = useCallback((invite) => {
    setSubmitState(true)
    revokeOrgInvite(invite.slug, success => {
      submitCallback(success)
      if (success) reloadContacts()
    })
  }, [home])
  const PendingInviteTile = useCallback(({invite, disabled}) => {
    return <div className="col-12 mb-2 p-0">
      <div className="bg-white d-flex align-items-center user-tile p-2">
        <span className="me-2">
          <Image className="sm-avatar" roundedCircle src={home.default_user_avatar_url} title={invite.email} alt={invite.email} />
        </span>
        <span className="me-2 text-ellipsis lh-sm">
          <i className="d-block">Pending</i>
          <div className="text-muted d-initial">{invite.email}</div>
        </span>
        <span className="ms-auto row-actions">
          { userTypeName(invite) }
        </span>
        <IconBtnDropdown id={`invite-menu-dropdown-${invite.slug}`}
                         disabled={disabled} className="d-inline ms-2">
          <BaseDropdownBtn eventKey={invite.slug} onSelect={() => updatePendingOrgUser(invite, { member: true })} hidden={isMember(invite)} title="Make user" />
          <BaseDropdownBtn eventKey={invite.slug} onSelect={() => updatePendingOrgUser(invite, { admin: true })} bsPrefix="dropdown-item" hidden={isAdmin(invite)} title="Make admin" />
          <BaseDropdownBtn eventKey={invite.slug} onSelect={() => manageUserGroups(invite)} title="Manage groups" />
          <BaseDropdownBtn eventKey={invite.slug} onSelect={() => resendPendingOrgUseInviter(invite)} title="Resend invite" />
          <BaseDropdownBtn eventKey={invite.slug} onSelect={() => revokePendingOrgUser(invite)} title="Revoke invite" />
        </IconBtnDropdown>
      </div>
    </div>
  }, [home])

  const RecordTile = useCallback(({user, disabled}) => {
    if (user.type === 'org_user') {
      return <UserTile user={user} disabled={disabled}/>
    } else if (user.type === 'pending_invite') {
      return <PendingInviteTile invite={user} disabled={disabled}/>
    } else {
      return null;
    }
  }, [home])

  const SortByActivity = useCallback(() =>
    <div className="fw-bolder ms-auto my-3">
      <div className="d-inline-flex me-1">Sort by</div>
      <BtnDropdownToggleInlineShadowText id="sort-by-dropdown"
                                         bsPrefix="pe-0"
                                         title={SortByData.find((sortBy) => sortBy.value === sortOrder)?.label?.toLowerCase()}>
        {SortByData.map((sortBy) =>
          <BaseDropdownBtn key={`sort-order-${sortBy.value}`} eventKey={sortBy.value} onSelect={() => setSortOrder(sortBy.value)} title={sortBy.label} />
        )}
      </BtnDropdownToggleInlineShadowText>
    </div>, [sortOrder, searchQuery]);

  const UsersCount = useCallback(() =>
    <div className="users-count my-3">
      {pluralize(filteredRecords.length, 'user')}
    </div>
  ,[filteredRecords])

  return <>
    <div className="mx-auto users-container">
      <PageHeader />
      <div className="row">
        <div className="col pe-0">
          <SearchIcon className={'ps-1'} style={{ top: '15px', right: '12px' }} />
          <Button hidden={!filtersUsed} onClick={resetSearchAction} bsPrefix="position-absolute bg-white border-0" style={{ top: '14px', right: '34px' }}>
            <i className="fas fa-times text-danger w-100" />
          </Button>
          <input className="form-control border-0 search-input"
                 autoComplete="off"
                 placeholder="Search users"
                 type="text"
                 disabled={submitState}
                 onChange={(e) => setSearchQuery(e.target.value)}
                 value={searchQuery}
          />
        </div>
        <div className="col users-filter">
          <Select defaultValue={usersFiler}
                  onChange={onChangeUsersFilter}
                  isDisabled={submitState}
                  placeholder="Show all"
                  components={{ IndicatorSeparator:() => null }}
                  options={UsersFilters}
                  styles={NoBorderFilterStyle} />
        </div>
      </div>
      <div className="row" hidden={!home.users.loading}>
        <Loader />
      </div>
      <div className="row" hidden={home.users.loading}>
        <div className="col d-flex">
          <UsersCount />
          <SortByActivity />
        </div>
      </div>
      <div className="row w-100 m-0" hidden={home.users.loading}>
        { recordsPerPage.map(user => <RecordTile user={user} key={`record-tile-${user.type}-${user.slug}`} disabled={submitState} />) }
      </div>
      <div className="row pb-4" hidden={home.users.loading || totalPages < 2}>
        <Pagination page={page} totalPages={totalPages} setPage={setPage} totalCount={filteredRecords.length} perPage={USERS_PER_PAGE} />
      </div>
      <div className="modals">
        <InviteUsersModal shown={addModalShown} onClose={() => setAddModalShown(false)} onSubmit={onSubmitAddUsersModal} />
        <ManageUserGroupsModal shown={manageGroupsModalState.shown} user={manageGroupsModalState.user} onClose={onCloseManageGroupsModal} onSubmit={onSubmitManageGroupsModal} />
      </div>
    </div>
  </>
}
const mapStateToProps = ({ home, current_user }) => ({
  home, current_user
});
const mapDispatchToProps = (dispatch) => ({
  loadDashboardUsers: (data = {}) => {
    dispatch(loadDashboardUsers(data))
  },
  updateOrgUser: (slug, data, callback) => {
    dispatch(updateOrgUser(slug, data, callback))
  },
  removeOrgUser: (slug, callback) => {
    dispatch(removeOrgUser(slug, callback))
  },
  inviteOrgUsers: (data, callback) => {
    dispatch(inviteOrgUsers(data, callback))
  },
  updatePendingUser: (slug, data, callback) => {
    dispatch(updatePendingUser(slug, data, callback))
  },
  resendOrgInvite: (slug, callback) => {
    dispatch(resendOrgInvite(slug, callback))
  },
  revokeOrgInvite: (slug, callback) => {
    dispatch(revokeOrgInvite(slug, callback))
  },
  reloadContacts: () => {
    dispatch(reloadContacts())
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(UsersTab);
