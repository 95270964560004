import React from "react";
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../../helpers/common";

const Comments = ({ driverData }) => <div key={`driver-card-${driverData.driver.slug}-comments`}
                                          hidden={isBlank(driverData.driver.comments)}
                                          className="source-icon d-inline-block me-1">
  <i className="fa fa-solid fa-comment text-primary fa-2x file-icon" />
  {driverData.driver.comments <= 1 ? '' : <AggregatedDataLength length={driverData.driver.comments.length} /> }
</div>

const DataSources = ({ driverData }) => <div key={`driver-card-${driverData.driver.slug}-sources`}
                                             hidden={isBlank(driverData.driver_sources_slugs)}
                                             className="source-icon d-inline-block me-1">
  <i className="fas fa-paperclip text-primary fa-2x file-icon"></i>
  {driverData.driver_sources_slugs.length <= 1 ? '' : <AggregatedDataLength length={driverData.driver_sources_slugs.length} /> }
</div>

const AggregatedDataLength = ({ length }) =>
  <div className="d-inline-block align-text-bottom fw-normal text-gray ms-1">
    {length}
  </div>

const AggregatedData = ({ driverData, hidden = false }) => {
  if(hidden) return null;
  if(isBlank(driverData.driver_sources_slugs) && isBlank(driverData.driver.comments)) return null;

  return <div className="driver-data-sources mt-2">
    {isPresent(driverData.driver_sources_slugs) ? <DataSources driverData={driverData} /> : null}
    {isPresent(driverData.driver.comments) ? <Comments driverData={driverData} /> : null}
  </div>
}
const mapStateToProps = ({ tree }) => ({ tree });
export default connect(mapStateToProps, {})(AggregatedData);
