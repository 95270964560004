import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooterP0 } from "../../common/modals";
import { isBlank } from "../../helpers/common";
import { NameRow } from "../../homepage/modals/TemplateSetModal";
import { SECTION_NAME_INPUT_LIMIT } from "./AddSectionModal";

export const showEditSectionModal = (modal) => modal.type === "SetSectionsPanelEditSectionModal" && modal.shown

const EditSectionModal = ({ key, modal, title, show, onClose, updateSectionAction = () => {} }) => {
  const [name, setName] = useState(modal.set_section.name || '')
  const [submit, setSubmit] = useState(false)

  // callbacks
  useEffect(() => {
    if (show) setName(modal.set_section.name)
  }, [modal, show])

  const onSubmit = () => {
    setSubmit(true)
    updateSectionAction(modal.slug, { section: { name } }, (status) => {
      if(status) onClose()
      setSubmit(false)
    })
  }

  return <Modal key={key} size="md" backdrop="static" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>{title || 'Add a section'}</h2>
      <NameRow name={name} setName={setName} disabled={submit} nameLimit={SECTION_NAME_INPUT_LIMIT}
               placeholder={'Enter the name of the section'} />
    </Modal.Body>
    <ModalDoneFooterP0 onClose={onSubmit} disabled={isBlank(name) || submit} />
  </Modal>
}
const mapStateToProps = ({ modal }) => ({ modal });
export default connect(mapStateToProps)(EditSectionModal);