import React, { useContext } from 'react';
import { AccordionContext } from 'react-bootstrap'
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import {isBlank, isPresent} from "../../../helpers/common";
import SearchActionFilterText from "./SearchActionFilterText";
import { ALL_STATUS } from "../../../helpers/filter_helpers";

export default ({
                  children, eventKey, filterTextArray,
                  callback
                }) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
  const isCurrentEventKey = currentEventKey.activeEventKey?.includes(eventKey) || false;
  const allStatusText = filterTextArray?.includes(ALL_STATUS) ? ALL_STATUS : '';
  const hideText = isBlank(filterTextArray) || filterTextArray?.includes(ALL_STATUS);
  const hideToggle = isPresent(filterTextArray) && !filterTextArray?.includes(ALL_STATUS);

  return (
    <div className="d-flex p-3" onClick={ hideToggle ? null : decoratedOnClick}>
      <div className={`fw-bolder filter-text me-1`}>{children}</div>
      <SearchActionFilterText isCurrentEventKey={isCurrentEventKey} hidden={hideToggle} displayToggle={!hideToggle}>
        {allStatusText || 'Any'}
      </SearchActionFilterText>
    </div>
  );
}
