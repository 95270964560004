import React, {useEffect, useMemo, useState} from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { addTemplateToSet } from "../../store/template_set/actions";
import Button from "react-bootstrap/Button";
import { resetSidebars } from "../../store/sidebar/actions";
import { createNewTemplate } from "../../store/template/actions";
import { duplicateTemplateCallback } from "../../template_view/header/helpers";
import { isPresent } from "../../helpers/common";
import { getSectionSlugFromParam } from "../../helpers/set_helpers";
import { resetTemplateSetData } from "../../store/template_set/common_actions";
import { reloadTemplatesData } from "../../store/homepage/actions";

export const showAddToSetModal = (modal) => modal.type === "AddToSetModal" && modal.shown;
export const openAddToSetModalCallback = (openModal, template) => openModal({ decision: template, type: 'AddToSetModal' })

export const AddToSetModal = ({
                                template, template_set, home,
                                shown = false, onClose = () => {},
                                addTemplateToSet, resetSidebars, createNewTemplate,
                                resetTemplateSetData, reloadTemplatesData
                              }) => {
  const history = useHistory();
  const [submit, setSubmit] = useState(false)
  const [relatedTemplateSet, setRelatedTemplateSet] = useState({})
  const currentTemplateSet = useMemo(() => template_set, [])
  const section_slug = useMemo(() => getSectionSlugFromParam(document), []);
  const duplicateTemplate = duplicateTemplateCallback(createNewTemplate, template,
    [onClose, resetSidebars, resetTemplateSetData, reloadTemplatesData],
    history, {
    ...(isPresent(section_slug) && { section_slug }),
    template_set_slug: currentTemplateSet?.slug
  })

  // callbacks
  useEffect(() => {
    if (home.admin_template_sets.loaded && template_set.loaded && shown) {
      setRelatedTemplateSet(home.admin_template_sets.data.find((set) => set.slug === template.template_set_slug))
    }
  }, [home.admin_template_sets.loaded, template_set.loaded, shown])

  const close = () => onClose()
  const reset = () => {
    setSubmit(false)
    close()
  }
  const moveTemplate = () => {
    setSubmit(true)
    addTemplateToSet(template.slug, currentTemplateSet.slug, success => {
      setSubmit(false)
      if (success) {
        close();
        history.push(`/template_sets/${currentTemplateSet.slug}?left_side_bar_open=set_decisions`)
      }
    }, section_slug)
  }

  return <Modal size="md" backdrop="static" show={shown} onHide={reset}>
    <Modal.Body>
      <CloseIcon onClose={reset} />
      <h2>Add template</h2>
      <div>
        This template belongs to the <span className="fw-bolder">"{relatedTemplateSet?.name}"</span> decision flow.
        Would you like to remove this template from that flow and move it
        to the <span className="fw-bolder">"{currentTemplateSet.name}"</span> flow, or make a copy of the template?
      </div>
    </Modal.Body>
    <Modal.Footer bsPrefix={`modal-footer border-top-0 p-3`}>
      <div className="col mw-fit-content pe-1 m-0">
        <Button onClick={moveTemplate} bsPrefix="btn btn-primary w-100" disabled={submit}>Move template</Button>
      </div>
      <div className="col mw-fit-content ps-1 m-0">
        <Button onClick={duplicateTemplate} bsPrefix="btn btn-secondary w-100" disabled={submit}>Make a copy</Button>
      </div>
    </Modal.Footer>
  </Modal>
}
const mapStateToProps = ({ template_set, modal, home }) => ({ template_set, home, template: modal.decision });
export default connect(mapStateToProps, {
  addTemplateToSet, resetSidebars, resetTemplateSetData, createNewTemplate, reloadTemplatesData
})(AddToSetModal);
