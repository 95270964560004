import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { ACCEPT_FILE_TYPES, DEFAULT_MAX_FILES_SIZE, MAX_FILE_SIZE } from "../../models/data_source";
import ReactTooltip from 'react-tooltip';
import { isPresent } from "../../helpers/common";
import { Type } from '../../models/data_source';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 7,
  borderColor: '#60B8D5',
  borderStyle: 'dashed',
  backgroundColor: '#D0EEFF',
  color: '#607483',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const StyledDropzone = ({ hiddenInput = false, maxFiles = DEFAULT_MAX_FILES_SIZE,
                          onCreateSource, report = { slug: null }, dropFilesFormWrapper= 'report_data_source',
                          onStartUpload, onProcessUpload, onFinishUpload, isReplaceFile = false
                        }) => {
  const tip = useRef(null);

  useEffect(() => { ReactTooltip.rebuild(); });

  const handleFileRejections = () => {
    ReactTooltip.show(tip.current)
    setTimeout(() => {
      ReactTooltip.hide(tip.current)
    }, 5000);
  }
  const handleAcceptedFiles = (acceptedFiles) => {
    const uploadingSources = onStartUpload(acceptedFiles)
    uploadingSources.forEach((uploadingSourceData) => {
      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0);
          onProcessUpload(uploadingSources, uploadingSourceData.uploadingSource, progress)
        },
        cancelToken: uploadingSourceData.cancelTokenSource.token
      };
      const formData = new FormData();
      formData.append(dropFilesFormWrapper, uploadingSourceData.file);
      onCreateSource(
        formData,
        config, (status) => onFinishUpload(uploadingSources, status, uploadingSourceData.uploadingSource)
      );
    })
  }

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    fileRejections.length > 0 ? handleFileRejections() : handleAcceptedFiles(acceptedFiles)
  }, [])

    const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: ACCEPT_FILE_TYPES,
    maxSize: MAX_FILE_SIZE,
    maxFiles: maxFiles,
    onDrop: onDrop
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const errorMessage = (isReplaceFile) => {
    if(isPresent(fileRejections)){
      const errorIntro = isReplaceFile ? `<p class="fw-bold text-center mb-0"><span style="color: #EE5952;">Oops!</span> The file can not be attached <br>because` :
                                         `<p class="fw-bold text-center mb-0"><span style="color: #EE5952;">Oops!</span> One or more files can not <br>be attached because`;
      if(fileRejections[0].errors[0].code === 'file-too-large') {
        return `${errorIntro} it exceeds <br>the size limit of 100MB.</p>`
      } else if(fileRejections[0].errors[0].code === 'file-invalid-type') {
        return `${errorIntro} it is an unsupported <br>file type.</p>`
      }
    }
  };

  return (
    <>
      {
        isPresent(fileRejections) ?
          <ReactTooltip id="dropfiles-error-message" effect="solid" className="rounded" /> :
          null
      }
      <span ref={tip}
            data-for="dropfiles-error-message"
            data-tip={errorMessage(isReplaceFile)}
            data-type="light"
            data-html={true}
            data-border={true}
            data-border-color="#ced4da"
      >
        { hiddenInput ?
          <input className={'d-none'} {...getInputProps()} /> :
          <div className="pointer" {...getRootProps({style})} >
            <input {...getInputProps()} />
            <div className="upload-icon text-primary mb-2"><i className="fas fa-cloud-upload-alt fa-3x" /></div>
            <p className="mb-0">Drop a file here or <span className="text-primary">browse</span>.</p>
          </div>
        }
      </span>
    </>
  );
};
export default StyledDropzone;
