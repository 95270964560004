import {
  EXPAND_TREE,
  LOAD_TREE_DATA,
  LOAD_TREE_FAILED,
  LOAD_TREE_STARTED,
  RESET_TREE_DATA, RESET_TREE_DRIVERS_DATA,
  UPDATE_TREE_DATA
} from "./types";

export const resetTree = () => (dispatch) => dispatch(resetTreeState())
export const resetTreeDrivers = () => (dispatch) => dispatch(resetTreeDriversState())

export function toggleTree(expanded) {
  return (dispatch) => {
    dispatch(expandTree(expanded));
  }
}

const expandTree = (expanded) => ({
  type: EXPAND_TREE,
  payload: expanded
});
export const loadTreeSuccess = (data) => ({
  type: LOAD_TREE_DATA,
  payload: {
    ...data
  }
});
export const loadTreeStarted = (query = {}) => ({
  type: LOAD_TREE_STARTED,
  payload: {
    ...query
  }
});
export const updateTreeData = (data) => ({
  type: UPDATE_TREE_DATA,
  payload: {
    ...data
  }
});
export const loadTreeFailure = error => ({
  type: LOAD_TREE_FAILED,
  payload: { error }
});
export const resetTreeState = () => ({ type: RESET_TREE_DATA });
export const resetTreeDriversState = () => ({ type: RESET_TREE_DRIVERS_DATA });
