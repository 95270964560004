import {
    DSIGHT_METRICS_LOAD_DATA,
    DSIGHT_METRICS_LOAD_DATA_FAILED,
    DSIGHT_METRICS_LOAD_DATA_STARTED,
    DSIGHT_METRICS_RESET_DATA,
    DSIGHT_METRICS_UPDATE_DATA
} from "./types";

const initialState = {
    loading: false,
    loaded: false,
    error: '',
    slug: '',
    levers: [],
    metrics: [],
    metric_charts: {}
};

export function metricsReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case DSIGHT_METRICS_LOAD_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case DSIGHT_METRICS_UPDATE_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case DSIGHT_METRICS_RESET_DATA: {
            return {
                ...initialState,
                ...action.payload,
                loading: false
            };
        }
        case DSIGHT_METRICS_LOAD_DATA_STARTED: {
            return {
                ...state,
                ...initialState,
                ...action.payload,
                loading: true
            }
        }
        case DSIGHT_METRICS_LOAD_DATA_FAILED: {
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        }
        default:
            return state;
    }
}
