import React from 'react';
import { connect } from "react-redux";
import {setDecisionOrderSidebarOpen} from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import SetPanelHeader from "../../tree_view/side_panel/SetPanelHeader";
import { isMobile } from "react-device-detect";
import TreeSection from './sections/TreeSection';

const DecisionSetPanel = ({
                            sidebar, addClass, isDecision,
                            preview = false,
                            ...opts
                          }) =>
  <SlidingPanel
    type="left"
    isOpen={sidebar.decisionOrderSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-order-panel-wrapper details-panel-width template-panel ${addClass} ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-order-panel-content`}
    size={52}
  >
    <SetPanelHeader headerName="Decision flow" {...opts} />
    <TreeSection {...opts} isDecision={isDecision} preview={preview} />
  </SlidingPanel>

const mapStateToProps = ({ sidebar }) => ({ sidebar });
export default connect(mapStateToProps, { openSideBar: setDecisionOrderSidebarOpen })(DecisionSetPanel);
