import React from 'react';
import Select from 'react-select';
import { BorderedFilterStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import { getMemorizedOptions } from "../../helpers/period_helpers";

const PeriodSelectors = ({ to, setTo, from, setFrom, options, disabled = false, fromHeader = 'From', toHeader = 'To'  }) => {
  const { validToOptions, validFromOptions } = getMemorizedOptions({ from, to, options })

  return <div className="mt-3 mb-0">
    <div className="row">
      <div className='col-sm-6'>
        <h3>{fromHeader}</h3>
        <Select
          defaultValue={validFromOptions.find(option => option.value === from)}
          onChange={(option) => setFrom(option.value)}
          components={{ IndicatorSeparator: () => null }}
          styles={BorderedFilterStyle}
          placeholder="Make a selection"
          value={validFromOptions.find(opt => opt.value === from)}
          disabled={disabled}
          options={validFromOptions} />
      </div>
      <div className='col-sm-6'>
        <h3>{toHeader}</h3>
        <Select
          defaultValue={validToOptions.find(option => option.value === to)}
          onChange={(option) => setTo(option.value)}
          components={{ IndicatorSeparator: () => null }}
          styles={BorderedFilterStyle}
          placeholder="Make a selection"
          value={validToOptions.find(opt => opt.value === to)}
          disabled={disabled}
          options={validToOptions} />
      </div>
    </div>
  </div>
}
export default PeriodSelectors;
