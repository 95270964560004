import React, { useRef } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { openModal } from "../../../../store/modals/actions";
import { attachEventEffect } from "../../../../helpers/html_events_helpers";

const AddDriver = ({ tree, decision, treeNode, openModal }) => {
  const ref = useRef();
  const onAddDriver = (e) => {
    e.preventDefault();
    openModal({
      decision,
      drivers: tree.drivers,
      data_sources: tree.data_sources,
      slug: treeNode.childrenEmptyDriver.driver.slug,
      type: 'TreePanelAddDriverDetailsModal'
    })
  }
  //hack to attach click event after tree is initiated to fix click on selected row button
  attachEventEffect(ref, onAddDriver)

  return <Button className="btn btn-secondary btn-sm btn-sm-round lh-1 me-1" ref={ref}>
    <i className="fas fa-plus fa-sm" />
  </Button>
}

const mapStateToProps = ({ tree, decision, template },  { isTemplate }) => ({
  tree, decision: isTemplate ? template : decision
});
export default connect(mapStateToProps, { openModal })(AddDriver);
