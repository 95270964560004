import React from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../helpers/common";
import { RichMutedText } from "../../../common/RichTextEditor";

const EditDecisionTitle = ({ hidden = false, playbook_notes }) =>
  <>
    <h3>Decision</h3>
    {
      hidden || isBlank(playbook_notes.decision_title) ? null :
        <div className={'mb-2'}>
          <RichMutedText text={playbook_notes.decision_title} showMoreLess={true}/>
        </div>
    }
  </>

const mapStateToProps = ({ playbook_notes }) => ({ playbook_notes })
export default connect(mapStateToProps)(EditDecisionTitle);
