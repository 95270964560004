import React, { useMemo } from 'react';
import {connect} from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import {isMobile} from "react-device-detect";
import IframeLink from "../../data_sources/IframeLink";
import LinkPanelHeader from "./LinkPanelHeader";
import {isBlank} from "../../../helpers/common";

const LinkPanel = ({ sidebar, preview = true }) => {
  const isTableau = useMemo(() => isBlank(sidebar.dataSource?.link_url) ? false : sidebar.dataSource.link_url.includes('public.tableau.com'), [sidebar.dataSource, sidebar.linkPanelSidebar]);

  const MinPanel = () => <SlidingPanel
    type={preview ? 'left' : 'right'}
    isOpen={sidebar.linkPanelSidebar}
    panelContainerClassName={`sidebar-panel-wrapper discussion-panel-wrapper left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content discussion-panel-content`}
    size={50}
  >
    <LinkPanelHeader />
    <IframeLink isTableau={isTableau}  source={sidebar.dataSource} sources={sidebar.data_sources} />
  </SlidingPanel>

  const MaxPanel = () => <div className="max-panel-container">
    <LinkPanelHeader />
    <div className={`${isTableau ? 'tableau-content' : ''}`}>
      <IframeLink isTableau={isTableau} source={sidebar.dataSource} sources={sidebar.data_sources} maximize={sidebar.maximizeIframe} />
    </div>
  </div>

  return useMemo(() => sidebar.maximizeIframe ? <MaxPanel /> : <MinPanel />, [sidebar.dataSource, sidebar.maximizeIframe, sidebar.linkPanelSidebar, isTableau]);
}
const mapStateToProps = ({ sidebar }) => ({ sidebar });

export default connect(mapStateToProps, null)(LinkPanel);
