import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Menu from "./SearchReportFiltersMenu";
import { useRootClose } from "react-overlays";
import { isDefaultReportFilters } from '../../../helpers/filter_helpers';

export const SearchReportFilters = ({ home, loadAction }) => {
  const [show, setShow] = useState(false);
  const [saveMenu, setSaveMenu] = useState(false);
  const showBubble = !isDefaultReportFilters(home);
  const [openedSection, setOpenedSection]= useState('');
  const ref = useRef();
  const onRootCloseStart = () => {
    setSaveMenu(true);
  };
  const onRootCloseFinish = () => {
    setSaveMenu(false);
    setShow(false);
  };
  useRootClose(ref, onRootCloseStart, { disabled: !show });
  return <div className="search-decisions-filter">
    <Button className={`btn form-control rounded text-dark fw-normal dropdown-toggle border-0 w-100 ${showBubble ? 'bg-secondary' : 'bg-white'}`} onClick={() => setShow(true)}>
      <span className={`${showBubble ? 'orange-bubble' : ''}`}>Report properties</span>
    </Button>
    {show && (
      <div ref={ref} className="filter-menu">
        <Menu {...{saveMenu, onSave: onRootCloseFinish, loadAction, openedSection, setOpenedSection}} />
      </div>
    )}
  </div>;
}

const mapStateToProps = ({ home }) => ({ home });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SearchReportFilters);
