import React from 'react';
import $ from "jquery";
import TableauReport from "tableau-react";

const options = {
  width: '100%',
  hideTabs: false
};

const Tableau = ({link}) => {
  setTimeout(() => {
    $('iframe').prop('scrolling', 'true');
  }, 200);

  return (
    <TableauReport
      url={link}
      options={options}
    />
  );
};

export default Tableau;