import React, { useState } from 'react';
import { JoditTextEditor } from './JoditTextEditor';


export const CategorizationNotesRow = ({ categorizationNotes, setCategorizationNotes }) =>
  <div className="w-100 mb-3">
    <h3>Categorization notes</h3>
    <JoditTextEditor className="jodit-container-default"
                     value={categorizationNotes}
                     placeholder="Enter any comments or instructions for selecting categories"
                     setValue={setCategorizationNotes}
                     autofocus={false}/>
  </div>

export const categorizationNotesState = ({ categorization = '' }) => {
  const [categorizationNotes, setCategorizationNotes] = useState(categorization || '')
  const categorizationNotesRow = <CategorizationNotesRow categorizationNotes={categorizationNotes} setCategorizationNotes={setCategorizationNotes}/>

  return {
    categorizationNotes,
    categorizationNotesRow
  }
}
