import React, {useMemo} from 'react';
import { connect } from "react-redux";
import { openModal } from "../../../store/modals/actions";
import { openDriverEntryModal } from "../../modals/DriverEntryModal";
import { setDriverInputSidebarOpen, updateDriver } from "../../../store/sidebar/actions";
import { BaseDropdownBtn } from "../../../common/BaseHamburgerBtn";
import { isDSightDecision } from "../../../helpers/home_decision_helpers";
import { IconBtnDropdown } from "../../../common/dropdowns";
import Decision from "../../../models/decision";
import copyToClipboard from "../../../../js/utls/copyToClipboard";
import { DURATION } from "../../../alerts";
import { showAlert } from "../../../store/alerts/actions";

export const hideListChoicesLink = (decision, decisionObject, choices) => {
  const noChoices = choices.length === 0
  const isOpenEnded = decisionObject.isRecommendationFlow ? decisionObject.isOpenEndedRecommendation : decisionObject.isOpenEnded
  return isDSightDecision(decision) || noChoices || isOpenEnded
}

const DraftMoreActionsSection = ({
                                   showAlert, driverData, openModal, decision, sidebar, channels, user,
                                   setDriverInputSidebarOpen, updateDriver
                                 }) => {
  const slug = driverData?.driver?.slug;
  const updateDriverDraftState = () => {
    updateDriver({ slug, draft: true });
    setDriverInputSidebarOpen(false)
  }
  const decisionObject = useMemo(() => new Decision(decision), [decision]);
  const choices = useMemo(() =>
      decisionObject.sortedChoices(true).length > 0 ? decisionObject.sortedChoices(true) : decisionObject.sortedRecommendationChoices(true)
    , [decisionObject]);

  const copyChiocesToClipboard = () => {
    const bulletedChoicesList = choices.map((c) => " • " + c.description).join("\n");
    copyToClipboard(bulletedChoicesList).then(_ => {});
    showAlert({
      text: 'The decision choices have been copied to your clipboard',
      type: 'success',
      addClass: 'text-center',
      dismissible: false,
      duration: DURATION
    });
  };

  return <IconBtnDropdown id={'DraftMoreActionsSection'}>
    <BaseDropdownBtn onSelect={() => openDriverEntryModal(decision, slug, sidebar, openModal, channels, user)}>
      {isDSightDecision(decision) ? 'Assign driver' : 'Edit driver'}
    </BaseDropdownBtn>
    <BaseDropdownBtn hidden={hideListChoicesLink(decision, decisionObject, choices)} onSelect={() => copyChiocesToClipboard()} title="Copy public link" >
      Copy decision choices to clipboard
    </BaseDropdownBtn>
    <BaseDropdownBtn onSelect={updateDriverDraftState}>
      Save draft
    </BaseDropdownBtn>
  </IconBtnDropdown>
}
const mapStateToProps = ({ sidebar, tree, decision, channels, current_user, org_driver_types }) => (
  { sidebar, tree, decision, channels, user: current_user, org_driver_types }
);
const mapDispatchToProps = (dispatch) => ({
  openModal: (data = {}) => dispatch(openModal(data)),
  setDriverInputSidebarOpen: (value) => dispatch(setDriverInputSidebarOpen(value)),
  updateDriver: (data) => { dispatch(updateDriver(data)) },
  showAlert: (data) => dispatch(showAlert(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(DraftMoreActionsSection);
