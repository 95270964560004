import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  resetSidebars,
  setAssignDecisionsSidebarOpen,
  setSetDecisionsSidebarOpen,
  setSetSectionsSidebarOpen, setSetSummarySidebarOpen
} from "../../store/sidebar/actions";
import BaseHamburgerBtn, {BaseDropdownBtn, BaseHamburgerItem} from "../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import { openModal } from "../../store/modals/actions";
import { openModalCallback } from "../modals/SetNameModal";
import { forceReloadHomepageTemplates, resetSearchAndFilters } from "../../store/homepage/actions";
import DetailsHamburgerButton from "../../template_view/header/DetailsHamburgerButton";
import DecisionSetPanelHamburgerButton from "../../template_view/header/DecisionSetPanelHamburgerButton";
import { successActions } from "../../helpers/common";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { openTemplateSetUsersEntry } from "../modals/TemplateSetUsersModal";
import { deleteSetCallback } from "../helpers";
import { isSummaryPending } from "../../helpers/set_helpers";
import { openSummaryDetailsModal } from "../../decision_set_view/modals/SummaryDetailsModal";
import Dropdown from "react-bootstrap/Dropdown";
import MembersHamburgerButton from "./MembersHamburgerButton";
import DataSourcesHamburgerButton from "../../template_view/header/DataSourcesHamburgerButton";
import MoreActionsHamburgerButton from "../../template_view/header/MoreActionsHamburgerButton";
import HelpHamburgerButton from "../../template_view/header/HelpHamburgerButton";

export const DeleteSetItem = ({
                                set, homeTab, setTitle, as = '',
                                forceReloadHomepageTemplates, resetSidebars,
                                resetSet, removeSet, resetSearchAndFilters, reloadRecords,
                                ...opts
                              }) => {
  const history = useHistory();
  const onDeleteSet = deleteSetCallback(removeSet,
    [reloadRecords, resetSearchAndFilters, resetSidebars, resetSet],
    { history, homeTab, setTitle }
  )

  return <BaseDropdownBtn as={as} eventKey={set.slug} onSelect={onDeleteSet} {...opts}>
    <span className="text-danger">Ungroup section</span>
  </BaseDropdownBtn>
}

export const HamburgerDropdown = ({
                                    set, homeTab, setTitle, isManager = false, isSetUser = false,
                                    openModal, resetSet, removeSet, isTemplateSet = false,
                                    reloadRecords, resetSidebars, template_set, decision_set, resetSearchAndFilters,
                                    setSetSectionsSidebarOpen, setSetDecisionsSidebarOpen, setAssignDecisionsSidebarOpen,
                                    setSetSummarySidebarOpen
                                  }) => {
  const history = useHistory();
  const onDeleteSet = deleteSetCallback(removeSet,
    [reloadRecords, resetSearchAndFilters, resetSidebars, resetSet],
    { history, homeTab }
  )

  const processBackClick = () => {
    successActions(true, [resetSet, resetSearchAndFilters, resetSidebars, reloadRecords]);
    redirectToHomeCallback(true, history);
  }

  return <HamburgerMenuIconBtn>
    <DetailsHamburgerButton />
    <MembersHamburgerButton />
    <DataSourcesHamburgerButton />
    <DecisionSetPanelHamburgerButton />
    <MoreActionsHamburgerButton />
    <HelpHamburgerButton />
    <Dropdown.Divider />
    <BaseHamburgerBtn onSelect={processBackClick} title={'Go to homepage'} />
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({decision_set, template_set}) => ({decision_set, template_set});
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  resetSidebars: () => dispatch(resetSidebars()),
  forceReloadHomepageTemplates: () => dispatch(forceReloadHomepageTemplates()),
  resetSearchAndFilters: () => dispatch(resetSearchAndFilters()),
  setSetSummarySidebarOpen: () => dispatch(setSetSummarySidebarOpen(true)),
  setSetSectionsSidebarOpen: () => dispatch(setSetSectionsSidebarOpen(true)),
  setSetDecisionsSidebarOpen: () => dispatch(setSetDecisionsSidebarOpen(true)),
  setAssignDecisionsSidebarOpen: () => dispatch(setAssignDecisionsSidebarOpen(true))
});
export default connect(mapStateToProps, mapDispatchToProps)(HamburgerDropdown);
