import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import StyledDropzone from './DropFiles';
import AddLink from './AddLink';
import {isBlank, isPresent, qSortArray} from "../../helpers/common";
import * as moment from "moment";
import {
  DecisionSetSourcesTypes,
  DSightSourcesTypes, LinkedForecastScenariosDataSourcesType,
  LinkedReportDataSourcesType,
} from "../../models/data_source";
import { DataSourcesList, UploadingSourcesList } from "../../template_view/side_panel/sections/DataSourcesSection";
import { generateBatchDataSourceUploadState } from "../../helpers/uploads_callbacks";
import SelectExistingSourceRow from "../side_panel/sections/data_sources/SelectExistingSourceRow";
import Button from "react-bootstrap/Button";
import AccordionWrapper from "../../common/AccordionWrapper";

export const AddLinkRow = ({ onClick, showExtraMargin }) =>
  <div className="add-link">
    <AddLink {...{onClick, showExtraMargin}}/>
  </div>

const HeaderElement = () => <h3 className="mb-0" htmlFor="driverDataSources">Data sources</h3>

const DataSources = ({
                       decision,
                       isTemplate = false,
                       current_user, driver, isDriverDetailsModal = false,
                       dataSources, driverDataSources, uploadingSources, saveUploadingSources,
                       availableOrgReports, availableOrgForecastScenarios,
                       createSource,
                       attachReport, attachScenario,
                       decisionSetSources, addSourceToDecision, isDriverPanel = false,
                       ...opts
                     }) => {

  const [selectedSource, setSelectedSource] = useState('');
  const uploadOptions = generateBatchDataSourceUploadState(driverDataSources, uploadingSources, saveUploadingSources, current_user)
  const [showDropzone, setShowDropzone] = useState(false);
  const focusDropzone = useRef(null);

  const scrollToBottom = () => {
    focusDropzone.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [showDropzone])

  const onSelectSource = (option) => {
    setSelectedSource(option.value);
    let createParams = { slug: option.value };
    isTemplate ? (createParams.template_driver_slug = driver.slug) : (createParams.driver_slug = driver.slug)
    createSource(createParams);
  };

  const onSelectReport = (option) => {
    setSelectedSource(option.value);
    const data = isTemplate ? { template_driver_slug:  driver.slug } : { driver_slug: driver.slug }
    attachReport(option.value, data);
  };

  const onSelectScenario = (option) => {
    setSelectedSource(option.value);
    const data = isTemplate ? { template_driver_slug:  driver.slug } : { driver_slug: driver.slug }
    attachScenario(option.value, data);
  };

  const onSelectSetSource = (option) => {
    setSelectedSource(option.value);
    const attributes = { data_source: { decision_slug: decision.slug }, driver_slug: driver.slug }
    addSourceToDecision(option.value, decision.decision_set_slug, attributes);
  };

  const submitLink = (value, callback) => {
    let createParams = { link_url: value };
    isTemplate ? (createParams.template_driver_slug = driver.slug) : (createParams.driver_slug = driver.slug)
    createSource(createParams, {}, callback);
  }

  const accordionObject = {
    header: <HeaderElement />,
    body: <Fragment>
      <ul className="list-group mt-2">
        <DataSourcesList driver={driver} isTemplate={isTemplate} data_sources={driverDataSources} fromDriver={true} user={current_user} isDriverDetailsModal={isDriverDetailsModal} {...uploadOptions} {...opts} />
        <UploadingSourcesList uploading_sources={uploadingSources} user={current_user} {...uploadOptions} {...opts} />
      </ul>
      <AddLinkRow onClick={submitLink} />
      <Button className="btn btn-secondary w-100 mt-2"
              onClick={() => setShowDropzone(true)}
              hidden={showDropzone}>
        Add files
      </Button>
      <div className={`mt-2`} hidden={!showDropzone} ref={focusDropzone}>
        <StyledDropzone onCreateSource={createSource}
                        driver={driver}
                        driverModal={true}
                        isTemplate={isTemplate}
                        onStartUpload={uploadOptions.onStartUpload}
                        onFinishUpload={uploadOptions.onFinishUpload}
                        onProcessUpload={uploadOptions.onProcessUpload} />
      </div>
      <div className="mt-2" hidden={isBlank(decisionSetSources) && isBlank(availableOrgReports) && isBlank(dataSources)}>
        <div className="new-source-entries">
          <SelectExistingSourceRow selectedSource={selectedSource}
                                   onSelectSource={onSelectSource}
                                   onSelectReport={onSelectReport}
                                   onSelectScenario={onSelectScenario}
                                   onSelectSetSource={onSelectSetSource}
                                   dataSources={dataSources}
                                   orgReports={availableOrgReports}
                                   orgForecastScenarios={isTemplate ? [] :availableOrgForecastScenarios}
                                   driver={driver}
                                   decisionSetSources={decisionSetSources}
          />
        </div>
      </div>
    </Fragment>,
    defaultExpand: isDriverPanel ? isPresent(driverDataSources) : isPresent(dataSources)
  };

  if (isTemplate) {
    return <>
      <HeaderElement />
      {accordionObject.body}
    </>
  }

  return <AccordionWrapper accordionObject={accordionObject} />
};

const mapStateToProps = ({ decision, sidebar, modal, current_user, decision_set, current_org }, { isSidebar = false, driverData = {} }) => {
  const allDataSources = (isSidebar ? sidebar.data_sources : modal.data_sources) || [];
  const uploadingSources = (isSidebar ? sidebar.uploading_sources : modal.uploading_sources) || [];
  const selectableDataSources = allDataSources.filter((source) =>
    !DSightSourcesTypes.includes(source.type) && !LinkedReportDataSourcesType.includes(source.type) &&
    !DecisionSetSourcesTypes.includes(source.type) && !LinkedForecastScenariosDataSourcesType.includes(source.type)
  )
  const selectableDataSourcesSorted = selectableDataSources.sort((x,y)=>((x.title || x.link_url) > (y.title || y.link_url) ? 1 : -1));
  const driver = driverData.driver || {};
  const driverSourcesSlugs = driverData.driver_sources_slugs || [];
  const driverDataSources = allDataSources.filter((source) => driverSourcesSlugs.includes(source.slug));
  const driverDataSourcesReportSlugs = driverDataSources.map((source) => source.report_slug).filter(isPresent);
  const driverDataSourcesForecastScenariosIds = driverDataSources.map((source) => source.scenario_id).filter(isPresent);
  const sortedDriverDataSources = qSortArray(driverDataSources, true,(o) => moment(o.created_at));
  const is_user_in_decision_set = decision_set?.users.some(user => user.email === current_user.email)
  const availableSetSources = is_user_in_decision_set ? allDataSources.filter((source) => DecisionSetSourcesTypes.includes(source.type)) : []
  return {
    decision, current_user, driver, uploadingSources,
    decisionSetSources: availableSetSources,
    dataSources: selectableDataSourcesSorted,
    driverDataSources: sortedDriverDataSources,
    availableOrgReports: current_org.reports.filter((report) => !driverDataSourcesReportSlugs.includes(report.slug)),
    availableOrgForecastScenarios: current_org.forecast_simulator_scenarios.filter(scenario => !driverDataSourcesForecastScenariosIds.includes(scenario.id)),
  }
};
export default connect(mapStateToProps)(DataSources);

