import React, { useCallback, useEffect } from 'react';
import { connect } from "react-redux";
import { isDefaultTemplateFilters, resetAllFilters } from "../../../helpers/filter_helpers";
import { isGuest, TABS_URLS } from "../../../helpers/home_helpers";
import SearchTemplateFilters from "../filters/SearchTemplateFilters";
import { isBlank, isPresent, qSortArray } from "../../../helpers/common";
import {
  isHomepageSectionLoaded,
  isHomepageSectionLoading,
  loadDashboardTemplates,
  needToLoadHomepageSection
} from "../../../store/homepage/actions";
import { loadOrgCategories } from "../../../store/org_categories/actions";
import { homeSearchState } from "../SearchSection";
import { templateSortValue } from "../create_decision_tab/TemplatesList";
import { checkLoadingEffect, checkSearchChangeEffect } from "../../../helpers/callbacks_helpers";
import LearningTemplateTile from "../dsight/tiles/LearningTemplateTile";
import TemplatesFilters from "../helpers/TemplatesFilters";
import NullSearchResults from "../helpers/NullSearchResults";
import SearchRow, { resetSearchAction } from "../helpers/SearchRow";
import { Loader } from "../../../common/Loader";

export const IndividualDecisionsSection = ({ home, org_categories, current_user, loadDashboardTemplates, loadOrgCategories }) => {
  if (isGuest(current_user)) return null;

  const {
    searchQuery, setSearchQuery,
    loading, setLoading, resetSearch
  } = homeSearchState(home)

  const filtersUsed = isPresent(searchQuery) || home.d_sight_only || isPresent(home.category_options);
  const showNullResults = filtersUsed && isBlank(home.create_decision.data);

  useEffect(() => {
    if (needToLoadHomepageSection(home, 'create_decision')) loadDashboardTemplates();
  }, [home.create_decision.loaded])

  checkLoadingEffect(org_categories, loadOrgCategories)
  checkSearchChangeEffect(loading, setLoading, searchQuery, loadDashboardTemplates)

  const TemplatesList = useCallback(() => {
    if (isHomepageSectionLoading(home, 'create_decision')) return null;

    if(showNullResults) return <NullSearchResults home={home} resetAllFilters={() => resetAllFilters(setSearchQuery, setLoading, loadDashboardTemplates)} />

    return qSortArray(home.create_decision.data, true, templateSortValue).map(t =>
      <LearningTemplateTile template={t} key={`homepage-learning-template-tile-${t.slug}`}/>
    );
  }, [home, searchQuery])

  if (!filtersUsed && isBlank(home.create_decision.data) && isHomepageSectionLoaded(home, 'create_decision')) return null;

  return <>
    <div className="row">
      <div className="col">
        <h1>Individual decisions</h1>
      </div>
    </div>
    <SearchRow rowStyles={`${isDefaultTemplateFilters(home) ? 'mb-3' : 'mb-2'}`}
               {...{ searchQuery, setSearchQuery, placeholder: 'Search templates' }}
               resetSearchAction={() => resetSearchAction(setSearchQuery, resetSearch)}>
      <SearchTemplateFilters {...{ loadAction: loadDashboardTemplates }} />
    </SearchRow>
    <TemplatesFilters {...{ home, org_categories, loadDashboardTemplates }} classNames='mb-3'/>
    <div className="row" hidden={isHomepageSectionLoaded(home, 'create_decision')}>
      <Loader />
    </div>
    <div className="row" hidden={isHomepageSectionLoading(home, 'create_decision')}>
      <TemplatesList />
    </div>
  </>
}

const mapStateToProps = ({ home, current_user, org_categories }) => ({ home, current_user, org_categories });
const mapDispatchToProps = (dispatch) => ({
  loadDashboardTemplates: (data, tab = TABS_URLS.learn) => {
    dispatch(loadDashboardTemplates(data, tab))
  },
  loadOrgCategories: () => {
    dispatch(loadOrgCategories())
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(IndividualDecisionsSection);
