import React, { useCallback, useState } from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CloseIcon from "../../common/CloseIcon";
import { isBlank, isPresent } from "../../helpers/common";
import GroupsTypehead from "../../common/GroupsTypehead";
import { userName } from "../../helpers/user_helpers";

const ManageUserGroupsModal = ({ shown, user, groups, onClose = () => {}, onSubmit = () => {}}) => {
  if(isBlank(user)) return null;

  const [groupsSelection, setGroupsSelection] = useState(user.group_slugs.map(slug => groups.find(g => g.slug === slug)).filter(g => isPresent(g)))
  const [submit, setSubmit] = useState(false)

  const setDefaultValues = useCallback(() => {
    setGroupsSelection(user.group_slugs.map(slug => groups.find(g => g.slug === slug)).filter(g => isPresent(g)))
  }, [groups, user])

  const submitModal = () => {
    setSubmit(true)
    onSubmit(user, groupsSelection, (success) => {
      setSubmit(false)
      if (success) close();
    })
  }

  const close = () => {
    setDefaultValues();
    onClose()
  }

  return <Modal size="md" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <CloseIcon onClose={close} />
      <h2>{userName(user)}</h2>
      <h3>Add or remove groups</h3>
      <div className="mb-3">
        <GroupsTypehead
          placeholder="Enter a group" disabled={submit}
          values={groupsSelection} setValues={setGroupsSelection}
          autoFocus={shown}
        />
      </div>
      <Button onClick={submitModal} className="w-100 mt-3" disabled={submit}>Done</Button>
    </Modal.Body>
  </Modal>
}
const mapStateToProps = ({ home }) => ({ groups: home.groups.data });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ManageUserGroupsModal);
