import React, { useState, useMemo, useEffect } from 'react';
import { isPresent, qSortArray } from "../../../../helpers/common";
import {
  analysisSurveyNodes,
  chartBasesValueFor,
  chartValueFor, filteredProcessData,
  findProcessDataFor, getDefaultTimeNode,
  getLastSurvey,
  uniqDemographics
} from "../../../../models/d_sight/metric_chart_helpers";
import { TREE_RELATION_TYPE } from "../../../../models/d_sight/Analysis";
import FilteredChartPlaceholder from "./FilteredChartPlaceholder";
import { filtersSurveysBySubMetrics } from "../helpers";
import FunnelChart from "./components/FunnelChart";
import CompareCompetitorsChart from "./components/CompareCompetitorsChart";
import { sortBrandsBySubMetrics } from "./brand/helpers";

const fetchFilteredMetrics = (chartData, metric) =>
  chartData?.metrics?.filter(({ metric_id }) => {
    const childMetric = metric.metrics.find(m => m.id === metric_id)
    return childMetric?.data?.model_type !== TREE_RELATION_TYPE.res;
  })

const fetchSubMetrics = ({ filteredMetrics, metric, addAvailableOptions, d_sight_goal, selectedSurvey }) =>
  filteredMetrics.map(({ metric_id, process_data, question }) => {
    const childMetric = metric.metrics.find(m => m.id === metric_id)
    addAvailableOptions(uniqDemographics({ process_data }))
    const filtered_process_data = filteredProcessData({ process_data }, d_sight_goal)
    const targetProcessData = findProcessDataFor(filtered_process_data, parseInt(d_sight_goal.target.name), selectedSurvey.node_index)
    return {
      metric: childMetric,
      process_data: filtered_process_data, metric_id, question,
      mainTargetValue: chartValueFor(selectedSurvey, targetProcessData, childMetric),
      mainTargetBasesValue: chartBasesValueFor(selectedSurvey, targetProcessData)
    }
  })

const fetchState = ({ filteredMetrics, metric, addAvailableOptions, d_sight_goal, selectedSurvey }) => {
  const sortedNodeNames = sortBrandsBySubMetrics(d_sight_goal, filteredMetrics)

  const subMetrics = fetchSubMetrics({ filteredMetrics, metric, addAvailableOptions, d_sight_goal, selectedSurvey })
  const sortedSubMetrics = qSortArray(subMetrics, false, hash => hash.mainTargetValue)

  return {
    subMetrics, sortedSubMetrics, sortedNodeNames
  }
}

export default ({
                  d_sight_goal, metric, chartData,
                  addAvailableOptions,
                  ...opts
                }) => {
  const timeNodes = analysisSurveyNodes(d_sight_goal)
  const filteredMetrics = fetchFilteredMetrics(chartData, metric) || []
  const surveys = filtersSurveysBySubMetrics(d_sight_goal, getDefaultTimeNode(timeNodes), filteredMetrics)
  const [selectedSurvey, setSelectedSurvey] = useState(getLastSurvey(surveys))

  useEffect(() => {
    if (chartData && chartData.metric_id === metric.id) setSelectedSurvey(getLastSurvey(surveys))
  }, [surveys.length, chartData?.metric_id, metric.id])

  const {
    subMetrics, sortedSubMetrics, sortedNodeNames
  } = useMemo(() =>
      fetchState({ filteredMetrics, metric, addAvailableOptions, d_sight_goal, selectedSurvey })
    , [filteredMetrics, metric, d_sight_goal, selectedSurvey])

  if (!subMetrics.some(({ process_data }) => isPresent(process_data))) return <FilteredChartPlaceholder/>

  return <>
    <FunnelChart data={sortedSubMetrics} {...{ metric, selectedSurvey, setSelectedSurvey, surveys, chartData, ...opts }} />
    <CompareCompetitorsChart {...{
      d_sight_goal, selectedSurvey, setSelectedSurvey, surveys, chartData, metric,
      data: sortedSubMetrics, brands: sortedNodeNames, measure: '%',
      ...opts
    }} />
  </>;
}
