import React from 'react';
import { connect } from "react-redux";
import { setDataSourcesSidebarOpen } from "../../store/sidebar/actions";

const DataSourcesItem = ({ sidebar, openSideBar }) =>
  <li className={sidebar.dataSourcesSidebar ? 'active' : ''}>
    <div className="side-row text-nowrap pointer"
         onClick={() => openSideBar(!sidebar.dataSourcesSidebar)}>
      <span className="icon d-inline-block text-center ">
        <i className="fas fa-paperclip fa-lg"/>
      </span>
      <span className="d-none d-lg-inline tab-title">Data sources</span>
    </div>
  </li>

const mapStateToProps = ({sidebar}) => ({sidebar});
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setDataSourcesSidebarOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(DataSourcesItem);
