import React from 'react';
import { connect } from "react-redux";
import {isPresent, successActions} from "../../../helpers/common";
import { reloadShareData } from "../../../store/share/actions";
import Alerts from "../../../../js/alerts";
import { reloadContacts } from "../../../store/contacts/actions";
import UserAvatarImage from "../../../common/UserAvatarImage";

const removeInvite = (slug, removeCollaborator, name, actions) => {
  Alerts.warning({
    title: `Are you sure you want to remove ${name}?`,
  }).then(confirmed => {
    if (confirmed) {
      removeCollaborator(slug, (success) => successActions(success, actions))
    }
  }, () => {})
}

export const CollaboratorsInviteRow = ({ user, slug, removeCollaborator, email, preview = false }) => {
  const name = isPresent(user) ? user.full_name : email
  const isUserDecider = slug === 'decider'

  return <div className="d-flex mb-1 align-items-center">
    <span className="collaborator_img position-relative">
      <UserAvatarImage user={user} size='md' pendingUserEmail={email} />
    </span>
    <span className="px-2">{name} { isUserDecider ? <span className="text-muted">(decision manager)</span> : '' }</span>
    {!preview && <div className="ms-auto">
      <span className="btn btn-light btn-sm btn-sm-round text-danger ms-2 px-2" onClick={() => removeInvite(slug, removeCollaborator, name, [reloadContacts])}>
        <i className="fas fa-times"/>
      </span>
    </div>}
  </div>
}

const mapStateToProps = ({ current_user, tree, template }) => ({
  current_user, template,
  default_user_avatar_url: tree.default_user_avatar_url
});
export default connect(mapStateToProps, {reloadShareData, reloadContacts})(CollaboratorsInviteRow);
