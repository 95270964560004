import React, { useState } from 'react';
import { isBlank } from "../../../../../helpers/common";
import Button from "react-bootstrap/Button";
import TextareaAutosize from 'react-textarea-autosize';
import { ThreeDots } from "react-loader-spinner";
import { isEnter } from "../../../../../helpers/keys_helpers";

const ChatInput = ({
                     defaultValue = '',
                     submit = () => {},
                     askCloverpopPage = false,
                     disabledInput = false
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleCancel = () => {
    setValue('');
  }

  const handleSend = () => {
    submit(value);
    setValue('');
  }

  const handleKeyDown = (e) => {
    if (isEnter(e.keyCode) && !e.shiftKey) {
      e.preventDefault();
      handleSend()
    }
  }

  const buttonText = askCloverpopPage ? 'Send' : 'Ask Cloverpop';

  return <div className="d-flex justify-content-between align-items-center">
    <div className="position-relative w-100">
      <TextareaAutosize
        className="form-control rounded search-input"
        placeholder="Enter a question"
        disabled={disabledInput}
        type="text"
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
        value={value}
      />
      <Button
        hidden={isBlank(value)}
        onClick={handleCancel}
        bsPrefix="position-absolute bg-white border-0"
        style={{ top: '14px', right: '8px' }}>
        <i className="fas fa-times text-danger"/>
      </Button>
    </div>
    {
      disabledInput ?
        <ThreeDots height="30" width="30" radius="5" color="#8A949F" ariaLabel="three-dots-loading" visible={true}/> :
        <Button
          className={`h-fit-content text-nowrap ms-2 ${askCloverpopPage ? 'px-3' : ''}`}
          onClick={handleSend}>
          {buttonText}
        </Button>
    }
  </div>
};

export default ChatInput;
