import {UPDATE_SIGN_IN_DATA, UPDATE_SIGN_IN_FAILED} from "./types";
import {dispatchError, isResponseFailed} from "../../helpers/store_helpers";
import {sendLoginLinkRequest} from "../../utils/Api";

export const sendLoginLink = (email, callback) => (dispatch) => {
  dispatch(updateSignInUserData({ loading: true }))

  sendLoginLinkRequest(email).then(response => {
    const { data } = response;
    if (isResponseFailed(response)) return dispatchError(dispatch, { data, callback: updateSignInUserFailure }, callback)

    const { email } = data;
    dispatch(updateSignInUserData({ loading: false, email }))
    callback(email)
  })
}

export const updateSignInUserData = (data) => ({
  type: UPDATE_SIGN_IN_DATA,
  payload: {
    ...data
  }
});
export const updateSignInUserFailure = error => ({
  type: UPDATE_SIGN_IN_FAILED,
  payload: {
    error
  }
});
