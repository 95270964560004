import React, { Fragment } from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import { isBlank } from "../../../helpers/common";
import * as moment from "moment";
import Loader from "../../../content/Loader";
import { redirectToLearningCallback } from "../../../helpers/decision_helpers";
import ReactHtmlParser from "react-html-parser";
import { abbreviateNumber } from "js-abbreviation-number";

export const PLACEHOLDER = 0

export const formatTotalCount = ({ total_count }) => {
  if (isBlank(total_count)) return PLACEHOLDER;
  try {
    return abbreviateNumber(total_count, 1);
  } catch (error) {
    if (error instanceof RangeError) {
      return total_count
    }
  }
}

export const formatCost = ({ total_cost }) => {
  if (isBlank(total_cost)) return PLACEHOLDER;

  try {
    return `$${abbreviateNumber(total_cost, 1)}`;
  } catch (error) {
    if (error instanceof RangeError) {
      return `$${total_cost}`
    }
  }

}

export const formatCompletionTreeCount = ({ current_driver_count, total_count }) => {
  if (isBlank(total_count)) return PLACEHOLDER;

  return `${current_driver_count} of ${total_count}`;
}

export const formatAvgCollaborators = ({ total_count, avg_collaborators }) => {
  if (isBlank(total_count)) return PLACEHOLDER;
  if (isBlank(avg_collaborators)) return PLACEHOLDER;

  return parseFloat(parseFloat(avg_collaborators).toFixed(1))
}

export const formatTreeCompletion = ({ total_count = null, total_drivers = null, tree_completion = null }) => {
  if (isBlank(total_count) || isBlank(total_drivers) || isBlank(tree_completion)) return PLACEHOLDER;

  return formatPercentage(tree_completion, false);
}

export const formatSuccessDecisions = ({ total_results, successful_decisions }) => {
  if (isBlank(total_results)) return PLACEHOLDER;
  if (isBlank(successful_decisions)) return PLACEHOLDER;

  return formatPercentage(successful_decisions, false);
}

export const formatDriverConfidence = ({ driver_confidence }) => {
  return formatPercentage(driver_confidence);
}

export const formatPercentage = (value, checkBlank = true) => {
  if (checkBlank && isBlank(value)) return PLACEHOLDER;

  return `${(parseFloat(value.toString()) * 100).toFixed(0)}%`
}

export const daysCounts = (avg_decision_time) => {
  const now = moment()
  const next = moment().add(avg_decision_time, 'seconds')

  const days = next.diff(now, 'days', true)
  return parseFloat(days).toFixed(1);
}

export const formatAvgTime = ({ total_count, avg_decision_time }) => {
  if (isBlank(total_count)) return PLACEHOLDER;
  if (isBlank(avg_decision_time)) return PLACEHOLDER;

  const now = moment()
  const next = moment().add(avg_decision_time, 'seconds')

  const days = next.diff(now, 'days', true)
  if (days >= 1) return `${parseFloat(days).toFixed(1)} days`

  const hours = next.diff(now, 'hours', true)
  if (hours >= 1) return `${parseFloat(hours).toFixed(1)} hours`

  const minutes = next.diff(now, 'minutes', true)
  if (minutes >= 1) return `${parseFloat(minutes).toFixed(1)} minutes`

  const seconds = next.diff(now, 'seconds', true)
  if (seconds >= 1) return `${parseInt(seconds)} seconds`

  return PLACEHOLDER;
}

export const StatisticBlock = ({ number, label }) =>
  <div className="col px-1">
    <h3 className="text-dark text-center text-nowrap mb-0">{number}</h3>
    <div className="text-gray text-center lh-sm">{ReactHtmlParser(label)}</div>
  </div>

const StatisticsPanel = ({ statistics }) =>
  isBlank(statistics) ? <Loader /> :
    <div className="pointer px-2" onClick={redirectToLearningCallback}>
      <div className="row all-decisions-row rounded bg-white mb-3 p-3">
        <StatisticBlock number={formatTotalCount(statistics)}
                        label={'Total<br/>decisions'} />
        <StatisticBlock number={formatAvgCollaborators(statistics)}
                        label={'Avg collaborators<br/>per decision'} />
        <StatisticBlock number={formatAvgTime(statistics)}
                        label={'Avg time<br/>to decision'} />
        <StatisticBlock number={formatTreeCompletion(statistics)}
                        label={'Tree<br/>completion'} />
        <StatisticBlock number={formatSuccessDecisions(statistics)}
                        label={'Successful<br/>decisions'} />
      </div>
    </div>

export const AllDecisionsStatisticsPanel = ({ home }) => {
  const { all_decisions } = home.learning.data;

  return <Fragment>
    <div className="row">
      <div className="col-6">
        <h1>All decisions</h1>
      </div>
      <div className="col-6">
        <Button className="h5 float-end h-36 py-1"  onClick={redirectToLearningCallback}>
          <span>Show details</span>
        </Button>
      </div>
    </div>
    <StatisticsPanel statistics={all_decisions}/>
  </Fragment>
}

const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps)(AllDecisionsStatisticsPanel);
