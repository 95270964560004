import React from 'react';
import { connect } from "react-redux";
import { isHomepageSectionLoading } from "../../../store/homepage/actions";
import { isBlank, qSortArray } from "../../../helpers/common";
import DraftTile from "./DraftTile";

const DraftsList = ({ home }) => {
  if (isHomepageSectionLoading(home, 'draft')) return null;

  const tiles = qSortArray(home.draft.data, false, d => d.created_at)
  if (isBlank(tiles) && home.draft.data) return null;

  return tiles.map((t) =>
    <DraftTile decision={t} key={`decisions-center-decision-tile-${t.slug}`} />
  )
}
const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps)(DraftsList);
