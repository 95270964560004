import React, { Fragment, useEffect } from 'react';
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { isBlank, isPresent, successActions } from "../../helpers/common";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { switchOrg } from "../../store/homepage/actions";
import { resetOrgCategoriesState } from '../../store/org_categories/actions';
import { resetOrgDriverTypesState } from '../../store/org_driver_types/actions';
import { reloadContacts } from "../../store/contacts/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import { reloadAdmissions } from "../../store/decision_admissions/actions";

const OrgLogoLink = ({ current_org }) =>
  <Link to='/homepage' className="logo-dropdown">
    <Image src={current_org?.logo_horizontal} alt={current_org?.alt_text}/>
  </Link>

const OrgDropdownItem = ({current_org, org, selectOrg}) =>
  <BaseHamburgerBtn onSelect={() => selectOrg(org.slug)} className={current_org?.slug === org.slug ? 'active' : ''} title={org.name} />

export const OrgSwitcher = ({
                              home, current_org,
                              switchOrg,
                              reloadContacts,
                              resetOrgCategoriesState,
                              resetOrgDriverTypesState,
                              reloadAdmissions
                            }) => {
  if (isBlank(current_org)) return null;

  const selectOrg = (org_slug) => {
    if (org_slug === current_org?.slug) return;

    switchOrg(org_slug, (success) => {
      successActions(success, [reloadContacts, resetOrgCategoriesState, resetOrgDriverTypesState, reloadAdmissions]);
    })
  }

  useEffect(() => {
    const location = window.location.href;
    const org_slug = location.substring(location.lastIndexOf("/") + 1);
    const isUserOrg = home.user_orgs.some(hash => hash.slug === org_slug)

    isUserOrg && selectOrg(org_slug)
  }, [home.user_orgs.length])

  useEffect(() => {
    if (isPresent(current_org.slug)) {
      if(isBlank(current_org.dashboard_access)) document.location.reload();
    }
  }, [current_org.slug])

  if(home.user_orgs.length < 2) {
    return <OrgLogoLink current_org={current_org} />
  } else {
    return <Fragment>
      <Dropdown as={ButtonGroup} bsPrefix="dropdown me-auto">
        <Dropdown.Toggle variant="" bsPrefix={'p-0 dropdown-toggle after shadow-none'}>
          <OrgLogoLink current_org={current_org} />
        </Dropdown.Toggle>
        <Dropdown.Menu bsPrefix={'dropdown-menu org-dropdown'} align={'start'}>
          {home.user_orgs.map(org =>
            <OrgDropdownItem current_org={current_org} org={org} selectOrg={selectOrg} key={`select-org-${org.slug}`} />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  }
}
const mapStateToProps = ({ home, current_org }) => ({ home, current_org });
const mapDispatchToProps = (dispatch) => ({
  switchOrg: (slug, callback) => {
    dispatch(switchOrg(slug, callback))
  },
  resetOrgCategoriesState: () => {
    dispatch(resetOrgCategoriesState())
  },
  reloadContacts: () => {
    dispatch(reloadContacts())
  },
  reloadAdmissions: () => {
    dispatch(reloadAdmissions())
  },
  resetOrgDriverTypesState: () => {
    dispatch(resetOrgDriverTypesState())
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(OrgSwitcher);
