import React, { useEffect, useState } from 'react';
import CloseIcon from "../../common/CloseIcon";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import CategoryItem from "./sort_categories/CategoryItem";
import { arrayMoveImmutable } from "array-move";

const SortableItem = SortableElement(({ category }) =>
  <div className="choice-item">
    <CategoryItem category={category} key={`sorted-category-${category.slug}`} />
  </div>
);

const SortableList = SortableContainer(({ categories }) =>
  <div>
    {categories.map((category, index) => (
      <SortableItem key={`item-${category.slug}`} index={index} category={category} />
    ))}
  </div>
);

const SortedCategories = ({ categories, setSortedCategories }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const reSortedCategories = arrayMoveImmutable(categories, oldIndex, newIndex);
    setSortedCategories(reSortedCategories)
    document.body.style.cursor = ''
  };
  const onSortStart = ({oldIndex, newIndex}) => {
    document.body.style.cursor = 'grabbing'
  };
  return <SortableList categories={categories}
                       onSortEnd={onSortEnd}
                       onSortStart={onSortStart}
                       helperClass="on-modal"
                       distance={1} />;
};

export default ({shown, categories, onClose = () => {}, onSubmit = () => {}}) => {
  const prevCategoriesData = categories.available_categories;
  const [sortedCategories, setSortedCategories] = useState(prevCategoriesData);
  const [submit, setSubmit] = useState(false)

  useEffect(() => {
    setDefaultValues();
  }, [categories]);

  // Callback
  const submitModal = () => {
    setSubmit(true)
    const sortedCategoriesData = sortedCategories.map(({ slug }) => ({ slug }));
    onSubmit({ sorted_categories: sortedCategoriesData }, success => {
      setSubmit(false)
      if (success) onClose()
    })
  }
  const setDefaultValues = () => {
    setSortedCategories(prevCategoriesData)
  }
  const close = () => {
    setDefaultValues();
    onClose()
  }

  // Render
  return <Modal size="md" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <CloseIcon onClose={close} />
      <h2>Sort categories</h2>
      <p className="text-muted">Drag and drop a category to change the display order.</p>
      <div className="mb-2">
        <SortedCategories categories={sortedCategories} setSortedCategories={setSortedCategories}/>
      </div>
      <Button onClick={submitModal} className="w-100 mt-3" disabled={submit}>Done</Button>
    </Modal.Body>
  </Modal>
}
