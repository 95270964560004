import React from "react";
import WizardDataSources from "../../data_sources/index";

export const DataSourcesBlock = (opts) => {
  return <div className={`bg-white rounded p-3 my-3 ms-xl-3`}>
    <WizardDataSources isHistoricalDecision={true} {...opts} />
  </div>
}

export default DataSourcesBlock;
