import React from 'react';
import { connect } from "react-redux";
import {
  createChoice,
  createConsideredChoices,
  createRecommendation,
  createRecommendationChoice,
  setDecisionInputSidebarOpen,
  updateFinalChoices,
  updateRecommendationFinalChoices,
  updateRecordDecision,
  updateRecommendation,
  updateRecommendationChoice,
  destroyChoice,
  updateChoice,
  destroyRecommendationChoice,
  createRecommendationConsideredChoices
} from "../../../../store/sidebar/actions";
import DoneButton from '../../../../common/DoneButton';
import {isBlank, isPresent} from '../../../../helpers/common'
import Decision from "../../../../models/decision";
import EntryPoint from "../../../../EntryPoint";
import { isMobile } from "react-device-detect";
import Recommendation from "../../../../models/recommendation";
import { flow } from "../../../../helpers/decision_flows";

export const onSaveDsight = (flowEnum,
                             rateSupport,
                             openEndedChoice,
                             reasons,
                             expectedResults,
                             consideredChoices,
                             setConsideredChoices,
                             nextStepsDescription,
                             setDecisionInputSidebarOpen,
                             createChoice,
                             createConsideredChoices,
                             updateFinalChoices,
                             createRecommendation,
                             createRecommendationChoice,
                             createRecommendationConsideredChoices,
                             updateRecommendationFinalChoices,
                             updateRecommendationChoice,
                             destroyChoice,
                             destroyRecommendationChoice,
                             updateChoice,
                             updateRecordDecision,
                             updateRecommendation,
                             expectedInvestment,
                             expectedOpportunity,
                             decisionDate,
                             decision,
                             draft) => {

  const decisionObj = new Decision(decision);
  const existedOpenEndedChoice = decisionObj.openEndedChoice[0];
  const recommendation = decision?.recommendation;
  const recommendationObj = new Recommendation(recommendation);
  const existedRecommendationChoice = recommendationObj.openEndedChoice[0];
  const filteredConsideredChoices = consideredChoices.filter(choice => isPresent(choice.description))

  const createOrUpdateRecommendationFlow = () => {
    if (isBlank(recommendation)) {
      createRecommendationFlow()
    }
    else {
      updateRecommendationFlow()
    }
  }

  const createRecommendationFlow = () => {
    updateRecordDecision({ flow_enum: flowEnum })
    createRecommendation({
      final_decision_reasons: reasons,
      expected_results: expectedResults,
      next_steps_description: nextStepsDescription,
      choice_entry_widget_type: 'open_ended',
      expected_investment: expectedInvestment,
      expected_opportunity: expectedOpportunity
    }, (success, recommendationSlug = '') => {
      if(success) {
        EntryPoint.instance.recommendationSlug = recommendationSlug;
        recommendationChoiceCreate()
        recommendationConsideredChoiceCreate()
        setDecisionInputSidebarOpen(false)
      } else {
        setDecisionInputSidebarOpen(false)
      }
    })
  }

  const updateRecommendationFlow = () => {
    if (isPresent(existedRecommendationChoice)) {
      if (existedRecommendationChoice.description.trim() !== openEndedChoice && isPresent(openEndedChoice)) {
        updateRecommendationChoice(existedRecommendationChoice.slug,
          {choice: {description: openEndedChoice, ignore_line_breaks: true }});
      } else if (isBlank(openEndedChoice)) {
        destroyRecommendationChoice(existedRecommendationChoice.slug)
        return setDecisionInputSidebarOpen(false)
      }
    }

    updateRecommendation({
      final_decision_reasons: reasons,
      expected_results: expectedResults,
      next_steps_description: nextStepsDescription,
      choice_entry_widget_type: 'open_ended',
      expected_investment: expectedInvestment,
      expected_opportunity: expectedOpportunity,
      slug: recommendation.slug
    }, (success) => {
      if(success && isPresent(existedRecommendationChoice)) {
        updateRecommendationFinalChoices(existedRecommendationChoice.slug, {
          draft: draft
        })
      } else if (isBlank(existedRecommendationChoice)) {
        recommendationChoiceCreate()
      }
      if(success) {
        EntryPoint.instance.recommendationSlug = recommendation.slug;
        recommendationConsideredChoiceCreate()
      }
      setDecisionInputSidebarOpen(false)
    })
  }

  const createFinalDecisionFlow = () => {
    updateRecordDecision({
      rate_support_on_slack: rateSupport,
      flow_enum: flowEnum,
      final_decision_reasons: reasons,
      expected_results: expectedResults,
      next_steps_description: nextStepsDescription,
      expected_investment: expectedInvestment,
      expected_opportunity: expectedOpportunity,
      historical_decided_at: decisionDate
    })
    if(isBlank(existedOpenEndedChoice)) {
      createChoice({
        choice: { description: openEndedChoice, open_ended: true, ignore_line_breaks: true, draft: draft }
      }, updateFinalChoices, false, draft);
    } else {
      if (existedOpenEndedChoice.description.trim() !== openEndedChoice && isPresent(openEndedChoice)) {
        updateChoice(existedOpenEndedChoice.slug,
          {choice: {description: openEndedChoice, ignore_line_breaks: true }});
      }
      updateFinalChoices(existedOpenEndedChoice.slug, { draft: draft })
      if (isBlank(openEndedChoice)) {
        destroyChoice(existedOpenEndedChoice.slug)
      }
    }
    decisionConsideredChoiceCreate()
    setDecisionInputSidebarOpen(false)
  }
  const decisionConsideredChoiceCreate = () => {
    const callback = (success, choices) => { success && setConsideredChoices(choices) }
    createConsideredChoices({considered_choices: {data: filteredConsideredChoices}}, callback);
  }

  const recommendationChoiceCreate = () => {
    createRecommendationChoice({
      choice: { description: openEndedChoice, open_ended: true, ignore_line_breaks: true, draft: draft }
    }, updateRecommendationFinalChoices, false, draft);
  }

  const recommendationConsideredChoiceCreate = () => {
    const callback = (success, choices) => { success && setConsideredChoices(choices)}
    createRecommendationConsideredChoices({considered_choices: {data: filteredConsideredChoices}}, callback);
  }

  if(flowEnum === flow.D_SIGHT_RECOMMENDATION) {
    createOrUpdateRecommendationFlow(flowEnum, reasons, expectedResults, consideredChoices, setConsideredChoices, nextStepsDescription, expectedInvestment,
      expectedOpportunity, openEndedChoice, updateRecordDecision, updateRecommendationChoice, destroyRecommendationChoice,
      createRecommendation, createRecommendationChoice, createRecommendationConsideredChoices, updateRecommendationFinalChoices, setDecisionInputSidebarOpen)
  } else {
    createFinalDecisionFlow(rateSupport, flowEnum, reasons, expectedResults, consideredChoices, setConsideredChoices, nextStepsDescription, expectedInvestment,
      expectedOpportunity, decisionDate, openEndedChoice, destroyChoice, updateChoice,
      updateRecordDecision, createChoice, createConsideredChoices, updateFinalChoices, setDecisionInputSidebarOpen)
  }
}

const Footer = ({
                  flowEnum,
                  rateSupport,
                  openEndedChoice,
                  reasons,
                  expectedResults,
                  consideredChoices,
                  setConsideredChoices,
                  nextStepsDescription,
                  decision,
                  setDecisionInputSidebarOpen,
                  createChoice,
                  createConsideredChoices,
                  updateFinalChoices,
                  createRecommendation,
                  createRecommendationChoice,
                  createRecommendationConsideredChoices,
                  updateRecommendationFinalChoices,
                  updateRecommendationChoice,
                  destroyChoice,
                  destroyRecommendationChoice,
                  updateChoice,
                  updateRecordDecision,
                  updateRecommendation,
                  expectedInvestment,
                  expectedOpportunity,
                  decisionDate
                }) => {

  const draft = false
  const onClose = () => {
    onSaveDsight(flowEnum,
      rateSupport,
      openEndedChoice,
      reasons,
      expectedResults,
      consideredChoices,
      setConsideredChoices,
      nextStepsDescription,
      setDecisionInputSidebarOpen,
      createChoice,
      createConsideredChoices,
      updateFinalChoices,
      createRecommendation,
      createRecommendationChoice,
      createRecommendationConsideredChoices,
      updateRecommendationFinalChoices,
      updateRecommendationChoice,
      destroyChoice,
      destroyRecommendationChoice,
      updateChoice,
      updateRecordDecision,
      updateRecommendation,
      expectedInvestment,
      expectedOpportunity,
      decisionDate,
      decision,
      draft
    )
  }

  return <div className={`${isMobile ? 'mb-5' : 'side-panel bottom'} p-3`} style={{zIndex: 0}}>
    <DoneButton onClose={onClose} />
  </div>
}
const mapStateToProps = ({ sidebar }) => ({ decision: sidebar.decision });
export default connect(mapStateToProps, {
  setDecisionInputSidebarOpen,
  createRecommendation,
  createRecommendationChoice,
  createRecommendationConsideredChoices,
  updateRecommendationFinalChoices,
  createChoice,
  createConsideredChoices,
  updateFinalChoices,
  updateRecordDecision,
  updateRecommendation,
  updateRecommendationChoice,
  destroyChoice,
  destroyRecommendationChoice,
  updateChoice
})(Footer);
