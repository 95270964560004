import React, { useState, useEffect, useMemo } from 'react';
import { getKeyByValue, isBlank } from "../../../../helpers/common";
import {
  chartBasesValueFor,
  filteredProcessData,
  findProcessDataFor,
  getLastSurvey,
  lastSurveys,
  processDataForTypeNode,
  TIME_NODE_INDEXES
} from "../../../../models/d_sight/metric_chart_helpers";
import ChartSourcesLabels from "./ChartSourcesLabels";
import { TimePeriodDropdown } from "./TimePeriodDropdown";
import { fetchDynamicChainsNodeNames } from "./helpers/table_helpers";
import BrandTable from "./brand/BrandTable";
import AdsTable from "./advertising/AdsTable";
import NonBrandTable from "./other_types/NonBrandTable";

const filterSurveys = (d_sight_goal, timeNode, chartData, metric) => {
  const sortedNodes = fetchDynamicChainsNodeNames(chartData, d_sight_goal, metric)
  const timeNodeKey = parseInt(getKeyByValue(TIME_NODE_INDEXES, timeNode))

  return lastSurveys(d_sight_goal, timeNode).filter(survey =>
    chartData.dynamic_chains.some(chainData => {
      const process_data = filteredProcessData(chainData, d_sight_goal)
      const filtered_process_data = processDataForTypeNode({ process_data }, timeNode)
      return sortedNodes.some(nodeId =>
        chartBasesValueFor(survey, findProcessDataFor(filtered_process_data, nodeId, timeNodeKey)) > 0
      )
    })
  )
}

export default ({
                  d_sight_goal,
                  metric, masterMetric = null, header = null,
                  chartData, timeNode, sourceLabels,
                  ...opts
                }) => {
  if (isBlank(chartData)) return null;

  const surveys = useMemo(() =>
    filterSurveys(d_sight_goal, timeNode, chartData, metric)
    , [timeNode, chartData])

  const [selectedSurvey, setSelectedSurvey] = useState(getLastSurvey(surveys))
  useEffect(() => {
    setSelectedSurvey(getLastSurvey(surveys))
  }, [timeNode])

  const tableOpts = {
    d_sight_goal, surveys, chartData, timeNode, selectedSurvey, metric, sourceLabels, masterMetric,
    ...opts,
    header: () =>
      <>
        <TimePeriodDropdown {...{ selectedSurvey, setSelectedSurvey, surveys }}
                            header={header || `${(masterMetric || metric).name} by period`} />
        <div className={`mb-2`} hidden={isBlank(chartData.question)}>
          <div>
            <span className='h5 text-black'>Question: </span>
            <span className='text-dark'>{chartData.question}</span>
          </div>
        </div>
      </>,
    footer: () =>
      <div className="mt-2" hidden={!sourceLabels}>
        {
          sourceLabels ? <ChartSourcesLabels {...{ chartData, surveys, hideQuestion: true }} /> : null
        }
      </div>
  }
  if (metric.isBrandMetric(d_sight_goal)) return <BrandTable {...tableOpts} />
  if (metric.isAdvertisingMetric(d_sight_goal)) return <AdsTable {...tableOpts} />;
  if (metric.isNonBrandMetric(d_sight_goal)) return <NonBrandTable {...tableOpts} />;

  return null;
}
