import React from 'react';
import { Page, View, Text, Link } from '@react-pdf/renderer';
import styles from '../../styles/common'
import DocumentFooter from "./DocumentFooter";
import DocumentHeader, {DummyHeader} from "./DocumentHeader";
import { isBlank } from "../../../helpers/common";
import { CustomBullet, CustomEmptyBubble } from "../../helpers/common";

const LiIcon = ({parent_id, depth}) => {
  if (isBlank(parent_id) || isBlank(depth)) return null

  return depth % 2 ? <CustomBullet /> : <CustomEmptyBubble />
}

const DriverBlock = ({driver, children, org_driver_types, depth = 0}) => {
  if (isBlank(driver.question)) return null;

  return <Link src={`#${driver.slug}`} style={[styles.linkText, {lineHeight: 2.1}]}>
    <View style={[styles.row, {overflow: 'hidden'}]}>
      <LiIcon {...driver} key={`driver-${driver.slug}`} depth={depth}/>
      <Text style={[styles.row, {overflow: 'hidden'}]}>{driver?.question}</Text>
    </View>
    <View style={styles.list}>
      { children.map(d => <DriverBlock {...d} key={`driver-block-${d.driver.slug}`} org_driver_types={org_driver_types} depth={depth+1} />) }
    </View>
  </Link>
}

const DriversPage = ({ decision, org, drivers, org_driver_types, format }) => {
  if (isBlank(drivers)) return null;

  return <Page size={format} style={styles.container} id={'drivers_page'}>
    <DummyHeader text={'Drivers'} id={'drivers_page'} />
    <DocumentHeader description={'Drivers'} src_id={'#drivers_page'} />
    <View style={[styles.body, {lineHeight: 2.1, maxWidth: '95%'}]}>
      <Link style={styles.linkText} src={'#continuous_page'}>{decision.description}</Link>
      <View style={styles.pl3}>
        { drivers.map(d => <DriverBlock {...d} key={`driver-block-${d.driver.slug}`} org_driver_types={org_driver_types} />) }
      </View>
    </View>
    <DocumentFooter org={org} />
  </Page>
}
export default DriversPage;
