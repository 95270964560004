import React from 'react';
import Decision, {CHOICE_ENTRY_TYPE_DATA} from "../../../models/decision";
import {isBlank, isPresent} from "../../../helpers/common";
import RankedChoices from "./RankedChoices";
import PickyChoices from "./PickyChoices";
import TopChoice from "./TopChoice";
import Recommendation from "../../../models/recommendation";

const Choices = ({ object, choicesVotes, setChoicesVotes, user }) => {
  if (isBlank(choicesVotes)) return null;

  if(object.isRankedList) {
    return <RankedChoices {...{object, choices: choicesVotes, setChoicesVotes, user}} />
  } else if(object.isPicky) {
    return <PickyChoices {...{object, choicesVotes, setChoicesVotes, user}} />
  } else if(object.isTopChoice) {
    return <TopChoice {...{object, choicesVotes, setChoicesVotes, user}} />
  } {
    return null;
  }
}

const PollChoicesSection = ({decision, choicesVotes, setChoicesVotes, user, addClass = ''}) => {
  if (isBlank(decision)) return null;
  const decisionObj = new Decision(decision);
  const recommendation = decision?.recommendation
  const object = decisionObj.isRecommendationFlow ? new Recommendation(recommendation) : decisionObj

  return <>
    <h3 className={`${isPresent(decision.poll_notes) && isPresent(addClass) ? addClass : ''} mx-3`}>{decision.description}</h3>
    <div className="text-muted fw-normal mb-1 mx-3 mb-2">{CHOICE_ENTRY_TYPE_DATA[decisionObj.isRecommendationFlow ? recommendation.choice_entry_widget_type : decision.choice_entry_widget_type]}</div>
    <Choices {...{decision, choicesVotes, setChoicesVotes, object, user}} />
  </>
}
export default PollChoicesSection;
