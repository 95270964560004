import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import ChartValuesType, { CHART_VALUES_TYPES } from "./components/ChartValuesType";
import MetricsSelect from "./components/MetricSelect";
import { Loader } from "../../common/Loader";
import { updateViewOptions } from "../../store/forecast_simulator_scenario/actions";
import PeriodSelectors from "./components/PeriodSelectors";
import DecompositionChartBlock, { fetchDecompositionData } from "./components/DecompositionChartBlock";
import { generatePromisesCallbacks, isBlank, isPresent } from "../../helpers/common";
import NoChartsDataPlaceholder from "./components/NoChartsDataPlaceholder";
import { checkAllNonChartsComponentsRendered } from "../helpers/helpers";
import {isStoreLoading} from "../../helpers/callbacks_helpers";
const valueForDifferenceCalc = (value) => parseFloat(value.toFixed(10))

const generateLoadDecompDataPromise = ({
                                         controller, config,
                                         fromPeriod, toPeriod,
                                         metric, chartValuesType,
                                         cmusGroups,
                                         scenario, setResult,
                                         rows = false
                                       }) => {
  return new Promise((resolve, reject) => {
    // fetching data from resource two and changing some states
    controller.signal.addEventListener('abort', () => reject());
    setResult(
      fetchDecompositionData({
        scenario, config,
        fromPeriod, toPeriod,
        metric, chartValuesType,
        cmusGroups, rows
      })
    )
    resolve(true)
  });
}

const ScenarioForecastDecomposition = ({
                                         forecast_simulator_scenario,
                                         config, timeScale,
                                         forecastScenario, forecastBenchmarkScenario,
                                         updateViewOptions,
                                         simulatedDecompData, setSimulatedDecompData,
                                         benchmarkDecompData, setBenchmarkDecompData,
                                         filteredSimulationScenarioRows, filteredBenchmarkScenarioRows
                                       }) => {
  if (isStoreLoading(forecast_simulator_scenario, 'scenario') || isStoreLoading(forecast_simulator_scenario, 'benchmark')) return <Loader />

  const controller = new AbortController();
  useEffect(() => {
    return generatePromisesCallbacks({ setLoading, controller })
  }, []);

  const view_options = forecast_simulator_scenario?.scenario?.view_options
  const [selectedDecompMetric, setDecompMetric] = useState(view_options?.selectedDecompMetric)
  const [from, setFrom] = useState(view_options?.decompFrom || config.getTimeScaleBy(timeScale).fromPeriod.start_date)
  const [to, setTo] = useState(view_options?.decompTo || config.getTimeScaleBy(timeScale).toPeriod.end_date)
  const [chartValuesType, setChartValuesType] = useState(view_options?.chartValuesType || CHART_VALUES_TYPES.percent)
  const [nonChartsComponentsRendered, setNonChartsComponentsRendered] = useState(false);
  const [loading, setLoading] = useState(forecast_simulator_scenario.view_loading);
  const [disabled, setDisabled] = useState(false);

  const saveFrom = (decompFrom) => {
    setDisabled(true)
    updateViewOptions({ decompFrom }, (status) => {
      if(status) setFrom(decompFrom)
      setDisabled(false)
    })
  }
  const saveTo = (decompTo) => {
    setDisabled(true)
    updateViewOptions({ decompTo }, (status) => {
      if(status) setTo(decompTo)
      setDisabled(false)
    })
  }

  const {
    fromPeriod, toPeriod
  } = useMemo(() => {
    const periods = forecastScenario?.allTimeScalePeriods() || [];
    return {
      fromPeriod: periods.find(p => p.start_date === from) || {},
      toPeriod: periods.find(p => p.end_date === to) || {}
    }
  }, [from, to, forecastScenario])

  const metric = useMemo(() => {
    return config.outputColumns.find(output => output.id === selectedDecompMetric) || {}
  }, [selectedDecompMetric])

  const { cmusGroups } = forecastScenario.simulationScopesData

  useEffect(() => {
    if (!nonChartsComponentsRendered) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastScenario, setResult: setSimulatedDecompData, rows: filteredSimulationScenarioRows
        }),
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastBenchmarkScenario, setResult: setBenchmarkDecompData, rows: filteredBenchmarkScenarioRows
        })
      ]
    })
  }, [fromPeriod.id, toPeriod.id, metric.id, chartValuesType])

  useEffect(() => {
    if (!nonChartsComponentsRendered) return;
    if (isPresent(simulatedDecompData)) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastScenario, setResult: setSimulatedDecompData, rows: filteredSimulationScenarioRows
        }),
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastBenchmarkScenario, setResult: setBenchmarkDecompData, rows: filteredBenchmarkScenarioRows
        })
      ]
    })
  }, [nonChartsComponentsRendered])

  useEffect(() => {
    if (!nonChartsComponentsRendered) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastScenario, setResult: setSimulatedDecompData, rows: filteredSimulationScenarioRows
        })
      ]
    })
  }, [forecastScenario.id, filteredSimulationScenarioRows]);
  useEffect(() => {
    if (!nonChartsComponentsRendered) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastBenchmarkScenario, setResult: setBenchmarkDecompData, rows: filteredBenchmarkScenarioRows
        })
      ]
    })
  }, [forecastBenchmarkScenario.id, filteredBenchmarkScenarioRows]);

  useEffect(() => {
    if (nonChartsComponentsRendered || isBlank(metric)) return;
    if (isPresent(simulatedDecompData)) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastScenario, setResult: setSimulatedDecompData, rows: filteredSimulationScenarioRows
        }),
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastBenchmarkScenario, setResult: setBenchmarkDecompData, rows: filteredBenchmarkScenarioRows
        })
      ]
    })
  }, []);

  const differenceChartsData = useMemo(() => {
    if (isBlank(simulatedDecompData?.chartsData) || isBlank(benchmarkDecompData?.chartsData)) return [];

    return simulatedDecompData.chartsData.map(({ name, y }, index) => {
      const difference = valueForDifferenceCalc(y) - valueForDifferenceCalc(benchmarkDecompData.chartsData[index].y)
      return { name, y: difference }
    })
  }, [simulatedDecompData, benchmarkDecompData])

  return <>
    <div className="position-sticky top-72 bg-white z-1 w-100 pt-3">
      <div className="container-xxl">
        <div className="row justify-content-center">
          <div className="col-7 col-lg-3 px-1 mb-2">
            <MetricsSelect {...{
              selectedDecompMetric, setDecompMetric, config, timeScale, loading: loading
            }} />
          </div>
          <PeriodSelectors  {...{
            to, setTo: saveTo,
            from, setFrom: saveFrom,
            options: config.periodOptions(timeScale),
            disabled: loading || disabled
          }} />
          <div className="col-auto px-1 mb-2">
            <ChartValuesType {...{
              chartValuesType, setChartValuesType,
              loading: loading
            }} />
          </div>
        </div>
      </div>
    </div>
    {
      (checkAllNonChartsComponentsRendered(nonChartsComponentsRendered, setNonChartsComponentsRendered) || loading) && <Loader />
    }
    { nonChartsComponentsRendered && !loading && isPresent(simulatedDecompData) ?
      (isBlank(simulatedDecompData.chartsData) && isPresent(cmusGroups) ? <NoChartsDataPlaceholder/> :
        <>
          <DecompositionChartBlock
            {...simulatedDecompData}
            {...{
              fromPeriod, toPeriod, scenario: forecastScenario, metric, chartValuesType
            }} />
          {
            forecastBenchmarkScenario && <>
              <DecompositionChartBlock
                {...benchmarkDecompData}
                {...{
                  fromPeriod, toPeriod, scenario: forecastBenchmarkScenario, metric, chartValuesType
                }} />
              <DecompositionChartBlock {...{
                showTotals: false, title: 'Difference', metric, chartValuesType, chartsData: differenceChartsData
              }} />
            </>
          }
        </>
      ) : null
    }
  </>

}

const mapStateToProps = ({ forecast_simulator_scenario }) => ({ forecast_simulator_scenario });

export default connect(mapStateToProps, { updateViewOptions })(ScenarioForecastDecomposition);
