import React, { useMemo } from 'react'
import { connect } from "react-redux";
import DriverActions from './DriverActions';
import ZTree from "./ZTree";
import { DoneBtnW100 } from "../../common/DoneButton";
import { isMobile } from "react-device-detect";
import { Loader } from "../../common/Loader";
import { isBlank } from "../../helpers/common"
import ZTreeDriverDetailsModal from "../modals/ZTreeDriverDetailsModal";
import {
  isDriverInEditModeByUser,
  performEditDriverDetailsAction,
  treeChannelIsConnected
} from "../../helpers/channel_helpers";
import { TREE_CHANNEL_ACTIONS } from "../../../../channels/tree_channel";
import { transferDataAndCloseModal } from "../../store/modals/actions";
import { fetchContacts } from "../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import { TREE_MODES } from "../../helpers/drivers_helpers";

const DETAILS_MODALS_TYPES = ["TreePanelAddDriverDetailsModal", "TreePanelDriverDetailsModal"];

export const Header = ({treeMode}) => <h2>{treeMode}</h2>

export const Footer = ({ onClose }) =>
  <div className="row">
    <div className="col-12">
      <DoneBtnW100 onClose={onClose} />
    </div>
  </div>

const getCollaborators = (isTemplate, contactsData) => isTemplate ?
  fetchContacts({}, contactsData, false, false):
  fetchContacts({}, contactsData, true)

const showDetails = (modal) => DETAILS_MODALS_TYPES.includes(modal.type) && modal.shown

const modalTitle = (modal) =>
  modal.type === "TreePanelAddDriverDetailsModal" ? 'Add a driver' : 'Edit driver'

const PanelTreeEditor = ({
                           tree, channels, user, contactsData, modal, treeMode,
                           preview = false, isTemplate = false,
                           forceEditLink = false,
                           onClose, transferDataAndCloseModal, current_org,
                           ...opts
                        }) => {
  if(isBlank(tree.drivers)) return <Loader />;
  const collaborators = useMemo(() => getCollaborators(isTemplate, contactsData), [isTemplate, contactsData]);

  const onCloseDriverDetailsModal = () => {
    if(treeChannelIsConnected() && isDriverInEditModeByUser(channels, TREE_CHANNEL_ACTIONS.edit_driver_details, user)) {
      performEditDriverDetailsAction(user, modal.slug, false)
    }
    transferDataAndCloseModal();
  }

  return (
    <div className="py-2 px-3">
      <Header treeMode={treeMode} />
      <div className="tree-container mb-2">
        <div className={`tree-body ${treeMode === TREE_MODES.edit ? current_org.allow_gpt_suggestions ? 'edit-mode-gpt-suggestions' : 'edit-mode two-rows' : "" } ${treeMode !== TREE_MODES.edit ? "assign-mode" : "" }`}>
          <ZTree {...{ ...opts, treeMode, isTemplate, collaborators, preview, forceEditLink }} />
        </div>
      </div>
      <div className="actions-container mt-1 container-fluid">
        {treeMode === TREE_MODES.edit ? <DriverActions /> : null}
      </div>
      <div className={`${(isMobile ? "footer-done-space" : "")}`}>
        {(treeMode === TREE_MODES.edit || treeMode === TREE_MODES.assign) ? <Footer onClose={onClose} /> : null}
      </div>
      <div className="modals">
        <ZTreeDriverDetailsModal show={showDetails(modal)} title={modalTitle(modal)} onClose={onCloseDriverDetailsModal}
                                 {...{ isTemplate, collaborators, allowNew: !isTemplate }} />
      </div>
    </div>
  )
}
const mapStateToProps = ({ current_org, tree, modal, channels, current_user, contacts }) => ({
  current_org, tree, channels, modal, user: current_user, contactsData: contacts
});
export default connect(mapStateToProps, { transferDataAndCloseModal })(PanelTreeEditor);
