import React from "react";
import { connect } from "react-redux";
import { updateViewOptions } from "../../../store/forecast_simulator_scenario/actions";
import Toggle from "../../../common/Toggle";

export const SIMULATED_TYPES = {
  simulated: 'simulated',
  both: 'both'
}

const SCENARIO_TYPES = [
  {
    value: SIMULATED_TYPES.simulated,
    body: 'Simulated'
  },
  {
    value: SIMULATED_TYPES.both,
    body: 'Both'
  },
]

const SimulatedType = ({
                         simulatedType, setSimulatedType,
                         updateViewOptions,
                         loading = false
                       }) => {
  const onChange = (simulatedType) => {
    updateViewOptions({ simulatedType }, (status) => {
      if(status) setSimulatedType(simulatedType);
    }, false)
  }
  return <>
    <div style={{width: '161px'}}>
      <div className="mb-1">Scenario</div>
      <Toggle items={SCENARIO_TYPES} selectedItem={simulatedType} onClick={onChange} padding={'px-2'} disabled={loading}/>
    </div>
  </>;
}
const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, { updateViewOptions })(SimulatedType);
