import React, { Fragment } from 'react';
import { Highlighter } from 'react-bootstrap-typeahead';
import { isGroupContact } from "../../../helpers/decision_helpers";
import { USER_ICON, USERS_GROUP_ICON } from "../../../helpers/share_helpers";
import { isPresent } from "../../../helpers/common";

const iconByOption = (contact) => {
  if (isGroupContact(contact)) {
    return USERS_GROUP_ICON
  } else if (contact.full_name) {
    return USER_ICON
  } else {
    return USER_ICON
  }
}

const labelByOption  = (contact) => {
  if (isGroupContact(contact)) {
    return contact.full_name;
  } else if (contact.full_name) {
    return `${contact.full_name} <${contact.email}>`;
  } else {
    return contact.email;
  }
}

export const onChangeInvites = (selected, setInvites, filterNewInvites) => {
  const newInvites = [];
  selected.forEach(contact => {
    if (typeof contact === "string") {
      newInvites.push(contact)
    } else if (isPresent(contact.emails)) {
      contact.emails.split(',').forEach(email => newInvites.push(email))
    } else {
      newInvites.push(contact.value)
    }
  })
  setInvites(filterNewInvites(newInvites))
}

export const renderContactItem = (option, { text }, _index) =>
  <Fragment>
    {iconByOption(option)}&nbsp;
    <Highlighter search={text}>
      {labelByOption(option)}
    </Highlighter>
  </Fragment>
