import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import { setTreeSidebarOpen } from "../../store/sidebar/actions";
import CloseIcon from "../../common/CloseIcon";
import PanelTreeEditor from '../../tree_editor/tree_view/PanelTreeEditor'
import { isPresent } from "../../helpers/common";
import {isDeciderReadOnly, isVisitor} from "../../helpers/user_helpers";
import { EditIcon } from "../../common/EditIcon";
import { TREE_MODES } from "../../helpers/drivers_helpers";
import { isDecider } from "../../helpers/user_helpers";

export const TreePanel = ({ sidebar, openSideBar, isTemplate = false, isDSight = false, current_user }) => {
  const [treeMode, setTreeMode] = useState(sidebar.treeMode);

  useEffect(() => {
    if(isPresent(sidebar.treeMode)) {
      setTreeMode(sidebar.treeMode);
    }
  }, [sidebar.treeMode])
  const onClose = () => openSideBar(false);
  return (
    <SlidingPanel
      type="left"
      isOpen={sidebar.treeSidebar}
      panelContainerClassName="sidebar-panel-wrapper tree-panel-wrapper decision-tree-panel-width left-side-panel-position tree-page"
      panelClassName="sidebar-panel-wrapper-content tree-panel-content h-auto"
      size={50}>
      <div className="d-flex mt-2 me-3 float-end">
        <div hidden={isDeciderReadOnly(current_user) || isVisitor(current_user) || treeMode === TREE_MODES.edit || !isDecider(current_user)}>
          <EditIcon classNames="ms-2" onClick={() => {openSideBar(true, TREE_MODES.edit)}}/>
        </div>
        <CloseIcon {...{onClose}} classParams="ms-2" />
      </div>
      <PanelTreeEditor {...{ isDSight, treeMode, isTemplate, onClose }} />
    </SlidingPanel>
  )
}

const mapStateToProps = ({ sidebar, current_user }) => ({ sidebar, current_user });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options, treeMode) => {
    dispatch(setTreeSidebarOpen(options, treeMode));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(TreePanel);
