import React from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TemplateTile from "./tiles/TemplateTile";
import { isBlank, isPresent, qSortArray } from "../../../helpers/common";
import { startNewDecision } from "../../../store/homepage/actions";
import { Goal } from "../../../models/d_sight/goal";

const DSightSection = ({ home, startNewDecision, selectedGoal }) => {
  const { data } = home.dsight
  if (isBlank(selectedGoal)) return null;

  const templates = data.templates || []
  const filteredTemplates = templates.filter(t => isBlank(t.data_set_slug) ||
    (t.data_set_slug === selectedGoal.data_set_slug && t.goal_ids.includes(selectedGoal.id))
  )

  const levers = new Goal(selectedGoal, filteredTemplates).levers
  const templateSortValue = (template) => levers.find(l => l.templateSlug === template.slug)?.index

  const tiles = qSortArray(filteredTemplates,true, templateSortValue)

  const history = useHistory();

  return <div>
    <div className="row">
      {
        tiles.map(tile =>
          <TemplateTile template={tile} key={`template-tile-${tile.slug}`} history={history} startNewDecision={startNewDecision} />
        )
      }
    </div>
  </div>
}
const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps, { startNewDecision })(DSightSection);
