import { totalUsersVotes } from "./user_helpers";
import { isDSightDecision } from "./home_decision_helpers";
import Decision from "../models/decision";
import Recommendation from "../models/recommendation";
import { isBlank } from "./common";
import { collectRecommendationUsersVotes, collectUsersVotes } from "./decision_helpers";

export const isVoted = (decision) => {
  const usersChoices = userVotesChoicesCallback(decision)
  return totalUsersVotes(usersChoices) > 0
}
export const userVotesChoicesCallback = (decision) => {
  if (isDSightDecision(decision)) return [];

  const decisionObj = new Decision(decision)
  const recommendationObj = new Recommendation(decision.recommendation)
  const isRecommendationFlow = decisionObj.isRecommendationFlow
  const choices = isRecommendationFlow ? recommendationObj.recommendationChoices : decisionObj.decisionChoices
  if (isBlank(choices)) return [];

  return new Decision(decision).isRecommendationFlow ?
    collectRecommendationUsersVotes(decision.recommendation) :
    collectUsersVotes(decision)
}
