import {compareCurrencies, currencyFormat, isBlank, isPresent} from "../../../helpers/common";
import {baseBlockSection, baseHeadParagraph, baseRichBlock, paragraphBorder} from "./wrappers";
import {HeadingLevel, Paragraph, TextRun} from "docx";
import Decision from "../../../models/decision";
import {mutedProps, space4d13pts} from "../../styles/docx_styles";
import {enteredBySection} from "./commonBlocks";

const changedDecisionBlock = (decisionObj) => {
  const followupChoice = decisionObj.followupChoice;
  if (isBlank(followupChoice)) return [];

  return [
    ...baseBlockSection(
      {
        header: 'We changed our decision:',
        text: followupChoice.description,
        heading: HeadingLevel.HEADING_4,
        instance: 4
      }
    ),
  ]
}

const expectationDataBlock = (decisionObj) => {
  const expectationData = decisionObj.expectationData;
  const followupChoice = decisionObj.followupChoice;
  if (isBlank(expectationData)) return {};

  return baseHeadParagraph({text: expectationData.formatLabel, heading: isPresent(followupChoice) ? HeadingLevel.HEADING_3 : HeadingLevel.HEADING_4})
}

const whatHappenedBlock = (decisionObj) => {
  const whatHappened = decisionObj.lastFollowup.followup_what_happened
  if (isBlank(whatHappened)) return [];

  return [
    ...baseRichBlock({text: whatHappened, instance: 8}),
  ]
}

const realizedOpportunityBlock = (decisionObj) => {
  const realizedOpportunity = decisionObj.lastFollowup.realized_value
  if (isBlank(realizedOpportunity)) return [];

  const prefix = ` (${compareCurrencies(decisionObj.expectedOpportunity, realizedOpportunity)})`

  return [
    baseHeadParagraph ({text: 'Realized value'}),
    new Paragraph({
      style: "normalPara",
      children: [
        new TextRun({
          text: currencyFormat(realizedOpportunity)
        }),
        decisionObj.expectedOpportunityPresent ?
          new TextRun({
            text: prefix,
            ...mutedProps,
            bold: false
          }) : {}
      ]
    })
  ]
}

const realizedInvestmentBlock = (decisionObj) => {
  const realizedInvestment = decisionObj.lastFollowup.realized_investment
  if (isBlank(realizedInvestment)) return [];

  const prefix = ` (${compareCurrencies(decisionObj.expectedInvestment, realizedInvestment)})`

  return [
    baseHeadParagraph ({text: 'Realized investment or cost'}),
    new Paragraph({
      style: "normalPara",
      children: [
        new TextRun({
          text: currencyFormat(realizedInvestment)
        }),
        decisionObj.expectedInvestmentPresent ?
          new TextRun({
            text: prefix,
            ...mutedProps,
            bold: false
          }) : {}
      ]
    })
  ]
}

const resultsUserBlock = (decisionObj) => {
  const lastFollowup = decisionObj.lastFollowup;
  if (isBlank(lastFollowup.user)) return {};

  return enteredBySection({user: lastFollowup.user, time: lastFollowup.followed_up_at, space: space4d13pts})
}

export const ResultsSection = (decision) => {
  const decisionObj = new Decision(decision)
  if (!decisionObj.isDecisionWithResults || !decisionObj.isRecordedOrRecommended) return [];

  return [
    baseHeadParagraph ({text: 'Results'}),
    ...changedDecisionBlock(decisionObj),
    expectationDataBlock(decisionObj),
    ...whatHappenedBlock(decisionObj),
    ...realizedOpportunityBlock(decisionObj),
    ...realizedInvestmentBlock(decisionObj),
    resultsUserBlock(decisionObj),
    paragraphBorder(),
  ]
}
