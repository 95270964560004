import React from "react";
import WaterfallChart, { COLORS } from "../charts/WaterfallChart";
import { CHART_VALUES_TYPES } from "./ChartValuesType";
import TotalsBlock from "./TotalsBlock";
import { isBlank, isPresent, qSortArray } from "../../../helpers/common";
import { generateCustomPeriod } from "../../../models/forecast/ForecastTImeScale";
import { TIME_SCALES } from "../../helpers/ag_grid_vars";

const prepareChartData = ({
                            scenario, config,
                            chartValuesType,
                            metric,
                            fromPeriod, toPeriod,
                            cmus = [], cmusGroups = [], rows = false,
                            startValue, endValue
                          }) => {
  if (isBlank(metric) || isBlank(scenario)) return [];

  const customPeriod = generateCustomPeriod({
    fromPeriod,
    toPeriod,
    timeScale: config.timeScales.find(ts => ts.key === TIME_SCALES[0])
  })
  const impacts = qSortArray(
    config.driversColumns.filter(dr => isPresent(dr.decompRules[metric.id]?.visible)),
    true, impact => impact.decompId(metric)
  )

  const changeValue = chartValuesType === CHART_VALUES_TYPES.value ?
    endValue - startValue :
    (endValue / startValue - 1) * 100
  let impactsSum = 0
  const rowsData = impacts.map(impact => {
    if (impact.isAllOtherImpacts(metric)) {
      return ({
        name: impact.displayName || impact.decompName(metric),
        y: changeValue - impactsSum
      })
    }

    const impactValue = scenario.aggregateImpactsBy({
      driver: impact, period: customPeriod, cmus, cmusGroups, rows,
      metric,
      chartValuesType
    }) * ( chartValuesType === CHART_VALUES_TYPES.percent ? 100 : 1 )
    impactsSum += impactValue;
    return ({
      name: impact.displayName || impact.decompName(metric),
      y: impactValue
    })
  })

  // console.log(rowsData)
  return [
    ...rowsData,
    {
      name: 'Change',
      color: COLORS[2],
      y: changeValue
    }
  ]
}

export const fetchDecompositionData = ({
                                         scenario, config,
                                         fromPeriod, toPeriod,
                                         metric, chartValuesType,
                                         cmus = [],
                                         cmusGroups = [],
                                         rows = false
                                       }) => {
  if (isBlank(scenario)) return {};

  const startValue = scenario?.aggregateBy({ period: fromPeriod, driver: metric, cmus, cmusGroups, rows })
  if (startValue === null) return { startValue: null, endValue: null, chartsData: null };

  const endValue = scenario?.aggregateBy({ period: toPeriod, driver: metric, cmus, cmusGroups, rows })

  const chartsData = prepareChartData({
    scenario, metric,
    fromPeriod, toPeriod, rows,
    config,
    chartValuesType,
    startValue, endValue,
    cmus, cmusGroups
  })

  return {
    startValue, endValue, chartsData
  }
}

const DecompositionChartBlock = ({
                                   scenario = null, fromPeriod = null, toPeriod = null,
                                   metric, chartValuesType, chartsData,
                                   startValue = null, endValue = null,
                                   showTotals = true, title = false
                                 }) => {
  return <div className="mt-2 charts-container d-lg-flex">
    <div className="chart-col pe-lg-2">
      <WaterfallChart
        title={title || `${scenario?.displayName} ${fromPeriod?.name}-${toPeriod?.name}`}
        data={chartsData}
        decimal={chartValuesType === CHART_VALUES_TYPES.percent ? 2 : metric.decimal}
        measure={chartValuesType === CHART_VALUES_TYPES.percent ? '%' : metric.measure}
      />
    </div>
    <div className="totals-col">
      { showTotals && <TotalsBlock {...{ driver: metric, startValue, endValue }} /> }
    </div>
  </div>
}
export default DecompositionChartBlock;
