import React from 'react';
import { capitalizeAllWords, isBlank, uniqueByCallback } from "../../../../../helpers/common";
import { AttributeSegment, initAttrSegmentBy } from "../../../../../models/d_sight/AttributeSegment";
import TableTypeCharts from "../TableTypeCharts";

const SUPPORTED_SEGMENTS = [
  {
    key: 'product',
    header: 'Product Attributes',
    filterAttributes: (dc, d_sight_goal) => initAttrSegmentBy(dc, d_sight_goal)?.isProduct,
    allowSegmentByLever: (lever) => lever.isProductPerformance
  },
  {
    key: 'emotional',
    header: 'Emotional Connection Attributes',
    filterAttributes: (dc, d_sight_goal) => initAttrSegmentBy(dc, d_sight_goal)?.isEmotionalConnection,
    allowSegmentByLever: (_lever) => false
  },
  {
    key: 'Imagery',
    header: 'Imagery Attributes',
    filterAttributes: (dc, d_sight_goal) => initAttrSegmentBy(dc, d_sight_goal)?.isImage,
    allowSegmentByLever: (lever) => lever.isImageryAttributes
  },
  {
    key: 'ksp',
    header: 'Key Selling Point Attributes',
    filterAttributes: (dc, d_sight_goal) => initAttrSegmentBy(dc, d_sight_goal)?.isKeySellingPoint,
    allowSegmentByLever: (_lever) => false
  }
]

export default ({ d_sight_goal, lever = null, ...opts }) => {
  const customSegments = uniqueByCallback(
    d_sight_goal.attribute_segments.map(h => new AttributeSegment(h)).filter(s => s.isCustomCategory),
    (s) => s.group
  )

  const allowedSegments = [
    ...SUPPORTED_SEGMENTS,
    ...customSegments.map(custom => ({
      allowHide: true,
      key: custom.group,
      header: `${capitalizeAllWords(custom.group)} Attributes for Category Custom`,
      filterAttributes: (dc, d_sight_goal) => {
        const segment = initAttrSegmentBy(dc, d_sight_goal)
        return segment.isCustomCategory && segment.group === custom.group;
      },
      allowSegmentByLever: (lever) => lever.isImageryAttributes
    }))
  ].filter(({ allowSegmentByLever }) => isBlank(lever) || allowSegmentByLever(lever))

  return allowedSegments.map(({ key, header, filterAttributes }) =>
    <div className='mb-2' key={`table-${key}`}>
      <TableTypeCharts {...{
        ...opts, d_sight_goal, header,
        filterAttributes: (dc) => filterAttributes(dc, d_sight_goal)
      }} />
    </div>
  )
}
