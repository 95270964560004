import React from 'react';
import HeaderLogo from "../../../../../assets/images/logos/cloverpop_logo_320x80.png";

const CLOVERPOP_WEBSITE = 'https://www.cloverpop.com/';

export default () => <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active mx-auto">
        <div className="logo-dropdown pointer">
          <a href={CLOVERPOP_WEBSITE} target="_blank" rel="noreferrer">
            <img src={HeaderLogo} alt='Cloverpop' />
          </a>
        </div>
      </div>
    </nav>
  </header>
