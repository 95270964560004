import {isBlank, isPresent} from "./common";

export function isDecider(user, previewMode = false) {
  if (previewMode) return false;

  return isPresent(user) && user['decision_role'] === 'decider';
}

export function isDecisionMember(decision, user) {
  if (isBlank(decision) || isBlank(user)) return false;

  return decision.users.find(u => u.email === user.user_email)?.length !== 0
}

export function isDeciderReadOnly(user, previewMode = false) {
  if (previewMode) return false;

  return isPresent(user) && user['decision_role'] === 'decider_read_only';
}

export function isCollaborator(user, previewMode = false) {
  if (previewMode) return false;

  return isPresent(user) && (user['decision_role'] === 'decider' || user['decision_role'] === 'collaborator');
}

export function hasParticipantAdmission(user, previewMode = false) {
  if (previewMode) return false;

  return isPresent(user) && (user['decision_role'] === 'participant_admission');
}

export function isVisitor(user, previewMode = false) {
  if (previewMode) return true;

  return isBlank(user) || user['decision_role'] === 'visitor';
}

export function isOrgAdmin(user) {
  return isPresent(user) || current_user['decision_role'] === 'admin';
}

export function isPublicVisitor(user, previewMode = false) {
  if (previewMode) return true;

  return isBlank(user) || isBlank(user.email);
}

export function showPointer(isVisitor = true, answered = false) {
  if (!isVisitor) return 'pointer'

  return answered ? 'pointer' : ''
}

export const userName = (user, email = '') => {
  const name = user?.full_name || user?.email || email;
  const status = user?.hasOwnProperty('active') && !user.active ? ' (removed)' : ''
  return `${name}${status}`;
}

export const userInitials = (user) => user?.full_name?.split(' ')?.map(w => w[0]?.toUpperCase( ))?.join('')

export const totalUsersVotes = (usersChoices = []) => usersChoices.reduce((total, choice) => total + choice?.votes?.filter(vote => vote?.voted).length, 0);