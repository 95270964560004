import React from 'react';
import { connect } from "react-redux";
import {isDecisionUploader, isHistoricalDecision, isLastStep, isWizardV3} from "../../../helpers/wizard_helpers";
import { Button } from 'react-bootstrap'

export const SubmitStepWrapper = ({ className = 'w-100 mt-3', onClick, disabled, children }) =>
  <Button {...{ className, onClick, disabled }}>
    {children}
  </Button>

export const processStep = (wizard) => `Proceed to step ${wizard.step_index + 2}`

export const StartBtn = ({wizard}) =>
  <>
    {
      isHistoricalDecision(wizard) || isDecisionUploader(wizard) ?
        <>Done</> :
        <>Start decision</>
    }
  </>

export const PreviewBtn = () => 'Preview decision'

const SubmitStepButton = ({ wizard, children = null, ...opts }) => {
  const isTreeBuilderLastStep = wizard.flow_steps && 
                                wizard.flow_steps.length > 0 && 
                                wizard.flow_steps[wizard.flow_steps.length - 1].step === 'tree_builder';
  const isTreeFramingLastStep = wizard.flow_steps && 
                                wizard.flow_steps.length > 0 && 
                                wizard.flow_steps[wizard.flow_steps.length - 1].step === 'tree_framing';

  return (
    <SubmitStepWrapper {...opts}>
      {
        children ||
        (
          isLastStep(wizard) ?
            (isTreeFramingLastStep || isTreeBuilderLastStep ? <StartBtn wizard={wizard}/> :
             (isWizardV3(wizard) && !isHistoricalDecision(wizard) && !isDecisionUploader(wizard) ? <PreviewBtn /> : <StartBtn wizard={wizard}/>)) :
            processStep(wizard)
        )
      }
    </SubmitStepWrapper>
  );
};

const mapStateToProps = ({ wizard }) => ({ wizard });
export default connect(mapStateToProps)(SubmitStepButton);
