import React  from 'react';
import { connect } from "react-redux";
import {isBlank} from "../../../helpers/common";
import {
  createSource,
  destroySource,
  replaceSource,
  saveUploadingSources,
  updateSource
} from "../../../store/template_set/actions";
import NAElement from "../../../tree_view/side_panel/sections/NAElement";
import { decisionDataSources } from "../../../models/decision";
import {TemplateSetSourcesTypes, Type} from "../../../models/data_source";
import {
  AddSourceRows,
  UploadingSourceRow,
} from "../../../template_view/side_panel/sections/DataSourcesSection";
import { generateBatchDataSourceUploadState } from "../../../helpers/uploads_callbacks";
import SourceItem from "../../../report_wizard/data_sources/Item";
import {isAdmin} from "../../../helpers/home_helpers";

const isPreview = () => document.location.pathname.includes('preview')

const LocalAddSourceRowsDecision = ({ user, ...opts }) =>
  !isPreview() && <AddSourceRows {...opts} dataSourceType={Type.TEMPLATE_SET_DATA_SOURCE}
                 formWrapper={'set_data_source'} isTemplate={false} />


const DataSourcesList = ({ template_set, data_sources, user, uploadingFile, isManager,
                           isSetUser, hideMenu = false, ...opts
                         }) => {
  return data_sources.map(data_source =>
    <SourceItem source={data_source}
                dropFilesFormWrapper={'set_data_source'}
                forceEditMode={data_source.just_created}
                hideMenu={!isAdmin(user) || hideMenu}
                key={`template-set-data-source-${data_source.slug}-${data_source.key}`}
                { ...opts }
    />
  )
}

const UploadingSourcesList = ({
                                uploading_sources, user,
                                ...opts
                              }) => {
  return uploading_sources.map((sourceData, i) =>
    <UploadingSourceRow uploading_source={sourceData.uploadingSource}
                        isTemplate={false}
                        key={`decision-set-details-uploading-source-${sourceData.uploadingSource.slug}-${i}`}
                        {...opts}
    />
  )
};

const DataSourcesSection = ({ current_org,
                              template_set, data_sources, user,
                              createSource, uploading_sources,  decision_sources,
                              saveUploadingSources, addClass, recommendation_sources,
                              updateSource, preview = false,
                              ...opts
                            }) => {
  const submitLink = (value, callback) => { createSource({ set_data_source: { link_url: value } }, {}, callback); };
  const uploadOptions = generateBatchDataSourceUploadState(data_sources, uploading_sources, saveUploadingSources, user)

  if (preview && isBlank(data_sources))
    return <div className={`px-3 pb-3`}>
      <NAElement />
    </div>

  return <div className={`${addClass}`}>
    <div className="d-flex flex-column" hidden={isBlank(data_sources)}>
      <DataSourcesList data_sources={data_sources}
                       user={user}
                       template_set={template_set}
                       updateSource={updateSource}
                       {...uploadOptions}
                       {...opts} />
      <UploadingSourcesList uploading_sources={uploading_sources} user={user} {...uploadOptions} {...opts} />
    </div>
    <LocalAddSourceRowsDecision user={user}
                                submitLink={submitLink}
                                createSource={createSource}
                                showExtraMargin={isBlank(data_sources)}
                                {...uploadOptions} />
  </div>
};
const mapStateToProps = ({ template_set, current_org, current_user }) => {
  const sortedRelatedDataSources = decisionDataSources(template_set.data_sources, [], TemplateSetSourcesTypes)
  return {
    current_org: current_org,
    data_sources: sortedRelatedDataSources,
    uploading_sources: template_set.uploading_sources,
    user: current_user,
    template_set: template_set
  }
};
export default connect(mapStateToProps, {
  createSource, updateSource, destroySource, saveUploadingSources, replaceSource
})(DataSourcesSection);
