import {
  BASE_CHARTS_OPTIONS, POINT_HEADER, POINT_SAMPLES_ROW,
  POINT_SIGNS_ROW,
  SERIES_NAME_ROW
} from "../../../../../models/d_sight/metric_chart_helpers";
import { chartYAxis } from "../../../../../helpers/metric_helpers";

export const baseCompOptions = (metric) => ({
  ...BASE_CHARTS_OPTIONS,

  chart: {
    type: 'column',
    spacing: [5, 0, 0, 0],
    style: { fontFamily: 'Montserrat, sans-serif' }
  },

  legend: {
    itemStyle: {
      color: '#1F2F3F',
      fontWeight: '500',
      fontSize: '0.875rem'
    }
  },

  ...chartYAxis(metric, { min: 0, max: 100 }),

  tooltip: {
    headerFormat: `${POINT_HEADER}<table>`,
    pointFormat: `<tr>${SERIES_NAME_ROW}${POINT_SIGNS_ROW}${POINT_SAMPLES_ROW}</tr>`,
    footerFormat: '</table>',
    shared: true,
    style: { color: '#1F2F3F' },
    useHTML: true
  },

  plotOptions: {
    column: {
      pointPadding: 0,
      borderWidth: 0
    }
  },
})
