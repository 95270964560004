import {pageHeader} from "./sections/PageHeader";
import {pageFooter} from "./sections/PageFooter";
import {pageProperties} from "../index";
import {isBlank, isPresent, uniqueBy} from "../../helpers/common";
import {baseHeadParagraph, baseParagraph, paragraphBorder} from "./sections/wrappers";
import {HeadingLevel, Table, TableBorders, TableCell, TableRow, TextRun, WidthType} from "docx";
import Decision, {decisionSelectedCategories} from "../../models/decision";
import {mutedProps, tableCellProps} from "../styles/docx_styles";
import {isDecisionApprover} from "../../helpers/decision_helpers";
import {dataSourceBlock} from "./sections/DataSources";
import { userName } from "../../helpers/user_helpers";

const categoryBlockRow = (category, selectedOptions) =>
  new TableRow({
    children: [
      new TableCell({
        ...tableCellProps,
        width: {
          size: 2000,
          type: WidthType.DXA,
        },
        children: [
          baseParagraph([
            new TextRun({
              text: category.name,
              ...mutedProps,
              bold: false
            })
          ])
        ],
      }),
      new TableCell({
        ...tableCellProps,
        width: {
          size: 9500,
          type: WidthType.DXA,
        },
        children: [
          baseParagraph([
            new TextRun({
              text: selectedOptions.length > 0 ? selectedOptions.map(option => option.description).join(', ') : 'N/A'
            })
          ])
        ],
      }),
    ],
  })

const CategoriesSection = (decision, org_categories) => {
  if (isBlank(org_categories)) return [];

  const selectedCategories = decision.category_options
  const orgCategoriesBody = new Table({
    borders: TableBorders.NONE,
    columnWidths: [2000, 9500],
    rows: org_categories.map(category => {
      const selectedOptions = selectedCategories.map(id => category.options.find(h => h.id === id)).filter(h => isPresent(h))
      return categoryBlockRow(category, selectedOptions)
    })
  })

  return [
    baseHeadParagraph({text: 'Categories', heading: HeadingLevel.HEADING_5}),
    orgCategoriesBody,
    paragraphBorder(),
  ]
}

const CollaboratorsSection = (decision, decisionObj, org_categories) => {
  const collaborators = uniqueBy(decisionObj.collaborators, 'email');

  return [
    baseHeadParagraph({text: 'Collaborators',  heading: isBlank(org_categories) ? HeadingLevel.HEADING_5 : HeadingLevel.HEADING_2 }),
    ...collaborators.map(collaborator =>
      collaboratorRow({
        user: collaborator,
        slug: collaborator.email === decisionObj.decision.user.email ? 'decider' : collaborator.email,
        decision: decision
      })),
    paragraphBorder(),
  ]
}

const collaboratorRow = ({user, slug = '', decision}) => {
  const isUserDecider = slug === 'decider'
  const enteredApprovalsEmails = uniqueBy(decision.user_approvals, 'user_email').map(a => a.user_email)
  const isApprover = isDecisionApprover(decision, user.email) && enteredApprovalsEmails.includes(user.email)
  const decisionManagerText = isUserDecider ? 'decision manager' : '';
  const deciderText = decision.assigned_decider_email === user.email ? 'decider' : '';
  const recommenderText = decision.recommendation?.assigned_recommender_email === user.email ? 'recommender' : '';
  const approverText = isApprover ? 'approver' : '';
  const userDescription = [decisionManagerText, deciderText || recommenderText, approverText].filter(text => isPresent(text)).join(', ')

  return baseParagraph([
    new TextRun({text: userName(user)}),
    new TextRun({...mutedProps, text: isPresent(userDescription) ? ` (${userDescription})` : ''})
  ])
}

const DataSourceSection = (data_sources) => {
  if (isBlank(data_sources)) return [];

  return [
    baseHeadParagraph({text: 'Data Sources'}),
    ...data_sources.map((data_source) => dataSourceBlock(data_source)),
  ]
}

export const DecisionDetailsPage = (format, decision, data_sources, org_categories, extraImages) => {
  const decisionObj = new Decision(decision)
  const selectedCategories = decisionSelectedCategories(org_categories, decision.selected_categories)

  return {
    ...pageProperties({format}),
    ...pageHeader({head: 'Details', description: 'Details', _, anchor: 'anchorForDetailsPage', id: 'anchorForDetailsPage'}),
    ...pageFooter(extraImages),
    children: [
      ...CategoriesSection(decision, selectedCategories),
      ...CollaboratorsSection(decision, decisionObj, selectedCategories),
      ...DataSourceSection(data_sources)
    ]
  }
}
