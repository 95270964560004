import React from 'react';
import { setHelpPanelOpen } from "../../store/sidebar/actions";
import { connect } from "react-redux";
import { hasParticipantAdmission, isVisitor } from "../../helpers/user_helpers";
import { isLearningTab } from "../../helpers/home_helpers";

export const HelpItem = ({home, sidebar, current_user, openHelpPanelOpen, treeHelp = true}) => {
  if ((isVisitor(current_user) && treeHelp) || hasParticipantAdmission(current_user)) return null;
  if (isLearningTab(home, current_user)) return null;

  return <li className={sidebar.helpSidebar ? 'active' : ''}>
    <div className="side-row text-nowrap pointer" onClick={() => { openHelpPanelOpen(!sidebar.helpSidebar) }}>
      <span className="icon d-inline-block text-center ">
        <i className="fas fa-question fa-lg" />
      </span>
      <span className="d-none d-lg-inline tab-title">Help & resources</span>
    </div>
  </li>
}

const mapStateToProps = ({ home, sidebar, current_user }) => ({ home, sidebar, current_user });
const mapDispatchToProps = (dispatch) => ({
    openHelpPanelOpen: (flag) => dispatch(setHelpPanelOpen(flag))
});
export default connect(mapStateToProps, mapDispatchToProps)(HelpItem);
