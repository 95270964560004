import { UPDATE_SHARE_DATA, UPDATE_SHARE_FAILED } from "./types";

const initialState = {
    error: '',
    sharable: false,
    available_items: [],
    available_decision_shared_items: [],
    available_org_shared_items: [],
    values: []
};

export function shareReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case UPDATE_SHARE_DATA: {
            return {
                ...state,
                ...action.payload,
                error: ''
            };
        }
        case UPDATE_SHARE_FAILED: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
}
