import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../helpers/common";
import DemographicFilter from "./filters/DemographicFilter";

export const availableDemographicsState = (criteria) => {
    const [availableDemographics, setAvailableDemographics] = useState([])
    const addAvailableOptions = (options) => {
        if (availableDemographics.some(arr => _.isEqual(arr, options))) {
            return null;
        }
        setAvailableDemographics([...availableDemographics, options])
    }
    const resetAvailableOptions = () => setAvailableDemographics([])
    useEffect(resetAvailableOptions, [criteria])

    return {
        availableDemographics, setAvailableDemographics,
        addAvailableOptions, resetAvailableOptions
    }
}

const DemographicFilters = ({ d_sight_goal, ...opts }) =>
  isBlank(d_sight_goal.demographics) ? null :
    <div className="d-block d-md-flex mb-3">
      {
        d_sight_goal.demographics.map(demographic =>
          <DemographicFilter {...demographic} {...opts} key={`demographic-filter-${demographic.name}`} />
        )
      }
    </div>

export const mapStateToProps = ({ d_sight_goal }) => ({ d_sight_goal });
export default connect(mapStateToProps)(DemographicFilters);
