import React from 'react';
import ChoiceItem, {pickedRankedVotes} from "./ChoiceItem";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import {sortChoicesByCreatedAt, sortRankedVotes} from "../../../models/decision_choices";
import {isBlank, isPresent, qSortArray} from "../../../helpers/common";

const SortableItem = SortableElement(({choice, object, setChoicesVotes, choicesVotes}) => {
  const isSelected = isPresent(choice.vote?.rank)

   return <div className="choice-item">
      <ChoiceItem choice={choice} choicesVotes={choicesVotes} setChoicesVotes={setChoicesVotes}
                  object={object} key={`top-choice-${choice.slug}`} selected={isSelected} />
    </div>
});

const SortableList = SortableContainer(({choicesVotes, setChoicesVotes, object, user}) =>
  <div>
    {choicesVotes.map((choice, index) => (
      <SortableItem key={`item-${choice.slug}`} index={index} choice={choice} setChoicesVotes={setChoicesVotes}
                    object={object} choicesVotes={choicesVotes} user={user} />
    ))}
  </div>
);

const sortedRatedRankedChoices = (choices) => {
  const rankedChoicesVotes = pickedRankedVotes(choices).sort(sortRankedVotes);
  const otherVotes = choices.filter(choice => !choice.vote?.rank);
  const notRankedChoices = qSortArray(otherVotes, true, sortChoicesByCreatedAt);

  const rankedChoicesWithRank = rankedChoicesVotes.map((choiceVote, index) => ({
    ...choiceVote, vote: { ...choiceVote.vote, rank: index + 1 }
  }));

  return [...rankedChoicesWithRank, ...notRankedChoices];
};

const RankedChoices = ({object, choices, setChoicesVotes, user}) => {
  const choicesVotes = sortedRatedRankedChoices(choices, user);

  const buildVotes = (index, newIndex) => ({
    user_email: user.email,
    picked: false,
    rank: index === newIndex ? index + 1 : null
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortedChoicesImmutable = arrayMoveImmutable(choicesVotes, oldIndex, newIndex);
    const sortedChoicesVotes = sortedChoicesImmutable.map((choicesVote, index) => ({
      ...choicesVote,
      vote: isBlank(choicesVote.vote)
        ? buildVotes(index, newIndex)
        : { ...choicesVote.vote, rank: (isPresent(choicesVote.vote?.rank) || newIndex === index) ? index + 1 : null, picked: false }
    }))
    setChoicesVotes(sortedChoicesVotes);
  };

  return <SortableList choicesVotes={choicesVotes} onSortEnd={onSortEnd} helperClass="on-modal" distance={1}
                       setChoicesVotes={setChoicesVotes} object={object} user={user} />;
};

export default RankedChoices;
