import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactHtmlParser from 'react-html-parser';
import CloseIcon from "../../common/CloseIcon";
import { loadContacts } from "../../store/contacts/actions";
import GroupsTypehead from "../../common/GroupsTypehead";
import ContactsTypehead from "../../common/ContactsTypehead";

const EXISTING_EMAIL_ERROR = '<b class="text-ellipsis">item</b><span class="mw-max-content ps-1">was already added.</span>';
const INVALID_EMAIL_ERROR = '<b class="text-ellipsis">item</b><span class="mw-max-content ps-1">is an invalid email address.';

export const InviteUsersModal = ({
                                   shown,
                                   groups, users,
                                   invite_org_users_default_notification_text,
                                   onClose = () => {}, onSubmit = () => {}
                                }) => {
  useEffect(() => {
    setSendNotificationText(invite_org_users_default_notification_text)
  }, [invite_org_users_default_notification_text])

  const [sendNotification, setSendNotification] = useState(true);
  const [sendNotificationText, setSendNotificationText] = useState(invite_org_users_default_notification_text);
  const [orgUsersSelection, setOrgUsersSelection] = useState([])
  const [adminPermission, setAdminPermission] = useState(false)
  const [deciderPermission, setDeciderPermission] = useState(false)
  const [groupsSelection, setGroupsSelection] = useState([])
  const [submit, setSubmit] = useState(false)
  const [errors, setErrors] = useState({})

  const setDefaultValues = useCallback(() => {
    setOrgUsersSelection([])
    setGroupsSelection([])
    setSendNotificationText(invite_org_users_default_notification_text)
    setSendNotification(true)
    setAdminPermission(false)
    setDeciderPermission(false)
    setErrors({})
  }, [invite_org_users_default_notification_text])

  const submitAddUsersModal = () => {
    const permissions = { decider: deciderPermission, admin: adminPermission }
    const notifyData = { notify: sendNotification, notification: sendNotification ? sendNotificationText : null }

    setSubmit(true)
    onSubmit(orgUsersSelection.map(u => u.email), groupsSelection, permissions, notifyData, (success) => {
      setSubmit(false)
      if (success) close();
    })
  }

  const close = () => {
    setDefaultValues();
    onClose()
  }

  const buildEmailErrorMessages = () => {
    if(isBlank(errors['invalid_emails']) && isBlank(errors['existing_emails'])) return [];

    const messages = [];
    errors['invalid_emails'].forEach(item => messages.push(INVALID_EMAIL_ERROR.replace(/item/g, item)));
    errors['existing_emails'].forEach(item => messages.push(EXISTING_EMAIL_ERROR.replace(/item/g, item)));
    return messages
  };

  return <Modal size="lg" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <CloseIcon onClose={close} />
      <h2>Add users</h2>
      <div className="mb-3 typehead-hide-match">
        <h3>Email</h3>
        <ContactsTypehead
          autoFocus={shown} disabled={submit}
          placeholder={"Separate email addresses with a comma"}
          values={orgUsersSelection} excludeValues={users}
          setValues={setOrgUsersSelection}
          errors={errors}
          setErrors={setErrors}
        />
        <div className={`fw-light d-block mt-1 ${ errors['invalid_emails'] || errors['existing_emails'] ? 'text-danger' : 'hide-text' }`}>
          {buildEmailErrorMessages().map((item, index) =>
            (<div key={`error-message-item-${index}`} className="d-flex">
              { ReactHtmlParser (item) }
            </div>)
          )}
        </div>
      </div>
      <div className="mb-3 lh-checkbox">
        <input type="checkbox"  id="admin-permissions-checkbox" name="admin-permissions-checkbox"
               className="custom-checkbox"
               checked={adminPermission}
               onChange={() => { setAdminPermission(!adminPermission) }}/>
        <label htmlFor="admin-permissions-checkbox" className="mb-0">Make admin</label>
      </div>
      {/*<div className="mb-3 lh-checkbox">*/}
      {/*  <input type="checkbox"  id="admin-permissions-checkbox" name="admin-permissions-checkbox"*/}
      {/*         className="custom-checkbox"*/}
      {/*         checked={deciderPermission}*/}
      {/*         onChange={() => { setDeciderPermission(!deciderPermission) }}/>*/}
      {/*  <label htmlFor="share-notify-checkbox" className="mb-0">Make decider</label>*/}
      {/*</div>*/}

      <div hidden={isBlank(groups)}>
        <h3>Add to groups</h3>
        <div className="mb-3 checkbox-line">
          <GroupsTypehead
            placeholder="Enter a group" disabled={submit}
            values={groupsSelection} setValues={setGroupsSelection}
          />
        </div>
      </div>

      <div className="mb-3 lh-checkbox">
        <input type="checkbox"  id="notify-checkbox" name="notify-checkbox"
               className="custom-checkbox"
               checked={sendNotification}
               onChange={() => { setSendNotification(!sendNotification) }}/>
        <label htmlFor="notify-checkbox" className="mb-0">Send a notification</label>
      </div>
      <div className="mb-3" hidden={!sendNotification}>
        <h3>Notification message</h3>
        <textarea className="form-control h-auto" rows="5" placeholder="Add a personal note to the notification" value={sendNotificationText}
                  onChange={(e) => setSendNotificationText(e.target.value)} disabled={submit}/>
      </div>
      <Button onClick={submitAddUsersModal} className="w-100 mt-3" disabled={isBlank(orgUsersSelection) || submit}>Add users</Button>
    </Modal.Body>
  </Modal>
}
const mapStateToProps = ({ home }) => ({
  groups: home.groups.data,
  users: home.users.data,
  invite_org_users_default_notification_text: home.invite_org_users_default_notification_text
});
const mapDispatchToProps = (dispatch) => ({
  loadContacts: () => {
    dispatch(loadContacts());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(InviteUsersModal);
