import React, { useMemo, useCallback } from 'react';
import ContextAwareToggle from "./ContextAwareToggle";
import { Card, Accordion } from 'react-bootstrap'
import {isBlank, isPresent} from "../../../helpers/common";
import ContactsTypehead from "../../../common/ContactsTypehead";
import SearchIcon from "./SearchIcon";
import { setFilterAutofocus } from "../../../helpers/filter_helpers";

const OWNER = 'Manager';

export default ({
                  openedSection, setOpenedSection,
                  orgUsersSelection, setOrgUsersSelection,
                  submit, nonUsers
                }) => {
  const setAutoFocus = useCallback(() => setFilterAutofocus(setOpenedSection, OWNER), [])

  const filterTextArray = orgUsersSelection.map((u) => u.full_name)

  return <Card className="shadow">
    <Card.Header className="bg-white border-0 pointer p-0" onClick={setAutoFocus}>
      <ContextAwareToggle eventKey="1" {...{ filterTextArray }}>
        {OWNER}
      </ContextAwareToggle>
    </Card.Header>
    <Accordion.Collapse eventKey="1" className={`${isPresent(filterTextArray) ? 'show' : ''}`}>
      <Card.Body className="pt-0 px-3 pb-3">
        <SearchIcon />
        <ContactsTypehead
          {...{
            disabled: submit,
            values: orgUsersSelection, setValues: setOrgUsersSelection,
            excludeValues: nonUsers,
            autoFocus: openedSection === OWNER,
            minLength: 0,
            flip: false
          }}
        />
      </Card.Body>
    </Accordion.Collapse>
  </Card>
}
