import React, {Fragment, useState} from 'react';
import { connect } from "react-redux";
import {isBlank, isPresent} from "../../../helpers/common";
import {
  attachReport, attachScenario,
  detachReport, detachScenario
} from "../../../store/tree/actions";
import { addSourceToDecision } from "../../../store/decision_set/actions";
import {hasParticipantAdmission, isDeciderReadOnly, isVisitor} from "../../../helpers/user_helpers";
import NAElement from "./NAElement";
import {decisionDataSources} from "../../../models/decision";
import DataSource, {
  DecisionOrDSightDataSourcesTypes, DecisionOrTemplateOrDSightDataSourcesTypes,
  LinkedReportTemplateDataSourcesType,
  DSightSourcesTypes,
  DecisionSetSourcesTypes
} from "../../../models/data_source";
import { DataSourcesPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";
import {
  AddSourceRows,
  DataSourceRow,
  UploadingSourceRow,
} from "../../../template_view/side_panel/sections/DataSourcesSection";
import { generateBatchDataSourceUploadState } from "../../../helpers/uploads_callbacks";
import {
  createSource,
  destroySource,
  replaceSource,
  saveUploadingSources,
  updateSource
} from "../../../store/sidebar/actions";
import AccordionWrapper from "../../../common/AccordionWrapper";
import AddReportSelector from "./data_sources/AddReportSelector"
import AddForecastScenarioSelector from "./data_sources/AddForecastScenarioSelector"
import SelectExistingSourceRow from "./data_sources/SelectExistingSourceRow"
import { sourcesData } from "./data_sources/helpers"

const HeaderElement = () => <h3 className="mb-0">Data sources</h3>

const LocalAddSourceRowsRecommendation = ({ user, fromRecommendationPanel, ...opts }) => {
  if(isVisitor(user) || hasParticipantAdmission(user) || isDeciderReadOnly(user)) return null;

  return <AddSourceRows {...opts} fromRecommendationPanel={fromRecommendationPanel} isTemplate={false} />
};

const LocalAddSourceRowsDecision = ({ user, fromDecisionPanel, ...opts }) => {
  if(isVisitor(user) || hasParticipantAdmission(user) || isDeciderReadOnly(user)) return null;

  return <AddSourceRows {...opts} fromDecisionPanel={fromDecisionPanel} isTemplate={false} />
};

const DataSourcesList = ({
                           data_sources, user, fromDecisionPanel,
                           isWizard = false,
                           ...opts
                         }) => {
  return data_sources.map(data_source =>
    <DataSourceRow data_source={data_source}
                   isWizard={isWizard}
                   isTemplate={false}
                   fromDecisionPanel={fromDecisionPanel}
                   hideMenu={isVisitor(user) || hasParticipantAdmission(user) || isDeciderReadOnly(user) || new DataSource(data_source).isDSight}
                   key={`decision-details-data-source-${data_source.slug}-${data_source.key}`}
                   {...opts}
    />
  )
};

const UploadingSourcesList = ({
                                uploading_sources, user,
                                ...opts
                              }) => {
  return uploading_sources.map((sourceData, i) =>
    <UploadingSourceRow uploading_source={sourceData.uploadingSource}
                        isTemplate={false}
                        key={`decision-details-uploading-source-${sourceData.uploadingSource.slug}-${i}`}
                        {...opts}
    />
  )
};

const DataSourcesSection = ({
                              current_org,
                              decision, playbook_notes, data_sources, user, fromRecommendationPanel = false,
                              createSource, uploading_sources, fromDecisionPanel = false, decision_sources,
                              decision_set_sources,
                              saveUploadingSources, addClass, isTemplate = false, recommendation_sources,
                              isWizard, updateSource, driver = { slug: null }, attachReport, attachScenario,
                              orgReportsForDecisionPanel, orgReportsForRecommendationPanel, orgReportsForDetailsPanel,
                              orgForecastScenariosForDetailsPanel, orgForecastScenariosDecisionPanel, orgForecastScenariosRecommendationPanel,
                              addSourceToDecision, isDecision = false,
                              ...opts
                            }) => {
  if ((isDeciderReadOnly(user) || isVisitor(user) || hasParticipantAdmission(user)) && isBlank(data_sources))
    return <div className={`${addClass}`}>
      <NAElement />
    </div>

  const submitLink = (value, callback) => { createSource({ link_url: value }, {}, callback, isWizard); };
  const submitLinkInDecisionPanel = (value, callback) => { createSource({ link_url: value, hide_in_decision_panel: false }, {}, callback, isWizard); };
  const submitLinkInRecommendationPanel = (value, callback) => { createSource({ link_url: value, hide_in_recommendation_panel: false }, {}, callback, isWizard); };
  const [selectedSource, setSelectedSource] = useState('');
  const uploadOptions = generateBatchDataSourceUploadState(data_sources, uploading_sources, saveUploadingSources, user)

  const onSelectDecisionSource = (option) => {
    setSelectedSource(option.value);
    updateSource(option.value, { hide_in_decision_panel: false, driver_slug: driver.slug }, (isTemplate || isWizard));
  };
  const onSelectSetSource = (option) => {
    setSelectedSource(option.value);

    const attributes = { data_source: { decision_slug: decision.slug } }
    if(fromDecisionPanel) attributes.data_source.hide_in_decision_panel = false;
    if(fromRecommendationPanel) attributes.data_source.hide_in_recommendation_panel = false;
    addSourceToDecision(option.value, decision.decision_set_slug, attributes, (isTemplate || isWizard));
  };
  const onSelectReport = (option) => {
    setSelectedSource(option.value);
    attachReport(option.value, {}, (isTemplate || isWizard));
  };
  const onSelectScenario = (option) => {
    setSelectedSource(option.value);
    attachScenario(option.value, {}, (isTemplate || isWizard));
  };
  const onSelectRecommendationReport = (option) => {
    setSelectedSource(option.value);
    attachReport(option.value, { hide_in_recommendation_panel: false }, (isTemplate || isWizard));
  };
  const onSelectRecommendationScenario = (option) => {
    setSelectedSource(option.value);
    attachScenario(option.value, { hide_in_recommendation_panel: false }, (isTemplate || isWizard));
  };
  const onSelectDecisionReport = (option) => {
    setSelectedSource(option.value);
    attachReport(option.value, { hide_in_decision_panel: false }, (isTemplate || isWizard));
  };
  const onSelectDecisionScenario = (option) => {
    setSelectedSource(option.value);
    attachScenario(option.value, { hide_in_decision_panel: false }, (isTemplate || isWizard));
  };
  const onSelectRecommendationSource = (option) => {
    setSelectedSource(option.value);
    updateSource(option.value, { hide_in_recommendation_panel: false, driver_slug: driver.slug }, (isTemplate || isWizard));
  };

  const recommendationAccordion = {
    header: <HeaderElement />,
    body: <Fragment>
      <div className="d-flex flex-column" hidden={isBlank(data_sources)}>
        <DataSourcesList data_sources={data_sources}
                         user={user}
                         isWizard={isWizard}
                         fromRecommendationPanel={fromRecommendationPanel}
                         updateSource={updateSource}
                         {...uploadOptions}
                         {...opts} />
        <UploadingSourcesList uploading_sources={uploading_sources} user={user} {...uploadOptions} {...opts} />
      </div>
      <LocalAddSourceRowsRecommendation user={user}
                                        submitLink={fromRecommendationPanel ? submitLinkInRecommendationPanel : submitLink}
                                        isWizard={isWizard}
                                        fromRecommendationPanel={fromRecommendationPanel}
                                        createSource={createSource}
                                        {...uploadOptions} />
      <div className="mt-2" hidden={isBlank(recommendation_sources) && isBlank(decision_set_sources) && isBlank(orgReportsForRecommendationPanel) && isBlank(orgForecastScenariosRecommendationPanel)}>
        <div className="new-source-entries">
          <SelectExistingSourceRow selectedSource={selectedSource}
                                   onSelectSource={onSelectRecommendationSource}
                                   onSelectReport={onSelectRecommendationReport}
                                   onSelectScenario={onSelectRecommendationScenario}
                                   onSelectSetSource={onSelectSetSource}
                                   dataSources={recommendation_sources}
                                   decisionSetSources={decision_set_sources}
                                   orgReports={orgReportsForRecommendationPanel}
                                   orgForecastScenarios={orgForecastScenariosForDetailsPanel}
          />
        </div>
      </div>
    </Fragment>,
    defaultExpand: data_sources.some((source) => !source.hide_in_recommendation_panel)
  };

  if(fromRecommendationPanel) {
    return <AccordionWrapper accordionObject={recommendationAccordion} className={`${addClass}`} />
  }

  const decisionAccordion = {
    header: <HeaderElement />,
    body: <Fragment>
      <DataSourcesPBSection playbook_notes={playbook_notes}
                            sidePanelStyle={true}
                            showNA={!data_sources?.length}
                            hidden={fromDecisionPanel}
                            isDecision={isDecision}
                            {...opts}/>
      <div className={`d-flex flex-column ${isDecision ? '' : 'mt-2'}`} hidden={isBlank(data_sources)}>
        <DataSourcesList data_sources={data_sources}
                         user={user}
                         isWizard={isWizard}
                         fromDecisionPanel={fromDecisionPanel}
                         updateSource={updateSource}
                         {...uploadOptions}
                         {...opts} />
        <UploadingSourcesList uploading_sources={uploading_sources} user={user} {...uploadOptions} {...opts} />
      </div>
      <LocalAddSourceRowsDecision user={user}
                                  submitLink={fromDecisionPanel ? submitLinkInDecisionPanel : submitLink}
                                  isWizard={isWizard}
                                  fromDecisionPanel={fromDecisionPanel}
                                  createSource={createSource}
                                  showExtraMargin={isBlank(data_sources)}
                                  {...uploadOptions}
                                  {...opts} />
      {
        (isPresent(decision.decision_set_slug) && isPresent(decision_set_sources)) || fromDecisionPanel ?
          <div className="mt-2" hidden={isDeciderReadOnly(user) || isVisitor(user) || isBlank(decision_sources) && isBlank(decision_set_sources) && isBlank(orgReportsForDecisionPanel)}>
            <div className="new-source-entries">
              <SelectExistingSourceRow selectedSource={selectedSource}
                                       onSelectSource={onSelectDecisionSource}
                                       onSelectReport={onSelectDecisionReport}
                                       onSelectScenario={onSelectDecisionScenario}
                                       onSelectSetSource={onSelectSetSource}
                                       dataSources={decision_sources}
                                       decisionSetSources={decision_set_sources}
                                       orgReports={orgReportsForDecisionPanel}
                                       orgForecastScenarios={orgForecastScenariosForDetailsPanel}

              />
            </div>
          </div> :
          <>
            {
              current_org.show_report_insights &&
              <AddReportSelector user={user}
                                 fromDecisionPanel={fromDecisionPanel}
                                 fromRecommendationPanel={fromRecommendationPanel}
                                 orgReportsForDetailsPanel={orgReportsForDetailsPanel}
                                 selectedSource={selectedSource}
                                 onSelectReport={onSelectReport} />
            }
            {
              current_org.show_forecast_scenarios &&
              <AddForecastScenarioSelector hidden={isVisitor(user) || hasParticipantAdmission(user) || isDeciderReadOnly(user)}
                                           orgForecastScenarios={orgForecastScenariosForDetailsPanel}
                                           selectedSource={selectedSource}
                                           onSelectScenario={onSelectScenario} />
            }
          </>
      }
    </Fragment>,
    defaultExpand: fromDecisionPanel ? data_sources.some((source) => !source.hide_in_decision_panel) : isPresent(data_sources)
  };

  if (isDecision) {
    return <div className={`${addClass}`}>
      {decisionAccordion.body}
    </div>
  }

  return  <AccordionWrapper accordionObject={decisionAccordion} className={`${addClass}`} />
};

export const dataSourcesMapStateToProps = ({tree, current_org, current_user, decision_set, isWizard = false}) => {
  const sourcesScopes = isWizard ?
    DecisionOrTemplateOrDSightDataSourcesTypes :
    DecisionOrDSightDataSourcesTypes
  const available_tree_data_sources = isBlank(tree.data_sources) ? [] : tree.data_sources.filter((source) => !LinkedReportTemplateDataSourcesType.includes(source.type))
  const is_user_in_decision_set = decision_set?.users.some(user => user.email === current_user.email)
  const available_set_sources = is_user_in_decision_set ? available_tree_data_sources.filter((source) => DecisionSetSourcesTypes.includes(source.type)) : []
  const sortedRelatedDataSources = decisionDataSources(available_tree_data_sources || [], tree.drivers, sourcesScopes)
  const finalRelatedDataSources = sortedRelatedDataSources.filter((source) => !DSightSourcesTypes.includes(source.type))
  return {
    data_sources: sortedRelatedDataSources,
    decision_set_sources: available_set_sources,
    ...sourcesData(current_org, finalRelatedDataSources)
  }
}
const mapStateToProps = ({ decision, current_org, playbook_notes, tree, decision_set, current_user, isWizard={isWizard} }) => {
  return {
    ...dataSourcesMapStateToProps({tree, current_org, current_user, decision_set, isWizard}),
    current_org: current_org,
    uploading_sources: tree.uploading_sources,
    user: current_user,
    decision, playbook_notes
  }
};
export default connect(mapStateToProps, {
  createSource, updateSource, destroySource, saveUploadingSources, replaceSource,
  attachReport, detachReport,
  attachScenario, detachScenario,
  addSourceToDecision
})(DataSourcesSection);
