import React, { useState } from 'react';
import { connect } from "react-redux";
import * as moment from "moment";
import DecisionDate from '../../../common/DecisionDate';
import { isPresent } from "../../../helpers/common";
import { updateRecordDecision } from '../../../store/decision/actions';
import {isDeciderReadOnly, isVisitor} from "../../../helpers/user_helpers";
import Decision from "../../../models/decision";

export const DecisionDueDateSection = ({ decision, current_user, updateRecordDecision }) => {
  const initDate = isPresent(decision.due_date) ? moment(decision.due_date) : null;
  const defaultDate = moment().add(1, 'weeks');
  const [dueDate, setDueDate] = useState(initDate || defaultDate);
  const [submitState, setSubmitState] = useState(false)

  const decisionObject = new Decision(decision);

  if(isVisitor(current_user) || isDeciderReadOnly(current_user) || decisionObject.isRecordedOrShowAsCompleted || (decisionObject.isEnteredRecommendation && decisionObject.isDecisionWithResults)) return <></>;

  const onChangeDate = (date) => {
    setSubmitState(true);
    updateRecordDecision({ due_date: moment(date).toString() }, (success) => {
      if(success) {
        setDueDate(moment(date));
      }
      setSubmitState(false);
    })
  };

  return <div className="px-3 pb-3">
    <DecisionDate date={dueDate} onChangeDate={onChangeDate} readOnly={submitState} minDate={new Date()} />
  </div>
}
const mapStateToProps = ({ decision, current_user }) => ({
  decision, current_user
});
const mapDispatchToProps = (dispatch) => ({
  updateRecordDecision: (data, callback) => {
    dispatch(updateRecordDecision(data, callback))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionDueDateSection);
