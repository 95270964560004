import { INSIGHT_MESSAGE_TYPE } from "./insight";
import { InsightsData } from "./InsightsData";
import { qSortArray } from "../../helpers/common";

export const SEMAPHORE_SORT_ORDER = {
  g_null: 888,
  grey: 888,
  none: 999,
  red: 1,
  g_red: 1,
  yellow: 2,
  g_yellow: 2,
  green: 3,
  g_green: 3
}

const sortLeverValue = (record) => record.index

export class Goal extends InsightsData {
  constructor(data, templates = []) {
    super(data);
    this.templates = templates;
  }

  get levers() {
    const allLevers = (this.data.levers?.map((l, index) => new Lever(l, index)) || [])
    const levers = allLevers.filter(l => this.templates.some(t => t.slug === l.templateSlug))
    return qSortArray(levers, true, sortLeverValue);
  }

  get insights() {
    return this.allInsights.filter(i => i.message_type !== INSIGHT_MESSAGE_TYPE.question)
  }

  get allInsights() {
    return this.data.insights || []
  }

  get reportUrl() {
    return this.data.report_url || ''
  }
}

export class Lever extends Goal {
  constructor(data, index = 0) {
    super(data);
    this.index = index;
  }
  get name() {
    return this.data.display_name;
  }

  get templateSlug() {
    return this.data.template_slug;
  }
}
