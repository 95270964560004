import React from 'react';
import { connect } from "react-redux";
import {
  destroyRecommendationChoice,
  updateRecommendationChoice,
  updateRecommendationFinalChoices
} from "../../../../store/sidebar/actions";
import { ChoiceItem } from "../../decision_input/choices/ChoiceItem";
import Decision from "../../../../models/decision";
import Recommendation from "../../../../models/recommendation";

export const sortedRatedRecommendationChoices = (decision, drivers) => {
  const decisionObj = new Decision(decision, drivers)
  const recommendationObj = new Recommendation(decision.recommendation);
  const isSortedByRating = decisionObj.isTreeRecommendation && !decisionObj.isRateAndCompareDriverChoicesHidden
  return isSortedByRating ?
    recommendationObj.sortedRatedChoices(decisionObj.keyDrivers) :
    recommendationObj.sortedChoices(true)
}

const mapDispatchToProps = (dispatch) => ({
  destroyChoice: (slug, data) => { dispatch(destroyRecommendationChoice(slug, data)) },
  updateChoice: (slug, data) => { dispatch(updateRecommendationChoice(slug, data)) },
  updateFinalChoices: (slug, data, callback = () => {}) => { dispatch(updateRecommendationFinalChoices(slug, data, callback)) }
})
export default connect(null, mapDispatchToProps)(ChoiceItem);
