import {isBlank, isPresent} from "../helpers/common";
import {COLLABORATOR_TYPE} from "./template";

export default class TemplateSet {
  constructor(template_set) {
    this.template_set = template_set;
  }

  get templates() {
    return this.template_set.templates;
  }

  get sections() {
    return this.template_set.sections;
  }

  templatesInSection(section) {
    return this.templates.filter(d => d.section_slug === section.slug);
  }

  get templatesWithoutSection() {
    return this.templates.filter(d => isBlank(d.section_slug));
  }

  get collaborator_admissions() {
    return this.template_set.admissions.filter(a => a.share_type === COLLABORATOR_TYPE).map(a => this._withUserData(a))
  }

  _withUserData(admission) {
    if (admission.share_type !== COLLABORATOR_TYPE) return admission;
    const userData = this.template_set.users.find(u => u.email === admission.user_email)
    if (isPresent(userData)) admission.user = userData
    return admission;
  }
}
