import { deleteTemplateSetWarning, deleteWarning } from "../helpers/alert_helpers";
import { redirectToHomeCallback } from "../helpers/decision_helpers";
import { successActions } from "../helpers/common";

export const deleteSetCallback = (removeSet, actions, { history, homeTab }) => () => {
  deleteWarning(confirmed => {
    if (!confirmed) return;

    removeSet((success) => {
      redirectToHomeCallback(success, history, homeTab);
      successActions(success, actions)
    })
  }, { custom_description: `ungroup this decision flow` })
}

export const deleteSetTemplateCallback = (removeSet, actions, { history, homeTab }, deleteAllTemplateSet) => () => {
  deleteTemplateSetWarning(action => {
    switch (action) {
      case 'cancel':
        return;
      case 'ungroup':
        removeSet((success) => {
          redirectToHomeCallback(success, history, homeTab);
          successActions(success, actions);
        });
        break;
      case 'deleteAll':
        deleteAllTemplateSet((success) => {
          if (success) {
            redirectToHomeCallback(success, history, homeTab);
            successActions(success, actions);
          }
        });
        break;
    }
  });
}
