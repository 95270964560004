import React from 'react';
import { connect } from "react-redux";
import { setMoreActionsSidebarOpen } from "../../store/sidebar/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";

const MoreActionsHamburgerButton = ({ openSideBar }) =>
  <BaseHamburgerBtn onSelect={openSideBar} title="More actions" />

const mapDispatchToProps = (dispatch) => ({
  openSideBar: () => dispatch(setMoreActionsSidebarOpen(true))
});
export default connect(null, mapDispatchToProps)(MoreActionsHamburgerButton);
