import { useState, useEffect } from 'react';
import { sha256 } from 'js-sha256';
import Cookies from 'js-cookie';

export const generateCookieName = (email, section) => {
  const hashedEmail = sha256(email);
  return `${section}-${hashedEmail}`;
}

export const useSectionVisibility = (email, section, defaultVisibility = true) => {
  const [isSectionVisible, setIsSectionVisible] = useState(() => {
    const cookieName = generateCookieName(email, section);
    const visibilityFromCookie = Cookies.get(cookieName);
    return visibilityFromCookie ? visibilityFromCookie === 'true' : defaultVisibility;
  });

  useEffect(() => {
    const cookieName = generateCookieName(email, section);
    const currentVisibility = Cookies.get(cookieName) === 'true';
    if (currentVisibility !== isSectionVisible) {
      Cookies.set(cookieName, isSectionVisible, { expires: 90 });
    }
  }, [isSectionVisible, email, section]);

  return [isSectionVisible, setIsSectionVisible];
}
