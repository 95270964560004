import { StyleSheet } from '@react-pdf/renderer';
import {LIGHT_COLOR, LIGHT_GRAY, PRIMARY_COLOR, SECONDARY_COLOR, WHITE} from "./common";

const styles = StyleSheet.create({
  footer_image: {
    maxWidth: 80,
    maxHeight: 75,
    padding: 1
  },
  cover_footer_image: {
    maxWidth: 110,
    maxHeight: 100
  },
  alignCenter: {
    alignItems: 'center',
  },
  source_image: {
    minWidth: '100%',
  },
  cover_white: {
    backgroundColor: WHITE,
    opacity: 1,
    zIndex: 10
  },
  cover_light: {
    backgroundColor: LIGHT_COLOR
  },
  user_avatar_image: {
    border: 1,
    borderWidth: 1,
    borderTopLeftRadius: '50%',
    borderTopRightRadius: '50%',
    borderBottomLeftRadius: '50%',
    borderBottomRightRadius: '50%',
    width: 24,
    height: 24
  },
  user_avatar_initials: {
    backgroundColor: LIGHT_GRAY,
    borderTopLeftRadius: '50%',
    borderTopRightRadius: '50%',
    borderBottomLeftRadius: '50%',
    borderBottomRightRadius: '50%',
    width: 24,
    height: 24,
    zIndex: 0
  }
});
export default styles
