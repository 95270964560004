import {
  LEARN_LOAD_DATA,
  LEARN_LOAD_DATA_FAILED,
  LEARN_LOAD_DATA_STARTED,
  LEARN_RESET_DATA,
  LEARN_RESET_FILTERS
} from "./types";

const initialState = {
    loading: false,
    loaded: false,
    submit: false,
    current_template: '',
    template_name: '',
    error: '',
    statistics: {},
    filters: {},
    selected_filters: {}
};

export function learnReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case LEARN_LOAD_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case LEARN_RESET_FILTERS: {
            return {
                ...state,
                error: '',
                selected_filters: {},
                loading: false
            };
        }
        case LEARN_LOAD_DATA_STARTED: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: true
            }
        }
        case LEARN_LOAD_DATA_FAILED: {
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        }
        case LEARN_RESET_DATA: {
            return {
                ...state,
                ...initialState,
                loading: false
            }
        }
        default:
            return state;
    }
}
