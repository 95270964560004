import React from 'react';
import { isBlank, qSortArray } from "../../../../../helpers/common";
import { getNodeName } from "../../../../../helpers/metric_helpers";
import { fetchDynamicChainsNodeNames } from "../helpers/table_helpers";
import TableMetric from "../components/TableMetric";

export default ({
                  d_sight_goal, chartData, timeNode, selectedSurvey, metric,
                  header, footer,
                  ...opts
                }) => {
  chartData.dynamic_chains.forEach(chain => {
    if (chain.answer == null) {
      chain.answer = getNodeName(d_sight_goal, chain.process_data[0]?.nodes[0])
    }
  })

  const rows = qSortArray(
    chartData.dynamic_chains.map(({ answer }) => answer),
    true, a => a)

  if (isBlank(rows)) return null;

  const sortedNodeNames = fetchDynamicChainsNodeNames(chartData, d_sight_goal, metric)

  return <div className={`bg-white rounded p-3 mb-3`}>
    {header()}
    <div className={`answers-table-max-h overflow-auto`}>
      <TableMetric {...{
        ...opts,
        d_sight_goal, chartData, timeNode, selectedSurvey,
        rows, sortedNodeNames
      }} />
    </div>
    {footer()}
  </div>
}
