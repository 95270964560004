import {
  DSIGHT_ANALYSIS_LOAD_DATA,
  DSIGHT_ANALYSIS_LOAD_DATA_FAILED,
  DSIGHT_ANALYSIS_LOAD_DATA_STARTED,
  DSIGHT_ANALYSIS_RESET_DATA,
  DSIGHT_ANALYSIS_UPDATE_DATA
} from "./types";
import { loadAnalysisMetricData, loadDSightAnalysisData } from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import EntryPoint from "../../EntryPoint";
import { updateMetricsData } from "../metrics/actions";

export const loadAnalysis = (data = {}, callback = () => {}) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  dispatch(loadAnalysisStarted({}));
  loadDSightAnalysisData({controllerName, objectSlug, data}).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadAnalysisFailure }, callback);

    const { data } = response;
    const { analysis } = data;
    dispatch(updateAnalysisData({ ...analysis, loaded: true }));
    callback(true)
  })
}

export const loadMetric = (slug = {}, callback = () => {}) => (dispatch, getState) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  loadAnalysisMetricData({controllerName, objectSlug, slug}).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadAnalysisFailure }, callback);

    const { data } = response;
    const { chartsData } = data;
    const metric_charts = { ...getState().metrics.metric_charts }
    metric_charts[slug] = chartsData
    dispatch(updateMetricsData({ metric_charts }));
    callback(chartsData)
  })
}

// Helpers
export const resetAnalysis = (data = {}) => ({
  type: DSIGHT_ANALYSIS_RESET_DATA,
  payload: {
    ...data
  }
});
export const loadAnalysisSuccess = (data) => ({
  type: DSIGHT_ANALYSIS_LOAD_DATA,
  payload: {
    ...data
  }
});
export const updateAnalysisData = (data) => ({
  type: DSIGHT_ANALYSIS_UPDATE_DATA,
  payload: {
    ...data
  }
});
export const loadAnalysisStarted = (query = {}) => ({
  type: DSIGHT_ANALYSIS_LOAD_DATA_STARTED,
  payload: {
    ...query
  }
});
export const loadAnalysisFailure = error => ({
  type: DSIGHT_ANALYSIS_LOAD_DATA_FAILED,
  payload: {
    error
  }
});
