import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Help from "../../help";
import { saveReportWizardStepData } from "../../../store/wizard/actions";
import { Button } from 'react-bootstrap'
import {
  WIZARD_STEPS,
  wizardStepDataBy
} from "../../../helpers/wizard_helpers";
import { isBlank, isPresent } from "../../../helpers/common";
import ReportPropertiesForm from "./ReportPropertiesForm";
import CurrencyInput from "../../../common/CurrencyInput";
import { InputCurrencyHeader } from "../../../tree_wizard/steps_wizard/steps/HistoricalDecisionStep";

export const getSavedReportsData = (stepData) => isBlank(stepData['properties']) ? [] : stepData['properties']
export const getSavedOpenPropertiesData = (stepData) => isBlank(stepData['open_properties']) ? [] : stepData['open_properties']
export const getInitialPropertiesData = (org_report_properties, stepData) => isPresent(stepData['selected']) ?
  getSavedReportsData(stepData) : [];

export const getInitialOpenPropertiesData = (org_report_properties, stepData) => isPresent(stepData['selected']) ?
  getSavedOpenPropertiesData(stepData) : []

export const onChangePropertyCallback = (propertiesData, setPropertiesData) => (reportObject, data) => {
  const propertyOptionIds = reportObject.options.map(option => option.id)
  const resetIds = propertiesData.filter(id => !propertyOptionIds.includes(id));
  if (isBlank(data)) {
    setPropertiesData([...resetIds])
  } else if (reportObject.isPickAllThatApply) {
    setPropertiesData([...resetIds, ...data.map(o => o.value)])
  } else {
    setPropertiesData([...resetIds, data.value])
  }
}

export const onChangeOpenPropertyCallback = (openPropertiesData, setOpenPropertiesData) => (reportObject, data) => {
  const propertyOptionIds = reportObject.options.map(option => option.id)
  const resetData = openPropertiesData.filter(data => !propertyOptionIds.includes(data.id));
  if (isBlank(data)) {
    setOpenPropertiesData([...resetData])
  } else {
    setOpenPropertiesData([...resetData, { id: data.value, description: data.description }])
  }
}

export const reportPropertiesStepState = ({ wizard, org_report_properties }) => {
  const stepData = wizardStepDataBy(wizard, WIZARD_STEPS.report_properties.key)
  const [propertiesData, setPropertiesData] = useState(getInitialPropertiesData(org_report_properties, stepData))
  const [openPropertiesData, setOpenPropertiesData] = useState(getInitialOpenPropertiesData(org_report_properties, stepData))

  useEffect(() => {
    if (!org_report_properties.loaded) return;

    setPropertiesData(getInitialPropertiesData(org_report_properties, stepData));
    setOpenPropertiesData(getInitialOpenPropertiesData(org_report_properties, stepData));
  }, [org_report_properties.loaded])
  const onChangeProperty = onChangePropertyCallback(propertiesData, setPropertiesData)
  const onChangeOpenProperty = onChangeOpenPropertyCallback(openPropertiesData, setOpenPropertiesData)

  return {
    stepData,
    propertiesData, setPropertiesData, openPropertiesData,
    onChangeProperty, onChangeOpenProperty
  }
}

export const ReportPropertiesStep = ({
                                       wizard, org_report_properties, current_org,
                                       saveWizardStepData, handleClosePropertiesModal,
                                       stepRef
}) => {
  const {
    stepData, propertiesData, openPropertiesData, onChangeProperty, onChangeOpenProperty
  } = reportPropertiesStepState({ wizard, org_report_properties })
  const [submitState, setSubmitState] = useState(false)
  const [cost, setCost] = useState(stepData['cost'])

  const submitStep = (nav_data = {}, callback = () => {}) => {
    setSubmitState(true)
    saveWizardStepData(WIZARD_STEPS.report_properties.key, {
      ...nav_data,
      properties: propertiesData,
      open_properties: openPropertiesData,
      cost: cost,
      wizard: { step_index: wizard.step_index },
      next_step: true, complete: true
    }, callback)
  }

  return <div>
    <ReportPropertiesForm className={'w-100 mb-3'}
                          onChangeProperty={onChangeProperty}
                          propertiesData={propertiesData}
                          onChangeOpenProperty={onChangeOpenProperty}
                          openPropertiesData={openPropertiesData}
                          submitState={submitState} />
    { current_org.allow_report_cost ?
      <div className="w-100 mb-3">
        <CurrencyInput key={'report-cost-currency-input'}
                       header={<InputCurrencyHeader headerText={'Cost'} className=""/>}
                       value={cost}
                       onChange={(value) => setCost(value || '')}
                       placeholder={'Enter a dollar amount'}
                       name={'report-cost'}
                       id={'report-cost'}/>
      </div> : null
    }
    <div className="w-100">
      <Button onClick={() => {
          submitStep();
          handleClosePropertiesModal();
      }} disabled={wizard.submit || submitState} className="w-100 mt-3">
          Done
      </Button>
    </div>
  </div>
}
const mapStateToProps = ({ wizard, org_report_properties, current_org }) => ({
  wizard, org_report_properties, current_org
});
const mapDispatchToProps = (dispatch) => ({
  saveWizardStepData: (step, data, callback) => {
    dispatch(saveReportWizardStepData(step, data, callback))
  }
});
const wrapper = React.forwardRef((props, ref) => <ReportPropertiesStep {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
