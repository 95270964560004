import React, { useEffect, useState } from 'react';
import { loadConversations } from "../../../../../utils/Api";
import { useParams } from "react-router-dom";
import { isEmpty } from "ramda";
import SavedChat from "./SavedChat";
import { querySavedChats, setSavedChats, useSavedChatsStore } from "../stores/saved-chats.store";

const SavedChats = () => {
  const { goal_id, conversation_id } = useParams();
  const conversations = useSavedChatsStore((state) => state.conversations);

  useEffect(() => {
    querySavedChats(goal_id).then((res) => {
      if(res.status === 200) {
        const { data: { conversations: convers } } = res;
        setSavedChats(convers);
      }
    });
  }, [conversation_id]);

  if(isEmpty(conversations)) return null;

  return <div className="bg-white rounded h-100 p-3 mt-3">
    <h3>Saved chats</h3>
    {
      conversations.map((conversation) => {
        return <SavedChat
          key={conversation.id}
          conversation={conversation}
        />
      })
    }
  </div>
};

export default SavedChats;
