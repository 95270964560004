import React from 'react';
import { isBlank, isEmail, isPresent } from "../../../helpers/common";
import { AssignToFilterStyles } from "../../../tree_wizard/styles/choice_entry_styles";
import Creatable  from 'react-select/creatable';
import Select from "react-select";

const defaultAssignee = (assignedToUser, collaborators) => {
  if(isBlank(assignedToUser)) return null;

  const collaborator = collaborators.find((collaborator) => collaborator.email === assignedToUser);
  const label = isPresent(collaborator) ? (collaborator.full_name || collaborator.label ) : assignedToUser;
  return { value: assignedToUser, label: label }
};

const assignToItems = (assignedToUser, collaborators) => {
  if(isBlank(collaborators)) return [];

  return collaborators.map((collaborator) =>
    ({ value: collaborator.email, label: collaborator.full_name || collaborator.label })
  );
};

export default ({
                  assignedToUser, collaborators,
                  onCreateAssignee, onChangeAssignee, allowNew = true,
                  className = '', title = 'Assign to'
                }) => {
  if (isBlank(collaborators)) return null;

  if(allowNew) {
    return <div className={className}>
      <h3>{title}</h3>
      <Creatable
        isClearable
        isMulti={false}
        onCreateOption={onCreateAssignee}
        isValidNewOption={isEmail}
        value={defaultAssignee(assignedToUser, collaborators)}
        onChange={onChangeAssignee}
        components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
        styles={AssignToFilterStyles}
        placeholder="Enter a name or email address"
        options={assignToItems(assignedToUser, collaborators)}/>
    </div>
  } else {
    return <div className={className}>
      <h3>{title}</h3>
      <Select
        isClearable
        isMulti={false}
        value={defaultAssignee(assignedToUser, collaborators)}
        onChange={onChangeAssignee}
        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        styles={AssignToFilterStyles}
        placeholder="Enter a name or email address"
        options={assignToItems(assignedToUser, collaborators)} />
    </div>
  }
}
