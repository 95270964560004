import {
  Header,
  Paragraph,
  TextRun,
  HeadingLevel, TabStopType, TabStopPosition, Bookmark
} from "docx";
import {borderProps, mutedProps, PTS_13, PTS_4} from "../../styles/docx_styles";
import {internalLink} from "./wrappers";
import {truncateTextWithDots} from "../../helpers/common";

export const pageHeader = ({head = '', description = '', isContinuousSection = false, anchor = "", id = '', skipFirstPage = false}) => {
  const twoTabStops = [{ type: TabStopType.RIGHT, position: TabStopPosition.MAX * 1.155 }];
  const firstPage = () => {
    return new Header({
      children: [
        new Paragraph({
          spacing: { line: 320, before: 0, after: PTS_4 }, // eq 4.5pts,
          heading: HeadingLevel.HEADING_1,
          children: [
            new Bookmark({
              id: skipFirstPage ? '' : id,
              children: [
                new TextRun({text: isContinuousSection ? description : head})
              ],
            })
          ],
        }),
      ]
    })
  }

  const defaultPage = () => {
    return new Header({
      children: [
        new Paragraph({
          ...borderProps,
          spacing: { line: 276, before: 0, after: PTS_13 },
          style: 'normalPara',
          tabStops: twoTabStops,
          children: [
            internalLink({text: head, anchor: anchor}),
            new TextRun({
              ...mutedProps,
              text: `\t${truncateTextWithDots(description)}`,
            }),
          ],
        }),
      ],
    })
  }

  return {
    headers: {
      default: defaultPage(),
      first: skipFirstPage ? defaultPage() : firstPage()
    }
  }
}
