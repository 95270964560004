import React from "react";
import PollDueBy from "./PollDueBy";
import Decision from "../../../models/decision";
import {pluralize} from "../../../helpers/common";

const responsesCount = (usersChoicesVotes) => {
  const votedUserEmails = new Set(
    usersChoicesVotes.flatMap(choice =>
      choice.votes.filter(vote => vote.voted).map(vote => vote.user_email)
    )
  );
  const responsesCount = votedUserEmails.size;
  return pluralize(responsesCount, 'response', 'responses', 'No');
};

export default ({ decision, usersChoicesVotes }) => {
  const decisionObj = new Decision(decision)
  const isShowDueDate = !decisionObj.isRecordedOrRecommended

  return <div className="d-flex">
    {isShowDueDate && <PollDueBy {... {decision}}/>}
    <span className='text-muted'>
      {isShowDueDate && <span>&nbsp;· </span>}
      {responsesCount(usersChoicesVotes)}
    </span>
  </div>
}
