import React from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import { openSummaryDetailsModal } from "../../decision_set_view/modals/SummaryDetailsModal";
import { openModal } from "../../store/modals/actions";

const SeeSummaryButton =({ openModal }) =>
  <Button onClick={() => openSummaryDetailsModal({ openModal })} className="btn btn-secondary h-48 w-100">
    See decision flow summary
  </Button>

export default connect(null, { openModal })(SeeSummaryButton);
