import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import CategoriesForm from "../../decision_categories/CategoriesForm";
import { saveTemplateWizardStepData } from "../../store/wizard/actions";
import { stepKeyWithCategories, wizardStepDataBy } from "../../helpers/wizard_helpers";
import { categorizationStepState } from "../../tree_wizard/steps_wizard/steps/TreeDecisionCategorizationStep";
import { ModalBodyWithClose, ModalDoneFooterP0 } from "../../common/modals";
import SelectCategory from "../../tree_wizard/helpers/SelectCategory";
import { categorizationNotesState } from "../../common/CategorizationNotesRow";
import { fetchDefaultSortedSelectedCategories } from "../../helpers/categories_helpers";

export const TemplateCategoriesModal = ({
                                          wizard, playbook_notes, org_categories, shown,
                                          onClose = () => {},
                                          saveTemplateWizardStepData
                                        }) => {
  const stepData = wizardStepDataBy(wizard, stepKeyWithCategories(wizard)) || {}
  const {
    setCategoriesData,
    selectedCategories, setSelectedCategories,
    onSelectCategory, submitCategoriesData,
    ...hash
  } = categorizationStepState({ wizard, org_categories })

  const [submitState, setSubmitState] = useState(false)
  const {
    categorizationNotes,
    categorizationNotesRow
  } = categorizationNotesState(playbook_notes)

  useEffect(() => { if (shown) resetData() }, [shown])

  const resetData = () => {
    setCategoriesData(stepData['categories'])
    setSelectedCategories(
      fetchDefaultSortedSelectedCategories(org_categories, stepData['selected_categories'])
    )
  }

  const reset = () => {
    resetData()
    close()
  }
  const close = () => onClose()
  const submitData = () => {
    setSubmitState(true)
    saveTemplateWizardStepData(stepKeyWithCategories(wizard), {
      ...submitCategoriesData(),
      categorization: categorizationNotes,
      next_step: true,
      finish_later: true
    }, success => {
      setSubmitState(false)
      if (success) close();
    })
  }

  return <Modal size="md" backdrop="static" show={shown} onHide={reset}>
    <ModalBodyWithClose bsPrefix="categories-modal" header="Categories" onClose={reset}>
      <CategoriesForm className={'w-100 mb-3'}
                      {...{
                        ...hash,
                        submitState, selectedCategories,
                        requireInput: true,
                        categorizationNotesRow: <>
                          <SelectCategory selected={selectedCategories}
                                          onSelect={onSelectCategory}
                                          submitState={submitState} />
                          {categorizationNotesRow}
                        </>
                      }}
      />
    </ModalBodyWithClose>
    <ModalDoneFooterP0 disabled={submitState} onClose={submitData} />
  </Modal>
}
const mapStateToProps = ({ wizard, template, playbook_notes, org_categories }) => ({ wizard, template, playbook_notes, org_categories });
export default connect(mapStateToProps, { saveTemplateWizardStepData })(TemplateCategoriesModal);
