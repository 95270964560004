import {
  BENCHMARK_KEY, BM_VS_YA_KEY,
  DEFAULT_SIM_VS_VALUE, FACT_COMPARISON_HEADERS, FACTS_HEADER, ROW_DRIVER_ID_KEY,
  SALES_FACTS,
  SCENARIO_ROW_ID_SEPARATOR,
  SIM_VS_BENCHMARK_KEY,
  SIM_VS_YA_KEY, SUB_FACT_HEADER
} from "./ag_grid_vars";
import { isBlank, isPresent, uniqArray, uniqueBy } from "../../helpers/common";
import {
  calcRootBySimVsRootValue,
  calcRootFromSimVs,
  calcSimVsValue,
  calcYARootFromPrevSimVsYa
} from "./ag_grid_formulas";
import { hideOverlay } from "./custom_loading_overlay";

const simAggValueKeyByPeriodAndSuffix = (params, periodId, periodAgGridSuffixes) => {
  return Object.keys(params.node.aggData).find(key =>
    periodAgGridSuffixes.some(suffix => key === `${periodId}_${suffix}`)
  );
}

export const simAggValue = (params, periodId, periodAgGridSuffixes = []) => {
  if(isPresent(periodAgGridSuffixes)) {
    const foundKey = simAggValueKeyByPeriodAndSuffix(params, periodId, periodAgGridSuffixes);
    if (isPresent(foundKey)) return params.node.aggData[foundKey].sum || params.node.aggData[foundKey][0];
  }

  if (isPresent(params.node.aggData[periodId]))
    return params.node.aggData[periodId].sum || params.node.aggData[periodId][0];

  return DEFAULT_SIM_VS_VALUE;
}

export const getPeriodAgGridSuffixes = (params) => {
  if(isBlank(params.node.aggData)) return [];

  const keys = Object.keys({ ...params.node.aggData });
  return uniqArray(keys.map(key => key.split('_')[1]).filter(isPresent));
}

export const findDriverColumn = (rowDriverId, forecastScenario, config = null) => {
  const { driverId } = parseRowCellId(rowDriverId)
  return (config || forecastScenario.config).allFactsColumns.find(column => column.id === Number(driverId));
}

export const parseRowCellId = (rowCellId) => {
  const [comparisonKey, cmus, driverId, periodId] = rowCellId.split(SCENARIO_ROW_ID_SEPARATOR);
  return { comparisonKey, cmus, driverId, periodId };
}

export const rowCellIdKey = (params) => `${params.node.id}${SCENARIO_ROW_ID_SEPARATOR}${params.colDef.colId}`;

export const extractDriverName = (driverWithMeasure = '') => {
  driverWithMeasure = driverWithMeasure || '';
  return driverWithMeasure.split(',')[0];
}

export const buildNewEditedCells = (editedCells, list, timeScale, additionalCellAttrs = {}) => {
  return list.map(params => {
    const cellData = {
      ...findOrBuildCell(editedCells, params, timeScale),
      value: isPresent(params.newValue) ? params.newValue : params.node.value,
      prev_value: isPresent(params.node.prevValue) ? params.node.prevValue : params.oldValue,
    }
    return Object.assign({}, cellData, additionalCellAttrs)
  })
}

export const findOrBuildCell = (editedCells, params, timeScale) => {
  const cellId = rowCellIdKey(params);
  return editedCells.find(cellData => cellData.id === cellId && timeScale === cellData.timeScale) ||
    {
      id: cellId,
      timeScale: timeScale,
      nodeId: params.node.id,
      periodId: params.colDef.colId,
      field: params.colDef.field,
      default_value: isPresent(params.node.prevValue) ? params.node.prevValue : params.oldValue
    };
}

export const updateCells = (gridRef, editedCells, forecastScenario, runModelCells, list, timeScale, updateScenario, callback) => {
  const newEditedCells = buildNewEditedCells(editedCells, list, timeScale, { need_recalculation: true });
  const allEditedCells = uniqueBy([...newEditedCells, ...editedCells], 'id');
  const calcRunModelCells = calcRunModelCellsFunc(gridRef, newEditedCells, runModelCells, timeScale)

  updateScenario(forecastScenario.local_id, {
    update_data: { edited_cells: allEditedCells, run_model_cells: calcRunModelCells },
  }, (status, errors) => {
    if(status) callback();
    hideOverlay(gridRef.current?.api);
  })
}

export const runModelCellsByRowId = (runModelCells, rowId) =>
  runModelCells.filter(cellData => cellData.nodeId === rowId);

const addToList = (list, rowNode, editedCell, value) => {
  list.push({
    node: { id: rowNode.id, prevValue: value },
    colDef: { field: editedCell.field, colId: editedCell.periodId },
    newValue: value
  });
}

const handleLeafChildren = (localRowNode, rowNode, editedCells, salesNode, list, periodAgGridSuffix) => {
  localRowNode.allLeafChildren.forEach(child => {
    const cells = editedCells.filter(editedCell => editedCell.nodeId === child.id);
    cells.forEach(editedCell => {
      const value = simAggValue({ node: localRowNode }, editedCell.periodId, periodAgGridSuffix)
      addToList(list, localRowNode, editedCell, value);
      if(salesNode) {
        const value = simAggValue({ node: rowNode }, editedCell.periodId, periodAgGridSuffix)
        addToList(list, rowNode, editedCell, value);
      }
    })
  })
}

const filterRunModelCells = (runModelCells, newCells) => {
  return runModelCells.filter(cellData => !newCells.some(newCell => newCell.id === cellData.id));
}

export const calcRunModelCellsFunc = (gridRef, editedCells, runModelCells, timeScale, action) => {
  if(editedCells.length === 0) return [];

  let list = [];
  gridRef.current.api.forEachNode((rowNode) => {
    const salesNode = SALES_FACTS.includes(extractDriverName(rowNode.key));
    const localRowNode = salesNode ? rowNode.parent : rowNode;
    const periodAgGridSuffixes = getPeriodAgGridSuffixes({ node: localRowNode });
    if (!localRowNode.leafGroup && localRowNode.allLeafChildren) {
      handleLeafChildren(localRowNode, rowNode, editedCells, salesNode, list, periodAgGridSuffixes);
    }
  })
  const newCells = buildNewEditedCells(runModelCells, list, timeScale, { request_run_model_at: new Date().toISOString() });
  if(action === 'reset') {
    return uniqueBy([...filterRunModelCells(runModelCells, newCells)], 'id');
  } else {
    return uniqueBy([...filterRunModelCells(runModelCells, newCells), ...newCells], 'id');
  }
}

export const performUpdateCells = (gridRef, groupedDelayedUpdateData) => {
  if(isBlank(groupedDelayedUpdateData)) return;

  Object.entries(groupedDelayedUpdateData).forEach(([nodeId, data]) => {
    const rowNode = gridRef.current?.api?.getRowNode(nodeId);
    gridRef.current.api.applyTransaction({ update: [{ ...rowNode.data, ...data }] });
  });
}

export const runDelayedUpdateData = (gridRef, delayedUpdateData) => {
  const groupedDelayedUpdateData = delayedUpdateData.reduce((acc, row) => {
    if (isBlank(acc[row.node.id])) acc[row.node.id] = {};
    acc[row.node.id][row.key] = row.value
    return acc;
  }, {})
  performUpdateCells(gridRef, groupedDelayedUpdateData);
}

export const onCellValueChanged = (forecastScenario, newEditedCells, list, gridRef, editedCells, updateScenario, timeScale, runModelCells, callback = () => {}, action = '') => {
  newEditedCells = isPresent(newEditedCells) ? newEditedCells : buildNewEditedCells(editedCells, list, timeScale);
  const { recalculatedCells, delayedUpdateData } = recalculateCells(gridRef.current?.api, editedCells, newEditedCells, timeScale);
  const allEditedCells = uniqueBy([...recalculatedCells, ...newEditedCells, ...editedCells], 'id');
  const calcRunModelCells = calcRunModelCellsFunc(gridRef, newEditedCells, runModelCells, timeScale, action)
  updateScenario(forecastScenario.local_id, {
    update_data: { edited_cells: allEditedCells, run_model_cells: calcRunModelCells },
  }, (status, errors) => {
    if (status) {
      hideOverlay(gridRef.current?.api);
      callback();
      runDelayedUpdateData(gridRef, delayedUpdateData);
    } else {
      hideOverlay(gridRef.current?.api);
    }
  });
};


// Functions related to cells recalculation
const findNodeInGroupRows = (groupRows, key) => groupRows.find(row => row.id.includes(key));

const prepareCellReCalcData = (api, editedCell) => {
  const node = api.getRowNode(editedCell.nodeId);
  const periodId = editedCell.periodId;
  const colDefField = editedCell.field;
  const groupRows = node.key ? node.childrenAfterGroup : node.parent.childrenAfterGroup;
  const rootNode = groupRows.find(row => row.childIndex === 0);
  const isSimVsBmEdited = node.id.includes(SIM_VS_BENCHMARK_KEY);
  const isSimVsYaEdited = node.id.includes(SIM_VS_YA_KEY);
  return {
    periodId,
    colDefField,
    rootNode,
    isSimVsBmEdited,
    isSimVsYaEdited,
    benchmarkNode: findNodeInGroupRows(groupRows, BENCHMARK_KEY),
    simVsBmNode: findNodeInGroupRows(groupRows, SIM_VS_BENCHMARK_KEY),
    simVsYaNode: findNodeInGroupRows(groupRows, SIM_VS_YA_KEY),
    isRootNodeEdited: !isSimVsBmEdited && !isSimVsYaEdited && node.id === rootNode.id
  }
}

const performSimVsBmEdited = (editedCells, rootNode, benchmarkNode, simVsYaNode, colDefField, periodId, editedCell, recalculatedCells, delayedUpdateData, timeScale) => {
  const prevRootValue = rootNode.data[colDefField];
  const prevSimVsYaValue = simVsYaNode.data[colDefField];
  const newRootValue = calcRootFromSimVs(benchmarkNode.data[colDefField], editedCell.value);
  const yARootValue = calcYARootFromPrevSimVsYa(prevRootValue, prevSimVsYaValue);
  const newSimVsYaValue = calcSimVsValue(newRootValue, yARootValue);
  addToReCalcCells(recalculatedCells, editedCells, rootNode, prevRootValue, colDefField, periodId, newRootValue, timeScale);
  addToReCalcCells(recalculatedCells, editedCells, simVsYaNode, prevSimVsYaValue, colDefField, periodId, newSimVsYaValue, timeScale);
  delayedUpdateData.push({ node: rootNode, key: colDefField, value: newRootValue });
  delayedUpdateData.push({ node: simVsYaNode, key: colDefField, value: newSimVsYaValue });
}

const performSimVsYaEdited = (editedCells, rootNode, benchmarkNode, simVsBmNode, colDefField, periodId, editedCell, recalculatedCells, delayedUpdateData, timeScale) => {
  const prevRootValue = rootNode.data[colDefField];
  const prevSimVsYaValue = editedCell.prev_value;
  const prevSimVsBmValue = simVsBmNode.data[colDefField];
  const benchmarkRootValue = benchmarkNode.data[colDefField];
  const yARootValue = calcYARootFromPrevSimVsYa(prevRootValue, prevSimVsYaValue);
  const newRootValue = calcRootFromSimVs(yARootValue, editedCell.value);
  const newSimVsBmValue = calcSimVsValue(newRootValue, benchmarkRootValue);
  addToReCalcCells(recalculatedCells, editedCells, rootNode, prevRootValue, colDefField, periodId, newRootValue, timeScale);
  addToReCalcCells(recalculatedCells, editedCells, simVsBmNode, prevSimVsBmValue, colDefField, periodId, newSimVsBmValue, timeScale);
  delayedUpdateData.push({ node: rootNode, key: colDefField, value: newRootValue });
  delayedUpdateData.push({ node: simVsBmNode, key: colDefField, value: newSimVsBmValue });
}

const performRootNodeEdited = (editedCells, rootNode, benchmarkNode, simVsBmNode, simVsYaNode, colDefField, periodId, editedCell, recalculatedCells, delayedUpdateData, timeScale) => {
  const prevRootValue = editedCell.prev_value;
  const benchmarkRootValue = benchmarkNode.data[colDefField];
  const prevSimVsBmValue = simVsBmNode.data[colDefField];
  const prevSimVsYaValue = simVsYaNode.data[colDefField];
  const yARootValue = calcYARootFromPrevSimVsYa(prevRootValue, prevSimVsYaValue);
  const newRootSimVsYaValue = calcRootBySimVsRootValue(editedCell.value, yARootValue);
  const newRootSimVsBmValue = calcRootBySimVsRootValue(editedCell.value, benchmarkRootValue);
  addToReCalcCells(recalculatedCells, editedCells, simVsBmNode, prevSimVsBmValue, colDefField, periodId, newRootSimVsBmValue, timeScale);
  addToReCalcCells(recalculatedCells, editedCells, simVsYaNode, prevSimVsYaValue, colDefField, periodId, newRootSimVsYaValue, timeScale);
  delayedUpdateData.push({ node: simVsYaNode, key: colDefField, value: newRootSimVsYaValue });
  delayedUpdateData.push({ node: simVsBmNode, key: colDefField, value: newRootSimVsBmValue });
}


const addToReCalcCells = (recalculatedCells, editedCells, node, prevValue, colDefField, periodId, value, timeScale) => {
  recalculatedCells.push({
    ...findOrBuildCell(editedCells, { node: {...node, prevValue }, colDef: { field: colDefField, colId: periodId } }, timeScale),
    value
  });
}

const recalculateCells = (api, editedCells, newEditedCells, timeScale) => {
  const recalculatedCells = [];
  let delayedUpdateData = [];
  newEditedCells.forEach(editedCell => {
    const {
      periodId,
      colDefField,
      rootNode,
      benchmarkNode,
      simVsBmNode,
      simVsYaNode,
      isSimVsBmEdited,
      isSimVsYaEdited,
      isRootNodeEdited
    } = prepareCellReCalcData(api, editedCell);

    if(isBlank(benchmarkNode) && isBlank(simVsBmNode) && isBlank(simVsYaNode) && isRootNodeEdited) {
      return { recalculatedCells: [], delayedUpdateData: [] };
    }
    if(isSimVsBmEdited) {
      performSimVsBmEdited(editedCells, rootNode, benchmarkNode, simVsYaNode, colDefField, periodId, editedCell, recalculatedCells, delayedUpdateData, timeScale);
    }
    if(isSimVsYaEdited) {
      performSimVsYaEdited(editedCells, rootNode, benchmarkNode, simVsBmNode, colDefField, periodId, editedCell, recalculatedCells, delayedUpdateData, timeScale);
    }
    if(isRootNodeEdited) {
      performRootNodeEdited(editedCells, rootNode, benchmarkNode, simVsBmNode, simVsYaNode, colDefField, periodId, editedCell, recalculatedCells, delayedUpdateData, timeScale);
    }
  })
  return { recalculatedCells, delayedUpdateData };
}

// Functions related to creation of comparison rows

export const rowCmus = (node) => {
  const rowDriverId = node.allLeafChildren[0].data[ROW_DRIVER_ID_KEY];
  return parseRowCellId(rowDriverId)?.cmus?.split(',')?.filter(isPresent)?.map(Number) || []
}

export const calculateScopedCmusYearAgoChanges = ({ currentValue, scenario,
                                                    period, cmus, driverColumn, allPeriods }) => {
  const prevYearPeriod = allPeriods.find(p => {
    if (p.startDate >= period.startDate) return false;

    const difference = period.startDate.diff(p.startDate, 'months');
    return difference === 12;
  })
  if (isBlank(prevYearPeriod)) return DEFAULT_SIM_VS_VALUE;
  const prevYearValue = scenario.aggregateBy({
    cmus,
    period: prevYearPeriod,
    driver: driverColumn
  })
  if (isBlank(prevYearValue)) return DEFAULT_SIM_VS_VALUE;

  if (driverColumn.measure === '%') return currentValue - prevYearValue;

  return calcSimVsValue(currentValue, prevYearValue);
}

const buildBenchmarkData = (config, rowData, params, periods, forecastBenchmarkScenario, cmus) => {
  const driverColumn = findDriverColumn(rowData[ROW_DRIVER_ID_KEY], forecastBenchmarkScenario, config);
  return periods.forEach(period => {
    rowData[period.name] = forecastBenchmarkScenario.aggregateBy(
      { cmus, period, driver: driverColumn }
    );
  });
}

const buildSimVsBenchmarkData = (rowData, params, periods, benchmarkRowData, periodAgGridSuffixes) => {
  return periods.forEach(period => {
    const benchmarkValue = benchmarkRowData[period.name];
    const simValue = simAggValue(params, period.id, periodAgGridSuffixes);
    rowData[period.name] = calcSimVsValue(simValue, benchmarkValue);
  });
}

const buildSimVsYearAgoData = (config, rowData, params, periods, allPeriods, forecastScenario, cmus, periodAgGridSuffixes) => {
  const driverColumn = findDriverColumn(rowData[ROW_DRIVER_ID_KEY], forecastScenario, config)
  return periods.forEach(period => {
    const currentValue = simAggValue(params, period.id, periodAgGridSuffixes);
    rowData[period.name] = calculateScopedCmusYearAgoChanges({
      currentValue, scenario: forecastScenario,
      period, cmus, driverColumn, allPeriods
    })
  });
}

const buildBenchmarkVsYearAgoData = (config, rowData, params, periods, allPeriods, benchmarkRowData, forecastBenchmarkScenario, cmus) => {
  const driverColumn = findDriverColumn(rowData[ROW_DRIVER_ID_KEY], forecastBenchmarkScenario, config);
  return periods.forEach(period => {
    const currentValue = benchmarkRowData[period.name];
    rowData[period.name] = calculateScopedCmusYearAgoChanges({
      currentValue, scenario: forecastBenchmarkScenario,
      period, cmus, driverColumn, allPeriods
    })
  });
}

const buildUniqRowDriverId = (rowData, childRow, data, params) => {
  if(rowData[FACTS_HEADER]) {
    return `${data.key}${params.node.allLeafChildren[0].data[ROW_DRIVER_ID_KEY]}`
  }
  return `${data.key}${childRow.data[ROW_DRIVER_ID_KEY]}${SCENARIO_ROW_ID_SEPARATOR}${params.node.id}`
}

const buildGroupedColumnsData = (params, forecastScenario) => {
  const groupedData = {};
  const indexOfClicked = forecastScenario.groupFields.indexOf(params.node.field) >= 0 ?
    forecastScenario.groupFields.indexOf(params.node.field) :
    forecastScenario.groupFields.length;
  forecastScenario.groupFields.forEach((field, index) => {
    if(index <= indexOfClicked) {
      groupedData[field] = params.node.allLeafChildren[0].data[field]
    }
  })
  if(params.node.field === FACTS_HEADER) {
    groupedData[FACTS_HEADER] = params.node.key;
  }
  return groupedData;
}

export const createComparisonRows = (config, params, forecastScenario, forecastBenchmarkScenario) => {
  let benchmarkRowData = {};
  const allPeriods = forecastScenario.allTimeScalePeriods();
  const periods = forecastScenario.periods();
  const cmus = rowCmus(params.node).slice(0, params.node.level + 1);
  const childRow = params.node.allLeafChildren[0];
  const periodAgGridSuffixes = getPeriodAgGridSuffixes(params);
  return FACT_COMPARISON_HEADERS.map(data => {
    let rowData = buildGroupedColumnsData(params, forecastScenario);
    rowData[ROW_DRIVER_ID_KEY] = buildUniqRowDriverId(rowData, childRow, data, params);
    rowData[SUB_FACT_HEADER] = data.name;
    switch (data.key) {
      case BENCHMARK_KEY:
        buildBenchmarkData(config, rowData, params, periods, forecastBenchmarkScenario, cmus);
        benchmarkRowData = rowData;
        break;
      case SIM_VS_BENCHMARK_KEY:
        buildSimVsBenchmarkData(rowData, params, periods, benchmarkRowData, periodAgGridSuffixes);
        break;
      case SIM_VS_YA_KEY:
        buildSimVsYearAgoData(config, rowData, params, periods, allPeriods, forecastScenario, cmus, periodAgGridSuffixes);
        break;
      case BM_VS_YA_KEY:
        buildBenchmarkVsYearAgoData(config, rowData, params, periods, allPeriods, benchmarkRowData, forecastBenchmarkScenario, cmus);
        break;
      default:
        return null;
    }
    return rowData;
  })
};
