import React from 'react';
import { connect } from "react-redux";
import DataSources from '../../data_sources';
import {
  createSource,
  destroySource,
  replaceSource,
  saveUploadingSources,
  updateSource,
  attachReport,
  addSourceToDecision, attachScenario
} from "../../../store/sidebar/actions";

const DataSourcesRow = ({
                          driverData, createSource, updateSource, destroySource, saveUploadingSources, replaceSource,
                          attachReport, attachScenario,
                          addSourceToDecision, addClass = '', isDriverPanel = false, setAutoFocus
                        }) => {
  return <div className={`mb-3 ${addClass}`} onFocus={() => setAutoFocus(false)}>
    <DataSources driverData={driverData}
                 createSource={createSource}
                 updateSource={updateSource}
                 destroySource={destroySource}
                 replaceSource={replaceSource}
                 attachScenario={attachScenario}
                 attachReport={attachReport}
                 detachReport={destroySource}
                 addSourceToDecision={addSourceToDecision}
                 saveUploadingSources={saveUploadingSources}
                 isDriverPanel={isDriverPanel}
                 isSidebar={true}
    />
  </div>
}
const mapStateToProps = (_state) => ({});
export default connect(mapStateToProps, {
  createSource, updateSource, destroySource, saveUploadingSources, replaceSource,
  attachReport, attachScenario,
  addSourceToDecision
})(DataSourcesRow);
