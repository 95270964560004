import { isBlank } from "./common";

export const isBlankRichTextValue = (value, isJodit = false) => {
  if (isBlank(value)) return true

  if (isJodit) return !!isBlank(clearRichTextSpaces(value));

  return !!isBlank(value.toString().replace(/<br>|<\/br>|<p><br><\/p>|<p>\s+<\/p>/g, ""));
}

const clearRichTextSpaces = (value) => {
  return value.toString().replace(/&nbsp;/g, "").replace(/(<([^>]+)>)/ig, '');
}

const checkTableTags = (inputString) => {
  // Regular expression to match <table> tags and their contents
  const tableRegex = /<table[^>]*>[\s\S]*?<\/table>/gi;

  // Function to check if a table has <tr> tags
  function hasRowsOrCells(table) {
    return /<tr[^>]*>/i.test(table);
  }

  function replaceLTGTInTableCells(inputString) {
    // Regular expression to match <td> tags and their contents
    const tdRegex = /<td[^>]*>(.*?)<\/td>/gi;

    // Replace < and > symbols with &lt; and &gt; respectively in the inner text of <td> tags
    return inputString.replace(tdRegex, function(match, innerText) {
      const replacedText = innerText.replace(/&lt;|&gt;/g, ""); // Replaces escaped < and > characters with empty string
      return "<td>" + replacedText + "</td>";
    });
  }

  // Replace <table> tags without <tr> or <td> tags with an empty string
  return inputString.replace(tableRegex, function(match) {
    if (!hasRowsOrCells(match)) {
      return ""; // Replace with an empty string
    } else {
      return replaceLTGTInTableCells(match); // Keep the table as it is and td tag check
    }
  });
}

const allowedTags = ['strong', 'br', 'p', 'a', 'table', 'u', 's', 'tbody', 'thead', 'tr', 'td', 'ol', 'ul', 'li', 'em'];
const tagPattern = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi
const scrubHtml = (inputHtml) => {
  // Replace all HTML tags in the inputHtml string
  let scrubbedHtml = inputHtml.replace(tagPattern, (match, tag) => {
    // Check if the tag is allowed
    if (allowedTags.includes(tag)) {
      // If the tag is allowed, return it unchanged
      return match;
    } else {
      // If the tag is not allowed, replace it with an empty string
      return '';
    }
  });

  return checkTableTags(scrubbedHtml);
}

const provedParentTags = ['strong', 'em', 'u', 's'];
const cleanNestedTagsPattern = new RegExp(`<(?:${provedParentTags.join('|')})>\\s*(<p>.*?<\/p>)\\s*<\/(?:${provedParentTags.join('|')})>`, 'g');

export const prepareJoditRichText = (value) => { 
  const preparedHtml = value.toString().replace(/^\s*(<p>\s*<br\s*\/?>\s*<\/p>\s*)+/g, '')
    .replace(/(\s*<p>\s*<br\s*\/?>\s*<\/p>\s*)+$/g, '')
    .replace(/^\s*(<p>\s*(((<strong>|<em>|<u>|<s>)\s*)*(<br\s*\/?>\s*)+((<\/strong>|<\/em>|<\/u>|<\/s>)\s*)*)\s*<\/p>\s*)+/g, '')
    .replace(/(\s*<p>\s*(((<strong>|<em>|<u>|<s>)\s*)*(<br\s*\/?>\s*)+((<\/strong>|<\/em>|<\/u>|<\/s>)\s*)*)\s*<\/p>\s*)+$/g, '')
    .replace(/<img[^>]+>/ig, '')
    .replace(/<table/g, "<table class='table-bordered'")
    .replace(/<thead>/g, "<tbody>")
    .replace(/<\/thead><tbody>/g, "")
    .replace(/<th/g, "<td")
    .replace(/<\/th/g, "<\/td")
    .replace(/<\/th/g, "<\/td")
    .replace(/^\s*(<br\s*\/?>\s*)+/g, '')
    .replace(/(\s*<br\s*\/?>\s*)+$/g, '')
    .replace(/<u><br><\/u>/g, '<br>')
    .replace(/<br><br><\/u>/g, "<br><\/u><br>")
    .replace(/<s><br><\/s>/g, '<br>')
    .replace(/<br><br><\/s>/g, "<br><\/s><br>")
    .replace(/<p><br><br><\/p>/g, '<p><br><\/p>')
    .replace(/<strong><br><\/strong>/g, '<br>')
    .replace(/<br><br><\/strong>/g, '<br><\/strong><br>')
    .replace(/<em><br><\/em>/g, '<br>')
    .replace(/<br><br><\/em>/g, "<br><\/em><br>")
    .replace(/<u><br><\/u>|<s><br><\/s>|<em><br><\/em>|<strong><br><\/strong>/g, '<br>')
    .replace(cleanNestedTagsPattern, '')
    .replace(/<li[^>]*><p>(.*?)<\/p>/g, "<li>$1")
    .replace(/<p>\s*<br\s*\/?>\s*(?!<\/p>)/, '<p>')
    .replace(/<p>\s*<\/p>/g, '')
    .replace(/^\s*(<p>(?:&nbsp;|\s)*)+/g, '<p>')
    .replace(/((?:&nbsp;|\s)*<\/p>\s*)+$/g, '</p>')
    .replace(/^\s*(<p>(\s|&nbsp;|<br\s*\/?>)*(<strong>|<em>|<u>|<s>|<span>)*(\s|&nbsp;)*)+/g, '<p>$3')
    .replace(/((\s|&nbsp;)*(<\/strong>|<\/em>|<\/u>|<\/s>|<\/span>)*(\s|&nbsp;|<br\s*\/?>)*<\/p>\s*)+$/g, '$3</p>')

  return scrubHtml(preparedHtml)
}

export const focusToEnd = (focusElement) => {
  const len = focusElement.current.getEditor().getLength()
  const selection = { index: len, length: len };
  focusElement.current.setEditorSelection(focusElement.current.editor, selection);
  focusElement.current.focus();
}
