import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from "../../common/CloseIcon";
import ReportPropertiesStep from "../steps_wizard/steps/ReportPropertiesStep";

const ReportPropertiesModal = ({ show, handleClose, stepRef }) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Body>
        <CloseIcon onClose={handleClose} />
        <h2>Report properties</h2>
        <ReportPropertiesStep ref={stepRef} handleClosePropertiesModal={handleClose} />
      </Modal.Body>
    </Modal>
  );
};

export default ReportPropertiesModal;
