import React, {useEffect, useMemo, useRef, useState} from 'react';
import { connect } from "react-redux";
import { setPollPanelOpen, updateChoiceVotes } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import PanelHeader from "./PanelHeader";
import { isMobile } from "react-device-detect";
import { isCollaborator, isDecider } from "../../helpers/user_helpers";
import PollMoreActionsSection from "./sections/PollMoreActionsSection";
import PollChoicesSection from "./poll_input/PollChoicesSection";
import DoneButton from "../../common/DoneButton";
import VotesComment from "./poll_input/VotesComment";
import Decision from "../../models/decision";
import Recommendation from "../../models/recommendation";
import {isPresent, successActions} from "../../helpers/common";
import {reloadContacts} from "../../store/contacts/actions";
import {DriverNotes} from "./driver_input/QuestionRow";
import { userVotesChoicesCallback } from "../../helpers/decision_votes_helpers";

export const useUsersChoicesVotes = (user, decision) => {
  const currentUserVote = (choice, user) => choice?.votes?.find(v => v.user_email === user.email)

  return useMemo(() => userVotesChoicesCallback(decision).map(c_v => ({
    ...c_v, vote: currentUserVote(c_v, user)
  })), [decision.choices]);
};

const PollPanel = ({ sidebar, setPollPanelOpen, user, decision, updateChoiceVotes, reloadContacts }) => {
  if (!isDecider(user) && !isCollaborator(user)) return null;

  const decisionObject = new Decision(decision)
  const object = decisionObject.isRecommendationFlow ? new Recommendation(decision.recommendation) : decisionObject
  const decisionObj = new Decision(decision);
  const initUsersChoicesVotes = useUsersChoicesVotes(user, decision);
  const [choicesVotes, setChoicesVotes] = useState(initUsersChoicesVotes);
  const [submit, setSubmit] = useState(false);
  const [votesFeedback, setVotesFeedback] = useState(decisionObj.votesFeedbacks.find(f => user.email === f.user_email) || {});

  const saveVotesCallback = (make_mentionee_participant = false, current_mentionees) => {
    const data = {
      votedChoices: choicesVotes,
      votesFeedback: {
        ...votesFeedback,
        make_mentionee_participant,
        mentions: current_mentionees.newMentioned().map(h => h['email']),
        all_new_mentions: current_mentionees.mentioned().map(h => h['email']),
      }
    };
    const callback = (success) => {
      if (success) {
        setPollPanelOpen(false);
        successActions(make_mentionee_participant, [reloadContacts])
      }
    };
    updateChoiceVotes(data, callback)
  };

  useEffect(() => {
    setChoicesVotes(initUsersChoicesVotes);
    setVotesFeedback(decisionObj.votesFeedbacks.find(f => user.email === f.user_email) || {});
  }, [decision.choices]);

  useEffect(() => {
    if (object.isOpenEnded || !decisionObject.isPoll) return;

    const getParams = new URLSearchParams(window.location.search);
    const leftSideBarOpen = getParams.get('left_side_bar_open');
    if (leftSideBarOpen === 'decision_poll_panel') {
      setPollPanelOpen(true);
    }
  }, []);

  return (
    <SlidingPanel
      type="right"
      isOpen={sidebar.pollPanelSidebar}
      panelContainerClassName={`right-sidebar-panel-wrapper decision-input-panel-wrapper decision-panel-width`}
      panelClassName={`right-sidebar-panel-wrapper-content decision-input-panel-content ${isMobile ? '' : 'd-flex flex-column'}`}
      size={52}
    >
      <PanelHeader headerName={'Decision poll'} openSideBar={setPollPanelOpen} preview={true} children={<PollMoreActionsSection />} />
      <div className={`${(isPresent(decision.poll_notes)) ? 'border-bottom' : ''}`}>
        { decision.poll_notes && <DriverNotes {...{ driverNotes: decision.poll_notes }} /> }
      </div>
      <PollChoicesSection decision={decision} choicesVotes={choicesVotes} setChoicesVotes={setChoicesVotes} user={user} addClass={'mt-3'} />
      <VotesComment votesFeedback={votesFeedback} setVotesFeedback={setVotesFeedback} isFocusInput={false}
                    submit={submit} setSubmit={setSubmit} saveVotesCallback={saveVotesCallback} />
      <div className={`${isMobile ? 'mb-5' : 'side-panel bottom'} p-3 mt-auto`} style={{ zIndex: 0 }}>
        <DoneButton onClose={() => setSubmit(true)} />
      </div>
    </SlidingPanel>
  );
};

const mapStateToProps = ({ sidebar, current_user, decision }) => ({sidebar, user: current_user, decision});

export default connect(mapStateToProps, { updateChoiceVotes, setPollPanelOpen, reloadContacts })(PollPanel);
