import React from 'react';
import Decision from "../../../models/decision";
import { isBlank } from "../../../helpers/common";
import TopChoice from "./choices/TopChoice";
import RankedChoices from "./choices/RankedChoices";
import PickyChoices from "./choices/PickyChoices";
import OpenEndedChoices from "./choices/OpenEndedChoices";

const ChoicesSection = ({ decision }) => {
  if (isBlank(decision)) return null;

  const decisionObj = new Decision(decision);

  if(decisionObj.isRankedList) {
    return <RankedChoices object={decisionObj} />
  } else if(decisionObj.isPicky) {
    return <PickyChoices object={decisionObj} />
  } else if(decisionObj.isTopChoice) {
    return <TopChoice object={decisionObj} />
  } else if (decisionObj.isOpenEnded) {
    return <OpenEndedChoices />
  } {
    return null;
  }
}
export default ChoicesSection;
