import React from 'react';
import { connect } from "react-redux";
import { updateDecisionNotes } from "../../../store/playbook_notes/actions";
import { PlaybookForm } from "../../../template_view/side_panel/sections/PlaybookForm";

const mapStateToProps = ({ playbook_notes }) => ({ playbook_notes });
const mapDispatchToProps = (dispatch) => ({
  updateNotes: (data) => { dispatch(updateDecisionNotes(data)); }
});
const wrapper = React.forwardRef((props, ref) => <PlaybookForm {...props} inputRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
