import React, { Fragment } from 'react';
import { connect } from "react-redux";
import Decision, { FOLLOWUP_EXPECTATIONS, LEGACY_FOLLOWUP_EXPECTATIONS } from "../../../models/decision";
import {compareCurrencies, currencyFormat, isBlank} from "../../../helpers/common";
import * as moment from "moment";
import Button from 'react-bootstrap/Button';
import { isCollaborator, isDecider, userName } from "../../../helpers/user_helpers";
import { RichTextSection } from "../../../common/RichTextEditor";
import UserAvatarImage from "../../../common/UserAvatarImage";
import {openModal} from "../../../store/modals/actions";
import SectionWrapper from "./SectionWrapper";

const FollowupExpectation = ({ isLegacy, decisionObject }) => {
  const followupExpectations = isLegacy ? LEGACY_FOLLOWUP_EXPECTATIONS : FOLLOWUP_EXPECTATIONS;
  const expectationData = followupExpectations.find(data => data.value === decisionObject.lastFollowup.followup_expectation)
  if (isBlank(expectationData)) return null;

  const index = followupExpectations.indexOf(expectationData)
  return <Fragment>
    <h4 className="m-0">{expectationData.formatLabel}</h4>
    <div className="bg-primary text-white wb-badge">{isLegacy ? expectationData.number : index + 1}</div>
  </Fragment>
}

export const FinalResultsSection = ({decision, user, openModal, isLegacy = false }) => {
  const decisionObject = new Decision(decision);
  if (!decisionObject.isDecisionWithResults) return null;

  const followedUpDate = () => {
    if(isLegacy) return decisionObject.lastFollowup.followed_up_at || decisionObject.lastFollowup.created_at

    return decisionObject.lastFollowup.followed_up_at
  }

  return <SectionWrapper>
    <div className="d-flex">
      <h3 className="px-3 mb-2">Results</h3>
      <span className="icon pe-3 ms-auto mt-2-negative" hidden={isLegacy}>
        <Button onClick={() => openModal({type: 'FollowupResultsModal'})} bsPrefix="btn btn-sm btn-secondary btn-sm-round py-1"
                hidden={!isDecider(user) && !isCollaborator(user)}>
          <i className="fas fa-pencil-alt fa-lg w-100"/>
        </Button>
      </span>
    </div>
    <div className="px-3 mb-3">
      <div className="mb-2" hidden={isBlank(decisionObject.followupChoice)}>
        <h3 className="text-muted">We changed our decision:</h3>
        <RichTextSection text={decisionObject.followupChoice?.description}/>
      </div>
      <div className="d-flex align-items-center mb-2">
        <FollowupExpectation {...{isLegacy, decisionObject}} />
      </div>
      <div className="mb-3" hidden={isBlank(decisionObject.lastFollowup.followup_what_happened)}>
        <RichTextSection text={decisionObject.lastFollowup.followup_what_happened}/>
      </div>
      <div className="mb-3" hidden={isBlank(decisionObject.lastFollowup.realized_value)}>
        <h3>Realized value</h3>
        <span>
          {currencyFormat(decisionObject.lastFollowup.realized_value)}&nbsp;
          <i className="text-muted" hidden={!decisionObject.expectedOpportunityPresent}>
            ({compareCurrencies(decisionObject.expectedOpportunity, decisionObject.lastFollowup.realized_value)})
          </i>
        </span>
      </div>
      <div className="mb-3" hidden={isBlank(decisionObject.lastFollowup.realized_investment)}>
        <h3>Realized investment or cost</h3>
        <span>
          {currencyFormat(decisionObject.lastFollowup.realized_investment)}&nbsp;
          <i className="text-muted" hidden={!decisionObject.expectedInvestmentPresent}>
            ({compareCurrencies(decisionObject.expectedInvestment, decisionObject.lastFollowup.realized_investment)})
          </i>
        </span>
      </div>
      <div className="d-flex align-items-center" key={`results-by-${decisionObject.lastFollowup.user?.email}`}>
        <UserAvatarImage user={decisionObject.lastFollowup.user} />
        <div className="text-muted ms-2">
          Entered by {userName(decisionObject.lastFollowup.user)} {moment(followedUpDate()).format('DD MMM, yyyy')}
        </div>
      </div>
    </div>
  </SectionWrapper>;
}

const mapStateToProps = (state) => ({
  decision: state.decision, user: state.current_user
});
export default connect(mapStateToProps, { openModal })(FinalResultsSection);
