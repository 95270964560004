import React from 'react';
import { connect } from "react-redux";
import { setDecisionOrderSidebarOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import SetPanelHeader from "../../tree_view/side_panel/SetPanelHeader";
import { isMobile } from "react-device-detect";
import TreeSection from './sections/TreeSection';

export const DecisionOrderPanel = ({
                                     sidebar, addClass,
                                     openSideBar, isTemplate = false,
                                     isTemplateSet = false, preview = false,
                                     ...opts
                                  }) =>
  <SlidingPanel
    type="left"
    isOpen={sidebar.decisionOrderSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-order-panel-wrapper details-panel-width template-panel ${addClass} ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-order-panel-content`}
    size={52}
  >
    <SetPanelHeader headerName={`Decision flow`} {...{...opts, openSideBar, isTemplateSet}} />
    <TreeSection {...{ ...opts, preview, isTemplate }} />
  </SlidingPanel>

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setDecisionOrderSidebarOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionOrderPanel);
