import React, { useRef } from "react";
import { connect } from "react-redux";
import { setSetDecisionsSidebarOpen } from "../../../../store/sidebar/actions";
import BaseHamburgerBtn from "../../../../common/BaseHamburgerBtn";

const AddDecision = ({ set, isTemplateSet, treeNode, history, openSideBar }) => {
  const addDecisionParams = treeNode.isRoot ? '' : `?section_slug=${treeNode.slug}`

  const onAddSection = (e) => {
    e.preventDefault();

    if(isTemplateSet) {
      history.push(`/template_sets/${set.slug}/add_template${addDecisionParams}`);
    } else {
      history.push(`/decision_sets/${set.slug}/add_decision${addDecisionParams}`);
    }
    openSideBar(false);
  }

  return <>
    <BaseHamburgerBtn onSelect={onAddSection} title={`Add a decision`}/>
  </>
}
const mapStateToProps = ({ modal, decision_set, template_set }, { isTemplateSet }) => ({
  modal, set: isTemplateSet ? template_set : decision_set
});
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (flag) => {
    dispatch(setSetDecisionsSidebarOpen(flag));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(AddDecision);
