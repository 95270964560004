import React, { useRef, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { isPresent, successActions } from "../../helpers/common";
import { deleteUserDriverComment, updateUserDriverComment } from "../../store/modals/actions";
import { isEnter, isEsc } from "../../helpers/keys_helpers";
import { PLACEHOLDER } from "./AddCommentForm";
import { findDriverData } from "../accordion_tree/shared/helpers";
import DiscussionMentionsInput from "../side_panel/discussion/DiscussionMentionsInput";
import { parseContactIds } from "../../helpers/discussion_helpers";
import { reloadContacts } from "../../store/contacts/actions";
import { mentioneeWarning } from "../../helpers/alert_helpers";
import { initLastOpenedFieldUseEffect } from "./comments_helpers";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";

export const mapModalSidebarDriverToProps = ({ modal, sidebar, driver = null }) => {
  if(driver) return { driver }

  const isDriverModal = modal.shown && modal.type === "DriverShowModal";
  const stateData = isDriverModal ? modal : sidebar;
  const stateSlug = isDriverModal ? modal.slug : sidebar.driverSlug;
  const driverData = findDriverData(stateData, stateSlug);
  driver = driverData.driver || {};
  return { driver }
}

const EditCommentRow = ({
                          innerRef,
                          comment, driver, onClose,
                          updateUserDriverComment, deleteUserDriverComment, reloadContacts, resetDecisionSetData,
                          lastOpenedField
                        }) => {
  const [userComment, setUserComment] = useState(comment.description)
  const [mentioned, _] = useState(parseContactIds(comment.description))
  const [submitState, setSubmitState] = useState(false);

  const ref = useRef(null);

  const onCloseCallback = (callback = () => null) => {
    setUserComment(comment.description)
    callback()
    onClose()
  }
  const onSuccessCallback = (success, make_mentionee_participant = false, callback = () => null) => {
    setSubmitState(false)
    if (success) {
      onCloseCallback(callback);
      successActions(make_mentionee_participant, [reloadContacts, resetDecisionSetData])
    }
  }
  const onDeleteCallback = (success, callback = () => null) => {
    setSubmitState(false)
    if (success) onCloseCallback(callback)
  }
  const updateComment = (callback = () => null) => {
    if(submitState) return false;

    if (isPresent(userComment.trim())) {
      const newMentioned = ref.current.newMentioned().map(h => h['email'])
      mentioneeWarning(
        newMentioned,
        (make_mentionee_participant) => {
            setSubmitState(true)
            updateUserDriverComment(driver, comment, {
            feedback: { description: userComment },
            all_new_mentions: ref.current.mentioned().map(h => h['email']),
            mentions: newMentioned,
            make_mentionee_participant
          }, (status) => onSuccessCallback(status, make_mentionee_participant, callback))
        }
      )
    } else {
      setSubmitState(true)
      deleteUserDriverComment(driver, comment, arg1 => onDeleteCallback(arg1, callback))
    }
  }
  const onKeyDown = (e) => {
    if (isEsc(e.keyCode)) {
      e.preventDefault();
      onCloseCallback()
      return false;
    } else if (isEnter(e.keyCode)) {
      e.preventDefault();
      updateComment();
    }
  }
  const onChange = ({userMessage}) => setUserComment(userMessage)

  initLastOpenedFieldUseEffect({
    lastOpenedField,
    fieldKey: comment.id.toString(),
    submitAction: updateComment,
    showCondition: true
  })

  if (innerRef) {
    innerRef.current = { updateComment }
  }

  return <div className="message-input-max-width message-input-max-height w-100">
    <DiscussionMentionsInput {...{
      ref, onChange, initialMentioned: mentioned, initialValue: userComment, placeholder: PLACEHOLDER, onKeyDown,
      disabled: submitState
    }} />
  </div>
}
const mapStateToProps = (state, { driver = null }) => mapModalSidebarDriverToProps({ ...state, driver });
const wrapper = React.forwardRef((props, ref) => <EditCommentRow {...props} innerRef={ref} />)
export default connect(mapStateToProps, {
  updateUserDriverComment, deleteUserDriverComment, reloadContacts, resetDecisionSetData
}, null, { forwardRef: true })(wrapper);
