import { COMPLETE_TEAMS_DATA, FETCH_CONTACTS_DATA, LOAD_FAILED } from "./types";
import EntryPoint from "../../EntryPoint";
import { loadCollaborationContacts } from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import { isPresent } from "../../helpers/common";

export const loadContacts = (callback = () => {}, data = {}) => (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance
    const decision_id = controllerName === 'decisions' ? objectSlug : null
    dispatch(fetchContactsData());
    const { invite_yourself } = data;
    loadCollaborationContacts(decision_id, invite_yourself).then(response => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure });

        const { data } = response;
        const { contacts } = data;
        callback(contacts);
        dispatch(saveContactsData({ contacts, loaded: true, loading: false, invite_yourself: invite_yourself || isPresent(decision_id) }));
    })
}

export const reloadContacts = () => (dispatch) => {
    dispatch(saveContactsData({ loaded: false, loading: false }))
}

export const fetchContactsData = () => ({
    type: FETCH_CONTACTS_DATA,
    payload: {
        loading: true,
        loaded: false
    }
});

export const saveContactsData = (payload) => ({
    type: COMPLETE_TEAMS_DATA,
    payload
});

export const loadFailure = error => ({
    type: LOAD_FAILED,
    payload: {
        error,
        loading: false
    }
});
