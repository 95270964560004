import { isDSightDecision } from "../../helpers/home_decision_helpers";
import { isBlank } from "../../helpers/common";
import { updateDecisionDataInStores } from "../../helpers/store_helpers";

export const updateGptQueries = (dispatch, getState, decision_chat_gpt_queries, recommendation_chat_gpt_queries) => {
  const {decision} = getState();
  if(isDSightDecision(decision) || isBlank(decision)) return;

  const decisionData = {
    ...decision,
    chat_gpt_queries: decision_chat_gpt_queries || [],
    recommendation: {...decision?.recommendation, chat_gpt_queries: recommendation_chat_gpt_queries || []}
  }
  updateDecisionDataInStores(dispatch, getState, decisionData)
}
