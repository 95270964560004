import React from 'react';
import ContextAwareToggle from "./ContextAwareToggle";
import { ALL_STATUS, DECIDED_STATUS, IN_FLIGHT_STATUS, RESULTS_STATUS } from "../../../helpers/filter_helpers";
import { Card, Accordion } from 'react-bootstrap'

const StatusCheckbox = ({ status, onChange, checked, name }) =>
  <div className="mb-3 w-50 d-inline-block lh-checkbox">
    <input type="checkbox" className="custom-checkbox" id={`${name}-checkbox`}
           {...{ checked, name, onChange, title: status }} />
    <label htmlFor={`${name}-checkbox`} className="mb-0">{status}</label>
  </div>

export default ({
                  allStatus, inFlightStatus, decidedStatus, resultsStatus,
                  handleStatusChange
                }) => {
  const all = allStatus ? ALL_STATUS : "";
  const decided = decidedStatus ? DECIDED_STATUS : "";
  const inFlight = inFlightStatus ? IN_FLIGHT_STATUS : "";
  const results = resultsStatus ? RESULTS_STATUS : "";
  const filterTextArray = [all, decided, inFlight, results].filter(Boolean)

  return <Card className="shadow">
    <Card.Header className="bg-white border-0 pointer p-0">
      <ContextAwareToggle eventKey="0" {...{ filterTextArray }}>
        Current status
      </ContextAwareToggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0" className={`${allStatus ? '' : 'show'}`}>
      <Card.Body className="py-0 px-3">
        <div>
          <StatusCheckbox {...{ name: 'all', checked: allStatus, status: ALL_STATUS, onChange: handleStatusChange }} />
          <StatusCheckbox {...{ name: 'inFlight', checked: inFlightStatus, status: IN_FLIGHT_STATUS, onChange: handleStatusChange }} />
        </div>
        <div>
          <StatusCheckbox {...{ name: 'decided', checked: decidedStatus, status: DECIDED_STATUS, onChange: handleStatusChange }} />
          <StatusCheckbox {...{ name: 'results', checked: resultsStatus, status: RESULTS_STATUS, onChange: handleStatusChange }} />
        </div>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
}

