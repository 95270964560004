import React, { Fragment } from 'react';
import { isBlank, isPresent } from "../../../../helpers/common";
import { Link } from "react-router-dom";
import { RightAlignedSemaphoreIcon } from "../../AnalysisContent";
import { analysisLink } from "../../../../helpers/dsight_helpers";
import { skipAnalysisRecord } from "../../../../models/d_sight/Analysis";

const NodeLink = ({ analysisObject, topNode=false, onClick }) =>
  <Link to={analysisLink(analysisObject.link)} onClick={() => { onClick() }}>
    <NodeText insightData={analysisObject.insightData} topNode={topNode} showSemaphore={analysisObject.showSemaphore} />
  </Link>

const NodeText = ({ insightData, activeNode=false, topNode=false, showSemaphore = false }) =>
  <Fragment>
    <div className={`${activeNode ? 'bg-light rounded p-1 ms-1' : 'rounded p-1 ms-1'} ${topNode ? '' : 'mt-3-negative'}`}>
      <div className="d-flex justify-content-between">
        {isPresent(insightData.question) ? insightData.question : insightData.display_name}
        { showSemaphore ? <RightAlignedSemaphoreIcon semaphore={insightData.semaphore} /> : null }
      </div>
    </div>
  </Fragment>

const Node = ({ analysisObject, currentTreeNode, topNode=false, onClick }) => {
  const isCurrentNode = currentTreeNode === analysisObject.id

  if (skipAnalysisRecord(analysisObject)) return null;

  const renderChildrenNodes = () => {
    if(isBlank(analysisObject.child)) { return; }

    const childrenTreeNodes = analysisObject.child.map((analysisChildObject, index) =>
      <Node key={`node-${analysisObject.id}-${analysisChildObject.id}-${index}`}
            currentTreeNode={currentTreeNode}
            analysisObject={analysisChildObject} onClick={onClick} />
    );
    return (
      <ul className={`${isCurrentNode ? '' : ''}`}>
        {childrenTreeNodes}
      </ul>
    )
  };

  return <li className={`${topNode ? 'no-before ps-0' : ''}`}>
      {isCurrentNode ?
        <NodeText insightData={analysisObject.insightData} activeNode={true} topNode={topNode} showSemaphore={analysisObject.showSemaphore} /> :
        <NodeLink analysisObject={analysisObject} topNode={topNode} onClick={onClick}/>
      }
    {renderChildrenNodes()}
  </li>
};
export default Node;
