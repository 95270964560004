import React from 'react';
import {CheckForm} from "../../../tree_wizard/helpers/DecisionFlowRow";
import {NUMBER_FORMAT} from "../../../models/driver";

export default ({ numberFormat, hidden, submitted, onChangeNumberFormatting}) => {
  return <div className="w-100 mt-3" hidden={hidden}>
    <h3>Number formatting</h3>
    <CheckForm label="Default"
               labelExtra=" (1,000.13)"
               className='mb-2'
               extraClassName='text-muted'
               disabled={submitted}
               checked={numberFormat === NUMBER_FORMAT.DEFAULT}
               onClick={() => onChangeNumberFormatting(NUMBER_FORMAT.DEFAULT)} />
    <CheckForm label={'Dollar amount'}
               labelExtra=" ($1,000.13)"
               className='mb-2'
               extraClassName='text-muted'
               disabled={submitted}
               checked={numberFormat === NUMBER_FORMAT.DOLLAR}
               onClick={() => onChangeNumberFormatting(NUMBER_FORMAT.DOLLAR)} />
    <CheckForm label={'Percentage'}
               labelExtra={" (10.13%)"}
               className='mb-0'
               extraClassName='text-muted'
               disabled={submitted}
               checked={numberFormat === NUMBER_FORMAT.PERCENTAGE}
               onClick={() => onChangeNumberFormatting(NUMBER_FORMAT.PERCENTAGE)} />
  </div>
}
