import Decision from "../models/decision";
import { isDraftDecision } from "../models/decision_state";

export const fetchAssignedToCollaborator = (decision, decisionObj = null) => {
  if(isDraftDecision(decision)) return decision.assigned_collaborator_email;

  return (decisionObj || new Decision(decision)).isRecommendationFlow ?
    decision.recommendation?.assigned_collaborator_email :
    decision.assigned_collaborator_email;
}
