import React from 'react';
import { connect } from "react-redux";
import {isBlank} from "../../../helpers/common";
import CollabRowDescription from "../../../tree_view/side_panel/helpers/CollabRowDescription";
import UserAvatarImage from "../../../common/UserAvatarImage";
import {isPendingContact} from "../../../helpers/decision_helpers";
import { userName } from "../../../helpers/user_helpers";

const CollaboratorsInviteRow = ({ decision, slug, user, email, onRemoveClick = () => {} }) => {
  const name = userName(user, email)
  const isUserDecider = slug === 'decider'
  const deciderText = decision.assigned_decider_email === (user?.email || email) && decision.decided_at ? 'decider' : '';

  return <div className="d-flex mb-1 align-items-center">
    <span className="collaborator_img position-relative">
      <UserAvatarImage user={user} isPendingUser={isPendingContact(user)} pendingUserEmail={email} size='md' />
      <span className={ isBlank(deciderText) ? 'd-none' : 'd-inline' }>
        <i className="fas fa-star fa-sm text-warning position-absolute end-0"/>
      </span>
    </span>
    <CollabRowDescription {...{ name, isUserDecider, decision, email, deciderText }} />
    <span className="px-2 ms-auto btn btn-light btn-sm text-danger btn-sm-round" onClick={onRemoveClick} hidden={isUserDecider}>
      <i className="fas fa-times w-100"/>
    </span>
  </div>
}
const mapStateToProps = ({ wizard, decision}) => ({
  default_user_avatar_url: wizard.default_user_avatar_url, decision: decision
});
export default connect(mapStateToProps)(CollaboratorsInviteRow);
