import { useHistory } from "react-router-dom";
import { deleteWarning } from "../../helpers/alert_helpers";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { isBlank, successActions } from "../../helpers/common";
import { forceReloadHomepageDecisions, reloadTemplatesData } from "../../store/homepage/actions";
import { deleteDecision } from "../../store/tree/actions";
import {
  copyChoices,
  resetSidebars,
  setDecisionSidebarOpen,
  setRecommendationInputSidebarOpen,
  setTreeSidebarOpen
} from "../../store/sidebar/actions";
import { resetPlaybookNotes } from "../../store/playbook_notes/actions";
import { createNewTemplate } from "../../store/template/actions";
import { isPresent } from "../../../ms_teams/helpers/common";
import {closeModal, openModal, updateDecision} from "../../store/modals/actions";
import { openStartDuplicateDecisionModal } from "../modals/StartDuplicateDecisionModal";
import {openEditDecisionEntryModal} from "../modals/DecisionEntryModal";
import { resetTemplateSetData } from "../../store/template_set/common_actions";
import {TREE_MODES} from "../../helpers/drivers_helpers";
import Decision from "../../models/decision";
import Recommendation from "../../models/recommendation";
import {openDecisionPollModal} from "../modals/DecisionPollModal";
import {resetDecisionSetData} from "../../store/decision_set/common_actions";

const startDecisionFromDuplicate = (decision, history, openModal) => () => {
  history.replace({ search: '' });
  openStartDuplicateDecisionModal({ openModal, type: 'StartDuplicateDecisionModal', decision });
}

const openTreeSidebar = (setTreeSidebarOpen) => () => {
  setTreeSidebarOpen(true, TREE_MODES.edit);
}

const openAssignDriversSidebar = (setTreeSidebarOpen) => () => {
  setTreeSidebarOpen(true, TREE_MODES.assign);
}

const onEditDecisionFromDecisionEntryModal = (decision, openModal, copyChoices) => () => {
  const decisionObj = new Decision(decision);
  const recommendation = decision.recommendation;
  const recommendationObject = new Recommendation(recommendation);

  if(isBlank(decisionObj.decisionChoices) && isPresent(recommendationObject.recommendationChoices)) {
    copyChoices((success, updatedDecision) => {
      if(success) openEditDecisionEntryModal({ openModal, type: 'PanelDecisionEntryModal', decision: updatedDecision });
    })
  } else {
    openEditDecisionEntryModal({ openModal, type: 'DecisionPollModal', decision });
  }
}

const openPollModal = (decision, openModal) => () => {
  openDecisionPollModal({ openModal, type: 'DecisionPollModal', decision });
}

const duplicateAsTemplateFromDecision = (createNewTemplate, decision, history, actions) => () => {
  history.replace({ search: '' });
  const templateData = {
    duplicated_decision_slug: decision.slug,
    description: `${decision.description} (copy)`,
    complete: true
  }
  createNewTemplate(templateData, (slug, completed = false) => {
    if (isPresent(slug)) {
      successActions(true, actions);
      setTimeout(() => {
        history.push(`/templates/${slug}${completed ? '' : '/wizard'}`)
      }, 50);
    }
  });
}

export const onShowAsCompletedCallback = (updateDecision) => {
  return updateDecision({template: {show_as_completed: true}});
}

export const deleteAndStartActions = ({
                                        decision,
                                        deleteDecision,
                                        createNewTemplate,
                                        forceReloadHomepageDecisions,
                                        resetSidebars, resetPlaybookNotes, resetTemplateSetData, reloadTemplatesData,
                                        openDecisionDetailsSideBar,
                                        setRecommendationInputSidebarOpen,
                                        setTreeSidebarOpen,
                                        openModal, updateDecision,
                                        copyChoices, decision_set, current_user, resetDecisionSetData
                                      }) => {
  const resetActions = [resetDecisionSetData,
    forceReloadHomepageDecisions, resetSidebars, resetPlaybookNotes, resetTemplateSetData, reloadTemplatesData,
    () => openDecisionDetailsSideBar(false),
    () => setRecommendationInputSidebarOpen(false)
  ]
  const history = useHistory();
  const isFromDecisionSet = history.location.search.includes('decision_set')

  const setSlug = decision_set ? decision.decision_set_slug : '';
  const emailShareMembers = setSlug ? decision_set.users.map(user => user.email) : [];
  const userIsInShareMembers = current_user && emailShareMembers.includes(current_user.email);

  const deleteDecisionHandler = () => {
    deleteWarning(confirmed => {
      if (!confirmed) return;

      deleteDecision((success) => {
      if (isPresent(setSlug) && userIsInShareMembers && isFromDecisionSet){
        history.push(`/decision_sets/${setSlug}`);
      } else {
        redirectToHomeCallback(success, history);
      }
        successActions(success, resetActions);
      })
    })
  };

  const editDecisionTree = openTreeSidebar(setTreeSidebarOpen, TREE_MODES.edit);
  const assignDriversTree = openAssignDriversSidebar(setTreeSidebarOpen);
  const startDecision = startDecisionFromDuplicate(decision, history, openModal);
  const startTemplate = duplicateAsTemplateFromDecision(createNewTemplate, decision, history, resetActions);
  const onEditDecision = onEditDecisionFromDecisionEntryModal(decision, openModal, copyChoices);
  const createDecisionPoll = openPollModal(decision, openModal);
  const onShowAsCompleted = () => onShowAsCompletedCallback(updateDecision)

  return {
    deleteDecisionHandler, startDecision, startTemplate, onEditDecision, editDecisionTree, assignDriversTree, createDecisionPoll, onShowAsCompleted
  }
}
export const mapStartDeleteDispatchToProps = (dispatch) => ({
  forceReloadHomepageDecisions: () => dispatch(forceReloadHomepageDecisions()),
  deleteDecision: (callback) => dispatch(deleteDecision(callback)),
  resetSidebars: () => dispatch(resetSidebars()),
  resetTemplateSetData: () => dispatch(resetTemplateSetData()),
  reloadTemplatesData: () => dispatch(reloadTemplatesData()),
  resetPlaybookNotes: () => dispatch(resetPlaybookNotes()),
  openDecisionDetailsSideBar: (flag) => dispatch(setDecisionSidebarOpen(flag)),
  setRecommendationInputSidebarOpen: (flag) => dispatch(setRecommendationInputSidebarOpen(flag)),
  setTreeSidebarOpen: (flag, mode) => dispatch(setTreeSidebarOpen(flag, mode)),
  createNewTemplate: (data, callback) => dispatch(createNewTemplate(data, callback)),
  openModal: (data) => dispatch(openModal(data)),
  closeModal: () => dispatch(closeModal()),
  copyChoices: (callback) => dispatch(copyChoices(callback)),
  updateDecision: (data, callback) => dispatch(updateDecision(data, callback)),
  resetDecisionSetData: () => dispatch(resetDecisionSetData()),
});
