import React from 'react';
import ChoiceItem, {sortedDriverChoices} from "./ChoiceItem";
import {isPresent} from "../../../../helpers/common";

const TopChoice = ({driver, isHistoricalDecision, setChoices, choices, updateDriverFinalChoices}) => {
  const driverChoices = isHistoricalDecision ? choices.filter(c => isPresent(c.description)) : sortedDriverChoices(driver)
  const notSaveChoice = isHistoricalDecision

  return <div>
    {
      driverChoices.map(choice =>
        <ChoiceItem driver={driver} choice={choice} key={`top-choice-${choice.slug}`} selected={choice.final_decision}
                    notSaveChoice={notSaveChoice} setChoices={setChoices} updateDriverFinalChoices={updateDriverFinalChoices}
                    choices={driverChoices} />
      )
    }
  </div>
}

export default TopChoice;
