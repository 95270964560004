import React, {useCallback, useEffect} from 'react';
import { Accordion, Card } from 'react-bootstrap'
import ContextAwareToggle from "./ContextAwareToggle";
import ShareToContactsTypehead from "../../../common/ShareToContactsTypehead";
import SearchIcon from "./SearchIcon";
import { setFilterAutofocus } from "../../../helpers/filter_helpers";
import {isBlank, isPresent} from "../../../helpers/common";

const SECTION = 'template_users'


export default ({
                  selectedTemplateUsers, setSelectedTemplateUsers,
                  submit,
                  openedSection, setOpenedSection,
                  eventKey =  '1',
                  allGroups = false,
                  ...opts
                }) => {
  const setAutofocus = useCallback(() => setFilterAutofocus(setOpenedSection, SECTION), [])

  const filterTextArray = selectedTemplateUsers.map((u) => u.full_name)

  return <Card className="shadow">
    <Card.Header className="bg-white border-0 pointer p-0" onClick={setAutofocus}>
      <ContextAwareToggle {...{ ...opts, filterTextArray, eventKey }}>
        Groups
      </ContextAwareToggle>
    </Card.Header>
    <Accordion.Collapse eventKey={eventKey} className={`${isPresent(filterTextArray) ? 'show' : ''}`}>
      <Card.Body className="pt-0 px-3 pb-3">
        <SearchIcon />
        <ShareToContactsTypehead
          {...{
            disabled: submit, allGroups,
            values: selectedTemplateUsers, setValues: setSelectedTemplateUsers,
            flip: false
          }}
          autoFocus={openedSection === SECTION}
          minLength={0}
        />
      </Card.Body>
    </Accordion.Collapse>
  </Card>
}
