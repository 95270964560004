import React, { Fragment, useEffect, useRef } from 'react';
import WizardHeader from "../tree_wizard/header";
import { connect } from "react-redux";
import 'react-sliding-side-panel/lib/index.css';
import AlertsSection from "../alerts";
import HelpPanel from "./side_panel/HelpPanel";
import Help from "./help";
import { loadWizardData } from "../store/wizard/actions";
import StepsWizard from "./steps_wizard";
import { updateEntryPointData } from "../EntryPoint";
import { loadOrgCategories } from "../store/org_categories/actions";
import { Loader } from "../common/Loader";
import DecisionDetailsPanel from "./side_panel/DecisionDetailsPanel";
import TemplatePlaybookPanel from "../template_view/side_panel/TemplatePlaybookPanel";
import SidebarTreeNavigationWizard from "./navigation";
import WizardTreePanel from "../tree_wizard/side_panel/WizardTreePanel";
import { resetTemplate, updateTemplateData } from "../store/template/common_actions";
import { checkLoadingEffect } from "../helpers/callbacks_helpers";
import LinkPanel from "../tree_view/side_panel/link_panel/LinkPanel";
import { successActions } from "../helpers/common";
import { resetTree } from "../store/tree/common_actions";
import { reloadContacts } from "../store/contacts/actions";
import RatingsAndWeightsPanel from "../tree_wizard/side_panel/RatingsAndWeightsPanel";
import { isTreePreview } from "../helpers/wizard_helpers";
import DataSourcesPanel from "../tree_view/side_panel/DataSourcesPanel";
import DataSourcesSection from "../tree_view/side_panel/sections/DataSourcesSection";

const Sidebars = ({ wizard, stepRef }) =>
  <Fragment>
    {isTreePreview(wizard) ? null : <Help addClass={'sticky-help-button'} />}
    <div className="sidebars">
      <RatingsAndWeightsPanel isTemplate={true} />
      {wizard.loaded ? <DecisionDetailsPanel key="decision-details-side-panel" /> : null }
      {isTreePreview(wizard) ?
        <TemplatePlaybookPanel preview={false} addClass={'left-side-panel-position tree-page'} /> :
        <TemplatePlaybookPanel preview={false} position={'right'} />}
      <WizardTreePanel key="tree-side-panel" isTemplate={true} stepRef={stepRef}/>
      <DataSourcesPanel>
        <DataSourcesSection isWizard={true} />
      </DataSourcesPanel>
      <LinkPanel key="link-side-panel" preview={isTreePreview(wizard)} />
      <div className="help-side-panels">
        <HelpPanel />
      </div>
    </div>
  </Fragment>

const Wrapper = ({children, wizard, stepRef, ...opts}) =>
  <Fragment>
    <AlertsSection />
    <WizardHeader ref={stepRef} isTemplate={true} />
    <div className={`d-flex ${wizard.step_index === 2 ? '' : 'overflow-hidden'}`}>
      {isTreePreview(wizard) ? <SidebarTreeNavigationWizard {...opts}/> : null}
      {children}
    </div>
    <Sidebars wizard={wizard} stepRef={stepRef} />
  </Fragment>

const TemplateWizard = ({
                          wizard, template, org_categories,
                          loadWizardData, loadOrgCategories, resetTemplate, resetTree, reloadContacts,
                          match
                       }) => {
  useEffect(() => {
    updateEntryPointData(match, 'templates', () => {
      successActions(true, [resetTree, resetTemplate, reloadContacts])
    })
  }, [match?.params?.id])
  useEffect(() => {
    if (template.loaded && template.slug !== match?.params?.id) {
      resetTemplate()
    }
  }, [match?.params?.id, template])

  checkLoadingEffect(org_categories, loadOrgCategories)
  useEffect(() => { loadWizardData() }, []);

  const stepRef = useRef(null)

  if (wizard.loading) {
    return <Wrapper {...{wizard, stepRef, template}}>
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper {...{wizard, stepRef, template}}>
      <StepsWizard ref={stepRef} />
    </Wrapper>;
  }
};
const mapStateToProps = ({ wizard, current_user, org_categories, template }) => ({
  wizard, current_user, org_categories, template
});
const mapDispatchToProps = (dispatch) => ({
  loadWizardData: () => { dispatch(loadWizardData()) },
  loadOrgCategories: () => { dispatch(loadOrgCategories()) },
  updateTemplateData: (data) => { dispatch(updateTemplateData(data)) },
  resetTree: () => dispatch(resetTree()),
  resetTemplate: () => { dispatch(resetTemplate()) },
  reloadContacts: () => dispatch(reloadContacts())
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateWizard);
