import React from 'react';
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

const NO_RESULTS_IMG_SIZE = '150px';

export default ({ home, resetAllFilters }) =>
  <div className="text-center d-grid gap-2 col-auto mx-auto">
    <div className="text-center">
      <Image src={home.no_results_image_url} alt="No results" width={NO_RESULTS_IMG_SIZE} height={NO_RESULTS_IMG_SIZE} />
    </div>
    <h4>Sorry, no results match your search.</h4>
    <Button bsPrefix="btn btn-primary m-0" onClick={resetAllFilters}>
      Search all
    </Button>
  </div>
