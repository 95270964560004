import React from 'react';
import { connect } from "react-redux";
import { closeModal } from "../../../store/modals/actions";
import Modal from "react-bootstrap/Modal";
import { ModalBodyWithClose, ModalDoneFooterP3 } from "../../../common/modals";
import { capitalize, isPresent } from "../../../helpers/common";
import { Table } from 'react-bootstrap'

export const MODAL_KEY = 'segmentation'
const isShownSegmentationModal = ({ shown, type, segment }) => shown && type === 'SegmentationCriteriaModal' && isPresent(segment)
export const openSegmentationModal = ({ openModal }, segment) => openModal({ type: 'SegmentationCriteriaModal', segment })

const SegmentationCriteriaModal = ({ modal, closeModal }) =>
  <Modal size="md" backdrop="static" show={isShownSegmentationModal(modal)} onHide={closeModal}>
    <ModalBodyWithClose header="Segmentation" onClose={closeModal}>
      <p className="text-gray mb-3">N = 80-100/group/quarter</p>
      <div>
        <p className="fw-bolder mb-2">{capitalize(modal.segment?.question)}</p>
        <Table borderless className="segmentation-table mb-0">
          <tbody>
          {
            modal.segment?.segmentation_desc?.map(({ value, description }, index) =>
              <tr key={`filter-criteria-${index}`}>
                <td>{description}</td>
                <td>{value}</td>
              </tr>
            )
          }
          </tbody>
        </Table>
      </div>
    </ModalBodyWithClose>
    <ModalDoneFooterP3 onClose={closeModal} />
  </Modal>

const mapStateToProps = ({ modal }) => ({ modal });
export default connect(mapStateToProps, { closeModal })(SegmentationCriteriaModal);
