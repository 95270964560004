import React from "react";
import {openModal} from "../store/modals/actions";
import {connect} from "react-redux";

const EditAssignBtn = ({ assignUser, openModal, modalType, isDSight = false}) => {
  if (isDSight || assignUser) return null

  return (
  <button onClick={() => openModal({type: modalType})} className='border-0 bg-transparent rounded-circle sm-avatar p-0 bg-secondary'>
    <i className="fas fa-lg fa-user text-primary"/>  </button>
  )
}

const mapStateToProps = ({ modal }) => ({ modal });
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(EditAssignBtn);