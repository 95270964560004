import React from "react";
import Wrapper from "../Wrapper";
import Description from "../decision/Description";
import PollInfo from "../poll/PollInfo";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import RecordedDecisionPollCard from "./RecordedDecisionPollCard";
import {isVoted, userVotesChoicesCallback} from "../../../helpers/decision_votes_helpers";
import {isCollaborator, isDecider} from "../../../helpers/user_helpers";
import {connect} from "react-redux";
import {closeModal} from "../../../store/modals/actions";
import {
  setPollPanelOpen
} from "../../../store/sidebar/actions";
import Decision from "../../../models/decision";
import Recommendation from "../../../models/recommendation";
import {ArrowUpIcon} from "../../../icons/custom_icons";

const PollCard = ({ user, sidebar, decision, setPollPanelOpen, showArrow = false, showBefore = false }) => {
  if (isDSightDecision(decision)) return null;

  const decisionObject = new Decision(decision)
  const object = decisionObject.isRecommendationFlow ? new Recommendation(decision.recommendation) : decisionObject
  if (object.isOpenEnded || !decisionObject.isPoll) return null;

  const hideEdit = !isDecider(user) && !isCollaborator(user)
  const usersChoicesVotes = userVotesChoicesCallback(decision);
  if(isVoted(decision)) return <RecordedDecisionPollCard {...{decision, usersChoicesVotes, object, hideEdit, showArrow, showBefore}} />

  const onEditPoll = () => {
    if(hideEdit) return false;

    setPollPanelOpen(!sidebar.pollPanelSidebar)
  }

  return <Wrapper isPoll={true} {...{decisionObject, showBefore}}>
    <div className="d-flex">
      <div className="d-flex flex-column my-auto lh-sm w-100 me-1" style={{minHeight: 36}}>
        <Description {...{user, description: 'Decision poll', onClick: onEditPoll}} />
        <PollInfo {...{decision, decisionObject, usersChoicesVotes}} />
      </div>
    </div>
    <div className={`arrow-up-icon-block ${showArrow ? 'd-block' : 'd-none'}`}>
      <ArrowUpIcon/>
    </div>
  </Wrapper>
}

const mapStateToProps = ({current_user, sidebar, decision}) => ({
  sidebar, decision,
  user: current_user
});
const mapDispatchToProps = (dispatch) => ({
  setPollPanelOpen: (value) => dispatch(setPollPanelOpen(value)),
  closeModal: () => { dispatch(closeModal()) }
});
export default connect(mapStateToProps, mapDispatchToProps)(PollCard);
