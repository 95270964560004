import React from 'react';
import { Card } from 'react-bootstrap'

export default ({ reset, save, defaultFilters }) =>
  <Card className="shadow">
    <div className="d-flex">
      <div className={`btn ${defaultFilters() ? 'text-muted disabled bg-white ' : 'text-danger'} border-0 p-3`} onClick={reset}>Reset</div>
      <div className="btn text-primary p-3 ms-auto" onClick={save}>Done</div>
    </div>
  </Card>
