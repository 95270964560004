import React from 'react';
import { connect } from "react-redux";
import { CHOICE_ENTRY_TYPE_DATA } from "../../../models/decision";

const SelectedChoiceEntryType = ({decision}) =>
  <div className="mb-2 text-muted">
    {CHOICE_ENTRY_TYPE_DATA[decision.choice_entry_widget_type]}
  </div>

const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps)(SelectedChoiceEntryType);
