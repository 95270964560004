import React from 'react';
import Select from 'react-select';
import AccordionWrapper from "../../../common/AccordionWrapper";
import { capitalize, isBlank, isPresent } from "../../../helpers/common";
import { DEFAULT_SCOPES, SCOPES_LABELS } from "../../helpers/scopes_helpers";
import { ChoiceEntryStyle } from "../../../tree_wizard/styles/choice_entry_styles";

const Header = ({ title, value = '' }) =>
  <div className="d-flex justify-content-between w-100">
    <div className="fw-bolder">{title}</div>
    <div className="pe-1">
      {value}
    </div>
  </div>

const ScopeInput = ({
                      options, selected = [],
                      onChange = () => null
                    }) => {
  return <Select onChange={onChange}
                 defaultValue={selected.map(id => options.find(hash => hash.value.toString() === id.toString()))}
                 selected={selected.map(id => options.find(hash => hash.value.toString() === id.toString()))}
                 isDisabled={false}
                 components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                 options={options} styles={ChoiceEntryStyle}
                 isSearchable isMulti isClearable
                 placeholder={'Select all that apply'}/>
}

const filteredCmus = (cmuColumn, cmuCombinations, otherScopesData) => {
  const cmus = Object.keys(cmuColumn.values);
  if (isBlank(cmuCombinations)) return cmus;

  return cmus.filter(cmuIdStr => {
    const cmuId = parseInt(cmuIdStr)

    return cmuCombinations.some(group =>
      group.includes(cmuId) && otherScopesData.every(hash => hash.cmus.some(cmId => group.includes(parseInt(cmId))))
    )
  })
}

const ChartsDecompositionScope = ({
                                    forecastConfig, updateScope, scopes,
                                    header = 'Charts & decomposition scope',
                                    keyBase = 'charts-decomposition-scope',
                                    scenarioCmus = []
                                  }) => {
  return <div className="px-3 mt-3 mb-0">
    <h3>{header}</h3>
    {
      Object.keys(scopes).map(columnId => {
        const otherScopesData = Object.keys(scopes).filter(cId => cId !== columnId && isPresent(scopes[cId].cmus)).map(cId => scopes[cId])
        const cmuColumn = forecastConfig.cmuColumns.find(column => column.id.toString() === columnId.toString())
        const options = filteredCmus(cmuColumn, scenarioCmus, otherScopesData).map(cmuId => ({ value: cmuId, label: cmuColumn.values[cmuId] }))
        const scopeData = scopes[columnId]
        const accordionObject = {
          header: <Header title={cmuColumn.displayName} value={capitalize(SCOPES_LABELS[scopeData?.scope])} />,
          body: <ScopeInput {...{ options }} selected={scopeData?.cmus || []} onChange={(selectHash) => {
            updateScope(cmuColumn.id.toString(), selectHash.map(hash => hash.value))
          }} />,
          defaultExpand: scopeData?.scope === DEFAULT_SCOPES.custom
        }
        return <AccordionWrapper
          accordionObject={accordionObject}
          className='w-100 mb-2' key={`${keyBase}-${columnId}-scope`}
          headerClass='d-flex' accordionArrowClass='' isDecomposition={true}
        />
      })
    }
  </div>
};
export default ChartsDecompositionScope;
