const firefoxCopyToClipboard = (value) => {
  return navigator.clipboard.writeText(value);
};

export const copyFormattedText = (html) => {
  // Create container for the HTML
  const container = document.createElement('div');
  container.innerHTML = html;

  // Hide element
  container.style.position = 'fixed';
  container.style.pointerEvents = 'none';
  container.style.opacity = 0;

  // Mount the container to the DOM to make `contentWindow` available
  document.body.appendChild(container);

  // Copy to clipboard
  const range = document.createRange();
  range.selectNode(container);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Unable to copy:', err);
  }
  document.body.removeChild(container);
};

export default (value) => {
  if (navigator.clipboard.writeText) return firefoxCopyToClipboard(value);

  const dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = value;

  if (navigator.userAgent.match(/ipad|iphone/i)) {
    const range = document.createRange();
    range.selectNodeContents(dummy);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    dummy.setSelectionRange(0, 999999);
  } else {
    dummy.select();
  }
  document.execCommand('copy');
  document.body.removeChild(dummy);
}
