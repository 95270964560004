import React from 'react';
import CloseIcon from "../../../common/CloseIcon";

export default ({
                  headerText,
                  openSideBar = () => null,
                  children,
                  ...opts
               }) =>
  <div className="side-panel header px-3">
    <div className="d-flex my-auto pt-2">
      <h2>{headerText}</h2>
      <div className="d-flex ms-auto">
        <div className="mx-2 d-flex align-items-start gap-2">
          { children }
        </div>
        <CloseIcon classParams={"d-inline-block"} onClose={() => { openSideBar(false) }} {...opts} />
      </div>
    </div>
  </div>
