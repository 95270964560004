import React from 'react';
import { connect } from "react-redux";
import BaseHamburgerBtn, { BaseDropdownBtn, DeleteItemElement } from "../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import { openModal } from "../../store/modals/actions";
import { openEditNameModal } from "../modals/EditNameModal";
import { openSaveAsNewModalModal } from "../modals/SaveAsNewModal";
import { setSimulationSettingsOpen } from "../../store/sidebar/actions";
import { createDecisionTabWithScenario } from "../helpers/helpers";

export const settingsCallback = (sidebar, openSimulationSettingsSideBar) => () => openSimulationSettingsSideBar(!sidebar.simulationSettingsSidebar)
const editNameCallback = (openModal) => () => openEditNameModal({ openModal })
const saveAsNewCallback = (openModal) => () => openSaveAsNewModalModal({ openModal })

export const HamburgerDropdown = ({
                                    sidebar, forecast_simulator_scenario, processBackClick, openModal, deleteCallback, openSimulationSettingsSideBar
                                  }) => {
  return <HamburgerMenuIconBtn>
    <BaseHamburgerBtn onSelect={settingsCallback(sidebar, openSimulationSettingsSideBar)} title="Settings" />
    <BaseHamburgerBtn onSelect={saveAsNewCallback(openModal)} title="Save as a new scenario" />
    <BaseHamburgerBtn onSelect={editNameCallback(openModal)} title="Edit scenario name" />
    <BaseHamburgerBtn onSelect={() => createDecisionTabWithScenario(forecast_simulator_scenario) } title="Create a decision" />
    <BaseHamburgerBtn onSelect={() => {}} title="Generate Excel file" />
    <BaseDropdownBtn onSelect={deleteCallback}>
      <DeleteItemElement name="scenario" />
    </BaseDropdownBtn>
    <BaseHamburgerBtn onSelect={processBackClick} title="Go to homepage" />
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({ sidebar, forecast_simulator_scenario }) => ({ forecast_simulator_scenario });
export default connect(mapStateToProps, { openModal, setSimulationSettingsOpen })(HamburgerDropdown);
