import React from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import {openModal} from "../../../../store/modals/actions";

const AssignDecision = ({ treeNode, openModal }) => {
  const onAssignDecision = (e) => {
    e.preventDefault();
    openModal({ decision: treeNode.node, slug: treeNode.slug, type: 'AssignDecisionModal' })
  }

  return <Button onClick={onAssignDecision} className="btn btn-secondary btn-sm btn-sm-round text-primary me-1">
    <i className="fas fa-user fa-lg"/>
  </Button>
}

const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data))
});
export default connect(null, mapDispatchToProps)(AssignDecision);