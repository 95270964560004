import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import {loadContacts} from "../../../store/contacts/actions";
import CollaboratorsInviteRow from "./CollaboratorsInviteRow";
import {CollaboratorsPBSection} from "./PlaybookNotesSection";
import {addCollaborators, removeCollaborator} from "../../../store/template/actions";
import {Template} from "../../../models/template";
import {InputCollaboratorsField} from "../../../tree_view/side_panel/sections/CollaboratorsSection";
import {
  filterNewCollaboratorInvites
} from "../../../template_set_view/side_panel/sections/CollaboratorsSection";
import {filteredContactsCallback} from "../../../decision_set_view/side_panel/sections/InviteInput";
import {isBlank} from "../../../helpers/common";
import NAElement from "../../../tree_view/side_panel/sections/NAElement";
import {isDSightTemplate} from "../../../helpers/template_helpers";
import {resetTemplateSetData} from "../../../store/template_set/common_actions";

export const submitCollaboratorInvite = (setSubmit, invites, setInvites, addCollaborators) => {
  setSubmit(true)
  const emails = invites.join(',')
  addCollaborators({ emails }, (success) => {
    if (success) { setInvites([]) }
    setSubmit(false)
  })
}

const removeCollaboratorCallback = (slug, resetTemplateSetData, removeCollaborator) => {
  const callback = (success) => success && resetTemplateSetData()
  removeCollaborator(slug, callback)
}

export const CollaboratorsSection = ({
                                       template, playbook_notes, contactsData, current_user,
                                       loadContacts, addCollaborators, removeCollaborator,
                                       preview = false, resetTemplateSetData = ()=>{}
                                     }) => {
  if(isDSightTemplate(template)) return null;

  useEffect(() => {
    if (!contactsData.loaded || (contactsData.loaded && !contactsData.loading && !contactsData.invite_yourself)) {
      setTimeout(loadContacts(() => {}, { invite_yourself: true }), 50)
    }
  }, [contactsData.loaded, contactsData.invite_yourself])

  const [invites, setInvites] = useState([])
  const [submit, setSubmit] = useState(false)

  const templateObj = new Template(template)
  const admissions = templateObj.collaborator_admissions

  const submitInvite = () => submitCollaboratorInvite(setSubmit, invites, setInvites, addCollaborators)
  const filteredContacts = filteredContactsCallback({
    contactsData, current_user, invites,
    anotherCondition: (contact) => !admissions.some(admission => admission?.user_email === contact.value),
    excludeCurrentUser: false
  })
  const filterNewInvites = (newInvites) => filterNewCollaboratorInvites(newInvites, contactsData, templateObj)

  if (preview && isBlank(admissions)) {
    return <div className={`px-3`}>
      <NAElement />
    </div>
  }

  return <div className="px-3 pb-3">
    <CollaboratorsPBSection playbook_notes={playbook_notes} sidePanelStyle={true} showNA={!admissions?.length} />
    {
      admissions.map(admission =>
        <CollaboratorsInviteRow user={admission.user} key={`collaborator-row-${admission.slug}`}
                                removeCollaborator={() => removeCollaboratorCallback(admission.slug, resetTemplateSetData, removeCollaborator)}
                                slug={admission.slug} preview={preview} />)
    }
    {
      !preview && <InputCollaboratorsField {...{
        submit, filteredContacts, contactsData,
        invites, setInvites, filterNewInvites,
        submitInvite, isTemplate: true
      }} />
    }
  </div>
}
const mapStateToProps = ({ template, contacts, playbook_notes, current_user }) => ({
  template, playbook_notes, contactsData: contacts, current_user
});
const mapDispatchToProps = (dispatch) => ({
  loadContacts: (callback, data) => dispatch(loadContacts(callback, data)),
  addCollaborators: (data, callback) => { dispatch(addCollaborators(data, callback)) },
  removeCollaborator: (slug, callback) => { dispatch(removeCollaborator(slug, callback)) },
  resetTemplateSetData: () => { dispatch(resetTemplateSetData()) }
});
export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorsSection);
