import React from 'react';
import {hasParticipantAdmission, isDeciderReadOnly, isVisitor} from "../../../../helpers/user_helpers";
import { isBlank } from "../../../../helpers/common";
import {BorderedFilterStyle, ChoiceEntryStyle} from "../../../../tree_wizard/styles/choice_entry_styles";
import Select from "react-select";

const SelectReportSourceRow = ({ selectedSource, onSelectReport, orgReports }) =>
  <div className="existing-report-source-dropdown">
    <Select
      value={selectedSource}
      onChange={onSelectReport}
      components={{ IndicatorSeparator:() => null }}
      styles={BorderedFilterStyle}
      placeholder="Add a report"
      options={orgReports.map(report => ({
        value: report.slug,
        label: <span style={{wordBreak: 'break-word'}}>
          {report.name}
        </span>})
      )} />
  </div>

export default ({ user, fromDecisionPanel, fromRecommendationPanel, orgReportsForDetailsPanel, selectedSource, onSelectReport }) => {
  if(isVisitor(user) || hasParticipantAdmission(user) || isDeciderReadOnly(user)) return null;

  return <div className="mt-2" hidden={fromDecisionPanel || fromRecommendationPanel || isBlank(orgReportsForDetailsPanel)}>
    <div className="new-source-entries">
      <SelectReportSourceRow selectedSource={selectedSource} onSelectReport={onSelectReport}
                             orgReports={orgReportsForDetailsPanel}/>
    </div>
  </div>
}
