import React, { useCallback, useState } from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../helpers/common";
import { isCollaborator } from "../../../helpers/user_helpers"
import NAElement from "./NAElement";
import DecisionCategoriesModal from "../../modals/DecisionCategoriesModal";
import { CategoryBlockRow } from "../../../decision_categories/CategoryBlockRow";
import { decisionSelectedCategories } from "../../../models/decision";
import { CategorizationPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";
import { isDSightDecision } from "../../../helpers/home_decision_helpers";
import Button from "react-bootstrap/Button";

export const HeaderCategoriesElement = () => <h3 className={'me-auto'}>Categories</h3>

export const EditCategorySection = ({ openEditModal, hideEditIcon = false  }) =>
  !hideEditIcon && <Button onClick={openEditModal} bsPrefix="btn btn-secondary w-100 m-0 mt-2" hidden={hideEditIcon}>
    Update categories
  </Button>

export const CategoriesSection = ({ decision, playbook_notes, user, org_categories, ...opts }) => {
  if (!isCollaborator(user) && isBlank(decision.selected_categories))
    return <div className="px-3 pb-3">
      <HeaderCategoriesElement />
      <NAElement />
    </div>;

  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState(false)

  const openEditModal = useCallback(() => {
    setShowEditCategoriesModal(true)
  }, [])

  const selectedCategories = decisionSelectedCategories(org_categories, decision.selected_categories)

  return <div className="px-3 pb-3">
    <HeaderCategoriesElement />
    <CategorizationPBSection playbook_notes={playbook_notes} sidePanelStyle={true} showNA={false} {...opts} />
    <div className="d-flex">
      <div className="d-table me-auto">
        <div hidden={selectedCategories.length > 0}>
          <NAElement />
        </div>
        {selectedCategories.map(category =>
          <CategoryBlockRow category={category} selectedCategories={decision.category_options} key={`category-block-row-${category.slug}`} />
        )}
      </div>
    </div>
    <EditCategorySection hideEditIcon={!isCollaborator(user) || isDSightDecision(decision)} openEditModal={openEditModal} />
    <div className="modals">
      <DecisionCategoriesModal shown={showEditCategoriesModal} onClose={() => setShowEditCategoriesModal(false)} />
    </div>
  </div>
}
const mapStateToProps = ({ decision, playbook_notes, current_user, org_categories }) => ({
  decision, playbook_notes, user: current_user, org_categories
});
export default connect(mapStateToProps)(CategoriesSection);
