import React from 'react';
import { isBlank, sortSourceByType } from "../../../../helpers/common";
import DataSource, { IMAGE_FILE_TYPES } from "../../../../models/data_source";
import SourceItem from "../../../data_sources/Item";
import Image from "react-bootstrap/Image";

export default ({ data_sources }) =>
  isBlank(data_sources) ? null :
    <div className="px-3 my-2">
      <h3>Data sources</h3>
      <div className="d-flex flex-column" hidden={isBlank(data_sources)}>
        {sortSourceByType(data_sources, IMAGE_FILE_TYPES).map(data_source => {
          const sourceObj = new DataSource(data_source);
          return sourceObj.isImage ?
            <Image className='w-100 my-2 border'
                   key={`decision-details-data-source-${data_source.slug}-${data_source.key}`}
                   src={data_source.url} alt={data_source.title} title={data_source.title} /> :
            <SourceItem source={data_source} hideMenu={true}
                        key={`decision-details-data-source-${data_source.slug}-${data_source.key}`} />
        })}
      </div>
    </div>
