import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { hideLearningTab } from "../../helpers/home_helpers";
import { Loader } from "../../common/Loader";
import {
  isHomepageSectionLoaded,
  isHomepageSectionLoading,
  loadLearnData,
  needToLoadHomepageSection
} from "../../store/homepage/actions";
import AllDecisionsStatisticsPanel from "./learning/AllDecisionsStatisticsPanel";
import IndividualDecisionsSection from "./learning/IndividualDecisionsSection";

const LearningTab = ({
                       home, current_user,
                       loadLearnData
                     }) => {
  if (hideLearningTab(current_user)) return null;

  useEffect(() => {
    if (needToLoadHomepageSection(home, 'learning')) loadLearnData({ search: home.search })
  }, [home.learning.loaded])

  return <>
    <div className="mx-auto dsight-container">
      <div className="row" hidden={isHomepageSectionLoaded(home, 'learning')}>
        <Loader />
      </div>
      <div hidden={isHomepageSectionLoading(home, 'learning')}>
        <AllDecisionsStatisticsPanel />
        <IndividualDecisionsSection />
      </div>
    </div>
  </>
}

const mapStateToProps = ({ home, current_user }) => ({ home, current_user });
export default connect(mapStateToProps, { loadLearnData })(LearningTab);
