import React, { useRef } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { openModal } from "../../../../store/modals/actions";
import { attachEventEffect } from "../../../../helpers/html_events_helpers";

const EditDriver = ({ drivers, tree, decision, treeNode, openModal }) => {
  const ref = useRef();
  const onEditDriver = (e) => {
    e.preventDefault();
    openModal({
      decision,
      drivers: drivers,
      data_sources: tree.data_sources,
      slug: treeNode.slug,
      type: 'WizardDriverDetailsModal'
    })
  }

  //hack to attach click event after tree is initiated to fix click on selected row button
  attachEventEffect(ref, onEditDriver)

  return <Button className="btn btn-secondary btn-sm btn-sm-round me-1" ref={ref}>
    <i className="fas fa-pencil-alt fa-lg w-100" />
  </Button>
}
const mapStateToProps = ({ decision, template, tree },  { isTemplate }) => ({
  tree, decision: isTemplate ? template : decision
});
export default connect(mapStateToProps, { openModal })(EditDriver);
