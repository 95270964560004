import {
  PLAYBOOK_NOTES_LOAD_DATA,
  PLAYBOOK_NOTES_LOAD_DATA_FAILED,
  PLAYBOOK_NOTES_UPDATE_DATA,
  RESET_PLAYBOOK_NOTES
} from "./types";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import EntryPoint from "../../EntryPoint";
import { updateDecisionNotesData, updateTemplateNotesData } from "../../utils/Api";

// Template
export const updateTemplateNotes = (update_data) => (dispatch) => {
  dispatch(updateNotes(updateTemplateNotesData, update_data))
}

// Decision
export const updateDecisionNotes = (update_data) => (dispatch) => {
  dispatch(updateNotes(updateDecisionNotesData, update_data))
}

const updateNotes = (request, update_data) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance
  request({ objectSlug, update_data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadPlaybookNotesFailure }, callback);

    const { data } = response;
    const { playbook_notes } = data;

    dispatch(loadPlaybookNotesSuccess({ ...playbook_notes }));
  })
}

// Helpers
export const loadPlaybookNotesSuccess = (data) => ({
  type: PLAYBOOK_NOTES_LOAD_DATA,
  payload: {
    ...data
  }
});
export const resetPlaybookNotes = () => ({
  type: RESET_PLAYBOOK_NOTES
});
export const updatePlaybookNotesData = (data) => ({
  type: PLAYBOOK_NOTES_UPDATE_DATA,
  payload: {
    ...data
  }
});
export const loadPlaybookNotesFailure = error => ({
  type: PLAYBOOK_NOTES_LOAD_DATA_FAILED,
  payload: {
    error
  }
});
