import React from 'react';
import { isBlank, isEmail, isPresent } from "../../../helpers/common";
import { AssignToFilterStyles } from "../../../tree_wizard/styles/choice_entry_styles";
import Creatable  from 'react-select/creatable';
import {connect} from "react-redux";
import {loadContacts} from "../../../store/contacts/actions";

const defaultResponder = (responder, collaborators) => {
  if(isBlank(responder)) return null;

  const collaborator = collaborators.find((collaborator) => collaborator.email === responder);
  const label = isPresent(collaborator) ? (collaborator.full_name || collaborator.label ) : responder;
  return { value: responder, label: label }
};

const responseToItems = (responder, collaborators) => {
  if(isBlank(collaborators)) return [];

  return collaborators.map((collaborator) =>
    ({ value: collaborator.email, label: collaborator.full_name || collaborator.label })
  );
};

const AttributeResponseRow = ({collaborators, responder, onChangeResponder, onCreateResponder,
                               className = '', title = 'Attribute response to'}) => {
  if (isBlank(collaborators)) return null;

  return <div className={className}>
    <h3>{title}</h3>
    <Creatable
      isClearable
      isMulti={false}
      onCreateOption={onCreateResponder}
      isValidNewOption={isEmail}
      value={defaultResponder(responder, collaborators)}
      onChange={onChangeResponder}
      components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
      styles={AssignToFilterStyles}
      placeholder="Enter a name or email address"
      options={responseToItems(responder, collaborators)}/>
  </div>
}

const mapStateToProps = ({ contacts }) => ({ contactsData: contacts });
export default connect(mapStateToProps, {loadContacts})(AttributeResponseRow);
