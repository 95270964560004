import { FETCH_DECISION_ADMISSIONS_DATA, LOAD_FAILED } from "./types";
import { loadDecisionAdmissions } from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import { saveContactsData } from "../contacts/actions";


export const loadAdmissions = (callback = () => {}, data = {}) => (dispatch) => {
    dispatch(fetchDecisionAdmissionsData());
    loadDecisionAdmissions(data).then(response => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure });

        const { data } = response;
        const { decision_admissions } = data;
        callback(decision_admissions);
        dispatch(saveDecisionAdmissionsData({ decision_admissions, loaded: true, loading: false }));
    })
};

export const reloadAdmissions = () => (dispatch) => {
  dispatch(saveDecisionAdmissionsData({ loaded: false, loading: false }))
}

export const fetchDecisionAdmissionsData = () => ({
    type: FETCH_DECISION_ADMISSIONS_DATA,
    payload: {
        loading: true,
        loaded: false
    }
});

export const loadFailure = error => ({
  type: LOAD_FAILED,
  payload: {
    error,
    loading: false
  }
});

export const saveDecisionAdmissionsData = (payload) => ({
  type: FETCH_DECISION_ADMISSIONS_DATA,
  payload: {
    ...payload,
  }
});
