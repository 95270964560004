import React, { useMemo, useState, useEffect } from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { isBlank } from "../../helpers/common";
import { submitCategories } from "../../store/decision/actions";
import CloseIcon from "../../common/CloseIcon";
import CategoriesForm from "../../decision_categories/CategoriesForm";
import { onChangeCategoryCallback } from "../../tree_wizard/steps_wizard/steps/TreeDecisionCategorizationStep";
import { CategorizationPBSection } from "../../template_view/side_panel/sections/PlaybookNotesSection";
import { ModalDoneFooterP0 } from "../../common/modals";
import {
  fetchDefaultSortedSelectedCategories,
  fetchRequiredCategories,
  selectCategoryState
} from "../../helpers/categories_helpers";
import SelectCategory from "../../tree_wizard/helpers/SelectCategory";

export const submitCategoriesData = (updateData, submitCategories, setSubmit, onClose) => {
  setSubmit(true)
  submitCategories(updateData, success => {
    setSubmit(false)
    if (success) onClose();
  })
}

const DecisionCategoriesModal = ({decision, playbook_notes, org_categories, shown, onClose = () => {}, submitCategories}) => {
  const [categoriesData, setCategoriesData] = useState(decision.category_options)
  const {
    selectedCategories, setSelectedCategories, onSelectCategory, ...hash
  } = selectCategoryState({
    selected_categories: decision.selected_categories, org_categories, categoriesData, setCategoriesData,
  })

  const [submitState, setSubmitState] = useState(false)
  useEffect(() => {
    if (shown) resetData()
  }, [shown])

  const resetData = () => {
    setCategoriesData(decision.category_options)
    setSelectedCategories(
      fetchDefaultSortedSelectedCategories(org_categories, decision.selected_categories)
    )
  }
  const reset = () => {
    resetData()
    onClose()
  }
  const submitData = () => {
    submitCategoriesData({
      categories: categoriesData,
      selected_categories: selectedCategories
    }, submitCategories, setSubmitState, onClose)
  }

  const requiredCategories = useMemo(() => fetchRequiredCategories(org_categories, selectedCategories), [org_categories, selectedCategories])

  const disabled = () => {
    if (isBlank(requiredCategories)) return false;

    return requiredCategories.some(category => !category.options.some(option => categoriesData.includes(option.id)))
  }

  const onChangeCategory = onChangeCategoryCallback(categoriesData, setCategoriesData)

  return <Modal size="md" backdrop="static" show={shown} onHide={reset}>
    <Modal.Body bsPrefix="modal-body categories-modal">
      <div className="d-flex w-100 pb-0">
        <h2 className="me-auto">Categories</h2>
        <CloseIcon onClose={reset} />
      </div>
      <SelectCategory selected={selectedCategories}
                      onSelect={onSelectCategory}
                      submitState={submitState} />
      <CategorizationPBSection classParams={'mb-3 pt-0'} playbook_notes={playbook_notes} sidePanelStyle={true} showNA={false} isDecision={true}/>
      <CategoriesForm className={'w-100 mb-3 mt-3'}
                      {...{
                        ...hash, categoriesData, submitState, selectedCategories, onChangeCategory
                      }}
      />
    </Modal.Body>
    <ModalDoneFooterP0 disabled={disabled() || submitState} onClose={submitData} />
  </Modal>
}
const mapStateToProps = ({ decision, playbook_notes, org_categories }) => ({ decision, playbook_notes, org_categories });
export default connect(mapStateToProps, { submitCategories })(DecisionCategoriesModal);
