export const flow = {
  TREE: 'tree',
  TREE_RECOMMENDATION: 'tree_recommendation',
  TREE_HISTORICAL: 'tree_historical',
  D_SIGHT: 'd_sight',
  D_SIGHT_RECOMMENDATION: 'd_sight_recommendation',
  D_SIGHT_HISTORICAL: 'd_sight_historical'
}

export const LEGACY_FLOWS = ['initial', 'quick_decide', 'super_vote', 'enhanced_framing', 'risk_benefit',
  'guided_discussion', 'quick_record', 'quick_poll', 'custom']
export const SLACK_FLOWS = ['slack_announce', 'slack_approve', 'slack_poll', 'slack_track']
export const MS_TEAMS_FLOWS = ['teams_announce', 'teams_approve', 'teams_poll', 'teams_track']
export const LEGACY_SLACK_MS_TEAMS_FLOWS = LEGACY_FLOWS.concat(SLACK_FLOWS, MS_TEAMS_FLOWS)