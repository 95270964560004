import React, { useState } from "react";
import { connect } from "react-redux";
import DecisionDate, { DATEPICKER_TYPES } from "../../../common/DecisionDate";
import { updateRecordDecision } from "../../../store/sidebar/actions";
import { isBlank, isPresent } from "../../../helpers/common";
import * as moment from "moment";

const DecisionDateInput = ({ decision, updateRecordDecision }) => {
  if (isBlank(decision)) return null;

  const initDate = isPresent(decision.historical_decided_at) ? moment(decision.historical_decided_at) : null;
  const [decisionDate, setDecisionDate] = useState(initDate || moment());

  const onChangeDate = (date) => {
    setDecisionDate(moment(date))
    updateRecordDecision({ historical_decided_at: isPresent(date) ? moment(date).toString() : null });
  };

  return <div className="w-100 mb-3 px-3">
    <DecisionDate id={DATEPICKER_TYPES.historical_decided_at}
                  name={DATEPICKER_TYPES.historical_decided_at}
                  maxDate={new Date()}
                  header="Decision date"
                  date={decisionDate} onChangeDate={onChangeDate}/>
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecordDecision })(DecisionDateInput);