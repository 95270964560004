import React from 'react';
import { connect } from "react-redux";
import { setMoreActionsSidebarOpen } from "../../store/sidebar/actions";

const MoreActionsItem = ({ sidebar, openSideBar }) =>
  <li className={sidebar.moreActionsSidebar ? 'active' : ''}>
    <div className="side-row text-nowrap pointer"
         onClick={() => openSideBar(!sidebar.moreActionsSidebar)}>
      <span className="icon d-inline-block text-center ">
        <i className="fas fa-wrench fa-lg"/>
      </span>
      <span className="d-none d-lg-inline tab-title">More actions</span>
    </div>
  </li>

const mapStateToProps = ({sidebar}) => ({sidebar});
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (value) => dispatch(setMoreActionsSidebarOpen(value))
});
export default connect(mapStateToProps, mapDispatchToProps)(MoreActionsItem);
