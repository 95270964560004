import React, { useEffect, useState } from 'react';
import { flow } from "../../../helpers/decision_flows";

export default ({ hidden = false, treeEnum, setTreeEnum, classNames = 'mt-2', submitted = false }) => {
  const [checked, setChecked] = useState(treeEnum === flow.TREE_RECOMMENDATION)

  useEffect(() => {
    if (submitted) return;

    setChecked(treeEnum === flow.TREE_RECOMMENDATION)
  }, [treeEnum])

  return <div className={`w-100 ${classNames} lh-checkbox`} hidden={hidden}>
    <input type="checkbox" id="include-reccomendation-checkbox" name="include-reccomendation-checkbox"
           className="custom-checkbox"
           checked={checked} disabled={submitted}
           onChange={() => { setTreeEnum(checked ? flow.TREE : flow.TREE_RECOMMENDATION) }}/>
    <label htmlFor="include-reccomendation-checkbox" className="mb-0">Include a recommendation step</label>
  </div>
}

