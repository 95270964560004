import React, {useMemo, useState} from 'react';
import { connect } from "react-redux";
import Decision from "../../../models/decision";
import { Accordion, Card, Table } from 'react-bootstrap'
import { ChoiceWeights } from "../../../models/DriverChoiceRating";
import { RatingOverlayTrigger } from "../helpers/RatingOverlayTrigger";
import {isDeciderReadOnly, isVisitor} from "../../../helpers/user_helpers";
import { setDriverInputSidebarOpen} from "../../../store/sidebar/actions";
import { openModal } from "../../../store/modals/actions";
import CustomHeaderToggle from "../CustomHeaderToggle";
import { toggleClickAction, openAllActionUseEffect, setAccordionDropdownToggleState } from "../helpers/accordion_helpers";

const CustomToggle = ({ obj, children, eventKey, openIndexes, setOpenIndexes }) => {
  const [openState, setOpenState] = useState(false);

  setAccordionDropdownToggleState(openIndexes, eventKey, {setOpenState})

  return (
    <div onClick={() => toggleClickAction(openIndexes, eventKey, {setOpenIndexes})}
         className="d-flex fw-bolder border-0 align-items-baseline">
      <div className="note_paragraph text-ellipsis me-1">{children}</div>
      <div className={`dropdown-toggle ms-auto ${openState ? 'rotate-after-180' : ''}`}>
        {obj.weightedRatingScore}
      </div>
    </div>
  );
}

const WeightedChoiceRow = ({
                             decision, choice, keyDrivers, current_user, tree, openModal, setDriverInputSidebarOpen,
                             openIndexes, setOpenIndexes
}) => {
  const obj = new ChoiceWeights(choice, keyDrivers);
  const allowEdit = !isDeciderReadOnly(current_user) && !isVisitor(current_user);

  const showDriverDetailsModal = (rating) => {
    if (rating.isAnswered) {
      openModal({
        decision: decision,
        drivers: tree.drivers,
        data_sources: tree.data_sources,
        slug: rating.driverSlug,
        type: 'DriverShowModal'
      })
    }
    else if (allowEdit) {
      setDriverInputSidebarOpen(rating.driverSlug)
    }
  };

  return <div className='mb-2'>
    <Accordion>
      <Card className="border-0">
        <Card.Header className="bg-white border-0 pointer p-0 mb-1">
          <CustomToggle eventKey={choice.slug} {...{obj, openIndexes, setOpenIndexes}}>
            {choice.description}
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={choice.slug} className={`${openIndexes.includes(choice.slug) ? 'show' : ''}`}>
          <Card.Body className="p-0">
            <Table borderless className="mb-0">
              <thead>
              <tr>
                <th className="col-10 fw-bolder text-gray p-0 pe-2">Driver</th>
                <th className="col-1 fw-bolder text-gray p-0 pe-2 text-end">Weight</th>
                <th className="col-1 fw-bolder text-gray p-0 text-end">Rating</th>
              </tr>
              </thead>
              <tbody className="note_paragraph">
              {
                obj.ratings.map(rating =>
                  <tr key={`choice-weights-${choice.slug}-driver-${rating.driverSlug}`}>
                    <td className={`p-0 pe-2 ${allowEdit ? 'pointer' : 'no-pointer'}`} onClick={() => showDriverDetailsModal(rating)}>
                      <span className={`button me-1 ${rating.isAnswered ? 'answered' : 'non-answered'}`} />
                      <span>{rating.driverQuestion}</span>
                    </td>
                    <td className="p-0 pe-2 text-end">
                      {rating.driverWeight}%
                    </td>
                    <td className="p-0 text-end">
                      {
                        rating.isAnswered ?
                          <RatingOverlayTrigger rating={rating} />:
                          <span className="text-muted">N/A</span>
                      }
                    </td>
                  </tr>
                )
              }
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </div>
}

const WeightedRatingOfChoicesSection = ({ decision, drivers, ...opts }) => {
  const decisionObj = useMemo(() => new Decision(decision, drivers), [decision, drivers]);

  const [openAll, setOpenAll] = useState(false);
  const [openIndexes, setOpenIndexes] = useState([]);
  const keyDrivers =  useMemo(() => decisionObj.keyDrivers, [decisionObj]);
  const allRateChoicesSlugs = useMemo(() => decisionObj.sortedRatedChoices.map((rateChoiceData) => rateChoiceData.slug), [decisionObj]);

  openAllActionUseEffect(openAll, openIndexes, allRateChoicesSlugs, {setOpenIndexes, setOpenAll});
  if (decisionObj.choicesOrRecommendations(true).length === 0) return null;

  return <div className="px-3 mb-3 border-bottom">
    <CustomHeaderToggle {...{openAll, setOpenAll}}>
      <h3>Weighted rating of choices</h3>
    </CustomHeaderToggle>
    {
      decisionObj.sortedRatedChoices.map(choice =>
        <WeightedChoiceRow key={`choice-row-${choice.slug}`} {...{choice, keyDrivers, decision, openIndexes, setOpenIndexes}} {...opts}/>
      )
    }
  </div>
};

const mapStateToProps = ({ decision, template, tree, current_user }, { isTemplate = false }) => ({
  drivers: tree.drivers,
  decision: isTemplate ? template : decision, tree, current_user
});
const mapDispatchToProps = (dispatch) => ({
  setDriverInputSidebarOpen: (driverSlug) => {
    dispatch(setDriverInputSidebarOpen(true, driverSlug))
  },
  openModal: (data) => dispatch(openModal(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(WeightedRatingOfChoicesSection);
