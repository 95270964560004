import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {
  BASE_CHARTS_OPTIONS,
  SERIES_NAME_ROW
} from "../../../models/d_sight/metric_chart_helpers";
import { isPresent } from "../../../helpers/common";

import { X_AXIS, Y_AXIS, Y_AXIS_LABEL, TOOLTIP } from "./chart_helpers"

const BASE_LINE_CHART_OPTIONS = {
  ...BASE_CHARTS_OPTIONS,
  chart: {
    type: 'line',
    style: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },
  legend: {
    align: 'right',
    verticalAlign: 'middle',
    layout: 'vertical',
    itemStyle: {
      color: '#1F2F3F',
      fontWeight: '500',
      fontSize: '0.875rem'
    }
  },
  plotOptions: {
    series: {
      lineWidth: 3
    }
  }
}

const LineChart = ({
                     title, measure, categories, series, decimal = 0
                   }) => {
  const chartOptions = {
    ...BASE_LINE_CHART_OPTIONS,
    yAxis: [{
      ...Y_AXIS,
      labels: {
        ...Y_AXIS_LABEL,
        format: ['{value}', measure].join('')
      },
      title: {
        text: [title, measure].filter(isPresent).join(', ')
      }
    }],
    tooltip: {
      ...TOOLTIP,
      pointFormat: ['<tr>', SERIES_NAME_ROW, '<td style="padding:0"><b>{point.y:.', decimal, 'f}', measure, '</b></td></tr>'].join('')
    },
    xAxis: {
      ...X_AXIS,
      categories
    },
    series
  };

  return <>
    <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={{ className: "forecast-chart-container" }} />
  </>
}
export default LineChart;
