import React from 'react';
import { Document, PDFViewer } from '@react-pdf/renderer';
import TreeDocument from "./document";

export const PdfTreeViewDocument = ({ decision, document_options = {}, ...opts }) =>
  <Document title={`Pdf report ${decision.description}`} {...document_options}>
    <TreeDocument decision={decision} {...opts}/>
  </Document>

const PdfTreeView = (options) =>
  <PDFViewer>
    <PdfTreeViewDocument {...options} />
  </PDFViewer>

export default PdfTreeView;
