import React, { useEffect } from 'react';
import Select from 'react-select';
import { BorderedFilterStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import { isBlank, isPresent } from "../../../helpers/common";
import { DEFAULT_DRIVER_TYPE_DATA } from "../../../models/driver";

const DEFAULT_TYPE = {
  value: DEFAULT_DRIVER_TYPE_DATA.slug,
  label: DEFAULT_DRIVER_TYPE_DATA.name
};

const defaultDriverType = (driverTypeSlug, availableDriverTypes) => {
  if (isBlank(driverTypeSlug)) return DEFAULT_TYPE;

  const driverType = availableDriverTypes.find((driverType) => driverType.slug === driverTypeSlug);
  if (isBlank(driverType)) return null;

  return { value: driverType.slug, label: driverType.name }
};

const isDefaultTypeInList = (orgDriverTypes) =>
  isPresent(orgDriverTypes.available_types.find(({ slug, name }) =>
    slug === DEFAULT_DRIVER_TYPE_DATA.slug && name === DEFAULT_DRIVER_TYPE_DATA.name
  ))

export const useDefaultDriverTypesEffect = (orgDriverTypes) => {
  useEffect(() => {
    if (!isDefaultTypeInList(orgDriverTypes)) {
      orgDriverTypes.available_types.unshift(DEFAULT_DRIVER_TYPE_DATA)
    }
  }, [orgDriverTypes.available_types]);
}

export default ({
                  isDSight,
                  driverTypeSlug, availableDriverTypes,
                  onChangeDriverType = (_) => {}
                }) => {
  if (isDSight) return null;

  return <div className="mt-3 mb-0">
    <h3>Driver type</h3>
    <Select
      defaultValue={defaultDriverType(driverTypeSlug, availableDriverTypes)}
      onChange={onChangeDriverType}
      components={{ IndicatorSeparator: () => null }}
      styles={BorderedFilterStyle}
      placeholder="Make a selection"
      options={availableDriverTypes.map((driverType) => ({ value: driverType.slug, label: driverType.name }))} />
  </div>
};
