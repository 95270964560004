const CANCELED_UPLOADS_STORAGE_KEY = 'canceled_uploads';
const FINISHED_UPLOADS_STORAGE_KEY = 'finished_uploads';
const UPLOAD_PROGRESS_STORAGE_KEY = 'upload_progress';

export const MAX_PROGRESS = 100;

export const canceledUploads = () => JSON.parse(localStorage.getItem(CANCELED_UPLOADS_STORAGE_KEY)) || []

export const addCanceledUploads = (canceledSource) =>
  localStorage.setItem(CANCELED_UPLOADS_STORAGE_KEY, JSON.stringify([...canceledUploads(), canceledSource.slug]));

export const resetCanceledUploads = () => localStorage.removeItem(CANCELED_UPLOADS_STORAGE_KEY);

export const finishedUploads = () => JSON.parse(localStorage.getItem(FINISHED_UPLOADS_STORAGE_KEY)) || []

export const addFinishedUploads = (finishedSource) =>
  localStorage.setItem(FINISHED_UPLOADS_STORAGE_KEY, JSON.stringify([...finishedUploads(), finishedSource.slug]));

export const resetFinishedUploads = () => localStorage.removeItem(FINISHED_UPLOADS_STORAGE_KEY);

export const resetUploadProgress = () => localStorage.removeItem(UPLOAD_PROGRESS_STORAGE_KEY);

export const uploadsWithProgress = () => JSON.parse(localStorage.getItem(UPLOAD_PROGRESS_STORAGE_KEY)) || []

export const uploadProgress = (source) => uploadsWithProgress().find((uploadData) => uploadData.slug === source.slug)?.progress || MAX_PROGRESS

export const updateUploadProgress = (source, progress) => {
  const filteredUploads = uploadsWithProgress().filter((uploadData) => uploadData.slug !== source.slug)
  localStorage.setItem(UPLOAD_PROGRESS_STORAGE_KEY, JSON.stringify([...filteredUploads, { slug: source.slug, progress: progress }]));
}

export const saveUploads = (allSources, saveUploadingSources) => {
  const newUploadingSources = allSources.filter((sourceData) => {
    return !canceledUploads().includes(sourceData.uploadingSource.slug) && !finishedUploads().includes(sourceData.uploadingSource.slug)
  }).map((sourceData) => {
    sourceData.uploadingSource.progress = uploadProgress(sourceData.uploadingSource)
    return sourceData
  })
  saveUploadingSources([...newUploadingSources])
}
