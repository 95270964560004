import React from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../helpers/common";
import SourceItem from "../../data_sources/Item";
import NAElement from "./NAElement";
import { TemplateOrDSightDataSourcesTypes } from "../../../models/data_source";
import { decisionDataSources } from "../../../models/decision";
import { DataSourcesPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";
import { isPresent } from "../../../../ms_teams/helpers/common";

export const DataSourcesSection = ({ template, data_sources, playbook_notes }) => {
  if (isBlank(data_sources) && isBlank(playbook_notes.data_sources))
    return <div className="px-3 pb-3">
      <NAElement />
    </div>

  else if (isBlank(data_sources) && isPresent(playbook_notes.data_sources))
    return <div className="px-3 pb-3">
      <DataSourcesPBSection playbook_notes={playbook_notes} sidePanelStyle={true} showNA={!data_sources?.length} />
    </div>

  return <div className="px-3 pb-3">
    <DataSourcesPBSection playbook_notes={playbook_notes} sidePanelStyle={true} showNA={!data_sources?.length} />
    <div className="d-flex flex-column" hidden={isBlank(data_sources)}>
      { data_sources.map(data_source =>
          <SourceItem source={data_source} key={`decision-details-data-source-${data_source.slug}-${data_source.key}`} />
      )}
    </div>
  </div>
};
const mapStateToProps = ({ template, tree, playbook_notes }) => {
  const sortedRelatedDataSources = decisionDataSources(tree.data_sources || [], tree.drivers, TemplateOrDSightDataSourcesTypes)
  return {
    template, playbook_notes,
    data_sources: sortedRelatedDataSources
  }
};
export default connect(mapStateToProps)(DataSourcesSection);
