import React, { useState, useEffect, useMemo } from 'react';
import Modal from "react-bootstrap/Modal";
import { ModalDoneFooter } from "../../common/modals";
import { RatingSlider } from "../side_panel/helpers/CustomSlider"
import ModalHeaderTitle from "../../common/ModalHeaderTitle";
import { sortKeyDrivers } from "../../tree_wizard/side_panel/sections/helpers";

const EditDriverWeightsModal = ({ shown, decisionObj, onClose, onSubmit }) => {
  const obj = useMemo(() => decisionObj.cloneObj, [shown]);
  const keyDrivers = useMemo(() => obj.keyDrivers.map(d => d.cloneObj), [shown]);
  obj.keyDrivers = keyDrivers

  const sortedKeyDrivers = useMemo(() => sortKeyDrivers(keyDrivers), [keyDrivers]);

  const [weights, setWeights] = useState({})
  useEffect(() => {
    setWeights(
      keyDrivers.reduce((res, driver) => {
        res[driver.slug] = driver.weightScore
        return res;
      }, {})
    )
  }, [obj.decision, obj.decision.drivers])

  const updateDriverWeight = (slug, newScore) => {
    const newData = {...weights}
    newData[slug] = newScore
    keyDrivers.forEach(driver => {
      if (driver.slug === slug) {
        driver.weightScore = newScore
      }
    })
    setWeights(newData)
  }
  const submit = () => {
    const weightsData = keyDrivers.map(driver =>
      ({ slug: driver.slug, weight: weights[driver.slug]  })
    )
    onSubmit({ weights: weightsData })
    onClose();
  }
  return <Modal size="lg" backdrop="static" show={shown} onHide={onClose}>
    <Modal.Body>
      <ModalHeaderTitle {...{ onClose, title: 'Driver weights' }} />
      <div className="rating-slider-container">
        {
          sortedKeyDrivers.map(driver =>
            <RatingSlider {...{
              sliderTitle: `${driver.weightPercentage}% ${driver.question}`,
              isSubDriver: false,
              showResetButton: false,
              rating: weights[driver.slug] || 0,
              setRating: (newScore) => updateDriverWeight(driver.slug, newScore),
              key: `driver-weight-${driver.slug}`
            }} />
          )
        }
      </div>

    </Modal.Body>
    <ModalDoneFooter onClose={submit} />
  </Modal>
}
export default EditDriverWeightsModal;
