import React from 'react';
import { connect } from "react-redux";
import { updateDecisionSet } from "../../../store/decision_set/actions";
import DecisionSet, { OBJECT_SEQUENCES } from "../../../models/decision_set";

const SEQUENCES = [
  { value: OBJECT_SEQUENCES.sequence_none, label: 'No numbers' },
  { value: OBJECT_SEQUENCES.sequence_all, label: 'Number all' },
  { value: OBJECT_SEQUENCES.per_section, label: 'Number per section' }
]

export const SequenceSection = ({
                                  current_user,
                                  set, updateSet,
                                  isTemplateSet = false, preview = false
                                }) => {
  if (isTemplateSet) {
    if (preview) return null;
  } else {
    const decisionSetObj = new DecisionSet(set, current_user)
    if (!decisionSetObj.isManager) return null;
  }

  const setSequence = (objects_sequence) => {
    if (objects_sequence === set.objects_sequence) return false;

    updateSet({ objects_sequence })
  }

  return <div className="px-3 pb-3">
    <h3>Sequence decisions</h3>
    <ul className="nav nav-pills nav-fill bg-light-gray text-dark decision-sequence-pills user-notifiers rounded">
      {
        SEQUENCES.map(({ value, label }) =>
          <li className="nav-item" key={value}>
            <a className={`nav-link text-dark ${value === set.objects_sequence ? 'bg-white' : 'pointer'}`}
               onClick={() => setSequence(value)}>
              {label}
            </a>
          </li>
        )
      }
    </ul>
  </div>
}
const mapStateToProps = ({ current_user, decision_set }) => ({ set: decision_set, current_user });
export default connect(mapStateToProps, { updateSet: updateDecisionSet })(SequenceSection);
