import React from "react";
import { connect } from "react-redux";
import { updateViewOptions } from "../../../store/forecast_simulator_scenario/actions";
import Toggle from "../../../common/Toggle";

export const CHART_TYPES = {
  line: 'line',
  column: 'column'
}

const RENDER_CHART_TYPES = [
  {
    value: CHART_TYPES.line,
    body: 'Line'
  },
  {
    value: CHART_TYPES.column,
    body: 'Column'
  },
]

const SimulatedType = ({
                         chartType, setChartType,
                         updateViewOptions,
                         loading = false
                       }) => {
  const onChange = (chartType) => {
    updateViewOptions({ chartType }, status => {
      if(status) setChartType(chartType);
    }, false)
  }
  return <>
    <div style={{width: '141px'}}>
      <div className="mb-1">Chart type</div>
      <Toggle items={RENDER_CHART_TYPES} selectedItem={chartType} onClick={onChange} padding={'px-2'} disabled={loading}/>
    </div>
  </>;
}
const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, { updateViewOptions })(SimulatedType);
