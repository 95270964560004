import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Node from './Node';
import Analysis from "../../../../models/d_sight/Analysis";

export const METRICS_NODE = 'metrics';

const TreeDetails = ({ analysis, currentTreeNode, onClick }) =>
  <section className={`bg-white ps-2 pe-5 py-3`}>
    <ul className="tree mb-2 ms-0">
      <Node currentTreeNode={currentTreeNode} analysisObject={new Analysis(analysis)} topNode={true} onClick={onClick}/>
    </ul>
    <div hidden={currentTreeNode === METRICS_NODE} className="ps-1">
      <Link to={new Analysis(analysis).allMetricsLink} onClick={() => onClick()} className="fw-bolder">Go to metrics</Link>
    </div>
  </section>

const mapStateToProps = ({ analysis }) => ({ analysis });
export default connect(mapStateToProps)(TreeDetails);
