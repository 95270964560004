import React, { useMemo, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { setSimulationSettingsOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import { isMobile } from "react-device-detect";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import ChartsDecompositionScope from "./settings/ChartsDecompositionScope"
import TimeScaleSelector from "./settings/TimeScaleSelector";
import PeriodSelectors from "./settings/PeriodSelectors";
import {
  fetchAgGridRows,
  updateScenario,
  updateScenarioData,
  updateViewOptions
} from "../../store/forecast_simulator_scenario/actions";
import DefaultToButton from "./settings/DefaultToButton";
import { Loader } from "../../common/Loader";
import UpdateButton from "./settings/UpdateButton"
import { TIME_SCALES } from "../helpers/ag_grid_vars";
import {cmuScopes, DEFAULT_SCOPES} from "../helpers/scopes_helpers";
import { isBlank, isPresent } from "../../helpers/common";
import { removeAllComparisonRows } from "../helpers/FactsCellRenderer";
import { showOverlayWithMessage, MESSAGES, hideOverlay } from "../helpers/custom_loading_overlay";
import { TABS } from "../index";
import { checkAgGridRowsSynced } from "../../utils/Api";

const SimulationSettingsPanel = ({ activeTab,
                                   updateViewOptions, sidebar, openSideBar, forecast_simulator_scenario,
                                   config, forecastScenario, gridRef, updateScenario, updateScenarioData, fetchAgGridRows
                                 }) => {
  if(!forecast_simulator_scenario.scenario_loaded) return null;

  const prevTimeScale = forecastScenario.viewOptions.timeScale || TIME_SCALES[0];
  const [timeScale, setTimeScale] = useState(prevTimeScale);
  const defaultFrom = config.periodOptions(prevTimeScale)[0].start_date;
  const defaultTo = config.periodOptions(prevTimeScale).slice(-1)[0].end_date;
  const prevFrom = forecastScenario.viewOptions.from;
  const prevTo = forecastScenario.viewOptions.to;
  const prevDefaultScope = forecastScenario.viewOptions.defaultScope;
  const prevScopes = forecastScenario.viewOptions.scopes;
  const [from, setFrom] = useState(prevFrom);
  const [to, setTo] = useState(prevTo);
  const [disabled, setDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { defaultScope, scopes, onChangeDefaultScope, updateScope } = cmuScopes(config, forecastScenario, prevScopes);
  const activeTableTab = useMemo(() => activeTab === TABS.table, [activeTab]);

  useEffect(() => {
    if (isBlank(forecastScenario.viewOptions.simulated)) {
      onChangeDefaultScope(DEFAULT_SCOPES.visible)
    }
  }, [forecastScenario.viewOptions.simulated]);

  const dataChanged = () => {
    return timeScaleChanged() ||
      prevFrom !== from ||
      prevTo !== to ||
      defaultScope !== prevDefaultScope ||
      scopes !== prevScopes;
  }

  const tableDataChanged = () => {
    return timeScaleChanged() || prevFrom !== from || prevTo !== to;
  }

  const timeScaleChanged = () => {
    return prevTimeScale !== timeScale;
  }

  const resetOpenedGroups = () => {
    updateScenario(forecast_simulator_scenario.scenario_id, { update_data: { opened_groups: [] } }, (status) => {
      if (status) {
        const openedGroups = forecastScenario.openedGroups || []
        removeAllComparisonRows(openedGroups, gridRef);
      }
    })
  }

  const runSubmitAction = () => {
    openSideBar(false);
    if(dataChanged()) {
      if (tableDataChanged())
        showOverlayWithMessage(gridRef.current?.api, updateScenarioData, MESSAGES.updating_scenario);
      updateViewOptions({ timeScale, from, to, defaultScope, scopes }, (status) => {
        if(status) {
          // Solution to prevent empty cells on comparison rows when change time scale period(from, to)
          if(tableDataChanged()) resetOpenedGroups();
          // To hide extra "Simulated" rows of opened groups
          if(prevTimeScale !== timeScale) {
            gridRef.current?.api?.refreshClientSideRowModel();
          }
          hideOverlay(gridRef?.current?.api);
        } else {
          hideOverlay(gridRef?.current?.api);
        }
        setDisabled(false);
      })
    }
  }

  const onSubmit = () => {
    setShowLoader(false);
    setDisabled(true);
    if(activeTableTab && timeScaleChanged()) {
      checkAgGridRowsSynced(forecast_simulator_scenario.scenario_id).then((response) => {
        const status = response.data['status'] === 'ok';
        const currentAgGridRowsDataSyncedAt = forecastScenario.agGridRowsDataSyncedAt;
        const agGridRowsDataSyncedAt = response.data['ag_grid_rows_data_synced_at'];
        if(status) {
          if(currentAgGridRowsDataSyncedAt === agGridRowsDataSyncedAt) {
            runSubmitAction()
          } else {
            fetchAgGridRows(forecast_simulator_scenario.scenario_id, (status) => {
              if(status) {
                runSubmitAction();
              } else {
                setDisabled(false);
              }
            })
          }
        } else {
          showOverlayWithMessage(gridRef.current?.api, updateScenarioData, MESSAGES.syncing_scenario);
          setTimeout(() => hideOverlay(gridRef.current?.api), 3000);
          setDisabled(false);
        }
      });
    } else {
      runSubmitAction();
    }
  }

  const presetTimeScales = () => {
    setFrom(prevFrom || defaultFrom)
    setTo(prevTo || defaultTo)
    setTimeScale(prevTimeScale)
  }

  const changeTimeScale = (value) => {
    setTimeScale(value)
    setFrom(defaultFrom);
    setTo(defaultTo);
  }

  return <SlidingPanel
    type="left"
    isOpen={sidebar.simulationSettingsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper simulator-settings-panel-wrapper tree-page ms-0 ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content d-flex flex-column h-100`}
    size={52}
    onOpening={presetTimeScales}
  >
    <PanelHeader headerName={'Simulation settings'} openSideBar={openSideBar} preview={true} />
    <TimeScaleSelector {...{ timeScale, changeTimeScale, options: config.timeScalesOptions }} />
    <PeriodSelectors  {...{ to, setTo, from, setFrom, options: config.periodOptions(timeScale) }} />
    <ChartsDecompositionScope {...{ forecastConfig: config, updateScope, scopes, keyBase: 'simulation-settings-panel', scenarioCmus: forecast_simulator_scenario.cmus }} />
    <DefaultToButton {...{defaultScope, setDefaultScope: onChangeDefaultScope, disabled: isBlank(forecastScenario.viewOptions.simulated)}} />
    {disabled || showLoader ? <Loader /> : null}
    <UpdateButton {...{onSubmit, disabled: disabled || showLoader, setShowLoader}}/>
  </SlidingPanel>
}

const mapStateToProps = ({ sidebar, forecast_simulator_scenario }) => ({ sidebar, forecast_simulator_scenario });
const mapDispatchToProps = (dispatch) => ({
  updateViewOptions: (params, callback) => dispatch(updateViewOptions(params, callback)),
  openSideBar: (flag) => dispatch(setSimulationSettingsOpen(flag)),
  updateScenario: (scenario_id, updateData, callback) =>
    dispatch(updateScenario(scenario_id, updateData, callback, false)),
  updateScenarioData: (data) => dispatch(updateScenarioData(data)),
  fetchAgGridRows: (scenario_id, callback) => dispatch(fetchAgGridRows(scenario_id, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(SimulationSettingsPanel);
