import React from 'react';
import { connect } from "react-redux";
import {openModal, updateDecision} from "../../../store/modals/actions";
import { BaseDropdownBtn } from "../../../common/BaseHamburgerBtn";
import { IconBtnDropdown } from "../../../common/dropdowns";
import Alerts from "../../../../js/alerts";
import {openDecisionPollModal} from "../../modals/DecisionPollModal";
import {setPollPanelOpen} from "../../../store/sidebar/actions";

const removePoll = (updateDecision, setPollPanelOpen) => {
  Alerts.warning({
    title: `Are you sure you would like to delete the decision poll?`,
  }).then(confirmed => {
    if (confirmed) {
      const callback = (success) => {
        success && setPollPanelOpen(false)
      }
      updateDecision({template: {reset_votes: true}}, callback)
    }
  }, () => {})
}

const PollMoreActionsSection = ({decision, openModal, hidden, updateDecision, setPollPanelOpen, className = ''}) => {
  const editPoll = () => {
    openDecisionPollModal({ openModal, type: 'DecisionPollModal', decision });
  }

  return hidden ? <></>:
    <div className={className}>
      <IconBtnDropdown id={'PollMoreActionsSection'}>
        <BaseDropdownBtn onSelect={editPoll} >
          Edit decision poll
        </BaseDropdownBtn>
        <BaseDropdownBtn onSelect={() => removePoll(updateDecision, setPollPanelOpen)} bsPrefix="text-danger">
          Delete decision poll
        </BaseDropdownBtn>
      </IconBtnDropdown>
    </div>

}
const mapStateToProps = ({ decision }) => ({ decision });
export default connect(mapStateToProps, {openModal, updateDecision, setPollPanelOpen})(PollMoreActionsSection);
