import React from "react";
import {isBlank, isPresent, qSortArray} from "../../../../helpers/common";
import Decision from "../../../../models/decision";
import OpenEndedChoices from "../../../../tree_view/side_panel/decision_input/choices/OpenEndedChoices";
import {updateChoicesWithoutSave} from "../../../../tree_view/side_panel/driver_input/choices/ChoiceItem";
import {
  DisplayChoiceItem, PickListChoiceItem,
} from "../../../../tree_view/side_panel/decision_input/choices/ChoiceItem";
import {arrayMoveImmutable} from "array-move";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {sortChoicesByCreatedAt, sortRankedChoices} from "../../../../models/decision_choices";

const generateChoiceItemState = ({ choice, selected, setChoices, decisionObj, choices }) => {
  const isTopChoice = decisionObj.isTopChoice
  const isRankChoice = decisionObj.isRankedList

  return {
    onSelectChoice: () => {
      updateChoicesWithoutSave(setChoices, isTopChoice, choice, selected, isRankChoice, choices)
    }
  };
};

const ChoiceItem = ({ choice, selected = false, setChoices = () => {}, decisionObj, choices, namePrefix='' }) => {
  const { ...choiceProps } = generateChoiceItemState({choice, selected, setChoices, decisionObj, choices })

  if(decisionObj.isPicky) {
    return <PickListChoiceItem choice={choice} selected={selected} boxType="checkbox" namePrefix={namePrefix} {...choiceProps} />
  } else if(decisionObj.isTopChoice) {
    return <PickListChoiceItem choice={choice} selected={selected} boxType="radio" namePrefix={namePrefix} {...choiceProps} />
  } {
    return <DisplayChoiceItem choice={choice} selected={selected} {...choiceProps} />;
  }
};

const NotRankedChoices = ({choices, setChoices, decisionObj, namePrefix=''}) =>
  <div>
    {
      choices.map((choice) =>
        <ChoiceItem choice={choice} key={`picky-choice-${choice.slug}`} selected={choice.final_decision}
                    decisionObj={decisionObj} setChoices={setChoices} choices={choices} namePrefix={namePrefix}
        />
      )
    }
  </div>

export const sortedRatedRankedChoices = (choices) => {
  const rankedChoices = choices.filter(choice => choice.final_decision).sort(sortRankedChoices);
  const notRankedChoices = qSortArray(choices.filter(choice => !choice.final_decision), true, sortChoicesByCreatedAt).map(choice => ({ ...choice, final_decision_rank: null }));
  const rankedChoicesWithRank = rankedChoices.map((choice, index) => ({ ...choice, final_decision_rank: index + 1 }));

  return [...rankedChoicesWithRank, ...notRankedChoices];
}

const RankedChoices = ({choices, setChoices, decisionObj}) => {
  const rankChoices = sortedRatedRankedChoices(choices)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const sortedChoicesImmutable = arrayMoveImmutable(rankChoices, oldIndex, newIndex)
    const sortedChoices = sortedChoicesImmutable.map((choice, index) => ({
      ...choice,
      order_rank: index,
      final_decision_rank: index + 1,
      final_decision: index === newIndex ? true : choice.final_decision
    }));
    setChoices(sortedChoices)
  };

  return <SortableList choices={rankChoices} onSortEnd={onSortEnd} helperClass="on-modal" distance={1}
                       setChoices={setChoices} decisionObj={decisionObj} />;
};

const SortableList = SortableContainer(({choices, setChoices, decisionObj}) => {
  return <div>
    {choices.map((choice, index) => (
      <SortableItem key={`item-${choice.slug}`} index={index} choice={choice} setChoices={setChoices} decisionObj={decisionObj} choices={choices} />
    ))}
  </div>
});

const SortableItem = SortableElement(({choice, decisionObj, setChoices, choices}) => {
  return <div className="choice-item">
    <ChoiceItem choice={choice} key={`ranked-choice-${choice.slug}`} selected={choice.final_decision}
                decisionObj={decisionObj} setChoices={setChoices} choices={choices} />
  </div>
});


export default ({ decision, choicesData, setChoices, namePrefix = '' }) => {
  if (isBlank(decision)) return null;

  const decisionObj = new Decision(decision);
  const choices = choicesData.filter(choice => isPresent(choice.description))

  if(decisionObj.isRankedList) {
    return <RankedChoices {...{choices, setChoices, decisionObj}} />
  } else if(decisionObj.isPicky || decisionObj.isTopChoice) {
    return <NotRankedChoices {...{choices, setChoices, decisionObj, namePrefix}} />
  } else if (decisionObj.isOpenEnded) {
    return <OpenEndedChoices {...{choices, setChoices, decisionObj}} />
  } {
    return null;
  }
}
