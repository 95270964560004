import * as React from 'react';
import { BrowserRouter } from "react-router-dom";
import Content from "../content";

export class MainPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter basename="/">
        <Content />
      </BrowserRouter>
    );
  }
}
export default MainPage;
