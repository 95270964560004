import React from 'react';
import { connect } from "react-redux";
import { setDecisionOrderSidebarOpen, setSetDecisionsSidebarOpen } from "../../store/sidebar/actions";
import { updateDecisionSetData } from "../../store/decision_set/common_actions";
import { updateTemplateSetData } from "../../store/template_set/common_actions";

export const DecisionSetItem = ({
                                    sidebar, addDecision = false,
                                    openDecisionOrderSideBar, openSetDecisionsSideBar
                                  }) => {
  const openSidebarCallback = () =>
    addDecision ? openSetDecisionsSideBar(!sidebar.setDecisionsSidebar) : openDecisionOrderSideBar(!sidebar.decisionOrderSidebar)

  return <li className={(sidebar.decisionOrderSidebar || sidebar.setDecisionsSidebar) ? 'active' : ''}>
    <div className="side-row text-nowrap pointer" onClick={openSidebarCallback}>
      <span className="icon d-inline-block text-center ">
        <i className="fas fa-list-check fa-lg"/>
      </span>
      <span className="d-none d-lg-inline tab-title">Decision flow</span>
    </div>
  </li>
}

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openDecisionOrderSideBar: (flag) => {
    if (flag) {
      dispatch(updateDecisionSetData({ added_decision_slug: '' }));
      dispatch(updateTemplateSetData({ added_template_slug: '' }));
    }
    dispatch(setDecisionOrderSidebarOpen(flag));
  },
  openSetDecisionsSideBar: (flag) => {
    if (flag) {
      dispatch(updateDecisionSetData({ added_decision_slug: '' }));
      dispatch(updateTemplateSetData({ added_template_slug: '' }));
    }
    dispatch(setSetDecisionsSidebarOpen(flag));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionSetItem);
