import React, { useCallback } from 'react';
import { connect } from "react-redux";
import Recommendation from "../../../models/recommendation";
import OpenEndedDecisionInput from "./OpenEndedDecisionInput";
import ChoicesDecisionInput from "./ChoicesDecisionInput";
import { isBlank } from "../../../helpers/common";
import {
  openModal,
  transferDataAndCloseModal,
  updateRecommendation
} from "../../../store/modals/actions";
import RecommendationEntryModal from "../../modals/RecommendationEntryModal";
import MultiEntryModal from "../../modals/MultiEntryModal";
import Decision from "../../../models/decision";

export const DecisionModal = ({ decisionObj, modal, onClose, updateRecommendation }) =>
  decisionObj.isRecordedOrRecommended || decisionObj.isTreeHistorical ?
    <RecommendationEntryModal key={`recommendation-entry-modal-${modal.slug}`}
                              show={modal.type === "PanelRecommendationEntryModal" && modal.shown}
                              onClose={onClose}
                              updateRecommendation={updateRecommendation} /> :
    <MultiEntryModal key={`multi-entry-modal-${modal.slug}`}
                     show={modal.type === "PanelRecommendationEntryModal" && modal.shown}
                     onClose={onClose} />

const DecisionInputBody = ({ decision, modal, transferDataAndCloseModal, updateRecommendation }) => {
  if (isBlank(decision) || isBlank(decision.recommendation)) return null;

  const decisionObj = new Decision(decision);

  const CachedDecisionInput = useCallback(() =>
      new Recommendation(decision.recommendation).isOpenEnded ?
        <OpenEndedDecisionInput decision={decision} /> :
        <ChoicesDecisionInput decision={decision} />
    , [decision?.recommendation?.choice_entry_widget_type, decision?.description]
  )

  return <>
    <CachedDecisionInput />
    <DecisionModal decisionObj={decisionObj}
                   modal={modal}
                   onClose={transferDataAndCloseModal}
                   updateRecommendation={updateRecommendation} />
  </>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision, modal: state.modal });
export default connect(mapStateToProps, {
  transferDataAndCloseModal, updateRecommendation, openModal
})(DecisionInputBody);
