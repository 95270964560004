import React, {Fragment} from "react";
import Header from "./header";
import EditNameModal, { showEditNameModal } from "./modals/EditNameModal";
import SaveAsNewModal, { showSaveAsNewModalModal } from "./modals/SaveAsNewModal";
import AlertsSection from "../alerts";
const Wrapper = ({
                   modal, closeModal, children, activeTab, gridRef, forecastScenario = null, scenarioIsLoaded = false
                 }) =>
  <Fragment>
    <AlertsSection/>
    <Header {...{ activeTab, scenarioIsLoaded, gridRef, forecastScenario }} />
    {children}
    {
      scenarioIsLoaded &&
      <div className="modals">
        <EditNameModal show={showEditNameModal(modal)} onClose={closeModal} key={'edit-modal'} />
        <SaveAsNewModal show={showSaveAsNewModalModal(modal)} onClose={closeModal} key={'save-as-new-modal'}/>
      </div>
    }
  </Fragment>
export default Wrapper;
