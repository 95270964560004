import React, { useState } from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import { isBlank, isEmail, successActions, uniqArray, uniqueBy } from "../../../helpers/common";
import { collectCollaborationInvites, isGroupContact } from "../../../helpers/decision_helpers";
import { isDecider } from "../../../helpers/user_helpers";
import { reloadShareData } from "../../../store/share/actions";
import { submitInvites } from "../../../store/decision/actions";
import { loadContacts, reloadContacts } from "../../../store/contacts/actions";
import Decision from "../../../models/decision";
import { Typeahead } from 'react-bootstrap-typeahead';
import CollaboratorsInviteRow from "./CollaboratorsInviteRow";
import { CollaboratorsPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";
import { checkLoadingEffect } from "../../../helpers/callbacks_helpers";
import { DEFAULT_TYPEHEAD_ATTRIBUTES } from "../../modals/helpers/share_helpers";
import { onChangeInvites, renderContactItem } from "../helpers/collaborators_helpers";
import { renderTag } from "../../../tree_wizard/steps_wizard/steps/helpers/collaboration_helpers";
import {fetchAddSetMembers} from "../../../tree_wizard/steps_wizard/steps/CollaboratorsDecisionStep";
import {submitSetMembersCallback} from "../../../tree_wizard/side_panel/sections/CollaboratorsSection";
import { resetDecisionSetData } from "../../../store/decision_set/common_actions";
import {onInviteKeyDown} from "../../../homepage/modals/UploadDecisionDocumentModal";

const isCollaborationInvite = (i) => !['ApprovalInvite', 'ViewerInvite'].includes(i.type)

export const InputCollaboratorsField = ({
                                          current_user, submit,
                                          filteredContacts, contactsData,
                                          invites, setInvites, filterNewInvites,
                                          submitInvite, isTemplate = false,
                                          isLegacy = false, isBlankAdmissions = false, showExtraMargin = false
                                        }) => {
  if (isLegacy || !isTemplate && !isDecider(current_user)) return null;

  const ref = React.createRef();
  const onKeyDown = (e) => onInviteKeyDown(e, ref, invites, setInvites, filterNewInvites)

  return <div className={`d-flex flex-column flex-sm-row ${isBlankAdmissions ? 'mt-1' : 'mt-2'} ${showExtraMargin ? 'mt-1' : ''}`}>
    <div className="me-2 w-100">
      <Typeahead
        {...DEFAULT_TYPEHEAD_ATTRIBUTES} labelKey="value" filterBy={['value','full_name']}
        id="collaboration-invite-input"
        ref={ref} selected={invites}
        onChange={(selected) => onChangeInvites(selected, setInvites, filterNewInvites)}
        options={filteredContacts()}
        renderMenuItemChildren={renderContactItem}
        onKeyDown={onKeyDown}
        renderToken={(option, events, index) => renderTag(option, events, index, contactsData)}
      />
    </div>
    <Button onClick={() => submitInvite(invites)} style={{minWidth: 64}} className={`mt-2 mt-sm-0`} disabled={isBlank(invites) || submit}>
      { isTemplate ? 'Add' : 'Invite' }
    </Button>
  </div>;
}

export const CollaboratorsSection = ({
                                       decision, playbook_notes, contactsData, current_user,
                                       loadContacts, submitInvites,
                                       reloadShareData, reloadContacts, resetDecisionSetData,
                                       decision_set, classNames = 'p-3', isLegacy = false,
                                       ...opts
                                    }) => {
  checkLoadingEffect(contactsData, loadContacts)

  const submitInvite = (invites) => {
    setSubmit(true)
    const emails = invites.join(',')
    const type = decisionObject.isDecisionRecorded ? 'BuyInInvite' : 'CollaborationInvite'
    const contacts = invites.map(email => (contactsData.contacts.find(c => c.value === email) || { email }))
    submitInvites({
      emails, type, contacts,
      callback: (success) => {
        successActions(success, [
          reloadContacts,
          () => setInvites([]),
          reloadShareData,
          resetDecisionSetData
        ])
        setSubmit(false)
        setSubmitSetMembers(false)
      }
    })
  }

  const decisionObject = new Decision(decision)
  const decisionInvites = uniqueBy(collectCollaborationInvites(decision), 'email');
  const filterSetInvitesToAdd = fetchAddSetMembers(decision, decision_set, decisionInvites).map(u => u.email)

  const filteredContacts = () =>
    contactsData.contacts.filter(contact => {
      if(isGroupContact(contact)) return true;
      if (contact.value === decision.user.email) return false;
      if (invites.indexOf(contact.value) > -1) return false;

      return !decision.invites.filter(isCollaborationInvite).some(invite => invite.email === contact.value)
    })

  const filterNewInvites = (newInvites) =>
    uniqArray(
      newInvites.filter(email => isEmail(email))
        .filter(email => decision.user.email !== email)
        .filter(email =>
          !decision.invites.filter(isCollaborationInvite).some(i => i.email === email)
        )
    )

  const [invites, setInvites] = useState([])
  const [submit, setSubmit] = useState(false)
  const [submitSetMembers, setSubmitSetMembers] = useState(false)

  const addSetMembers = () => {setSubmitSetMembers(true)}

  submitSetMembersCallback(submitSetMembers, submitInvite, setSubmitSetMembers, filterSetInvitesToAdd)

  return <div className={`${classNames}`}>
    <CollaboratorsPBSection playbook_notes={playbook_notes} sidePanelStyle={true} showNA={false} {...opts} />
    <CollaboratorsInviteRow user={ decision.user } email={decision.user.email} slug={'decider'} />
    { decisionInvites.map(invite => <CollaboratorsInviteRow {...invite} {...{isLegacy}} key={`invite-row-${invite.slug}`} />) }
    <Button onClick={addSetMembers} disabled={submitSetMembers} hidden={isBlank(filterSetInvitesToAdd) || !isDecider(current_user)} className="btn-secondary w-100 mt-1">
      Add all decision flow members
    </Button>
    <InputCollaboratorsField {...{
      isLegacy, current_user, submit,
      filteredContacts, contactsData,
      invites, setInvites, filterNewInvites,
      submitInvite
    }} />
  </div>
}
const mapStateToProps = ({ decision, playbook_notes, current_user, contacts, decision_set }) => ({
  decision, playbook_notes, current_user, decision_set,
  contactsData: contacts
});
export default connect(mapStateToProps, {
  loadContacts, submitInvites, reloadShareData, reloadContacts, resetDecisionSetData
})(CollaboratorsSection);
