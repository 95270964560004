import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateHomepageCurrentTab } from "../../store/homepage/actions";
import { hideGroupsTab, isGroupsCenter, TABS, TABS_URLS } from "../../helpers/home_helpers";

export const GroupsItem = ({displayCurrent, home, current_user, updateHomepageCurrentTab}) => {
  if (hideGroupsTab(current_user)) return null;

  return <li className={displayCurrent && isGroupsCenter(home, current_user) ? 'active' : ''}>
    <Link to={`/homepage/${TABS_URLS.groups}`}
          onClick={() => updateHomepageCurrentTab(TABS.groups)}
          className="text-nowrap">
      <span>
        <span className="icon d-inline-block text-center">
          <i className="fas fa-users fa-lg" />
        </span>
        <span className="d-none d-lg-inline tab-title">Groups</span>
      </span>
    </Link>
  </li>
}

const mapStateToProps = ({ home, current_user }) => ({ home, current_user });
export default connect(mapStateToProps, { updateHomepageCurrentTab })(GroupsItem);
