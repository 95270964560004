import React, { useMemo } from "react";
import { connect } from "react-redux";
import { ForecastScenario } from "../../models/forecast/ForecastScenario";
import { isPresent } from "../../helpers/common";
import { calcSimVsValue, calcYARootFromPrevSimVsYa } from "./ag_grid_formulas";
import { buildNewEditedCells, createComparisonRows } from "./common";
import {
  ROW_DRIVER_ID_KEY,
  EDITABLE_COLUMN_KEYS,
  BENCHMARK_KEY,
  SIM_VS_BENCHMARK_KEY,
  SIM_VS_YA_KEY,
  FACT_COMPARISON_HEADERS_NAMES,
  SIMULATED_FACT_HEADER
} from "./ag_grid_vars";
import { updateScenario, updateScenarioData } from "../../store/forecast_simulator_scenario/actions";
import { hideOverlay, MESSAGES, showOverlayWithMessage } from "./custom_loading_overlay";

const isEditableComparisonRow = (row) => EDITABLE_COLUMN_KEYS.some(key => row[ROW_DRIVER_ID_KEY].includes(key))

const createEditedCellsForRows = (editedCells, params, newRows, timeScale) => {
  const benchmarkRow = newRows.find(row => row[ROW_DRIVER_ID_KEY].includes(BENCHMARK_KEY));
  const list = newRows.flatMap(row => {
    if(isEditableComparisonRow(row)) {
      return editedCells.filter(editedCell => row[ROW_DRIVER_ID_KEY].includes(editedCell.nodeId)).map(editedCell => {
        const benchmarkRootValue = benchmarkRow[editedCell.field];
        const rootValue = editedCell.value;
        const defaultRootValue = editedCell.default_value
        const newValue = row[editedCell.field];
        let prevValue = ''
        if(row[ROW_DRIVER_ID_KEY].includes(SIM_VS_BENCHMARK_KEY)) {
          prevValue = calcSimVsValue(defaultRootValue, benchmarkRootValue);
        }
        if(row[ROW_DRIVER_ID_KEY].includes(SIM_VS_YA_KEY)) {
          const yARootValue = calcYARootFromPrevSimVsYa(rootValue, newValue);
          prevValue = calcSimVsValue(defaultRootValue, yARootValue);
        }
        return {
          node: { id: row[ROW_DRIVER_ID_KEY], prevValue },
          colDef: { field: editedCell.field, colId: editedCell.periodId },
          newValue
        }
      });
    }
  }).filter(isPresent)
  return buildNewEditedCells(editedCells, list, timeScale);
}

const addComparisonRows = (newItems, params) => {
  const addIndex = params.node.leafGroup ?
    params.node.allLeafChildren[params.node.allLeafChildren.length-1].rowIndex :
    params.node.rowIndex;
  params.api.applyTransaction({
    add: newItems,
    addIndex
  });
  if(params.node.leafGroup) params.node.setExpanded(true);
}

const removeComparisonRows = (openedGroups, params) => {
  const group = openedGroups.find(group => group.id === params.node.id);
  const idsToRemove = group?.added_rows?.map(row => ({ [ROW_DRIVER_ID_KEY]: row[ROW_DRIVER_ID_KEY] })) || [];
  params.api.applyTransaction({ remove: idsToRemove });
  if(params.node.leafGroup) params.node.setExpanded(false);
}

export const removeAllComparisonRows = (openedGroups, gridRef) => {
  openedGroups.forEach(group => {
    const idsToRemove = group?.added_rows?.map(row => ({ [ROW_DRIVER_ID_KEY]: row[ROW_DRIVER_ID_KEY] })) || [];
    gridRef.current.api.applyTransactionAsync({ remove: idsToRemove });
  });
}

const editedCellAlreadyPresent = (editedCells, params, forecastScenario) => {
  return editedCells.some(cell => cell.nodeId === params.node.allLeafChildren[0].id && cell.timeScale === forecastScenario.timeScale)
}

const updateDataHashOnOpen = (params, forecastScenario, editedCells, newRows, expanded) => {
  let updateData = {
    opened_group: {
      id: params.node.id,
      output: !params.node.leafGroup || forecastScenario.outputDriversNames.some(driverName => params.node.key.includes(driverName)),
      time_scale: forecastScenario.timeScale,
      added_rows: newRows,
      expanded
    }
  }
  if (editedCellAlreadyPresent(editedCells, params, forecastScenario))
    updateData.edited_cells = [
      ...editedCells,
      ...createEditedCellsForRows(editedCells, params, newRows, forecastScenario.timeScale)
    ];
  return updateData;
}

const onRowGroupOpened = (config, editedCells, params, expanded, updateScenario, forecastScenario, forecastBenchmarkScenario, openedGroups, updateScenarioData) => {
  showOverlayWithMessage(params.api, updateScenarioData, MESSAGES.updating_scenario);
  setTimeout(() => {
    if(expanded) {
      const newRows = createComparisonRows(config, params, forecastScenario, forecastBenchmarkScenario)
      updateScenario(forecastScenario.local_id, {
        update_data: { ...updateDataHashOnOpen(params, forecastScenario, editedCells, newRows, expanded) }
      }, (status, errors) => {
        if (status) {
          addComparisonRows(newRows, params);
        }
        hideOverlay(params.api);
      })
    } else {
      updateScenario(forecastScenario.local_id, { update_data: { opened_group: { id: params.node.id, expanded } } }, (status, errors) => {
        if (status) {
          removeComparisonRows(openedGroups, params);
        }
        hideOverlay(params.api);
      })
    }
  }, 0)
}

const lookupScenarioEditedData = (forecast_simulator_scenario) => {
  const forecastScenario = useMemo(() =>
      new ForecastScenario(
        forecast_simulator_scenario?.scenario,
        forecast_simulator_scenario?.scenario.config,
        forecast_simulator_scenario?.scenario.view_options?.timeScale
      ),
    [forecast_simulator_scenario?.scenario]
  );
  return {
    editedCells: forecastScenario.actualEditedCells || [],
    openedGroups: forecastScenario.openedGroups || []
  }
}

const cellStyles = (isGroup, isComparisonHeader, isSimulatedFact) => !isGroup && (isComparisonHeader || isSimulatedFact) ?
  'ag-row-group-leaf-indent ag-row-group-indent-1' : 'ag-cell-expandable ag-row-group ag-row-group-indent-0';


const FactsCellRenderer = ({ params, config, forecastScenario, forecastBenchmarkScenario, forecast_simulator_scenario,
                             updateScenario, updateScenarioData }) => {
  const isGroup = params.node.group;
  const isComparisonHeader = FACT_COMPARISON_HEADERS_NAMES.includes(params.value);
  const isSimulatedFact = params.value === SIMULATED_FACT_HEADER;
  const { editedCells, openedGroups } = lookupScenarioEditedData(forecast_simulator_scenario);
  const expanded = isGroup && openedGroups.map(group => group.id).includes(params.node.id);
  const isBenchmarkLoaded = !forecast_simulator_scenario.benchmark_loading && forecast_simulator_scenario.benchmark_loaded;
  const onClick = () => {
    if(!isBenchmarkLoaded) {
      showOverlayWithMessage(params.api, updateScenarioData, MESSAGES.loading_benchmark_data);
      setTimeout(() => hideOverlay(params.api), 3000);
    } else {
      onRowGroupOpened(config, editedCells, params, !expanded, updateScenario, forecastScenario, forecastBenchmarkScenario, openedGroups, updateScenarioData);
    }
  }

  return <span className={`ag-cell-wrapper ${cellStyles(isGroup, isComparisonHeader, isSimulatedFact)}`}>
   <span className={`ag-group-expanded ${isGroup && expanded ? '' : 'ag-hidden'}`} onClick={onClick}>
     <span className="ag-icon ag-icon-tree-open" unselectable="on" role="presentation"></span>
   </span>
   <span className={`ag-group-contracted ${isGroup && !expanded ? '' : 'ag-hidden'}`} onClick={onClick}>
     <span className="ag-icon ag-icon-tree-closed" unselectable="on" role="presentation"></span>
   </span>
   <span className="ag-group-checkbox ag-invisible"/>
    <span className="ag-group-value">
      {isGroup && (isSimulatedFact || isComparisonHeader) ? forecastScenario.valueSalesDriverName : params.value}
    </span>
    <span className="ag-group-child-count"/>
  </span>
};
const mapStateToProps = ({ forecast_simulator_scenario }) => ({
  forecast_simulator_scenario
});
const mapDispatchToProps = (dispatch) => ({
  updateScenario: (scenario_id, updateData, callback) =>
    dispatch(updateScenario(scenario_id, updateData, callback, false)),
  updateScenarioData: (data) => dispatch(updateScenarioData(data))
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FactsCellRenderer);
