import React, { useRef } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { openModal } from "../../../../store/modals/actions";
import {
  isDriverInEditModeByOthers,
  performEditDriverDetailsAction,
  treeChannelIsConnected
} from "../../../../helpers/channel_helpers";
import { editingDriverAlert } from "../../../../tree_view/accordion_tree/shared/helpers"
import { TREE_CHANNEL_ACTIONS } from "../../../../../../channels/tree_channel";
import { attachEventEffect } from "../../../../helpers/html_events_helpers";

const EditDriver = ({ tree, decision, channels, user, treeNode, openModal }) => {
  const ref = useRef();
  const onEditDriver = (e) => {
    e.preventDefault();
    if(treeChannelIsConnected()) {
      if (isDriverInEditModeByOthers(channels, TREE_CHANNEL_ACTIONS.edit_driver_details, treeNode.slug, user))
        return editingDriverAlert(channels, TREE_CHANNEL_ACTIONS.edit_driver_details, treeNode.slug)

      performEditDriverDetailsAction(user, treeNode.slug, true)
    }
    openModal({
      decision,
      drivers: tree.drivers,
      data_sources: tree.data_sources,
      slug: treeNode.slug,
      type: 'TreePanelDriverDetailsModal'
    })
  }

  //hack to attach click event after tree is initiated to fix click on selected row button
  attachEventEffect(ref, onEditDriver)

  return <Button className="btn btn-secondary btn-sm btn-sm-round py-1 me-1" ref={ref}>
    <i className="fas fa-pencil-alt fa-lg w-100" />
  </Button>
}
const mapStateToProps = ({ tree, decision, template, current_user, channels },  { isTemplate }) => ({
  tree, channels, decision: isTemplate ? template : decision, user: current_user
});
export default connect(mapStateToProps, { openModal })(EditDriver);
