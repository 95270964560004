import React from 'react';
import { connect } from "react-redux";
import { updateDriver } from "../../../store/sidebar/actions";
import DriverRichTextInput from "./DriverRichTextInput";

export const DETAILS_PLACEHOLDER = "Enter any additional detail, explanation, or analysis"

const DetailsInput = ({ innerRef, setAutoFocus, sidebar, ...opts }) => {
  const {driver} = opts?.driverData;
  const updateLocalReasonsData = (reasons) => localStorage.setItem(`driverReasonsData-${driver.slug}`, reasons.target.innerHTML);

  return <div onFocus={() => setAutoFocus(false)} onBlur={updateLocalReasonsData} key={`driver-details-${sidebar.rationaleTimeStamp}`}>
    <DriverRichTextInput {...opts}
                         header="Details"
                         field="explanation"
                         placeholder={DETAILS_PLACEHOLDER}
                         ref={innerRef} setAutoFocus={() => {}} />
  </div>
}

const mapStateToProps = ({ decision, sidebar }) => ({ decision, sidebar })
const wrapper = React.forwardRef((props, ref) => <DetailsInput {...props} innerRef={ref} />)
export default connect(mapStateToProps, { updateDriver }, null, { forwardRef: true })(wrapper);

