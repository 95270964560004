import React from 'react';
import { connect } from "react-redux";
import { setHelpPanelOpen } from "../../../store/sidebar/actions";
import { isInIframeOfOwnOrigin } from "../../../helpers/common";

export const Help = ({ sidebar, openSideBar }) => {
  if(isInIframeOfOwnOrigin()) return null;

  return <button className={`btn btn-primary btn-sm btn-sm-round sticky-help-button`}
          onClick={() => {
            openSideBar(!sidebar.helpSidebar)
          }}>
    <i className="fas fa-question text-white h3"/>
  </button>
}

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setHelpPanelOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Help);
