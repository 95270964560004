import React from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import { currencyFormat, pluralize_text, isPresent } from "../../../../helpers/common";
import { SortByTypes } from "../ReportsSection";

export const ReportTile = ({ report, home }) =>{
  return <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <div className="text-dark text-center">
      <div className="tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title mb-3">{report.name}</h4>
        <h5 className="badge rounded-pill bg-light cursor-default py-1 w-100 mb-2">
          <span className="text-dark">
            {
              home.sort_order === SortByTypes.DECISION_USAGE ?
                <>
                  {Number(report.decisions_count) === 0 ?
                    'No ' :
                    <span className="fw-bolder me-1">{report.decisions_count}</span>}
                    {pluralize_text(report.decisions_count, 'decision', 'decisions')}
                </> :
                home.sort_order === SortByTypes.MOST_RECENTLY_ADDED ?
                  <>
                    Added <span className="fw-bolder">{moment(report.created_at).format('D MMM, yyyy')}</span>
                  </> :
                  home.sort_order === SortByTypes.COST ? (isPresent(report.cost) ?
                    <>$<span className="fw-bolder">{currencyFormat(report.cost, "")}</span></> :
                    <span className="text-muted">Cost not entered</span>) : null
            }
          </span>
        </h5>
        <Link to={`/reports/${report.slug}`} target={`_blank`}>
          <div className="btn btn-primary align-center h-36 py-1 w-100">
            <span className="mx-3">Show details</span>
          </div>
        </Link>
      </div>
    </div>
  </div>
}