import {
  DSIGHT_GOALS_LOAD_DATA,
  DSIGHT_GOALS_LOAD_DATA_FAILED,
  DSIGHT_GOALS_LOAD_DATA_STARTED,
  DSIGHT_GOALS_RESET_DATA,
  DSIGHT_GOALS_UPDATE_DATA
} from "./types";
import { loadCommonGoalData } from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import EntryPoint from "../../EntryPoint";

export const loadCommonGoal = (slug) => (dispatch) => {
  dispatch(loadDSightGoalStarted({}));
  const { objectSlug, controllerName } = EntryPoint.instance
  loadCommonGoalData({slug, section: controllerName, objectSlug}).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDSightGoalFailure });

    const { data } = response;
    const { common_goal_data } = data;
    dispatch(updateDSightGoalData({ ...common_goal_data, slug, loaded: true }));
  })
}

export const updateDSightGoal = (data = {}) => (dispatch) => {
  dispatch(updateDSightGoalData({ ...data }));
}
// Helpers
export const resetDSightGoal = (data = {}) => ({
  type: DSIGHT_GOALS_RESET_DATA,
  payload: {
    ...data
  }
});
export const loadDSightGoalSuccess = (data) => ({
  type: DSIGHT_GOALS_LOAD_DATA,
  payload: {
    ...data
  }
});
export const updateDSightGoalData = (data) => ({
  type: DSIGHT_GOALS_UPDATE_DATA,
  payload: {
    ...data
  }
});
export const loadDSightGoalStarted = (query = {}) => ({
  type: DSIGHT_GOALS_LOAD_DATA_STARTED,
  payload: {
    ...query
  }
});
export const loadDSightGoalFailure = error => ({
  type: DSIGHT_GOALS_LOAD_DATA_FAILED,
  payload: {
    error
  }
});
