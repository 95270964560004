import React from "react";
import BubbleTooltip from "../../../bubble_tooltip/BubbleTooltip";
import {safeDescription} from "../../../helpers/decision_helpers";
import {ButtonText, CollaboratorsImages, LinkWrapper} from "./DecisionTile";
import Decision from "../../../models/decision";

export default ({decision, section, isDecisionSet, children, hasAccess, hideCollaborators, isSearch, isDSightHistory, isInDecisionSet}) => {
  const decisionObj = new Decision(decision);
  const collaborators = decisionObj.collaborators;

  return <div className="col-12 mb-2">
    <LinkWrapper isSearchSection={isSearch} {...{ decision, hasAccess, isInDecisionSet }}>
      <div className="homepage-list-col">
        <div className="d-flex align-items-center list-col-content bg-white p-2">
          <div className="text-start overflow-hidden lh-sm">
            <div className="text-nowrap text-ellipsis fw-bolder">
              {safeDescription(decision)}
            </div>
            <div className="text-muted">
              <ButtonText decision={decision} isSearchSection={isSearch} isDSightHistory={isDSightHistory}/>
            </div>
          </div>
          <div className="d-flex ps-2 ms-auto">
            <BubbleTooltip {...{ object_hash: decision, decision_set_name: decision.decision_set_name, isDecisionSet, inListLine: true }} />
            <div className={`collaborators text-end`} hidden={hideCollaborators}>
              <CollaboratorsImages object={decision} collaborators={collaborators}/>
            </div>
          </div>
        </div>
      </div>
    </LinkWrapper>
  </div>
}