import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent, pluralize, qSortArray } from "../../helpers/common";
import { LEGACY_SUPPORT_VALUES, SUPPORT_VALUES } from "../../models/decision";
import Modal from "react-bootstrap/Modal";
import { ModalBodyWithClose, ModalDoneFooterP3 } from "../../common/modals";
import { RichTextSection } from "../../common/RichTextEditor";
import { userName } from "../../helpers/user_helpers";
import UserAvatarImage from "../../common/UserAvatarImage";

export const UserSupportCommentTile = ({ userSupport }) =>
  <div className="d-flex mt-2">
    <div className="mt-auto user_image p-0 me-2">
      <UserAvatarImage user={userSupport.user} />
    </div>
    <div className="d-grid bg-light p-2 rounded user-explanation">
      <div className="user-name text-muted">
        { userName(userSupport.user) }
      </div>
      <div className="support-comment overflow-auto pt-1">
        <RichTextSection text={userSupport.support_comment} objectComment={userSupport}/>
      </div>
    </div>
  </div>

export const BuyInDetailsModal = ({userSupports, shown, onClose = () => {}, isLegacy = false}) => {
  const supportSections = (supportData) => {
    const sectionSupports = userSupports.filter(s => s.support === supportData.value)
    if (isBlank(sectionSupports)) return null;

    const commentedSupports = qSortArray(sectionSupports.filter(s => isPresent(s.support_comment)), true, s => s.commented_at)
    const supportPercentage = parseFloat((sectionSupports.length/userSupports.length * 100).toFixed(0))

    return <Fragment key={`user-support-${supportData.value}-section`}>
      <div className="radio-pillbox mb-3">
        <div className="d-flex m-0">
          <div className="support-header radio-container p-0 me-2">
            <div className={`rating-circle ${supportData.className} active`}>
              <span>{supportData.value}</span>
            </div>
          </div>
          <div className="support-details w-100">
            <div className={`fw-bold`}>
              { pluralize(sectionSupports.length, 'person', 'people') } ({supportPercentage}%)
            </div>
            <div className={`supported-users text-muted`}>
              { sectionSupports.map(s => s.user.full_name).join(', ') }
            </div>
          </div>
        </div>
        <div className="support-details ms-lg-4">
          { commentedSupports.map(s => <UserSupportCommentTile userSupport={s} key={`user-support-${supportData.value}-comment-${s.user_email}`} />) }
        </div>
      </div>
    </Fragment>
  }

  return <Modal size="lg" backdrop="static" show={shown} onHide={onClose}>
    <ModalBodyWithClose bsPrefix="support-details-modal" header="Buy-in details" onClose={onClose}>
      { [...isLegacy ?  LEGACY_SUPPORT_VALUES : SUPPORT_VALUES].reverse().map(supportSections) }
    </ModalBodyWithClose>
    <ModalDoneFooterP3 onClose={onClose} />
  </Modal>
}
const mapStateToProps = ({ decision, current_user }) => ({ decision, current_user });
export default connect(mapStateToProps)(BuyInDetailsModal);
