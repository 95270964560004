import React, { useState } from 'react';
import { connect } from "react-redux";
import Help from "../../help";
import { saveTemplateWizardStepData } from "../../../store/wizard/actions";
import { Table } from 'react-bootstrap'
import { isLastStep, stepKeyWithCategories } from "../../../helpers/wizard_helpers";
import CategoriesForm from "../../../decision_categories/CategoriesForm";
import { categorizationStepState } from "../../../tree_wizard/steps_wizard/steps/TreeDecisionCategorizationStep";
import SubmitStepButton from "./SubmitStepButton";
import SelectCategory from "../../../tree_wizard/helpers/SelectCategory";
import { categorizationNotesState } from "../../../common/CategorizationNotesRow";

export const TreeDecisionCategorizationStep = ({ wizard, org_categories, playbook_notes, saveTemplateWizardStepData, stepRef }) => {
  const [submitState, setSubmitState] = useState(false)
  const {
    categorizationNotes,
    categorizationNotesRow
  } = categorizationNotesState(playbook_notes)

  const submitStep = (nav_data = {}, callback = () => {}) => {
    setSubmitState(true)
    saveTemplateWizardStepData(stepKeyWithCategories(wizard), {
      complete: isLastStep(wizard),
      ...nav_data,
      ...submitCategoriesData(),
      categorization: categorizationNotes,
      next_step: true
    }, callback)
  }

  const {
    selectedCategories, onSelectCategory, submitCategoriesData,
    ...hash
  } = categorizationStepState({ wizard, org_categories })

  stepRef.current = {
    submitStep: (additional_data = {}, callback = () => {}) => {
      submitStep(additional_data, (success, wizardData) => {
        setSubmitState(false)
        callback(success, false, wizardData)
      })
    }
  }

  return <div className="bg-white rounded p-3 my-3 mx-auto tree_wizard">
    <Table borderless className="mb-2">
      <tbody>
        <tr>
          <td className="d-flex p-0">
            <h1 className="d-table-cell pe-1">Select the default categories for this template.</h1>
            <div className="d-table-cell ms-auto">
              <Help />
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
    <CategoriesForm className={'w-100 mb-3'}
                    {...{...hash, submitState, selectedCategories, requireInput: true,
                      categorizationNotesRow: <>
                        <SelectCategory selected={selectedCategories}
                                        onSelect={onSelectCategory}
                                        submitState={submitState}
                        />
                        {categorizationNotesRow}
                      </>}} />
    <div className="w-100">
      <SubmitStepButton onClick={() => submitStep()} disabled={wizard.submit || submitState} />
    </div>
  </div>
}
const mapStateToProps = ({ wizard, org_categories, playbook_notes }) => ({ wizard, org_categories, playbook_notes });
const mapDispatchToProps = (dispatch) => ({
  saveTemplateWizardStepData: (step, data, callback) => {
    dispatch(saveTemplateWizardStepData(step, data, callback))
  }
});
const wrapper = React.forwardRef((props, ref) => <TreeDecisionCategorizationStep {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
