import React, {useState} from "react";
import BubbleTooltip from '../../../../bubble_tooltip/BubbleTooltip';
import { isMobile } from "react-device-detect";
import { tileSectionsFlags } from "../../tiles/helpers";
import DecisionTileContent from "../../tiles/DecisionTileContent";

const DSightDecisionTile = ({
                              decision, current_user, section = '',
                              children,
                              isDecisionSet = false, isApprovalNeeded = false
                            }) => {
  const [showChildren, setShowChildren] = useState(!!isMobile);

  const {
    isSearch, isDSightHistory, isInDecisionSet
  } = tileSectionsFlags(section)

  return (
    <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3"
         onMouseEnter={e => {
           setShowChildren(true);
         }}
         onMouseLeave={e => {
           isMobile ? {} : setShowChildren(false)
         }}
    >
      <BubbleTooltip {...{ object_hash: decision, decision_set_name: decision.decision_set_name, isDecisionSet }} />
      <DecisionTileContent {...{ decision, isSearch, isDSightHistory, isInDecisionSet, isApprovalNeeded, current_user, hideCollaborators: children && showChildren }} />
      {
        children && showChildren &&
        <div className="position-absolute bottom-0 ps-2 pe-3">
          {children}
        </div>
      }
    </div>
  )
}
export default DSightDecisionTile;
