import {isBlank, isPresent} from "../../../helpers/common";
import Decision from "../../../models/decision";
import React from "react";
import {
  baseHeadParagraph, baseParagraph,
  baseRichBlock,
  bookMarkHeader,
  internalLink,
  paragraphBorder
} from "./wrappers";
import Driver from "../../../models/driver";
import {
  Table,
  TableCell,
  TableRow,
  TableBorders,
  TextRun,
  AlignmentType,
  HeadingLevel, WidthType
} from "docx";
import {ChoiceWeights} from "../../../models/DriverChoiceRating";
import {tableCellProps} from "../../styles/docx_styles";
import {pickyDecisionBlock} from "./FinalDecision";

export const questionBlock = ({driver, heading = HeadingLevel.HEADING_3, id = ''}) => {
  if (isBlank(driver.question)) return [];

  return bookMarkHeader({id, text: driver.question, heading})
}

export const answerBlock = (driver, instance = 10) => {
  const driverObj = new Driver(driver)
  if (!driverObj.withEnteredAnswer || driverObj.isDraft) return [];

  const driverAnswer = () => {
    if (driverObj.isOpen || driverObj.isNumbering) {
      return [
        ...baseRichBlock({text: driver.answer, instance, isJodit: false})
      ]
    } else if (driverObj.isTopChoice) {
      return [
        ...baseRichBlock({text: driverObj.finalDecisions[0].description, instance, isJodit: false})
      ]
    } else if (driverObj.isPicky) {
      return driverObj.finalDecisions.length === 1 ?
        [...baseRichBlock({text: driverObj.finalDecisions[0].description, instance, isJodit: false})] :
        pickyDecisionBlock(driverObj);
    }
  }

  return driverAnswer()
}

export const notAnswerBlock = (driver) => {
  const driverObj = new Driver(driver);
  if (driverObj.isAnswered) return [];

  return [
    ...baseRichBlock({text: 'N/A', style: "mutedText"})
  ]
}

export const notAnswerRatingBlock = (driverObj, sortedDriverChoicesRatings) => {
  const isNotAnsweredRating = sortedDriverChoicesRatings.some(d => d.driver.driver.parent_id && isBlank(d.evaluation))
  if (driverObj.withEnteredChoicesRating || !isNotAnsweredRating) return [];

  return [
    ...baseRichBlock({text: 'N/A', style: "mutedText"})
  ]
}

const keyDriverBody = (drivers) => {
  const keyDriverBody = []

  drivers.filter(driver => isPresent(driver.driver.question)).forEach((driver, index) => {
    keyDriverQuestionBlock({driver: driver.driver, keyDriverBody, index, instance: `driver-key-${index}`})
  })

  return keyDriverBody
}

export const keyDriverQuestionBlock = ({driver, keyDriverBody, heading, id, index, instance}) => {
  keyDriverBody.push(questionBlock({driver, heading: heading || index === 0 ? HeadingLevel.HEADING_4 : HeadingLevel.HEADING_3, id}))
  const driverObj = new Driver(driver)

  if (driverObj.withEnteredAnswer && !driverObj.isDraft) {
    keyDriverBody.push(...answerBlock(driver, instance))
  } else {
    keyDriverBody.push(...notAnswerBlock(driver))
  }
}

export const keyDriversBlock = (decision, drivers) => {
  const decisionObj = new Decision(decision);
  if (isBlank(drivers) || !decisionObj.isRateAndCompareDriverChoicesHidden) return [];

  return [
    baseHeadParagraph ({text: 'Key drivers'}),
    ...keyDriverBody(drivers),
    paragraphBorder()
  ]
}

const weightedRatingChoicesBody = (drivers, decisionObj, keyDrivers) => {
  const rows = []
  decisionObj.sortedRatedChoices.forEach(choice => {
    const obj = new ChoiceWeights(choice, keyDrivers)
    rows.push(new TableRow({
      children: [
        new TableCell({
          width: {
            size: 10000,
            type: WidthType.DXA,
          },
          style: 'normalPara',
          ...tableCellProps,
          children: [
            baseParagraph([new TextRun({text: choice.description})])
          ],
        }),
        new TableCell({
          width: {
            size: 500,
            type: WidthType.DXA,
          },
          ...tableCellProps,
          children: [
            baseParagraph([new TextRun({text: String(obj.weightedRatingScore)})], AlignmentType.RIGHT)
          ],
        }),
      ],
     }))
  })
  return [
    new Table({
      borders: TableBorders.NONE,
      style: 'normalPara',
      columnWidths: [10000, 500],
      rows: [...rows]
    })
  ]
}

const weightedRatingChoicesBlock = (drivers, decision) => {
  const decisionObj = new Decision(decision, drivers);
  if (decisionObj.sortedChoicesOrRecommendations(true).length === 0) return [];
  if (decisionObj.isRateAndCompareDriverChoicesHidden) return [];

  const keyDrivers =  decisionObj.keyDrivers

  return [
    baseHeadParagraph ({text: 'Weighted rating of choices'}),
    ...weightedRatingChoicesBody(drivers, decisionObj, keyDrivers),
    paragraphBorder(),
  ]
}

const driverWeightsBody = (keyDrivers) => {
  const rows = []
  keyDrivers.forEach(driver => {
    rows.push(new TableRow({
      children: [
        new TableCell({
          width: {
            size: 9800,
            type: WidthType.DXA,
          },
          ...tableCellProps,
          children: [
            baseParagraph([internalLink({text: driver.question, anchor: `driver-${driver.slug}`})])
          ],
        }),
        new TableCell({
          width: {
            size: 700,
            type: WidthType.DXA,
          },
          ...tableCellProps,
          children: [
            baseParagraph([new TextRun({text: `${driver.weightPercentage}%`})], AlignmentType.RIGHT)
          ],
        }),
      ],
    }))
  })

  return [
    new Table({
      borders: TableBorders.NONE,
      style: 'normalPara',
      columnWidths: [9800, 700],
      rows: [...rows]
    })
  ]
}

const driverWeightsBlock = (drivers, decision) => {
  const decisionObj = new Decision(decision, drivers)
  const keyDrivers =  decisionObj.keyDrivers
  if (isBlank(drivers) || decisionObj.isRateAndCompareDriverChoicesHidden || keyDrivers.length === 0) return [];

  return [
    baseHeadParagraph ({text: 'Driver weights'}),
    ...driverWeightsBody(keyDrivers),
    paragraphBorder(),
  ]
}

export const decisionBlockContent = (decision, drivers) =>
  [
    ...keyDriversBlock(decision, drivers),
    ...weightedRatingChoicesBlock(drivers, decision),
    ...driverWeightsBlock(drivers, decision)
 ]


export const AdditionSection = (decision, drivers, hideDecisionRecommendationContainer) => {
  const decisionObj = new Decision(decision, drivers);

  if (decisionObj.isDecisionRecorded) {
    return decisionBlockContent(decision, drivers)
  } else {
    if (!hideDecisionRecommendationContainer) return []

    return [
      ...keyDriversBlock(decision, drivers)
    ]
  }
}

export const RecommendationAdditionSection = (decision, drivers, hideDecisionRecommendationContainer) => {
  if (hideDecisionRecommendationContainer) return []

  return decisionBlockContent(decision, drivers)
}