import { InsightsData } from "./InsightsData";
import { capitalizeAllWords, isBlank, isPresent, qSortArray, uniqueByCallback } from "../../helpers/common";
import { INSIGHT_TYPE, skipSemaphore } from "./insight";
import { SEMAPHORE_SORT_ORDER } from "./goal";
import { TABS_URLS } from "../../helpers/home_helpers";
import { isBrandFunnel, isNPSChart } from "./metric_chart_helpers";

export const LAYER_LEVELS = {
  goal: [6],
  lever: [5],
  insight: [4],
  kpi: [3],
  metrics: [2, 1, 0]
}

export const TREE_RELATION_TYPE = {
  output: 'output',
  trend: 'trend',
  blocknm: 'blocknm',
  res: 'res',
  add: 'add'
}

const BASE_BRAND_FUNNEL_DATA = {
  display_name: "Brand Funnel",
  dynamic_chains: false,
  has_chart_data: false,
  layer: "2.0",
  measure: "none",
  model: "brandFunnel",
  name: "brandFunnel",
  node_index: null,
  output: true,
  view: "view",
  hide: true
}

export const uniqAnalysisRecords = (records) => uniqueByCallback(records, m => m.id)

export const analysisSortValue = (record) => [record.sequence, SEMAPHORE_SORT_ORDER[record.semaphore], record.name]
export const skipAnalysisRecord = (record) => skipSemaphore(record.semaphore) && skipAnalysisSemaphore(record)

export const skipAnalysisSemaphore = (analysis) => {
  if (LAYER_LEVELS.goal.includes(analysis.layer)) return false;
  if (LAYER_LEVELS.lever.includes(analysis.layer)) return false;
  if (LAYER_LEVELS.insight.includes(analysis.layer)) return false;
  if (LAYER_LEVELS.kpi.includes(analysis.layer)) return false;
  if (LAYER_LEVELS.metrics.includes(analysis.layer)) return false;

  return false;
}

const sortMetrics = ({ metrics = [] }, record) => {
  const records = (metrics || []).map(hash => new AnalysisMetric(hash, record, record.insightType, record.selectedDemographic))
  return qSortArray(records,true, analysisSortValue)
}
class BaseAnalysis {
  constructor(data, parent = null, insightType= null, selectedDemographic = null) {
    this.data = data
    this.parent = parent;
    this.insightType = insightType || INSIGHT_TYPE['wave']
    this.selectedDemographic = selectedDemographic
  }

  get showSemaphore() {
    return false;
  }

  get sequence() {
    return parseInt(this.data.sequence_field)
  }

  get insightData() {
    return new InsightsData(this.data?.chain_data, this.insightType, this.selectedDemographic)
  }

  get child() {
    return [];
  }

  get visible() {
    return !this.data.chain_data?.hide;
  }

  get layer() {
    return this.insightData?.layer || 0;
  }

  get id() {
    return this.insightData?.id || '';
  }

  get chainName() {
    return this.insightData?.name || '';
  }

  get question() {
    return this.insightData?.question || this.insightData?.display_name || '';
  }

  get name() {
    return this.insightData?.display_name || '';
  }

  get semaphore() {
    return this.insightData.semaphore;
  }

  get semaphoreAnotherPath() {
    return this.insightData.semaphoreAnotherPath;
  }

  orderedInsights(filterDemographic = false) {
    return this.insightData.orderedInsights(filterDemographic);
  }

  get orderedAnotherInsights() {
    return this.insightData.orderedAnotherInsights;
  }

  get hash() {
    return { value: this.id, label: this.name };
  }

  get relationType() {
    return this.data.model_type;
  }

  get isOutOfTree() {
    return this.relationType === 'none';
  }

  get view() {
    return this.insightData.view;
  }

  get isChart() {
    return isPresent(this.insightData.output);
  }

  isNonBrandMetric(_arg) {
    return false;
  }
  isAdvertisingMetric(_arg) {
    return false;
  }

  get hasDynamicChains() {
    return false;
  }

  get measure() {
    if (this.insightData.measure === 'none') return '';

    return this.insightData.measure;
  }

  get isPercentage() {
    return this.measure === '%';
  }

  get metrics() {
    return []
  }

  get allLevelMetrics() {
    return uniqAnalysisRecords(this.metrics.flatMap(m => m.allLevelMetrics))
  }

  get chartMetrics() {
    return this.metrics.filter(m => m.isDisplayMetric)
  }

  get allChartMetrics() {
    return this.allLevelMetrics.filter(m => m.isDisplayMetric)
  }
}

export class AnalysisMetric extends BaseAnalysis {
  get metrics() {
    return sortMetrics(this.data, this)
  }

  get trendMetrics() {
    return this.metrics.filter(metric => metric.relationType === 'trend')
  }

  get chartType() {
    return this.insightData.name;
  }

  get node_index() {
    return this.insightData.node_index;
  }

  get link() {
    if (this.analysisParent == null) return this.id;

    return `${this.analysisParent.allMetricsLink}/${this.id}`
  }

  get idDisplayInSelect() {
    if (this.view === 'dynamic' && !this.isOutOfTree) {
      return false;
    }

    return this.isDisplayMetric
  }

  get isDisplayMetric() {
    if (!this.isChart) return false;
    if (skipAnalysisRecord(this)) return false;
    if (this.hasDynamicChains) return this.isOutOfTree;

    if (isPresent(this.orderedInsights())) return true;
    if (isBrandFunnel(this) || isNPSChart(this)) return true;
    if (this.metrics.some(m => m.isDisplayMetric || m.hasDynamicChains)) return true;

    return this.hasChartData;
  }

  get analysisParent() {
    if (this.parent == null) return null;

    let parent = this.parent;
    while(!(parent instanceof Analysis)) {
      parent = parent.parent;
    }
    return parent;
  }

  isBrandMetric(goal) {
    return this.insightData.isBrandMetric(goal);
  }

  isNonBrandMetric(goal) {
    return this.insightData.isNonBrandMetric(goal);
  }

  isAdvertisingMetric(goal) {
    return this.insightData.isAdvertisingMetric(goal);
  }

  get hasDynamicChains() {
    return this.insightData.hasDynamicChains;
  }

  get hasChartData() {
    return this.insightData.hasChartData;
  }

  get getKPIParent() {
    if (this.parent instanceof AnalysisKPI) return this.parent;
    if (this.parent instanceof AnalysisMetric) return this.parent.getKPIParent;

    return null;
  }

  get subTitle() {
    return this.insightData.subTitle;
  }
}

export class AnalysisKPI extends BaseAnalysis {
  constructor(data, parent = null, insightType= null, selectedDemographic = null) {
    super(data, parent, insightType, selectedDemographic);
    this._checkKpiBrandFunnel()
  }

  get metrics() {
    return sortMetrics(this.data, this);
  }

  get allLevelMetrics() {
    const result = [];
    const forEachMetric = (metric) => {
      result.push(metric);
      metric.metrics.forEach(forEachMetric)
    }
    this.metrics.forEach(forEachMetric)
    return qSortArray(uniqAnalysisRecords(result), true, m => m.name)
  }

  // get child() {
  //   return this.metrics;
  // }

  get link() {
    return `${this.parent.link}/kpi/${this.id}`
  }

  get isBrandFunnelMetricMissed() {
    if (this.data.chain_data.name !== 'kpi_brand_funnel') return false;
    if (this.data.metrics.length <= 1) return false;

    return !this.data.metrics.some(({ chain_data }) => chain_data.model === "brandFunnel")
  }

  _checkKpiBrandFunnel() {
    if (isBlank(this.data.metrics)) return false;
    if (!this.isBrandFunnelMetricMissed) return false;

    this.data.metrics = [
      {
        chain_data: { ...this.data.chain_data, ...BASE_BRAND_FUNNEL_DATA },
        metrics: [...this.data.metrics],
        model_type: "res",
        sequence_field: 0
      },
      ...this.data.metrics
    ]
  }
}

export class AnalysisContributingInsight extends BaseAnalysis {
  get showSemaphore() {
    return true;
  }

  get kpis() {
    return qSortArray((this.data.kpis || []).map(hash => new AnalysisKPI(hash, this, this.insightType, this.selectedDemographic)),
      true, analysisSortValue)
  }

  get child() {
    return this.kpis;
  }

  get link() {
    return `${this.parent.link}/${this.id}`
  }
}

export class AnalysisGoal extends BaseAnalysis {
  get goalData() {
    return new InsightsData(this.data)
  }
  get goalSlug() {
    return this.goalData.id;
  }
  get goalLink() {
    return `/homepage/${TABS_URLS.dsight}/${this.goalSlug}`
  }
  get goalName() {
    return capitalizeAllWords(this.goalData.goal);
  }
}

export default class Analysis extends BaseAnalysis {
  get showSemaphore() {
    return true;
  }

  get contributingInsights() {
    return qSortArray((this.data.contributing_insights || []).map(hash => new AnalysisContributingInsight(hash, this, this.insightType, this.selectedDemographic)),
      true, analysisSortValue)
  }

  get child() {
    return this.contributingInsights;
  }

  get link() {
    return `/dsight/${this.data.section}/${this.data.slug}/analysis`
  }

  get allMetricsLink() {
    return `${this.link}/metrics`;
  }

  get metrics() {
    const result = [];
    const forEachMetric = (metric) => {
      result.push(metric);
      metric.metrics.forEach(forEachMetric)
    }
    this.contributingInsights.forEach(insight => {
      insight.kpis.forEach(kpi => {
        kpi.metrics.forEach(forEachMetric)
      })
    })
    return qSortArray(result, true, m => m.name)
  }

  get isProductPerformance() {
    return this.chainName === 'dec_performance'
  }

  get isImageryAttributes() {
    return ['dec_creative', 'dec_positioning', 'dec_competition'].includes(this.chainName)
  }
}
