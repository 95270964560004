import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import { postUserMessage } from "../../../store/decision/actions";
import { isBlank, successActions } from "../../../helpers/common";
import { isEnter } from "../../../helpers/keys_helpers";
import Button from "react-bootstrap/Button";
import DiscussionMentionsInput from "./DiscussionMentionsInput";
import { reloadContacts } from "../../../store/contacts/actions";
import { mentioneeWarning } from "../../../helpers/alert_helpers";
import { resetDecisionSetData } from "../../../store/decision_set/common_actions";

export const DiscussionInput = ({ postUserMessage, reloadContacts, resetDecisionSetData }) => {
  const [userMessage, setUserMessage] = useState('')
  const [submit, setSubmit] = useState(false)
  const [notifyCollaborators, setNotifyCollaborators] = useState(false)

  const postMessageRequest = (make_mentionee_participant = false) => {
    postUserMessage({
      feedback: { description: ref.current.userMessage(), notify_all: notifyCollaborators },
      mentions: ref.current.newMentioned().map(h => h['email']),
      all_new_mentions: ref.current.mentioned().map(h => h['email']),
      make_mentionee_participant
    }, (success) => {
      setSubmit(false)
      if (success) {
        ref.current.clear()
        setUserMessage('')
        successActions(make_mentionee_participant, [reloadContacts, resetDecisionSetData])
      }
    })
  }

  const postNewMessage = () => {
    setSubmit(true)
    mentioneeWarning(ref.current.newMentioned(), postMessageRequest)
  }

  const onKeyDown = (e) => {
    if(isEnter(e.keyCode)) postNewMessage();
  }
  const onChange = ({userMessage}) => setUserMessage(userMessage)

  const ref = useRef(null);

  return <div className="side-panel bottom">
    <div className="d-flex mb-2">
      <div className="w-100 pe-2 message-input-max-width">
        <DiscussionMentionsInput ref={ref} onKeyDown={onKeyDown} placeholder="Type @ to mention someone" initialValue={userMessage} onChange={onChange} />
      </div>
      <div>
        <Button disabled={isBlank(userMessage) || submit} onClick={postNewMessage} className="w-100 border-0">Send</Button>
      </div>
    </div>
    <div className="mb-0 lh-checkbox">
      <input type="checkbox" id="notify-checkbox" name="notify-checkbox"
              className="custom-checkbox"
              checked={notifyCollaborators}
              onChange={() => { setNotifyCollaborators(!notifyCollaborators) }}/>
      <label htmlFor="notify-checkbox" className="mb-0">Notify all collaborators of this comment</label>
    </div>
  </div>
};
export default connect(null, {
  postUserMessage, reloadContacts, resetDecisionSetData
})(DiscussionInput);
