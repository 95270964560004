import React from 'react';
import { SectionName } from "./ZTreeSectionNode";
import {EditRoundButton} from "../../../common/EditRoundButton";
import SetDropdown from "./actions/SetDropdown";
import {connect} from "react-redux";
import {openModal} from "../../../store/modals/actions";
import {openModalCallback} from "../../../template_set_view/modals/SetNameModal";
import {copyDecisionSetSection, dragDecisionSetSection} from "../../../store/decision_set/actions";
import {updateDecisionSetData} from "../../../store/decision_set/common_actions";
import Button from "react-bootstrap/Button";
import {MOVE_TYPES} from "../../wizard/ZTree";
import {dragTemplateSetSection} from "../../../store/template_set/actions";
import {DECISION_ZTREE_ID} from "../ZTree";
import DecisionSet from "../../../models/decision_set";

const ZTreeHoverSectionNode = ({ current_user, copyDecisionSetSection, updateDecisionSetData, openModal, treeNode,
                                 set, isTemplateSet, hideEdit = false, dragDecisionSetSection, dragTemplateSetSection, ...opts }) => {
  const copyTreeNode = (e) => {
    e.preventDefault();

    const {slug} = treeNode;
    copyDecisionSetSection({ slug }, (success) => {
      if(success) updateDecisionSetData()
    });
  }
  const sectionPosition = set.sections.findIndex(element => element.slug === treeNode.node.slug)
  const isFirst = sectionPosition === 0
  const isLast = sectionPosition === set.sections.length - 1

  const treeObj = $.fn.zTree.getZTreeObj(DECISION_ZTREE_ID);
  const nodes = treeObj.getNodes()[0]?.children?.filter(node => node.isSectionNode) || [];

  const saveSetSections = () => {
    const section_slugs = treeObj.getNodes()[0]?.children?.map(el => el.slug) || []
    isTemplateSet ?
      dragTemplateSetSection(treeNode.slug, { section_slugs }) :
      dragDecisionSetSection(treeNode.slug, { section_slugs })
  }

  const moveUp = (e) => {
    e.preventDefault()
    treeObj.moveNode(nodes[sectionPosition - 1], nodes[sectionPosition], MOVE_TYPES.prev);
    saveSetSections()
  }
  const moveDown = (e) => {
    e.preventDefault()
    treeObj.moveNode(nodes[sectionPosition + 1], nodes[sectionPosition], MOVE_TYPES.next);
    saveSetSections();
  }
  const isManager = isTemplateSet || new DecisionSet(set, current_user).isManager;

  return <div className="d-flex align-items-center h-inherit">
    <SectionName treeNode={treeNode}/>
    {
      hideEdit ? null :
        <div className="ms-auto">
          {
            treeNode.isRoot ?
              isManager && <EditRoundButton onClickAction={openModalCallback(openModal, set)}/> :
              <>
                <Button onClick={moveUp} disabled={isFirst} className="btn btn-secondary btn-sm btn-sm-round text-primary me-1">
                  <i className="fas fa-arrow-up fa-lg"/>
                </Button>
                <Button onClick={moveDown} disabled={isLast} className="btn btn-secondary btn-sm btn-sm-round text-primary me-1">
                  <i className="fas fa-arrow-down fa-lg"/>
                </Button>
                <SetDropdown btnId="share-header-dropdown"
                             {...{...opts, isTemplateSet, set, treeNode, openModal, copyTreeNode}}
                />
              </>
          }
        </div>
    }
  </div>
}

const mapStateToProps = ({ current_user }) => ({ current_user });
const mapDispatchToProps = (dispatch) => ({
  copyDecisionSetSection: (data) => dispatch(copyDecisionSetSection(data)),
  updateDecisionSetData: (data) => dispatch(updateDecisionSetData(data)),
  openModal: (data) => dispatch(openModal(data)),
  dragDecisionSetSection: (slug, data) => dispatch(dragDecisionSetSection(slug, data)),
  dragTemplateSetSection: (slug, data) => dispatch(dragTemplateSetSection(slug, data))
});
export default connect(mapStateToProps, mapDispatchToProps)(ZTreeHoverSectionNode);
