import { useEffect } from "react";
import { ForecastScenario } from "../../models/forecast/ForecastScenario";
import { isPresent } from "../../helpers/common";
import { MESSAGES, showOverlayWithMessage } from "./custom_loading_overlay";
import { extractDriverName, updateCells, onCellValueChanged, runDelayedUpdateData } from "./common";
import { FACTS_HEADER, ROW_DRIVER_ID_KEY, SALES_FACTS } from "./ag_grid_vars";

const replaceCurrentValue = (list, rowNode, forecastScenario, preparedImportedRows, delayedUpdateData) => {
  const periods = forecastScenario.periods();
  const childRowNode = rowNode.allLeafChildren[0];
  const importedRow = preparedImportedRows.find(row => row[ROW_DRIVER_ID_KEY] === childRowNode.data[ROW_DRIVER_ID_KEY]);
  if(isPresent(importedRow)) {
    periods.forEach(period => {
      const newValue = importedRow[period.name];
      if(newValue !== childRowNode.data[period.name]) {
        list.push({
          node: { ...childRowNode, prevValue: childRowNode.data[period.name] },
          colDef: { field: period.name, colId: period.id },
          newValue
        })
        delayedUpdateData.push({ node: childRowNode, key: period.name, value: newValue })
      }
    })
  }
}

const initImportedScenario = (forecast_simulator_scenario, forecastScenario) => {
  const { import_values_new_rows, imported_scenario_id } = forecast_simulator_scenario;
  return new ForecastScenario({
    id: imported_scenario_id,
    data: {},
    view_options: forecastScenario.viewOptions,
    rows: import_values_new_rows
  }, forecastScenario.config, forecastScenario.editableTimeScale);
}

const updateTableData = (gridRef, list, forecastScenario, filteredImportedRows, delayedUpdateData, editedCells, updateScenario, runModelCells, callback) => {
  gridRef.current.api.forEachNode((rowNode) => {
    if(rowNode.leafGroup) replaceCurrentValue(list, rowNode, forecastScenario, filteredImportedRows, delayedUpdateData);
  })
  onCellValueChanged(forecastScenario, [], list, gridRef, editedCells, updateScenario, forecastScenario.timeScale, runModelCells, () => {
    runDelayedUpdateData(gridRef, delayedUpdateData);
    callback();
  })
}

const updateHiddenTableData = (importedScenario, forecastScenario, list) => {
  importedScenario.rows.forEach(importedRow => {
    const period = importedRow.timePeriod;
    const row = forecastScenario.findRowBy(importedRow.cmus, period.id, importedRow.selectedDriver.id)
    if (row) {
      const prevDriverData = row.fetchDriverData({ id: row.selectedDriver.id });
      const newDriverData = importedRow.fetchDriverData({ id: importedRow.selectedDriver.id })
      if (newDriverData.base !== prevDriverData.base) {
        list.push({
          node: { id: importedRow.cmusDriverId, prevValue: parseFloat(prevDriverData.base) },
          colDef: { field: period.name, colId: period.id },
          newValue: parseFloat(newDriverData.base)
        })
      }
    }
  })
}

export const applyImportedValues = ({ gridRef, forecast_simulator_scenario, editedCells, updateScenario, forecastScenario, runModelCells }, callback) => {
  const importedScenario = initImportedScenario(forecast_simulator_scenario, forecastScenario);
  let list = [];
  let delayedUpdateData = [];
  if(forecastScenario.isEditableTimeScale) {
    const preparedImportedRows = importedScenario.preparedRowsForTable();
    const filteredImportedRows = preparedImportedRows.filter(rowData => !SALES_FACTS.includes(extractDriverName(rowData[FACTS_HEADER])))
    updateTableData(gridRef, list, forecastScenario, filteredImportedRows, delayedUpdateData, editedCells, updateScenario, runModelCells, callback);
  } else {
    updateHiddenTableData(importedScenario, forecastScenario, list);
    updateCells(gridRef, editedCells, forecastScenario, runModelCells, list, importedScenario.timeScale, updateScenario, callback)
  }
}

export const useImportValuesEffect = ({ gridRef, forecast_simulator_scenario, editedCells, updateScenario, forecastScenario, runModelCells, updateScenarioData }) => {
  useEffect(() => {
    if(forecast_simulator_scenario.import_values) {
      showOverlayWithMessage(gridRef.current?.api, updateScenarioData, MESSAGES.updating_scenario);
      setTimeout(() => {
        applyImportedValues({
            gridRef,
            forecast_simulator_scenario,
            editedCells,
            updateScenario,
            forecastScenario,
            runModelCells
          },
          () => updateScenarioData({ import_values: false, import_values_new_rows: [], imported_scenario_id: null }));
      }, 0)
    }
  }, [forecast_simulator_scenario.import_values])
}
