import React from 'react';
import { connect } from "react-redux";
import { copyChoices, setDecisionInputSidebarOpen } from "../../../store/sidebar/actions";
import { isBlank } from "../../../helpers/common";
import { isPresent } from "../../../../ms_teams/helpers/common";
import { isDSightDecision } from "../../../helpers/home_decision_helpers";
import Button from 'react-bootstrap/Button';
import { decisionObjects, isNonUser } from "./helpers";
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";

const RenderDecisionInputSidebar = ({
                                      decision, current_user, sidebar,
                                      openDecisionInputSidebar, copyChoices,
                                      addClass = '', isHamburgerBtn = false
                                    }) => {
  const {
    recommendation, recommendationObject, decisionObject
  } = decisionObjects(decision)

  if (isNonUser(current_user)) return null;
  if (isBlank(decision)) return null;
  if (decisionObject.isTreeHistorical && decisionObject.withRecommendation && !recommendationObject.isEnteredRecommendation) return null;
  if (decisionObject.isTreeRecommendation && !recommendationObject.isEnteredRecommendation && isPresent(recommendation)) return null;
  if (decisionObject.isDecisionRecorded) return null;

  const onDecisionEntryOpen = () => {
    if(isBlank(decisionObject.decisionChoices) && isPresent(recommendationObject.recommendationChoices)) {
      copyChoices((success) => {
        if (success) openDecisionInputSidebar(!sidebar.decisionInputSidebar)
      })
    } else {
      openDecisionInputSidebar(!sidebar.decisionInputSidebar)
    }
  }

  if (isHamburgerBtn) {
    return <BaseHamburgerBtn onSelect={onDecisionEntryOpen} title="Enter what was decided" />
  }

  return <Button onClick={onDecisionEntryOpen} className={`btn btn-secondary w-100 h-48 ${addClass}`}>
    {
      isDSightDecision(decision) && !recommendationObject?.isEnteredRecommendation ?
        "Enter decision" : "Enter what was decided"
    }
  </Button>
}
const mapStateToProps = ({ current_user, decision, sidebar }) => ({
  current_user, decision, sidebar
});
export default connect(mapStateToProps, {
  openDecisionInputSidebar: setDecisionInputSidebarOpen, copyChoices
})(RenderDecisionInputSidebar);
