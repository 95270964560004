import { filteredProcessData, findNodeValue } from "../../../../../models/d_sight/metric_chart_helpers";
import { isBlank } from "../../../../../helpers/common";
import { sortNodesByType } from "../../../../../helpers/metric_helpers";

export const fetchDynamicChainsNodeNames = ({ dynamic_chains }, d_sight_goal, metric) => {
  const process_data = dynamic_chains.reduce((result, dynChainData) =>
      [...result, ...filteredProcessData(dynChainData, d_sight_goal)]
    , [])
  if (isBlank(process_data)) return []

  const groupedProcessData = process_data.reduce((result, p) => {
    const nodeId = findNodeValue(p.nodes, metric?.node_index)
    if (isBlank(result[nodeId])) result[nodeId] = []
    result[nodeId].push(p)
    return result;
  }, {})

  const nodes = Object.keys(groupedProcessData).filter(node =>
    groupedProcessData[node].some(p => p.time_periods.some(val => parseFloat(val) > 0))
  ).map(node => parseInt(node))

  return sortNodesByType(metric, nodes, d_sight_goal)
}
