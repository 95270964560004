import React from 'react';
import Select from "react-select";
import {ResponseTypes} from "../../../models/decision";
import {BorderedFilterStyle} from "../../../tree_wizard/styles/choice_entry_styles";

export default ({ responseType, submitted, onChangeResponseType, classNames = '', disabledResponse = false }) =>
  <div className={`w-100 ${classNames}`}>
    <h3>Response type</h3>
    <Select value={ResponseTypes.find(type => type.value === responseType)}
            onChange={onChangeResponseType}
            components={{ IndicatorSeparator: () => null }}
            isDisabled={submitted || disabledResponse}
            placeholder="Open-ended"
            options={ResponseTypes}
            styles={BorderedFilterStyle}/>
  </div>
