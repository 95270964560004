import React from 'react';
import { connect } from "react-redux";
import { updateRecommendation } from "../../../store/sidebar/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import AccordionWrapper from "../../../common/AccordionWrapper";
import { JoditTextEditor } from '../../../common/JoditTextEditor';

const NextStepsInput = ({ decision, updateRecommendation }) => {
  if (isBlank(decision) || isBlank(decision.recommendation)) return null;

  const recommendation = decision.recommendation;
  const description = recommendation.next_steps_description
  const onChangeNextStepsDescription = (nextStepsDescription) => { updateRecommendation({ decision_recommendation: {next_steps_description: nextStepsDescription } }) };

  const accordionObject = {
    header: <h3 className="mb-0">Next steps</h3>,
    body: <JoditTextEditor className="jodit-container-default"
                           value={description}
                           placeholder="Enter any actions to take"
                           setValue={onChangeNextStepsDescription}
                           autofocus={false} />,
    defaultExpand: isPresent(description)
  };

  return <div onBlur={onChangeNextStepsDescription}>
    <AccordionWrapper accordionObject={accordionObject} className='px-3 mb-3' />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecommendation })(NextStepsInput);
