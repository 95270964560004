import {isBlank, isPresent} from "./common";
import Driver from "../models/driver";

export function answeredChildrenDrivers(driverData, isRoot = false) {
  const children = isRoot ? driverData : driverData.children
  if (isBlank(children)) return [];

  return children.filter(child => {
    const driverObj = new Driver(child.driver)
    return isPresent(child.driver.question) && driverObj.withEnteredAnswer && !driverObj.isDraft;
  });
}

export function answeredChildrenRatingDrivers(driverData, isRoot = false) {
  const children = isRoot ? driverData : driverData.children
  if (isBlank(children)) return [];

  return children.filter(child => {
    if(isBlank(child.driver.question) || isBlank(child.driver.driver_choices)) return false;

    const driverObj = new Driver(child.driver);

    return child.driver.driver_choices.some(c => isPresent(c['rating']) || isPresent(c['evaluation'])) && !driverObj.isDraft;
  });
}