import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Menu from "./SearchTemplateFiltersMenu";
import { useRootClose } from "react-overlays";
import { isDefaultTemplateFilters } from '../../../helpers/filter_helpers';

export const SearchTemplateFilters = ({
                                        home, loadAction,
                                        isSetPage = false,
                                        templateUsersFilter = false, allGroups = false
                                      }) => {
  const [show, setShow] = useState(false);
  const [saveMenu, setSaveMenu] = useState(false);
  const showBubble = !isDefaultTemplateFilters(home);
  const ref = useRef();
  const onRootCloseStart = () => {
    setSaveMenu(true);
  };
  const onRootCloseFinish = () => {
    setSaveMenu(false);
    setShow(false);
  };
  useRootClose(ref, onRootCloseStart, {disabled: !show,});
  return <div className="search-decisions-filter">
    <Button className={`btn form-control rounded text-dark fw-normal dropdown-toggle border-0 w-100 ${showBubble ? 'bg-secondary' : 'bg-white'}`} onClick={() => setShow(true)}>
      <span className={`${showBubble ? 'orange-bubble' : ''}`}>{ templateUsersFilter ? 'Categories & groups': 'Categories' }</span>
    </Button>
    {show && (
      <div ref={ref} className="filter-menu">
        <Menu {...{ saveMenu, loadAction, isSetPage, templateUsersFilter, allGroups}} onSave={onRootCloseFinish} />
      </div>
    )}
  </div>;
}

const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps)(SearchTemplateFilters);
