import {
  loadForecastSimulatorData,
  loadForecastSimulatorScenarioData,
  removeForecastSimulatorScenarioData,
  resetForecastSimulatorScenarioData,
  runModelData, syncAgGridRowsData,
  updateForecastSimulatorScenarioData, updateForecastSimulatorScenarioRowsData,
  fetchAgGridRowsData
} from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import { LOAD_SCENARIO_FAILED, UPDATE_SCENARIO_DATA, RESET_SCENARIO_DATA } from "./types";
import { isBlank } from "../../helpers/common";
import { reloadOrgForecastScenariosData } from "../current_org/actions";
import EntryPoint from "../../EntryPoint";

const checkScenario = (dispatch, getState, callback) => {
  setTimeout(() => {
    callback()(dispatch, getState)
  }, 500)
}

export const loadForecastSimulator = (scenario_id) => (dispatch, getState) => {
  if (getState().forecast_simulator_scenario.loaded && getState().forecast_simulator_scenario.scenario_id === scenario_id) return;

  dispatch(updateScenarioData({ loading: true }))
  loadForecastSimulatorData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure });

    const { data } = response;
    const benchmark_id = getState().forecast_simulator_scenario.benchmark_id === scenario_id ? null : getState().forecast_simulator_scenario.benchmark_id
    const { config, config_scenarios } = data;
    dispatch(updateScenarioData({ config, config_scenarios, benchmark_id, scenario_id, loaded: true, loading: false }));
    loadForecastSimulatorScenario(scenario_id)(dispatch, getState)
  })
}

const iterateLoadingRows = (dispatch, getState, scenario_id, {
  cmus, index = 0, rows = [], key, callbackFailure, callback = () => {}
}) => {
  const updateKeys = {}
  updateKeys[`${key}_hint`] = `Loading scenario...`
  dispatch(updateScenarioData(updateKeys));

  // Make all requests in parallel
  Promise.all(cmus.map(cmu => updateForecastSimulatorScenarioRowsData(scenario_id, { cmu })))
    .then(responses => {
      responses.forEach(response => {
        if (isResponseFailed(response)) {
          return failedResponseHandler(dispatch, { ...response, callback: callbackFailure });
        }
        const { data } = response;
        rows = [...rows, ...data.rows];
      });

      updateKeys[`${key}_hint`] = false
      updateKeys[`${key}_loaded`] = true
      updateKeys[`${key}_loading`] = false
      updateKeys[key] = {
        ...getState().forecast_simulator_scenario[key],
        rows
      }
      callback(true, rows)
      return dispatch(updateScenarioData(updateKeys));
    });
}

export const loadForecastSimulatorScenario = (scenario_id, checkLoading = true) => (dispatch, getState) => {
  if (checkLoading) {
    if (getState().forecast_simulator_scenario.scenario_loaded && getState().forecast_simulator_scenario.scenario_id === scenario_id) return;
    if (getState().forecast_simulator_scenario.scenario_loading) return;
  }

  dispatch(updateScenarioData({ scenario_loading: true }))
  loadForecastSimulatorScenarioData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadSimFailure });

    const benchmark_id = getState().forecast_simulator_scenario.benchmark_id === scenario_id ? null : getState().forecast_simulator_scenario.benchmark_id
    const { data } = response;
    const { scenario } = data;
    if (scenario.data.attributes.calculating_state !== 'completed') {
      checkScenario(dispatch, getState, () => loadForecastSimulatorScenario(scenario_id, false))
      return;
    }
    const cmus = data.cmus || scenario.cmus || []

    if (cmus.length > 0) {
      dispatch(updateScenarioData({ cmus, scenario, scenario_id, benchmark_id }));
      iterateLoadingRows(dispatch, getState, scenario_id, { cmus, key: 'scenario', callbackFailure: loadSimFailure })
    } else {
      dispatch(updateScenarioData({ scenario, scenario_id, benchmark_id, scenario_loading: false, scenario_loaded: true }));
    }
  })
}

export const loadForecastSimulatorDBenchmarkScenario = (benchmark_id, checkLoading = true) => (dispatch, getState) => {
  if (isBlank(benchmark_id)) return null;
  if (checkLoading) {
    if (getState().forecast_simulator_scenario.loading || getState().forecast_simulator_scenario.benchmark_loading) return null;
  }

  dispatch(updateScenarioData({ benchmark_loading: true }))
  loadForecastSimulatorScenarioData(benchmark_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadBenchFailure });

    const { data } = response;
    const { scenario } = data;
    if (scenario.data.attributes.calculating_state !== 'completed') {
      checkScenario(dispatch, getState, () => loadForecastSimulatorDBenchmarkScenario(benchmark_id, false))
      return;
    }
    const cmus = data.cmus || scenario.cmus || []
    if (cmus) {
      dispatch(updateScenarioData({ benchmark: scenario, benchmark_id }));
      iterateLoadingRows(dispatch, getState, benchmark_id, { cmus, key: 'benchmark', callbackFailure: loadBenchFailure })
    } else {
      dispatch(updateScenarioData({ benchmark: scenario, benchmark_id, benchmark_loading: false, benchmark_loaded: true }));
    }
  })
}

export const updateViewOptions = (newViewOptions, callback = () => {}, updateLoadingState = true) => (dispatch, getState) => {
  if (updateLoadingState) dispatch(updateScenarioData({ view_loading: true }))

  updateForecastSimulatorScenarioData(getState().forecast_simulator_scenario.scenario_id, { scenario: { view_options: newViewOptions } }).then(response => {
    if (isResponseFailed(response)) {
      return failedResponseHandler(dispatch, {
        ...response,
        callback: loadFailure
      }, callback);
    }

    const { data } = response;
    const { scenario } = data;
    if (updateLoadingState) {
      dispatch(updateScenarioData({
        scenario: {
          ...getState().forecast_simulator_scenario.scenario,
          ...scenario
        },
        view_loading: false
      }));
    } else {
      updateScenarioData({
        scenario: {
          ...getState().forecast_simulator_scenario.scenario,
          ...scenario
        }
      })
    }

    callback(true)
  })
}
export const updateScenario = (scenario_id, updateData, callback, showLoading = true) => (dispatch, getState) => {
  if(showLoading) dispatch(updateScenarioData({ loading: true }))
  updateForecastSimulatorScenarioData(scenario_id, updateData).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);
    const { data } = response;
    const { scenario } = data;
    if (scenario.data.attributes.calculating_state !== 'completed') {
      checkScenario(dispatch, getState, () => loadForecastSimulatorScenario(scenario_id, false))
      return;
    }
    dispatch(updateScenarioData({
      scenario: {
        ...getState().forecast_simulator_scenario.scenario,
        ...scenario
      },
      loading: false
    }));
    dispatch(reloadOrgForecastScenariosData())
    callback(true)
  })
}

export const loadForecastSimulatorScenarioPostRunModel = (scenario_id, cmus) => (dispatch, getState) => {
  loadForecastSimulatorScenarioData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadSimFailure });

    const { data } = response;
    const { scenario } = data;
    if (scenario.data.attributes.calculating_state !== 'completed') {
      checkScenario(dispatch, getState, () => loadForecastSimulatorScenarioPostRunModel(scenario_id, cmus))
      return;
    }
    const filteredRows = getState().forecast_simulator_scenario.scenario.rows.filter(row =>
      !cmus.some(cmusGroup => cmusGroup.every(cmu => row.attributes.cmus.includes(cmu)))
    )
    iterateLoadingRows(dispatch, getState, scenario_id, {
      cmus,
      rows: filteredRows,
      key: 'scenario',
      callbackFailure: loadSimFailure,
      callback: (status, updatedRows) => {
        if(status) {
          dispatch(updateScenarioData({
            run_model: true,
            run_model_new_rows: updatedRows,
            run_model_new_rows_cmus: cmus
          }))
        }
      }
    })
  })
}

export const runModel = (scenario_id, { drivers, cmus }, callback) => (dispatch, getState) => {
  runModelData(scenario_id, { drivers }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);

    loadForecastSimulatorScenarioPostRunModel(scenario_id, cmus)(dispatch, getState)
    callback(true)
  })
}

export const syncAgGridRows = (scenario_id, { time_scale_keys, cmus_groups }, callback) => (dispatch, getState) => {
  syncAgGridRowsData(scenario_id, { time_scale_keys, cmus_groups }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);

    callback(true)
  })
}

export const fetchAgGridRows = (scenario_id, callback) => (dispatch, getState) => {
  fetchAgGridRowsData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);

    const { data } = response;
    const { ag_grid_rows_data, ag_grid_rows_data_synced_at } = data;
    dispatch(updateScenarioData({
      scenario: {
        ...getState().forecast_simulator_scenario.scenario,
        ag_grid_rows_data: ag_grid_rows_data,
        ag_grid_rows_data_synced_at: ag_grid_rows_data_synced_at
      }
    }));
    callback(true)
  })

}

export const importValues = (scenario_id, cmus, callback) => (dispatch, getState) => {
  iterateLoadingRows(dispatch, getState, scenario_id, {
    cmus,
    key: 'imported_scenario',
    callbackFailure: loadSimFailure,
    callback: (status, importedRows) => {
      if(status) {
        dispatch(updateScenarioData({
          import_values: true,
          imported_scenario_id: scenario_id,
          import_values_new_rows: importedRows
        }))
      }
    }
  })
}

export const resetScenarioValues = (scenario_id, callback, showLoading = true) => (dispatch, getState) => {
  if(showLoading) dispatch(updateScenarioData({ scenario_loading: true }))

  resetForecastSimulatorScenarioData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);

    const { data } = response;
    const { default_values, scenario } = data;

    if(default_values) {
      if(showLoading) dispatch(updateScenarioData({ scenario_loading: false }))
      dispatch(updateScenarioData({
        scenario: {
          ...getState().forecast_simulator_scenario.scenario,
          ...scenario
        }
      }));
      callback(true, true)
    } else {
      dispatch(updateScenarioData({
        scenario: {
          ...getState().forecast_simulator_scenario.scenario,
          ...scenario
        }
      }));
      const cmus = getState().forecast_simulator_scenario.cmus
      iterateLoadingRows(dispatch, getState, scenario_id, {
        cmus,
        key: 'scenario',
        callbackFailure: loadSimFailure,
        callback: (status, _updatedRows) => callback(status, false)
      })
    }
  })
}

export const removeScenario = (scenario_id, callback) => (dispatch) => {
  dispatch(updateScenarioData({ loading: true }))
  removeForecastSimulatorScenarioData(scenario_id).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadFailure }, callback);

    dispatch(reloadOrgForecastScenariosData())
    callback(true)
  })
}

export const loadFailure = error => ({
  type: LOAD_SCENARIO_FAILED,
  payload: {
    error,
    loading: false,
    view_loading: false
  }
});

export const loadSimFailure = error => ({
  type: LOAD_SCENARIO_FAILED,
  payload: {
    scenario_error: error, scenario_loading: false
  }
});

export const loadBenchFailure = error => ({
  type: LOAD_SCENARIO_FAILED,
  payload: {
    benchmark_error: error,
    benchmark_loading: false
  }
});

export const updateScenarioData = (data) => ({
  type: UPDATE_SCENARIO_DATA,
  payload: {
    ...data
  }
});

export const resetScenarioData = () => ({ type: RESET_SCENARIO_DATA });
