import React from 'react';
import { connect } from "react-redux";
import { isLastStep } from "../../../helpers/wizard_helpers";
import { processStep, SubmitStepWrapper } from "../../../tree_wizard/steps_wizard/steps/SubmitStepButton";

const SubmitStepButton = ({ wizard, ...opts }) =>
  <SubmitStepWrapper {...opts}>
    { isLastStep(wizard) ? 'Done' : processStep(wizard) }
  </SubmitStepWrapper>

const mapStateToProps = ({ wizard }) => ({ wizard });
export default connect(mapStateToProps)(SubmitStepButton);
