import React from 'react';
import { connect } from "react-redux";
import DecisionCenterItem from "./DecisionСenterItem";
import CreateDecisionItem from "./CreateDecisionItem";
import SearchDecisionsItem from "./SearchDecisionsItem";
import DSightItem from "./DSightItem";
import LearnItem from "./LearnItem";
import GroupsItem from "./GroupsItem";
import ReportInsightsItem from "./ReportInsightsItem";
import ForecastSimulatorItem from "./ForecastSimulatorItem";
import SettingsItem from "./SettingsItem";
import { isGuest } from "../../helpers/home_helpers";
import HelpItem from "../../tree_view/navigation/HelpItem";

const NavigationItems = ({ current_user, displayCurrent }) => {
  if (isGuest(current_user)) return null;

  return <>
    <div className="sidebar-top">
      <ul className="list-unstyled m-0 p-3">
        <DecisionCenterItem displayCurrent={displayCurrent} />
        <DSightItem displayCurrent={displayCurrent} />
        <ForecastSimulatorItem displayCurrent={displayCurrent} />
        <LearnItem displayCurrent={displayCurrent} />
        <ReportInsightsItem displayCurrent={displayCurrent} />
        <CreateDecisionItem displayCurrent={displayCurrent} />
        <SearchDecisionsItem displayCurrent={displayCurrent} />
        <GroupsItem displayCurrent={displayCurrent} />
        <SettingsItem displayCurrent={displayCurrent} />
      </ul>
    </div>
    <div className="sidebar-bottom">
      <ul className="list-unstyled m-0 p-3">
        <HelpItem treeHelp={false} />
      </ul>
    </div>
  </>
}

export const SidebarNavigation = ({ current_user, displayCurrent= false }) =>
  <div id="sidebar-navigation" className={`home-sidebar bg-light-gray d-none d-lg-block`}>
    <NavigationItems displayCurrent={displayCurrent} current_user={current_user} />
  </div>

const mapStateToProps = ({ current_user }) => ({ current_user });
export default connect(mapStateToProps)(SidebarNavigation);
