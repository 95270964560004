import React from 'react';
import {Page} from '@react-pdf/renderer';
import styles from '../../styles/common'
import DocumentFooter from "./DocumentFooter";
import DocumentHeader from "./DocumentHeader";
import { isBlank } from "../../../helpers/common";
import {DataSourceImage} from "./KeyDriverPage";

const ImagesSupplement = ({ org, data_sources, format }) => {
  if (isBlank(data_sources)) return null;

  return data_sources.map(data_source =>
    <Page size={format} style={styles.container} key={data_source.key} id={data_source.key}>
      <DocumentHeader title={'Data sources'} src_id={'#details_data_sources'} description={data_source.title || data_source.filename} />
      <DataSourceImage data_source={data_source} stylesView={styles.body} />
      <DocumentFooter org={org} />
    </Page>
  )
}
export default ImagesSupplement;
