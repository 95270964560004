import React from 'react';
import { useHistory } from "react-router-dom";
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../../common/dropdowns";
import { goToHomepageCallback } from "../../../tree_wizard/header/helpers";

export const HamburgerDropdown = ({ }) => {
  const history = useHistory();
  const goToHomepage = goToHomepageCallback([], history)

  return <HamburgerMenuIconBtn>
    <BaseHamburgerBtn onSelect={goToHomepage} title="Go to homepage" />
  </HamburgerMenuIconBtn>
}

export default HamburgerDropdown;
