import React from "react";
import CurrencyInput from "react-currency-input-field";

export default ({ header = '', id, name, placeholder, value, onChange = () => {} }) =>
  <>
    {header}
    <CurrencyInput
      className="form-control"
      id={id}
      name={name}
      placeholder={placeholder}
      decimalsLimit={2}
      prefix={'$'}
      allowNegativeValue={false}
      maxLength={20}
      decimalScale={2}
      defaultValue={value}
      value={value}
      onValueChange={(value, name) => onChange(value)}
    />
  </>
