import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateHomepageCurrentTab } from "../../store/homepage/actions";
import { hideDSightTab, isDSightTab, TABS, TABS_URLS } from "../../helpers/home_helpers";

export const DSightItem = ({
                             displayCurrent,
                             home, current_user, current_org,
                             updateHomepageCurrentTab
                          }) => {
  if (hideDSightTab(current_user, current_org)) return null;

  const isDsightIconActive = displayCurrent && isDSightTab(home, current_user, current_org)
  const dsightsDefaultImg = isDsightIconActive ? `${current_org.dsight_primary_svg}` : `${current_org.dsight_gray_svg}`
  const [dsightIcon, setDsightIcon] = useState(dsightsDefaultImg)

  useEffect(() => {
    setDsightIcon(dsightsDefaultImg);
  }, [isDsightIconActive])

  return <li className={isDsightIconActive ? 'active' : ''}>
    <Link to={`/homepage/${TABS_URLS.dsight}`}
          className="text-nowrap"
          onClick={() => updateHomepageCurrentTab(TABS.dsight)}
          onMouseOver={()=>setDsightIcon(`${current_org.dsight_primary_svg}`)}
          onMouseOut={()=>setDsightIcon(dsightsDefaultImg)}>
        <span>
          <span className="icon d-inline-block text-center">
            <img src={dsightIcon} alt="DSight" className="dsights-img align-text-bottom"/>
          </span>
          <span className="d-none d-lg-inline tab-title">D-Sight</span>
        </span>
    </Link>
  </li>
}

const mapStateToProps = ({ home, current_user, current_org }) => ({ home, current_user, current_org });
export default connect(mapStateToProps, { updateHomepageCurrentTab })(DSightItem);
