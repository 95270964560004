import React from 'react';

const UserPasswordInput = ({ id, name, placeholder, value, onChange, isPassword,
                            setIsPassword, className = '',  errors = {},
                            readOnly = false, disabled = false, autoComplete = "off"
                          }) => {
  const passwordView = () => {
    setIsPassword(prevState => !prevState);
  };

  const inputClassName = () => {
    let defaultClassName = `form-control rounded ${className}`;
    if (errors['password'] || errors['base']) {
      defaultClassName += ' is-invalid';
    }
    return defaultClassName;
  };

  return (
    <div className={`position-relative ${className}`}>
      <input
        id={id}
        name={name}
        type={isPassword ? 'text' : 'password'}
        className={inputClassName()}
        autoComplete={autoComplete}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
      />
      <span className="position-absolute end-0 top-0 d-flex align-center pe-2 h-100 " onClick={passwordView} style={{ cursor: 'pointer' }}>
        <span hidden={!isPassword}>
          <i className="fa-eye-slash fa-solid fa-lg" />
        </span>
        <span hidden={isPassword}>
          <i className={`fa-eye fa-solid fa-lg`} />
        </span>
      </span>
    </div>
  );
};

export default UserPasswordInput;

