import React from 'react';
import { StatusAndQuestion } from "./ZTreeDriverNode";
import AddDriver from './actions/AddDriver';
import EditDriver from './actions/EditDriver';
import EditDecision from './actions/EditDecision';
import EditRecommendation from './actions/EditRecommendation';
import RemoveDriver from './actions/RemoveDriver';
import { connect } from "react-redux";
import Decision from "../../../models/decision";

const ZTreeHoverDriverNode = ({ key, tree, decision, treeNode, isTemplate = false, ...opts }) => {
  const decisionObject = new Decision(decision)
  const editRecommendation = !isTemplate && decisionObject.withRecommendation && !decisionObject.isDecisionRecorded
  // jsx
  return <div className="d-flex align-items-center h-inherit" key={key}>
    <StatusAndQuestion treeNode={treeNode}
                       classes={`selectable-area ${tree.copied_ztree_node.slug === treeNode.slug ? 'copied' : ''} text-truncate`} />
    <div className="ms-auto">
      <AddDriver {...{ ...opts, treeNode, isTemplate }} />
      {
        !treeNode.isDecision ? <EditDriver {...{ ...opts, treeNode, isTemplate }} /> :
          editRecommendation ? <EditRecommendation {...{ ...opts, treeNode, isTemplate }} /> :
            <EditDecision {...{ ...opts, treeNode, isTemplate }} />
      }
      <RemoveDriver treeNode={treeNode} />
    </div>
  </div>
}
const mapStateToProps = ({ tree, decision }) => ({ tree, decision });
export default connect(mapStateToProps)(ZTreeHoverDriverNode);
