import React from 'react';

export default ({
                  title= null,
                  items=[], selectedItem,
                  onClick,
                  padding = 'px-4',
                  disabled = false,
                  fullWidth = false,
                  addClass = ''
                }) =>
  <div className={`d-inline-flex mw-fit-content ${disabled ? '' : ''} ${fullWidth ? 'w-100' : ''} ${addClass}`}>
    <h3>{title}</h3>
    <ul className={`nav nav-pills nav-fill bg-light-gray text-dark user-notifiers rounded ${disabled ? '' : ''} ${fullWidth ? 'w-100' : ''}`}>
      {
        items.map(item =>
          <li key={item.value} className="nav-item">
            <a className={`nav-link text-dark ${selectedItem === item.value ? 'bg-white' : disabled ? '' : 'pointer'} ${padding}`} onClick={() => disabled || onClick(item.value)}>
              {item.body}
            </a>
          </li>
        )
      }
    </ul>
  </div>
