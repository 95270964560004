import React from 'react';
import { Link } from "react-router-dom";
import UserAvatarImage from "../../../common/UserAvatarImage";
import CreatedData from "./CreatedData";

export default ({ scenario, user }) =>
  <div className="col-12 mb-2">
    <Link to={`/forecast_simulator/scenarios/${scenario.id}`}
          className="text-dark text-center">
      <div className="homepage-list-col">
        <div className="d-flex align-items-center list-col-content bg-white p-2">
          <div className="text-start overflow-hidden lh-sm">
            <div className="text-nowrap text-ellipsis fw-bolder">
              {scenario.data.attributes.display_name}
            </div>
            <CreatedData scenario={scenario} classNames={'text-muted'}/>
          </div>
          <div className="d-flex ps-2 ms-auto">
            <div className={`collaborators text-end`}>
              <UserAvatarImage key={`${scenario.id}`} user={user}/>
            </div>
          </div>
        </div>
      </div>
    </Link>
  </div>
