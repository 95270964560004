import React, { useEffect } from "react";
import {connect} from "react-redux";
import { isBlank } from "../../../helpers/common";

const ExpandDriver = ({ tree, driverData, expanded }) => {
  if(isBlank(driverData)) return null;

  useEffect(() => {
    if(tree.loaded) {
      const icon = document.getElementById(`expand-driver-icon-${driverData.driver.slug}`);
      if(expanded) {
        icon.classList.add('up');
        icon.classList.remove('down', 'first-load');
      } else {
        icon.classList.remove('up');
        icon.classList.add('down');
      }
    }
  }, [expanded, driverData]);

  return isBlank(tree.loaded) ?
    <></> :
    <span className={`expand-driver text-primary fw-bolder pointer ms-1 ${expanded ? 'h5' : ''}`}>
      <i id={`expand-driver-icon-${driverData.driver.slug}`}
         className={`fas fa-chevron-down down first-load arrow fa-sm`} />
    </span>
}

const mapStateToProps = ({ tree }) => ({ tree });
export default connect(mapStateToProps, {})(ExpandDriver);