import {
  LEARN_LOAD_DATA,
  LEARN_LOAD_DATA_FAILED,
  LEARN_LOAD_DATA_STARTED,
  LEARN_RESET_DATA,
  LEARN_RESET_FILTERS
} from "./types";
import { fetchLearnData, fetchLearnTemplateData } from "../../utils/Api";
import {
  failedResponseHandlerWithDispatchCallback,
  isResponseFailed,
  isResponseRedirected
} from "../../helpers/store_helpers";

export const loadLearnData = () => (dispatch) => {
  dispatch(loadLearnStarted({}));
  fetchLearnData().then((response) => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadLearnFailure });
    const { data } = response;
    if (isResponseRedirected(response)) return document.location.href = data.redirect_path;

    const { learn_data } = data;
    dispatch(loadLearnSuccess({
      statistics: { ...learn_data },
      current_template: null,
      template_name: null,
      loaded: true
    }));
  })
}
export const loadLearnTemplateData = (template_id) => (dispatch) => {
  dispatch(loadLearnStarted({}));
  fetchLearnTemplateData(template_id).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadLearnFailure });
    const { data } = response;
    if (isResponseRedirected(response)) return document.location.href = data.redirect_path;

    const { learn_data, template_name } = data;
    dispatch(loadLearnSuccess({
      statistics: { ...learn_data },
      current_template: template_id,
      template_name,
      loaded: true
    }));
  })
}

export const loadLearnSuccess = (data) => ({
  type: LEARN_LOAD_DATA,
  payload: {
    ...data
  }
})

export const resetFilters = () => ({
  type: LEARN_RESET_FILTERS
});
export const resetLearnData = () => ({
  type: LEARN_RESET_DATA
});

export const loadLearnStarted = (query = {}) => ({
  type: LEARN_LOAD_DATA_STARTED,
  payload: {
    ...query
  }
})

export const loadLearnFailure = error => ({
  type: LEARN_LOAD_DATA_FAILED,
  payload: {
    error
  }
});
