import React, { useState } from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import BuyInDetailsModal from "../../modals/BuyInDetailsModal";
import { isBlank } from "../../../helpers/common";
import {collectUserSupports, collectUserSupportsLegacy} from "../../../helpers/decision_helpers";

export const BuyInDetailsSection = ({ decision, isLegacy = false }) => {
  if (!isLegacy && isBlank(decision.rate_support)) return null;

  const userSupports = isLegacy ? collectUserSupportsLegacy(decision) : collectUserSupports(decision);
  if (isBlank(userSupports)) return null;

  const [showBuyInDetailsModal, setShowBuyInDetailsModal] = useState(false)

  return <div className="px-3 mb-2">
    <Button onClick={() => setShowBuyInDetailsModal(true)} className="btn btn-secondary w-100 mb-2" hidden={isBlank(decision.rate_support) && isBlank(userSupports)}>
      Show buy-in details
    </Button>
    <div className="modals">
      <BuyInDetailsModal {...{userSupports, isLegacy}} shown={showBuyInDetailsModal} onClose={() => setShowBuyInDetailsModal(false)} />
    </div>
  </div>
};
const mapStateToProps = ({ decision }) => ({ decision });
export default connect(mapStateToProps, {})(BuyInDetailsSection);
