import React  from "react";
import { connect } from "react-redux";
import DecisionDate, { DATEPICKER_TYPES } from "../../../../common/DecisionDate";
import { isBlank } from "../../../../helpers/common";
import * as moment from "moment";

const DecisionDateInput = ({ decision, decisionDate, setDecisionDate }) => {
  if (isBlank(decision)) return null;

  const onChangeDate = (date) => {
    setDecisionDate(moment(date))
  };

  return <div className="w-100 mb-3">
    <DecisionDate id={DATEPICKER_TYPES.historical_decided_at}
                  name={DATEPICKER_TYPES.historical_decided_at}
                  maxDate={new Date()}
                  header="Decision date"
                  date={decisionDate} onChangeDate={onChangeDate}/>
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps)(DecisionDateInput);