import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Analysis, { skipAnalysisRecord } from "../../models/d_sight/Analysis";
import { SemaphoreIcon } from "./DSightBadge";
import { isBlank } from "../../helpers/common";
import { analysisLink } from "../../helpers/dsight_helpers";

export const RightAlignedSemaphoreIcon = (props) =>
  <span className="float-end mw-max-content ms-2">
    <SemaphoreIcon {...props} />
  </span>

export const LeftAlignedSemaphoreIcon = (props) =>
  <span className="float-start mw-max-content me-2">
    <SemaphoreIcon {...props} />
  </span>

const DecisionBlock = ({ analysisObj }) =>
  <div className="w-100 bg-white rounded p-3 mb-3">
    <h3 className="d-flex justify-content-between">
      Q: {analysisObj.insightData.question}
      <RightAlignedSemaphoreIcon semaphore={analysisObj.semaphore}/>
    </h3>
    <h4 hidden={isBlank(analysisObj.insightData.answerInsight)}>
      A: {analysisObj.insightData.answer}
    </h4>
    {analysisObj.orderedInsights().map(insight =>
      <div key={insight.id} className="mt-2">
        <div className="dsights-icon--primary h-17 ps-3">{insight.insight}</div>
      </div>
    )}
  </div>

export const RecordInsight = ({ insight }) =>
  <div className="mt-2">
    <div className="dsights-icon--primary h-17 ps-3">{insight.insight}</div>
  </div>

export const RecordInsights = ({ insights }) =>
  isBlank(insights) ? null :
    <div>
      {insights.map(insight => <RecordInsight key={insight.id} insight={insight}/>)}
    </div>

export const AnalysisRecordHeaderLink = ({ title, semaphore, link, hideLink }) =>
  <h3 className="d-flex justify-content-between">
    {
      hideLink ? title :
        <Link to={analysisLink(link)} className={'d-flex'}>
          <span className="text-dark">{title}</span>
          <i className="fas fa-external-link-alt ms-2"/>
        </Link>
    }
    {
      semaphore == null ? null : <RightAlignedSemaphoreIcon semaphore={semaphore}/>
    }
  </h3>

export const ContributingInsightSection = ({ insight, hideLink = false, addClass = '' }) =>
  <div className={`${addClass} w-100`}>
    <div className={'h-100 bg-white rounded p-3'}>
      <AnalysisRecordHeaderLink hideLink={hideLink} link={insight.link} title={insight.question}
                                semaphore={insight.semaphore}/>
      <RecordInsights insights={insight.orderedInsights()}/>
    </div>
  </div>

const ContributingInsightBlock = ({ insight, hideLink = false }) => {
  if (skipAnalysisRecord(insight)) return null;

  return <div className={'d-flex flex-column flex-md-row mb-3'}>
    <ContributingInsightSection insight={insight} hideLink={hideLink} addClass={''}/>
    <div className="mt-2 mt-md-0 ms-0 ms-md-3 sidepanel-minwidth">
      <div className={'h-100 bg-white rounded p-3'}>
        <h3>Contributing KPIs</h3>
        {insight.kpis.map(kpi =>
          <RelatedRecordBlock record={kpi} key={`contributing-insight-block-${insight.id}-kpi-${kpi.id}`}/>
        )}
      </div>
    </div>
  </div>
}

export const RelatedRecordBlock = ({ record }) => {
  if (skipAnalysisRecord(record)) return null;

  return <div className="mt-1 d-flex">
    <Link to={analysisLink(record.link)}>
      {record.name}
    </Link>
  </div>
}

const AnalysisContent = ({ analysis }) => {
  const analysisObj = new Analysis(analysis, null)
  return <div className={`container mt-3`}>
    <h1>Decision</h1>
    <DecisionBlock analysisObj={analysisObj}/>
    <h1>Contributing insights</h1>
    {analysisObj.contributingInsights.map(insight => <ContributingInsightBlock insight={insight}
                                                                               key={`contributing-insight-block-${insight.id}`}/>)}
  </div>
}
export const mapStateToProps = ({ analysis }) => ({ analysis });
export default connect(mapStateToProps)(AnalysisContent);
