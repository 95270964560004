import React from 'react';
import { connect } from "react-redux";
import OpenEndedChoices from "./choices/OpenEndedChoices";

const OpenEndedDecisionInput = ({ decision, openEndedChoice, setOpenEndedChoice, flowEnum }) => {
  return <div className="mb-3">
    <h3 className="align-self-center text-ellipsis">{decision.description}</h3>
    <OpenEndedChoices openEndedChoice={openEndedChoice} setOpenEndedChoice={setOpenEndedChoice} flowEnum={flowEnum}/>
  </div>;
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, {})(OpenEndedDecisionInput);
