import React from 'react';
import { connect } from "react-redux";
import {setMembersSidebarOpen} from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import { isMobile } from "react-device-detect";

const MembersPanel = ({ sidebar, openSideBar, children }) =>
  <SlidingPanel
    type="left"
    isOpen={sidebar.decisionMembersSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper details-panel-width template-panel left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
    size={52}
  >
    <PanelHeader headerName={'Members'} preview={true} {...{openSideBar}} />
    {children}
  </SlidingPanel>

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => { dispatch(setMembersSidebarOpen(options)); }
});
export default connect(mapStateToProps, mapDispatchToProps)(MembersPanel);
