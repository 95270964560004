import { UPDATE_ALERTS_DATA } from "./types";

const initialState = {
    shown: false,
    text: '',
    type: '',
    addClass: '',
    duration: 0
};

export function alertReducer(
    state= initialState,
    action
) {
    switch (action.type) {
        case UPDATE_ALERTS_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
}
