import React from 'react';
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooterP0 } from "../../common/modals";
import Image from "react-bootstrap/Image";

export default ({shown, sourceObj, onClose = () => {}}) => {
  const source = sourceObj.data_source

  return <Modal size="lg" backdrop="static" show={shown} onHide={onClose}>
    <Modal.Body bsPrefix="modal-body">
      <div className="d-flex w-100 mb-3">
        <h2 className="me-auto text-truncate">{source.title}</h2>
        <CloseIcon onClose={onClose} />
      </div>
      <div className='text-center'>
        <Image className='border mw-100' src={source.url} alt={source.title} title={source.title} />
      </div>
    </Modal.Body>
    <ModalDoneFooterP0 onClose={onClose} />
  </Modal>
}
