import React from 'react';
import { connect } from "react-redux";
import { destroyChoice, updateChoice, updateFinalChoices } from "../../../../store/sidebar/actions";
import { isPresent } from "../../../../helpers/common";
import Decision from "../../../../models/decision";
import {CheckForm} from "../../../../tree_wizard/helpers/DecisionFlowRow";

export const sortedRatedChoices = (decision, drivers) => {
  const decisionObj = new Decision(decision, drivers);
  return decisionObj.isRateAndCompareDriverChoicesHidden || decisionObj.isTreeRecommendation ?
    decisionObj.sortedChoices(true) :
    decisionObj.sortedRatedChoices;
}

export const DisplayChoiceItem = ({ choice, key, selected, onSelectChoice }) =>
  <div className="d-flex">
    <div className={`btn text-start mb-2 sidebar-choice-width w-100 h-auto ${selected ? 'btn-primary' : 'btn-secondary'}`} key={key} onClick={onSelectChoice}>
      <div className={`choice-description`}>
        {choiceRank(choice)}
        {choice.description}
      </div>
    </div>
  </div>

export const PickListChoiceItem = ({ choice, selected, onSelectChoice, boxType, namePrefix= '' }) => {
  const name = namePrefix ? `${namePrefix}-choices` : 'choices';

  return (
    <div className="d-flex mb-2">
      <CheckForm
        type={boxType}
        id = {choice.slug}
        label={
          <div className="text-start w-100 h-auto choice-description pointer note_paragraph">
            {choiceRank(choice)}
            {choice.description}
          </div>
        }
        name={name}
        checked={selected}
        onClick={() => onSelectChoice(choice.slug)}
        className="check-form-pointer"
      />
    </div>
  );
}

const generateChoiceItemState = ({ choice, selected, updateFinalChoices }) => {
  return {
    onSelectChoice: () => {
      updateFinalChoices(choice.slug, { description: choice.description, final_decision: !selected, reset_final: true });
    }
  }
}

const choiceRank = (choice) => choice.final_decision && isPresent(choice.final_decision_rank) ? `${choice.final_decision_rank}. ` : ''

export const ChoiceItem = ({
                      choice, selected = false, key = '', object,
                      ...opts
                   }) => {
  const { ...choiceProps } = generateChoiceItemState({ choice, selected, ...opts })

  if(object.isPicky) {
    return <PickListChoiceItem choice={choice} selected={selected} boxType="checkbox" {...choiceProps} />
  } else if(object.isTopChoice) {
    return <PickListChoiceItem choice={choice} selected={selected} boxType="radio" {...choiceProps} />
  } {
    return <DisplayChoiceItem choice={choice} key={key} selected={selected} {...choiceProps} />;
  }
};
export default connect(null, { destroyChoice, updateChoice, updateFinalChoices })(ChoiceItem);
