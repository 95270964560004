import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import { Routes as routes } from "../../routes";
import { TABS_URLS } from "../../helpers/home_helpers";

export const RegularHelpText = () =>
  <Fragment>
    <p>
      Use the template options menu in the upper right to edit the tree, decision question, or users.
      Select <strong>Edit template users</strong> to limit who can view this template on
      the <Link to={`${routes.homepage}/${TABS_URLS.create_decision}`}>Create a decision</Link> page and start
      decisions from it. Select <strong>Duplicate template</strong> to create a new template that matches this one.
      You will be able to review and edit the duplicated template prior to publishing it.
    </p>
    <p>
      Use the Details panel to edit the default categories, context, collaborators, and data sources of future
      decisions created from this template.
    </p>
  </Fragment>

export default RegularHelpText;
