import React, { Fragment, useState } from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../common/CloseIcon";
import { isBlank } from "../../../helpers/common";
import {
  DSightAnalysisAnswerBlock,
  DSightAnalysisHeaderLink,
  DSightAnalysisInsightsBlock,
} from "../../../tree_view/modals/DriverShowModal";
import Driver from "../../../models/driver";
import {
  mapModalDriverWithSourcesToProps,
  PreviewDriverBody
} from "../../../tree_view/modals/PreviewDriverDetailsModal";
import { findDriverData } from "../../../tree_view/accordion_tree/shared/helpers";
import { transferDataAndCloseModal, updateDriver } from "../../../store/modals/actions";
import { ModalDoneFooter } from "../../../common/modals";
import NotesRow, { updateNotesCallback } from "../../../tree_editor/modals/helpers/NotesRow";
import debounce from "lodash.debounce";

export const openDSightDriverModal = (decision, slug, {drivers, data_sources}, openModal) => openModal({ decision, drivers, data_sources, slug, type: 'DSightDriverModal' })
export const showDSightDriverModal = (modal) => modal.type === "DSightDriverModal" && modal.shown;

const DSightDataSection = ({
                             driverObj, scope, insightsData, template
                           }) =>
  <Fragment>
    <DSightAnalysisHeaderLink linkTo={`/dsight/${scope}/${template.slug}/analysis/${driverObj.dsightChainId}`} containerClass={'mb-2'} headerClass={'h3'} />
    <DSightAnalysisAnswerBlock insightsData={insightsData} />
    <DSightAnalysisInsightsBlock insightsData={insightsData} keyPreffix={'dsight-driver-modal'} decision={template} driverObj={driverObj} />
  </Fragment>

const DSightDriverModal = ({
                             template, driverData, driver, driverDataSources,
                             shown, scope = 'decisions', edit = false,
                             onClose, updateDriver, transferDataAndCloseModal
                           }) => {
  if (isBlank(driverData)) return null;

  const driverObj = new Driver(driver)
  const prevNotes = driver.notes || '';
  const { slug } = driver;
  const [notes, setNotes] = useState(driverData.driver.notes || '');

  const debouncedUpdateDriver = debounce(() => {
    updateDriver({ slug, notes: notes });
  }, 500);

  const close = () => {
    if (edit) {
      if(prevNotes !== notes) {
        debouncedUpdateDriver.cancel()
        updateDriver({ slug, notes: notes }, (_status) => {
          transferDataAndCloseModal()
        });
      } else {
        transferDataAndCloseModal()
      }
    } else {
      onClose()
    }
  }
  if (edit) {
    updateNotesCallback(prevNotes, slug, notes, debouncedUpdateDriver)
  }

  return <Modal size="lg" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <div>
        <CloseIcon onClose={close} classParams="float-end ms-2"/>
        <h2>Insight</h2>
      </div>
      <h3>{driver.question}</h3>
      {
        edit ?
          <NotesRow {...{ notes, setNotes }} /> :
          <PreviewDriverBody driver={driver} driverDataSources={driverDataSources} />
      }
      <DSightDataSection driverObj={driverObj} scope={scope} insightsData={driverObj.insightsData} template={template} />
    </Modal.Body>
    <ModalDoneFooter onClose={close} />
  </Modal>
}
const mapStateToProps = ({modal, tree}) => {
  const driverData = findDriverData(tree, modal.slug);
  return {
    ...mapModalDriverWithSourcesToProps({ modal }),
    modal, driverData,
    template: modal.decision
  }
}
export default connect(mapStateToProps, { updateDriver, transferDataAndCloseModal })(DSightDriverModal);
