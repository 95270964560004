import React from 'react';
import DoneButton from '../../../common/DoneButton';
import { isMobile } from "react-device-detect";

const Footer = ({ addClass = '', onClose }) =>
  <div className={`${isMobile ? 'mb-5' : 'side-panel bottom'} py-3 ${addClass}`} style={{zIndex: 0}}>
    <DoneButton onClose={onClose} />
  </div>

export default Footer;
