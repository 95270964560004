import React, { useState } from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import { createRequestAccess } from '../utils/Api'
import { RequestAccessStyles } from './RequestAccessStyles'
import Image from "react-bootstrap/Image";

const REQUEST_ACCESS_IMG_WIDTH = '115px';
const REQUEST_ACCESS_IMG_HEIGHT = '155px';
const REQUEST_SENT_IMG_WIDTH = '115px';
const REQUEST_SENT_IMG_HEIGHT = '170px';

export const ErrorImageWrapper = ({ children }) =>
  <div className="error-image text-center">
    {children}
  </div>

export const RequestAccessWrapper = ({children}) =>
  <div className="container">
    <div className="bg-white rounded p-3 my-3 mx-auto error-content" style={RequestAccessStyles.contentMaxWidth()}>
      {children}
    </div>
  </div>

const onSendRequest = ({ decisionSlug, current_user, setSentRequest }) => {
  createRequestAccess(decisionSlug, { email: current_user.email }).then(response => {
    const { data, ok } = response
    if(ok || data['status'] === 'ok') {
      setSentRequest(true)
    }
  })
};

const RequestAccess = ({ decisionSlug, home, current_user }) => {
  const [sentRequest, setSentRequest] = useState(false);

  if(sentRequest) {
    return <RequestAccessWrapper>
      <div className="header text-center">
        <h1 className="text-success">Request sent!</h1>
      </div>
      <p className="text-center">We'll notify you when your request is approved.</p>
      <ErrorImageWrapper>
        <Image src={home.request_access_sent_image_url} alt="No permissions" width={REQUEST_SENT_IMG_WIDTH} height={REQUEST_SENT_IMG_HEIGHT} />
      </ErrorImageWrapper>
    </RequestAccessWrapper>
  } else {
    return <RequestAccessWrapper>
      <div className="header text-center">
        <h1>Sorry!</h1>
      </div>
      <p className="text-center">You don't have permission to view that decision.</p>
      <div className="btn-container text-center pb-3">
        <Button bsPrefix="btn btn-primary w-100" onClick={() => onSendRequest({decisionSlug, current_user, setSentRequest})}>
          Request access
        </Button>
      </div>
      <ErrorImageWrapper>
        <Image src={home.request_access_image_url} alt="No permissions" width={REQUEST_ACCESS_IMG_WIDTH} height={REQUEST_ACCESS_IMG_HEIGHT} />
      </ErrorImageWrapper>
    </RequestAccessWrapper>
  }
}
const mapStateToProps = ({ home, current_user }) => ({ home, current_user });
export default connect(mapStateToProps)(RequestAccess);
