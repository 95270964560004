import React from 'react';
import { connect } from "react-redux";
import DecisionCard from './cards/DecisionCard';
import DriverCard from './cards/DriverCard';
import {
  setDriverInputSidebarOpen,
  setDecisionInputSidebarOpen,
  setRecommendationInputSidebarOpen,
  setFinalDecisionSidebarOpen
} from "../../store/sidebar/actions";
import { updateTreeData } from "../../store/tree/common_actions";
import { resetDriverInputSidebar } from "../../store/sidebar/common_actions";
import { closeModal, transferDataAndCloseModal, updateDecision } from "../../store/modals/actions";
import { isLeftSidebarOpen } from "../../helpers/sidebar_helpers";
import FollowupResultsModal, { showFollowupResultsModal } from "../modals/FollowupResultsModal";
import {
  treeInitData,
  useDecisionRecordedSidebarHandlerEffect,
  useRecommendationFlowChangedEffect
} from "./shared/helpers";
import Decision from "../../models/decision";
import { DecisionModal } from "../side_panel/decision_input/DecisionInputBody";
import { fetchContacts } from "../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import { isPresent } from "../../helpers/common";
import PollCard from "./cards/PollCard";
import DecisionPollModal from "../modals/DecisionPollModal";
import {ArrowUpIcon} from "../../icons/custom_icons";
import {isDSightDecision} from "../../helpers/home_decision_helpers";

export const DriversHeader = ({ decisionObject, rootDrivers }) =>
  decisionObject.isRecordedOrRecommended && isPresent(rootDrivers) ? <h1>Drivers</h1> : <></>

const Tree = ({ tree, decision, user, sidebar, modal, channels, contactsData, setDriverInputSidebarOpen,
                setDecisionInputSidebarOpen, setRecommendationInputSidebarOpen, closeModal, resetDriverInputSidebar,
                setFinalDecisionSidebarOpen, transferDataAndCloseModal, updateDecision, updateTreeData }) => {
  const { decisionObject, rootDrivers } = treeInitData(tree, decision)
  const collaborators = fetchContacts({}, contactsData, true, true)

  useDecisionRecordedSidebarHandlerEffect(sidebar, decisionObject, setFinalDecisionSidebarOpen)
  useRecommendationFlowChangedEffect(sidebar, decisionObject, setRecommendationInputSidebarOpen,
    setDecisionInputSidebarOpen)

  return <div className="container-fluid p-0">
    <div className={`px-3 px-md-0 my-3 ${isLeftSidebarOpen(sidebar) ? 'mx-auto mx-md-3 vertical-tree-grow' : 'mx-auto'} vertical-tree`}>
      <DecisionCard {...{
        decision, sidebar, collaborators, contactsData, decisionObject, user, setDecisionInputSidebarOpen,
        setRecommendationInputSidebarOpen, showArrow: rootDrivers.length > 0 ||
          (!decisionObject.isOpenEnded && decisionObject.isPoll && !isDSightDecision(decision))
      }} />
      <PollCard showArrow={rootDrivers.length > 0 && !decisionObject.isDecisionRecorded && !decisionObject.isEnteredRecommendation}
                showBefore = {!decisionObject.isDecisionRecorded && !decisionObject.isEnteredRecommendation}/>
      <DriversHeader {...{decisionObject, rootDrivers}} />
      {
        rootDrivers && rootDrivers.length > 0 ?
          <div className={`position-relative ml-border-0 ${decisionObject.isRecordedOrRecommended && isPresent(rootDrivers) ? 'overflow-hidden' : ''}`}>
            {rootDrivers.map((driverData, index) =>
                <DriverCard key={`driver-card-${driverData.driver.slug}`}
                            indentStep={1}
                            {...{
                              driverData, contactsData, decisionObject, user, collaborators, tree,
                              sidebar, channels, setDriverInputSidebarOpen, resetDriverInputSidebar,
                              updateTreeData, classNames: index === 0 && decisionObject.isRecordedOrRecommended && isPresent(rootDrivers) ? 'mb-2' : 'my-2',
                              shortBorderToArrow: rootDrivers.length === 1 || index === rootDrivers.length - 1
                            }} />
            )}
          </div> : null
      }
    </div>
    <div className="modals">
      <FollowupResultsModal key={`followup-results-modal-${modal.slug}`} show={showFollowupResultsModal(modal)} onClose={closeModal}/>
      <DecisionModal decisionObj={new Decision(decision)}
                     transferDataAndCloseModal={transferDataAndCloseModal}
                     updateDecision={updateDecision}
                     modal={modal} />
      <DecisionPollModal key={`decision-poll-modal-${modal.slug}`}
                         show={modal.type === "DecisionPollModal" && modal.shown}
                         onClose={transferDataAndCloseModal} />
    </div>
  </div>
}
const mapStateToProps = ({ tree, decision, modal, current_user, sidebar, channels, contacts }) => ({
  tree, modal, sidebar, channels,
  decision: decision,
  user: current_user,
  contactsData: contacts
});
export default connect(mapStateToProps, {
  closeModal, setDriverInputSidebarOpen, setDecisionInputSidebarOpen, transferDataAndCloseModal,
  setRecommendationInputSidebarOpen, resetDriverInputSidebar, setFinalDecisionSidebarOpen, updateDecision, updateTreeData
})(Tree);
