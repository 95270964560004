import React from 'react';
import { isBlank } from "../../../../helpers/common";
import {
  filteredProcessData,
  filterSurveysWithChartData,
  lastSurveys,
  uniqDemographics
} from "../../../../models/d_sight/metric_chart_helpers";
import FilteredChartPlaceholder from "./FilteredChartPlaceholder";
import BrandLineChart from "./brand/BrandLineChart";
import { AdvLineChart } from "./advertising/AdvLineChart";
import { NonBrandLineChart } from "./other_types/NonBrandLineChart";

export default ({
                  d_sight_goal, metric, chartData,
                  timeNode, viewMetric = null,
                  addAvailableOptions,
                  ...opts
                }) => {
  if (isBlank(chartData)) return null;

  let surveys = filterSurveysWithChartData(lastSurveys(d_sight_goal, timeNode), chartData, timeNode)

  addAvailableOptions(uniqDemographics(chartData))
  const process_data = filteredProcessData(chartData, d_sight_goal)

  if (isBlank(process_data)) return <FilteredChartPlaceholder/>;

  const chartOpts = {
    ...opts, chartData,
    d_sight_goal, process_data, surveys, timeNode,
    metric, viewMetric
  }

  if (metric.isAdvertisingMetric(d_sight_goal)) {
    return <AdvLineChart {...chartOpts} />
  } else if (metric.isNonBrandMetric(d_sight_goal)) {
    return <NonBrandLineChart {...chartOpts} />
  } else {
    return <BrandLineChart {...chartOpts} />
  }
}
