import React from 'react';
import { setRatingsAndWeightsOpen } from "../../store/sidebar/actions";
import { connect } from "react-redux";
import Decision from "../../models/decision";

const RatingsAndWeightsItem = ({sidebar, decision, setRatingsAndWeightsOpen}) =>
  new Decision(decision).isRateAndCompareDriverChoicesHidden ? null :
    <li className={sidebar.ratingsAndWeightsSidebar ? 'active' : ''}>
      <div className="side-row text-nowrap pointer" onClick={() => setRatingsAndWeightsOpen(!sidebar.ratingsAndWeightsSidebar)}>
        <span className="icon d-inline-block text-center ">
          <i className="fas fa-scale-balanced fa-lg" />
        </span>
        <span className="d-none d-lg-inline tab-title">Ratings & Weights</span>
      </div>
    </li>

const mapStateToProps = ({ sidebar, decision, template }, { isTemplate = false }) =>
  ({ sidebar, decision: isTemplate ? template : decision });
export default connect(mapStateToProps, { setRatingsAndWeightsOpen })(RatingsAndWeightsItem);
