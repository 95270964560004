export const Routes = {
  homepage: '/homepage',
  homepage_tab: '/homepage/:tab',
  homepage_tab_item: '/homepage/:tab/:id',
  profile: '/profile',

  report_wizard: '/reports/:id/report_wizard',
  report_view: '/reports/:id',

  template_view: '/templates/:id',
  template_preview: '/templates/:id/preview',
  template_wizard: '/templates/:id/wizard',
  template_set_view: '/template_sets/:id',
  template_set_add_decision_view: '/template_sets/:id/add_template',
  template_set_preview: '/template_sets/:id/preview',
  decision_set_view: '/decision_sets/:id',
  decision_set_add_decision_view: '/decision_sets/:id/add_decision',
  decision_set_search_view: '/decision_sets/:id/search',

  tree_view: '/decisions/:id/tree_viewer',
  tree_wizard: '/decisions/:id/tree_wizard',

  decision_state: '/decisions/:id/state',

  dsight_template_view: '/dsight/templates/:id',
  dsight_template_preview: '/dsight/templates/:id/preview',

  dsight_metrics: '/dsight/goals/:goal_id/metrics',
  dsight_metric: '/dsight/goals/:goal_id/metrics/:metric_id',

  dsight_analysis: '/dsight/:section/:id/analysis',
  dsight_analysis_insight: '/dsight/:section/:id/analysis/:insight_id',
  dsight_analysis_insight_kpi: '/dsight/:section/:id/analysis/:insight_id/kpi/:kpi_id',
  dsight_analysis_metrics: '/dsight/:section/:id/analysis/metrics',
  dsight_analysis_metric: '/dsight/:section/:id/analysis/metrics/:metric_id',

  dsight_tree_view: '/dsight/decisions/:id/tree_viewer',
  dsight_ask_cloverpop_conversation: '/ask_cloverpop/goals/:goal_id/conversations/:conversation_id',
  dsight_ask_cloverpop: '/ask_cloverpop/goals/:goal_id/conversations',

  forecast_simulator_scenario: '/forecast_simulator/scenarios/:id',

  learn_details: '/learn/details',
  learn_template_details: '/learn/details/:id',

  root: '/',
  sign_in: '/users/sign_in',
  reset_password: '/users/password/new',
  change_reset_password: '/users/password/edit',
  submit_reset_password: '/users/password',
  login_link: '/login_link',
  invites_landing_page: '/invites_landing_page/:id',
  quick_driver_entry: '/quick_driver_entry/:id',
  quick_poll_entry: '/quick_poll_entry/:id',
  user_registration: '/users',
  invite_create_password: '/passwords/invite_create_password',

  slack: '/slack',
  slack_bone: '/slack_bone',
  slack_success: '/slack/success'
};
