import React from 'react';
import { connect } from "react-redux";
import { setCollaboratorsSidebarOpen } from "../../store/sidebar/actions";

const CollaboratorsItem = ({ sidebar, openSideBar, collaboratorsInvitesLength = 0 }) =>
  <li className={sidebar.collaboratorsSidebar ? 'active' : ''}>
    <div className="side-row text-nowrap pointer"
         onClick={() => openSideBar(!sidebar.collaboratorsSidebar)}>
      <span className="icon d-inline-block text-center ">
        <i className="fas fa-users fa-lg"/>
      </span>
      <span className="d-none d-lg-inline tab-title">Collaborators</span>
      <div className="rounded-pill badge-pill-char comments me-2" hidden={collaboratorsInvitesLength < 1}>{collaboratorsInvitesLength}</div>
    </div>
  </li>

const mapStateToProps = ({sidebar}) => ({sidebar});
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setCollaboratorsSidebarOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorsItem);
