import React, { useEffect, useState, useRef} from 'react';
import { isPresent } from "../../helpers/common";
import {setRatingScaleCallback} from "../modals/RateBuyInModal";

const ConfidenceBubble = ({ confidence, setConfidence, disabled, className, showAfter = true, id, isFirst, isLast, value, slug }) => {
  const inputRef = useRef(null);

  setRatingScaleCallback(inputRef, null, confidence, setConfidence, true)

  return <div className={`rating-circle ${className} ${showAfter ? '' : 'no-after mt-1 square-24'} ${confidence === value ? 'active' : (isPresent(confidence) ? 'non-active' : '')}`} key={`confidence-${id}-${slug}-${value}`}>
    <input type="radio" name="radio-group" ref={inputRef}
      id={`${id}-${slug}`} value={value}
      className={`bg-transparent ${isFirst ? 'first' : ''} ${isLast ? 'last' : ''} ${disabled ? '' : 'pointer'}`}
      disabled={disabled} />
    <label htmlFor={`${id}-${slug}`} className={`${disabled ? '' : 'pointer'}`}>
      {value}
    </label>
  </div>
}

export default ConfidenceBubble;
