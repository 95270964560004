import React from 'react';
import { Link } from "react-router-dom";
import BubbleTooltip from "../../../../bubble_tooltip/BubbleTooltip";
import { safeDescription } from "../../../../helpers/decision_helpers";
import { getScenarioIdFromParam } from "../../../../forecast_simulator_scenario/helpers/helpers";
import { isPresent, previewQueryParams } from "../../../../helpers/common";

export default ({ template }) => {
  const forecast_scenario_id = getScenarioIdFromParam(document);
  const queryParams = {
    ...(isPresent(forecast_scenario_id) && { forecast_scenario_id }),
  }

  return <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <BubbleTooltip object_hash={template}/>
    <div className="text-dark text-center">
      <div className="tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title mb-3">{safeDescription(template)}</h4>
        <Link to={`/dsight/templates/${template.slug}/preview${previewQueryParams(queryParams)}`}>
          <div className="btn btn-secondary btn-dsight align-center py-1 w-100 mb-2">
            <span>Preview decision</span>
          </div>
        </Link>
        <Link to={`/dsight/templates/${template.slug}/analysis`}>
          <div className="btn btn-primary btn-dsight py-1 w-100">
            <span className="dsights-icon--light h-16 mx-3 ps-3">Show D-Sight analysis</span>
          </div>
        </Link>
      </div>
    </div>
  </div>
}

