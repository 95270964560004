import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { loadLearnData } from "../../store/learn/actions";
import AlertsSection from "../../alerts";
import LearnHeader from "./header";
import { Loader } from "../../common/Loader";
import FiltersPanel from "./side_panel/FiltersPanel";
import PageContent from "./PageContent";
import { checkLoadingEffect, isStoreLoading } from "../../helpers/callbacks_helpers";

export const Sidebars = () =>
  <Fragment>
    <div className="sidebars">
      <FiltersPanel />
    </div>
  </Fragment>

export const Wrapper = ({ children }) =>
  <Fragment>
    <AlertsSection/>
    <LearnHeader />
    {children}
    <Sidebars/>
  </Fragment>

export const LearnDetails = ({ learn, loadLearnData }) => {
  checkLoadingEffect(learn, loadLearnData)

  if (isStoreLoading(learn)) {
    return <Wrapper>
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper>
      <PageContent />
    </Wrapper>;
  }
}
const mapStateToProps = ({ learn }) => ({ learn })
export default connect(mapStateToProps, { loadLearnData })(LearnDetails);
