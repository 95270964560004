import React from 'react';
import { isPresent } from "../../../helpers/common";
import { isDecisionApprover } from "../../../helpers/decision_helpers";

export default ({ name, isUserDecider, decision, email, deciderText, recommenderText = false }) => {
  const isApprover = isDecisionApprover(decision, email)
  const decisionManagerText = isUserDecider ? 'decision manager' : '';
  const approverText = isApprover ? 'approver' : '';
  const userDescription = [decisionManagerText, deciderText || recommenderText, approverText].filter(text => isPresent(text)).join(', ')

  return <span className="px-2 text-truncate">
      {name}{userDescription ? <span className="text-muted"> ({userDescription})</span> : ''}
    </span>
}
