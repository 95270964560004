import React, {useMemo, useState} from "react";
import Decision, {DESCRIPTION_INPUT_LIMIT} from "../../models/decision";
import CharCounter from "../../common/CharCounter";
import {RichTextEditor} from "../../common/RichTextEditor";
import {ANSWER_PLACEHOLDER} from "../../tree_view/side_panel/driver_input/DriverRichTextInput";
import {isBlank, qSortArray, stripHtmlTags} from "../../helpers/common";
import Driver from "../../models/driver";
import {DriverHeader, SelectedChoiceEntryType} from "../../tree_view/side_panel/driver_input/ChoicesDriverInput";
import {sortChoicesByCreatedAt} from "../../models/decision_choices";
import {ChoiceItem} from "../../tree_view/side_panel/driver_input/choices/ChoiceItem";
import NumericInput from "../../tree_view/side_panel/driver_input/NumericInput";

const ChoiceDriverInput = ({driver, choices, setChoices}) =>
  <div className="mb-2 px-3">
    <DriverHeader driver={driver} />
    <SelectedChoiceEntryType driver={driver}  />
    <ChoicesSection {...{driver, choices, setChoices}} />
  </div>

const ChoicesSection = ({ driver, choices, setChoices}) => {
  if (isBlank(driver)) return null;

  const driverObj = new Driver(driver);

  if (driverObj.isPicky) {
    return <PickyChoices {...{driver, choices, setChoices }} />
  } else if(driverObj.isTopChoice) {
    return <TopChoice {...{driver, choices, setChoices }} />
  } {
    return null;
  }
}

const PickyChoices = ({driver, choices, setChoices}) =>
  <div>
    {
      qSortArray(choices, true, sortChoicesByCreatedAt).map((choice) =>
        <ChoiceItem driver={driver} choice={choice} key={`picky-choice-${choice.slug}`} selected={choice.final_decision}
                    choices={choices} setChoices={setChoices} notSaveChoice={true} />
      )
    }
  </div>

const TopChoice = ({driver, choices, setChoices}) =>
  <div>
    {
      qSortArray(choices, true, sortChoicesByCreatedAt).map((choice) =>
        <ChoiceItem driver={driver} choice={choice} key={`top-choice-${choice.slug}`} selected={choice.final_decision}
                    choices={choices} setChoices={setChoices} notSaveChoice={true} />
      )
    }
  </div>

const DriverRichTextInput = ({slug, driver, answer, onChangeDriver, showChar, setShowChar}) => {
  const isExceedLimit = useMemo(() => stripHtmlTags(answer || '').length > DESCRIPTION_INPUT_LIMIT, [answer])

  const Counter = () =>
    <CharCounter show={showChar} field={stripHtmlTags(answer || '')}
                 limit={DESCRIPTION_INPUT_LIMIT}
                 extraClassName={`driver-badge-pill ${isExceedLimit ? 'warning' : ''}`}
    />

  return <>
    <SummaryHeader driver={driver.driver} />
    <div id={`driver_answer`} className='mb-3 px-3' onBlur={() => setShowChar(false)} onFocus={() => setShowChar(true)}>
      <Counter />
      <RichTextEditor quillId={`summary-input-${slug}`} theme = 'bubble'
                      value={answer || ''}
                      setValue={newValue => onChangeDriver(newValue, 'answer')}
                      className={`mb-2 ${showChar ? 'primary-box-shadow' : ''}`}
                      placeholder={ANSWER_PLACEHOLDER} />
    </div>
  </>
}

const NumericRow = ({ driver, answer, onChangeDriver }) =>
  <>
    <SummaryHeader driver={driver} />
    <div className='px-3 mb-3'>
      <NumericInput driver={driver}
                    value={answer}
                    setValue={newValue => onChangeDriver(newValue, 'answer')} />
    </div>
  </>

const SummaryHeader = ({driver}) => <h3 className='px-3'>{driver.question}&nbsp;
  <span className="required-muted-text text-muted">(required)</span>
</h3>

const SummaryInput = ({ driver, driverData, onChangeDriver, decision, responseChoices, setResponseChoices }) => {
  const {slug, answer} = driverData
  const [showChar, setShowChar] = useState(false);
  const decisionObject = new Decision(decision);
  const driverObj = new Driver(driver.driver);

  if (!decisionObject.isRateAndCompareDriverChoicesHidden) return null;

  return driverObj.isOpen ?
    <DriverRichTextInput {...{slug, driver, answer, onChangeDriver, showChar, setShowChar}} /> :
    driverObj.isNumbering ?
      <NumericRow {...{driver: driver.driver, answer, onChangeDriver}} /> :
      <ChoiceDriverInput {...{driver: driver.driver, choices: responseChoices, setChoices: setResponseChoices}} />
}

export default SummaryInput;