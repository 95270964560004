import React from "react";
import { connect } from "react-redux";
import { updateTreeData } from "../../../../store/tree/common_actions";
import BaseDriverRemove from "../../../../common/BaseDriverRemove";

const DriverRemove = ({ stepRef, tree, treeNode, onRemoveDriver, updateTreeData }) => {
  const deleteCallback = confirmed => {
    if (confirmed) {
      const newDrivers = onRemoveDriver(treeNode.slug)
      stepRef.current.submitStep({ drivers: newDrivers, finish_later: true }, (success) => {
        if(success && tree.loaded) updateTreeData({ drivers: newDrivers });
      })
    }
  }
  return <BaseDriverRemove treeNode={treeNode} deleteCallback={deleteCallback} />
}
const mapStateToProps = ({ tree }) => ({ tree });
export default connect(mapStateToProps, { updateTreeData })(DriverRemove);
