import React, {useMemo} from 'react';
import Select from 'react-select';
import { BorderedFilterStyle300Menu } from "../../../tree_wizard/styles/choice_entry_styles";
import {isBlank} from "../../../helpers/common";

const formatFromOption = ({ label, start_date }) => ({ label, value: start_date })
const formatToOption = ({ label, end_date }) => ({ label, value: end_date })

const PeriodSelectors = ({ to, setTo, from, setFrom, options }) => {
  const validToOptions = useMemo(() => {
    return (isBlank(from) ? options : options.slice(options.map(option => option.start_date).indexOf(from) + 1)).map(formatToOption)
  }, [options, from])
  const validFromOptions = useMemo(() => {
    return (isBlank(to) ? options : options.slice(0, options.map(option => option.end_date).indexOf(to))).map(formatFromOption)
  }, [options, to])

  return <div className="px-3 mt-3 mb-0">
    <div className="row">
      <div className='col-sm-6'>
        <h3>From</h3>
        <Select
          defaultValue={validFromOptions.find(option => option.start_date === from)}
          onChange={(option) => setFrom(option.value)}
          components={{ IndicatorSeparator: () => null }}
          styles={BorderedFilterStyle300Menu}
          placeholder="Make a selection"
          value={validFromOptions.find(opt => opt.value === from)}
          options={validFromOptions} />
      </div>
      <div className='col-sm-6'>
        <h3>To</h3>
        <Select
          defaultValue={validToOptions.find(option => option.end_date === to)}
          onChange={(option) => setTo(option.value)}
          components={{ IndicatorSeparator: () => null }}
          styles={BorderedFilterStyle300Menu}
          placeholder="Make a selection"
          value={validToOptions.find(opt => opt.value === to)}
          options={validToOptions} />
      </div>
    </div>
  </div>
}
export default PeriodSelectors;
