import React, { useEffect } from "react";
import {
  extractDriverName,
  runModelCellsByRowId,
  getPeriodAgGridSuffixes,
  simAggValue
} from "./common";
import { isBlank, isPresent, uniqArray } from "../../helpers/common";
import { calcSimVsValue, calcYARootFromPrevSimVsYa } from "./ag_grid_formulas";
import { hideOverlay, MESSAGES, showOverlayWithMessage } from "./custom_loading_overlay";
import {
  TIME_SCALES,
  ROW_DRIVER_ID_KEY,
  SCENARIO_ROW_ID_SEPARATOR,
  SIM_VS_YA_KEY,
  SIM_VS_BENCHMARK_KEY,
  BENCHMARK_KEY,
  SALES_FACTS
} from "./ag_grid_vars";
import { createComparisonRows } from "./common";

const nonCurrentTimeScales = (timeScale) => TIME_SCALES.filter(ts => ts !== timeScale);

const isSimVsBmRow = (row) => row[ROW_DRIVER_ID_KEY].includes(SIM_VS_BENCHMARK_KEY);
const isSimVsYaRow = (row) => row[ROW_DRIVER_ID_KEY].includes(SIM_VS_YA_KEY);

const successRunModelCallback = (gridRef, setRunModelActive, updateScenarioData,
                                 updateCompDataCallback = () => {}, syncAgGridRowsCallback) => {
  setRunModelActive(false);
  updateCompDataCallback();
  syncAgGridRowsCallback();
  hideOverlay(gridRef.current.api);
  updateScenarioData({ run_model: false, run_model_new_rows: [], run_model_row_ids_to_update: [] });
}

const updateRunModelCells = (gridRef, runModelCells) => {
  let needUpdate = false;
  let updatedRunModelCells = [];
  gridRef.current.api.forEachNode((rowNode) => {
    const salesNode = SALES_FACTS.includes(extractDriverName(rowNode.key));
    const localRowNode = salesNode ? rowNode.parent : rowNode;
    const periodAgGridSuffixes = getPeriodAgGridSuffixes({ node: localRowNode });
    const cells = runModelCellsByRowId(runModelCells, rowNode.id);
    cells.forEach(cellData => {
      const newValue = simAggValue(salesNode ? { node: rowNode } : { node: localRowNode }, cellData.periodId, periodAgGridSuffixes);
      if (newValue) {
        needUpdate = true
        cellData.value = newValue;
        cellData.request_run_model_at = '';
        cellData.run_model_at = new Date().toISOString();
      }
      updatedRunModelCells.push(cellData);
    })
  })
  return { needUpdate, updatedRunModelCells }
}

const recalculateRow = (group, row, cells, benchmarkRow) => {
  cells.forEach(cellData => {
    const benchmarkRootValue = benchmarkRow[cellData.field];
    const rootValue = cellData.value;
    if(isSimVsBmRow(row)) {
      row[cellData.field] = calcSimVsValue(rootValue, benchmarkRootValue);
    }
    if(isSimVsYaRow(row)) {
      const yARootValue = calcYARootFromPrevSimVsYa(cellData.prev_value, row[cellData.field]);
      row[cellData.field] = calcSimVsValue(rootValue, yARootValue);
    }
  })
  return row;
}

const recalculateOpenedGroups = (gridRef, forecastScenario, updatedRunModelCells) => {
  return forecastScenario.openedGroups.map(group => {
    if(!group.output) return group;

    const groupData = { ...group };
    const cells = runModelCellsByRowId(updatedRunModelCells, groupData.id);
    if(cells.length === 0) return groupData;

    const benchmarkRow = groupData.added_rows.find(row => row[ROW_DRIVER_ID_KEY].includes(BENCHMARK_KEY));
    groupData.added_rows = groupData.added_rows.map(row => recalculateRow(groupData, row, cells, benchmarkRow))
    return groupData;
  })
}

const recalculateOpenedGroupsFromAnotherTimeScale = (gridRef, forecastScenario, forecastBenchmarkScenario, runModelRowsIds, editedCellsRowIds) => {
  const editedCellsParentRowsIds = editedCellsRowIds.map(rowId => gridRef.current.api.getRowNode(rowId).parent.id);
  return forecastScenario.openedGroups.map(group => {
    if (!runModelRowsIds.includes(group.id) && !editedCellsParentRowsIds.includes(group.id)) return group;

    const groupData = { ...group };
    const node = gridRef.current.api.getRowNode(groupData.id);
    groupData.added_rows = createComparisonRows(forecastScenario.config, { node }, forecastScenario, forecastBenchmarkScenario)
    return groupData;
  })
}

const updateCompDataCallback = (gridRef, updatedOpenedGroups, output = false) => {
  const allRowsToUpdate = output ?
    updatedOpenedGroups.filter(group => group.output).flatMap(group => group.added_rows) :
    updatedOpenedGroups.flatMap(group => group.added_rows);
  gridRef.current.api.applyTransaction({ update: allRowsToUpdate });
}

const syncAgGridRowsCallback = (forecast_simulator_scenario, syncAgGridRows, forecastScenario) => () => {
  syncAgGridRows(forecastScenario.local_id, {
    time_scale_keys: nonCurrentTimeScales(forecastScenario.timeScale),
    cmus_groups: forecast_simulator_scenario.run_model_new_rows_cmus
  })
}

const updateTableData = (forecastScenario, forecast_simulator_scenario, gridRef, runModelCells, setRunModelActive, updateScenarioData, updateScenario, newRowsForTable, syncAgGridRowsCallback) => {
  const { needUpdate, updatedRunModelCells} = updateRunModelCells(gridRef, runModelCells);
  if (needUpdate) {
    const updatedOpenedGroups = recalculateOpenedGroups(gridRef, forecastScenario, updatedRunModelCells);
    updateScenario(forecastScenario.local_id, {
      update_data: { run_model_cells: updatedRunModelCells, opened_groups: updatedOpenedGroups },
      ag_grid_rows_data: { [forecastScenario.timeScale]: newRowsForTable }
    }, () => successRunModelCallback(gridRef, setRunModelActive, updateScenarioData, () => updateCompDataCallback(gridRef, updatedOpenedGroups, true), syncAgGridRowsCallback));
  } else {
    successRunModelCallback(gridRef, setRunModelActive, updateScenarioData, () => {}, () => {});
  }
}

const completeRunModelCells = (forecastScenario,
                               forecastBenchmarkScenario,
                               editedCells,
                               runModelCells,
                               gridRef,
                               setRunModelActive,
                               updateScenario,
                               updateScenarioData,
                               newRowsForTable,
                               syncAgGridRowsCallback,
                               runModelRowsIds,
                               editedCellsRowIds) => {
  const updatedOpenedGroups = recalculateOpenedGroupsFromAnotherTimeScale(gridRef, forecastScenario, forecastBenchmarkScenario, runModelRowsIds, editedCellsRowIds);
  updateScenario(forecastScenario.local_id, {
    update_data: {
      opened_groups: updatedOpenedGroups,
      edited_cells: editedCells.map(cellData => ({ ...cellData, need_recalculation: false })),
      run_model_cells: runModelCells.map(cell => ({
        ...cell,
        run_model_at: new Date().toISOString(),
        request_run_model_at: ''
      }))
    },
    ag_grid_rows_data: { [forecastScenario.timeScale]: newRowsForTable },
  }, () => successRunModelCallback(gridRef, setRunModelActive, updateScenarioData, () => updateCompDataCallback(gridRef, updatedOpenedGroups), syncAgGridRowsCallback));
}

export const useRunModelEffect = ({
                                    gridRef,
                                    gridReady,
                                    editedCells,
                                    forecast_simulator_scenario,
                                    forecastScenario,
                                    forecastBenchmarkScenario,
                                    updateScenario,
                                    setRunModelActive,
                                    runModelCells,
                                    updateScenarioData,
                                    syncAgGridRows,
                                    runModelRowsIds,
                                    editedCellsRowIds,
                                  }) => {
  useEffect(() => {
    if(gridReady && forecast_simulator_scenario.run_model) {
      forecastScenario.updateScenarioRows(forecast_simulator_scenario.run_model_new_rows);
      const newRowsForTable = forecastScenario.preparedRowsForTable(forecast_simulator_scenario.run_model_new_rows_cmus);
      gridRef.current.api.applyTransaction({ update: newRowsForTable });
      showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.updating_scenario);
      if(forecastScenario.isEditableTimeScale) {
        updateTableData(
          forecastScenario,
          forecast_simulator_scenario,
          gridRef,
          runModelCells,
          setRunModelActive,
          updateScenarioData,
          updateScenario,
          newRowsForTable,
          syncAgGridRowsCallback(forecast_simulator_scenario, syncAgGridRows, forecastScenario)
        );
      } else {
        completeRunModelCells(
          forecastScenario,
          forecastBenchmarkScenario,
          editedCells,
          runModelCells,
          gridRef,
          setRunModelActive,
          updateScenario,
          updateScenarioData,
          newRowsForTable,
          syncAgGridRowsCallback(forecast_simulator_scenario, syncAgGridRows, forecastScenario),
          runModelRowsIds,
          editedCellsRowIds,
        );
      }
    }
  }, [forecast_simulator_scenario.run_model])
}

export const prepareDataForRunModel = (editedCells, forecastScenario) => {
  const cmusList = [];
  const rowsToUpdate = editedCells.map(cellData => {
    const [simVsBmPrefix, cmus, driverId, periodId] = cellData.id.split(SCENARIO_ROW_ID_SEPARATOR);
    if(isPresent(simVsBmPrefix)) return null;
    const convertedCmus = cmus.split(',').map(Number);
    cmusList.push(convertedCmus);
    const row = forecastScenario.findRowBy(convertedCmus, periodId, driverId);
    return { rowId: row.id, driverId: driverId, value: cellData.value }
  }).filter(isPresent)
  const uniqCmusGroups = Array.from(new Set(cmusList.map(JSON.stringify)), JSON.parse);
  return {
    driversData: rowsToUpdate.reduce((acc, row) => {
      const { rowId, driverId, value } = row;
      if (isBlank(acc[rowId])) acc[rowId] = {};
      acc[rowId][driverId] = { value };
      return acc;
    }, {}),
    cmusList: uniqCmusGroups
  }
}
