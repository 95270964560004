import {
    WIZARD_LOAD_AI_DATA_FAILED,
    WIZARD_LOAD_DATA,
    WIZARD_LOAD_DATA_FAILED,
    WIZARD_LOAD_DATA_STARTED,
    WIZARD_RESET_DATA,
    WIZARD_UPDATE_DATA
} from "./types";

const initialState = {
    loading: false,
    loadingAiData: false,
    loaded: false,
    submit: false,
    disabledSubmit: false,
    error: '',
    errorAi: '',
    user_email: '',
    created_at: '',
    completed_at: '',
    flow_steps: [],
    users: [],
    admissions: [],
    step_index: 0,
    default_user_avatar_url: null,
    tree_preview: false,
};

export function wizardReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case WIZARD_LOAD_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case WIZARD_UPDATE_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case WIZARD_LOAD_DATA_STARTED: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: true
            }
        }
        case WIZARD_LOAD_DATA_FAILED: {
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        }
        case WIZARD_LOAD_AI_DATA_FAILED: {
            return {
                ...state,
                ...action.payload,
                loadingAiData: false
            }
        }
        case WIZARD_RESET_DATA: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }
}
