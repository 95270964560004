// Common functions
import { RESET_TEMPLATE_SET_DATA, UPDATE_SETS_FAILED, UPDATE_TEMPLATE_SET_DATA } from "./types";

export const updateTemplateSetData = (data) => ({
  type: UPDATE_TEMPLATE_SET_DATA,
  payload: {
    ...data
  }
});

export const resetTemplateSetData = () => ({
  type: RESET_TEMPLATE_SET_DATA
});

export const updateSetsFailure = error => ({
  type: UPDATE_SETS_FAILED,
  payload: {
    error,
    loading: false
  }
});
