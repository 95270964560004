import React, { Fragment } from 'react';
import { Highlighter, Token } from 'react-bootstrap-typeahead';
import { isGroupItem } from "../../../helpers/decision_helpers";
import { USER_ICON, USERS_GROUP_ICON } from "../../../helpers/share_helpers";

export const FILTER_BY_FIELDS = ['value','full_name','email'];
export const DEFAULT_TYPEHEAD_ATTRIBUTES = {
  multiple: true,
  flip: true,
  placeholder: "Enter a name, group, or email address",
  id:"share-input",
  labelKey: "label",
  filterBy: FILTER_BY_FIELDS
};

export const findItemByEmailCallback = (shareObject) => (email) => shareObject.availableItems.find(i => i.email === email)

export const filteredItems = (shareObject, shareToSelection) =>
  shareObject.availableItems.filter(item =>
    !shareToSelection.some(shareItem => shareItem.admittable_id === item.admittable_id && shareItem.admittable_type === item.admittable_type)
  ).map(({value = '', full_name = '', email = '', ...item}) =>
    ({ ...item, value: value || '', full_name: full_name || '', email: email || '' })
  )

export const filteredOrgItems = (shareObject, shareToSelection) =>
  shareObject.availableOrgShareItems.filter(item =>
    !shareToSelection.some(shareItem => shareItem.admittable_id === item.admittable_id && shareItem.admittable_type === item.admittable_type)
  ).map(({value = '', full_name = '', email = '', ...item}) =>
    ({ ...item, value: value || '', full_name: full_name || '', email: email || '' })
  )

export const filteredDecisionItems = (shareObject, shareToSelection) =>
  shareObject.availableDecisionShareItems.filter(item =>
    !shareToSelection.some(shareItem => shareItem.admittable_id === item.admittable_id && shareItem.admittable_type === item.admittable_type)
  ).map(({value = '', full_name = '', email = '', ...item}) =>
    ({ ...item, value: value || '', full_name: full_name || '', email: email || '' })
  )

const iconByOption = (contact) => isGroupItem(contact) ? USERS_GROUP_ICON : USER_ICON

export const renderContactItem = (option, { text }, _index) =>
  <Fragment>
    {iconByOption(option)}&nbsp;
    <Highlighter search={text}>{option.label}</Highlighter>
  </Fragment>

export const renderTag = (option, { onRemove, tabIndex, disabled }, index) =>
  <Token key={`${option.admittable_id}-${index}`} onRemove={onRemove} tabIndex={tabIndex} disabled={disabled} option={option}>
    {iconByOption(option)}&nbsp; {option.label}
  </Token>
