import React, { useCallback } from 'react';
import ContextAwareToggle from "./ContextAwareToggle";
import { Card, Accordion } from 'react-bootstrap'
import SharedWithTypeahead from "./SearchDecisionFilters/SharedWithTypeahead";
import SearchIcon from "./SearchIcon";
import { setFilterAutofocus } from "../../../helpers/filter_helpers";
import {isPresent} from "../../../helpers/common";

const SHARED_WITH = 'Shared with';

export default ({
                  sharedWithSelection, setSharedWithSelection, openedSection, setOpenedSection, nonUsers, submit
                }) => {
  const setAutoFocus = useCallback(() => setFilterAutofocus(setOpenedSection, SHARED_WITH), [])
  const filterTextArray = sharedWithSelection?.map((u) => u.name)

  return <Card className="shadow">
    <Card.Header className="bg-white border-0 pointer p-0"  onClick={setAutoFocus}>
      <ContextAwareToggle eventKey="2"  {...{ filterTextArray }} >
        {SHARED_WITH}
      </ContextAwareToggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2" className={`${isPresent(filterTextArray) ? 'show' : ''}`}>
      <Card.Body className="pt-0 px-3 pb-3">
        <SearchIcon />
        <SharedWithTypeahead
          {...{
            disabled: submit,
            values: sharedWithSelection,
            setValues: setSharedWithSelection,
            excludeValues: nonUsers,
            autoFocus: openedSection === SHARED_WITH,
            minLength: 0,
            flip: false
          }}
        />
      </Card.Body>
    </Accordion.Collapse>
  </Card>
}
