import React from 'react';
import { connect } from "react-redux";
import { setTreeSidebarOpen } from "../../store/sidebar/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import {TREE_MODES} from "../../helpers/drivers_helpers";

const DecisionTreeHamburgerButton = ({ openDecisionTreeSideBar, title = 'Decision tree', hidden = false }) =>
  !hidden && <BaseHamburgerBtn onSelect={openDecisionTreeSideBar} title={title} />

const mapDispatchToProps = (dispatch) => ({
  openDecisionTreeSideBar: () => dispatch(setTreeSidebarOpen(true, TREE_MODES.view))
});
export default connect(null, mapDispatchToProps)(DecisionTreeHamburgerButton);

