import React from 'react';
import { connect } from "react-redux";
import ChoiceItem, { sortedRatedRecommendationChoices } from './ChoiceItem';

const PickyChoices = ({decision, drivers, object}) =>
  <div>
    {
      sortedRatedRecommendationChoices(decision, drivers).map((choice) =>
        <ChoiceItem choice={choice} key={`picky-choice-${choice.slug}`} selected={choice.final_decision} object={object}/>
      )
    }
  </div>

const mapStateToProps = ({ sidebar, tree }) => ({ decision: sidebar.decision, drivers: tree.drivers });
export default connect(mapStateToProps)(PickyChoices);
