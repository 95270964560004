export const KEY_CODE = {
  enter: 13,
  numpadEnter: 176,
  esc: 27
}

export const isEnter = (key) => key && key === KEY_CODE.enter
export const isNumpadEnter = (key) => key && key === KEY_CODE.numpadEnter
export const isEsc = (key) => key && key === KEY_CODE.esc

