import { isBlank, isPresent } from "./common";
import { DSIGHT_TREE_FLOWS } from "./decision_helpers";

export const isTemplateDraft = (template) => isBlank(template.completed_at)

export const isDSightTemplate = (template) => DSIGHT_TREE_FLOWS.includes(template.flow)

export const isTemplateSetTemplate = (template) => isPresent(template.template_set_slug)

export const isObsoleteDSightTemplate = ({ d_sight }) => isPresent(d_sight?.obsolete)
