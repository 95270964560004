import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { Mention, MentionsInput } from 'react-mentions'
import { collectMentions, MENTIONED_MARKUP } from "../../../helpers/discussion_helpers";
import { isPresent } from "../../../helpers/common";
import { isEsc } from "../../../helpers/keys_helpers";

export const DiscussionMentionsInput = ({
                                          innerRef,
                                          placeholder = '', initialValue = '', disabled = false,
                                          contactsData, initialMentioned = [], isFocusInput = true,
                                          onKeyDown = () => {},
                                          onChange = () => {}
                                        }) => {
  const [userMessage, setUserMessage] = useState(initialValue)
  const users = collectMentions(contactsData)
  const [mentioned, setMentioned] = useState(users.filter(u => initialMentioned.some(m => u.id === m.id)))
  const { contacts } = contactsData

  const changeInput = (e, newValue, newPlainTextValue, mentions) => {
    const mentionedUsers = mentions.map(item => users.find(u => u.id.toString() === item.id.toString())).filter(i => isPresent(i))
    setUserMessage(e.target.value)
    setMentioned(mentionedUsers)
    onChange({ userMessage: e.target.value, mentioned: mentionedUsers })
  }
  const mentionedData = () => users.filter(u => !mentioned.some(m => u.id.toString() === m.id.toString()))
  const inputKeyDown = (e) => {
    if (isEsc(e.keyCode)) {
      setUserMessage('')
      setMentioned([])
    }
    onKeyDown(e)
  }

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.addEventListener('keydown', inputKeyDown)
      return () => {
        inputRef.current.removeEventListener('keydown', inputKeyDown)
      }
    }
  }, [inputRef.current])

  if (innerRef) {
    innerRef.current = {
      userMessage: () => userMessage,
      mentioned: () => mentioned,
      newMentioned: () => mentioned.filter(m => !contacts.find(c => c.email === m.email).has_admission),
      clear: () => {
        setUserMessage('')
        setMentioned([])
        if (onChange) onChange({ userMessage: '', mentioned: [] })
      }
    }
  }

  return <MentionsInput
    singleLine
    inputRef={inputElement => {
      if (inputElement && isFocusInput) {
        inputElement.focus();
      }
    }}
    {...{ onKeyDown, placeholder, disabled, value: userMessage, onChange: changeInput }}
    a11ySuggestionsListLabel={"Suggested mentions"}
    classNames={{
      mentions__input: 'form-control singleLine-mentions',
      mentions__mention: 'mentions-display',
      mentions__control: 'mb-2 pt-2 ps-2',
      mentions__suggestions__list: 'mentions-suggestions-list',
      mentions__suggestions__item: 'mentions-suggestions-item',
      'mentions__suggestions__item--focused': 'mentions-suggestions-item-focused'
    }}
  >
    <Mention trigger="@" data={mentionedData()} className="mentions-display" appendSpaceOnAdd markup={MENTIONED_MARKUP} />
  </MentionsInput>
}
const mapStateToProps = (state) => ({
  contactsData: state.contacts
});
const wrapper = React.forwardRef((props, ref) => (
  <DiscussionMentionsInput {...props} innerRef={ref} />
))
export default connect(mapStateToProps, null, null, { forwardRef: true })(wrapper);
