import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import { resetSearch, searchDashboardDecisions } from "../../../../store/homepage/actions";
import {equalsArraysIgnoreOrder, isBlank, isPresent} from "../../../../helpers/common";
import CategoryCard from "../CategoryCard";
import ResetFiltersCard from "../ResetFiltersCard";
import { useParams } from "react-router-dom";

export const calcSelectedCategories = (org_categories, home) =>
  org_categories.available_categories.map((category, i) =>
    ({ category: category, options: category.options.filter((option) => home.category_options.includes(option.id)) })
  ).filter((selectedCategory) => isPresent(selectedCategory.options))


const CategoriesFilterMenu = (
  {
    saveMenu,
    onSave,
    // Redux props
    home,
    org_categories,
    searchDashboardDecisions
  }) => {
  const { id } = useParams();
  const decisionSetPresent = !!id;
  const calcCategories = calcSelectedCategories(org_categories, home);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState(calcCategories);
  const [submit, setSubmit] = useState(false);
  const [openedSection, setOpenedSection]= useState('');

  const filtersChanged = useCallback(() => {
    const categoriesChanged = !equalsArraysIgnoreOrder(home.category_options, buildCategoryOptionsFilterValues);
    return categoriesChanged;
  }, [selectedCategoryOptions]);

  const buildCategoryOptionsFilterValues = useMemo(() => {
    return selectedCategoryOptions.map(optionData => optionData.options.map((o) => o.id)).flat()
  }, [selectedCategoryOptions]);

  const save = useCallback(() => {
    if(filtersChanged()) {
      setSubmit(true);
      searchDashboardDecisions({
        category_options: buildCategoryOptionsFilterValues,
        current_page: 1,
        ...(decisionSetPresent && { decision_set_slug: id })
      });
      setSubmit(false);
    }
    onSave();
  }, [selectedCategoryOptions]);

  const defaultFilters = useCallback(() => {
    return isBlank(selectedCategoryOptions);
  }, [selectedCategoryOptions]);

  const reset = useCallback(() => {
    if(defaultFilters()) return;
    setSelectedCategoryOptions([]);
  }, [selectedCategoryOptions]);

  useEffect(() => {
    if (saveMenu) {
      save();
      onSave();
    }
  }, [saveMenu]);

  return (
    <>
      <ResetFiltersCard {...{ save, reset, defaultFilters }} />
      <Accordion alwaysOpen>
        {
          org_categories.available_categories.map((category, i) =>
            <CategoryCard key={`category-card-${category.slug}`} eventKey={i}
                          {...{ category, selectedCategoryOptions, setSelectedCategoryOptions, submit, openedSection, setOpenedSection }} />
          )
        }
      </Accordion>
    </>
  );
};

const mapStateToProps = ({home, current_org, contacts, org_categories, current_user}) => ({
  home, current_org, contactsData: contacts, org_categories, user: current_user
});
const mapDispatchToProps = (dispatch) => ({
  searchDashboardDecisions: (data) => {
    dispatch(searchDashboardDecisions(data))
  },
  resetSearch: () => {
    dispatch(resetSearch())
  }
});
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CategoriesFilterMenu);