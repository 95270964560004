import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { ChoiceEntryStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import { isBlank } from "../../../helpers/common";
import { updateViewOptions } from "../../../store/forecast_simulator_scenario/actions";

const FactsSelect = ({
                       selectedDriver, setSelectedDriver,
                       config, timeScale,
                       updateViewOptions,
                       loading = false
                     }) => {
  const driversForSelect = useMemo(() => {
    const timeScaleConfig = config.timeScales.find(ts => ts.key === timeScale)
    return config.allFactsColumns.filter(dr => dr.isUsedForAggregatingSubTotals(timeScale, timeScaleConfig)).map(dr => dr.selectHash)
  }, [config, timeScale])
  const onChangeDriver = (selectedHash) => {
    updateViewOptions({ selectedDriver: selectedHash.value }, status => {
      if(status) setSelectedDriver(selectedHash.value);
    })
  }
  useEffect(() => {
    if (isBlank(selectedDriver)) {
      setSelectedDriver(driversForSelect[0]?.value)
    }
  }, [config, selectedDriver])
  return <>
    <div>
      <div className="mb-1">Facts</div>
      <Select onChange={onChangeDriver}
              defaultValue={driversForSelect.find(hash => hash.value === selectedDriver)}
              components={{ IndicatorSeparator:() => null }}
              options={driversForSelect}
              className ={"DriversSelect"}
              classNamePrefix={"DriversSelect"}
              value={driversForSelect.find(hash => hash.value === selectedDriver)}
              isDisabled={loading}
              styles={ChoiceEntryStyle} />
    </div>
  </>;
}
const mapStateToProps = ({ forecast_simulator_scenario }) => ({ forecast_simulator_scenario });
export default connect(mapStateToProps, { updateViewOptions })(FactsSelect);
