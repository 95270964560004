import {
  ForecastCmuColumn,
  forecastColumnFactory,
  ForecastDriverColumn,
  ForecastOutputColumn
} from "./ForecastColumn";
import { isBlank, isPresent, qSortArray } from "../../helpers/common";
import { ForecastTimePeriod, ForecastTimeScale } from "./ForecastTImeScale";
import { timePeriodNameParse } from "../../forecast_simulator_scenario/helpers/ag_grid_col_defs";

function getWeekNumber(d) {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  return weekNo;
}

export class ForecastConfig {
  constructor(config) {
    this.id = config.data.id;
    this.attributes = config.data.attributes;
    if (config.included) {
      this._initColumns(config.included)
      this._initTimeScales(config.included)
    }
  }

  get cmuHeaders() {
    return this.cmuColumns || []
  }

  viewDriversBy(timeScale) {
    return this.allFactsColumns.filter(column => column.visibleFor(timeScale))
  }

  // TODO: implement setting editable flag during initialization instead of checking it here
  isEditableDriver(timeScale, driverName) {
    return this.allFactsColumns.some(column => {
      return column.displayName === driverName && column.editableFor(timeScale)
    })
  }

  periodOptions(timeScale) {
    return this.getTimeScaleBy(timeScale)?.timePeriods?.map((periodData) =>
      ({
        value: periodData.attributes.start_date,
        start_date: periodData.attributes.start_date,
        end_date: periodData.attributes.end_date,
        label: timePeriodNameParse(periodData.name)
      })
    )
  }

  getTimeScaleBy(key) {
    return this.timeScales?.find(scale => scale.key === key);
  }

  isPeriodInOuterPeriod(innerPeriodId, outerPeriodId) {
    // Traverse all years in the tree
    for (const year in this.allPeriodsTree) {
      if(this.allPeriodsTree[year].periodId === innerPeriodId && innerPeriodId === outerPeriodId) return true
      if (this.allPeriodsTree.hasOwnProperty(year)) {
        // Traverse all quarters in the year
        for (const quarter in this.allPeriodsTree[year]) {
          if (this.allPeriodsTree[year].hasOwnProperty(quarter) && quarter !== 'periodId') {

            if (this.allPeriodsTree[year][quarter].periodId === innerPeriodId && this.allPeriodsTree[year].periodId === outerPeriodId)
              return true

            // Traverse all months in the quarter
            for (const month in this.allPeriodsTree[year][quarter]) {
              if (this.allPeriodsTree[year][quarter].hasOwnProperty(month) && month !== 'periodId') {
                // Check if the month's periodId matches innerPeriodId
                if (this.allPeriodsTree[year][quarter][month].periodId === innerPeriodId && (this.allPeriodsTree[year][quarter].periodId === outerPeriodId || this.allPeriodsTree[year].periodId === outerPeriodId)) {
                  return true;
                }
                // Traverse all weeks in the month
                for (const week in this.allPeriodsTree[year][quarter][month]) {
                  if (this.allPeriodsTree[year][quarter][month].hasOwnProperty(week) && week !== 'periodId') {
                    // Check if the week's periodId matches innerPeriodId
                    if (this.allPeriodsTree[year][quarter][month][week].periodId === innerPeriodId && (this.allPeriodsTree[year][quarter][month].periodId === outerPeriodId || this.allPeriodsTree[year][quarter].periodId === outerPeriodId || this.allPeriodsTree[year].periodId === outerPeriodId)) {
                      return true;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    // The inner period is not in the outer period
    return false;
  }

  get allPeriodsTree() {
    if (isBlank(this._allPeriodsTree)) {
      const allPeriods = this.timeScales.flatMap(scale => scale.timePeriods)
      allPeriods.sort((a, b) => a.startDate - b.startDate);

      this._allPeriodsTree = {};

      allPeriods.forEach(period => {
        let startDate = new Date(period.startDate);
        let endDate = new Date(period.endDate);
        let diffDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
        let diffMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();

        let year = startDate.getFullYear();
        let quarter = Math.floor(startDate.getMonth() / 3) + 1;
        let month = startDate.getMonth() + 1;
        let week = getWeekNumber(startDate);

        if (!this._allPeriodsTree[year]) {
          this._allPeriodsTree[year] = { 1: {}, 2: {}, 3: {}, 4: {} };
        }

        if (diffMonths >= 11) {
          // This is a year period
          this._allPeriodsTree[year].periodId = period.id;
        } else if (diffMonths >= 2) {
          // This is a quarter period
          if (!this._allPeriodsTree[year][quarter]) {
            this._allPeriodsTree[year][quarter] = {};
          }
          this._allPeriodsTree[year][quarter].periodId = period.id
        } else if (diffDays >= 6 && diffDays <= 8) {
          if (!this._allPeriodsTree[year][quarter]) {
            this._allPeriodsTree[year][quarter] = {};
          }
          // This is a week period
          if (!this._allPeriodsTree[year][quarter][month]) {
            this._allPeriodsTree[year][quarter][month] = {};
          }
          if (!this._allPeriodsTree[year][quarter][month][week]) {
            this._allPeriodsTree[year][quarter][month][week] = {};
          }
          this._allPeriodsTree[year][quarter][month][week].periodId = period.id;

        } else {
          if (!this._allPeriodsTree[year][quarter]) {
            this._allPeriodsTree[year][quarter] = {};
          }
          // This is a month period
          if (!this._allPeriodsTree[year][quarter][month]) {
            this._allPeriodsTree[year][quarter][month] = {};
          }
          this._allPeriodsTree[year][quarter][month].periodId = period.id
        }
      });
    }
    return this._allPeriodsTree;
  }

  get dataTimeScale() {
    return this.timeScales.find(scale => scale.isInitData)
  }

  get timeScalesOptions() {
    return this.timeScales.map(timeScale =>
      ({ value: timeScale.key, label: timeScale.displayName })
    )
  }

  _initColumns(included) {
    const allColumns = included.filter(({ type }) => type === 'col').map(hash => forecastColumnFactory(hash));
    const cmuValues = included.filter(({ type }) => type === 'cmu')
    this.cmuColumns = qSortArray(
      allColumns.filter(column => column instanceof ForecastCmuColumn), true,
      column => column.sortOrder
    )
    this.cmuColumns.forEach(cmuColumn => cmuColumn.fillValues(cmuValues))
    this.outputColumns = qSortArray(
      allColumns.filter(column => column instanceof ForecastOutputColumn), true,
      column => column.sortOrder
    )
    this.driversColumns = qSortArray(
      allColumns.filter(column => column instanceof ForecastDriverColumn), true,
      column => column.sortOrder
    )
    this.allFactsColumns = qSortArray(
      [
        ...this.outputColumns,
        ...this.driversColumns.filter(column => column.isViewInTable),
        ...this.driversColumns.map(column => column.coefficientColumn).filter(isPresent)
      ],
      true, column => column.sortOrder
    )
  }

  _initTimeScales(included) {
    const timePeriodsData = included.filter(({ type }) => type === 'tp').map(hash => new ForecastTimePeriod(hash));
    this.timeScales = included.filter(({ type }) => type === 'ts').map(hash => new ForecastTimeScale(hash));
    this.timeScales.forEach(timeScale => timeScale.fillPeriods(timePeriodsData))
  }
}
