import { isHistoricalFlow, isLastStep, isTreePreview, isWizardV3 } from "../../helpers/wizard_helpers";

export const submitTreeStep = (wizard, stepRef) => {
  stepRef?.current?.submitStep()
}

export const submitPreviewStepParam = (wizard) => {
  if (isHistoricalFlow(wizard)) return false;

  return isLastStep(wizard) && isWizardV3(wizard);
}
