import React from 'react';
import { connect } from "react-redux";
import DropdownButton from "react-bootstrap/DropdownButton";
import BaseHamburgerBtn from "../../../../common/BaseHamburgerBtn";
import AddDecision from "./AddDecision";
import RemoveSection from "./RemoveSection";
import {openModal} from "../../../../store/modals/actions";

const SetDropdown = ({
                       set, treeNode, btnId, isManager = true, openModal, isTemplateSet = false,
                       current_user, copyTreeNode, ...opts
                    }) =>
  <DropdownButton id={btnId}
                  title=""
                  className="d-inline-block"
                  bsPrefix="btn btn-secondary btn-sm btn-sm-round d-inline-block">
    <AddDecision {...{isTemplateSet, set, treeNode}} {...opts} />
    <BaseHamburgerBtn hidden={!isTemplateSet && !isManager}
                      onSelect={() => openModal({type: 'SetSectionsPanelEditSectionModal', slug: treeNode.slug, set_section: { ...treeNode }})}
                      title={`Edit section name`}/>
    { !isTemplateSet && <BaseHamburgerBtn hidden={!isManager}
                                          onSelect={copyTreeNode}
                                          title={`Duplicate section`}/>}
    <RemoveSection {...{ ...opts, treeNode, isTemplateSet }} />
  </DropdownButton>

const mapStateToProps = ({current_user}) => ({current_user})
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SetDropdown);
