import React, { useState } from 'react';
import { connect } from "react-redux";
import {isCollaborator, isDecider, isVisitor} from "../../../helpers/user_helpers";
import Button from 'react-bootstrap/Button';
import Decision from "../../../models/decision";
import RequestApprovalModal from "../../modals/RequestApprovalModal";
import {closeModal, openModal} from "../../../store/modals/actions";

export const FinalDecisionActionsSection = ({ user, decision, openModal, setShowEnterApprovalModal }) => {
  if (!isDecider(user) && !isCollaborator(user)) return null;

  const decisionObject = new Decision(decision);
  const editFollowup = decisionObject.isDecisionWithResults
  const [showRequestApprovalModal, setShowRequestApprovalModal] = useState(false)

  return <div className="px-3 mb-3">
    <div className={'mb-2'}>
      <Button onClick={() => { setShowRequestApprovalModal(true) }} className="btn btn-secondary w-100">Request approval</Button>
    </div>
    <div hidden={decisionObject.isWithApprovalInvites || decisionObject.isWithApproval || isVisitor(user)} className={'mb-2'}>
      <Button onClick={() => { setShowEnterApprovalModal(true) }} className="btn btn-secondary w-100">
        Enter approval
      </Button>
    </div>
    <div hidden={editFollowup}>
      <Button onClick={() => openModal({ type: 'FollowupResultsModal'})} className="btn btn-secondary w-100">Enter the results & actions taken</Button>
    </div>
    <div className="modals">
      <RequestApprovalModal shown={showRequestApprovalModal} onClose={() => setShowRequestApprovalModal(false)} />
    </div>
  </div>
};
const mapStateToProps = ({ current_user, decision, modal }) => ({
  decision, user: current_user, modal
});
export default connect(mapStateToProps, {closeModal, openModal})(FinalDecisionActionsSection);
