import { useEffect, useState, useMemo } from "react";
import { isFuturePeriod } from "../../models/forecast/ForecastScenario";
import { isBlank, isPresent } from "../../helpers/common";
import { getContextMenuItems, showContextMenu } from "./ag_grid_context_menu";
import CustomLoadingOverlay, { showOverlayWithMessage, MESSAGES, hideOverlay } from "./custom_loading_overlay";
import { preparedColDefs, valueSalesSum } from "./ag_grid_col_defs";
import { styledPeriodColumn, styledFactsColumn } from "./ag_grid_cell_style";
import { useApplyLargeScaleInputEffect } from "./ag_grid_large_scale_input_helpers";
import { useRunModelEffect, prepareDataForRunModel } from "./ag_grid_run_model";
import { useImportValuesEffect } from "./ag_grid_import_values";
import { extractDriverName, onCellValueChanged, rowCellIdKey } from "./common";
import { ROW_DRIVER_ID_KEY } from "./ag_grid_vars";
import {
  saveFilters,
  saveCollapsedGroups,
  saveColumnState,
  restoreForecastCookies
} from "./ag_grid_cookies";
import { checkAgGridRowsSynced } from "../../utils/Api";

const getRowStyle = (params, forecastScenario) => {
  if(isValueSalesRow(params, forecastScenario)) {
    return { border: 'none' }
  }
  if (params.node.rowIndex % 2 === 0) {
    return { background: '#FFFFFF' };
  }
}

const isValueSalesRow = (params, forecastScenario) => {
  return params.node.key === forecastScenario.valueSalesDriverName
}

const applyEditedCells = (rows, editedCells, newTimeScale) => {
  return rows.map((row) => {
    const editedCellsData = editedCells.filter(cellData => cellData.nodeId === row[ROW_DRIVER_ID_KEY] && newTimeScale === cellData.timeScale);
    if(isPresent(editedCellsData)) {
      editedCellsData.forEach(editedCellData => {
        row[editedCellData.field] = editedCellData.value;
      })
    }
    return row;
  })
}

const calcUpdatedCells = (runModelCells) => {
  return runModelCells.filter(cellData =>
    isBlank(cellData.request_run_model_at) && isPresent(cellData.run_model_at) //&& cellData.value !== cellData.default_value
  );
}

const updateScheduledCells = (timeScale, editedCells, onCellValueChangedWrapper) => {
  const cellsToUpdate = editedCells.filter(cellData => timeScale === cellData.timeScale && cellData.need_recalculation).map(cellData => {
    cellData.need_recalculation = false
    return cellData;
  });
  if(isBlank(cellsToUpdate)) return;

  onCellValueChangedWrapper([], () => {}, '', cellsToUpdate);
}

const anyRunModelCells = (runModelCells) => {
  return runModelCells.some(cellData => isPresent(cellData.request_run_model_at));
}

export const agGridInit = ({
                             forecast_simulator_scenario, setRunModelActive, gridRef,
                             config, forecastScenario, forecastBenchmarkScenario, timeScale,
                             updateScenario, setLargeScalePanelOpen, updateScenarioData, runModel, syncAgGridRows
                           }) => {
  const scenariosLoaded = useMemo(() => forecast_simulator_scenario.benchmark_loaded && forecast_simulator_scenario.scenario_loaded, [forecast_simulator_scenario.benchmark_loaded, forecast_simulator_scenario.scenario_loaded]);
  const [gridReady, setGridReady] = useState(false);
  const [editedCells, setEditedCells] = useState(forecastScenario.actualEditedCells || []);
  const [runModelCells, setRunModelCells] = useState(forecastScenario.actualRunModelCells);
  const colDefsOpts = { forecastBenchmarkScenario, forecastScenario, config, timeScale, scenariosLoaded };
  const [colDefs, setColDefs] = useState(preparedColDefs({...colDefsOpts}));
  const [rowData, setRowData] = useState([]);
  const runModelCellsIds = useMemo(() => runModelCells.filter(cellData => isPresent(cellData.request_run_model_at)).map(cellData => cellData.id), [runModelCells]);
  const editedCellsIds = useMemo(() => editedCells.map(cellData => cellData.id), [editedCells]);
  const runModelRowsIds = useMemo(() => runModelCells.map(cellData => cellData.nodeId), [runModelCells]);
  const editedCellsRowIds = useMemo(() => editedCells.map(cellData => cellData.nodeId), [editedCells]);
  const updatedCellsIds = useMemo(() => calcUpdatedCells(runModelCells).map(cellData => cellData.id), [runModelCells]);

  useEffect(() => {
    if(scenariosLoaded) {
      setColDefs(preparedColDefs({ ...colDefsOpts }));
    }
  }, [scenariosLoaded])

  useEffect(() => {
    if(gridReady) {
      const locEditedCells = forecastScenario.actualEditedCells || [];
      const locRunModelCells = forecastScenario.actualRunModelCells;
      setEditedCells(locEditedCells);
      setRunModelCells(locRunModelCells);
      setRunModelActive(anyRunModelCells(locRunModelCells));
    }
  }, [forecastScenario.updateData])

  useEffect(() => {
    if(gridReady && isPresent(forecastScenario.viewOptions.timeScale)) {
      forecastScenario.setTimeScale(forecastScenario.viewOptions.timeScale);
      const localEditedCells = forecastScenario.actualEditedCells || [];
      setColDefs(preparedColDefs({ ...colDefsOpts, timeScale: forecastScenario.timeScale }));
      const rows = forecastScenario.preparedRows;
      rows.push(...forecastScenario.addedComparisonRows);
      setRowData(applyEditedCells(rows, localEditedCells, forecastScenario.timeScale));
      updateScheduledCells(forecastScenario.timeScale, localEditedCells, onCellValueChangedWrapper);
      // to fix an issue with blank groups/Facts columns after change timeScale
      //setTimeout(() => gridRef.current?.api?.refreshClientSideRowModel(), 10)
    }
  }, [forecastScenario.viewOptions.timeScale, forecastScenario.viewOptions.from, forecastScenario.viewOptions.to])

  useRunModelEffect({
    gridRef,
    gridReady,
    editedCells,
    forecast_simulator_scenario,
    forecastScenario,
    forecastBenchmarkScenario,
    updateScenario,
    setRunModelActive,
    runModelCells,
    updateScenarioData,
    syncAgGridRows,
    runModelRowsIds,
    editedCellsRowIds,
  })
  useApplyLargeScaleInputEffect({
    gridRef,
    forecast_simulator_scenario,
    editedCells,
    updateScenario,
    forecastScenario,
    forecastBenchmarkScenario,
    runModelCells,
    updateScenarioData
  });

  useImportValuesEffect({
    gridRef,
    forecast_simulator_scenario,
    editedCells,
    updateScenario,
    forecastScenario,
    runModelCells,
    updateScenarioData
  });

  const onResetCells = (rowNode, list) => {
    const updateData = {}
    const updatedList = list.map(params => {
      const editedCell = editedCells.find(editedCell => rowCellIdKey(params) === editedCell.id)
      if(editedCell && editedCell.hasOwnProperty('default_value')) {
        params.newValue = editedCell.default_value;
        updateData[params.colDef.field] = editedCell.default_value
        return params;
      }
    }).filter(isPresent);
    onCellValueChangedWrapper(updatedList,() => {
      gridRef.current.api.applyTransaction({ update: [{ ...rowNode.data, ...updateData }] });
    }, 'reset');
  }

  const onCellValueChangedWrapper = (list, callback = () => {}, action = '', newEditedCells = []) => {
    showOverlayWithMessage(gridRef.current?.api, updateScenarioData, MESSAGES.updating_scenario);
    setTimeout(() => {
      onCellValueChanged(forecastScenario, newEditedCells, list, gridRef, editedCells, updateScenario, forecastScenario.timeScale, runModelCells, callback, action)
    }, 0)
  };

  const onGridReady = () => {
    showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.loading_rows);
    try {
      const rows = forecastScenario.preparedRows;
      rows.push(...forecastScenario.addedComparisonRows);
      setColDefs(preparedColDefs({...colDefsOpts}));
      setRowData(applyEditedCells(rows, editedCells, timeScale));
      setGridReady(true);
      hideOverlay(gridRef.current.api);
    } catch (error) {
      console.error('An error occurred during preparing rows for table:', error);
    }
  };
  const openLargeScalePanel = (driverId) => setLargeScalePanelOpen(true, driverId);
  const onRunModel = () => {
    setRunModelActive(false);
    checkAgGridRowsSynced(forecast_simulator_scenario.scenario_id).then((response) => {
      const status = response.data['status'] === 'ok';
      if (status) {
        showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.updating_scenario);
        const { driversData, cmusList } = prepareDataForRunModel(editedCells, forecastScenario);
        runModel(forecast_simulator_scenario.scenario_id, { drivers: driversData, cmus: cmusList });
      } else {
        setRunModelActive(true);
        showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.syncing_scenario);
        setTimeout(() => hideOverlay(gridRef.current.api), 3000);
      }
    })
  }

  // AG Grid settings functions
  const getRowId = useMemo(() => ((params) => params.data[ROW_DRIVER_ID_KEY]), []);
  const aggFuncs = useMemo(() =>
    ({ 'valueSalesSum': (params) => valueSalesSum(params, forecastScenario, config) }), [forecastScenario, config]);
  const isGroupOpenByDefault = params => {
    return params.field !== 'Facts' || forecastScenario.openedGroupsIds.includes(params.rowNode.id)
  }
  const defaultColDef = useMemo(() => ({
    enableCellChangeFlash: true,
    menuTabs: ["filterMenuTab", "generalMenuTab"],
  }), []);
  // Define column types
  const columnTypes = useMemo(() => ({
    styledPeriodColumn: {
      cellStyle: (params) => styledPeriodColumn(forecastScenario, params, editedCells, editedCellsIds, updatedCellsIds, config, timeScale, runModelCellsIds)
    },
    styledFactsColumn: {
      cellStyle: (params) => styledFactsColumn(params, editedCellsRowIds, config, timeScale)
    },
    editableColumn: {
      onCellValueChanged: (params) => {
        if(isPresent(params.newValue) && isPresent(params.oldValue)) {
          onCellValueChangedWrapper([params])
        } else {
          params.api.undoCellEditing();
        }
      }
    },
  }), [forecastScenario, runModelCells, editedCells, runModelCellsIds, updatedCellsIds, editedCellsIds, config, timeScale]);

  return {
    onRunModel,
    rowData,
    rowStyle: { background: '#FBFCFE' },
    columnDefs: colDefs,
    rowGroupPanelShow: 'never',
    rowGroupPanelSuppressSort: true,
    groupSuppressBlankHeader: true,
    groupAllowUnbalanced: true,
    reactiveCustomComponents: true,
    loadingOverlayComponent: CustomLoadingOverlay,
    undoRedoCellEditing: true,
    columnMenu: 'legacy',
    undoRedoCellEditingLimit: 1,
    autoSizeStrategy: {
      type: "fitCellContents",
    },
    defaultColDef,
    getRowStyle: (params) => getRowStyle(params, forecastScenario),
    getRowId,
    aggFuncs,
    isGroupOpenByDefault,
    groupDisplayType: 'custom',
    suppressAggFuncInHeader: true,
    onGridReady,
    columnTypes,
    getContextMenuItems: (params) => {
      if(isBlank(params.value)) return [];

      const periodName = params.column.colDef.field;
      if(showContextMenu(params, config, timeScale, forecastScenario, periodName)) {
        const driverHeader = extractDriverName(params.node.data.Facts);
        const editable = config.isEditableDriver(timeScale, driverHeader) && isFuturePeriod(timeScale, periodName);
        return getContextMenuItems(gridRef, params, editable, colDefs, onCellValueChangedWrapper, onResetCells, forecastScenario.allFuturePeriodsByTimeScale, openLargeScalePanel);
      }
      return [];
    },
    onFirstDataRendered: (event) => {
      updateScenarioData({ ag_grid_rendered: true })
      restoreForecastCookies(event, forecastScenario);
      setTimeout(() => event.api.autoSizeAllColumns(), 100);
      setRunModelActive(anyRunModelCells(runModelCells));
      updateScheduledCells(timeScale, editedCells, onCellValueChangedWrapper);
    },
    onFilterChanged: (event) => saveFilters(event, forecastScenario),
    onRowGroupOpened: (event) => saveCollapsedGroups(event, forecastScenario),
    onColumnPinned: (event) => saveColumnState(event, forecastScenario),
    getRowHeight: function(params) {
      // NOTE: tricky way to hide extra Value Sales row(we actually need it to display aggregated Value Sales above)
      if (isValueSalesRow(params, forecastScenario)) {
        return 0;
      } else {
        return params.node.rowHeight;
      }
    }
  }
}
