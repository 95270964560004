import React from 'react';
import Button from "react-bootstrap/Button";
import { isBlank, toSentence } from "../../../helpers/common";

export default ({ home, loadDashboardTemplates }) => {
  let selectedOptions = home.template_users.map(tu => tu.full_name)
  return <Button bsPrefix="btn btn-secondary bg-secondary h5 h-36 rounded-pill text-black float-start py-1 px-2 me-1"
                 onClick={() => loadDashboardTemplates({ template_users: [] })}
                 hidden={isBlank(home.template_users)}>
    <span className="pe-1">Groups:</span>
    <span className="fw-normal">{toSentence(selectedOptions)}</span>
    <i className="fas fa-times ms-1" />
  </Button>
}
