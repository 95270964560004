import { UPDATE_SHARE_DATA, UPDATE_SHARE_FAILED } from "./types";
import EntryPoint from "../../EntryPoint";
import {
  addShareRequest,
  disableSharePublicLink,
  reloadShareDataRequest,
  removeShareRequest,
  sharePublicLink
} from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";

export function reloadShareData() {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    reloadShareDataRequest({controllerName, objectSlug}).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateShareFailure });

      const { data } = response;
      const { share_data } = data;
      dispatch(updateShareData({ ...share_data }));
    })
  }
}

export function addShare(decision_admissions_attributes, invites = null, additional_data = {}, callback = ()=>{}) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    addShareRequest({ controllerName, objectSlug, decision_admissions_attributes, additional_data, invites }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateShareFailure }, callback)

      const { data } = response;
      const { share_data } = data;
      dispatch(updateShareData({ ...share_data }));
      callback(true)
    })
  }
}

export function publicLinkShare() {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance;
    sharePublicLink(objectSlug).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateShareFailure });

      dispatch(updateShareData({ sharable: true }));
    })
  }
}

export function disablePublicLinkShare() {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance;
    disableSharePublicLink(objectSlug).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateShareFailure });

      dispatch(updateShareData({ sharable: false }));
    })
  }
}

export function removeShare({ admittable_type, admittable_id }) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    const decision_admissions_attributes = [{ admittable_type, admittable_id }]
    removeShareRequest({ controllerName, objectSlug, slug: admittable_id, decision_admissions_attributes }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateShareFailure });

      const { data } = response;
      const { share_data } = data;
      dispatch(updateShareData({ ...share_data }));
    })
  }
}

export const updateShareData = (data) => ({
  type: UPDATE_SHARE_DATA,
  payload: {
    ...data
  }
});
export const updateShareFailure = error => ({
  type: UPDATE_SHARE_FAILED,
  payload: {
    error
  }
});
