import React from 'react';
import Select from 'react-select';
import { BorderedFilterStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import { isBlank } from "../../../helpers/common";
import {DriverResponseTypes} from "../../../models/driver";

const defaultResponseType = (driverResponseType) => {
  const driverType = DriverResponseTypes.find((driverType) => driverType.value === driverResponseType);
  if (isBlank(driverType)) return null;

  return { value: driverType.value, label: driverType.label }
};

export default ({
                  isDSight,
                  driverResponseType,
                  onChangeResponseDriverType = (_) => {},
                  hideChoiceSection
                }) => {
  if (isDSight ||hideChoiceSection) return null;

  return <div className="mt-3 mb-0">
    <h3>Response type</h3>
    <Select
      defaultValue={defaultResponseType(driverResponseType)}
      onChange={onChangeResponseDriverType}
      components={{ IndicatorSeparator: () => null }}
      styles={BorderedFilterStyle}
      placeholder="Make a selection"
      options={DriverResponseTypes} />
  </div>
};
