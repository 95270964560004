import React from 'react';
import Select from 'react-select';
import { BorderedFilterStyle } from "../../../tree_wizard/styles/choice_entry_styles";

const TimeScaleSelector = ({ timeScale, changeTimeScale, options }) => {
  return <div className="px-3 mb-0">
    <h3>Time scale</h3>
    <Select
      defaultValue={options.find(option => option.value === timeScale)}
      value={options.find(option => option.value === timeScale)}
      onChange={(option) => changeTimeScale(option.value)}
      components={{ IndicatorSeparator: () => null }}
      styles={BorderedFilterStyle}
      placeholder="Make a selection"
      options={options} />
  </div>
};
export default TimeScaleSelector;