import { isPresent } from "./common";

export const isPreview = () => (new URLSearchParams(document.location.search)).get('preview') === 'true'
export const analysisLink = (link) => {
  const queryData = {}
  if (isPresent(isPreview())) queryData.preview = true;
  if (isPresent(queryData)) {
    const queryParams = new URLSearchParams(queryData);
    return `${link}?${queryParams}`;
  }

  return link;
}
