import React from "react";
import ChartsDecompositionScope from "../settings/ChartsDecompositionScope";

const ImportFrom = ({ config, updateScope, scopes, scenarioCmus = [] }) =>
  <ChartsDecompositionScope header={'Import from'}
                            scopes={scopes}
                            defaultToBtn={false}
                            updateScope={updateScope}
                            scenarioCmus={scenarioCmus}
                            forecastConfig={config}/>
export default ImportFrom;
