import React, { useState } from 'react';
import {
  filteredProcessData, processDataForTypeNode, surveyProcessDataIndex
} from "../../../../../models/d_sight/metric_chart_helpers";
import { brandDimension } from "../../../../../models/d_sight/dimensions";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { prepareChartData } from "../helpers/line_helpers";
import { BtnDropdownToggleInlineShadowText } from "../../../../../common/dropdowns";
import { capitalizeAllWords, isPresent } from "../../../../../helpers/common";
import { BaseDropdownBtn } from "../../../../../common/BaseHamburgerBtn";
import { generateChartOpts, generateNormChartData } from "../components/NormChart";
import ChartSourcesLabels from "../ChartSourcesLabels";

const AdvertisingDropdown = ({ subMetrics, selectedSubMetric, setSelectedAdv }) => {
  if (subMetrics.length < 2) return null;

  return <div className="d-block d-md-inline-block text-md-end mb-2 mb-md-0">
    <div className="d-inline-flex text-nowrap me-1">Advertising:</div>
    <BtnDropdownToggleInlineShadowText id="adv-dropdown"
                                       title={capitalizeAllWords(selectedSubMetric.answer)}
                                       onSelect={(adv) => { setSelectedAdv(parseInt(adv)) }} bsPrefix="text-capitalize pe-0">
      {subMetrics.map(subMetric =>
        <BaseDropdownBtn key={`adv-${subMetric.metric_id}`} eventKey={subMetric.metric_id} bsPrefix="text-capitalize"
                         title={capitalizeAllWords(subMetric.answer)}
                         active={selectedSubMetric.metric_id===subMetric.metric_id}/>
      )}
    </BtnDropdownToggleInlineShadowText>
  </div>
}

export default ({
                  d_sight_goal, chartData, timeNode, surveys,
                  masterMetric, metric, subMetrics, mainBrand,
                  sourceLabels,
                  ...opts
                }) => {
  const [selectedAdv, setSelectedAdv] = useState(subMetrics[0].metric_id)

  const branDim = brandDimension(d_sight_goal)
  const selectedSubMetric = subMetrics.find(m => m.metric_id === selectedAdv)
  const filterData = (data) => data?.filter(pd => pd.nodes[branDim.node_index] === mainBrand)
  const collectedProcessData = processDataForTypeNode({
    process_data: filterData(selectedSubMetric?.process_data)
  }, timeNode)
  const process_data = filteredProcessData({ process_data: collectedProcessData }, d_sight_goal)
  const extData = processDataForTypeNode({
    process_data: filterData(selectedSubMetric?.extended_data)
  }, timeNode)

  const { data } = prepareChartData({
    ...opts, surveys, process_data, timeNode, d_sight_goal, metric, sortedNodeNames: [mainBrand]
  })
  const chartSeriesData = [
    data[0],
    generateNormChartData(data[0], extData[0], { surveys, metric })
  ]
  const filteredSurveys = surveys.filter(s => {
    const index = surveyProcessDataIndex(s)
    return chartSeriesData.some(({ data }) => isPresent(data[index]))
  })
  const chartOptions = generateChartOpts({ metric, filteredSurveys, chartSeriesData })

  return <div className="row mb-3">
    <div className="col">
      <div className="h-100">
        <div className="d-block d-md-flex">
          <h3 className="me-2">{ (masterMetric || metric).name } norms - {selectedSubMetric.answer}</h3>
          <div className="text-nowrap ms-auto">
            <AdvertisingDropdown {...{ subMetrics, selectedSubMetric, setSelectedAdv }} />
          </div>
        </div>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} key={`adv-metric-line-chart-${metric.id}`}/>
        {
          sourceLabels && <ChartSourcesLabels {...{ chartData, surveys: filteredSurveys, hideSignificanceLegend: true, legendHint: 'norms' }} />
        }
      </div>
    </div>
  </div>
}
