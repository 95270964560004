import React, {useState} from 'react';
import { connect } from "react-redux";
import { updateDriver } from "../../../store/sidebar/actions";
import ConfidenceLevel from "../../driver_entry/ConfidenceLevel";

const ConfidenceLevelRow = ({ driverData, updateDriver }) => {
  const [confidence, setConfidence] = useState(driverData.driver?.confidence?.toString() || '');

  const onChangeConfidence = (value) => {
    updateDriver({ slug: driverData.driver.slug, confidence: value, draft: driverData.driver.draft });
    setConfidence(value);
  }

  return <ConfidenceLevel {...{ confidence, onChangeConfidence, setConfidence, disabled: false, slug: driverData.driver?.slug }} />
}
export default connect(null, { updateDriver })(ConfidenceLevelRow);
