
export const DRIVER_STATES_DATA = {
  unstarted: { state: 'unstarted',  icon_classes: 'fa-duotone fa-circle square-24', color: 'text-light-gray' },
  in_flight: { state: 'in_flight', icon_classes: 'fa-solid fa-circle-dot square-24', color: 'text-warning' },
  completed: { state: 'completed', icon_classes: 'fa-solid fa-circle-check square-24', color: 'text-success' }
}

export default class DriverState {
  constructor(driverObject, decisionObject) {
    this.driverObject = driverObject;
    this.decisionObject = decisionObject
  }

  get state() {
    if(this.isCompleted) return DRIVER_STATES_DATA.completed.state;
    if(this.isInFlight) return DRIVER_STATES_DATA.in_flight.state;
    if(this.isUnstarted) return DRIVER_STATES_DATA.unstarted.state;
  }

  get isInFlight() {
    return !this.isUnstarted && !this.isCompleted;
  }

  get isUnstarted() {
    if(this.decisionObject.isRateAndCompareDriverChoicesHidden) {
      return !this.driverObject.withEnteredAnswer &&
        !this.driverObject.withEnteredInformation
    }

    return !this.driverObject.withEnteredInformation &&
      !this.driverObject.withEnteredRateCompareChoicesAnswer
  }

  get isCompleted() {
    if(this.decisionObject.isRateAndCompareDriverChoicesHidden) {
      return this.driverObject.withEnteredAnswer && !this.driverObject.isDraft;
    }
    return (this.driverObject.withEnteredRateCompareChoicesAnswer) &&
      !this.driverObject.isDraft;
  }
}