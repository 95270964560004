import React from 'react';
import { isBlank } from "../../../helpers/common";
import * as moment from "moment";

export const DecisionDateSection = ({ decision }) => {
  if (isBlank(decision.historical_decided_at)) return null;

  return <div className="px-3 mb-3">
    <h3>Decision date</h3>
    <div>{moment(decision.historical_decided_at).format('D MMM, yyyy')}</div>
  </div>
}
export default DecisionDateSection;
