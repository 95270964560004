import React from 'react';
import HeaderSection from "./decisions_center/HeaderSection";
import DecisionsSection from "./decisions_center/DecisionsSection";
import Decision from "../../models/decision";
import AssignedDriversSection from "./decisions_center/AssignedDriversSection";
import DecisionsSetsSection from "./decisions_center/DecisionsSetsSection"

export const DecisionsCenterTab = ({}) =>
  <>
    <div>
      <HeaderSection />
    </div>
    <div className="decisions-categories mb-3">
      <DecisionsSection isApprovalNeeded={true} section="approval_needed" title="Approval needed" sortField={(d) => d.updated_at} />
      <AssignedDriversSection />
      <DecisionsSection isDecisionSet={true} section="in_flight" title="In-flight decisions" sortField={(d) => d.updated_at} />
      <DecisionsSetsSection />
      <DecisionsSection section="due_followup" title="Follow up on results" sortField={(d) => new Decision(d).scheduledFollowup.followup_due_date} />
    </div>
  </>

export default DecisionsCenterTab;
