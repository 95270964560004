import React, { Fragment, useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import { Document, Packer } from "docx";
import { RootDocxDocument } from "../../docx";
import { docxStyles } from "../../docx/styles/docx_styles";
import { isPresent } from "../../helpers/common";
import Decision, { decisionDataSources } from "../../models/decision";
import { DecisionOrDSightDataSourcesTypes } from "../../models/data_source";
import { base64DataSourcesCallback } from "../../docx/helpers/common";
import { checkLoadingEffect } from "../../helpers/callbacks_helpers";
import { DOCUMENT_FORMAT } from "../../pdf/helpers/common";
import { loadOrgCategories } from "../../store/org_categories/actions";
import { connect } from "react-redux";
import { numberStyles } from "../../docx/styles/number_styles";
import { driversToArray } from "../../helpers/drivers_helpers";
import Button from "react-bootstrap/Button";

const DownloadDOCX = ({
                        tree, decision, current_org, org_categories, org_driver_types,
                        loadOrgCategories, isButton = false
                      }) => {
  const filteredDrivers = tree.drivers.filter(d => isPresent(d.driver) && isPresent(d.driver.question))
  const driversData = isPresent(tree.drivers) ? driversToArray(tree.drivers).filter(d => d.driver.question) : [];
  const decisionObj = new Decision(decision, tree.drivers)
  const driverCount = driversData.length
  const sortedDriverDataSources = decisionDataSources(tree.data_sources, filteredDrivers, DecisionOrDSightDataSourcesTypes)
  const [dataSources, setDataSources] = useState([])
  const [extraImages, setExtraImages] = useState({})
  const [show, setShow] = useState(false);

  checkLoadingEffect(org_categories, loadOrgCategories);
  base64DataSourcesCallback(sortedDriverDataSources, setDataSources, setExtraImages, current_org, setShow);
  const doc = new Document({
    creator: "Cloverpop",
    styles: docxStyles,
    numbering: {
      config: numberStyles(driverCount)
    },
    sections: RootDocxDocument(DOCUMENT_FORMAT, decision, driversData, filteredDrivers, dataSources, extraImages, org_driver_types, org_categories, decisionObj)
  })

  const downloadDocument = () => {
    Packer.toBuffer(doc).then((buffer) => {
      const type = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      const blob = new Blob([buffer], { type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${decision.description}.docx`;
      a.click();
    }).catch((error) => {
      console.error('An error occurred during Docx rendering:', error);
    });
  };

  return <Fragment>
    {
      isButton ?
        <Button onClick={downloadDocument} className="btn btn-secondary w-100 mb-2">
          Download decision DOC
        </Button>:
        <Dropdown.Item as="button" disabled={!show} onClick={downloadDocument}>Download decision DOC</Dropdown.Item>
    }
  </Fragment>
}

const mapStateToProps = ({ tree, decision, current_org, org_categories, org_driver_types }) => ({
  tree, decision, current_org, org_categories, org_driver_types
});
const mapDispatchToProps = (dispatch) => ({
  loadOrgCategories: () => {
    dispatch(loadOrgCategories());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadDOCX);
