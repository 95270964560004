import React from "react";
import { connect } from "react-redux";
import { updateViewOptions } from "../../../store/forecast_simulator_scenario/actions";
import Toggle from "../../../common/Toggle";

export const CHART_VALUES_TYPES = {
  percent: 'percent',
  value: 'value'
}

const RENDER_CHART_TYPES = [
  {
    value: CHART_VALUES_TYPES.percent,
    body: '% changes vs VA'
  },
  {
    value: CHART_VALUES_TYPES.value,
    body: 'Value'
  },
]

const SimulatedType = ({
                         chartValuesType, setChartValuesType,
                         updateViewOptions,
                         loading = false
                       }) => {
  const onChange = (chartValuesType) => {
    updateViewOptions({ chartValuesType }, (status) => {
      if(status) setChartValuesType(chartValuesType)
    }, false)
  }
  return <>
    <div style={{width: '210px'}}>
      <div className="mb-1">Show</div>
      <Toggle items={RENDER_CHART_TYPES} selectedItem={chartValuesType} onClick={onChange} padding={'px-2'} disabled={loading}/>
    </div>
  </>;
}
const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, { updateViewOptions })(SimulatedType);
