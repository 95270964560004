import { isBlank, isPresent, qSortArray } from "../helpers/common";
import {
  sortChoicesByCreatedAt,
  sortChoicesByRankPoints,
  sortPickedChoices,
  sortRankedChoices
} from "./decision_choices";
import { sortChoicesByRating } from "./DriverChoiceRating";

export const ChoiceEntryType = {
  OPEN_ENDED: 'open_ended',
  TOP_CHOICE: 'top_choice',
  PICKY: 'picky',
  RANK_LIST: 'rank_list'
};

export const ResponseTypes = [
  { value: ChoiceEntryType.OPEN_ENDED, label: 'Open-ended' },
  { value: ChoiceEntryType.TOP_CHOICE, label: 'Pick top choice' },
  { value: ChoiceEntryType.PICKY, label: 'Pick all that apply' },
  { value: ChoiceEntryType.RANK_LIST, label: 'Rank a list' },
];

export const CHOICE_ENTRY_TYPE_DATA = {
  open_ended: 'Open-ended',
  top_choice: 'Pick a choice.',
  picky: 'Pick all that apply.',
  rank_list: 'Select and rank all that apply. Click to select, drag to rank.'
};

export default class Recommendation {
  constructor(recommendation) {
    this.recommendation = recommendation;
  }

  // Decision choice type
  get isRankedList() {
    return this.recommendation?.choice_entry_widget_type === ChoiceEntryType.RANK_LIST;
  }
  get isPicky() {
    return this.recommendation?.choice_entry_widget_type === ChoiceEntryType.PICKY;
  }
  get isTopChoice() {
    return this.recommendation?.choice_entry_widget_type === ChoiceEntryType.TOP_CHOICE;
  }
  get isOpenEnded() {
    return this.recommendation?.choice_entry_widget_type === ChoiceEntryType.OPEN_ENDED;
  }

  get assignedCollaboratorUser() {
    return this.recommendation?.assigned_collaborator_email;
  }

  // Decision choices
  get recommendationChoices() {
    return this.recommendation?.recommendation_choices?.filter(c => isBlank(c.hide_in_choices_section)) || [];
  }
  get openEndedChoice() {
    return this.recommendation?.recommendation_choices?.filter(c => c.open_ended) || [];
  }
  filteredChoices(filterOpenEnded = false) {
    return this.recommendationChoices.filter((choice) => !filterOpenEnded ? true : !choice.open_ended);
  }
  get sortCallback() {
    return this.isRankedList ? sortRankedChoices : sortPickedChoices;
  }
  sortedChoices(filterOpenEnded = false) {
    if(this.isRankedList) {
      return this.filteredChoices(filterOpenEnded).sort(sortRankedChoices);
    } else {
      return this.filteredChoices(filterOpenEnded).sort(sortChoicesByRankPoints);
    }
  }
  sortedRatedChoices(keyDrivers) {
    return sortChoicesByRating(this.filteredChoices(true), keyDrivers, {
      postSort: this.sortCallback
    });
  }
  get finalDecisions() {
    if (!this.isEnteredRecommendation) return [];

    return this.sortedChoices().filter(c => isPresent(c.final_decision))
  }
  get otherChoices() {
    if (!this.isEnteredRecommendation) return [];

    return this.sortedChoices().filter(c => isBlank(c.final_decision) && !c.open_ended)
  }

  get isEnteredRecommendation() {
    return isPresent(this.recommendation.recommended_at)
  }
}
