import React, {useEffect, useRef} from 'react';
import AutoGrowTextArea from "../../../common/AutoGrowTextArea";

const removeChoice = (slug, onChangeChoice, deleteConsideredChoice) => {
  $(`#rowDecisionChoice-${slug}`).removeClass('d-flex').addClass('d-none');
  deleteConsideredChoice(slug)
  onChangeChoice('', slug)
}
export default ({
                  description, slug,
                  submitted, newChoice,
                  onChangeChoice, setNewChoice, onPasteChoice,
                  onSaveConsideredChoice = () => {},
                  deleteConsideredChoice = () => {},
                  setIsMouseOverInput = () => {},
                  setMouseOverRemoveSlug = () => {}
                }) => {
  const inputElement = useRef();

  useEffect(() => {
    if (inputElement && newChoice) {
      setNewChoice(false)
    }
  }, [newChoice]);

  return <div className="d-flex align-items-center" id={`rowDecisionChoice-${slug}`} onBlur={() => onSaveConsideredChoice(description, slug)}>
    <AutoGrowTextArea
      id={`decisionChoice-${slug}`}
      key={`decision-choice-${slug}`}
      className={`no-resize mb-2`}
      placeholder="Enter a choice"
      value={description}
      ref={inputElement} autoFocus={newChoice}
      onChange={(e) => onChangeChoice(e.target.value, slug)}
      onPasteCallback={onPasteChoice ? ((e) => onPasteChoice(e, slug)) : () => {}}
      readOnly={submitted}
      onMouseEnter={() => setIsMouseOverInput(true)}
      onMouseLeave={() => setIsMouseOverInput(false)}
    />
    <span className="pointer btn btn-light btn-sm btn-sm-round text-danger mb-2 ms-1 px-2"
          onMouseEnter={() => setMouseOverRemoveSlug(slug)} onMouseLeave={() => setMouseOverRemoveSlug('')}
          onClick={() => removeChoice(slug, onChangeChoice, deleteConsideredChoice)}>
      <i className="fas fa-times w-100"/>
    </span>
  </div>
}
