import React, { useState } from "react";
import { connect } from "react-redux";
import {isBlank, isPresent} from "../../helpers/common";
import DriverDetailsModal, { modalDriverData } from "../../tree_editor/modals/DriverDetailsModal";
import { updateDriver } from "../../store/modals/actions";
import {DEFAULT_RATING_SCALE, DriverChoiceEntryType, NUMBER_FORMAT} from "../../models/driver";
import * as moment from "moment";
import {buildDriverChoices} from "../../tree_view/modals/entry_modal/helpers";

const ZTreeDriverDetailsModal = ({ modal,
                                   customKey,
                                   title,
                                   show,
                                   isTemplate = false,
                                   collaborators = [],
                                   onClose,
                                   allowNew = true,
                                   updateDriver }) => {
  if(isBlank(modal.drivers)) return null;

  const driverData = modalDriverData(modal)
  const [inputValue, setInputValue] = useState(driverData?.driver?.question || '')
  const [notes, setNotes] = useState(driverData?.driver?.notes || '')
  const [driverTypeSlug, setDriverTypeSlug] = useState(driverData?.driver?.driver_type_slug || '');
  const [assignedToUser, setAssignedToUser] = useState(driverData?.driver?.assign_to_user || '');
  const [submitState, setSubmitState] = useState(false)
  const [ratingScale, setRatingScale] = useState(driverData?.driver?.rating_scale || DEFAULT_RATING_SCALE.value)
  const [ratingLabels, setRatingLabels] = useState(driverData?.driver?.rating_labels || {})
  const initDate = isPresent(driverData?.driver?.due_date) ? moment(driverData?.driver?.due_date).format('DD MMM, yyyy') : null;
  const [dueDate, setDueDate] = useState(initDate);
  const [driverResponseType, setDriverResponseType] = useState(driverData?.driver?.choice_entry_widget_type || DriverChoiceEntryType.OPEN);
  const [choices, setChoices] = useState(buildDriverChoices(driverData?.driver || {}));
  const [numberFormat, setNumberFormat] = useState(driverData?.driver?.number_format || NUMBER_FORMAT.DEFAULT);

  if (isBlank(driverData?.driver)) return null;

  const onSubmitModal = (reloadContactsCallback = () => {}) => {
    setSubmitState(true);
    updateDriver({
      slug: driverData.driver.slug,
      question: inputValue, notes,
      driver_type: driverTypeSlug,
      assign_to_user: assignedToUser,
      rating_scale: ratingScale,
      rating_labels: ratingLabels,
      number_format: numberFormat,
      due_date: dueDate,
      choice_entry_widget_type: driverResponseType,
      choices
    }, () => {
      onClose();
      setSubmitState(false);
      reloadContactsCallback()
    })
  }

  return <DriverDetailsModal title={title}
                             key={customKey}
                             collaborators={collaborators}
                             isTemplate={isTemplate}
                             driverData={driverData}
                             question={inputValue}
                             setQuestionValue={setInputValue}
                             notes={notes}
                             driverTypeSlug={driverTypeSlug}
                             setDriverTypeSlug={setDriverTypeSlug}
                             setNotes={setNotes}
                             show={show}
                             onSubmitModal={onSubmitModal}
                             assignedToUser={assignedToUser}
                             setAssignedToUser={setAssignedToUser}
                             onClose={onClose}
                             submitState={submitState}
                             ratingScale={ratingScale}
                             setRatingScale={setRatingScale}
                             ratingLabels={ratingLabels}
                             setRatingLabels={setRatingLabels}
                             dueDate={dueDate}
                             setDueDate={setDueDate}
                             driverResponseType={driverResponseType}
                             setDriverResponseType={setDriverResponseType}
                             choices={choices}
                             setChoices={setChoices}
                             numberFormat={numberFormat}
                             setNumberFormat={setNumberFormat}
                             allowNew={allowNew}
  />
}
const mapStateToProps = ({ modal }) => ({ modal });
export default connect(mapStateToProps, { updateDriver } )(ZTreeDriverDetailsModal);
