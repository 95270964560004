import React from 'react';
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import CloseIcon from "../../../common/CloseIcon";
import { setDSightTreeViewerOpen } from "../../../store/sidebar/actions";
import TreeDetails from "./tree_details/TreeDetails";

export const DSightTreeViewerPanel = ({ sidebar, openSideBar, currentTreeNode }) =>
  <SlidingPanel
    type="right"
    isOpen={sidebar.dSightTreeSidebar}
    panelContainerClassName="sidebar-panel-wrapper d-sight-tree-panel-wrapper"
    panelClassName="sidebar-panel-wrapper-content -sight-tree-panel-content h-auto"
    size={50}>
    <div className="mt-2 me-3 float-end"><CloseIcon onClose={() => { openSideBar(false) }} /></div>
    <TreeDetails currentTreeNode={currentTreeNode} onClick={() => { openSideBar(false) }}/>
  </SlidingPanel>

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setDSightTreeViewerOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(DSightTreeViewerPanel);
