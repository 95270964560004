import React from 'react';
import { Highlighter, Token } from 'react-bootstrap-typeahead';
import BaseTypehead from "../../../common/BaseTypehead";

const renderNewItemTag = (option, { onRemove, tabIndex, disabled }, index) =>
  <Token key={index} onRemove={onRemove} tabIndex={tabIndex} disabled={disabled} option={option}>
    {option.description}
  </Token>

const renderNewItem = (option, { text }, _index) =>
  <Highlighter search={text}>{option.description}</Highlighter>

export default ({ values = [], setValues = (_) => {}, property, propertyObject, ...opts }) =>
  <BaseTypehead
    {...opts}
    labelKey="description" filterBy={['description']}
    selected={values}
    options={propertyObject.isOpen ? property.options[0]?.report_property_options : property.options}
    onChange={(options) => setValues(property, options)}
    renderMenuItemChildren={renderNewItem}
    renderToken={renderNewItemTag}
  />
