import { RESET_CHANNELS_DATA, UPDATE_CHANNELS_DATA } from "./types";

const initialState = {
  tree: {}
};

export function channelsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case UPDATE_CHANNELS_DATA: {
      return {
        ...state,
        ...action.payload
      };
    }
    case RESET_CHANNELS_DATA: {
      return {
        ...state,
        ...initialState
      }
    }
    default:
      return state;
  }
}
