import React from 'react';
import {connect} from "react-redux";
import CloseIcon from "../../../common/CloseIcon";
import Button from "react-bootstrap/Button";
import {setLinkPanelOpen} from "../../../store/sidebar/actions";
import {linkTitlePrepare} from "../../../helpers/link_helpers";
import {setMaximizeIframe} from "../../../store/sidebar/actions";

const LinkPanelHeader = ({ openSideBar, sidebar, maximizeIframe }) => {
  const headerName = linkTitlePrepare(sidebar?.dataSource)
  const dataSource = sidebar?.dataSource

  const closePanel = () => {
    openSideBar(false)
    maximizeIframe(false)
  }

  return <div className="side-panel header">
    <div className="d-flex justify-content-between pt-2 px-3">
      <h2 className="text-truncate">{headerName}</h2>
      <a href={dataSource?.link_url} target={'_blank'} className={'ms-auto'}>
        <Button bsPrefix="btn btn-sm btn-secondary btn-sm-round">
          <i className="fas fa-external-link-alt text-primary fa-lg" />
        </Button>
      </a>
      <Button bsPrefix="btn btn-sm btn-secondary btn-sm-round ms-2 maximize-icon" onClick={() => maximizeIframe(!sidebar.maximizeIframe)}>
        {
          sidebar.maximizeIframe ?
            <i className="fas fa-minimize text-primary fa-lg" /> :
            <i className="fas fa-maximize text-primary fa-lg" />
        }
      </Button>
      <div className="ms-2">
        <CloseIcon onClose={closePanel} />
      </div>
    </div>
  </div>
}

const mapStateToProps = ({ sidebar }) => ({ sidebar });
export default connect(mapStateToProps, { openSideBar: setLinkPanelOpen, maximizeIframe: setMaximizeIframe })(LinkPanelHeader);
