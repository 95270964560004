import {AlignmentType, convertInchesToTwip, LevelFormat} from "docx";

export const NUMBERING_COUNT = 15;
const NUMBERING_BASE_REFERENCE = "rich-numbering";
export const BASE_INDENT = 0.4;
const MAX_LEVELS = 9;

export const numberStyles = (driverCount) => generateNumberingConfigs(driverCount);

function generateNumberingConfigs(driverCount) {
  //generate style classes for restarting numbering of ordered list in DOCX
  const numberingConfigs = iterateArray(NUMBERING_COUNT, index => ({
    reference: `${NUMBERING_BASE_REFERENCE}-${index}`,
    levels: generateNumberingLevels(MAX_LEVELS),
  }));
  const driverSummaryNumberingConfigs = iterateArray(driverCount, index => ({
    reference: `${NUMBERING_BASE_REFERENCE}-driver-summary-${index}`,
    levels: generateNumberingLevels(MAX_LEVELS),
  }));
  const driverDetailsNumberingConfigs = iterateArray(driverCount, index => ({
    reference: `${NUMBERING_BASE_REFERENCE}-driver-details-${index}`,
    levels: generateNumberingLevels(MAX_LEVELS),
  }));
  const driverKeyNumberingConfigs = iterateArray(driverCount, index => ({
    reference: `${NUMBERING_BASE_REFERENCE}-driver-key-${index}`,
    levels: generateNumberingLevels(MAX_LEVELS),
  }));

  const richBulletPoints = {
    reference: "rich-bullet-points",
    levels: generateBulletLevels(MAX_LEVELS),
  };

  const smallBulletPoints = {
    reference: "small-bullet-points",
    levels: [
      {
        level: 0,
        format: LevelFormat.BULLET,
        text: "\u2022",
        alignment: AlignmentType.LEFT,
        style: {
          paragraph: {
            indent: { left: convertInchesToTwip(0.2), hanging: convertInchesToTwip(0.2) },
          },
        },
      },
    ],
  };

  return numberingConfigs.concat(richBulletPoints).concat(smallBulletPoints)
    .concat(driverSummaryNumberingConfigs).concat(driverDetailsNumberingConfigs).concat(driverKeyNumberingConfigs);
}

function iterateArray(count, callback) {
  return Array.from({ length: count }, (_, index) => callback(index));
}

function generateNumberingLevels(numLevels) {
  return iterateArray(numLevels, index => generateLevel(index, getNumberingFormat(index), `%${index + 1}.`));
}

function generateBulletLevels(numLevels) {
  return iterateArray(numLevels, index => generateLevel(index));
}

function generateLevel(level, format = LevelFormat.BULLET, text = "\u2022") {
  return {
    level,
    format,
    text,
    alignment: AlignmentType.START,
    style: {
      paragraph: {
        indent: {
          left: convertInchesToTwip(BASE_INDENT * (level + 1)),
          hanging: convertInchesToTwip(0.5 * BASE_INDENT)
        },
      },
    },
  };
}

function getNumberingFormat(level) {
  switch (level % 3) {
    case 0:
      return LevelFormat.DECIMAL;
    case 1:
      return LevelFormat.LOWER_LETTER;
    case 2:
      return LevelFormat.LOWER_ROMAN;
    default:
      return LevelFormat.DECIMAL;
  }
}
