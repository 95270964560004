import React from 'react';
import { Token } from 'react-bootstrap-typeahead';
import { isEmail, isPresent, uniqueBy } from "../../../../helpers/common";
import { buildInviteDataBy } from "../../../../helpers/wizard_helpers";

export const renderTag = (option, { onRemove, tabIndex, disabled }, index, contactsData) => {
  const email = typeof option === 'object' ? option.value : option
  const contact = contactsData.contacts.find(c => c.value === email)
  return <Token
    key={index}
    onRemove={onRemove}
    tabIndex={tabIndex}
    disabled={disabled}
    option={option}>
    {contact ? contact.full_name || contact.value : email}
  </Token>
}

export const onChangeInvites = (selected, invites, setInvites, contactsData, submitCallback = () => {}) => {
  const newInvites = [];
  selected.forEach(contact => {
    if (typeof contact === "string" && isEmail(contact)) {
      newInvites.push(buildInviteDataBy(contact, contactsData.contacts))
    } else if (isPresent(contact.emails)) {
      contact.emails.split(',').forEach(email => newInvites.push(buildInviteDataBy(email, contactsData.contacts)))
    } else if(isEmail(contact.value)){
      newInvites.push(buildInviteDataBy(contact.value, contactsData.contacts))
    }
  })
  setInvites(uniqueBy([...invites, ...newInvites], 'email'))
  submitCallback([...invites, ...newInvites]);
}
