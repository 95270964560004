import React, { Fragment } from 'react';
import { connect } from "react-redux";
import {
  hideDSightTab,
  isCategoriesCenter,
  isCreateDecisionCenter,
  isDecisionsCenter,
  isDSightTab,
  isGroupsCenter,
  isSearchDecisions,
  isTemplatesCenter,
  isUsersCenter,
  isReportInsightsTab,
  TABS_URLS
} from "../../helpers/home_helpers";
import ReportInsightsText from "./ReportInsightsText";
import GroupsText from "./GroupsText";
import DSightText from "./DSightText";
import CategoriesText from "./CategoriesText";
import UsersText from "./UsersText";
import CreateDecisionText from "./CreateDecisionText"
import CreateDSightsDecisionText from "./CreateDSightsDecisionText"
import { Link } from "react-router-dom";
import HelpButtons from "../../common/HelpButtons";
import { Routes as routes } from "../../routes";

export const Index = ({home, current_user, current_org}) => {
  return <Fragment>
    <div className="px-3 mb-3" hidden={!isSearchDecisions(home)}>
      <h3>Search decision bank</h3>
      <p>Use the search bar to find decisions you were a part of or were shared with you.</p>
      <p>Search will look for matching terms in the decision title, context, choices, collaborators, and data sources.</p>
      <p>Click on a decision tile to view the decision tree and details.</p>
    </div>
    <div className="px-3 mb-3" hidden={!isGroupsCenter(home, current_user)}>
      <h3>Groups</h3>
      <GroupsText />
    </div>
    <div className="px-3 mb-3" hidden={!isCategoriesCenter(home, current_user)}>
      <h3>Categories</h3>
      <CategoriesText />
    </div>
    <div className="px-3 mb-3" hidden={!isUsersCenter(home, current_user)}>
      <h3>Users</h3>
      <UsersText />
    </div>
    <div className="px-3 mb-3" hidden={!isReportInsightsTab(home, current_user, current_org)}>
      <h3>Report Insights</h3>
      <ReportInsightsText />
    </div>
    <div className="px-3 mb-3" hidden={!isTemplatesCenter(home, current_user)}>
      <h3>Templates</h3>
      <p>
        Templates are an easy way to create decisions. Templates can be pre-populated and structured so they are
        consistent and don’t require extensive setup each time you make the decision. Templates are shown to users on
        the <Link to={`${routes.homepage}/${TABS_URLS.create_decision}`}>Create decisions</Link> page.
      </p>
      <p>
        Select <strong>Create a new template</strong> to get started. To preview and edit, select
        the <strong>View template</strong> button.
      </p>
    </div>
    <div className="px-3 mb-3" hidden={!isCreateDecisionCenter(home, current_user)}>
      <h3>Create decisions</h3>
      {hideDSightTab(current_user, current_org) ? <CreateDecisionText /> : <CreateDSightsDecisionText />}
    </div>
    <div className="px-3 mb-3" hidden={!isDecisionsCenter(home)}>
      <h3>Decision center</h3>
      <p>The decision center shows your current active decisions.</p>
      <p>Select <strong>Create decisions</strong> to get started on a new decision. Select <strong>Search decision bank</strong> to find decisions that have been made or shared with you.</p>
      <p>If applicable, you can see your pending <strong>assigned drivers</strong>, <strong>in-flight decisions</strong>, and past decisions that are ready for you to <strong>follow up on results</strong>. Click on the decision tile to view the decision.</p>
    </div>
    <div className="px-3 mb-3" hidden={!isDSightTab(home, current_user, current_org)}>
      <DSightText/>
    </div>
    <HelpButtons />
  </Fragment>;
}
const mapStateToProps = ({ home, current_user, current_org }) => ({
  home, current_user, current_org
});
export default connect(mapStateToProps)(Index);
