import React from "react";
import Toggle from "../common/Toggle";

const itemTabs = [
  {
    value: 'createADecision',
    body: 'Create a decision',
  },
  {
    value: 'searchDecisionBank',
    body: 'Search decision bank',
  },
];

export default ({ selectedItem, handleToggle }) =><div className="mb-2">
    <Toggle items={itemTabs} selectedItem={selectedItem} onClick={handleToggle}/>
  </div>
