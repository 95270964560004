import React, {useState} from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooter } from "../../common/modals";
import { isBlank, isEmail, successActions, uniqArray } from "../../helpers/common";
import Decision from "../../models/decision";
import {Typeahead} from "react-bootstrap-typeahead";
import {isGroupContact} from "../../helpers/decision_helpers";
import { JoditTextEditor } from '../../common/JoditTextEditor';
import {onChangeApprovalInvites} from "../side_panel/sections/ApprovalSection";
import {createApprovalInvites} from "../../store/decision/actions";
import {loadContacts, reloadContacts} from "../../store/contacts/actions";
import {checkLoadingEffect} from "../../helpers/callbacks_helpers";
import { DEFAULT_TYPEHEAD_ATTRIBUTES } from "./helpers/share_helpers";
import { renderContactItem } from "../side_panel/helpers/collaborators_helpers";
import { renderTag } from "../../tree_wizard/steps_wizard/steps/helpers/collaboration_helpers";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";
import {onInviteKeyDown} from "../../homepage/modals/UploadDecisionDocumentModal";

const NotificationComment = ({ comment, setComment }) => {
  return <div className="mb-0">
    <h3>Notification comments</h3>
    <JoditTextEditor className="jodit-container-default"
                     value={comment}
                     placeholder="Add a personal note to the notification"
                     setValue={setComment}
                     autofocus={false} />
  </div>
}

const ApprovalInvites = ({contactsData, approvalInvites, decision, setApprovalInvites, filterNewApprovalInvites, current_user}) => {
  const filteredContacts = () => {
    return contactsData.contacts.filter(contact => {
      if(isGroupContact(contact)) return true;
      if (approvalInvites.indexOf(contact.value) > -1) return false;

      return decision.invites.filter(invite => invite.type === 'ApprovalInvite')
    })
  }
  const ref = React.createRef();

  const onKeyDown = (e) => onInviteKeyDown(e, ref, approvalInvites, setApprovalInvites, filterNewApprovalInvites)

  return <>
    <h3>Approvers</h3>
    <div className="mb-3 w-100">
      <Typeahead
        {...DEFAULT_TYPEHEAD_ATTRIBUTES} allowNew
        id="approval-invite-input" labelKey="value"
        ref={ref} selected={approvalInvites}
        onChange={(selected) => onChangeApprovalInvites(selected, setApprovalInvites, filterNewApprovalInvites)}
        options={filteredContacts()}
        renderMenuItemChildren={renderContactItem}
        onKeyDown={onKeyDown}
        renderToken={(option, events, index) => renderTag(option, events, index, contactsData)}
      />
    </div>
  </>
};

const RequestApprovalModal = ({
                                shown, onClose, decision, contactsData, loadContacts,
                                reloadContacts, resetDecisionSetData,
                                createApprovalInvites, current_user
                              }) => {
  const decisionObj = new Decision(decision)
  const collaborators = decisionObj.collaborators
  const [approvalInvites, setApprovalInvites] = useState([])
  const [submit, setSubmit] = useState(false)
  const [comment, setComment] = useState('')

  const filterNewApprovalInvites = (newApprovals) => uniqArray(newApprovals.filter(email => isEmail(email)))

  checkLoadingEffect(contactsData, loadContacts)

  const submitApprovalInvite = () => {
    setSubmit(true)
    const emails = approvalInvites.join(',')
    const contacts = approvalInvites.map(email => (contactsData.contacts.find(c => c.value === email) || { email }))
    createApprovalInvites({
      emails, contacts, comment,
      callback: (success) => {
        successActions(success, [
          reloadContacts, resetDecisionSetData,
          () => setApprovalInvites([]),
          () => setComment('')
        ])
        setSubmit(false)
        onClose()
      }
    })
  }
  const closeModal = () => {
    setApprovalInvites([]);
    setComment('')
    onClose()
  }

  return <Modal enforceFocus={false} size="lg" show={shown} backdrop="static" onHide={closeModal}>
    <Modal.Body>
      <div>
        <CloseIcon onClose={closeModal} classParams="float-end ms-2"/>
        <h2>Request approval</h2>
      </div>
      <ApprovalInvites {...{ approvalInvites, contactsData, collaborators, decision, setApprovalInvites, filterNewApprovalInvites, current_user }} />
      <NotificationComment {...{ comment, setComment }} />
    </Modal.Body>
    <ModalDoneFooter onClose={submitApprovalInvite} disabled={isBlank(approvalInvites) || submit} />
  </Modal>
}

const mapStateToProps = ({ modal, decision, current_user, contacts }) => ({
  modal, decision, current_user,
  contactsData: contacts
})
export default connect(mapStateToProps, {
  loadContacts, createApprovalInvites, reloadContacts, resetDecisionSetData
})(RequestApprovalModal);
