import React from 'react';
import HelpContent from "../help_content";
import BaseHelpPanel from "../../common/BaseHelpPanel";
import { connect } from "react-redux";
import { isTreePreview } from "../../helpers/wizard_helpers";

export const HelpPanel = ({ wizard }) =>
  isTreePreview(wizard) ?
     <BaseHelpPanel addClass="left-side-panel-position tree-page">
       <HelpContent/>
     </BaseHelpPanel> :
     <BaseHelpPanel position={'right'}>
       <HelpContent/>
     </BaseHelpPanel>

const mapStateToProps = ({ wizard }) => ({wizard});
export default connect(mapStateToProps, null)(HelpPanel);
