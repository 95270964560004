import React from "react";
import Decision from "../../../models/decision";
import { isInFlightDecision } from "../../../helpers/home_decision_helpers";
import { safeDescription } from "../../../helpers/decision_helpers";
import { ButtonText, buttonTextColor, CollaboratorsImages, LinkWrapper } from "./DecisionTile";
import { formatTreeCompletion } from "../learning/AllDecisionsStatisticsPanel";
import { isBlank, isPresent } from "../../../helpers/common";
import { isDraftDecision, isUnstartedDecision } from "../../../models/decision_state";

const displayDriversProgress = (decision) => {
  if (!isInFlightDecision(decision)) return false;
  if (isBlank(decision.drivers?.total_drivers)) return false;

  return isPresent(decision.drivers?.tree_completion);
}

const TreeCompletionMeter = ({ decision }) => {
  if (!displayDriversProgress(decision)) return null;

  return <div className="drivers-progress lh-sm float-end text-end">
    <div className="fw-bolder">Progress</div>
    <div className="lh-normal">{formatTreeCompletion(decision.drivers)}</div>
  </div>
}

export default ({
                  decision, current_user,
                  isSearch, isDSightHistory = false, isInDecisionSet = false,
                  hasAccess = true, hideCollaborators, isApprovalNeeded = false
                }) => {
  const decisionObj = new Decision(decision)
  const collaborators = decisionObj.collaborators

  return <LinkWrapper isSearchSection={isSearch} {...{ decision, hasAccess, isInDecisionSet }}>
    <div className="tile-content bg-white px-2 pt-3 pb-2 h-100">
      <div className="btn btn-secondary btn-sm btn-sm-round mb-2" hidden={isSearch || !(isDraftDecision(decision) || isUnstartedDecision(decision))}>
        <i className="fas fa-pencil-alt h4 m-0 w-100"/>
      </div>
      <h4 className="decision-title mt-0">
        {safeDescription(decision)}
      </h4>
      <div
        className={`collaborators mb-3 ${displayDriversProgress(decision) ? 'text-start px-2 w-100' : 'align-items-center'}`}
        hidden={hideCollaborators}>
        <CollaboratorsImages object={decision} collaborators={collaborators}/>
        <TreeCompletionMeter decision={decision}/>
      </div>
      <h5 className={`rounded-pill bg-light w-100 ${hideCollaborators ? 'mt-5' : ''}`}>
        <span className={buttonTextColor(decision, isSearch || isDSightHistory, isApprovalNeeded)}>
          <ButtonText {...{ decision, isSearchSection: isSearch, isDSightHistory, isApprovalNeeded, current_user }}/>
        </span>
      </h5>
    </div>
  </LinkWrapper>
}
