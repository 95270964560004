import React, { useRef } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { openModal } from "../../../../store/modals/actions";
import { attachEventEffect } from "../../../../helpers/html_events_helpers";

const AddDriver = ({ drivers, tree, decision, treeNode, openModal }) => {
  const ref = useRef();

  const onAddDriver = (e) => {
    e.preventDefault();
    openModal({
      decision,
      drivers: drivers,
      data_sources: tree.data_sources,
      slug: treeNode.childrenEmptyDriver.driver.slug,
      type: 'WizardAddDriverDetailsModal'
    })
  }

  attachEventEffect(ref, onAddDriver)

  return <Button onClick={onAddDriver} className="btn btn-secondary btn-sm btn-sm-round lh-1 me-1" ref={ref}>
    <i className="fas fa-plus fa-sm" />
  </Button>
}

const mapStateToProps = ({ decision, template, tree },  { isTemplate }) => ({
  tree, decision: isTemplate ? template : decision
});
export default connect(mapStateToProps, { openModal })(AddDriver);
