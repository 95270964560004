import React, {useCallback, useState, useEffect} from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { resetSidebars, setDecisionOrderSidebarOpen, setDecisionSidebarOpen } from "../../store/sidebar/actions";
import HamburgerDropdown from './HamburgerDropdown'
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { startNewDecision } from "../../store/homepage/actions";
import { isBlank, isPresent, successActions } from "../../helpers/common";
import { resetTree } from "../../store/tree/common_actions";
import { resetTemplate } from "../../store/template/common_actions";
import { resetPlaybookNotes } from "../../store/playbook_notes/actions";
import { isDSightTemplate, isObsoleteDSightTemplate } from "../../helpers/template_helpers";
import { addTemplateToSet } from "../../store/template_set/actions";
import { openModal } from "../../store/modals/actions";
import { openDecisionSetModal } from "../../homepage/modals/DecisionSetModal";
import { isDecisionStarted, startDecision } from "../../store/decision_set/actions";
import { alreadyStartedTemplateAlert } from "../../helpers/alert_helpers";
import { openAddToSetModalCallback } from "../../template_set_view/modals/AddToSetModal";
import { openStartDecisionModal } from "../../homepage/modals/StartDecisionModal";
import { openStartUnstartedDecisionModal } from "../../decision_set_view/modals/StartUnstartedDecisionModal";
import { getSectionSlugFromParam } from "../../helpers/set_helpers";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";
import { getScenarioIdFromParam } from "../../forecast_simulator_scenario/helpers/helpers";

export const addToTemplateSetCallback = ({
                                           template, templateSet, openDecisionDetailsSideBar, addTemplateToSet, openModal,
                                           history
                                        })  =>
  useCallback(() => {
    openDecisionDetailsSideBar(false);
    if(isBlank(template.template_set_slug)) {
      addTemplateToSet(template.slug, templateSet, (success) => {
        if(success) history.push(`/template_sets/${templateSet}`);
      }, getSectionSlugFromParam(document))
    } else {
      openAddToSetModalCallback(openModal, template)
    }
  }, [template, templateSet])

export const startDecisionFromTemplateCallback = ({
                                                    template, history, disabled, setDisabled,
                                                    startNewDecision, decisionSet = null,
                                                    openDecisionDetailsSideBar, openModal, resetTree,
                                                    resetDecisionSetData, isDecisionSet = false
                                                  }) =>
  useCallback(() => {
    openDecisionDetailsSideBar(false);
    if (isDSightTemplate(template)) {
      setDisabled(true)
      const forecast_scenario_id = getScenarioIdFromParam(document);
      startNewDecision({
        template_slug: template.slug, description: template.description,
        dsight: true, decision_set_slug: decisionSet,
        forecast_scenario_id
      }, slug => {
        if (isPresent(slug)) {
          template.loaded = false;
          successActions(true, [resetTree]);
          setDisabled(false)
          history.push(`/dsight/decisions/${slug}/tree_viewer`);
        }
      })
    } else if(isDecisionSet) {
      const getParams = new URLSearchParams(document.location.search);
      const section_slug = getParams.get('section_slug');
      const decision_set = getParams.get('decision_set');
      startNewDecision({
        ...isPresent(section_slug) && { section_slug: section_slug },
        template_slug: template.slug,
        description: template.description,
        dsight: false,
        decision_set_slug: decision_set,
        flow_enum: 'unstarted_tree',
        playbook: template.playbook,
        assigned_collaborator_email: template.assigned_collaborator_email,
        poll_notes: template.poll_notes
      }, () => {
        resetDecisionSetData();
        history.push(`/decision_sets/${decision_set}?left_side_bar_open=set_decisions`)
      })
    } else {
      openStartDecisionModal({ openModal, template })
    }
  }, [template])

export const startDecisionFromUnstartedTemplateCallback = ({
                                                             template,
                                                             decisionSlug, decisionSet, history,
                                                             isDecisionStarted, startUnstartedDecision, openModal,
                                                             resetDecisionSetData
                                                           }) =>
  useCallback(() => {
    const startedCallback = () => {
      resetDecisionSetData()
      alreadyStartedTemplateAlert().then(() => {
        history.push(`/decision_sets/${decisionSet}`)
      })
    }
    const submitData = {
      slug: decisionSlug,
      scope: 'decision_sets',
      setSlug: decisionSet
    }
    if (isDSightTemplate(template)) {
      startUnstartedDecision(submitData, (status, { already_started = false } = {}) => {
        if (status) {
          history.push(`/dsight/decisions/${decisionSlug}/tree_viewer`)
        } else if (already_started) {
          startedCallback()
        }
      })
    } else {
      isDecisionStarted(submitData, (status, { already_started = false } = {}) => {
        if (status) {
          openStartUnstartedDecisionModal({
            openModal, template, decision: { slug: decisionSlug }
          })
        } else if (already_started) {
          startedCallback()
        }
      })
    }
  }, [template, decisionSlug, decisionSet])

const AddToTemplateSet = (opts) =>
  <Button onClick={addToTemplateSetCallback(opts)} className="btn-primary btn">
    <i className="fas fa-plus fa-lg m-0 align-middle" />&nbsp;
    Add to decision flow
  </Button>

const StartDecisionBtn = ({isDsight, disabled, isDecisionSet = false, isUnstartedTemplateInSet = false, ...opts}) => {
  return <Button className="btn-primary align-center btn" disabled={disabled} {...opts}>
    <i className="fas fa-plus fa-lg m-0 align-middle" />&nbsp;
    {isDsight ? 'Start decision' :
      isUnstartedTemplateInSet ? 'Create decision' :
        isDecisionSet ? 'Add to decision flow' : 'Create decision'}
  </Button>
}


const StartDecisionFromTemplate = ({isDsight, isDecisionSet, ...opts}) =>
  <StartDecisionBtn onClick={startDecisionFromTemplateCallback({ isDecisionSet, ...opts })} isDsight={isDsight} disabled={opts.disabled} isDecisionSet={isDecisionSet} />

const StartDecisionFromUnstartedTemplate = (opts) =>
  <StartDecisionBtn onClick={startDecisionFromUnstartedTemplateCallback(opts)} isUnstartedTemplateInSet={true} />

const StartDecisionSet = (opts) =>
  <Button onClick={() => openDecisionSetModal(opts)} className="btn-primary btn">
    <i className="fas fa-plus fa-lg me-2" />
    <span>Create decision flow</span>
  </Button>

export const templateSetParams = () => {
  const getParams = new URLSearchParams(document.location.search);
  const templateSet = getParams.get('template_set');
  const decisionSet = getParams.get('decision_set');
  const decisionSlug = getParams.get('decision_slug');
  const setTemplatePreview = isPresent(templateSet)
  const isDecisionSet = isPresent(decisionSet)
  const isUnstartedTemplateInSet = isDecisionSet && isPresent(decisionSlug)

  return {
    templateSet,
    setTemplatePreview,
    decisionSet, isDecisionSet,
    decisionSlug, isUnstartedTemplateInSet
  }
}

export const getTemplateSetParamsFromTemplate = (template) => {
  if (!template.loaded) return { parentTemplateSet: '', isParentTemplateSet: false }

  const parentTemplateSet = template?.template_set_slug
  const isParentTemplateSet = isPresent(parentTemplateSet)

  return { parentTemplateSet, isParentTemplateSet }
}

const TemplatePreviewHeader = ({
                                 tree, template, isDsight = false, current_org, wizard,
                                 openDecisionDetailsSideBar, openDecisionOrderSideBar,
                                 resetSidebars, resetTree, resetTemplate, resetPlaybookNotes, analysis, learn,
                                 ...opts
                              }) => {
  const { error } = tree;
  if (error || !template.loaded) return <nav className="navbar"/>;
  const { parentTemplateSet, isParentTemplateSet } = getTemplateSetParamsFromTemplate(template);
  const {
    templateSet,
    setTemplatePreview,
    decisionSet, isDecisionSet,
    decisionSlug, isUnstartedTemplateInSet
  } = templateSetParams()
  const history = useHistory();
  const processBackClick = useCallback(() => {
    successActions(true, [resetTree, resetTemplate, resetSidebars, resetPlaybookNotes]);
    if (learn.loaded) {
      history.push(`/learn/details/${template.slug}`)
    } else if (setTemplatePreview) {
      history.push(`/template_sets/${templateSet}/add_template`)
    } else if(isDecisionSet && isPresent(decisionSlug)) {
      history.push(`/decision_sets/${decisionSet}`)
    } else if(isDecisionSet && !isPresent(decisionSlug)) {
      history.push(`/decision_sets/${decisionSet}/add_decision`)
    } else if (parentTemplateSet) {
      history.push(`/template_sets/${parentTemplateSet}/preview`)
    } else if (isDsight && analysis.loaded) {
      history.push(`/dsight/templates/${template.slug}/analysis`)
    } else  {
      redirectToHomeCallback(true, history);
    }
  }, [parentTemplateSet])
  const [disabled, setDisabled] = useState(false)

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <span className="btn btn-secondary btn-lg-round d-none d-lg-flex justify-content-around me-2" onClick={processBackClick}>
          <i className="fas fa-lg fa-arrow-left"/>
        </span>
        <h5>Decision preview</h5>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerDropdown {...{...opts, isDsight, setDisabled}}/>
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          <li className="nav-item ms-2">
            {
              setTemplatePreview ?
                <AddToTemplateSet {...{ ...opts, template, openDecisionDetailsSideBar, templateSet, history }} /> :
                isUnstartedTemplateInSet ?
                  <StartDecisionFromUnstartedTemplate {...{ ...opts, decisionSlug, history, decisionSet, template }} /> :
                  isDecisionSet ?
                    <StartDecisionFromTemplate {...{ ...opts, template, history, openDecisionDetailsSideBar, resetTree, decisionSet, isDecisionSet }} /> :
                    isParentTemplateSet ?
                      <StartDecisionSet {...opts} /> :
                      isObsoleteDSightTemplate(template) ? null :
                        <StartDecisionFromTemplate {...{ ...opts, template, history, openDecisionDetailsSideBar, resetTree, isDsight, disabled, setDisabled, isDecisionSet }} />
            }
          </li>
        </ul>
      </div>
    </nav>
  </header>;
}

const mapStateToProps = ({ tree, current_user, template, sidebar, template_set, current_org, wizard, analysis, learn }) => ({
  tree, current_user, template, sidebar, template_set, current_org, wizard, analysis, learn
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  openDecisionDetailsSideBar: (flag) => dispatch(setDecisionSidebarOpen(flag)),
  openDecisionOrderSideBar: (flag) => dispatch(setDecisionOrderSidebarOpen(flag)),
  startNewDecision: (data = {}, callback) => dispatch(startNewDecision(data, callback)),
  isDecisionStarted: (data, callback) => dispatch(isDecisionStarted(data, callback)),
  startUnstartedDecision: (data, callback) => dispatch(startDecision(data, callback)),
  resetSidebars: () => dispatch(resetSidebars()),
  resetTree: () => dispatch(resetTree()),
  resetTemplate: () => dispatch(resetTemplate()),
  resetPlaybookNotes: () => dispatch(resetPlaybookNotes()),
  resetDecisionSetData: () => dispatch(resetDecisionSetData()),
  addTemplateToSet: (templateSlug, setSlug, callback, sectionSlug) => dispatch(addTemplateToSet(templateSlug, setSlug, callback, sectionSlug))
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplatePreviewHeader);
