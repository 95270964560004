import { useEffect, useRef } from 'react';

export const initAutofocusInput = (conditions = []) => {
  const inputElement = useRef(null);
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, conditions);
  return inputElement;
}
