import React from "react";
import SimulationSettingsPanel from "./side_panel/SimulationSettingsPanel";
import LargeScaleInput from "./side_panel/LargeScaleInput";
import ImportValuesPanel from "./side_panel/ImportValuesPanel";

const Sidebars = (opts) =>
  <div className="sidebars forecast-sliding-panel">
    <SimulationSettingsPanel key="simulation-settings-side-panel" { ...opts }/>
    <LargeScaleInput key="large-scale-input-side-panel" { ...opts }/>
    <ImportValuesPanel  key="import-values-side-panel" { ...opts }/>
  </div>
export default Sidebars;