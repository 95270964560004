import React, {Fragment, useCallback, useEffect} from "react";
import {connect} from "react-redux";
import { updateEntryPointData } from "../EntryPoint";
import { checkLoadingEffect } from "../helpers/callbacks_helpers";
import { Loader } from "../common/Loader";
import {loadReport, loadReportDecisions, resetReportData} from "../store/report/actions";
import {loadOrgReportProperties} from "../store/org_report_properties/actions";
import AlertsSection from "../alerts";
import Header from "./header";
import ReportDataSection from "./ReportDataSection";
import DecisionsSection from "./DecisionsSection";
import { isPresent } from "../helpers/common";

const Wrapper = ({ children }) =>
  <Fragment>
    <AlertsSection />
    <Header />
    {children}
  </Fragment>

const ReportView = ({ report, org_report_properties,
                      loadReport, loadOrgReportProperties, loadReportDecisions, resetReport, match }) => {
  const getParams = new URLSearchParams(document.location.search)
  const decisionParam = getParams.get('decision_slug');
  const hideDecisions = isPresent(decisionParam);
  const loadRequestsData = hideDecisions ? { decision_slug: decisionParam } : {};
  useEffect(() => {
    updateEntryPointData(match, 'reports', () => {})
  }, [match?.params?.id])
  useEffect(() => {
    if (report.loaded && report.slug !== match?.params?.id) {
      resetReport()
    }
  }, [match?.params?.id, report])

  const ReportTitle = useCallback(() => {
    return <span>{report.name}</span>
  }, [report])

  checkLoadingEffect(org_report_properties, loadOrgReportProperties)
  useEffect(() => loadReport(loadRequestsData), []);
  if(!hideDecisions) useEffect(() => loadReportDecisions(), []);

  if (report.loading) {
    return <Wrapper report={report}>
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper report={report}>
      <div className={`home container mb-3 mx-auto`}>
        <h1 className="mt-3">
          <ReportTitle/>
        </h1>
        <ReportDataSection />
        {hideDecisions ? null : <DecisionsSection />}
      </div>
    </Wrapper>;
  }
};
const mapStateToProps = ({ wizard, report, current_user, org_report_properties }) => ({
  wizard, report, current_user, org_report_properties
});
const mapDispatchToProps = (dispatch) => ({
  loadReport: (data) => dispatch(loadReport(data)),
  loadOrgReportProperties: () => dispatch(loadOrgReportProperties()),
  resetReport: () => dispatch(resetReportData()),
  loadReportDecisions: (data) => dispatch(loadReportDecisions(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportView);
