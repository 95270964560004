import isEmpty from 'ramda/es/isEmpty'

const emailRegEx = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const formatCurrencyRegEx = /(\d)(?=(\d{3})+(?!\d))/g;
const capitalizeRegex = /(^\w{1})|(\s+\w{1})/g;
const htmlTagsRegex = /<\/?[^>]+(>|$)/g;

export const stubClick = (e) => e.stopPropagation()

export const protocolHost = () => window.location.protocol + "//" + window.location.host;

export const expireCookie = () => new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)

export const capitalize = (string) =>
  isBlank(string) ? '' : string.charAt(0).toUpperCase() + string.slice(1)
export const capitalizeAllWords = (string) => string.replace(capitalizeRegex, letter => letter.toUpperCase());

export const isBlank = (value) =>
  value == null || isEmpty(Object.getPrototypeOf(value) === String.prototype ? value.trim() : value) || (value === false || value === 'false')
export const isPresent = (value) => !isBlank(value)

export const uniqArray = (array) => {
  const onlyUnique = (value, index, self) => self.indexOf(value) === index
  return array.filter(onlyUnique);
}
export const equalsArraysIgnoreOrder = (a, b) => {
  if (a.length !== b.length) return false;

  const uniqueValues = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e === v).length;
    const bCount = b.filter(e => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
}
export const uniqueBy = (array, property) => {
  const trimValue = (value) => typeof value === 'string' ? value.trim() : value;

  const uniqValues = array.reduce((accumulator, item) => {
    const trimmedValue = trimValue(item[property]);
    if (!accumulator.includes(trimmedValue)) { accumulator.push(trimmedValue) }

    return accumulator;
  }, []);

  return uniqValues.map(value => array.find(item => trimValue(item[property]) === value));
}

export const uniqueByCallback = (array, calculateProperty = () => {}) => {
  const uniqValues = array.reduce((a, d) => {
    if (!a.includes(calculateProperty(d))) { a.push(calculateProperty(d)); }
    return a;
  }, []);
  return uniqValues.map(value => array.find(item => calculateProperty(item) === value));
}

export const validURL = (str) => {
  if(isBlank(str)) return false;

  let elm;
  if(!elm) {
    elm = document.createElement('input');
    elm.setAttribute('type', 'url');
  }
  elm.value = str;
  return elm.validity.valid;
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isFirefox = typeof window.InstallTrigger !== 'undefined';

export const isEmail = (email) => emailRegEx.test(email.toString())

export const pluralize_text = (val, word, plural = word + 's') =>
  `${[1, -1].includes(Number(val)) ? word : plural}`;

export const pluralize = (val, word, plural = word + 's', zeroValWord = '') =>
  `${Number(val) === 0 ? (zeroValWord || val) : val} ${[1, -1].includes(Number(val)) ? word : plural}`;

export const qSortArray = (array, asc, valueFunction) => {
  const mapped = array.map((el, i) => ({ index: i, value: valueFunction(el) }));
  const sortOrder = asc ? 1 : -1;
  mapped.sort((a, b) => a.value > b.value ? 1*sortOrder : (a.value < b.value ? -1*sortOrder : 0));
  return mapped.map((el) => array[el.index]);
};

export const sortSourceByType = (data_sources, types) => {
  return data_sources.sort((a, b) => {
    // Prioritize elements with 'link_url'
    if ( isPresent(a.link_url) && isBlank(b.link_url)) {
      return -1;
    } else if (isBlank(a.link_url) && isPresent(b.link_url)) {
      return 1;
    }
    if (!types.includes(a.content_type) && types.includes(b.content_type)) {
      return -1;
    } else if (types.includes(a.content_type) && !types.includes(b.content_type)) {
      return 1;
    }
    return 0;
  });
};

export const groupBy = (arr, k, fn = () => true) =>
  arr.reduce((r, c) => (fn(c[k]) ? r[c[k]] = [...r[c[k]] || [], c] : null, r), {});

export const paginateArray = (array, page, pageSize) =>
  array.slice((page - 1) * pageSize, page * pageSize)

export const arrayLastElement = (array) =>
  typeof array.at === 'function' ?
    array.at(-1) :
    array.reverse()[0]

export const convertBase64 = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })

export const toSentence = (arr, { comma = ',', last = ' or' } = {}) =>
  arr.join(`${comma} `).replace(/,\s([^,]+)$/, `${last} $1`);

export const clearHtmlTags = (string) => {
  if (!string) return false;

  return string.replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
}

export const successActions = (success, actions) => {
  if (success) actions.forEach(action => action())
}
export const getKeyByValue = (hash, value) => Object.keys(hash).find(key => hash[key] === value);

export const currencyFormat = (value, prefix = '$') => {
  if(isBlank(value)) return '';

  return prefix + Number(value).toString().replace(formatCurrencyRegEx, '$1,');
}

export const compareCurrencies = (expected, realized) => {
  const expectedNumber = Number(expected);
  const realizedNumber = Number(realized);
  let percentage;
  if(expectedNumber > realizedNumber) {
    percentage = ((expectedNumber - realizedNumber) / expectedNumber) * 100;
    return `${percentage.toFixed()}% less than expected`;
  }

  if(expectedNumber < realizedNumber) {
    percentage = ((realizedNumber - expectedNumber) / expectedNumber) * 100;
    return `${percentage.toFixed()}% more than expected`;
  }
  return 'Same as expected';
}
export const isInIframeOfOwnOrigin = () => {
  try {
    return window !== window.top && window.top.document.domain === window.document.domain;
  } catch(e) {
    return false;
  }
}

export function stripHtmlTags(htmlString) {
  return htmlString?.replace(htmlTagsRegex, '');
}

export function arrayToSentence(array) {
  if (array.length === 0) {
    return '';
  } else if (array.length === 1) {
    return array[0];
  } else if (array.length === 2) {
    return array.join(' and ');
  } else {
    const lastElement = array.pop();
    return `${array.join(', ')}, and ${lastElement}`;
  }
}

export const generateRandomSlug = (length) => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  let slug = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    slug += characters[randomIndex];
  }
  return slug;
};

export const previewQueryParams = (queryParams) => isBlank(queryParams) ? '' : `?${new URLSearchParams(queryParams).toString()}`

export const getRecommenderDetails = (decision, recommendation) => {
  const recommenderEmail = recommendation.assigned_recommender_email;
  const recommenderUser = recommenderEmail ? decision.users.find(item => item.email === recommenderEmail) || '' : recommendation.user;
  const text = recommenderEmail ? 'Recommended' : 'Entered';
  return { recommenderEmail, recommenderUser, text };
}

export const getDeciderDetails = (decision) => {
  const deciderEmail = decision.assigned_decider_email;
  const deciderUser = deciderEmail ? decision.users.find(item => item.email === deciderEmail) || '' : decision.deciding_user;
  const decidedAt = (deciderEmail && decision.historical_decided_at) ? decision.historical_decided_at : decision.decided_at;
  const text = deciderEmail ? 'Decided' : 'Entered';
  return { deciderEmail, deciderUser, decidedAt, text };
}

export const getDriverEnteredData = (decision, driver) => {
  const { answered_by_user, last_update_by_user, answered_at, last_update_at, responder_email } = driver;
  const users = decision.users || [];
  const answeredByUser = users.find(u => u.email === answered_by_user) || answered_by_user;
  const lastUpdateUser = users.find(u => u.email === last_update_by_user) || last_update_by_user;
  const driverResponder = users.find(u => u.email === responder_email) || responder_email;
  const enteredUser = lastUpdateUser ? lastUpdateUser : answeredByUser;
  const enteredAt = last_update_at ? last_update_at : answered_at;

  return { enteredUser, enteredAt, driverResponder };
};

export const generatePromisesCallbacks = ({ promises = [], setLoading, controller }) => {
  if (isPresent(promises)) {
    setLoading(true)

    const fetchAllData = async () => {
      await Promise.all(promises.map(f => f())).then(() => {
        setLoading(false)
      })
    }

    setTimeout(() => {
      fetchAllData();
    }, 15)
  }

  return () => {
    setLoading(false)
    controller.abort();
  }
}
