import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'
import { resetWizard } from "../../store/wizard/actions";
import {
  isCompletedWizard, isReportDetails
} from "../../helpers/wizard_helpers";
import ReportDetailsStep from "./steps/ReportDetailsStep";
import EntryPoint from "../../EntryPoint";
import { isBlank } from "../../helpers/common";
import { resetReportData } from "../../store/report/actions";
import { reloadOrgReports } from "../../store/current_org/actions";

const WizardStep = React.forwardRef(({
                                       wizard,
                                       resetWizard, resetReport, reloadOrgReports
                                     }, ref) => {
  if (isCompletedWizard(wizard)) {
    const { objectSlug } = EntryPoint.instance;
    resetWizard();
    reloadOrgReports()
    return <Redirect to={`/reports/${objectSlug}`}/>
  }
  if (isReportDetails(wizard)) return <ReportDetailsStep ref={ref} />

  return null;
})

export const StepsWizard = ({ wizard, stepRef, ...opts }) =>
  <div className="container-fluid p-0">
    <span hidden={isBlank(wizard.error)}>Error: {wizard.error}</span>

    <WizardStep {...opts} wizard={wizard} ref={stepRef} />
  </div>

const mapStateToProps = ({ wizard, report }) => ({ wizard, report });
const mapDispatchToProps = (dispatch) => ({
  reloadOrgReports: () => dispatch(reloadOrgReports()),
  resetWizard: () => dispatch(resetWizard()),
  resetReport: () => dispatch(resetReportData()),
});
const wrapper = React.forwardRef((props, ref) => <StepsWizard {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
