import React from 'react';
import { isBlank, isPresent, qSortArray } from "../../../helpers/common";
import { isDSightTemplate, isTemplateDraft } from "../../../helpers/template_helpers";
import { resetAllFilters } from "../../../helpers/filter_helpers";
import NullSearchResults from "./NullSearchResults";

export const genNullResultsSection = ({
                                        setSearchQuery, setLoading, action,
                                        ...opts
                                     }) =>
  <NullSearchResults {...opts} resetAllFilters={() => resetAllFilters(setSearchQuery, setLoading, action)}/>

const genCombinedFilter = ({
                             filtersUsed = false,
                             selectedTemplateSet = null,
                             selectedDecisionSet = null,
                             isTemplateSet = false,
                             isDecisionSet = false
                          }) => {
  const hideTemplateSets = (t) => filtersUsed ? true : isBlank(t.template_set_slug);

  const hideSelectedTemplates = (t) =>
    isPresent(selectedTemplateSet?.slug) ?
      t.template_set_slug !== selectedTemplateSet.slug :
      false

  const templatesFilter = (t) =>
    isTemplateSet ?
      (hideSelectedTemplates(t) && !isDSightTemplate(t) && !isTemplateDraft(t)) :
      isDecisionSet ? !isDSightTemplate(t) : hideTemplateSets(t)

  return ({ type, ...recordData }) =>
    type === 'Template' ? templatesFilter(recordData) :
      type === 'TemplateSet' ? !isTemplateSet && !isDecisionSet:
        false
}

const CombinedList = ({
                        list = [],
                        combinedFilter = () => true,
                        renderRow = () => null
                     }) =>
  list.filter(combinedFilter).map(renderRow)

export const TemplateCombinedList = ({
                                       getTemplates,  getTemplateSets,
                                       showNullResults, renderNullResults = () => null,
                                        ...opts
                                     }) => {
  if (showNullResults) return renderNullResults()

  const combinedArray =
    [
      ...getTemplates().map(h => ({ ...h, type: 'Template' })),
      ...getTemplateSets().map(h => ({ ...h, type: 'TemplateSet' }))
    ]
  if (isBlank(combinedArray)) return null;

  const combinedFilter = genCombinedFilter(opts)
  const list = qSortArray(combinedArray, false, d => d.created_at)

  return <CombinedList {...{ ...opts, list, combinedFilter }} />
}
