import { useEffect } from 'react';
import { isBlank, isPresent } from "../../../helpers/common";

export const openAllActionUseEffect = (openAll, openIndexes, allRateChoicesSlugs, {setOpenIndexes, setOpenAll}) => {
  useEffect(() => {
    if (isPresent(openAll)) setOpenIndexes(allRateChoicesSlugs);
    if (isBlank(openAll) && isPresent(openIndexes)) setOpenIndexes([]);
  }, [openAll]);

  useEffect(() => {
    if (isBlank(openIndexes)) setOpenAll(false);
    if (openIndexes.length === allRateChoicesSlugs.length) setOpenAll(true);
  }, [openIndexes]);
};

export const setAccordionDropdownToggleState = (openIndexes, eventKey, {setOpenState}) => {
  useEffect(()=>{
    setOpenState(openIndexes.includes(eventKey));
  },[openIndexes]);
};

export const toggleClickAction = (openIndexes, eventKey, {setOpenIndexes}) => {
  openIndexes.includes(eventKey) ?
    setOpenIndexes(openIndexes.filter(el => el !== eventKey )) :
    setOpenIndexes([...openIndexes, eventKey]);
};
