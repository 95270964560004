import React, {useEffect, useState} from 'react';
import { isPresent } from "../../../helpers/common";
import {checkLoadingEffect} from "../../../helpers/callbacks_helpers";
import {fetchContacts} from "../../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import {connect} from "react-redux";
import {loadContacts} from "../../../store/contacts/actions";
import AttributeResponseRow from "./AttributeResponseRow";
import {updateDriver} from "../../../store/sidebar/actions";
import {saveSideBarData} from "../../../store/sidebar/common_actions";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";

const AttributeResponseSection = ({
                                 driverData, contactsData, collaborators = null,
                                 allowPending = true, allowGuest = true, loadContacts,
                                 className = '', updateDriver, decision
                               }) => {
  if (isDSightDecision(decision)) return null;

  checkLoadingEffect(contactsData, () => loadContacts(() => {}, { invite_yourself: true }));
  collaborators = collaborators || fetchContacts({}, contactsData, allowGuest, allowPending)

  const { driver } = driverData;
  const [responder, setResponder] = useState(driver.responder_email)
  const { slug } = driver;

  const onChangeResponder = (option) => {
    const value = isPresent(option) && option.value ? option.value : '';
    setResponder(value);
    updateDriver({ slug, responder_email: value })
  };

  const onCreateResponder = (email) => {
    setResponder(email);
    updateDriver({ slug, responder_email: email })
  };

  useEffect(() => {
    setResponder(driver.responder_email)
  }, [driver.responder_email])

  return <AttributeResponseRow {...{collaborators, responder, onChangeResponder, onCreateResponder, className } } />
}

const mapStateToProps = ({ contacts, decision, tree }) => ({ contactsData: contacts, decision, tree });
export default connect(mapStateToProps, {loadContacts, updateDriver, saveSideBarData})(AttributeResponseSection);
