import React from 'react';
import { useSortBy, useTable } from 'react-table'
import BTable from 'react-bootstrap/Table';
import { isBlank } from "../helpers/common";
import { PLACEHOLDER } from "../homepage/tabs/learning/AllDecisionsStatisticsPanel";

export const SUCCESS_GREEN = 'bg-success-green';
export const SUCCESS_YELLOW = 'bg-success-yellow'
export const SUCCESS_RED = 'bg-success-red'
export const WHITE_COLUMN = 'bg-white'
export const DEFAULT_COLUMN = 'bg-light'
export const DEFAULT_CELL = 'rounded px-3 py-1'

export const columnBgColor = (val) => {
  if (isBlank(val) || val === PLACEHOLDER) return DEFAULT_COLUMN
  if (val >= 0.8) return SUCCESS_GREEN
  if (val >= 0.6) return SUCCESS_YELLOW

  return SUCCESS_RED
}

const FooterRow = ({ row }) =>
  <tfoot>
  <tr {...row.getRowProps()}>
    {
      row.cells.map(cell =>
        <td {...cell.getCellProps({
          className: [
            cell.column.cellClassName ? cell.column.cellClassName : '',
            cell.column.footerCellClassName ? cell.column.footerCellClassName : ''
          ].join(' ').trim()
        })}>
          {cell.render('footerCell')}
        </td>
      )
    }
  </tr>
  </tfoot>


export const Table = ({ columns, data, initialState, footer= false, footerRow = null, stickyHeader=false }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data, initialState }, useSortBy)

  return <BTable className="learn-table m-0" striped={false} bordered={false} borderless={true} hover={false} size="sm" {...getTableProps()}>
    <thead className={stickyHeader ? 'sticky-header' : ''}>
    {headerGroups.map(headerGroup => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map(column => (
          <th {...column.getHeaderProps(
            {
              ...column.getSortByToggleProps(),
              className: [
                column.className ? column.className : '',
                column.headerClassName ? column.headerClassName : ''
              ].join(' ').trim()
            },
          )}>
            <div className={`${column.isSorted ? 'text-dark' : 'text-gray'}`}>
              {column.render('Header')}
            </div>
          </th>
        ))}
      </tr>
    ))}
    </thead>
    <tbody {...getTableBodyProps()}>
    {rows.map(
      (row, _i) => {
        prepareRow(row);
        return <tr {...row.getRowProps()}>
          {
            row.cells.map(cell =>
              <td {...cell.getCellProps({
                className: [
                  cell.column.className ? cell.column.className : '',
                  cell.column.cellClassName ? cell.column.cellClassName : '',
                  cell.column.getCellClassName ? cell.column.getCellClassName(cell) : ''
                ].join(' ').trim()
              })}>{cell.render('Cell')}</td>
            )
          }
        </tr>
      }
    )}
    </tbody>
    { footer ? <FooterRow row={footerRow || rows[0]} /> : null }
  </BTable>
}

