import MessageRow from "../../side_panel/discussion/MessageRow";
import React from "react";

const CommentsBlock = ({messages, isHideLike = false}) => {
  if (messages.length === 0) return null;

  return <div className="chat">
    <div className="card border-0">
      <div className="card-body h-auto p-0 mb-2">
        <h3 className='mb-2'>Comments</h3>
        <div className={`chat-body h-auto overflow-y-visible`}>
          {
            messages.map(message =>
              <MessageRow key={`message-row-${message.id}`} message={message} hideResponseCommentOn={true} avatarSize={'sm'}
                          isHideLike={isHideLike} isHideDate={true} isHideReply={true} className={'mb-2'} />
            )
          }
        </div>
      </div>
    </div>
  </div>
}

export default CommentsBlock