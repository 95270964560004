import { CLOSE_MODAL, LOAD_MODAL_DATA, RESET_MODAL, UPDATE_FAILED, UPDATE_MODAL_DATA } from "./types";

const initialState = {
    shown: false,
    type: '',
    decision: {},
    report: {},
    set_section: {},
    segment: null,
    drivers: [],
    data_sources: [],
    uploading_sources: [],
    slug: '',
    trigger: null,
    error: '',
    uploaded_file: {}
};

export function modalsReducer(
    state= initialState,
    action
) {
    switch (action.type) {
        case LOAD_MODAL_DATA: {
            return {
                ...state,
                ...action.payload,
                shown: true
            };
        }
        case UPDATE_MODAL_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }
        case CLOSE_MODAL: {
            return {
                ...state,
                segment: null,
                trigger: null,
                shown: false
            };
        }
        case RESET_MODAL: {
            return {
                ...state,
                decision: {},
                report: {},
                drivers: [],
                data_sources: [],
                uploaded_file: {},
                slug: '',
                type: '',
                shown: false,
                trigger: null
            };
        }
        case UPDATE_FAILED: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
}
