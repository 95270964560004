import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { pluralize_text, isPresent } from "../../helpers/common";
import Decision from "../../models/decision";
import * as moment from "moment";

const BannerTextAndDate = ({ text = '', date = null }) =>
  <>
    <span className="me-1">{text}</span>
    {isPresent(date) && <span className="text-primary">{moment(date).format('DD MMM, yyyy')}</span>}
  </>

const Title = ({ decisionObject }) => {
  if (decisionObject.isDisplayResults) {
    return <BannerTextAndDate text={'Results entered'} date={decisionObject.lastFollowup.followed_up_at}/>
  }

  if (decisionObject.showAsCompleted) {
    return <BannerTextAndDate text={'Completed'} date={decisionObject.decision?.recommendation?.recommended_at}/>
  }

  if (decisionObject.isDecisionRecorded) {
    return <BannerTextAndDate text={'Completed'} date={decisionObject.decision.decided_at}/>
  }

  if (decisionObject.isEnteredRecommendation) {
    return <BannerTextAndDate text={'Decision due by'} date={decisionObject.decision.due_date}/>
  }

  return <>
    <span className="text-primary me-1">
      {decisionObject.answeredDrivers.length} of {decisionObject.actualDriversArray.length}
    </span>
    <span>
      {pluralize_text(decisionObject.actualDriversArray.length, 'driver')} completed, {decisionObject.isRecommendationFlow && !decisionObject.isDSightTreeRecommendation ? 'recommendation' : 'decision'} not entered
    </span>
  </>;
}
const DecisionContext = ({ tree, decision }) => {
  const [loaded, setLoaded] = useState(false);

  const { drivers } = tree;
  const decisionObject = new Decision(decision, drivers)

  useEffect(() => {
    if (tree.loaded) setLoaded(true)
  }, [tree.loaded, decision])

  return <div>
    { loaded && <Title decisionObject={decisionObject} /> }
  </div>
}
const mapStateToProps = ({ tree, decision }) => ({ tree, decision });
export default connect(mapStateToProps)(DecisionContext);
