import React from 'react';
import { Link } from "react-router-dom";
import BubbleTooltip from "../../../../bubble_tooltip/BubbleTooltip";
import { safeDescription } from "../../../../helpers/decision_helpers";
import { pluralize } from "../../../../helpers/common";

export default ({ template }) =>
  <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <BubbleTooltip object_hash={template} template_set_name={template.template_set_name} />
    <div className="text-dark text-center">
      <div className="tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title mb-3">{safeDescription(template)}</h4>
        <h5 className="badge rounded-pill bg-light cursor-default py-1 w-100 mb-2">
          <span className="text-dark">{pluralize(template.decisions_count, 'decision', 'decisions', 'No')}</span>
        </h5>
        <Link to={`/learn/details/${template.slug}`}>
          <div className="btn btn-primary align-center h-36 py-1 w-100">
            <span className="mx-3">Show details</span>
          </div>
        </Link>
      </div>
    </div>
  </div>
