import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateHomepageCurrentTab } from "../../store/homepage/actions";
import { isSearchDecisions, TABS, TABS_URLS } from "../../helpers/home_helpers";

export const SearchDecisionsItem = ({displayCurrent, home, updateHomepageCurrentTab}) =>
  <li className={displayCurrent && isSearchDecisions(home) ? 'active' : ''}>
    <Link to={`/homepage/${TABS_URLS.search_decisions}`}
          onClick={() => updateHomepageCurrentTab(TABS.search_decisions)}
          className="text-nowrap">
      <span>
        <span className="icon d-inline-block text-center">
          <i className="fas fa-search fa-lg" />
        </span>
        <span className="d-none d-lg-inline tab-title">Search decision bank</span>
      </span>
    </Link>
  </li>

const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps, { updateHomepageCurrentTab })(SearchDecisionsItem);
