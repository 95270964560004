import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { isBlank } from "../../helpers/common";
import { redirectToHomepageCallback, resetDispatch } from '../../tree_view/header/HamburgerDropdown'
import OrgLogo from "../../tree_view/header/OrgLogo";
import HamburgerDropdown from "./HamburgerDropdown";

const TreeHeader = ({ current_org, decision, current_user, ...opts }) => {
  if (isBlank(decision)) return <nav className="navbar"/>;

  const history = useHistory();
  const processBackClick = useCallback(redirectToHomepageCallback({
    current_user, history, ...opts
  }), [current_user]);
  const [navText, setNavtext] = useState('');

  useEffect(() => {
    if (decision.loaded)
      setNavtext(decision.flow_enum_type + ' decision')
  }, [decision]);

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer me-2">
          <OrgLogo current_org={current_org} processBackClick={processBackClick} />
        </div>
        <h5>{navText}</h5>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerDropdown {...{current_user}} {...opts} />
        </div>
      </div>
    </nav>
  </header>;
}

const mapStateToProps = ({ current_org, current_user, decision }) => ({
  current_org, current_user, decision
});
export default connect(mapStateToProps, resetDispatch)(TreeHeader);
