import React from 'react';
import DecisionSet from "../models/decision_set";
import { isBlank } from "../helpers/common";

export default ({ set }) => {
  const { decisions, completedDecisionsNumber, totalDecisionsNumber } = new DecisionSet(set);
  if(isBlank(decisions)) {
    return null;
  }

  return <h5>
    <span className="text-primary me-1">{completedDecisionsNumber} of {totalDecisionsNumber}</span>
    decisions completed
  </h5>
};
