import React, {useEffect, useMemo, useState, Fragment, useRef} from 'react';
import { connect } from "react-redux";
import {
  createSource,
  destroySource,
  replaceSource,
  saveUploadingSources,
  updateDriver,
  updateSource,
  attachReport,
  addSourceToDecision, attachScenario
} from "../../store/modals/actions";
import {isBlank, isPresent, successActions, uniqueBy} from "../../helpers/common";
import DataSources from '../../tree_view/data_sources';
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooter } from "../../common/modals";
import {driversToArray, isChoicesDifferent} from "../../helpers/drivers_helpers";
import DecisionAssignToBlock from "../../tree_view/modals/helpers/DecisionAssignToBlock";
import DriverTypesRow, { useDefaultDriverTypesEffect } from "./helpers/DriverTypesRow";
import QuestionRow from "./helpers/QuestionRow";
import NotesRow, { updateNotesCallback } from "./helpers/NotesRow";
import debounce from "lodash.debounce";
import Driver, {
  RATING_SCALES,
  YES_OR_NO_RATING_SCALE,
  DEFAULT_RATING_SCALE,
  DriverChoiceEntryType, MIN_DISPLAYING_CHOICES
} from '../../models/driver';
import { DESCRIPTION_LIMIT } from "../../models/category_option";
import CharCounter from "../../common/CharCounter";
import Select from "react-select";
import { BorderedFilterStyle } from "../../tree_wizard/styles/choice_entry_styles";
import Decision from "../../models/decision";
import * as moment from "moment/moment";
import DriverDueDate from "../../tree_view/modals/helpers/DriverDueDate";
import {reloadContacts} from "../../store/contacts/actions";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";
import DriverResponseTypesRow from "./helpers/DriverResponseTypesRow";
import ChoicesSection from "../../tree_view/modals/entry_modal/ChoicesSection";
import {Template} from "../../models/template";
import NumberingFormattingSection from "../../tree_view/modals/entry_modal/NumberingFormattingSection";
import { isEnter } from "../../helpers/keys_helpers";

export const modalDriverData = (modal) =>
  driversToArray(modal.drivers).find((driverData) => driverData.driver.slug === modal.slug)

export const RatingScale = ({ ratingScale = DEFAULT_RATING_SCALE.value, onChangeRatingScale = () => null }) =>
  <div className="mb-3">
    <h3>Rating response type</h3>
    <Select
      value={RATING_SCALES.find(r_s => r_s.label === ratingScale || r_s.value === ratingScale)}
      onChange={onChangeRatingScale}
      components={{ IndicatorSeparator: () => null }}
      styles={BorderedFilterStyle}
      placeholder="Make a selection"
      options={RATING_SCALES.map(({value, label }) => ({ value, label }))}
    />
  </div>

export const RatingLabels = ({ ratingLabels, onChangeLabels = () => null, hidden }) => {
  if(hidden) return false

  const [showCharLow, setShowCharLow] = useState(false);
  const [showCharHigh, setShowCharHigh] = useState(false);

  const inputElement = useRef(null);
  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  return <div className="mb-3 row">
    <div className='col-sm-6'>
      <h3>Low label</h3>
      <CharCounter show={showCharLow} field={ratingLabels.low || ''} limit={DESCRIPTION_LIMIT}  />
      <input className="form-control"
             name={'low'}
             placeholder='Enter a short description, e.g. “Worst”'
             value={ratingLabels.low || ''}
             onChange={onChangeLabels}
             maxLength={DESCRIPTION_LIMIT}
             onBlur={() => {setShowCharLow(false)}}
             onFocus={() => setShowCharLow(true)} />
    </div>
    <div className='col-sm-6'>
      <h3>High label</h3>
      <CharCounter show={showCharHigh} field={ratingLabels.high || ''} limit={DESCRIPTION_LIMIT}  />
      <input className="form-control"
             name={'high'}
             placeholder='Enter a short description, e.g. “Best”'
             value={ratingLabels.high || ''}
             onChange={onChangeLabels}
             maxLength={DESCRIPTION_LIMIT}
             onBlur={() => {setShowCharHigh(false)}}
             onFocus={() => setShowCharHigh(true)} />
    </div>
  </div>
}

const DataSourcesRow = ({ isWizard, hideDataSources, modal, isTemplate, ...opts }) => {
  const isDriverDetailsModal = modal.type === 'TreePanelDriverDetailsModal' || modal.type === 'DriverDetailsModal' || modal.type === 'TreePanelAddDriverDetailsModal'
  const hideInModal = !isTemplate && (modal.type === 'TreePanelDriverDetailsModal' || modal.type === 'DriverDetailsModal')
  if (isWizard || hideDataSources || hideInModal) return null;
  return <DataSources {...opts} {...{isTemplate, isDriverDetailsModal}} />
};

export const hideDriverResponceChoices = (isDSight, decisionObj, driverResponseType) => isDSight || driverResponseType === DriverChoiceEntryType.OPEN || driverResponseType === DriverChoiceEntryType.NUMBER || isBlank(driverResponseType)

export const onEnterKeyDown = (e) => {
  if (isEnter(e.keyCode)) {
    e.preventDefault()
  }
}

const EditDriverDetailsModal = ({
                                  title, submitState = false, isHistoricalDecision = false,
                                  question, setQuestionValue,
                                  driver, orgDriverTypes, show,
                                  isWizard = false, key = '', isDSight = false,
                                  onClose, onSubmitModal = null,
                                  notes, setNotes,
                                  decision, template,
                                  ratingScale, setRatingScale,
                                  ratingLabels, setRatingLabels,
                                  dueDate, setDueDate,
                                  driverTypeSlug, setDriverTypeSlug,
                                  updateDriver, modal, isTemplate = false,
                                  reloadContacts, resetDecisionSetData,
                                  driverResponseType, setDriverResponseType,
                                  choices, setChoices, numberFormat, setNumberFormat,
                                  ...opts
                                }) => {
  const { slug } = driver;
  const driverObj = new Driver(driver);
  const prevNotes = driver.notes || '';
  const prevChoices = isPresent(driver.driver_response_choices) ? driverObj.sortedChoices() : [];
  const debouncedUpdateDriver = debounce(() => {
    updateDriver({ slug, ...driverUpdateData });
  }, 500);
  const [showRating, setShowRating] = useState(false);
  const decisionObj = new Decision(decision)
  const templateObj = new Template(template)
  const driverUpdateData = { notes, choices }

  useEffect(() => {
    if (!hideChoiceSection) return setShowRating(false);
    if (driver && isBlank(modal.drivers.find((driverData) => driverData.driver.slug === driver.slug))) return setShowRating(false);
    if (driverObj.withEnteredChoicesRating) return setShowRating(false);

    setShowRating(template.rate_compare_choices || decision.rate_compare_choices) && isBlank(driver.parent_id) && !driverObj.withEnteredChoicesRating

  }, [modal.drivers, driver, template.rate_compare_choices, decision.rate_compare_choices]);

  useDefaultDriverTypesEffect(orgDriverTypes)
  const definedSubmitCallback = () => typeof onSubmitModal == 'function';

  if (!definedSubmitCallback()) {
    updateNotesCallback(prevNotes, slug, notes, debouncedUpdateDriver)
  }

  const availableDriverTypes = useMemo(() => orgDriverTypes.available_types, [orgDriverTypes]);

  const reloadData = () => successActions(true, [reloadContacts, resetDecisionSetData])

  const onChangeDriverType = (option) => {
    setDriverTypeSlug(option.value);
    if (definedSubmitCallback()) return;

    updateDriver({ slug, driver_type: option.value });
  };

  const onChangeResponseDriverType = (option) => {
    setDriverResponseType(option.value);
    if (definedSubmitCallback()) return;

    updateDriver({ slug, choice_entry_widget_type: option.value });
  };

  const onChangeNumberFormatting = (value) => {
    setNumberFormat(value);
    if (definedSubmitCallback()) return;

    updateDriver({ slug, number_format: value });
  };

  const onChangeQuestion = () => {
    if (definedSubmitCallback()) return;
    if(isBlank(question)) return;

    updateDriver({ slug, inputValue: question });
  };

  const onChangeRatingScale = (option) => {
    setRatingScale(option.value);
    if (definedSubmitCallback()) return;

    updateDriver({ slug, rating_scale: option.value });
  };

  const onChangeLabels = (e) => {
    setRatingLabels(Object.assign({}, ratingLabels, {[e.target.name]: e.target.value}))
    ratingLabels[e.target.name] = e.target.value
    if (definedSubmitCallback()) return;

    updateDriver({ slug, rating_labels: ratingLabels });
  }

  const onChangeDueDate = (date) => {
    setDueDate(moment(date).format('DD MMM, yyyy'));
    if (definedSubmitCallback()) return;

    updateDriver({ slug, due_date: moment(date).toString() });
  };

  const onCancelDueDate = () => {
    setDueDate(null);
    if (definedSubmitCallback()) return;

    updateDriver({ slug, due_date: null });
  };

  useEffect(() => {
    const filteredChoices = uniqueBy(driverUpdateData.choices.filter(choice => isPresent(choice.description)), 'description');
    if(filteredChoices.length === 0) {
      if (choices.length >= MIN_DISPLAYING_CHOICES ) return setChoices(choices.slice(0, MIN_DISPLAYING_CHOICES));
    } else {
      setChoices(filteredChoices)
    }
  }, [show])

  const onDone = () => {
    if(!definedSubmitCallback() && (prevNotes !== notes || isChoicesDifferent(prevChoices, choices))) {
      debouncedUpdateDriver.cancel();
      updateDriver({ slug, ...driverUpdateData }, (_status) => onClose(true));
      reloadData();
    } else {
      if(definedSubmitCallback()) {
        onSubmitModal(reloadData)
      } else {
        onClose(true);
        reloadData();
      }
    }
  }

  const onCloseHandler = () => {
    debouncedUpdateDriver.cancel();
    onClose(false)
    reloadData()
  }

  const hideLabels = () => isBlank(ratingScale) || ratingScale === YES_OR_NO_RATING_SCALE.value || ratingScale === YES_OR_NO_RATING_SCALE.label
  const hideChoiceSection = useMemo(() =>
      (isTemplate && templateObj.isRateAndCompareDriverChoices) || (!isTemplate && !decisionObj.isRateAndCompareDriverChoicesHidden),
    [isTemplate, templateObj.isRateAndCompareDriverChoices, decisionObj.isRateAndCompareDriverChoicesHidden])

  return <Modal enforceFocus={false} key={key} size="lg" backdrop="static" show={show} onHide={onCloseHandler}>
    <Modal.Body>
      <CloseIcon onClose={onCloseHandler} />
      <h2>{title || 'Edit driver'}</h2>
      <form>
        <QuestionRow onKeyDown={onEnterKeyDown} {...{ isDSight, question, setQuestionValue, onChangeQuestion }}  />
        {isTemplate ?
          <DecisionAssignToBlock {...{
            ...opts,
            definedSubmitCallback, className: 'mb-3',
            updateAction: assign_to_user => updateDriver({ slug, assign_to_user })
          }} />:
          <div className="row">
            <div className='col-sm-6'>
              <DecisionAssignToBlock {...{
                ...opts,
                definedSubmitCallback, className: 'mb-3',
                updateAction: assign_to_user => updateDriver({ slug, assign_to_user })
              }} />
            </div>
            <div className='col-sm-6'>
              <DriverDueDate {...{ dueDate, onChangeDueDate, onCancelDueDate, isTemplate }} />
            </div>
          </div>
        }
        <NotesRow {...{ notes, setNotes }} />
        {
          showRating && <Fragment>
            <RatingScale {...{ ratingScale, onChangeRatingScale }} />
            <RatingLabels {...{ ratingLabels, onChangeLabels }} hidden={hideLabels()} />
          </Fragment>
        }
        <DataSourcesRow {...{ isWizard, modal, isTemplate, ...opts }} />
        <DriverTypesRow {...{ isDSight, driverTypeSlug, availableDriverTypes, onChangeDriverType }} />
        <DriverResponseTypesRow {...{ decision, template, isDSight, driverResponseType, onChangeResponseDriverType, hideChoiceSection }} />
        <ChoicesSection {...{ choices, setChoices, hidden: (hideDriverResponceChoices(isDSight, decisionObj, driverResponseType) || hideChoiceSection) }} />
        <NumberingFormattingSection {...{ numberFormat, onChangeNumberFormatting, hidden: (driverResponseType !== DriverChoiceEntryType.NUMBER || hideChoiceSection) }} />
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={onDone} disabled={isBlank(question) || submitState} />
  </Modal>
};

const mapStateToProps = ({ org_driver_types, modal, decision, template }, { driverData = {} }) => {
  const driver = driverData.driver || {};
  return {
    driverData, driver,
    orgDriverTypes: org_driver_types,
    modal, decision, template
  }
};
export default connect(mapStateToProps, {
  updateDriver,
  createSource, updateSource, destroySource,
  saveUploadingSources, replaceSource,
  attachReport, attachScenario,
  reloadContacts, addSourceToDecision, resetDecisionSetData
})(EditDriverDetailsModal);
