import React, { useState, useRef, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import CharCounter from "../../common/CharCounter";
import { JoditTextEditor } from '../../common/JoditTextEditor';
import { ModalDoneFooterP0 } from "../../common/modals";
import { isBlank } from "../../helpers/common";
import { connect } from "react-redux";
import { createTemplateSet } from '../../store/template_set/actions'
import { useHistory } from "react-router-dom";
import { initAutofocusInput } from "./helpers";
import CreateAdmissionsInput from "../../template_view/modals/admissions/CreateAdmissionsInput";
import ViewAdmissionsInput from "../../template_view/modals/admissions/ViewAdmissionsInput";
import ShareData from "../../models/share_data";
import { loadContacts } from "../../store/contacts/actions";
import { checkLoadingEffect } from "../../helpers/callbacks_helpers";

const NAME_INPUT_LIMIT = 100

export const showTemplateSetModal = (modal) => modal.type === "TemplateSetModal" && modal.shown;
export const openTemplateSetModal = ({ openModal }) => openModal({ type: 'TemplateSetModal' })

export const NameRow = ({ name, setName, nameLimit = NAME_INPUT_LIMIT, placeholder, ...opts }) => {
  const [showChar, setShowChar] = useState(false);
  const inputElement = initAutofocusInput()

  return <div className="mb-3">
    <h3>Name</h3>
    <CharCounter show={showChar} field={name} limit={nameLimit}  />
    <input className="form-control"
           type="text"
           placeholder={placeholder}
           value={name}
           onChange={(e) => setName(e.target.value)}
           autoComplete="off" ref={inputElement}
           maxLength={nameLimit}
           onBlur={() => setShowChar(false)}
           onFocus={() => setShowChar(true)}
           {...opts}
    />
  </div>
};

export const DescriptionRow = ({ description, setDescription }) =>
  <div className="mb-3">
    <h3>Description</h3>
    <JoditTextEditor className="jodit-container-default"
                     value={description}
                     placeholder="Enter any comments or instructions"
                     setValue={setDescription}
                     autofocus={false} />
  </div>

export const onSetCreateCallback = (createSetCallback, getData, redirectPath, onClose, history, setSubmit) => () => {
  setSubmit(true)
  createSetCallback(getData(), (slug, status) => {
    if(status) {
      onClose();
      history.push(`/${redirectPath}/${slug}`)
    } else {
      onClose();
    }
  })
}

const TemplateSetModal = ({
                            share_data, contacts, shown,
                            onClose = () => {}, createTemplateSet, loadContacts
                          }) => {
  const shareObject = new ShareData(share_data);

  const [name, setName] = useState('');
  const [submit, setSubmit] = useState(false);
  const [description, setDescription] = useState('')
  const history = useHistory();
  checkLoadingEffect(contacts, loadContacts)

  const inputRef = useRef({
    resets: {},
    identifiers: () => [],
    decision_admission_identifiers: () => []
  })

  const fetchSubmitData = () => ({
    template_set: {
      name, description,
      identifiers: inputRef.current.identifiers(),
      decision_admission_identifiers: inputRef.current.decision_admission_identifiers()
    }
  })

  const onSetCreate = onSetCreateCallback(createTemplateSet, fetchSubmitData, 'template_sets', onClose, history, setSubmit)

  useEffect(() => {
    if (shown) {
      Object.values(inputRef.current.resets).forEach(callback => callback())
    }
  }, [shown])

  return <Modal enforceFocus={false} size="md" backdrop="static" show={shown} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>Create a decision flow</h2>
      <form>
        <NameRow name={name} setName={setName} placeholder={"Enter the name of the decision flow"} />
        <DescriptionRow {...{ description, setDescription }} />

        <CreateAdmissionsInput {...{ ref: inputRef, template_admissions: [
            { ...(shareObject.entireOrg || {}), admittable_slug: shareObject.entireOrg?.admittable_id }
          ],
          createObjTitle: 'a decision flow', createFromTitle: 'these templates'
        }} />
        <ViewAdmissionsInput {...{ ref: inputRef, viewObjsTitle: 'decisions', viewFromTitle: 'templates in this decision flow' }} />
      </form>
    </Modal.Body>
    <ModalDoneFooterP0 onClose={onSetCreate} disabled={isBlank(name) || submit} />
  </Modal>
}
const mapStateToProps = ({ share_data, contacts }) => ({ share_data, contacts });
const mapDispatchToProps = (dispatch) => ({
  createTemplateSet: (data, callback) => dispatch(createTemplateSet(data, callback)),
  loadContacts: () => dispatch(loadContacts())
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateSetModal);
