import React from 'react';
import AppHeader from "./header";
import AppFooter from "./footer";

export default React.memo(
  ({ children }) =>
    <div>
      <AppHeader/>
      <div className="body">
        {children}
      </div>
      <AppFooter/>
    </div>
);
