import React from 'react';
import { ButtonText } from "../tiles/DecisionTile";
import { safeDescription } from "../../../helpers/decision_helpers";
import BubbleTooltip from "../../../bubble_tooltip/BubbleTooltip";
import { Link } from "react-router-dom";
import { decisionLink } from "../tiles/helpers";

export default ({ decision, ...opts }) =>
  <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <BubbleTooltip {...{ ...opts, object_hash: decision }} />
    <div className="text-dark text-center">
      <div className="tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title mb-3">
          <span className="text-muted">Draft: </span>
          {safeDescription(decision)}
        </h4>
        <h5 className="rounded-pill bg-light w-100">
        <span className="text-dark">
          <ButtonText decision={decision} isSearchSection={false} isDSightHistory={false}/>
        </span>
        </h5>
        <div className="pt-2">
          <Link to={decisionLink(decision)}>
            <div className="btn btn-primary h-36 align-center py-1 w-100">
              <span>Continue</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
