import React, { useEffect } from 'react';
import apisauce from "apisauce";
import {
  addCanceledUploads,
  addFinishedUploads,
  resetCanceledUploads,
  resetFinishedUploads,
  resetUploadProgress,
  saveUploads,
  updateUploadProgress
} from "./uploads_helpers";
import { buildNewSource } from "../tree_view/data_sources/helpers";

export const generateDataSourceUploadState = (source, cancelTokenSource, setCancelTokenSource, setUploadProgress, setReplacingFile) => {
  const onStartUpload = (files) => {
    const cancelToken = apisauce.CancelToken.source();
    setReplacingFile(true);
    setUploadProgress(0)
    setCancelTokenSource(cancelToken)
    return [{
      uploadingSource: source,
      cancelTokenSource: cancelToken,
      file: files[0]
    }]
  };

  const onProcessUpload = ([], {}, process) => {
    setUploadProgress(process)
  }

  const onCancelUpload = () => {
    cancelTokenSource.cancel()
    setCancelTokenSource(null);
    setUploadProgress(null)
  };

  const onFinishUpload = () => {
    setReplacingFile(false);
    setUploadProgress(100)
  }

  return {
    onStartUpload,
    onProcessUpload,
    onCancelUpload,
    onFinishUpload
  }
}

export const generateBatchDataSourceUploadState = (data_sources, uploading_sources, saveUploadingSources, current_user) => {
  useEffect(() => {
    if(uploading_sources.length === 0) resetUploadsData()
  }, [])

  const onStartUpload = (files) => {
    const sources = files.map((file, index) => ({
      uploadingSource: buildNewSource(file, index, current_user),
      cancelTokenSource: apisauce.CancelToken.source(),
      file: file
    }))
    saveUploadingSources([...uploading_sources, ...sources])
    return sources;
  };

  const resetUploadsData = () => {
    resetCanceledUploads()
    resetUploadProgress()
    resetFinishedUploads()
  }

  const onProcessUpload = (allSources, uploadingSource, process) => {
    updateUploadProgress(uploadingSource, process)
    saveUploads(allSources, saveUploadingSources)
  }

  const onCancelUpload = (canceledSource) => {
    uploading_sources.find((s) => s.uploadingSource.slug === canceledSource.slug).cancelTokenSource.cancel()
    addCanceledUploads(canceledSource)
    saveUploads(uploading_sources, saveUploadingSources)
  };

  const onFinishUpload = (allSources, status, uploadedSource) => {
    if(status) {
      addFinishedUploads(uploadedSource)
      saveUploads(allSources, saveUploadingSources)
    }
  }

  return {
    onStartUpload,
    onProcessUpload,
    onCancelUpload,
    onFinishUpload
  }
}
