import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import SignInHeader from "./header";
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { Link, useLocation } from "react-router-dom";
import { Routes } from "../routes";
import { isBlank, isPresent } from "../helpers/common";
import EntryPoint from "../EntryPoint";
import queryString from 'query-string';
import UserPasswordInput from '../common/UserPasswordInput';

export const ChangePassword = ({}) => {
  const [password, setPassword] = useState('')
  const [submit, setSubmit] = useState(false)
  const [isPassword, setIsPassword] = useState(isMobile);
  const { instance } = EntryPoint
  const [resetPasswordToken, setResetPasswordToken] = useState(instance.pre_fill_data['reset_password_token'] || '')
  const [errors, _] = useState({ ...instance.flash })
  let location = useLocation();

  useEffect(() => {
    const parsedParams = queryString.parse(location.search);
    if (isPresent(parsedParams) && isPresent(parsedParams['reset_password_token'])) {
      setResetPasswordToken(parsedParams['reset_password_token'])
    } else if (isBlank(resetPasswordToken)) {
      document.location.href = '/';
    }
  }, [location])

  useEffect(() => {
    instance.flash = {}
  }, [])

  const formRef = useRef(null)

  const submitForm = useCallback((e) => {
    e.preventDefault();
    setSubmit(true)
    formRef.current.submit();
    return false;
  }, [])

  const authenticityToken = useMemo(() =>
      document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  , [])

  return <Fragment>
    <SignInHeader />
    <Container>
      <div className="bg-white rounded p-3 my-3 mx-auto sign_in">
        <div className="header text-center">
          <h2>Change password</h2>
        </div>
        <div className="row text-center">
          <div className="col mb-2"  hidden={isPresent(errors)}>
            <span className={`text-muted`}>
              Your password must be between 6 and 128 characters long, contain at least one symbol,
              and avoid repeating the same character more than twice in a row.
            </span>
          </div>
          <div className="col mb-3" hidden={isBlank(errors['warning'])}>
            <div className="text-danger w-100 text-center">
              {errors['warning']}
            </div>
          </div>
          <div className="col mb-3" hidden={isBlank(errors['error'])}>
            <div className="text-danger w-100 text-center">
              {errors['error']}
            </div>
          </div>
        </div>

        <form action={Routes.submit_reset_password} method={`post`} acceptCharset={`UTF-8`} onSubmit={submitForm} ref={formRef}>
          <input type={`hidden`} name={'_method'} value={'put'}/>
          <input type={`hidden`} name={'authenticity_token'} value={authenticityToken}/>
          <input type={`hidden`} name={'user[reset_password_token]'} id={'user_reset_password_token'} value={resetPasswordToken}/>
          <input type={`hidden`} name={'user[password_confirmation]'} value={password}/>
          <div className="row">
            <div className="col">      
              <UserPasswordInput
                id="user-password"
                name="user[password]"
                placeholder="New password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                isPassword={isPassword}
                setIsPassword={setIsPassword}
                readOnly={submit}
                className="mb-2"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="mb-3">
                <Button type="submit" className="btn btn-primary w-100"
                        onClick={submitForm} disabled={isBlank(password) || submit}>
                  Change password
                </Button>
              </div>
            </div>
          </div>
        </form>
        <div className="row" >
          <div className="col text-center">
            <Link to={Routes.sign_in} className={`pointer`}>
              Log in
            </Link>
          </div>
        </div>
      </div>
    </Container>
  </Fragment>
}
export default ChangePassword;
