import React from 'react';
import { Card } from 'react-bootstrap'
import Switcher from "../../../common/Switcher";
import SearchActionFilterText from "./SearchActionFilterText";

export default ({ dSightOnly, setDSightOnly }) =>
  <Card className="shadow">
    <Card.Header className="bg-white border-0 pointer p-0">
      <div className="d-flex p-3">
        <div className={`fw-bolder filter-text me-1`}>D-Sight only</div>
        <SearchActionFilterText displayToggle={false}>
          <Switcher label={''} value={dSightOnly} onChange={() => setDSightOnly(!dSightOnly)}/>
        </SearchActionFilterText>
      </div>
    </Card.Header>
  </Card>
