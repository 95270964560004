import React, { useEffect, useRef, useState, useMemo } from 'react';
import ReactDOMServer from "react-dom/server";
import {
  BoldJoditIcon, IndentJoditIcon,
  ItalicJoditIcon,
  LinkJoditIcon, OlJoditIcon, OutdentJoditIcon,
  StrikethroughJoditIcon, UlJoditIcon,
  UnderlineJoditIcon
} from "../icons/custom_icons";

export const editorConfig = {
  editorClassName: 'jodit-editor-container',
  defaultFontSizePoints: "pt",
  defaultActionOnPaste: "insert_clear_html",
  zIndex: 0,
  buttons: "bold,italic,underline,strikethrough,|,link,|,ol,ul,outdent,indent,|,table",
  disablePlugins: "about,add-new-line,class-span,clipboard,color,copyformat,file,font,format-block,fullsize,hr,image,image-processor,image-properties,justify,key-arrow-outside,line-height,video,symbols,spellcheck,speech-recognize,source,search,print,redo-undo,preview",
  toolbarAdaptive: false,
  toolbarSticky: false,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  tabIndex: 1,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  useSearch: false,
  autofocus: true,
  cursorAfterAutofocus: 'end',
  saveSelectionOnBlur: true,
  placeholder: "",
  selectionCellStyle: 'border: 1px double #ccc !important;',
  useExtraClassesOptions: true,
  cleanHTML: {
    fillEmptyParagraph: false
  },
  link: {
    processVideoLink: false,
    processPastedLink: true
  },
  getIcon: function (name, clearName) {
    switch (clearName) {
      case 'bold':
        return ReactDOMServer.renderToString(<BoldJoditIcon />);

      case 'italic':
        return ReactDOMServer.renderToString(<ItalicJoditIcon />);

      case 'underline':
        return ReactDOMServer.renderToString(<UnderlineJoditIcon />);

      case 'strikethrough':
        return ReactDOMServer.renderToString(<StrikethroughJoditIcon />);

      case 'link':
        return ReactDOMServer.renderToString(<LinkJoditIcon />);

      case 'ol':
        return ReactDOMServer.renderToString(<OlJoditIcon />);

      case 'ul':
        return ReactDOMServer.renderToString(<UlJoditIcon />);

      case 'outdent':
        return ReactDOMServer.renderToString(<OutdentJoditIcon />);

      case 'indent':
        return ReactDOMServer.renderToString(<IndentJoditIcon />);
    }
  }
};
