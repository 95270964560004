import React from 'react';
import Button from "react-bootstrap/Button";
import { isBlank } from "../../../helpers/common";
import SearchIcon from "../filters/SearchIcon";

export const resetSearchAction = (setSearchQuery, resetSearch) => {
  setSearchQuery('');
  resetSearch()
}

export default ({
                  searchQuery,
                  setSearchQuery,
                  resetSearchAction
                }) =>
  <div className={`text-end align-bottom search-row w-100`}>
    <div className="search-decisions position-relative">
      <SearchIcon style={{ top: '15px', right: '16px' }} />
      <Button hidden={isBlank(searchQuery)}
              onClick={resetSearchAction}
              bsPrefix="position-absolute bg-white border-0"
              style={{ top: '14px', right: '46px' }}>
        <i className="fas fa-times text-danger"/>
      </Button>
      <input className="form-control rounded border-0 search-input"
             autoComplete="off"
             placeholder={'Search scenarios'}
             type="text"
             onChange={(e) => setSearchQuery(e.target.value)}
             value={searchQuery}
      />
    </div>
  </div>
