import { useEffect } from "react";
import * as moment from "moment";
import { isEnter, isEsc } from "../../helpers/keys_helpers";
import { isBlank, isPresent } from "../../helpers/common";
import {
  iframeLinkPrepare, isEnteredDSightGoalMetricsLink,
  isEnteredDSightLink, isEnteredTemplateDSightLink, isOwnHostLink,
  transformSelfLink
} from "../../helpers/link_helpers";
import {checkDSightAnalysisAccess, loadGoalMetricsDataAccess} from "../../utils/Api";

export const buildNewSource = (file, index, current_user = null) => {
  let sourceData = {};
  sourceData.slug = `uploading-source-${moment().valueOf()}-${index}`
  sourceData.filename = file.name;
  sourceData.title = file.name;
  sourceData.byte_size = file.size;
  sourceData.content_type = file.type;
  sourceData.owner = current_user?.email;
  sourceData.progress = 0;
  return sourceData;
};

export const onEnterPress = (e, onSaveLink, onSave, onCancelTitle, onCancelLink) => {
  if (isEnter(e.keyCode)) {
    e.preventDefault();
    const value = e.target.value;
    if (value) {
      const type = e.target.dataset.valueType;
      switch(type) {
        case 'link':
          return onSaveLink();
        default:
          return onSave();
      }
    }
  }
  if(isEsc(e.keyCode)) {
    e.preventDefault();
    const value = e.target.value;
    if (value || isBlank(value)) {
      onCancelTitle()
      onCancelLink();
    }
  }
};

const renderIframe = (source, link, setCanShowIframe) => {
  const iframe = document.createElement('iframe');
  iframe.src = transformSelfLink(link);
  iframe.id = 'iframe';
  iframe.onload = function () {
    setCanShowIframe(true);
  };
  iframe.addEventListener('error', (event) => {
    setCanShowIframe(false);
    console.error('An error occurred while loading the iframe content:', event);
  });
  document.getElementById(`iframeHide-${source.slug}`)?.appendChild(iframe);
}

const cacheSourceAccessStatus = (source, status) => {
  const currentDate = moment(new Date()).format('YYYY-MM-DD-h-mm');
  localStorage.setItem(`access-to-${source.link_url}-${currentDate}`, status);
}

const getCachedSourceAccessStatus = (source) => {
  const removeDeprecatedData = (status) => {
    if(isBlank(status)) {
      const allKeys = Object.keys(localStorage);
      allKeys.forEach(key => {
        if (key.includes(`access-to-${source.link_url}`)) {
          localStorage.removeItem(key);
        }
      });
    }
  }
  const currentDate = moment(new Date()).format('YYYY-MM-DD-h-mm');
  const status = localStorage.getItem(`access-to-${source.link_url}-${currentDate}`);
  removeDeprecatedData(status)
  return status;
}


function renderDSightAnalysis(source, setCanShowIframe){
  const parsedUrl = new URL(source.link_url);
  const [, _dSight, controllerName, objectSlug] = parsedUrl.pathname.split('/');
  if(!['decisions', 'templates'].includes(controllerName) || isBlank(objectSlug)) return false;

  checkDSightAnalysisAccess({ controllerName, objectSlug }).then((response) => {
    const status = response.data['status'] === 'ok';
    setCanShowIframe(status);
    cacheSourceAccessStatus(source, status);
  });
}

function renderDSightGoalMetrics(source, setCanShowIframe){
  const parsedUrl = new URL(source.link_url);
  const [, _dSight, controllerName, id] = parsedUrl.pathname.split('/');
  if(!['goals'].includes(controllerName) || isBlank(id)) return false;

  loadGoalMetricsDataAccess({ id }).then((response) => {
    const status = response.data['status'] === 'ok';
    setCanShowIframe(status);
    cacheSourceAccessStatus(source, status);
  });
}

export const enteredDSightLinksIframeRendererEffect = (source, setCanShowIframe) => {
  useEffect(() => {
    if(isBlank(source.link_url)) return undefined;
    if (!isOwnHostLink(source.link_url)) return undefined;
    if(!isEnteredDSightLink(source)) return setCanShowIframe(false);
    const cachedStatus = getCachedSourceAccessStatus(source);
    if(isPresent(cachedStatus)) return setCanShowIframe(cachedStatus);
    if(isEnteredTemplateDSightLink(source)) return renderDSightAnalysis(source, setCanShowIframe);
    if(isEnteredDSightGoalMetricsLink(source)) return renderDSightGoalMetrics(source, setCanShowIframe)
  }, [source.link_url])
};

export const externalLinksIframeRendererEffect = (sourceObj, setCanShowIframe) => {
  useEffect(() => {
    if(isBlank(sourceObj.data_source.link_url)) return undefined;
    if(isOwnHostLink(sourceObj.data_source.link_url) || sourceObj.isLinkedReport) return undefined;
    if (sourceObj.isLinkedForecastScenario) return undefined;
    if(isPresent(iframeLinkPrepare(sourceObj.data_source)) || sourceObj.data_source.link_url.includes('public.tableau.com')) {
      return setCanShowIframe(true);
    }
    renderIframe(sourceObj.data_source, sourceObj.data_source.link_url, setCanShowIframe);
  }, [sourceObj.data_source.link_url])
};
