import { useEffect } from "react";
import { isBlank, isPresent } from "./common";
import { TREE_HISTORICAL_FLOWS } from "./decision_helpers";

export const WIZARD_STEPS = {
  report_details: {
    title: 'Report details',
    key: 'report_details'
  },
  report_properties: {
    title: 'Report properties',
    key: 'report_properties'
  },
  tree_historical: {
    title: 'What was decided',
    v3_title: 'Record a decision',
    key: 'tree_historical'
  },
  tree_decision_categories: {
    title: 'Decision categorization',
    key: 'tree_decision_categories'
  },
  tree_framing: {
    title: 'Framing the decision',
    v3_title: 'Create a decision',
    key: 'tree_framing'
  },
  tree_collaborators: {
    title: 'Decision collaborators',
    template_title: 'Template users',
    key: 'tree_collaborators'
  },
  tree_builder: {
    title: 'Decision tree',
    template_title: 'Decision tree',
    key: 'tree_builder'
  },
  decision_uploader: {
    title: 'Upload decision document',
    key: 'decision_uploader'
  }
}

export const buildInviteDataBy = (email, contacts) => {
  const slug = `invite-slug-${email}`
  const contactData = contacts.find(contact => contact.value === email);
  if (isBlank(contactData) || isBlank(contactData.full_name)) return { email, slug, user: { email, full_name: email, org_role: 'pending' } };

  const { full_name, first_name, last_name, avatar_url, org_role, active } = contactData
  const user = { email, full_name, first_name, last_name, avatar_url, org_role, active }
  return { email, user, slug }
}

export const checkSetInvitesEffect = (wizardStore, contactsDataStore, loadContacts, setInvites) => {
  useEffect(() => {
    const stepData = wizardStepDataBy(wizardStore, stepKeyWithInvites(wizardStore))
    if (!contactsDataStore.loaded && !contactsDataStore.loading) {
      loadContacts(contacts => {
        if (isPresent(stepData?.collaborators))
          setInvites(stepData?.collaborators.map(email => buildInviteDataBy(email, contacts)))
      });
    } else {
      if (isPresent(stepData?.collaborators))
        setInvites(stepData?.collaborators.map(email => buildInviteDataBy(email, contactsDataStore.contacts)))
    }
  }, [])
}

export const isCompletedWizard = (wizard) => isPresent(wizard.completed_at)
export const isHistoricalDecision = (wizard) => wizardStepKey(wizard) === WIZARD_STEPS.tree_historical.key
export const isHistoricalFlow = (wizard) => wizard?.flow_steps?.[0]?.step === 'tree_historical'
export const isTreeDecisionCategorization = (wizard) => wizardStepKey(wizard) === WIZARD_STEPS.tree_decision_categories.key
export const isTreePreview = (wizard) => wizard.tree_preview || (isWizardV3(wizard) && isPresent(wizardStep(wizard)?.preview))
export const isTreeFraming = (wizard) => wizardStepKey(wizard) === WIZARD_STEPS.tree_framing.key
export const isTreeCollaborators = (wizard) => wizardStepKey(wizard) === WIZARD_STEPS.tree_collaborators.key
export const isTreeBuilder = (wizard) => wizardStepKey(wizard) === WIZARD_STEPS.tree_builder.key
export const isDecisionUploader = (wizard) => wizardStepKey(wizard) === WIZARD_STEPS.decision_uploader.key
export const isReportDetails = (wizard) => wizardStepKey(wizard) === WIZARD_STEPS.report_details.key
export const isReportProperties = (wizard) => wizardStepKey(wizard) === WIZARD_STEPS.report_properties.key

export const isFirstWizardStep = (wizard) => isPresent(wizard.flow_steps) && wizard.step_index === 0
export const isLastWizardStep = (wizard) => isPresent(wizard.flow_steps) && wizard.step_index + 1 === wizard.flow_steps.length

export const wizardStepKey = (wizard) => wizardStep(wizard)?.step
export const wizardStep = (wizard, step_index = null) => wizard.flow_steps[step_index || wizard.step_index]
export const wizardStepDataBy = (wizard, step) => wizard?.flow_steps?.find(step_data => step_data.step === step)

export const wizardVersion = (wizard) => parseInt(wizard?.flow_steps?.[0]?.version || 1)
export const isWizardV2 = (wizard) => wizardVersion(wizard) === 2
export const isWizardV3 = (wizard) => wizardVersion(wizard) === 3
export const isLastStep = (wizard) => wizard.flow_steps.length <= wizard.step_index + 1
export const isWizardSingleStep = ({ flow_steps }) => flow_steps.length === 1
export const stepWithDrivers = ({ flow_steps = [] }) => flow_steps?.find(h => h.hasOwnProperty('drivers'))?.step
export const stepWithChoices = (decision) => TREE_HISTORICAL_FLOWS.includes(decision.flow_enum) ?
  WIZARD_STEPS.tree_historical.key : WIZARD_STEPS.tree_framing.key
export const stepKeyWithInvites = ({ flow_steps = [] }) => flow_steps?.find(h => h.hasOwnProperty('collaborators'))?.step
export const stepKeyWithCategories = ({ flow_steps = [] }) => flow_steps?.find(h => h.hasOwnProperty('categories'))?.step
