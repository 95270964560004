import React from 'react';
import { Page, View, Svg, Circle, Polygon, G, Text } from '@react-pdf/renderer';
import styles from '../../styles/common'
import DocumentFooter from "./DocumentFooter";
import DocumentHeader, {DummyHeader} from "./DocumentHeader";
import Decision, { decisionSelectedCategories } from "../../../models/decision";
import { isBlank, isPresent, uniqueBy } from "../../../helpers/common";
import { DataSourcesBlock } from "./KeyDriverPage";
import {isDecisionApprover} from "../../../helpers/decision_helpers";
import {BlockSeparate} from "./Cover";
import { userName } from "../../../helpers/user_helpers";

export const SectionBlockRow = ({ header, children, id='', showSeparator = true, blockSeparateMarginTop = true }) =>
  <View id={id} style={styles.section} >
    <Text style={styles.h2}>{header}</Text>
    {children}
    {showSeparator && <BlockSeparate viewStyles = {[blockSeparateMarginTop ? styles.mt3 : [], styles.mb3]} />}
  </View>

const CategoryBlockRow = ({category, selectedCategories}) => {
  const selectedOptions = selectedCategories.map(id => category.options.find(h => h.id === id)).filter(h => isPresent(h))
  return <View style={[styles.row, styles.text, {maxWidth: '95%'}]}>
    <Text style={[styles.muted, { width: '20%' }]}>{category.name}</Text>
    <Text style={[{ width: '80%' }]}>{
      selectedOptions.length > 0 ?
        selectedOptions.map(option => option.description).join(', ') :
        'N/A'
    }</Text>
  </View>;
}

const CategoriesBlock = ({ decision, org_categories }) => {
  if (isBlank(org_categories)) return null;

  return <>
    <SectionBlockRow header={'Categories'} >
      {org_categories.map(category =>
        <CategoryBlockRow category={category} selectedCategories={decision.category_options} key={`category-block-row-${category.slug}`} />
      )}
    </SectionBlockRow>
  </>
}

export const CollaboratorRow = ({user, slug = '', decision}) => {
  const isUserDecider = slug === 'decider'
  const enteredApprovalsEmails = uniqueBy(decision.user_approvals, 'user_email').map(a => a.user_email)
  const isApprover = isDecisionApprover(decision, user.email) && enteredApprovalsEmails.includes(user.email)

  const decisionManagerText = isUserDecider ? 'decision manager' : '';
  const deciderText = decision.assigned_decider_email === user.email ? 'decider' : '';
  const recommenderText = decision.recommendation?.assigned_recommender_email === user.email ? 'recommender' : '';
  const approverText = isApprover ? 'approver' : '';
  const userDescription = [decisionManagerText, deciderText || recommenderText, approverText].filter(text => isPresent(text)).join(', ')

  return <View style={[styles.row, {maxWidth: '95%'}]}>
    <Text style={[styles.text]}>{userName(user)}</Text>
    {
      userDescription ? <Text style={[styles.muted]}>&nbsp;({userDescription})</Text> : null
    }
  </View>
}

const CollaboratorsBlock = ({decisionObj, decision}) => {
  const collaborators = uniqueBy(decisionObj.collaborators, 'email');

  return <>
    <SectionBlockRow header={'Collaborators'} >
      {collaborators.map(u =>
        <CollaboratorRow user={u} slug={u.email === decisionObj.decision.user.email ? 'decider' : u.email} decision={decision} key={`collaborator-row-${u.email}`} />
      )}
    </SectionBlockRow>
  </>
}

const DataSourcesBlockSection = ({data_sources}) => {
  if (isBlank(data_sources)) return null;

  return <View id={'details_data_sources'}>
    <SectionBlockRow header={'Data Sources'} showSeparator={false} >
      <DataSourcesBlock data_sources={data_sources} sourceBlockKey={(data_source) => `data-source-${data_source.slug}-${data_source.key}`} />
    </SectionBlockRow>
  </View>
}
const DecisionDetailsPage = ({ org, decision, org_categories, data_sources, format }) => {
  const decisionObj = new Decision(decision)
  const selectedCategories = decisionSelectedCategories(org_categories, decision.selected_categories)
  if (isBlank(decision.collaboration_context) &&
    isBlank(org_categories) &&
    isBlank(decisionObj.collaborators) &&
    isBlank(data_sources))
    return null;

  return <Page size={format} style={styles.container}>
    <DummyHeader text={'Details'} id={'details_page'} />
    <DocumentHeader title={'Details'} src_id={'#details_page'} />
    <View style={styles.body}>
      <CategoriesBlock decision={decision} org_categories={selectedCategories} />
      <CollaboratorsBlock decisionObj={decisionObj} decision={decision} classNames={'mb-3'}/>
      <DataSourcesBlockSection data_sources={data_sources} />
    </View>
    <DocumentFooter org={org} />
  </Page>
}
export default DecisionDetailsPage;
