import React from 'react';
import { connect } from "react-redux";
import { updateTreeData } from "../../store/tree/common_actions";
import { isBlank } from "../../helpers/common";
import { saveTemplateWizardStep } from "../../utils/Api";
import DriverDetailsModalForm from "../../tree_wizard/modals/DriverDetailsModalForm";
import { modelDriverProps } from "../../tree_wizard/modals/helpers";

export const showTemplateDriverDetailsModal = (modal) => modal.type === "TemplateDriverDetailsModal" && modal.shown

export const TemplateDriverDetailsModal = ({
                                             shown = false, stepRef,
                                             wizard, template, driverData,
                                             ...opts
                                           }) => {
  if (isBlank(shown) || isBlank(driverData)) return null;

  return <DriverDetailsModalForm
    isTemplate={true} wizard={wizard} driverData={driverData}
    saveStepDataAction={saveTemplateWizardStep} submitStep={stepRef.current.submitStep}
    submitDrivers={stepRef.current.submitDrivers}
    {...opts}
  />
}
const mapStateToProps = ({tree, modal, wizard}) => ({
  ...modelDriverProps({tree, modal, wizard}),
  template: modal.decision
})
const wrapper = React.forwardRef((props, ref) => <TemplateDriverDetailsModal {...props} stepRef={ref} />)
export default  connect(mapStateToProps, { updateTreeData }, null, { forwardRef: true })(wrapper);
