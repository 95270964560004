import React, {Fragment, useMemo} from "react"
import SectionWrapper from "../tree_view/side_panel/sections/SectionWrapper";
import EntryPoint from "../EntryPoint";
import TreeHeader from "./header";

const SUCCESS_TEXT = 'Success!' ;
const OPEN_SLACK_TEXT = 'Open Slack and go to the Cloverpop app';

export default () => {
  const { instance } = EntryPoint;
  const href = useMemo(() => `https://slack.com/app_redirect?channel=${instance.slack_channel_id}`, [])

  return <Fragment>
    <TreeHeader/>
    <div className="d-flex overflow-hidden">
      <div className="container-fluid">
        <div className="px-3 px-md-0 my-3 mx-auto vertical-tree sign_in">
          <SectionWrapper>
            <div className='m-3 text-center'>
              <h1 className='mb-2'>{SUCCESS_TEXT}</h1>
              <img className="pb-3 ps-3 mb-2"
                   src="https://d3w0aqywn4hl70.cloudfront.net/party-popper.png"
                   alt="" width="205" height="204"/>
              <a className='btn btn-primary w-100' href={href}>
                {OPEN_SLACK_TEXT}
              </a>
            </div>
          </SectionWrapper>
        </div>
      </div>
    </div>
  </Fragment>
}