import React, {useState} from "react";
import ConfidenceLevel from "../../tree_view/driver_entry/ConfidenceLevel";
import AccordionWrapper from "../../common/AccordionWrapper";

const ConfidenceLevelInput = ({driver, onChangeDriver}) => {
  const [confidence, setConfidence] = useState(driver.confidence?.toString() || '');

  const onChangeConfidence = (value) => {
    onChangeDriver(value, 'confidence')
    setConfidence(value);
  }

  const accordionObject = {
    header: <h3>Rate your confidence level</h3>,
    body: <ConfidenceLevel {...{ confidence, onChangeConfidence, setConfidence, disabled: false, slug: driver.slug}}
                           showTitle={false} addClass='' />,
    defaultExpand: false
  };

  return <div className='px-3'>
    <AccordionWrapper accordionObject={accordionObject} className='mb-2' bodyClass='' />
  </div>
}

export default ConfidenceLevelInput
