import React from "react";
import ToggleSubDrivers from "./ToggleSubDrivers";
import { EditDriverIcon } from "../../modals/DriverShowModal";
import Description from "../decision/Description";
import CompleteDriverDescription from "./CompletedDriverDescription";
import {isDeciderReadOnly} from "../../../helpers/user_helpers";

export default ({ user, driverData, driverObject, childrenDrivers, setShowChildren, onExpandDriverClick = () => {}, onEditClick = () => {}, expanded = false }) =>
  <>
    <div hidden={!expanded || isDeciderReadOnly(user)} className="float-end">
      <EditDriverIcon user={user} driver={driverData.driver} onEdit={onEditClick} margin={'mt-2-negative me-2-negative'}/>
    </div>
    <ToggleSubDrivers {...{ driverData, childrenDrivers, setShowChildren } } />
    {
      driverObject.isCompleted ?
        <CompleteDriverDescription description={driverData.driver.question} onClick={onExpandDriverClick} {...{ driverData, expanded } } /> :
        <Description description={driverData.driver.question} onClick={onEditClick} user={user} />
    }
  </>
