import React from 'react';
import { connect } from "react-redux";
import {isBlank, isPresent} from "../../../../helpers/common";
import CurrencyInput from "../../../../common/CurrencyInput";
import { InputCurrencyHeader } from "../../../../tree_wizard/steps_wizard/steps/HistoricalDecisionStep"
import AccordionWrapper from "../../../../common/AccordionWrapper";

const ExpectedOpportunityInput = ({ object, decision, expectedOpportunity, setExpectedOpportunity }) => {
  if (isBlank(decision)) return null;

  const accordionObject = {
    header: <InputCurrencyHeader headerText={'Expected opportunity value'}/>,
    body: <CurrencyInput key={'dsight-decision-currency-input-opportunity'}
                         value={expectedOpportunity}
                         onChange={(value) => setExpectedOpportunity(value || '')}
                         placeholder={'Enter a dollar amount'}
                         name={'expected-opportunity'}
                         id={'expected-opportunity'}/>,
    defaultExpand: isPresent(object.expected_opportunity)
  };

  return <AccordionWrapper accordionObject={accordionObject} className='mb-3' />
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps)(ExpectedOpportunityInput);
