import React from 'react';
import { connect } from "react-redux";
import { setDataSourcesSidebarOpen } from "../../store/sidebar/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";

const DataSourcesHamburgerButton = ({ openSideBar }) =>
  <BaseHamburgerBtn onSelect={openSideBar} title="Data sources" />

const mapDispatchToProps = (dispatch) => ({
  openSideBar: () => dispatch(setDataSourcesSidebarOpen(true))
});
export default connect(null, mapDispatchToProps)(DataSourcesHamburgerButton);
