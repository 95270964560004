import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router";
import ShareData, { itemIdentifier } from "../../models/share_data";
import { isBlank, isEmail, isPresent, uniqueBy } from "../../helpers/common";
import { isEnter } from "../../helpers/keys_helpers";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { formatShareItemData } from "../../helpers/share_helpers";
import CloseIcon from "../../common/CloseIcon";
import {  Typeahead } from 'react-bootstrap-typeahead';
import { openSharingModal } from "../header/ShareDropdown";
import { JoditTextEditor } from '../../common/JoditTextEditor';
import { filterSharedItems } from "../../template_wizard/steps_wizard/steps/shared_helpers";
import {
  DEFAULT_TYPEHEAD_ATTRIBUTES,
  filteredItems,
  findItemByEmailCallback,
  renderContactItem, renderTag
} from "./helpers/share_helpers";

export const filterNewShared = (newShared) => uniqueBy(filterSharedItems(newShared), 'uniq_field')

export const onChangeSharedCallback = (selected, findItemByEmail, setShareToSelection) => {
  const newShared = [];
  selected.forEach(item => {
    if (typeof item === "string") {
      newShared.push(findItemByEmail(item))
    } else if (item.customOption && isEmail(item.label) ) {
      newShared.push(findItemByEmail(item.label))
    } else {
      newShared.push({ ...item, uniq_field: item.uniq_field || itemIdentifier(item) })
    }
  })
  setShareToSelection(filterNewShared(newShared))
}

export const onShareKeyDown = (e, ref, findItemByEmail, shareToSelection, setShareToSelection) => {
  if (isEnter(e.keyCode)) {
    const value = ref.current.getInput().value;
    if (isEmail(value)) {
      const newItem = findItemByEmail(value)
      shareToSelection.push(newItem)
      setTimeout(() => setShareToSelection(filterNewShared(shareToSelection)), 50)
      ref.current.clear()
    }
  }
}

const ShareDropdownModal = ({shown, share_data, onClose = () => {}, onSubmit = () => {}}) => {
  const [sendNotification, setSendNotification] = useState(false);
  const [sendNotificationText, setSendNotificationText] = useState('');
  const [shareToSelection, setShareToSelection] = useState([])
  const [submit, setSubmit] = useState(false)
  const [sharingFromDecisionAccess, setSharingFromDecisionAccess] = useState(false);

  const shareObject = new ShareData(share_data)
  const ref = React.createRef();
  const history = useHistory();

  useEffect(() => {
    const { show, getParams, requester } = openSharingModal();
    if(show) {
      onChangeShared([findItemByEmail(requester)])
      setSendNotification(true);
      setSharingFromDecisionAccess(true);
      setTimeout(() => {
        getParams.delete('modal_open');
        getParams.delete('requester');
        history.replace({
          search: getParams.toString(),
        });
      }, 200)
    }
  }, []);

  const findItemByEmail = (email) => findItemByEmailCallback(shareObject)(email) || { label: email, uniq_field: email, admittable_type: 'NewOrgUser', email }
  const submitShareModal = () => {
    const notify_only_ids =  shareToSelection.filter(item => isPresent(item.admittable_id)).map(item => `${item.admittable_id}:${item.admittable_type}`).join(',')
    const invites = shareToSelection.filter(item => item.admittable_type === 'NewOrgUser').map(item => item.email).join(',')
    const items = shareToSelection.filter(item => item.admittable_type !== 'NewOrgUser').map(item => formatShareItemData(item))
    setSubmit(true)
    onSubmit(items, invites, {
      decision_shared_notification: sendNotification,
      message: sendNotification ? sendNotificationText : null,
      notify_only_ids
    }, (success) => {
      setSubmit(false)
      if (success) {
        setSendNotification(false);
        setSendNotificationText('');
        setSharingFromDecisionAccess(false);
        setShareToSelection([]);
      }
    })
  }
  const onChangeShared = (selected) => onChangeSharedCallback(selected, findItemByEmail, setShareToSelection)
  const onKeyDown = (e) => onShareKeyDown(e, ref, findItemByEmail, shareToSelection, setShareToSelection)

  const addNewOrgShare = () => {
    const newShare = filterNewShared([...shareToSelection, shareObject.entireOrg])
    setShareToSelection(newShare)
    setSendNotification(false)
  }
  const close = () => {
    setShareToSelection([])
    setSendNotificationText('')
    setSendNotification(false)
    setSharingFromDecisionAccess(false);
    onClose()
  }

  const filterOptions = () => {
    if(sharingFromDecisionAccess) {
      return [...shareToSelection, shareObject.entireOrg]
    } else {
      return shareToSelection
    }
  }

  return <Modal enforceFocus={false} size="lg" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <CloseIcon onClose={close} />
      <h2>Share with people and groups</h2>
      <div className="mb-2 text-muted">
        Add names of people and groups to share this decision beyond the collaborators.
        <div hidden={sharingFromDecisionAccess || isBlank(shareObject.entireOrg)}>
          <a className="pointer" onClick={addNewOrgShare}>Click here</a> to add “{shareObject.entireOrg?.label}”.
        </div>
      </div>
      <div className="mb-3">
        <Typeahead
          {...DEFAULT_TYPEHEAD_ATTRIBUTES} allowNew
          ref={ref} selected={shareToSelection}
          onChange={onChangeShared}
          options={filteredItems(shareObject, filterOptions())}
          renderMenuItemChildren={renderContactItem}
          onKeyDown={onKeyDown}
          renderToken={renderTag}
        />
      </div>
      <div className="mb-3 lh-checkbox" hidden={sharingFromDecisionAccess}>
        <input type="checkbox"  id="share-notify-checkbox" name="share-notify-checkbox"
                className="custom-checkbox"
                checked={sendNotification}
                onChange={() => { setSendNotification(!sendNotification) }}/>

        <label htmlFor="share-notify-checkbox" className="mb-0">Send a notification</label>
      </div>
      <div className="mb-3" hidden={sharingFromDecisionAccess || !sendNotification}>
        <h3>Notification comments</h3>
        <JoditTextEditor className="jodit-container-default"
                         value={sendNotificationText}
                         placeholder="Add a personal note to the notification"
                         setValue={setSendNotificationText}
                         autofocus={false} />
      </div>
      <Button onClick={submitShareModal} className="w-100 mt-3" disabled={isBlank(shareToSelection) || submit}>Share decision</Button>
    </Modal.Body>
  </Modal>
}
const mapStateToProps = ({ share_data }) => ({ share_data });
export default connect(mapStateToProps)(ShareDropdownModal);
