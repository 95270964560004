import React from 'react';
import { isPresent } from "../../../../helpers/common";
import AccordionWrapper from "../../../../common/AccordionWrapper";
import { JoditTextEditor } from '../../../../common/JoditTextEditor';

const NextStepsInput = ({ object, nextStepsDescription, setNextStepsDescription }) => {
  const description = object.next_steps_description
  const accordionObject = {
    header: <h3 className="mb-0"> Next steps</h3>,
    body: <JoditTextEditor className="jodit-container-default"
                           value={nextStepsDescription}
                           placeholder="Enter any actions to take"
                           setValue={setNextStepsDescription}
                           autofocus={false} />,
    defaultExpand: isPresent(description)
  };

  return <AccordionWrapper accordionObject={accordionObject} className='mb-3' />
}

export default NextStepsInput;
