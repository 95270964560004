import React from 'react';
import Wrapper from '../Wrapper';
import TemplateDriverInfo from "../template/TemplateDriverInfo";
import AggregatedData from "../driver/AggregatedData";
import Header from "../template_driver/Header";
import Content from "../template/Content";
import { driverInitData } from "../shared/helpers";
import Status from "../template/Status";
import {ArrowUpIcon} from "../../../icons/custom_icons";

const DSightTemplateDriverCard = ({ driverData, template, tree, indentStep = 1, user, contactsData, openModal,
                                    shortBorderToArrow = false}) => {
  const { driverObject, childrenDrivers, setShowChildren, showChildren } = driverInitData(tree, driverData);

  const onDriverClick = () => openModal({
    decision: template, drivers: tree.drivers, data_sources: tree.data_sources,
    slug: driverData.driver.slug, type: 'DSightDriverModal'
  })

  return <>
    <Wrapper indentStep={indentStep} hasSingleChild={shortBorderToArrow}>
      <div className={`d-flex`}>
        <Status driverData={driverData}/>
        <div className="d-flex flex-column my-auto lh-sm w-100 me-1" style={{minHeight: 36}}>
          <Header {...{driverData, driverObject, childrenDrivers, setShowChildren, onEditClick: onDriverClick}} />
          <TemplateDriverInfo {...{driverData}} />
          <AggregatedData {...{driverData}} />
        </div>
        <Content {...{driverData, assignedUser: {}}} />
      </div>
      <div className={`arrow-up-icon-block ${showChildren && childrenDrivers.length > 0 ? 'd-block' : 'd-none'}`}>
        <ArrowUpIcon/>
      </div>
    </Wrapper>
    {
      showChildren && childrenDrivers.length > 0 ?
        <div className={`position-relative ml-border-${indentStep} ${shortBorderToArrow ? '' : 'border-to-arrow' }`}>
          {childrenDrivers.map((child, index) => {
            return <DSightTemplateDriverCard key={`dsight-driver-card-${child.driver.slug}`}
                                             {...{
                                               driverData: child,
                                               indentStep: indentStep + 1,
                                               template, tree,
                                               user, contactsData, openModal,
                                               shortBorderToArrow: childrenDrivers.length === 1 || index === childrenDrivers.length - 1
                                             } } />
          })}
        </div> : null
    }
  </>
}
export default DSightTemplateDriverCard;
