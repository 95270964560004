import React from "react";
import { JoditTextEditor } from '../../common/JoditTextEditor';
import {DETAILS_PLACEHOLDER} from "../../tree_view/side_panel/driver_input/DetailsInput";
import AccordionWrapper from "../../common/AccordionWrapper";

const DetailsInput = ({ explanation, setExplanation }) => {
  const accordionObject = {
    header: <h3>Add details</h3>,
    body: <JoditTextEditor className="jodit-container-default"
                           value={explanation}
                           placeholder={DETAILS_PLACEHOLDER}
                           setValue={setExplanation}
                           autofocus={false} classNames="mb-2" />,
    defaultExpand: false
  };

  return <AccordionWrapper accordionObject={accordionObject} className='mb-2 px-3' />
}

export default DetailsInput
