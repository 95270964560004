import React from 'react';
import { connect } from "react-redux";
import { setPlaybookSidebarOpen } from "../../store/sidebar/actions";
import Button from 'react-bootstrap/Button';

const PlaybookHeaderButton = ({ sidebar, openPlaybookSideBar }) =>
  <li className="nav-item ms-2">
    <Button onClick={() => openPlaybookSideBar(!sidebar.playbookSidebar)} className="btn-secondary btn">
      <i className="fas fa-book-open fa-lg m-0 align-middle" />&nbsp;
      Playbook
    </Button>
  </li>

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openPlaybookSideBar: (flag) => dispatch(setPlaybookSidebarOpen(flag))
});
export default connect(mapStateToProps, mapDispatchToProps)(PlaybookHeaderButton);
