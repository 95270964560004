import React, { forwardRef, useEffect } from 'react';
import Form from "react-bootstrap/Form";

export default forwardRef(({
                             value, setValue = () => {}, max100 = false, onPasteCallback,
                             ...opts
                           }, ref) => {
  useEffect(() => {
    ref.current.setSelectionRange(value.length, value.length)
  }, []);

  useEffect(() => {
    if (ref.current != null) {
      ref.current.style.height = "inherit";
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  }, [value])

  const onPaste = (e) => {
    e.preventDefault();
    // Get the pasted data from the clipboard
    const paste = (e.clipboardData || window.clipboardData).getData('text');
    // Remove newline characters from the pasted text
    const cleanPaste = paste.replace(/\n/g, '');
    // Insert the cleaned pasted text at the cursor position
    const textarea = ref.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const newText = value.slice(0, start) + cleanPaste + value.slice(end);

    setValue(max100 && newText.length > 100 ? newText.slice(0, 100) : newText);

    // Move the cursor to the end of the inserted text
    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = start + cleanPaste.length;
    }, 0);
  };

  return <Form.Control
    as="textarea"
    rows="1"
    value={value}
    ref={ref}
    onKeyDown={(e) => {e.key === 'Enter' && e.preventDefault()}}
    onPaste={typeof onPasteCallback === 'function' ? onPasteCallback : onPaste}
    {...opts}
  />
})
