import React from "react";
import PlusIcon from "../shared/PlusIcon";
import {isDeciderReadOnly, isVisitor} from "../../../helpers/user_helpers";
export default ({ user, description, onClick = () => {} }) =>
  <div onClick={onClick} className={`${isVisitor(user) || isDeciderReadOnly(user) ? 'cursor-default' : 'pointer'} d-inline-flex w-100 my-auto`}>
    <div className="note_paragraph fw-bolder text-ellipsis">
      {description}
      {!isVisitor(user) && !isDeciderReadOnly(user) && <PlusIcon />}
    </div>
  </div>
