import React, { Fragment } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { downloadDecisionSetsCSV } from "../../utils/Api";
import { saveAs } from 'file-saver';

const SetDownloadCSV = ({ decision_set, isButton = false }) => {
  const DownloadCSV = () => {
    if (!decision_set || !decision_set.slug) {
      console.error('Decision set or slug is missing');
      return;
    }

    downloadDecisionSetsCSV(decision_set.slug)
      .then(response => {
        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${decision_set.name}.csv`);
      })
      .catch(error => console.error('Error downloading CSV:', error));
  };

  return (
    <Fragment>
      {
        isButton ?
          <Button onClick={DownloadCSV} className="btn btn-secondary w-100 mb-2">
            Download decisions CSV
          </Button> :
          <Dropdown.Item as="button" onClick={DownloadCSV}>Download decisions CSV</Dropdown.Item>
      }
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  decision_set: state.decision_set,
});

export default connect(mapStateToProps)(SetDownloadCSV);
