import {
  LOAD_ORG_REPORT_OPTIONS_DATA,
  RESET_ORG_REPORT_OPTIONS,
  UPDATE_ORG_REPORT_OPTIONS_FAILED,
  UPDATE_ORG_REPORT_OPTIONS_DATA
} from "./types";

const initialState = {
    error: '',
    loaded: false,
    loading: false,
    available_report_properties: []
};

export function orgReportPropertiesReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case LOAD_ORG_REPORT_OPTIONS_DATA: {
            return {
                ...state,
                ...action.payload,
                error: ''
            };
        }
        case UPDATE_ORG_REPORT_OPTIONS_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }
        case UPDATE_ORG_REPORT_OPTIONS_FAILED: {
            return {
                ...state,
                loading: false,
                ...action.payload
            };
        }
        case RESET_ORG_REPORT_OPTIONS: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
}
