import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import styles from '../../styles/common'
import {isBlank, qSortArray, uniqueBy} from "../../../helpers/common";
import {approvalWithUser} from "../../../helpers/decision_helpers";
import * as moment from "moment";
import {Html} from "react-pdf-html";
import {SectionBlockRow} from "./DecisionDetailsPage";
import {modifyRichText, quillIndentStyles} from "../../helpers/rich_text";
import { userName } from "../../../helpers/user_helpers";

const ApprovalRow = ({approved, approved_comment, user, created_at}) => {
  const name = userName(user)

  return <View>
    <Text style={[styles.h3]}>{ approved ? 'Approved' : 'Not approved' }</Text>
    {approved_comment && <View style={[styles.text]}>
      <Html stylesheet={quillIndentStyles()} style={[styles.text, styles.pl1]}>{modifyRichText(approved_comment)}</Html>
    </View>}
    <Text style={[styles.muted, {marginBottom: 4}]}>
      Entered by {name} {moment(created_at).format('DD MMM, yyyy')}
    </Text>
  </View>
}

const ApprovalPageBlock = ({ decision }) => {
  const notCanceledApprovals = decision.user_approvals.filter(u_a => !u_a.canceled)
  if (isBlank(notCanceledApprovals)) return null;

  const collectApprovals = uniqueBy(notCanceledApprovals.map(approval => approvalWithUser(approval, decision)), 'user_email');
  const approvals = qSortArray(collectApprovals, true, (o) => moment(o.created_at));

  return <SectionBlockRow header={'Approval'}>
    { approvals.map((approval, index) => <ApprovalRow {...approval} key={`approved-${index}`} />) }
  </SectionBlockRow>
}
export default ApprovalPageBlock;
