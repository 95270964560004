import {
  PLAYBOOK_NOTES_LOAD_DATA,
  PLAYBOOK_NOTES_LOAD_DATA_FAILED,
  PLAYBOOK_NOTES_LOAD_DATA_STARTED,
  PLAYBOOK_NOTES_RESET_DATA,
  PLAYBOOK_NOTES_UPDATE_DATA,
  RESET_PLAYBOOK_NOTES
} from "./types";

const initialState = {
    loading: false,
    loaded: false,
    submit: false,
    error: '',
    categorization: '',
    collaborators: '',
    data_sources: '',
    decision_title: '',
    how_to: '',
    objective: '',
    other: '',
    tree_structure: ''
};

export function playbookNotesReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case PLAYBOOK_NOTES_LOAD_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case PLAYBOOK_NOTES_UPDATE_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case PLAYBOOK_NOTES_RESET_DATA: {
            return {
                ...initialState,
                ...action.payload,
                loading: false
            };
        }
        case PLAYBOOK_NOTES_LOAD_DATA_STARTED: {
            return {
                ...state,
                ...initialState,
                ...action.payload
            }
        }
        case PLAYBOOK_NOTES_LOAD_DATA_FAILED: {
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        }
        case RESET_PLAYBOOK_NOTES: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
}
