import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { ALL_GROUP_SLUG, TABS_URLS } from "../../helpers/home_helpers";
import { Link } from "react-router-dom";
import { Routes as routes } from "../../routes";

export const GroupsText = ({current_org}) => {
  return <Fragment>
      <p>
        Groups allow decision managers to quickly add multiple collaborators or share the decision based on any criteria
        such as role, location, or department.
      </p>
      <p>
        Anyone can create a new group and manage groups they belong to.
        Admins can see and manage all groups for {current_org.name}.
      </p>
      <p>
        When viewing a group, click the menu button to add members, edit the name, or delete the group.
      </p>
      <p>
        To remove someone from a group, go to the group and click the <b>X</b> button for the user.
      </p>
      <p>
        To see all Cloverpop users in your organization, view the <Link to={`${routes.homepage}/${TABS_URLS.groups}/${ALL_GROUP_SLUG}`}>All {current_org.name} group</Link>.
      </p>
  </Fragment>;
}
const mapStateToProps = ({ current_org }) => ({
  current_org
});
export default connect(mapStateToProps)(GroupsText);
