import { findNodeData } from "../../../../../models/d_sight/nodes";
import { qSortArray } from "../../../../../helpers/common";

const zeroPad = (value) =>  `0000${value}`.slice(-4)
const nodeSortOrder = (node, goalData) => {
  const nodeData = findNodeData(goalData, node)
  return [zeroPad(nodeData?.order || 999), nodeData.display_name]
}

export const sortNodes = (nodes, goalData) =>
  qSortArray(nodes, true, (node) => nodeSortOrder(node, goalData))
