import React, { useRef } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { closeModal, openModal, resetModal } from "../../../../store/modals/actions";
import TemplateDecisionDetailsModal from "../../../../template_wizard/modals/TemplateDecisionDetailsModal";
import DecisionDetailsModal from "../../../../tree_wizard/modals/DecisionDetailsModal";
import { isPresent, successActions } from "../../../../helpers/common";
import { attachEventEffect } from "../../../../helpers/html_events_helpers";

export const isPresentTitleNotes = (decision) => !!isPresent(decision.playbook_notes.decision_title)

const DetailsModal = ({ decision, isTemplate, onClose, shown }) => {
  if(isTemplate) {
    return <TemplateDecisionDetailsModal shown={shown}
                                         onClose={onClose} showTitleNotes={true} />
  } else {
    return <DecisionDetailsModal shown={shown}
                                 onClose={onClose} showTitleNotes={isPresentTitleNotes(decision)} />
  }
}

const showDetails = (modal, decision) => modal.type === "WizardDecisionDetailsModal" && modal.shown && modal.slug === decision.slug;

const EditDecision = ({ modal, decision, isTemplate, treeNode, openModal, closeModal, resetModal }) => {
  const ref = useRef();
  const onEditDecision = (e) => {
    e.preventDefault();
    openModal({ decision, drivers: [], data_sources: [], slug: treeNode.slug, type: 'WizardDecisionDetailsModal' })
  }
  attachEventEffect(ref, onEditDecision)

  const onClose = () => successActions(true, [closeModal, resetModal])

  return <>
    <Button className="btn btn-secondary btn-sm btn-sm-round" ref={ref}>
      <i className="fas fa-pencil-alt fa-lg w-100" />
    </Button>
    <DetailsModal decision={decision} isTemplate={isTemplate} shown={showDetails(modal, decision)} onClose={onClose} />
  </>
}
const mapStateToProps = ({ modal, decision, template },  { isTemplate }) => ({
  modal, decision: isTemplate ? template : decision
});
export default connect(mapStateToProps, { openModal, closeModal, resetModal })(EditDecision);
