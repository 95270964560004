import React, { Fragment } from 'react';

export default () =>
  <Fragment>
    <p>Create a new decision from scratch or a template.</p>
    <p>
      Templates are an easy way to create decisions. Templates are pre-populated and structured so they are consistent
      and don’t require extensive setup each time you make the decision. Templates can be added, modified, or removed
      by admins.
    </p>
    <p>Select <b>Preview decision</b> to view the pre-populated decision information.</p>
  </Fragment>
