import { UPDATE_ORG_DATA, UPDATE_ORG_FAILED } from "./types";

const initialState = {
    error: '',
    name: '',
    slug: '',
    source: '',
    alt_text: '',
    app_logo: '',
    logo_horizontal: '',
    logo_horizontal_direct_link: '',
    logo_square: '',
    dsight_gray_svg: '',
    dsight_primary_svg: '',
    dashboard_access: false,
    loaded: false,
    dsight: false,
    forecast_simulator: false,
    reports: [],
    forecast_simulator_scenarios: [],
    forecast_simulator_loading: false,
    allow_report_cost: false,
    show_report_insights: true,
    show_forecast_scenarios: true,
    allow_decision_sets: false,
    allow_gpt_suggestions: true,
    default_uploaded_decisions_flow: ''
};

export function currentOrgReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case UPDATE_ORG_DATA: {
            return {
                ...state,
                ...action.payload,
                error: ''
            };
        }
        case UPDATE_ORG_FAILED: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
}
