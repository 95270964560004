import React from 'react';
import { connect } from "react-redux";
import { setCollaboratorsSidebarOpen } from "../../store/sidebar/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";

const CollaboratorsHamburgerButton = ({ openSideBar }) =>
  <BaseHamburgerBtn onSelect={openSideBar} title="Collaborators" />

const mapDispatchToProps = (dispatch) => ({
  openSideBar: () => dispatch(setCollaboratorsSidebarOpen(true))
});
export default connect(null, mapDispatchToProps)(CollaboratorsHamburgerButton);
