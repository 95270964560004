import { ForecastScenarioRow, groupRowsByCmus, TableRow } from "./ForecastScenarioRow";
import { isBlank, isPresent, qSortArray } from "../../helpers/common";
import * as moment from "moment";
import { aggregatedByCmu, aggregatedByTime, combinedAggregation, impactsAggregation } from "./aggregastion";
import { SIMULATED_FACT_HEADER, TIME_SCALES, YEAR_HEADER_KEY, ROW_DRIVER_ID_KEY, FACTS_HEADER, SUB_FACT_HEADER } from "../../forecast_simulator_scenario/helpers/ag_grid_vars";
export const DISPLAY_NAME_LIMIT = 50;

export const isFuturePeriod = (timeScale, period) => {
  try {
    if(timeScale === YEAR_HEADER_KEY) return Number(period) >= new Date().getFullYear();

    return Number(period.split('_')[1]) >= new Date().getFullYear();
  } catch (e) {
    console.error("Error while parsing header", period, e);
    return false
  }
}

export class ForecastScenario {
  constructor(scenario, config, timeScale = null) {
    this.id = scenario.data.id;
    this.local_id = scenario.id;
    this.attributes = scenario.data.attributes;
    this.scenario_rows = isPresent(scenario.rows) ? scenario.rows : scenario.included;
    this.config = config;
    this.timeScale = timeScale ||
      (isPresent(scenario.view_options?.timeScale) ? scenario.view_options.timeScale : TIME_SCALES[0]);
    this.updateData = scenario.update_data;
    this.agGridRowsData = scenario.ag_grid_rows_data;
    this.agGridRowsDataSyncedAt = scenario.ag_grid_rows_data_synced_at;
    this.viewOptions = scenario.view_options;
    this.displayName = scenario.display_name;
  }

  get preparedRows() {
    return (this.agGridRowsData || {})[this.timeScale] || []
  }

  get isAnnualTimeScale() {
    return this.timeScale === 'y';
  }

  setTimeScale(timeScale) {
    if (this.timeScale !== timeScale) {
      this._viewHeaders = null;
      this._rows = null;
      this._scenariosRows = null;
      this.timeScale = timeScale;
    }
  }

  get actualEditedCells() {
    return this.updateData?.edited_cells?.filter(cell => {
      if(isBlank(cell.default_value)) return false;

      const numericCellValue = typeof cell.value === 'number' ? cell.value : parseFloat(cell.value);
      const roundedCellValue = parseFloat(numericCellValue.toFixed(5));
      const numericDefaultValue = typeof cell.default_value === 'number' ? cell.default_value : parseFloat(cell.default_value);
      const roundedDefaultValue = parseFloat(numericDefaultValue.toFixed(5));
      return roundedCellValue !== roundedDefaultValue
    })
  }

  get actualRunModelCells() {
    return this.updateData?.run_model_cells || []
  }

  get addedComparisonRows() {
    return this.openedGroups?.flatMap((group) => group.added_rows);
  }

  get openedGroupsIds() {
    return this.openedGroups?.map(group => group.id);
  }

  get openedGroups() {
    return this.updateData?.opened_groups?.filter(group => group.time_scale === this.timeScale) || [];
  }

  get simulationScopesData() {
    const filterScopes = this.viewOptions.scopes || {};
    const cmusGroups = Object.keys(filterScopes).map(columnId =>
      filterScopes[columnId].cmus.map(cmuId => parseInt(cmuId))
    ).filter(isPresent)
    return {
      filterScopes, cmusGroups
    }
  }

  // NOTE: There is a back-end realization of this function
  // app/presenters/forecast_simulator/ag_grid/rows_presenter.rb
  // change the code synchronously in both places
  preparedRowsForTable(cmusGroups = null) {
    const filteredRows = cmusGroups ? this.findRowsByCmusGroups(cmusGroups) : this.rows;
    return Object.values(groupRowsByCmus(filteredRows)).map(rows => {
      const aggregatedRowData = {}
      this.config?.cmuHeaders.forEach(column => aggregatedRowData[column.displayName] = rows[0].fetchCmu(column))
      const driver = rows[0].selectedDriver
      aggregatedRowData[FACTS_HEADER] = this.driverWithMeasure(driver)
      aggregatedRowData[SUB_FACT_HEADER] = SIMULATED_FACT_HEADER;
      aggregatedRowData[ROW_DRIVER_ID_KEY] = rows[0].cmusDriverId;
      if (isPresent(driver.driverRules)) {
        this.periodHeaders.forEach(period => {
          const rowsInPeriod = rows.filter(row => row.isInTimePeriod(period))
          const { value, format } = aggregatedByTime(rowsInPeriod, driver, period, this.config)
          if (format) {
            aggregatedRowData[period.name] = value
          }
        })
      }
      return new TableRow(aggregatedRowData)
    }).map(aggregatedRow =>
      this.viewHeaders.reduce((acc, header) => {
        acc[header] = aggregatedRow.fetchData(header);
        return acc;
      }, {})
    )
  }

  get groupFields() {
    return [
      ...this.config?.cmuHeaders?.map(column => column.displayName)
    ]
  }

  get allFuturePeriodsByTimeScale() {
    return this.config?.getTimeScaleBy(this.timeScale)?.timePeriods
      .filter(period => isFuturePeriod(this.timeScale, period.name));
  }

  get editableTimeScale() {
    return TIME_SCALES.find(timeScale =>
      this.config.driversColumns.some(column => column.isViewInTable && column.editableFor(timeScale))
    )
  }

  get isEditableTimeScale() {
    return this.editableTimeScale === this.timeScale;
  }

  get aggregateEditableDriverPeriods() {
    const timeScale = this.editableTimeScale;
    return this.config?.getTimeScaleBy(timeScale)?.timePeriods?.filter(period =>
      isFuturePeriod(timeScale, period.name)
    )
  }

  allTimeScalePeriods(timeScale = this.timeScale) {
    return this.config?.getTimeScaleBy(timeScale)?.timePeriods;
  }

  periods({ from = null, to = null } = this.viewOptions, timeScale = this.timeScale) {
    const fromDate = isPresent(from) ? moment(from) : false
    const toDate = isPresent(to) ? moment(to) : false

    return this.allTimeScalePeriods(timeScale).filter(period => {
      if (fromDate && fromDate > period.startDate) return false;
      if (toDate && toDate < period.endDate) return false;

      return true;
    }) || [];
  }

  fetchScopedRows({
                    cmu = null, period = null, cmus = [], cmusGroups = [], rows = false
                  }) {
    return (rows || this.scenariosRows).filter(row =>
      (isBlank(period) || row.isInTimePeriod(period)) &&
      (isBlank(cmu) || row.cmus.includes(cmu)) &&
      (isBlank(cmus) || cmus.filter(isPresent).every(id => row.cmus.includes(id))) &&
      (isBlank(cmusGroups) || cmusGroups.every(cmusGroup => cmusGroup.some(id => row.cmus.includes(id))))
    );
  }

  aggregateBy({
                cmu = null, cmus = [], cmusGroups = [], rows = false,
                period, driver
              }) {
    const allCmuRows = this.fetchScopedRows({
      cmu, period, cmus, cmusGroups, rows
    });
    if (isBlank(allCmuRows)) return null;
    if (isPresent(driver?.driverRules)) {
      const aggregateFunction = period.timeScale.isInitData ? aggregatedByCmu : combinedAggregation
      const {format, value} = aggregateFunction(allCmuRows, driver, period, this.config)
      if (format) return value;
    }
    return 0.0;
  }

  aggregateImpactsBy({
                       cmu = null, cmus = [], cmusGroups = [], rows = false,
                       period, driver, metric, chartValuesType
                     }) {
    const allCmuRows = this.fetchScopedRows({
      cmu, period, cmus, cmusGroups, rows
    });
    if (isPresent(driver?.decompRules[metric.id])) {
      const {format, value} = impactsAggregation(allCmuRows, driver, period, this.config, metric, chartValuesType)
      if (format) {
        return value;
      }
    }
    return 0.0;
  }

  get periodHeaders() {
    try {
      return this.periods(this.viewOptions);
    } catch (e) {
      console.error("Error while parsing period headers", e);
      return []
    }
  }

  get periodHeadersNames() {
    return this.periodHeaders.map(period => period.name)
  }

  get viewHeaders() {
    if (isBlank(this._viewHeaders)) {
      this._viewHeaders = [
        ROW_DRIVER_ID_KEY,
        ...this.config?.cmuHeaders.map(column => column.displayName),
        FACTS_HEADER,
        SUB_FACT_HEADER,
        ...this.periodHeadersNames
      ]
    }
    return this._viewHeaders;
  }

  get rows() {
    if (isBlank(this._rows)) {
      this._rows = qSortArray(
        this.scenario_rows.flatMap(hash =>
          this.config.viewDriversBy(this.timeScale).map(column => new ForecastScenarioRow(hash, this, column))
        ), true, row => row.cmusSortKey
      )
    }
    return this._rows;
  }

  get scenariosRows() {
    if (isBlank(this._scenariosRows)) {
      this._scenariosRows = qSortArray(
        this.scenario_rows.map(hash => new ForecastScenarioRow(hash, this)),
        true, row => row.cmusSortKey
      )
    }
    return this._scenariosRows;
  }

  driverWithMeasure(driver) {
    return [driver.displayName, driver.measure].filter(isPresent).join(', ')
  }

  get valueSalesDriverName() {
    if (isBlank(this._valueSalesDriver)) {
      const driver = this.config.allFactsColumns.find(column => column.displayName === 'Value Sales');
      this._valueSalesDriver = this.driverWithMeasure(driver);
    }
    return this._valueSalesDriver;
  }

  get outputDriversNames() {
    return this.config.outputColumns.map(column => column.displayName);
  }

  updateScenarioRows(rows) {
    this.scenario_rows = rows;
    this._rows = null;
    this._scenariosRows = null;
  }

  findRowsByCmusGroups(cmusGroups) {
    return this.rows.filter(row => cmusGroups.some(cmusGroup => cmusGroup.every(id => row.cmus.includes(id))))
  }

  findRowBy(cmus, periodId, driverId) {
    return this.rows.find(row => {
      return row.selectedDriver.id === Number(driverId) &&
        row.cmus.every(cmu => cmus.includes(cmu)) && row.timePeriod.id === Number(periodId)
    })
  }
}
