import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { resetSidebars } from "../../store/sidebar/actions";
import HamburgerDropdown from './HamburgerDropdown'
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { successActions } from "../../helpers/common";
import { TABS_URLS } from "../../helpers/home_helpers";
import { openModal } from "../../store/modals/actions";
import { openDecisionSetModal } from "../../homepage/modals/DecisionSetModal";
import { resetTemplateSetData } from "../../store/template_set/common_actions";

const SetHeader = ({
                     template_set, current_org,
                     resetSet, resetSidebars,
                     ...opts
                  }) => {
  const history = useHistory();
  const processBackClick = useCallback(() => {
    successActions(true, [resetSet, resetSidebars]);
    redirectToHomeCallback(true, history, TABS_URLS.create_decision);
  }, [])

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="btn btn-secondary btn-lg-round d-none d-lg-flex justify-content-around me-2" onClick={processBackClick}>
          <i className="fas fa-lg fa-arrow-left"/>
        </div>
        <h5>Decision flow preview</h5>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerDropdown />
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          <li className="nav-item ms-2">
            <Button onClick={() => openDecisionSetModal({ ...opts, template_set })} className="btn-primary btn">
              <i className="fas fa-plus fa-lg m-0 align-middle" />&nbsp;
              Create decision flow
            </Button>
          </li>
        </ul>
      </div>
    </nav>
  </header>;
}

const mapStateToProps = ({ current_org, template_set }) => ({ current_org, template_set });

const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  resetSidebars: () => dispatch(resetSidebars()),
  resetSet: () => dispatch(resetTemplateSetData())
});
export default connect(mapStateToProps, mapDispatchToProps)(SetHeader);
