import React, {useEffect, useState} from 'react';
import DetailsItem from "../../tree_view/navigation/DetailsItem";
import PlaybookTemplateItem from "./PlaybookTemplateItem";
import DecisionSetItem from "../../template_set_view/navigation/DecisionSetItem";
import RatingsAndWeightsItem from "../../tree_view/navigation/RatingsAndWeightsItem";
import CollaboratorsItem from "../../tree_view/navigation/CollaboratorsItem";
import {Template} from "../../models/template";
import DataSourcesItem from "../../tree_view/navigation/DataSourcesItem";
import {decisionDataSources} from "../../models/decision";
import {TemplateOrDSightDataSourcesTypes} from "../../models/data_source";
import DecisionTreeItem from "../../tree_view/navigation/DecisionTreeItem";

const NavigationItems = ({ tree, template, isDsight = false, isParentTemplateSet = false, collaboratorsInvites = [], sortedRelatedDataSources = []}) => {
  return <div className="sidebar-top">
    <ul className="list-unstyled m-0 p-3">
      <RatingsAndWeightsItem isTemplate={true}/>
      <DetailsItem/>
      {collaboratorsInvites.length === 0 ? null : <CollaboratorsItem collaboratorsInvitesLength={collaboratorsInvites.length}/>}
      {sortedRelatedDataSources.length === 0 ? null : <DataSourcesItem/>}
      {!isDsight && <DecisionTreeItem/>}
      <PlaybookTemplateItem/>
      {isParentTemplateSet ? <DecisionSetItem/> : null}
    </ul>
  </div>
}

export const SidebarTreeNavigationPreview = (opts) =>
  <div id="sidebar-navigation" className="tree-sidebar bg-light-gray d-none d-lg-block">
    <NavigationItems {...opts} />
  </div>

export default SidebarTreeNavigationPreview;
