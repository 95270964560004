import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
  addDataToDecisionReasons,
  addDataToDriverExplanation, addDataToRecommendationReasons,
  setChatGptDecisionSummarizeSidebarOpen,
  setChatGptDriverSummarizeSidebarOpen,
  setChatGptRecommendationSummarizeSidebarOpen,
  setDecisionInputSidebarOpen,
  setDriverInputSidebarOpen,
  setRecommendationInputSidebarOpen
} from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import {isMobile} from "react-device-detect";
import PanelHeader from "./PanelHeader";
import {findDriverData} from "../accordion_tree/shared/helpers";
import ChatGptDriverSummarizeSection, {gptApiSummarizeRequest} from "./sections/ChatGptDriverSummarizeSection";
import Button from "react-bootstrap/Button";
import {openModal} from "../../store/modals/actions";
import {isBlank, isPresent} from "../../helpers/common";
import Decision from "../../models/decision";
import {isDSightDecision} from "../../helpers/home_decision_helpers";
import {CopyIcon} from "../../common/CopyIcon";
import {copyFormattedText} from "../../../js/utls/copyToClipboard";
import {DURATION} from "../../alerts";
import {showAlert} from "../../store/alerts/actions";
import {saveSideBarData} from "../../store/sidebar/common_actions";
import {answeredChildrenDrivers, answeredChildrenRatingDrivers} from "../../helpers/gpt_helpers";
import Form from "react-bootstrap/Form";
import {chatGptDriverSummarizeRequest} from "../../store/decision/actions";

export const copyAssistantSuggestion = async (showAlert, childrenDrivers, disabled, expandedList, explanation) => {
  if (disabled) return;
  let detailsData = expandedList ? explanation + summarizedDrivers(childrenDrivers) : explanation;
  try {
    await copyFormattedText(detailsData);
    showAlert({
      text: 'Summary copied to clipboard',
      type: 'success',
      addClass: 'text-center',
      dismissible: false,
      duration: DURATION
    });
  } catch (error) {
    console.error('Failed to copy:', error);
  }
};

export const ChatGptRefine = ({setLoaded, gptApiSummarizeCallback, refineMessage, setRefineMessage, disabled}) => {
  const submissionCallback = (e) => {
    e.preventDefault()

    setLoaded(false)
    gptApiSummarizeCallback(refineMessage)
  };

  return <div className='d-flex mb-2'>
    <form className='me-2 w-100'>
      <Form.Control
        as="input"
        autoFocus
        disabled={disabled}
        value={refineMessage}
        onKeyDown={(e) => e.key === 'Enter' && !isBlank(refineMessage) && submissionCallback(e)}
        onChange={(e) => setRefineMessage(e.target.value)}
        placeholder="Enter instructions to refine the results"
      />
    </form>
    <Button disabled={isBlank(refineMessage) || disabled} className="btn btn-primary px-3" onClick={submissionCallback}>Done</Button>
  </div>
}

export const ChatGptSummarizePanelHeader = ({
                                              openSideBar, showAlert, explanation, disabled, expandedList = false,
                                              childrenDrivers, setError, headerName = "CloverpopAI driver summary"
                                            }) => {
  const closeSideBar = () => {
    setError('');
    openSideBar(false);
  }

  return <PanelHeader headerName={headerName} openSideBar={closeSideBar} preview={true}
                      children={<CopyIcon disabled={disabled} onClick={() => copyAssistantSuggestion(showAlert, childrenDrivers, disabled, expandedList, explanation)} />} />
}

export const AddToDetailsButton = ({explanation, disabled, addToDetails, type, disabledAddButton}) =>
  <Button className="w-100 btn-secondary" disabled={disabled || isBlank(explanation) || disabledAddButton} onClick={addToDetails}>
    {
      type === 'driver' ? 'Add to driver details' : `Add to ${type} rationale`
    }
  </Button>

const getSidebarOpenStatus = (type, sidebar) => {
  switch (type) {
    case 'recommendation':
      return sidebar.chatGptRecommendationSummarizerSidebar;
    case 'decision':
      return sidebar.chatGptDecisionSummarizerSidebar;
    case 'driver':
      return sidebar.chatGptDriverSummarizerSidebar;
  }
};

export const getInitContext = (type, decision, driverData) => {
  switch (type) {
    case 'recommendation':
      return decision.recommendation?.chat_gpt_queries?.find(query => isBlank(query['analyzed_files_slugs']))?.context || [];
    case 'decision':
      return decision.chat_gpt_queries?.find(query => isBlank(query['analyzed_files_slugs']))?.context || [];
    case 'driver':
      return driverData.driver?.chat_gpt_queries?.find(query => isBlank(query['analyzed_files_slugs']))?.context || [];
    default:
      return [];
  }
};

const summarizedDrivers = (childrenDrivers) => {
  if(isBlank(childrenDrivers)) return '';

  return '<p><br/></p><strong>Summarized drivers:</strong><ul>'
    + childrenDrivers.map(child => `<li>${child.driver.question}</li>`).join('')
    + '</ul><p><br/></p>';
};

export const handleAddToDetails = ({setDisabledAddButton, detailsData, type, openRecommendationInputSidebar,
                                     openDecisionInputSidebar, openDriverInputSidebar, driverData, addDataToRecommendationReasons,
                                     addDataToDecisionReasons, addDataToDriverExplanation}) => {
  setDisabledAddButton(true);
  setTimeout(() => {
    const callback = (success) => success && setDisabledAddButton(false);
    if (type === 'recommendation') {
      openRecommendationInputSidebar();
      addDataToRecommendationReasons({ details: detailsData }, callback);
    } else if (type === 'decision') {
      openDecisionInputSidebar();
      addDataToDecisionReasons({ details: detailsData }, callback);
    } else if (type === 'driver') {
      const { slug } = driverData.driver;
      openDriverInputSidebar(slug);
      addDataToDriverExplanation({ slug, explanation: detailsData }, callback);
    }
  }, 200);
};

export const aiObjectSlug = (type, driverData, decision) => {
  return type === 'driver' ?
    driverData?.driver?.slug :
    type === 'recommendation' ?
      decision.recommendation.slug : decision.slug
}

export const shouldCloseSummarizeSidebarCallback = (recommendationInputSidebar, decisionInputSidebar, driverInputSidebar,
                                    type, driverSlug, gptDriverSlug, sidebar, openSideBar, setError, isSidebarOpen) => {
  useEffect(() => {
    const shouldCloseSidebar = (
      (recommendationInputSidebar && type !== 'recommendation') ||
      (decisionInputSidebar && type !== 'decision') ||
      (driverInputSidebar && type !== 'driver') ||
      (type === 'driver' && driverSlug !== gptDriverSlug)
    );

    if (isSidebarOpen && shouldCloseSidebar) {
      openSideBar(false);
      setError('');
    }
  }, [recommendationInputSidebar, decisionInputSidebar, driverInputSidebar]);
}

const CombinedSummarizePanel = ({ type, sidebar, openSideBar, driverData, decision, showAlert,
                                  saveSideBarData, addDataToRecommendationReasons, addDataToDriverExplanation, addDataToDecisionReasons,
                                  openRecommendationInputSidebar, openDecisionInputSidebar, openDriverInputSidebar, chatGptDriverSummarizeRequest}) => {
  if ((isBlank(driverData) && type === 'driver' ) || isBlank(decision)) return null;
  if (isDSightDecision(decision)) return null;

  const decisionObject = new Decision(decision, sidebar.drivers);
  const isRated = !decisionObject.isRateAndCompareDriverChoicesHidden
  const isRoot = isBlank(sidebar.driverSlug)
  const isSidebarOpen = getSidebarOpenStatus(type, sidebar);

  if ((!isRated && answeredChildrenDrivers(driverData, isRoot).length < 2) || (isRated && answeredChildrenRatingDrivers(driverData, isRoot).length < 2)) {
    isSidebarOpen && openSideBar(false);
    return null;
  }

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState('');
  const disabled = isPresent(error) || !loaded
  const [context, setContext] = useState(getInitContext(type, decision, driverData) || [])
  const [index, setIndex] = useState(0)
  const assistantContent = context.filter(c => c.role === 'assistant')
  const explanation = assistantContent[index]?.content
  const [expandedList, setExpandedList] = useState(false)
  const childrenDrivers = isRated ?
    answeredChildrenRatingDrivers(driverData, isRoot) :
    answeredChildrenDrivers(driverData, isRoot)
  const {recommendationInputSidebar, decisionInputSidebar, driverInputSidebar, driverSlug, gptDriverSlug} = sidebar
  const [disabledAddButton, setDisabledAddButton] = useState(false);
  const [refineMessage, setRefineMessage] = useState('')
  const objectSlug = aiObjectSlug(type, driverData, decision)

  const gptApiSummarizeCallback = (refineMessage) => gptApiSummarizeRequest(
    chatGptDriverSummarizeRequest, setLoaded, setError, context, refineMessage, setContext, type, objectSlug, setRefineMessage
  )
  const detailsData = expandedList ? explanation + summarizedDrivers(childrenDrivers) : `<p>${explanation}</p>`;
  const addToDetails = () => handleAddToDetails({setDisabledAddButton, detailsData, type, openRecommendationInputSidebar,
    openDecisionInputSidebar, openDriverInputSidebar, driverData, addDataToRecommendationReasons,
    addDataToDecisionReasons, addDataToDriverExplanation})

  useEffect(() => {
    if (driverInputSidebar || recommendationInputSidebar || decisionInputSidebar) {
      setContext(getInitContext(type, decision, driverData) || []);
    }
  }, [driverInputSidebar, recommendationInputSidebar, decisionInputSidebar,
    getInitContext(type, decision, driverData).length, decision.slug]);

  shouldCloseSummarizeSidebarCallback(
    recommendationInputSidebar, decisionInputSidebar, driverInputSidebar, type, driverSlug, gptDriverSlug,
    sidebar, openSideBar, setError, isSidebarOpen
  )

  return (
    <SlidingPanel
      type="left"
      isOpen={isSidebarOpen}
      panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper decision-panel details-panel-width left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
      panelClassName={`${isMobile ? '' : 'd-flex flex-column'} sidebar-panel-wrapper-content`}
      onClosed={() => {
        !driverInputSidebar && saveSideBarData({ driverSlug: null, isOpened: false });
      }}
      size={52}
    >
      <ChatGptSummarizePanelHeader {...{ openSideBar, showAlert, explanation, disabled, expandedList, childrenDrivers, setError }} />
      <ChatGptDriverSummarizeSection {...{driverData, loaded, setLoaded, error, context, setContext, assistantContent,
                                          index, setIndex, type, decision, expandedList, setExpandedList, childrenDrivers, explanation, gptApiSummarizeCallback}} />
      <div className={`${isMobile ? 'mb-5' : 'side-panel bottom h-auto'} p-3`}>
        <ChatGptRefine {...{ setLoaded, gptApiSummarizeCallback, refineMessage, setRefineMessage, disabled }} />
        <AddToDetailsButton {...{ explanation, disabled, addToDetails, type, disabledAddButton }} />
      </div>
    </SlidingPanel>
  );
};

const mapStateToProps = ({ sidebar, decision, tree }) => {
  return {
    sidebar, decision,
    driverData: sidebar.driverSlug ? findDriverData(sidebar, sidebar.driverSlug || '') : tree.drivers
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const setSideBarOpenAction = ownProps.type === 'recommendation'
    ? setChatGptRecommendationSummarizeSidebarOpen
    : ownProps.type === 'decision'
      ? setChatGptDecisionSummarizeSidebarOpen
      : setChatGptDriverSummarizeSidebarOpen;

  return {
    openModal: (data) => dispatch(openModal(data)),
    openSideBar: (data) => dispatch(setSideBarOpenAction(data)),
    saveSideBarData: (data = {}) => dispatch(saveSideBarData(data)),
    showAlert: (data) => dispatch(showAlert(data)),
    addDataToRecommendationReasons: (data, callback) => dispatch(addDataToRecommendationReasons(data, callback)),
    addDataToDecisionReasons: (data, callback) => dispatch(addDataToDecisionReasons(data, callback)),
    addDataToDriverExplanation: (data, callback) => dispatch(addDataToDriverExplanation(data, callback)),
    openRecommendationInputSidebar: () => dispatch(setRecommendationInputSidebarOpen(true)),
    openDecisionInputSidebar: () => dispatch(setDecisionInputSidebarOpen(true)),
    openDriverInputSidebar: (slug) => dispatch(setDriverInputSidebarOpen(true, slug)),
    chatGptDriverSummarizeRequest: (data, callback, setContext) => dispatch(chatGptDriverSummarizeRequest(data, callback, setContext))
  };
};

const RecommendationGptDriverSummarizePanelWrapper = (props) => <CombinedSummarizePanel {...props} type="recommendation" />;
const DecisionChatGptSummarizePanelWrapper = (props) => <CombinedSummarizePanel {...props} type="decision" />;
const ChatGptDriverSummarizePanelWrapper = (props) => <CombinedSummarizePanel {...props} type="driver" />;

export const RecommendationGptDriverSummarizePanel = connect(mapStateToProps, mapDispatchToProps)(RecommendationGptDriverSummarizePanelWrapper);
export const DecisionChatGptSummarizePanel = connect(mapStateToProps, mapDispatchToProps)(DecisionChatGptSummarizePanelWrapper);
export const ChatGptDriverSummarizePanel = connect(mapStateToProps, mapDispatchToProps)(ChatGptDriverSummarizePanelWrapper);
