import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import DiscussionMentionsInput from "./DiscussionMentionsInput";
import { isBlank, successActions } from "../../../helpers/common";
import { isEnter, isEsc } from "../../../helpers/keys_helpers";
import { deleteUserMessage, updateUserMessage } from "../../../store/decision/actions";
import { parseContactIds } from "../../../helpers/discussion_helpers";
import { reloadContacts } from "../../../store/contacts/actions";
import { mentioneeWarning } from "../../../helpers/alert_helpers";
import { resetDecisionSetData } from "../../../store/decision_set/common_actions";

const EditMessageRow = ({
                          message, onClose = () => {},
                          deleteUserMessage, updateMessageText,
                          reloadContacts, resetDecisionSetData
                        }) => {
  const [messageText, setMessageText] = useState(message.description)
  const [submit, setSubmit] = useState(false)
  const [mentioned, _] = useState(parseContactIds(message.description))

  const ref = useRef(null);
  const onKeyDown = (e) => {
    if(isEsc(e.keyCode)) {
      setMessageText('');
      onClose();
    }
    if(isEnter(e.keyCode)) {
      e.preventDefault();
      e.stopPropagation();
      submitUpdatedMessage();
    }
  }
  const onChange = ({userMessage}) => {
    setMessageText(userMessage)
  }
  const updateMessageTextRequest = (make_mentionee_participant = false) => {
    const newMentioned = ref.current.newMentioned().filter(hash => !mentioned.some(row => parseInt(row.id) === parseInt(hash.id)))
    updateMessageText({
      description: messageText,
      slug: message.id,
      mentions: newMentioned.map(h => h['email']),
      all_new_mentions: newMentioned.map(h => h['email']),
      make_mentionee_participant
    }, (success) => {
      setSubmit(false)
      if (success) {
        onClose();
        successActions(make_mentionee_participant, [reloadContacts, resetDecisionSetData])
      }
    })
  }

  const submitUpdatedMessage = () => {
    setSubmit(true)
    if (isBlank(messageText)) {
      deleteUserMessage(message.id)
    } else {
      const newMentioned = ref.current.newMentioned().filter(hash => !mentioned.some(row => parseInt(row.id) === parseInt(hash.id)))
      mentioneeWarning(newMentioned, updateMessageTextRequest)
    }
  }

  return <div className="m-0">
    <div className="w-100 pb-2">
      <DiscussionMentionsInput ref={ref} placeholder="Type @ to mention someone" onKeyDown={onKeyDown}
                               onChange={onChange} initialValue={messageText} initialMentioned={mentioned}/>
    </div>
    <div className="d-flex">
      <Button bsPrefix="btn btn-primary w-100 me-1" onClick={submitUpdatedMessage} disabled={submit}>Done</Button>
      <Button bsPrefix="btn btn-light w-100 ms-1" onClick={onClose}>Cancel</Button>
    </div>
  </div>
}
const mapStateToProps = (_state) => ({
});
export default connect(mapStateToProps, {
  updateMessageText: updateUserMessage, deleteUserMessage, reloadContacts, resetDecisionSetData
})(EditMessageRow);
