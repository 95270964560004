import React from 'react';

export default ({ hidden, rateCompareChoices, setRateCompareChoices, classNames = 'mt-2' }) =>
  <div className={`w-100 ${classNames} lh-checkbox`} style={{lineHeight: '10px'}} hidden={hidden}>
    <input type="checkbox" id="rate-compare-checkbox" name="notify-checkbox"
           className="custom-checkbox"
           checked={rateCompareChoices}
           onChange={() => { setRateCompareChoices(!rateCompareChoices) }}/>
    <label htmlFor="rate-compare-checkbox" className="mb-0">Rate and compare choices per driver</label>
  </div>
