import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { loadHomepageData } from "../store/homepage/actions";
import AlertsSection from "../alerts";
import HomepageHeader from "../homepage/header";
import UserProfile from "./UserProfile";
import HelpPanel from "./side_panel/HelpPanel";
import { Loader } from "../common/Loader";
import SidebarNavigation from "../homepage/navigation";
import { checkLoadingEffect } from "../helpers/callbacks_helpers";

const Sidebars = () =>
  <Fragment>
    <div className="sidebars">
      <div className="help-side-panels">
        <HelpPanel />
      </div>
    </div>
  </Fragment>

const Wrapper = ({children}) =>
  <Fragment>
    <AlertsSection />
    <HomepageHeader />
    {children}
    <Sidebars />
  </Fragment>

export const Profile = ({ home, loadHomepageData }) => {
  checkLoadingEffect(home, loadHomepageData)

  if (home.loading) {
    return <Wrapper>
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper>
      <div className="d-flex w-100">
        <SidebarNavigation />
        <div className={`w-100 wrapper-container`}>
          <UserProfile />
        </div>
      </div>
    </Wrapper>;
  }
}
const mapStateToProps = ({ home }) => ({ home });
const mapDispatchToProps = (dispatch) => ({
  loadHomepageData: () => {
    dispatch(loadHomepageData())
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
