import React, {useMemo} from 'react';
import { connect } from "react-redux";
import { updateRecommendation } from "../../../store/sidebar/actions";
import {isBlank} from "../../../helpers/common";
import { JoditTextEditor } from "../../../common/JoditTextEditor";

const RationaleInput = ({ decision, updateRecommendation, sidebar }) => {
  if (isBlank(decision) || isBlank(decision.recommendation)) return null;
  const recommendation = decision.recommendation;
  if (isBlank(recommendation)) return null;
  const onChangeReasons = (reasons) => { updateRecommendation({ decision_recommendation: { final_decision_reasons: reasons, slug: recommendation.slug } }); };

  return <div className="mb-3 px-3">
    <h3>Rationale</h3>
      <JoditTextEditor className="jodit-container-default"
                       value={recommendation.final_decision_reasons}
                       key={`recommendation-rationale-${sidebar.rationaleTimeStamp}`}
                       setValue={onChangeReasons}
                       autofocus={false}
                       placeholder="Enter a detailed explanation" />
  </div>
};

const mapStateToProps = (state) => ({ decision: state.sidebar.decision, sidebar: state.sidebar});
export default connect(mapStateToProps, { updateRecommendation })(RationaleInput);
