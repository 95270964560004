import React from 'react';
import { connect } from "react-redux";
import { isBlank, successActions } from "../../../helpers/common";
import { isDecider, userName } from "../../../helpers/user_helpers";
import { reloadShareData } from "../../../store/share/actions";
import { removeDecisionInvite } from "../../../store/decision/actions";
import Alerts from "../../../../js/alerts";
import { BaseDropdownBtn } from "../../../common/BaseHamburgerBtn";
import { IconBtnDropdown } from "../../../common/dropdowns";
import { reloadContacts } from "../../../store/contacts/actions";
import CollabRowDescription from "../helpers/CollabRowDescription";
import UserAvatarImage from "../../../common/UserAvatarImage";
import { resetDecisionSetData } from "../../../store/decision_set/common_actions";
import { transferDecisionTo } from "../../../store/tree/actions";

const collaboratorMenuEvents = {
  makeManager: 'makeManager',
  removeInvite: 'removeInvite'
}

const removeInvite = (slug, removeDecisionInvite, actions) => {
  Alerts.warning({
    title: "Are you sure you want to remove this collaborator?",
  }).then(confirmed => {
    if (confirmed) {
      removeDecisionInvite(slug, (success) => successActions(success, actions))
    }
  }, () => {})
}

const makeManager = (user, transferDecision) => {
  Alerts.warning({
    title: `Are you sure you want ${user.full_name} to replace you as the manager of this decision?`,
    text: "You will no longer be able to manage collaborators or edit the decision tree.",
  }).then(confirmed => {
    if (confirmed) {
      transferDecision(user?.email)
    }
  }, () => {})
}

const CollaboratorsInviteRow = ({
                                  slug, user, email, decision, current_user,
                                  transferDecision, removeDecisionInvite,
                                  reloadShareData, reloadContacts, resetDecisionSetData, isLegacy = false
                                }) => {
  const collaboratorMenuSelect = (eventKey) => {
    switch (eventKey) {
      case collaboratorMenuEvents.makeManager:
        return makeManager(user, transferDecision)
      case collaboratorMenuEvents.removeInvite:
        return removeInvite(slug, removeDecisionInvite, [reloadShareData, reloadContacts, resetDecisionSetData])
    }
  }

  const name = userName(user, email)
  const isUserDecider = slug === 'decider'

  const deciderText = decision.assigned_decider_email === (user?.email || email) && decision.decided_at ? 'decider' : '';
  const recommenderText = decision.recommendation?.assigned_recommender_email === (user?.email || email) && decision.recommendation.recommended_at ? 'recommender' : '';

  return <div className="d-flex mb-1 align-items-center">
    <span className="collaborator_img position-relative">
      <UserAvatarImage user={user} size='md' pendingUserEmail={email} />
      <span className={ isBlank(recommenderText) && isBlank(deciderText) ? 'd-none' : 'd-inline' }>
        <i className="fas fa-star fa-sm text-warning position-absolute end-0"/>
      </span>
    </span>
    <CollabRowDescription {...{ name, isUserDecider, decision, email, deciderText, recommenderText }} />
    <div className="ms-auto" hidden={isLegacy || isUserDecider || !isDecider(current_user)}>
      <IconBtnDropdown id={`invite-menu-dropdown-${slug}`} onSelect={collaboratorMenuSelect}>
         <BaseDropdownBtn eventKey={collaboratorMenuEvents.makeManager} title="Make manager" hidden={isBlank(user)} />
         <BaseDropdownBtn eventKey={collaboratorMenuEvents.removeInvite} title="Remove" bsPrefix="text-danger" />
      </IconBtnDropdown>
    </div>
  </div>
}
const mapStateToProps = ({ current_user, tree, decision }) => ({
  current_user, decision,
  default_user_avatar_url: tree.default_user_avatar_url
});
export default connect(mapStateToProps, {
  removeDecisionInvite, reloadShareData, reloadContacts, resetDecisionSetData, transferDecision: transferDecisionTo
})(CollaboratorsInviteRow);
