import {
  LOAD_ORG_REPORT_OPTIONS_DATA,
  RESET_ORG_REPORT_OPTIONS,
  UPDATE_ORG_REPORT_OPTIONS_DATA,
  UPDATE_ORG_REPORT_OPTIONS_FAILED
} from "./types";
import {
  loadOrgReportPropertiesData,
} from "../../utils/Api";
import {
  dispatchErrorWithCallback,
  isResponseFailed
} from "../../helpers/store_helpers";

export function loadOrgReportProperties(data = {}) {
  return (dispatch) => {
    dispatch(updateOrgReportPropertiesData({ loaded: false, loading: true }))
    loadOrgReportPropertiesData(data).then((response) => {
      const { data } = response;
      if (isResponseFailed(response)) return dispatchErrorWithCallback(dispatch, { data, callback: loadOrgReportPropertiesFailure });
      dispatch(loadOrgReportPropertiesSuccess({ ...data, loaded: true, loading: false }));
    })
  }
}

export const loadOrgReportPropertiesSuccess = (data) => ({
  type: LOAD_ORG_REPORT_OPTIONS_DATA,
  payload: {
    ...data
  }
});

export const loadOrgReportPropertiesFailure = error => ({
  type: UPDATE_ORG_REPORT_OPTIONS_FAILED,
  payload: {
    error
  }
});

export const updateOrgReportPropertiesData = (data) => ({
  type: UPDATE_ORG_REPORT_OPTIONS_DATA,
  payload: {
    ...data
  }
});

export const resetOrgReportPropertiesState = () => ({
  type: RESET_ORG_REPORT_OPTIONS
});
