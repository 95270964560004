import { COMPLETE_TEAMS_DATA, FETCH_TEAMS_DATA, UPDATE_API_DATA } from "./types";

const initialState = {
    fetchLoading: false
};

export function apiReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case UPDATE_API_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }
        case FETCH_TEAMS_DATA: {
            return {
                ...state,
                teamFetchLoading: true,
                ...action.payload
            };
        }
        case COMPLETE_TEAMS_DATA: {
            return {
                ...state,
                teamFetchLoading: false,
                ...action.payload
            };
        }
        default:
            return state;
    }
}
