import React, { Fragment } from 'react';
import HelpButtons from "../../../common/HelpButtons";
import DSightText from "../../../homepage/help_content/DSightText";

export const Index = () => <Fragment>
  <div className="px-3 mb-3">
    <DSightText/>
  </div>
  <HelpButtons />
</Fragment>

export default Index;
