import React, {forwardRef, useMemo, useRef} from 'react';
import JoditEditor from 'jodit-react';
import { editorConfig } from '../helpers/jodit_helpers'
import {isBlankRichTextValue, prepareJoditRichText} from "../helpers/rich_text_helpers";

export const JoditTextEditor = forwardRef(({autofocus = true,
                                             placeholder = '',
                                             setValue,
                                             hasOnChangeAction = false,
                                             classNames='', ...opts},
                                           ref) => {
  const preparedRef = ref || useRef();
  const preparedConfig = useMemo(() => ({...editorConfig, ...{placeholder, autofocus}}), [opts] )

  const checkEnteredValue = (value) => {
    if (isBlankRichTextValue(value, true)) {
      setValue('')
    } else {
      setValue(prepareJoditRichText(value))
    }
  };

  const JoditEditorObject = () => <JoditEditor ref={preparedRef}
                                               config={preparedConfig}
                                               onBlur={(newValue) => !hasOnChangeAction && checkEnteredValue(newValue)}
                                               onChange={(newValue) => hasOnChangeAction && checkEnteredValue(newValue)}
                                               {...opts} />

  const memoJoditEditor = useMemo(() => <JoditEditorObject />, [autofocus]);

  return <div className={classNames}>
    { memoJoditEditor}
  </div>;
})
