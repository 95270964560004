import React, {Fragment, useMemo} from 'react';
import { connect } from "react-redux";
import 'react-sliding-side-panel/lib/index.css';
import { Loader } from "../../common/Loader";

import { isBlank, isPresent } from "../../helpers/common";
import Decision from "../../models/decision";
import { useHistory } from "react-router";
import {
  loadDecisionSetCallback,
  loadDecisionTreeCallback,
  loadTreeViewerEffects,
  mapDispatchToProps,
  mapStateToProps
} from "../../tree_view";
import DSightTree from "../../tree_view/accordion_tree/DSightTree";
import AlertsSection from "../../alerts";
import TreeHeader from "../../tree_view/header";
import RecommendationInputRightPanel from "../../tree_view/side_panel/RecommendationInputRightPanel";
import DriverInputRightPanel from "../../tree_view/side_panel/DriverInputRightPanel";
import DecisionInputRightPanel from "../../tree_view/side_panel/DecisionInputRightPanel";
import DSightDecisionInputRightPanel from "./side_panel/DSightDecisionInputRightPanel";
import DecisionDetailsPanel from "../../tree_view/side_panel/DecisionDetailsPanel";
import DiscussionPanel from "../../tree_view/side_panel/DiscussionPanel";
import DecisionPlaybookPanel from "../../tree_view/side_panel/DecisionPlaybookPanel";
import HelpPanel from "../../tree_view/side_panel/HelpPanel";
import { closeModal } from "../../store/modals/actions";
import DSightDriverModal, { showDSightDriverModal } from "../template_view/modals/DSightDriverModal";
import DSightDecisionModal, { showDSightDecisionModal } from "../template_view/modals/DSightDecisionModal";
import { SidebarTreeNavigation } from "../../tree_view/navigation";
import DecisionSetPanel from "../../tree_view/side_panel/DecisionSetPanel";
import LinkPanel from "../../tree_view/side_panel/link_panel/LinkPanel";
import CollaboratorsPanel from "../../tree_view/side_panel/CollaboratorsPanel";
import CollaboratorsSection from "../../tree_view/side_panel/sections/CollaboratorsSection";
import DataSourcesPanel from "../../tree_view/side_panel/DataSourcesPanel";
import DataSourcesSection from "../../tree_view/side_panel/sections/DataSourcesSection";
import MoreActionsPanel from "../../tree_view/side_panel/MoreActionsPanel";
import MoreActionsSection from "../../tree_view/side_panel/sections/MoreActionsSection";
import {isDecider, isPublicVisitor} from "../../helpers/user_helpers";
import {isGuest} from "../../helpers/home_helpers";
import {isDSightDecision} from "../../helpers/home_decision_helpers";

const Sidebars = ({ decisionObj, showDecisionSet = false }) =>
  <Fragment>
    <div className="sidebars">
      <RecommendationInputRightPanel key="recommendation-input-side-panel" hideAssignee={true} isDsight={true}/>
      <DriverInputRightPanel key="driver-input-side-panel" isDSight={true}/>
      {decisionObj?.isRecordedOrRecommended ?
        <DecisionInputRightPanel key="decision-input-side-panel" hideAssignee={true} isDsight={true}/> :
        <DSightDecisionInputRightPanel key="dsight-decision-input-side-panel" />
      }
      <DecisionDetailsPanel key="decision-details-side-panel"/>
      <CollaboratorsPanel key="collaborators-side-panel">
        <CollaboratorsSection classNames={"px-3 pb-3"}/>
      </CollaboratorsPanel>
      <DataSourcesPanel key="data-sources-side-panel">
        <DataSourcesSection addClass="px-3 pb-3" isDecision={true}/>
      </DataSourcesPanel>
      <DiscussionPanel key="discussion-side-panel"/>
      <DecisionPlaybookPanel key="playbook-side-panel" addClass={'left-side-panel-position tree-page'}/>
      {
        showDecisionSet ?
          <DecisionSetPanel addClass="left-side-panel-position tree-page" /> :
          null
      }
      <MoreActionsPanel>
        <MoreActionsSection isDetailsPanel={true} />
      </MoreActionsPanel>
      <LinkPanel key="link-side-panel" />
      <div className="help-side-panels">
        <HelpPanel/>
      </div>
    </div>
  </Fragment>

const Wrapper = ({ children, modal = null, closeModal, ...opts }) =>
  <Fragment>
    <AlertsSection/>
    <TreeHeader isDsight={true}/>
    <div className="d-flex vertical-tree-container">
      <SidebarTreeNavigation isDsight={true} {...opts} />
      {children}
    </div>
    <Sidebars {...opts} />
    {
      isBlank(modal) ? null :
        <div className="modals">
          <DSightDecisionModal shown={showDSightDecisionModal(modal)} onClose={closeModal} scope={'decisions'} />
          <DSightDriverModal shown={showDSightDriverModal(modal)} onClose={closeModal} scope={'decisions'} />
        </div>
    }
  </Fragment>

const DSightTreeView = ({
                          tree, decision, match,
                          modal, closeModal,
                          ...opts
                        }) => {
  const history = useHistory();

  loadTreeViewerEffects({ tree, decision, match, ...opts })
  loadDecisionTreeCallback({ history, tree, ...opts })
  loadDecisionSetCallback({ tree, decision, ...opts })

  const hideMoreSection = useMemo(() =>
    isBlank(opts.current_user) ||
    !isDecider(opts.current_user) && (isGuest(opts.current_user) || isDSightDecision(decision)) ||
    isPublicVisitor(opts.current_user) ||
    opts.current_org.slug !== opts.current_user.org_slug, [opts.current_user, opts.current_org]);

  if (tree.loading) {
    return <Wrapper>
      <Loader/>
    </Wrapper>;
  } else {
    return <Wrapper decisionObj={new Decision(decision)}
                    modal={modal}
                    closeModal={closeModal}
                    showDecisionSet={isPresent(decision.decision_set_slug)}
                    {...{decision, tree, hideMoreSection}}>
      <DSightTree />
    </Wrapper>;
  }
};

const newMapStateToProps = ({ modal, ...opts }) => ({
  ...mapStateToProps(opts),
  modal
})
const newMapDispatchToProps = (dispatch) => ({
  ...mapDispatchToProps(dispatch),
  closeModal: () => { dispatch(closeModal()) }
})
export default connect(newMapStateToProps, newMapDispatchToProps)(DSightTreeView);
