import {
  DSIGHT_ANALYSIS_LOAD_DATA,
  DSIGHT_ANALYSIS_LOAD_DATA_FAILED,
  DSIGHT_ANALYSIS_LOAD_DATA_STARTED,
  DSIGHT_ANALYSIS_RESET_DATA,
  DSIGHT_ANALYSIS_UPDATE_DATA
} from "./types";

const initialState = {
    loading: false,
    loaded: false,
    error: '',
    slug: '',
    section: '',
    goal_slug: '',
    obsolete: false,
    force_display: false,
    chain_data: {},
    contributing_insights: []
};

export function analysisReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case DSIGHT_ANALYSIS_LOAD_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case DSIGHT_ANALYSIS_UPDATE_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case DSIGHT_ANALYSIS_RESET_DATA: {
            return {
                ...initialState,
                ...action.payload,
                loading: false
            };
        }
        case DSIGHT_ANALYSIS_LOAD_DATA_STARTED: {
            return {
                ...state,
                ...initialState,
                ...action.payload,
                loading: true
            }
        }
        case DSIGHT_ANALYSIS_LOAD_DATA_FAILED: {
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        }
        default:
            return state;
    }
}
