import React from 'react';
import Image from "react-bootstrap/Image";

const DSightIcon = () =>
  <div className="dsight-datasource fa-layers-square bg-primary d-block">
    <i className="dsights-icon--light h-17 d-initial ps-3"/>
  </div>

const ForecastScenarioIcon = () =>
  <i className="fa-regular fa-magnifying-glass-chart" data-fa-transform="shrink-9 up-.5" data-fa-mask="fas fa-square"/>

const LinkIcon = ({ sourceIconClass }) =>
  <i className={sourceIconClass} data-fa-transform="shrink-10 up-.5" data-fa-mask="fas fa-square"/>

const FileExtIcon = ({fileExtension}) =>
  <>
    <i className="fas fa-square"/>
    <span className="fa-layers-text fa-inverse text-uppercase h1 text-white"
          data-fa-transform="shrink-9">{fileExtension}</span>
  </>

export default ({sourceObj, allowDSight = true, inUpload = false}) => {
  if (sourceObj.isDSight && allowDSight) {
    return <DSightIcon/>
  } else if (sourceObj.isLinkedForecastScenario) {
    return <ForecastScenarioIcon/>
  } else if (sourceObj.isLink) {
    return <LinkIcon sourceIconClass={sourceObj.sourceIconClass}/>
  } else if (sourceObj.isImage) {
    return <span className={`thumbnail-container ${inUpload ? 'fa-0' : ''}`}>
      <Image src={sourceObj.data_source.url} alt={sourceObj.data_source.title} title={sourceObj.data_source.title}/>
    </span>
  } else {
    return <FileExtIcon fileExtension={sourceObj.fileExtension}/>
  }
};
