import React, { useState } from 'react';
import { isBlank, isPresent, qSortArray } from "../../../../../helpers/common";
import { getNodeName } from "../../../../../helpers/metric_helpers";
import { fetchDynamicChainsNodeNames } from "../helpers/table_helpers";
import TableMetric from "../components/TableMetric";
import { advertisingDimension, brandDimension } from "../../../../../models/d_sight/dimensions";
import BrandTableNorms from "./BrandTableNorms";

const AdsNorms = ({ chartData, d_sight_goal, sortedNodeNames, ...opts }) => {
  const adsDimension = advertisingDimension(d_sight_goal)
  if (isBlank(adsDimension)) return null;

  const subChainsWithExtData = chartData.dynamic_chains.filter(dc => isPresent(dc.extended_data))
  if (isBlank(subChainsWithExtData)) return null;

  const branDim = brandDimension(d_sight_goal)
  const mainBrand = sortedNodeNames[0]

  const adsSubMetricsWithExtData = subChainsWithExtData.filter(dc =>
      dc.extended_data.some(ex =>
        isPresent(ex.nodes[adsDimension.node_index]) && ex.nodes[branDim.node_index] === mainBrand
      ) && dc.process_data.some(pd =>
        pd.nodes[branDim.node_index] === mainBrand
      )
  )
  if (isBlank(adsSubMetricsWithExtData)) return null;

  return <div className={`bg-white rounded p-3 mb-3`}>
    <BrandTableNorms {...{ ...opts, d_sight_goal, chartData, subMetrics: adsSubMetricsWithExtData, mainBrand }} />
  </div>
}

const filterDynChainsBy = (chain, filterDynChainsBy) => {
  if (filterDynChainsBy == null) return true;

  return filterDynChainsBy(chain);
}

export default ({
                  d_sight_goal, chartData, timeNode, selectedSurvey, metric,
                  header, footer, allowHide = false,
                  filterAttributes = null,
                  ...opts
                }) => {
  const [data, setData] = useState([])
  const rows = qSortArray(
    chartData.dynamic_chains.filter(dc => filterDynChainsBy(dc, filterAttributes)).map(({ answer }) => answer),
    true, a => a)

  if (isBlank(rows)) return null;

  const sortedNodeNames = fetchDynamicChainsNodeNames(chartData, d_sight_goal, metric)
  const targetNodeName = getNodeName(d_sight_goal, sortedNodeNames[0])

  return <>
    <div className={`bg-white rounded p-3 mb-3`} hidden={allowHide && isBlank(data)}>
      {header()}
      <div className={`answers-table-max-h overflow-auto mb-2`}>
        <TableMetric {...{ ...opts, d_sight_goal, chartData, timeNode, selectedSurvey, rows, sortedNodeNames, targetNodeName, setData }} />
      </div>
      {footer()}
    </div>
    <AdsNorms {...{ ...opts, chartData, d_sight_goal, sortedNodeNames, timeNode, metric }} />
  </>
}
