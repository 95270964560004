import React from 'react';
import { connect } from "react-redux";
import TemplateCard from './cards/TemplateCard';
import TemplateDriverCard from './cards/TemplateDriverCard';
import TemplateEntryModal, { openTemplateEntry, showTemplateEntry } from "../modals/TemplateEntryModal";
import { openModal, closeModal, transferDataAndCloseModal, updateTemplate } from "../../store/modals/actions";
import DriverEntryModal from "../modals/DriverEntryModal";
import {treeInitData} from "./shared/helpers";
import TemplatePollNotesModal from "../../template_view/modals/TemplatePollNotesModal";
import {showTemplatePollModal} from "../../template_view";

const TemplateTree = ({ tree, modal, template, user, contactsData, updateTemplate, transferDataAndCloseModal, openModal }) => {
  const { rootDrivers } = treeInitData(tree, {})

  const onTemplateClick = () => openTemplateEntry({ openModal, template })

  return <div className="container-fluid p-0">
    <div className="px-2 my-3 mx-auto vertical-tree">
      <TemplateCard {... { template, onTemplateClick, showArrow: rootDrivers.length > 0 } } />
      {
        rootDrivers.map((driverData, index) =>
          <TemplateDriverCard key={`template-driver-card-${driverData.driver.slug}`}
                              {...{ driverData, tree, contactsData, user, template, openModal,
                                shortBorderToArrow: rootDrivers.length === 1 || index === rootDrivers.length - 1  } } />
        )
      }
    </div>
    <div className="modals">
      <DriverEntryModal key={`tree-template-driver-entry-modal-${modal.slug}`}
                        isTemplate={true} />
      <TemplateEntryModal key={`tree-template-entry-modal-${modal.slug}`}
                          show={showTemplateEntry(modal)}
                          onClose={transferDataAndCloseModal}
                          updateTemplate={updateTemplate} />
      <TemplatePollNotesModal key={`tree-template-poll-modal-${modal.slug}`}
                          show={showTemplatePollModal(modal)}
                          onClose={transferDataAndCloseModal} />
    </div>
  </div>
}
const mapStateToProps = ({ tree, template, modal, current_user, contacts }) => ({
  tree, template, modal,
  user: current_user,
  contactsData: contacts
});
export default connect(mapStateToProps, {
  closeModal, updateTemplate, transferDataAndCloseModal, openModal
})(TemplateTree);