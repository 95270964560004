import {arrayToSentence, isBlank, isPresent} from "../../../helpers/common";
import React, {useMemo} from "react";
import Slider from "rc-slider";
import {getSliderStyles} from "../../side_panel/helpers/CustomSlider";
import {totalUsersVotes} from "../../../helpers/user_helpers";

const choiceScoreVote = (usersChoicesVotes, pickedByUser) => {
  return Math.round((pickedByUser.length / totalUsersVotes(usersChoicesVotes)) * 100);
};

const ChoicePollRow = ({choice, usersChoicesVotes, object}) => {
  const userPicked = (vote) => isBlank(vote.picked) && isBlank(vote.rank) ? null : `${vote.user.full_name || vote.user_email}`
  const pickedByUser = choice.votes.map(vote => userPicked(vote)).filter(name => isPresent(name));
  const score = useMemo(() => choiceScoreVote(usersChoicesVotes, pickedByUser), [choice.votes])

  return <div className='mb-3' key={`choice-${choice.slug}`}>
    <div className='fw-bold d-flex mb-2'>
      <div className='me-2'>{choice.description}</div>
      <div className="ms-auto">
        <div>{`${score}%`}</div>
      </div>
    </div>
    {
      !object.isRankedList && <div className="poll-card-slider mb-2">
        <Slider value={score}
          {...getSliderStyles({
            hideHandle: true,
            startScale: 0,
            endScale: 100,
            height: 12})
          }
        />
      </div>
    }
    {isPresent(pickedByUser) && <div className='text-muted fw-normal'>Picked by {arrayToSentence(pickedByUser)}</div>}
  </div>
}

export default ChoicePollRow