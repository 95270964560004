import React from 'react';
import { connect } from "react-redux";
import { setDecisionSidebarOpen } from "../../store/sidebar/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";

const DetailsHamburgerButton = ({ openDecisionDetailsSideBar, title = 'Details' }) =>
  <BaseHamburgerBtn onSelect={openDecisionDetailsSideBar} title={title} />

const mapDispatchToProps = (dispatch) => ({
  openDecisionDetailsSideBar: () => dispatch(setDecisionSidebarOpen(true))
});
export default connect(null, mapDispatchToProps)(DetailsHamburgerButton);
