import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { removeReport } from "../../store/report/actions";
import { removeReportWizardData } from "../../store/wizard/actions";
import {
  resetSearchAndFilters
} from "../../store/homepage/actions";
import { resetSidebars } from "../../store/sidebar/actions";
import { resetWizard } from "../../store/wizard/actions";
import { isPresent, successActions } from "../../helpers/common";
import BaseHamburgerBtn, { BaseHamburgerItem } from "../../common/BaseHamburgerBtn";
import { IconDropdown } from "../../common/dropdowns";
import { TABS } from "../../helpers/home_helpers";

const redirectAfterComplete = ({ history, slug }) => history.push(`/reports/${slug}`)

export const cancelCallback = ({ actions, history, slug, completed, removeReport, removeReportWizard }) => () => {
  if (completed) {
    removeReportWizard((success) => {
      if(success) {
        redirectAfterComplete({ history, slug })
        successActions(success, actions)
      }
    })
  } else {
    removeReport(slug, (success) => {
      redirectToHomeCallback(success, history, TABS.report_insights);
      successActions(success, actions);
    })
  }
}

export const doneCallback = ({ stepRef, wizard, actions, history, slug }) => () => {
  const submitData = { complete: true }
  if (wizard.disabledSubmit) return false;

  stepRef?.current?.submitStep(submitData, (success) => {
    if (success) {
      redirectAfterComplete({ history, slug })
      successActions(success, actions)
    }
  });
}

export const ActionsDropdown = ({
                                  btnId, wizard,
                                  stepRef, report,
                                  removeReport,
                                  removeReportWizard,
                                  resetSidebars, resetWizard, resetSearchAndFilters, forceReloadHomepageReportInsights,
                                  ...opts
                                }) => {

  const history = useHistory();

  const cancelHandler = useCallback(
    cancelCallback({
      history, slug: report.slug, completed: isPresent(report.completed_at), removeReport, removeReportWizard,
      actions: [resetSearchAndFilters, resetSidebars, resetWizard, forceReloadHomepageReportInsights]
    }), [report.slug] )

  const doneHandler = doneCallback( {
    stepRef, history, wizard, slug: report.slug,
    actions: [resetSearchAndFilters, resetSidebars, resetWizard, forceReloadHomepageReportInsights]
  })

  const iconDropdownEvents = {
    doneHandler: 'doneHandler',
    cancelHandler: 'cancelHandler'
  }

  const iconDropdownSelect = (eventKey) => {
    switch (eventKey) {
      case iconDropdownEvents.doneHandler:
        return doneHandler()
      case iconDropdownEvents.cancelHandler:
        return cancelHandler()
    }
  }

  return <IconDropdown id={btnId} bsPrefix="h5 m-0 btn-link pt-2"
                       className="btn btn-secondary btn-lg-round d-none d-lg-flex p-0"
                       onSelect={iconDropdownSelect}>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.doneHandler} disabled={wizard.submit || wizard.disabledSubmit} title="Done"/>
    <BaseHamburgerItem eventKey={iconDropdownEvents.cancelHandler} disabled={wizard.submit} className="text-danger" title="Cancel" />
  </IconDropdown>;
}
const mapStateToProps = ({ wizard, report }) => ({ wizard, report });
export const headerActionsDispatchProps = (dispatch) => ({
  removeReport: (slug, callback) => { dispatch(removeReport(slug, callback)) },
  removeReportWizard: (callback) => { dispatch(removeReportWizardData(callback)) },
})
const mapDispatchToProps = (dispatch) => ({
  ...headerActionsDispatchProps(dispatch),
  resetSidebars: () => {
    dispatch(resetSidebars())
  },
  resetWizard: () => {
    dispatch(resetWizard())
  },
  resetSearchAndFilters: () => dispatch(resetSearchAndFilters())
});
const wrapper = React.forwardRef((props, ref) => <ActionsDropdown {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
