import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../../helpers/common";
import {
  DSightSourcesTypes, LinkedReportTemplateDataSourcesType,
  TemplateOrDSightDataSourcesTypes, TemplateSetSourcesTypes
} from "../../../models/data_source";
import AddLink from "../../../tree_view/data_sources/AddLink";
import StyledDropzone from "../../../tree_view/data_sources/DropFiles";
import {
  createSource,
  destroySource,
  replaceSource,
  saveUploadingSources,
  updateSource,
  attachReport, detachReport
} from "../../../store/tree/actions";
import SourceItem from "../../../tree_view/data_sources/Item";
import UploadingItem from "../../../tree_view/data_sources/UploadingItem";
import { decisionDataSources } from "../../../models/decision";
import { DataSourcesPBSection } from "./PlaybookNotesSection";
import { canceledUploads, finishedUploads } from "../../../helpers/uploads_helpers";
import { generateBatchDataSourceUploadState } from "../../../helpers/uploads_callbacks";
import Button from "react-bootstrap/Button";
import { sourcesData } from "../../../tree_view/side_panel/sections/data_sources/helpers"
import { addSourceToTemplate } from "../../../store/template_set/actions";
import SelectExistingSourceRow from "../../../tree_view/side_panel/sections/data_sources/SelectExistingSourceRow"

export const DataSourceRow = ({
                                isTemplate = true, isDriverDetailsModal = false,
                                data_source, hideMenu = false,
                                fromDecisionPanel = false, fromRecommendationPanel = false,
                                isWizard = false,
                                ...opts
                              }) =>
  <SourceItem source={data_source}
              forceEditMode={data_source.just_created}
              hideMenu={hideMenu} isTemplate={isTemplate}
              isWizard={isWizard}
              fromDecisionPanel={fromDecisionPanel}
              fromRecommendationPanel={fromRecommendationPanel}
              isDriverDetailsModal={isDriverDetailsModal}
              {...opts}
  />

export const UploadingSourceRow = ({ uploading_source, onCancelUpload, isTemplate = true, isReport = false }) =>
  <UploadingItem id={`${uploading_source.slug}-item`}
                 isTemplate={isTemplate}
                 isReport={isReport}
                 source={uploading_source}
                 hidden={canceledUploads().includes(uploading_source.slug) || finishedUploads().includes(uploading_source.slug)}
                 onCancelUpload={onCancelUpload}
                 uploadProgress={uploading_source.progress}
  />

export const AddSourceRows = ({
                                submitLink, dataSourceType, formWrapper, isTemplate = false,
                                createSource, isWizard = false, fromDecisionPanel, fromRecommendationPanel,
                                onStartUpload, onFinishUpload, onProcessUpload, isReplaceFile,
                                isHistoricalDecision = false, showExtraMargin
                              }) => {
  const [showDropzone, setShowDropzone] = useState(false);
  const focusDropzone = useRef(null);
  const scrollToBottom = () => {
    focusDropzone.current?.scrollIntoView({ behavior: "smooth" })
  }
  useEffect(() => {
    if (isHistoricalDecision && window.innerWidth > 1199) return;

    scrollToBottom()
  }, [showDropzone])

  return <>
    <AddLink onClick={submitLink} sidebar={true} {...{showExtraMargin}}/>
    <Button className="btn btn-secondary w-100 mt-2"
            onClick={() => setShowDropzone(true)}
            hidden={showDropzone}>
      Add files
    </Button>
    <div className="mt-2" hidden={!showDropzone} ref={focusDropzone}>
      <StyledDropzone onCreateSource={createSource}
                      isTemplate={isTemplate}
                      isWizard={isWizard}
                      fromDecisionPanel={fromDecisionPanel}
                      fromRecommendationPanel={fromRecommendationPanel}
                      isReplaceFile={isReplaceFile}
                      dataSourceType={dataSourceType}
                      formWrapper={formWrapper}
                      onStartUpload={onStartUpload}
                      onFinishUpload={onFinishUpload}
                      onProcessUpload={onProcessUpload}/>
    </div>
  </>
}

export const DataSourcesList = ({
                                  data_sources, hideMenu= false,
                                  uploadingFile, fromDriver, isPlaybook,
                                  isDriverDetailsModal,
                                  ...opts
                                }) => {
  return data_sources.map(data_source =>
    <DataSourceRow data_source={data_source} hideMenu={hideMenu} fromDriver={fromDriver} isPlaybook={isPlaybook} isDriverDetailsModal={isDriverDetailsModal}
                   key={`template-details-data-source-${data_source.slug}-${data_source.key}`}
                   {...opts}
    />
  )
}

export const UploadingSourcesList = ({
                                  uploading_sources,
                                  onCancelUpload,
                                  ...opts
                                }) => {
  return uploading_sources.map((sourceData, i) =>
    <UploadingSourceRow uploading_source={sourceData.uploadingSource}
                        key={`template-details-uploading-source-${sourceData.uploadingSource.slug}-${i}`}
                        onCancelUpload={onCancelUpload}
                        {...opts}
    />
  )
}

export const DataSourcesSection = ({
                                     playbook_notes, data_sources, template, uploading_sources,
                                     createSource, saveUploadingSources, user, addSourceToTemplate,
                                     current_org, orgReportsForDetailsPanel, attachReport,
                                     template_set_sources,
                                     ...opts
                                  }) => {
  const [selectedSource, setSelectedSource] = useState('');

  const onSelectLinkedReport = (option) => {
    setSelectedSource(option.value);
    attachReport(option.value, {}, true);
  };

  const onSelectSetSource = (option) => {
    setSelectedSource(option.value);

    const attributes = { data_source: { template_slug: template.slug } }
    addSourceToTemplate(option.value, template.template_set_slug, attributes, true);
  };

  const uploadOptions = generateBatchDataSourceUploadState(data_sources, uploading_sources, saveUploadingSources, user)
  const submitLink = (value, callback) => createSource({ link_url: value }, {}, callback, true);

  return <div className="px-3 pb-3">
    <DataSourcesPBSection playbook_notes={playbook_notes} sidePanelStyle={true} showNA={!data_sources?.length} />
    <div className="d-flex flex-column" hidden={isBlank(data_sources)}>
      <DataSourcesList data_sources={data_sources} {...uploadOptions} {...opts} />
      <UploadingSourcesList uploading_sources={uploading_sources} {...uploadOptions} {...opts} />
    </div>
    <AddSourceRows submitLink={submitLink} createSource={createSource} {...uploadOptions} isTemplate={true} />
    {
      (isPresent(template.template_set_slug) && isPresent(template_set_sources)) ?
        <div className="mt-2" hidden={isBlank(data_sources) && isBlank(template_set_sources) && isBlank(orgReportsForDetailsPanel)}>
          <div className="new-source-entries">
            <SelectExistingSourceRow selectedSource={selectedSource}
                                     onSelectReport={onSelectLinkedReport}
                                     onSelectSetSource={onSelectSetSource}
                                     decisionSetSources={template_set_sources}
                                     orgReports={orgReportsForDetailsPanel}
            />
          </div>
        </div> :
        current_org.show_report_insights && isPresent(current_org.reports)  ?
          <div className="mt-2" hidden={isBlank(orgReportsForDetailsPanel)}>
            <div className="new-source-entries">
              <SelectExistingSourceRow selectedSource={selectedSource}
                                       onSelectReport={onSelectLinkedReport}
                                       orgReports={orgReportsForDetailsPanel}
              />
            </div>
          </div> : null
    }
  </div>
};
const mapStateToProps = ({ template, playbook_notes, tree, template_set, current_user, current_org }) => {
  const sortedRelatedDataSources = decisionDataSources(tree.data_sources || [], tree.drivers, TemplateOrDSightDataSourcesTypes)
  const finalRelatedDataSources = sortedRelatedDataSources.filter((source) => !DSightSourcesTypes.includes(source.type))
  const available_tree_data_sources = isBlank(tree.data_sources) ? [] : tree.data_sources.filter((source) => !LinkedReportTemplateDataSourcesType.includes(source.type))
  const is_user_in_template_set = template_set?.users.some(user => user.email === current_user.email)
  const available_set_sources = is_user_in_template_set? available_tree_data_sources.filter((source) => TemplateSetSourcesTypes.includes(source.type)) : []
  return {
    template, playbook_notes,
    uploading_sources: tree.uploading_sources,
    data_sources: sortedRelatedDataSources,
    template_set_sources: available_set_sources,
    user: current_user,
    current_org: current_org,
    ...sourcesData(current_org, finalRelatedDataSources)
  }
};
export default connect(mapStateToProps, {
  createSource, updateSource, destroySource, saveUploadingSources, replaceSource, attachReport, detachReport,
  addSourceToTemplate
})(DataSourcesSection);
