import React from 'react';
import {
  AnalysisRecordHeaderLink,
  ContributingInsightSection,
  RecordInsights,
  RelatedRecordBlock
} from "../AnalysisContent";
import { skipAnalysisRecord } from "../../../models/d_sight/Analysis";
import { transformsMetricsBy } from "../analysis_metrics/helpers";

export const ContributingKPISection = ({ kpi, hideLink = false, addClass = '', filterDemographic = false }) =>
  <div className={`${addClass} w-100`}>
    <div className={'h-100 bg-white rounded p-3'}>
      <AnalysisRecordHeaderLink link={kpi.link} hideLink={hideLink} title={kpi.name} />
      <RecordInsights insights={kpi.orderedInsights(filterDemographic)} />
    </div>
  </div>

const ContributingInsightKPIBlock = ({ kpi, lever, d_sight_goal }) => {
  if (skipAnalysisRecord(kpi)) return null;

  const chartMetrics = transformsMetricsBy(kpi.chartMetrics, lever, d_sight_goal)

  return <div className={'d-flex flex-column flex-md-row mb-3'}>
    <ContributingKPISection kpi={kpi} addClass={''}/>
    <div className="mt-2 mt-md-0 ms-0 ms-md-3 sidepanel-minwidth">
      <div className={'h-100 bg-white rounded p-3'}>
        <h3>Contributing metrics</h3>
        { chartMetrics.map(metric =>
          <RelatedRecordBlock record={metric} key={`contributing-kpi-${kpi.id}-metric-${metric.id}`} />
        )}
      </div>
    </div>
  </div>
}

export default ({ insight, analysisObj, d_sight_goal }) =>
  <div className={`container mt-3`}>
    <h1>Insight</h1>
    <div className={'mb-3'}>
      <ContributingInsightSection {...{ insight, hideLink: true }} />
    </div>
    <h1>Contributing KPIs</h1>
    {
      insight.kpis.map(kpi =>
        <ContributingInsightKPIBlock {...{ kpi, lever: analysisObj, d_sight_goal }} key={`contributing-insight-block-${insight.id}-kpi-${kpi.id}`} />
      )}
  </div>
