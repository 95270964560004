import React, { useState } from 'react';
import { connect } from "react-redux";
import ShareData, { itemIdentifier } from "../../../models/share_data";
import { Typeahead } from 'react-bootstrap-typeahead';
import { isPresent } from "../../../helpers/common";
import { onChangeUseStateCallback } from "../../../template_wizard/steps_wizard/steps/shared_helpers";
import {
  DEFAULT_TYPEHEAD_ATTRIBUTES, filteredDecisionItems,
  renderContactItem,
  renderTag
} from "../../../tree_view/modals/helpers/share_helpers";

const defaultDecisionAdmissions = (decision_admissions, shareObject) =>
  decision_admissions.map(a =>
    shareObject.availableDecisionShareItems.find(item => itemIdentifier(a, 'admittable_slug') === itemIdentifier(item))
  ).filter(a => isPresent(a))

const ViewAdmissionsInput = ({
                               share_data, decision_admissions = [],
                               viewObjsTitle = 'decisions', viewFromTitle = 'this template',
                               inputRef
                            }) => {
  const shareObject = new ShareData(share_data);
  const [decisionAdmissionsSelection, setDecisionAdmissionsSelection] = useState(defaultDecisionAdmissions(decision_admissions, shareObject))

  const onChangeDecisionAdmissions = onChangeUseStateCallback(setDecisionAdmissionsSelection, shareObject)

  inputRef.current.decision_admission_identifiers = () => decisionAdmissionsSelection.map(a => itemIdentifier(a))
  inputRef.current.resets.resetDecisionIdentifiers = () => () => setDecisionAdmissionsSelection(defaultDecisionAdmissions(decision_admissions, shareObject))

  return <div className="w-100 mb-3">
    <h3>Who can see {viewObjsTitle} created from {viewFromTitle}?</h3>
    <Typeahead
      {...DEFAULT_TYPEHEAD_ATTRIBUTES}
      id={"decision_admissions"}
      selected={decisionAdmissionsSelection}
      onChange={onChangeDecisionAdmissions}
      options={filteredDecisionItems(shareObject, decisionAdmissionsSelection)}
      renderMenuItemChildren={renderContactItem}
      renderToken={renderTag}
    />
  </div>
}
const mapStateToProps = ({ share_data }) => ({ share_data });
const wrapper = React.forwardRef((props, ref) => <ViewAdmissionsInput {...props} inputRef={ref} />)
export default connect(mapStateToProps, null, null, { forwardRef: true })(wrapper);
