import React from 'react';
import { hasParticipantAdmission, isVisitor } from "../../../../helpers/user_helpers";
import { isBlank } from "../../../../helpers/common";
import { BorderedFilterStyle } from "../../../../tree_wizard/styles/choice_entry_styles";
import Select from "react-select";

const SelectForecastScenarioSourceRow = ({ selectedSource, onSelectScenario, orgForecastScenarios }) => {
  const options = orgForecastScenarios.map(forecast_scenario => ({
      value: forecast_scenario.id,
      label: <span style={{ wordBreak: 'break-word' }}>
          {forecast_scenario.display_name}
        </span>
    })
  )

  return <div className="existing-forecast-scenario-source-dropdown">
    <Select
      value={selectedSource}
      onChange={onSelectScenario}
      components={{ IndicatorSeparator: () => null }}
      styles={BorderedFilterStyle}
      placeholder="Add a forecast simulator scenario"
      options={options} />
  </div>
}


export default ({
                  orgForecastScenarios,
                  selectedSource,
                  onSelectScenario, hidden = false
                }) => {
  if (hidden || isBlank(orgForecastScenarios)) return null;

  return <div className="mt-2">
    <div className="new-source-entries">
      <SelectForecastScenarioSourceRow selectedSource={selectedSource} onSelectScenario={onSelectScenario}
                                       orgForecastScenarios={orgForecastScenarios}/>
    </div>
  </div>
}
