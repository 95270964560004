import { COMPLETE_TEAMS_DATA, FETCH_CONTACTS_DATA, LOAD_FAILED } from "./types";

const initialState = {
    fetch: false,
    error: null,
    contacts: [],
    loaded: false,
    loading: false,
    invite_yourself: false
};

export function contactsReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case LOAD_FAILED: {
            return {
                ...state,
                fetch: false,
                ...action.payload
            };
        }
        case FETCH_CONTACTS_DATA: {
            return {
                ...state,
                fetch: true,
                ...action.payload
            };
        }
        case COMPLETE_TEAMS_DATA: {
            return {
                ...state,
                fetch: false,
                ...action.payload
            };
        }
        default:
            return state;
    }
}
