import React, { useState } from 'react';
import { connect } from "react-redux";
import { setSetSummarySidebarOpen } from "../../../store/sidebar/actions";
import { isMobile } from "react-device-detect";
import DoneButton from "../../../common/DoneButton";
import { RichTextEditor } from "../../../common/RichTextEditor";
import Cookies from "js-cookie";
import { expireCookie, isBlank, isPresent } from "../../../helpers/common";
import { updateDecisionSetSummary } from "../../../store/decision_set/actions";
import { isSummaryPending } from "../../../helpers/set_helpers";
import DecisionSet from "../../../models/decision_set";
import { decisionLink } from "../../../homepage/tabs/tiles/helpers";
import Decision from "../../../models/decision";
import * as moment from "moment";
import { userName } from "../../../helpers/user_helpers";
import { isDraftDecision, isUnstartedDecision } from "../../../models/decision_state";

const cookieKey = (set) => `decision-set-summary-${set.slug}`

const SummaryInput = ({
                        name, slug, value, setValue
                      }) =>
  <div className='mb-3'>
    <h3>{name}</h3>
    <RichTextEditor quillId={`summary-input-${slug}`} className="mb-2"
                    value={value || ''}
                    setValue={newValue => setValue(slug, newValue)}
                    placeholder={'Enter a brief summary'}/>
  </div>

const cachedSummary = (set) => {
  const cachedSummaryJson = Cookies.get(cookieKey(set))
  if (isPresent(cachedSummaryJson)) {
    if (isSummaryPending(set)) {
      try {
        return JSON.parse(cachedSummaryJson)
      } catch(_e) {}
    } else {
      Cookies.remove(cookieKey(set))
    }
  }

  return false;
}

const fetchDefaultSummary = (set) =>
  cachedSummary(set) || set.sections.reduce((obj, section) => {
    obj[section.slug] = section.summary || ''
    return obj;
  }, { set: set.summary || '' })

const generateFinalChoicesTexts = (obj, result) => {
  if (obj.finalDecisions.length < 1) return;

  if (obj.isRankedList) return result.push(`<ol>${obj.finalDecisions.map(c => `<li>${c.description}</li>`).join('')}</ol>`);

  if (obj.finalDecisions.length > 1) return result.push(`<ul>${obj.finalDecisions.map(c => `<li>${c.description}</li>`).join('')}</ul>`);

  result.push(`<p>${obj.finalDecisions[0].description}</p>`)
}

const formatDate = (date) => moment(date).format('DD MMM, yyyy');

const decisionText = (decision) => {
  const deciderEmail = decision.assigned_decider_email;
  const decisionDate = decision.historical_decided_at;
  const deciderUser = decision.users?.find(user => user.email === deciderEmail)?.full_name;
  const deciderName = deciderUser || deciderEmail;

  if (decisionDate) {
    const text = deciderEmail ? `Decided by ${deciderName} on` : 'Decided on';
    return `${text} ${formatDate(decisionDate)}`;
  } else {
    const text = deciderEmail ? `Decided by ${deciderName} on` : `Decision entered by ${userName(decision.deciding_user)} on` ;
    return `${text} ${formatDate(decision.decided_at)}`;
  }
};


const recommendationText = (decision) => {
  const recommenderEmail = decision.recommendation?.assigned_recommender_email;
  const recommenderUser = decision.users?.find(user => user.email === recommenderEmail)?.full_name;
  const recommenderName = recommenderUser || recommenderEmail;
  return recommenderEmail ? `Recommended by ${recommenderName}` : `Recommendation entered by ${userName(decision.recommendation.user)}`;
};

const generateDecisionText = (decision, set) => {
  const obj = new Decision(decision)
  const hasAccess = set.accessible_decisions?.some(slug => slug === decision.slug)

  const result = [
    `<p><a href="${document.location.origin}${decisionLink(decision)}" target="_blank"><strong>${decision.description}</strong></a></p>`
  ];
  if (hasAccess) {
    if (obj.isDecisionRecorded) {
      generateFinalChoicesTexts(obj, result)
      result.push(`<p><em>${decisionText(decision)}</em></p>`)
    } else if (obj.isEnteredRecommendation) {
      generateFinalChoicesTexts(obj.recommendation, result)
      result.push(`<p><em>${recommendationText(decision)} on ${formatDate(decision.recommendation.recommended_at)}</em></p>`)
    }
  }
  return result.join('\n');
}

const formatDecisions = (decisions, set) => {
  const filteredDecisions = decisions.filter(d => !isUnstartedDecision(d) && !isDraftDecision(d) )
  return isBlank(filteredDecisions) ? null : filteredDecisions.map(d => generateDecisionText(d, set)).join('<br />')
}

const generateFlowSummary = (set) => {
  const setObj = new DecisionSet(set)
  return formatDecisions(setObj.decisionsWithoutSection, set)
}

const generateFlowSectionSummary = (set, section) => {
  const setObj = new DecisionSet(set)
  return formatDecisions(setObj.decisionsInSection(section), set)
}

const SummaryFormSection = ({
                              decision_set,
                              setSetSummarySidebarOpen, updateDecisionSetSummary
                            }) => {
  const [stubUpdate, setStubUpdate] = useState(false)
  const [flowSummary, setFlowSummary] = useState(fetchDefaultSummary(decision_set))
  const autoFillSummary = () => {
    const newSummary = decision_set.sections.reduce((obj, section) => {
      obj[section.slug] = generateFlowSectionSummary(decision_set, section)
      return obj;
    }, { set: generateFlowSummary(decision_set) })

    setStubUpdate(true)
    setTimeout(() => saveFlowSummary(newSummary), 100)
    setTimeout(() => setStubUpdate(false), 300)
  }
  const onClose = () => setSetSummarySidebarOpen(false)
  const onSubmit = () => {
    updateDecisionSetSummary(
      { summary: flowSummary },
      (flag) => {
        if(flag) {
          Cookies.remove(cookieKey(decision_set))
          onClose()
        }
      }
    )
  }

  const saveFlowSummary = (newSummary) => {
    Cookies.set(cookieKey(decision_set), JSON.stringify(newSummary), { expires: expireCookie() })

    setFlowSummary(newSummary)
  }

  const saveSummaries = (slug, value) => {
    if (stubUpdate) return;

    if (flowSummary[slug] === value) return;

    const newSummary = { ...flowSummary }
    newSummary[slug] = value;

    saveFlowSummary(newSummary)
  }

  return <>
    <div className="px-3">
      <div className="mb-3 text-muted">
        <a className="pointer" onClick={autoFillSummary}>Click here</a> to auto-fill with decision recommendations or what was decided. This will replace any current entry.
      </div>
      <SummaryInput
        {...{ setValue: saveSummaries,
          slug: 'set', name: decision_set.name, value: flowSummary.set
        }} />
      {
        decision_set.sections.map(section =>
          <SummaryInput {...{
            setValue: saveSummaries,
            slug: section.slug, name: section.name, value: flowSummary[section.slug],
            key: `summary-${section.slug}`
          }} />
        )
      }
  </div>
    <div className={`${isMobile ? 'mb-5' : 'side-panel bottom'} p-3`} style={{zIndex: 0}}>
      <DoneButton onClose={onSubmit} />
    </div>
  </>
}

const mapStateToProps = ({ decision_set }) => ({ decision_set });
export default connect(mapStateToProps, { setSetSummarySidebarOpen, updateDecisionSetSummary })(SummaryFormSection);
