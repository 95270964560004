import React from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetSidebars } from "../../../store/sidebar/actions";
import { resetTemplate } from "../../../store/template/common_actions";
import { resetTree } from "../../../store/tree/common_actions";
import PlaybookHamburgerButton from "../../../template_view/header/PlaybookHamburgerButton";
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../../common/dropdowns";
import DetailsHamburgerButton from "../../../template_view/header/DetailsHamburgerButton";
import HelpHamburgerButton from "../../../template_view/header/HelpHamburgerButton";
import { goToHomepageCallback } from "../../../tree_wizard/header/helpers";
import MoreActionsHamburgerButton from "../../../template_view/header/MoreActionsHamburgerButton";

export const HamburgerDropdown = ({
                                    resetSidebars, resetTree, resetTemplate,
                                  }) => {
  const history = useHistory();
  const goToHomepage = goToHomepageCallback([resetSidebars, resetTree, resetTemplate], history)

  return <HamburgerMenuIconBtn>
    <DetailsHamburgerButton />
    <PlaybookHamburgerButton />
    <MoreActionsHamburgerButton />
    <HelpHamburgerButton />
    <BaseHamburgerBtn onSelect={goToHomepage} title="Go to homepage" />
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({template}) => ({ template });
const mapDispatchToProps = (dispatch) => ({
  resetSidebars: () => dispatch(resetSidebars()),
  resetTree: () => dispatch(resetTree()),
  resetTemplate: () => dispatch(resetTemplate())
});
export default connect(mapStateToProps, mapDispatchToProps)(HamburgerDropdown);
