import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { hideReportInsightsTab } from "../../helpers/home_helpers";
import { Loader } from "../../common/Loader";
import {
  isHomepageSectionLoaded,
  isHomepageSectionLoading,
  loadReportInsightsData,
  needToLoadHomepageSection,
  loadReportsData
} from "../../store/homepage/actions";
import { loadOrgReportProperties } from "../../store/org_report_properties/actions";
import AllReportsStatisticsPanel from "./report_insights/AllReportsStatisticsPanel";
import ReportsSection from "./report_insights/ReportsSection";
import {checkLoadingEffect} from "../../helpers/callbacks_helpers";

const ReportInsightsTab = ({
                             home, current_user, current_org, org_report_properties, loadReportInsightsData, loadOrgReportProperties, loadReportsData
}) => {
  if (hideReportInsightsTab(current_user, current_org)) return null;
  checkLoadingEffect(org_report_properties, loadOrgReportProperties)
  useEffect(() => {
    if (needToLoadHomepageSection(home, 'report_insights')) loadReportInsightsData()
  }, [home.report_insights.loaded])
  useEffect(() => {
    if (needToLoadHomepageSection(home, 'reports')) loadReportsData()
  }, [home.reports.loaded])

  return <>
    <div className="mx-auto">
      <div className="row" hidden={isHomepageSectionLoaded(home, 'report_insights')}>
        <Loader />
      </div>
      <div hidden={isHomepageSectionLoading(home, 'report_insights')}>
        <AllReportsStatisticsPanel />
        <ReportsSection />
      </div>
    </div>
  </>
}

const mapStateToProps = ({ home, current_user, current_org, org_report_properties }) => ({
  home, current_user, current_org, org_report_properties
});
export default connect(mapStateToProps, { loadReportInsightsData, loadOrgReportProperties, loadReportsData })(ReportInsightsTab);
