import { stepWithDrivers, wizardStepDataBy } from "../../helpers/wizard_helpers";
import { driversToArray } from "../../helpers/drivers_helpers";
import { isPresent } from "../../helpers/common";

export const modelDriverProps = ({tree, modal, wizard}) => {
  const modalDrivers = isPresent(modal.drivers) ?
    modal.drivers :
    wizardStepDataBy(wizard, stepWithDrivers(wizard))?.drivers || [];

  const driverData = driversToArray(modalDrivers).find(driverData => driverData.driver.slug === modal.slug);
  return {
    tree, modal, wizard,
    driverData, modalDrivers
  };
};
