import React from 'react';
import Button from "react-bootstrap/Button";
import { selectedCategoryOptionsByCategory } from "../../../helpers/filter_helpers";
import { isBlank, toSentence } from "../../../helpers/common";
import DSightOnlyPill from "./DSightOnlyPill";
import TemplateUsersFilter from "./TemplateUsersFilter";

const resetCategoryOptionsByCategory = (home, category, loadDashboardTemplates) => {
  const allCategoryOptionsIdsByCategory = category.options.map((option) => option.id)
  const filteredSelectedCategories = home.category_options.filter((category_option_id) => !allCategoryOptionsIdsByCategory.includes(category_option_id));
  loadDashboardTemplates({ category_options: filteredSelectedCategories })
}

const SelectedFiltersPill = ({ category, home, loadDashboardTemplates }) => {
  let selectedCategoryOptions = selectedCategoryOptionsByCategory(category, home);
  return <Button bsPrefix="btn btn-secondary bg-secondary h5 h-36 rounded-pill text-black float-start py-1 px-2 me-1"
                 onClick={() => resetCategoryOptionsByCategory(home, category, loadDashboardTemplates)}
                 hidden={isBlank(selectedCategoryOptions)}>
    <span className="pe-1">{category.name}:</span>
    <span className="fw-normal">{toSentence(selectedCategoryOptions)}</span>
    <i className="fas fa-times ms-1" />
  </Button>
}

export default ({ home, org_categories, loadDashboardTemplates }) =>
  <div className="col filter-pills">
    <TemplateUsersFilter {...{ home, loadDashboardTemplates }} />
    {
      org_categories.available_categories.map(category =>
        <SelectedFiltersPill key={`selected-category-${category.slug}`} {...{ home, loadDashboardTemplates, category }} />
      )
    }
    <DSightOnlyPill {...{ home }} resetDSightOnly={() => loadDashboardTemplates({ d_sight_only: false })} />
  </div>
