import React, {Fragment} from "react";
import {DecisionDueDateStyles} from "../../../common/styles/decision_due_date_styles";
import DatePicker from "react-datepicker";
import {isMobile} from "react-device-detect";
import {isBlank, isPresent} from "../../../helpers/common";
import * as moment from "moment";
import CloseIcon from "../../../../../../assets/images/close-icon.svg";

const CancelIcon = ({ onCancelDueDate, dueDate }) => {
  if (isBlank(dueDate)) return null;

  return <a className="icon close-icon no-pointer position-absolute end-0 top-0 me-2 mt-2" onClick={onCancelDueDate}>
    <img src={CloseIcon} alt='close icon' />
  </a>
}

export default ({ dueDate, onChangeDueDate = () => null, onCancelDueDate = () => null, isTemplate = false }) => {
  if (isTemplate) return null;

  return <Fragment>
    <h3>Due date</h3>
    <div className={'d-flex align-items-center mb-3'}>
      <div style={DecisionDueDateStyles.arrowOnInputContainer()} className={'w-100'}>
        <DatePicker className={`form-control bg-white pointer ${isMobile && isPresent(dueDate) ? 'px-1 text-center' : ''} ${isPresent(dueDate) ? '' : 'text-gray'}`}
                    onChange={onChangeDueDate} name="due-date" id="driver-due-date"
                    style={DecisionDueDateStyles.input()}
                    value={`${(isPresent(dueDate) ? `Due by ${moment(dueDate).format('DD MMM, yyyy')}` : 'Select a due date')}`}
                    minDate={new Date()}
                    popperModifiers={[
                      {
                        name: "arrow",
                        options: {
                          padding: { left: 20 }
                        }
                      }
                    ]}
        />
        <CancelIcon {...{ onCancelDueDate, dueDate }} />
      </div>
    </div>
  </Fragment>
}
