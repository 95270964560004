import React, { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { submitTemplateUsers } from "../../store/template/actions";
import { Template } from "../../models/template";
import { ModalDoneFooterP0 } from "../../common/modals";
import CreateAdmissionsInput from "./admissions/CreateAdmissionsInput";
import ViewAdmissionsInput from "./admissions/ViewAdmissionsInput";

export const TemplateUsersModal = ({
                                     template,
                                     shown = false, onClose = () => {},
                                     submitTemplateUsers
                                  }) => {
  const inputRef = useRef({
    resets: {}
  })
  const templateObj = new Template(template)

  const [submit, setSubmit] = useState(false)

  // callbacks
  useEffect(() => {
    if (template.loaded) {
      Object.values(inputRef.current.resets).forEach(callback => callback())
    }
  }, [template.loaded])

  const close = () => onClose()
  const reset = () => {
    Object.values(inputRef.current.resets).forEach(callback => callback())
    setSubmit(false);
    close();
  }
  const submitData = () => {
    setSubmit(true)
    const data = {
      identifiers: inputRef.current.identifiers(),
      decision_admission_identifiers: inputRef.current.decision_admission_identifiers()
    };
    submitTemplateUsers(data, success => {
      setSubmit(false)
      if (success) close();
    })
  }

  return <Modal size="md" backdrop="static" show={shown} onHide={reset}>
    <Modal.Body>
      <CloseIcon onClose={reset} />
      <h2>Edit template users</h2>
      <CreateAdmissionsInput {...{ ref: inputRef, template_admissions: templateObj.template_admissions }} />
      <ViewAdmissionsInput {...{ ref: inputRef, decision_admissions: templateObj.decision_admissions }} />
    </Modal.Body>
    <ModalDoneFooterP0 disabled={submit} onClose={submitData} />
  </Modal>
}
const mapStateToProps = ({ template }) => ({ template });
const mapDispatchToProps = (dispatch) => ({
  submitTemplateUsers: (data, callback) => {
    dispatch(submitTemplateUsers(data, callback))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateUsersModal);
