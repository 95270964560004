import React, {Fragment, useCallback, useState} from 'react';
import { connect } from "react-redux";
import DataSource from "../../models/data_source";
import SourceDescription from "../../tree_view/data_sources/SourceDescription";
import SourceIcon from "../../tree_view/data_sources/SourceIcon";
import UploadedImagesModal from "../../tree_view/modals/UploadedImagesModal";

const SourceItem = ({sourceObj, key = '', ...opts}) => {
  const [showUploadedImagesModal, setShowUploadedImagesModal] = useState(false)
  const openUploadedImagesModal = () => {
    if (!sourceObj.isImage) return null

    setShowUploadedImagesModal(true)
  }

  return <Fragment>
    <div className="mb-2" key={key}>
      <div className="data-source-row">
        <div className="d-flex">
          <a href={sourceObj.contentUrl()} title={sourceObj.contentUrl()} onClick={openUploadedImagesModal}
             target={sourceObj.contentTargetName} className="d-flex px-0 w-100 pointer">
            <div className="d-flex source-icon">
              <div className={`align-text-top fa-layers fa-3x ${sourceObj.iconBlock}`}>
                <SourceIcon {...{ sourceObj }} />
              </div>
            </div>
            <div className="source-description ms-2 my-auto lh-sm">
              <SourceDescription {...{ ...opts, sourceObj }} />
            </div>
            <div className="icon source-link ms-auto my-auto px-2">
              <i className={`${sourceObj.actionIconClass} text-primary fa-lg`} />
            </div>
          </a>
        </div>
      </div>
    </div>
    <div className="modals">
      <UploadedImagesModal shown={showUploadedImagesModal} sourceObj={sourceObj} onClose={() => setShowUploadedImagesModal(false)} />
    </div>
  </Fragment>
}

const mapStateToProps = ({ decision }, { source }) => {
  const sourceObj = new DataSource(source);
  return { sourceObj, users: decision.users }
}
export default connect(mapStateToProps)(SourceItem);
