import React, { useState } from 'react';
import CharCounter from "../../../common/CharCounter";
import { DESCRIPTION_INPUT_LIMIT } from "../../../models/decision";

export default ({ submitted, description, setDescription }) => {
  const [showChar, setShowChar] = useState(false);

  return <>
    <CharCounter show={showChar} field={description} limit={DESCRIPTION_INPUT_LIMIT}  />
    <input className="form-control"
           id="decisionDescription"
           type="text"
           placeholder="Enter the decision question or title"
           value={description}
           disabled={submitted}
           onChange={(e) => setDescription(e.target.value)}
           autoFocus={true}
           autoComplete="off"
           maxLength={DESCRIPTION_INPUT_LIMIT}
           onBlur={() => setShowChar(false)}
           onFocus={() => setShowChar(true)}
    />
  </>
}
