import React from "react";
import { safeDescription } from "../../../helpers/decision_helpers";
import DecisionSet from "../../../models/decision_set";
import { CollaboratorsImages } from "./DecisionTile";
import { isNil } from "ramda";
import BubbleTooltip from "../../../bubble_tooltip/BubbleTooltip";
import { pluralize } from "../../../helpers/common";
import { tileSectionsFlags } from "./helpers";

const decisionSetLink = (decision_set) => `/decision_sets/${decision_set.slug}`

const LinkWrapper = ({ children, decision_set }) =>
  <a href={decisionSetLink(decision_set)} target="_self" className="text-dark text-center">
    {children}
  </a>

const formatCompletion = (decisionSetObject) => {
  const { completedDecisionsNumber, totalDecisionsNumber } = decisionSetObject;

  return `${(completedDecisionsNumber/totalDecisionsNumber * 100).toFixed(0)}%`
}

const TreeCompletionMeter = ({ object }) => {
  const { completedDecisionsNumber, totalDecisionsNumber } = object;
  if(isNil(totalDecisionsNumber) || totalDecisionsNumber === 0 || completedDecisionsNumber === totalDecisionsNumber) {
    return null;
  }
  return <div className="drivers-progress lh-sm float-end text-end">
    <div className="fw-bolder">Progress</div>
    <div className="lh-normal">{formatCompletion(object)}</div>
  </div>
}

const ListRow = ({decision_set}) => {
  const decisionSetObj = new DecisionSet(decision_set)
  const collaborators = decisionSetObj.collaborators

  return <div className="col-12 mb-2">
    <LinkWrapper {...{ decision_set }}>
      <div className="homepage-list-col">
        <div className="d-flex align-items-center list-col-content bg-white p-2">
          <div className="text-start overflow-hidden">
            <div className="text-nowrap text-ellipsis fw-bolder">
              {safeDescription({description: decision_set.name})}
            </div>
            <div className="text-muted">
              { pluralize(decisionSetObj.totalDecisionsNumber, 'decision') }
            </div>
          </div>
          <div className="d-flex ps-2 ms-auto">
            <BubbleTooltip {...{ object_hash: decision_set }} />
            <div className={`collaborators text-end`}>
              <CollaboratorsImages object={decision_set} collaborators={collaborators}/>
            </div>
          </div>
        </div>
      </div>
    </LinkWrapper>
  </div>
}

const DecisionSetTileContent = ({ decision_set, section = '' }) => {
  const decisionSetObj = new DecisionSet(decision_set)
  const collaborators = decisionSetObj.collaborators

  const { isSearch } = tileSectionsFlags(section)

  return <LinkWrapper decision_set={decision_set}>
    <div className="position-absolute top-0 ps-2">
      <i className="text-muted fas fa-list-check fa-lg" />
    </div>
    <div className="set-folder tile-content bg-white pt-3 px-2 pb-2">
      <h4 className="decision-title mt-0">{safeDescription({description: decision_set.name})}</h4>
      <div className={`collaborators mb-3 ${isSearch ? 'align-items-center' : 'text-start px-2 w-100'}`}>
        <CollaboratorsImages object={decision_set} collaborators={collaborators}/>
        { !isSearch && <TreeCompletionMeter object={decisionSetObj} /> }
      </div>
      {
        isSearch &&
        <div className={`h5 rounded-pill bg-light mb-0 w-100`}>
          <span className='text-dark'>
            { pluralize(decisionSetObj.totalDecisionsNumber, 'decision') }
          </span>
        </div>
      }

    </div>
  </LinkWrapper>
}
export default ({ decision_set, isListLine = false, section = '' }) =>
  isListLine ?
    <ListRow decision_set={decision_set} /> :
    <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
      <DecisionSetTileContent {...{ decision_set, section }} />
    </div>
