import React, {useEffect, useState} from 'react';
import {isBlank} from "../../../../helpers/common";
import Recommendation from "../../../../models/recommendation";
import Decision from "../../../../models/decision";
import {addNewChoiceCallback, onChangeChoiceCallback, onPasteChoiceCallback} from "../../../../tree_view/modals/entry_modal/helpers";
import ConsideredChoiceSection from "../../../../tree_view/side_panel/sections/ConsideredChoiceSection";
import { flow } from "../../../../helpers/decision_flows";

const OtherConsideredChoicesInput = ({ flowEnum, decision, choices, setChoices}) => {
  const [show, setShow] = useState(false)
  const [object, setObject] = useState({})
  const [newChoice, setNewChoice] = useState(false)
  const onChangeChoice = onChangeChoiceCallback(choices, setChoices);
  const addNewChoice = addNewChoiceCallback(setNewChoice, choices, setChoices)
  const onPasteChoice = onPasteChoiceCallback(choices, setChoices, setNewChoice, onChangeChoice);
  const prevConsideredChoices = object.considered_choices

  useEffect(() => {
    const decisionObj = new Decision(decision);
    if(flowEnum === flow.D_SIGHT) {
      decisionObj.isOpenEnded && setShow(true)
      setObject(decision)
    } else {
      const recommendation = decision?.recommendation;
      if (isBlank(recommendation) && decisionObj.isOpenEnded) return setShow(true)

      const recommendationObj = new Recommendation(recommendation);
      recommendationObj.isOpenEnded && setShow(true)
      setObject(recommendation)
    }

  }, [flowEnum])

  if (!show) return null;

  return <ConsideredChoiceSection {...{choices, newChoice, onChangeChoice, setNewChoice, addNewChoice, prevConsideredChoices, onPasteChoice }} className={'mb-3'} />
}
export default OtherConsideredChoicesInput;
