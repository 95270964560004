import React, {useRef, useEffect, useState} from 'react';
import { connect } from "react-redux";
import {setDriverInputSidebarOpen, transferDriverFromSidebar, updateDriver} from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import Footer from "./driver_input/Footer";
import DriverTypeOrQuestionRow from "./driver_input/DriverTypeOrQuestionRow";
import AnswerInput from "./driver_input/AnswerInput";
import DetailsInput from "./driver_input/DetailsInput";
import ConfidenceLevelRow from "./driver_input/ConfidenceLevelRow";
import DataSourcesRow from "./driver_input/DataSourcesRow";
import { DSightAnalysis } from "../modals/DriverShowModal";
import Driver from "../../models/driver";
import { findDriverData } from "../accordion_tree/shared/helpers";
import { isMobile } from "react-device-detect";
import { performEditDriverAnswerAction } from "../../helpers/channel_helpers";
import { saveSideBarData } from "../../store/sidebar/common_actions";
import RateDriverChoicesInput from "./driver_input/RateDriverChoicesInput";
import DraftMoreActionsSection from "./sections/DraftMoreActionsSection";
import CommentsBlock from "../driver_entry/CommentsBlock";
import { isBlank } from "../../helpers/common";
import AttributeResponseSection from "./driver_input/AttributeResponseSection";
import {showDriverAssign} from "../../tree_editor/modals/DriverAssignModal";
import EditAssignBtn from "../../common/EditAssignBtn";

const DriverInputRightPanel = ({
                                 sidebar, user, driverData,modal,
                                 transferDriverFromSidebar, saveSideBarData,
                                 updateDriver, isDSight = false,
                                 ...opts
                              }) => {
  const innerRef = useRef(null)
  const [autoFocus, setAutoFocus] = useState(true);
  const assignUser = driverData.driver ? driverData.driver.assign_to_user : '';

  const updateDriverDraftState = () => {
    updateDriver({ slug: driverData.driver.slug, draft: false });
    opts.openSideBar(false)
  }

  const onDone = () => {
    if (innerRef?.current) {
      innerRef?.current?.onDone(updateDriverDraftState)
    } else {
      updateDriverDraftState()
    }
  }

  useEffect(() => {
    if (isBlank(driverData.driver) && sidebar.driverInputSidebar) {
      opts.openSideBar(false)
    }
  }, [isBlank(driverData.driver), sidebar.driverInputSidebar])

  return <SlidingPanel
    type="right"
    isOpen={sidebar.driverInputSidebar}
    panelContainerClassName={`right-sidebar-panel-wrapper driver-input-panel-wrapper driver-panel-width driver-slug-${sidebar.driverSlug}`}
    panelClassName={`right-sidebar-panel-wrapper-content driver-input-panel-content ${isMobile ? '' : 'd-flex flex-column'}`}
    onClose={transferDriverFromSidebar}
    onClosed={() => {
      setAutoFocus(true);
      if(!sidebar.chatGptDriverSummarizerSidebar && !sidebar.driverFilesSummarizerSidebar) {
        saveSideBarData({drivers: [], data_sources: [], driverSlug: null, trigger: null, isOpened: false});
      }
      if (driverData.driver) {
        performEditDriverAnswerAction(user, driverData.driver.slug, false);
      }
    }}
    onOpened={() => saveSideBarData({ isOpened: true })}
  >
    {
      driverData.driver && <>
        <DriverTypeOrQuestionRow driverData={driverData} isDSight={isDSight} {...opts} >
          <EditAssignBtn assignUser={assignUser} openAssignModal={() => showDriverAssign(modal)} modalType='DriverAssignModal' isDSight={isDSight} />
          <div className="mx-2">
            <DraftMoreActionsSection driverData={driverData} />
          </div>
        </DriverTypeOrQuestionRow>
        <AnswerInput driverData={driverData} ref={innerRef} {...{autoFocus, setAutoFocus}} />
        <RateDriverChoicesInput driverData={driverData} />
        <DetailsInput driverData={driverData} ref={innerRef} {...{setAutoFocus}} />
        <ConfidenceLevelRow driverData={driverData} />
        <AttributeResponseSection driverData={driverData} className='px-3 mb-3' />
        <DSightAnalysis driverObj={new Driver(driverData.driver)}
                        driverKpis={true} addClass='px-3'
                        {...opts}
                        keyPreffix={'driver-input-left-panel'} />
        <DataSourcesRow driverData={driverData} addClass='px-3' isDriverPanel={true}  {...{setAutoFocus}} />
        <CommentsBlock {...{ ref: innerRef, isDriverPanel: true, trigger: sidebar.trigger,
                             driver: driverData.driver, setAutoFocus }} />
        <Footer driverData={driverData} addClass='px-3' onClose={onDone} />
      </>
    }
  </SlidingPanel>
}

const mapStateToProps = ({ sidebar, decision, current_user, modal }) => ({
  sidebar, decision, user: current_user, modal,
  driverData: findDriverData(sidebar, sidebar.driverSlug || '')
});
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => { dispatch(setDriverInputSidebarOpen(options)) },
  transferDriverFromSidebar: () => { dispatch(transferDriverFromSidebar()) },
  saveSideBarData: (data = {}) => { dispatch(saveSideBarData(data)) },
  updateDriver: (data) => { dispatch(updateDriver(data)) }
});
export default connect(mapStateToProps, mapDispatchToProps)(DriverInputRightPanel);
