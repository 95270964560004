import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import {isBlank, isEmail, uniqueBy} from "../../../helpers/common";
import { loadContacts } from "../../../store/contacts/actions";
import CollaboratorsInviteRow from "../../../tree_wizard/steps_wizard/steps/CollaboratorsInviteRow";
import { Typeahead } from 'react-bootstrap-typeahead';
import { isGroupContact } from "../../../helpers/decision_helpers";
import { saveWizardStepData } from "../../../store/wizard/actions";
import { updateTreeData } from "../../../store/tree/common_actions";
import { CollaboratorsPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";
import {
  checkSetInvitesEffect,
  stepKeyWithInvites, stepWithDrivers,
  wizardStepDataBy
} from "../../../helpers/wizard_helpers";
import {isEnter} from "../../../helpers/keys_helpers";
import { saveDriversCallback } from "../../steps_wizard/steps/helpers/tree_builder_step";
import { DEFAULT_TYPEHEAD_ATTRIBUTES } from "../../../tree_view/modals/helpers/share_helpers";
import { renderContactItem } from "../../../tree_view/side_panel/helpers/collaborators_helpers";
import { onChangeInvites, renderTag } from "../../steps_wizard/steps/helpers/collaboration_helpers";
import {Button} from "react-bootstrap";
import {fetchAddSetMembers} from "../../steps_wizard/steps/CollaboratorsDecisionStep";

export const submitSetMembersCallback = (submitSetMembers, submitInvite, setSubmitSetMembers, invites) => {
  useEffect(() => {
    const callback = () => setSubmitSetMembers(false)
    if (submitSetMembers) {
      submitInvite(invites, callback)
    }
  }, [submitSetMembers])
}

export const CollaboratorsSection = ({
                                       wizard, decision, playbook_notes, contactsData,
                                       loadContacts, saveWizardStepData, updateTreeData,
                                       decision_set
                                     }) => {
  if (isBlank(decision.slug)) return null;

  const ref = React.createRef();
  const [invites, setInvites] = useState([])
  const filteredInvites = invites.filter(invite => invite.email !== decision.user.email)
  const [submitSetMembers, setSubmitSetMembers] = useState(false)

  checkSetInvitesEffect(wizard, contactsData, loadContacts, setInvites)

  const filterSetInvitesToAdd = fetchAddSetMembers(decision, decision_set, invites)

  const addSetMembers = () => {
    setInvites([...invites, ...filterSetInvitesToAdd])
    setSubmitSetMembers(true)
  }

  const removeInvite = (invite) => {
    const filteredInvites = invites.filter(i => i.email !== invite.email)
    setInvites(filteredInvites)
    const mergeData = decision.assigned_collaborator_email === invite.email ? { assigned_collaborator: '' } : {}
    submitInvite(filteredInvites, (status, wizardData) => {
      if (status) {
        const drivers = wizardStepDataBy(wizardData, stepWithDrivers(wizard)).drivers
        const saveDrivers = saveDriversCallback(updateTreeData, drivers)
        // updateTreeData({ loaded: false, loading: false })
        saveDrivers()
      }
    }, mergeData);
  }

  const submitInvite = (filteredInvites = invites, callback = () => {}, mergeData = {}) => {
    saveWizardStepData(stepKeyWithInvites(wizard), {
      ...mergeData,
      response: { step_index: wizard.step_index },
      collaborators: uniqueBy(filteredInvites, 'email').map(({ email }) => email),
      finish_later: true
    }, callback)
  }

  const filteredContacts = () => {
    return contactsData.contacts.filter(contact => {
      if (isGroupContact(contact)) return true;
      if (contact.value === decision.user.email) return false;

      return !invites.some(invite => invite.email === contact.value);
    })
  }
  const onKeyDown = (e) => {
    if (isEnter(e.keyCode)) {
      const value = ref.current.getInput().value;
      if (isEmail(value)) {
        onChangeInvites([value], invites, setInvites, contactsData, submitInvite)
        ref.current.clear()
      }
    }
  }
  const InputField = () => {
    return <div className="d-flex flex-column flex-sm-row mt-2">
      <div className="w-100">
        <Typeahead
          {...DEFAULT_TYPEHEAD_ATTRIBUTES} allowNew
          id="collaboration-invite-input" labelKey="value"
          ref={ref} selected={[]}
          onChange={(selected) => onChangeInvites(selected, invites, setInvites, contactsData, submitInvite)}
          options={filteredContacts()}
          onKeyDown={onKeyDown}
          renderMenuItemChildren={renderContactItem}
          renderToken={(option, events, index) => renderTag(option, events, index, contactsData)}
        />
      </div>
    </div>;
  }

  submitSetMembersCallback(submitSetMembers, submitInvite, setSubmitSetMembers, invites)
  return <div className="px-3">
    <CollaboratorsPBSection playbook_notes={playbook_notes} sidePanelStyle={true} showNA={false} />
    <CollaboratorsInviteRow user={ decision.user } email={decision.user.email} slug={'decider'} />
    { filteredInvites.map(invite => <CollaboratorsInviteRow {...invite} key={`invite-row-${invite.slug}`} onRemoveClick={() => removeInvite(invite)} />) }
    <Button onClick={addSetMembers} hidden={isBlank(filterSetInvitesToAdd)} disabled={wizard.submit || submitSetMembers} className="btn-secondary w-100 mt-1">
      Add all decision flow members
    </Button>
    <InputField />
  </div>
}
const mapStateToProps = ({ wizard, decision, contacts, playbook_notes, decision_set }) => ({
  wizard, decision, playbook_notes, contactsData: contacts, decision_set
});
const mapDispatchToProps = (dispatch) => ({
  loadContacts: () => {
    dispatch(loadContacts());
  },
  saveWizardStepData: (step, data, callback) => {
    dispatch(saveWizardStepData(step, data, callback));
  },
  updateTreeData: (data) => dispatch(updateTreeData(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorsSection);
