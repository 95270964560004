import React from "react";
import SourceItem from "./Item";

export const DataSourcesList = ({ report,
                                  data_sources, hideMenu= false,
                                  uploadingFile,
                                  ...opts
                                }) => {
  return data_sources.map(data_source =>
    <SourceItem source={data_source}
                users={report.users}
                forceEditMode={data_source.just_created}
                hideMenu={hideMenu}
                dropFilesFormWrapper={'report_data_source'}
                key={`report-data-source-${data_source.slug}-${data_source.key}`}
                { ...opts }
    />
  )
}
