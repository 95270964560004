import React, { useRef } from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../../../helpers/common";
import { JoditTextEditor } from '../../../../../common/JoditTextEditor';
import { flow } from "../../../../../helpers/decision_flows";

const OpenEndedChoices = ({ decision, openEndedChoice, setOpenEndedChoice, flowEnum }) => {
  if (isBlank(decision)) return null;

  const focusElement = useRef(null);

  return <JoditTextEditor className="jodit-container-default"
                          ref={focusElement}
                          value={openEndedChoice}
                          placeholder={
                            flowEnum === flow.D_SIGHT_RECOMMENDATION ?
                              'Enter the recommendation' :
                              'Enter the decision and resulting actions'
                          }
                          setValue={setOpenEndedChoice}
                          autofocus={false} />
};

const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, {} )(OpenEndedChoices);
