import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateHomepageCurrentTab } from "../../store/homepage/actions";
import { hideCreateDecisionTab, isCreateDecisionCenter, TABS, TABS_URLS } from "../../helpers/home_helpers";

export const CreateDecisionItem = ({
                                     displayCurrent,
                                     home, current_user,
                                     updateHomepageCurrentTab
                                  }) => {
  if (hideCreateDecisionTab(current_user)) return null;

  return <li className={displayCurrent && isCreateDecisionCenter(home, current_user) ? 'active' : ''}>
    <Link to={`/homepage/${TABS_URLS.create_decision}`}
          onClick={() => updateHomepageCurrentTab(TABS.create_decision)}
          className="text-nowrap">
        <span>
          <span className="icon d-inline-block text-center">
            <i className="fas fa-plus fa-lg" />
          </span>
          <span className="d-none d-lg-inline tab-title">Create decisions</span>
        </span>
    </Link>
  </li>
}

const mapStateToProps = ({ home, current_user }) => ({ home, current_user });
export default connect(mapStateToProps, { updateHomepageCurrentTab })(CreateDecisionItem);
