import {
  chartSignificanceChangeValueFor,
  chartValueFor,
  filteredProcessData,
  findNodeValue,
  sortBrands
} from "../../../../../models/d_sight/metric_chart_helpers";
import { uniqArray } from "../../../../../helpers/common";

export const sortBrandsBySubMetrics = (d_sight_goal, subMetrics) =>
  sortBrands(d_sight_goal.target.name,
    uniqArray(
      subMetrics.flatMap(m => filteredProcessData(m, d_sight_goal).map(p => findNodeValue(p.nodes)))
    )
  )

export const significanceHashData = (lastSurvey, brandProcessData, mainBrandProcessData, metric = {}) => ({
  curValue: chartValueFor(lastSurvey, brandProcessData, metric),
  mainValue: chartValueFor(lastSurvey, mainBrandProcessData, metric),
  curSignificance: chartSignificanceChangeValueFor(lastSurvey, brandProcessData),
  mainSignificance: chartSignificanceChangeValueFor(lastSurvey, mainBrandProcessData)
})
