import React from "react";
import {ThreeDots} from "react-loader-spinner";

export default ({text='CloverpopAI is typing', addClass = ''}) =>
  <div className={`d-inline-flex align-items-center ${addClass}`}>
    <ThreeDots
      height="30"
      width="30"
      radius="5"
      color="#8A949F"
      ariaLabel="three-dots-loading"
      visible={true}
    />
    <div className='text-muted ms-1'>{text}</div>
  </div>
