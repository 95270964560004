import React from 'react';
import Button from "react-bootstrap/Button";
import { isBlank } from "../../../helpers/common";

export default ({ home, resetDSightOnly }) =>
  <Button bsPrefix="btn btn-secondary bg-secondary h5 h-36 rounded-pill text-black float-start py-1 px-2 me-1"
          onClick={resetDSightOnly}
          hidden={isBlank(home.d_sight_only)}>
    <span className="pe-1">D-Sight only</span>
    <span className="fw-normal" />
    <i className="fas fa-times ms-1" />
  </Button>
