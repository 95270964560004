import React, {useMemo} from "react";
import {isCollaborator, isDecider} from "../../../helpers/user_helpers";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import {setPollPanelOpen} from "../../../store/sidebar/actions";
import {collectChatMessages, RESPONSE_TYPE} from "../../../helpers/discussion_helpers";
import ChoicePollRow from "./ChoicePollRow";
import CommentsBlock from "./CommentBlock";
import {isBlank} from "../../../helpers/common";
import {getOrdinal, rankedByUsersCallback} from "../../../decision_state/body/ChoicesSection";

const RankedChoicePollRow = ({choice, index, isTie = false}) => {
  const rankedByUsers = useMemo(()=> rankedByUsersCallback(choice), [choice.votes])

  return <div className="mb-3" key={`choice-${choice.slug}`}>
    <div className='d-flex fw-bold mb-2'>
      <div className='me-2'>{choice.description}</div>
      <div className="ms-auto text-nowrap" hidden={isBlank(rankedByUsers)}>
        {getOrdinal(index)}{isTie ? ' (tie)' : ''}
      </div>
    </div>
    <div className={`supported-users text-muted`} hidden={isBlank(rankedByUsers)}>
      Ranked by {rankedByUsers}
    </div>
  </div>
}

const ExpandedPollContent = ({ decision, setPollPanelOpen, usersChoicesVotes, object, user, sidebar, hideEdit = false }) => {
  const messages = collectChatMessages(decision).filter(m => m.type === RESPONSE_TYPE);
  const onOpenPollPanel = () => {
    if(!isDecider(user) && !isCollaborator(user)) return null;

    setPollPanelOpen(!sidebar.pollPanelSidebar)
  }

  const RankedChoices = () => {
    let index = 1;
    let prevRankPoints = usersChoicesVotes[0].rank_points;
    return usersChoicesVotes.map((choice) => {
      const isTie = choice.rank_points > 0 && usersChoicesVotes?.filter(c => c.rank_points === choice.rank_points).length > 1

      if (choice.rank_points !== prevRankPoints) {
        index = index + 1;
        prevRankPoints = choice.rank_points
      }

      return <RankedChoicePollRow key={`choice-${choice.slug}`} {...{choice, index, isTie}} />
    });
  }

  const NotRankedChoices = () => usersChoicesVotes.map(choice =>
    <ChoicePollRow {...{key: choice.slug, choice, usersChoicesVotes, object}} />
  )

  return <div id={'poll-card'}>
    <h4>{decision.description}</h4>
    {object.isRankedList ? <RankedChoices /> : <NotRankedChoices />}
    <CommentsBlock messages={messages} isHideLike={true} />
    <Button hidden={hideEdit} onClick={onOpenPollPanel} className="btn btn-secondary w-100">
      Take poll
    </Button>
  </div>
}

const mapStateToProps = ({ current_user, sidebar }) => ({sidebar, user: current_user});
const mapDispatchToProps = (dispatch) => ({
  setPollPanelOpen: (value) => dispatch(setPollPanelOpen(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ExpandedPollContent);