import React, {useRef} from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { attachEventEffect } from "../../../../helpers/html_events_helpers";
import { removeDecisionFromSet } from "../../../../store/decision_set/actions";
import { removeFromTemplateSet } from "../../../../store/template/actions";
import { deleteDecisionFromSetWarning } from "../../../../helpers/alert_helpers";
import { isUnstartedDecision } from "../../../../models/decision_state";

const RemoveDecision = ({ treeNode, removeDecisionFromSet, removeFromTemplateSet, isTemplateSet = false }) => {
  const ref = useRef();
  const onRemove = () => {
    if(isTemplateSet) {
      removeFromTemplateSet(treeNode.slug)
    } else {
      deleteDecisionFromSetWarning(() => removeDecisionFromSet(treeNode.slug), !isUnstartedDecision(treeNode.node))
    }
  }
  attachEventEffect(ref, onRemove)

  return <Button className="btn btn-light btn-sm btn-sm-round bg-light-gray text-danger" ref={ref}>
    <i className="fa fa-solid fa-lg fa-times bg-light-gray text-danger fa-2x w-100"/>
  </Button>
}
export default connect(null, { removeDecisionFromSet, removeFromTemplateSet })(RemoveDecision);
