import React from 'react';
import { Image, Page, View, Text } from '@react-pdf/renderer';
import styles from '../../styles/common'
import imageStyles from '../../styles/images'
import coverStyles from '../../styles/cover'
import { protectedImageSrc } from "./UserImage";

export const BlockSeparate = ({viewStyles = [styles.mb2]}) =>
  <View style={[imageStyles.cover_light, { height: 2 }, viewStyles]}/>

export default ({ decision, org, format }) =>
  <Page size={format}>
    <View style={coverStyles.body}>
      <View style={coverStyles.center} >
        <BlockSeparate viewStyles={[styles.mb3]} />
        <Text style={[coverStyles.title, {overflow: 'hidden'}]}>{decision.description}</Text>
        <BlockSeparate viewStyles={[styles.mb3]} />
      </View>
      <View  style={[imageStyles.alignCenter, {marginTop: 26}]}>
        <Image style={[imageStyles.cover_footer_image]} src={protectedImageSrc(org.logo_horizontal)}/>
      </View>
    </View>
    <View fixed style={[imageStyles.alignCenter, coverStyles.footer]}>
      <Text style={[styles.text, styles.muted]}>Cloverpop decision memo</Text>
    </View>
  </Page>
