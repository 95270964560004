import React, {useEffect, useRef} from "react";
import {PLACEHOLDER} from "../../driver_entry/AddCommentForm";
import DiscussionMentionsInput from "../discussion/DiscussionMentionsInput";
import {mentioneeWarning} from "../../../helpers/alert_helpers";
import {connect} from "react-redux";
import {reloadContacts} from "../../../store/contacts/actions";

const VotesComment = ({votesFeedback, setVotesFeedback, isFocusInput = true, submit, setSubmit, saveVotesCallback}) => {
  const onChange = ({userMessage}) => setVotesFeedback(
    Object.assign({}, votesFeedback, {
      description: userMessage,
      mentions: ref.current.newMentioned().map(h => h['email']),
      all_new_mentions: ref.current.mentioned().map(h => h['email'])
    })
  )

  useEffect(() => {
    if (submit) {
      mentioneeWarning(ref.current.newMentioned(),make_mentionee_participant => {saveVotesCallback(make_mentionee_participant, ref.current)})
      setSubmit(false)
    }
  }, [submit])

  const ref = useRef(null);

  return <div className='mx-3 mt-2 mb-3'>
    <h3>Comments</h3>
    <div className="w-100">
      <DiscussionMentionsInput
        ref={ref}
        placeholder={PLACEHOLDER}
        initialValue={votesFeedback?.description}
        isFocusInput={isFocusInput}
        onChange={onChange} />
    </div>
  </div>
}
export default connect(null, {reloadContacts})(VotesComment);
