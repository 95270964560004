import React, {useEffect, useState} from 'react';
import DetailsItem from "./DetailsItem";
import CollaboratorsItem from "./CollaboratorsItem";
import PlaybookItem from "./PlaybookItem";
import CommentsItem from "./CommentsItem";
import HelpItem from "./HelpItem";
import DecisionTreeItem from "./DecisionTreeItem";
import MoreActionsItem from "./MoreActionsItem";
import DecisionSetItem from "../../template_set_view/navigation/DecisionSetItem";
import RatingsAndWeightsItem from "./RatingsAndWeightsItem";
import { TREE_MODES } from "../../helpers/drivers_helpers";
import { uniqueBy } from "../../helpers/common";
import { collectCollaborationInvites } from "../../helpers/decision_helpers";
import DataSourcesItem from "./DataSourcesItem";
import {decisionDataSources} from "../../models/decision";
import {DecisionOrDSightDataSourcesTypes, LinkedReportTemplateDataSourcesType} from "../../models/data_source";

const NavigationItems = ({ hideMoreSection = true, decision, current_user, showDecisionSet = false,
                           hideDataSources = true, isDsight = false, tree }) => {
  const decisionInvites = decision && uniqueBy(collectCollaborationInvites(decision), 'email') || [];
  const collaboratorsInvitesLength = decisionInvites?.length + 1 || 0;
  const [sortedRelatedDataSources, setSortedRelatedDataSources] = useState([]);

  useEffect(() => {
    if (tree?.loaded && tree.data_sources.length > 0) {
      const available_tree_data_sources = tree.data_sources.filter((source) => !LinkedReportTemplateDataSourcesType.includes(source.type))
      setSortedRelatedDataSources(decisionDataSources(available_tree_data_sources || [], tree.drivers, DecisionOrDSightDataSourcesTypes))
    }}, [tree?.data_sources, tree?.loaded]);

  const isHiddenDataSources = hideDataSources && sortedRelatedDataSources.length === 0;

  return <>
    <div className="sidebar-top">
      <ul className="list-unstyled m-0 p-3">
        <RatingsAndWeightsItem />
        <DetailsItem />
        <CollaboratorsItem {...{collaboratorsInvitesLength}}/>
        {
          !isHiddenDataSources && <DataSourcesItem/>
        }
        {isDsight ? null : <DecisionTreeItem treeMode={TREE_MODES.view} />}
        <PlaybookItem />
        <CommentsItem />
        {
          showDecisionSet && <DecisionSetItem />
        }
        {
          !hideMoreSection && <MoreActionsItem/>
        }
      </ul>
    </div>
    <div className="sidebar-bottom">
      <ul className="list-unstyled m-0 p-3">
        <HelpItem />
      </ul>
    </div>
  </>
}

export const SidebarTreeNavigation = (opts) =>
  <div id="sidebar-navigation" className="tree-sidebar bg-light-gray d-none d-lg-block">
    <NavigationItems {...opts} />
  </div>

export default SidebarTreeNavigation;
