/**
 *
 * @param default_options
 * @param options
 * @return {swal}
 */
const show = (default_options, options) => {
  const $options = $.extend(default_options, options);
  return swal($options);
};

const colors = {
  red: "#f37041",
  blue: "#27a7dc",
  primary: "#2487BF",
  light: "#F0F6FC",
  dark: "#1F2F3F"
};

const info_params = (type) => {
  return {
    title: "",
    text: "",
    type: type,
    buttonsStyling: false,
    animation: false,
    focusConfirm: false,
    focusCancel: false,
    confirmButtonClass: "btn btn-primary bg-primary mr-1 me-1 border-0 shadow-none",
    cancelButtonClass: "btn btn-light bg-light ml-1 ms-1 text-dark border-0 shadow-none"
  }
};

class Alerts {
  static get colors() {
    return colors
  }

  static get swal() {
    return swal
  }

  /**
   * @param {Object} options
   * @return {swal}
   */
  static warning(options) {
    return show({
      title: "",
      text: "",
      html:"",
      type: "warning",
      showCancelButton: true,
      buttonsStyling: false,
      animation: false,
      focusConfirm: false,
      focusCancel: false,
      confirmButtonClass: "btn btn-primary bg-primary mr-1 me-1 border-0 shadow-none",
      cancelButtonClass: "btn btn-light bg-light ml-1 ms-1 text-dark border-0 shadow-none",
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }, options);
  };

  static warningDeleteTemplate(options) {
    let alertActions =  show({
      title: "",
      text: "",
      html: getWarningHtmlThreeBtn(),
      type: "warning",
      showConfirmButton: false,
      showCancelButton: false,
      buttonsStyling: false,
      animation: false,
    }, options);

    const buttons = [
      { id: 'swal-button-delete', action: 'deleteAll' },
      { id: 'swal-button-ungroup', action: 'ungroup' },
      { id: 'swal-button-cancel', action: 'cancel' }
    ];
    addEventListeners(buttons, options);

    return alertActions;
  }

  static question(options) {
    return show({
      title: "",
      text: "",
      type: "question",
      html:"",
      showCancelButton: false,
      showConfirmButton: false
    }, options);
  };

  static warningWithOk(options) {
    return show({
      title: "",
      text: "",
      type: "warning",
      buttonsStyling: false,
      animation: false,
      focusConfirm: false,
      focusCancel: false,
      confirmButtonClass: "btn btn-primary bg-primary mr-1 me-1 border-0 shadow-none",
      cancelButtonClass: "btn btn-light bg-light ml-1 ms-1 text-dark border-0 shadow-none",
      confirmButtonText: "OK"
    }, options);
  };

  static confirm(options) {
    return show({
      title: "",
      text: "",
      showCancelButton: true,
      buttonsStyling: false,
      animation: false,
      focusConfirm: false,
      focusCancel: false,
      confirmButtonClass: "btn btn-primary bg-primary mr-1 me-1 border-0 shadow-none",
      cancelButtonClass: "btn btn-light bg-light ml-1 ms-1 text-dark border-0 shadow-none",
      confirmButtonText: "OK"
    }, options);
  };

  static success(options) {
    return show(info_params("success"), options);
  };

  static error(options) {
    return show(info_params("error"), options);
  };

  static warningListener(options) {
    const Class = this;
    return function(e) {
      const $elem = $(this);
      if ($(this).attr('disabled')) {
        Cloverpop.stubLinkClick(e);
        return false;
      }
      if ($(this).data('confirmed')) {
        $elem.data('confirmed', false);
        return e;
      }
      Cloverpop.stubLinkClick(e);
      Class
        .warning(options)
        .then(function(confirmed) {
          if (!confirmed) return;
          $elem.data('confirmed', true);
          $elem.trigger('click');
        });
      return e;
    };
  }

  static deleteWarning(selector, options, $container, listener, $) {
    const Class = this;
    $container = $container || document;
    listener = listener || function() {};
    $ = $ || jQuery;
    return $($container).off('ajax:before', selector).on('ajax:before', selector, function(e) {
      const local_self = $(this);
      if (local_self.hasClass('confirmed')) {
        local_self.removeClass('confirmed');
        return true;
      }

      Cloverpop.stubLinkClick(e);

      listener.call(local_self, options);
      Class.warning(options).then((isConfirm) => {
        if (isConfirm) local_self.addClass('confirmed').click();
      }, () => {});

      return false;
    }).on('ajax:send', selector, function() {
      $(this).attr('disabled', 'disabled');
    });
  };
}

function addEventListeners(buttons, options) {
  buttons.forEach(({ id, action }) => {
    document.getElementById(id).addEventListener('click', function() {
      options.callback(action);
      swal.close();
    });
  });
}

function getWarningHtmlThreeBtn() {
  return `
    <p>Select “Delete all” to delete the decision flow and all associated decision templates. Select 
       “Ungroup decisions” to delete the decision flow but keep the decision templates.</p>
    <div class="swal-buttons d-flex justify-content-center">
      <button id="swal-button-delete" class="btn btn-primary bg-primary ml-1 ms-1 border-0 shadow-none">Delete all</button>
      <button id="swal-button-ungroup" class="btn btn-primary bg-primary ml-1 ms-1 border-0 shadow-none">Ungroup decisions</button>
      <button id="swal-button-cancel" class="btn btn-light bg-light ml-1 ms-1 text-dark border-0 shadow-none">Cancel</button>
    </div>
  `;
}

window.sweetAlertConfirmConfig = {
  buttonsStyling: false,
  animation: false,
  focusConfirm: false,
  focusCancel: false,
  confirmButtonClass: "btn btn-primary bg-primary mr-1 me-1 border-0 shadow-none"
};

export default Alerts;
