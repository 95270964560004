import { isBlank, isPresent } from "./common";

export const setSectionSlugParam = (document) => {
  const getParams = new URLSearchParams(document.location.search);
  const sectionSlug = getParams.get('section_slug');
  return isPresent(sectionSlug) ? `?section_slug=${sectionSlug}` : ''
}

export const getSectionSlugFromParam = (document) => {
  const getParams = new URLSearchParams(document.location.search);
  return getParams.get('section_slug');
}

export const isSummaryPending = (set) => isBlank(set.summary_entered_at)
