import React from 'react';

export default () =>
  <>
    <p>
      Decision flows allow grouping decisions by process, topic, or flow.
    </p>
    <p>
      Templates can be added, removed, or sorted. To show a sequence of decisions, sort the templates and go to the Details panel to display numbers.
    </p>
    <p>
      Add, remove, or sort sections to create and manage sub-groupings or parallel tracks for decisions.
    </p>
  </>
