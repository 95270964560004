import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import StyledDropzone from './DropFiles';
import { AddLinkRow } from '../../tree_view/data_sources';
import { DataSourcesList } from "./DataSourcesList";
import { UploadingSourcesList } from "../../template_view/side_panel/sections/DataSourcesSection";
import { generateBatchDataSourceUploadState } from "../../helpers/uploads_callbacks";
import Button from "react-bootstrap/Button";
import {qSortArray} from "../../helpers/common";
import { linkedForecastScenarioDataSources } from "../../models/decision";
import AddForecastScenarioSelector from "../../tree_view/side_panel/sections/data_sources/AddForecastScenarioSelector";

const DataSources = ({
                       report,
                       current_user,
                       dataSources, uploadingSources, saveUploadingSources,
                       orgForecastScenarios,
                       createSource,
                       ...opts
                     }) => {
  const filterSourcesCriteria = (source) => !source.deleted && !source.hidden_on_wizard;
  const sorterDataSources = qSortArray(dataSources.filter(filterSourcesCriteria), true, (s) => s.created_at);
  const uploadOptions = generateBatchDataSourceUploadState(sorterDataSources, uploadingSources, saveUploadingSources, current_user)
  const [showDropzone, setShowDropzone] = useState(false);
  const focusDropzone = useRef(null);

  const scrollToBottom = () => {
    focusDropzone.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    scrollToBottom()
  }, [showDropzone])

  const submitLink = (value, callback) => {
    let createParams = { report_data_source: { link_url: value } };
    createSource(createParams, {}, callback);
  }

  const onSelectScenario = ({ value }) => {
    let createParams = { report_data_source: { scenario_id: value } };
    createSource(createParams, {});
  }

  return <Fragment>
    <div className="mb-0">
      <h3 htmlFor="driverDataSources">Links & files</h3>
      <ul className="list-group">
        <DataSourcesList report={report} data_sources={sorterDataSources} user={current_user} {...uploadOptions} {...opts} />
        <UploadingSourcesList isReport={true} isTemplate={false}
                              uploading_sources={uploadingSources} user={current_user}
                              {...uploadOptions} {...opts} />
      </ul>
    </div>
    <AddLinkRow onClick={submitLink} />
    <Button className="btn btn-secondary w-100 mt-2"
            onClick={() => setShowDropzone(true)}
            hidden={showDropzone}>
      Add files
    </Button>
    <div className={`mt-2`} hidden={!showDropzone} ref={focusDropzone}>
      <StyledDropzone onCreateSource={createSource}
                      onStartUpload={uploadOptions.onStartUpload}
                      onFinishUpload={uploadOptions.onFinishUpload}
                      onProcessUpload={uploadOptions.onProcessUpload} />
    </div>
    <AddForecastScenarioSelector {...{
      orgForecastScenarios,
      selectedSource: null,
      onSelectScenario
    }} />
  </Fragment>
};

const mapStateToProps = ({ report, current_user, current_org }) => {
  const reportDataSources = report.data_sources || [];
  const uploadingSources = report.uploading_sources || [];

  const allLinkedForecastScenarios = linkedForecastScenarioDataSources(reportDataSources);
  const allLinkedForecastScenariosSlugs = allLinkedForecastScenarios.map((source) => source.scenario_id)
  const orgForecastScenarios = current_org.forecast_simulator_scenarios.filter((r) => !allLinkedForecastScenariosSlugs.includes(r.id))

  return {
    report, current_user, uploadingSources,
    dataSources: reportDataSources,
    orgForecastScenarios
  }
};
export default connect(mapStateToProps)(DataSources);

