import React from 'react';
import { findNodeValue, sortBrands } from "../../../../../models/d_sight/metric_chart_helpers";
import { uniqArray } from "../../../../../helpers/common";
import { prepareChartData } from "../helpers/line_helpers";
import OverTimeChart from "../components/OverTimeChart";
import LastPeriodChart from "../components/LastPeriodChart";

export default ({
                  d_sight_goal, process_data, surveys, timeNode,
                  metric, viewMetric,
                  ...opts
                }) => {
  const sortedNodeNames = sortBrands(
    d_sight_goal.target.name,
    uniqArray(process_data.map(p => findNodeValue(p.nodes, metric.node_index)))
  )

  const preparedData = prepareChartData({
    process_data, surveys, timeNode, sortedNodeNames, d_sight_goal, metric
  })

  return <>
    <OverTimeChart metric={viewMetric || metric} {...{...opts, ...preparedData, timeNode, d_sight_goal}} />
    <LastPeriodChart metric={viewMetric || metric}
                     {...{...opts, ...preparedData, timeNode, d_sight_goal, sortedNodeNames}}  />
  </>;
}
