import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { connect } from "react-redux";
import SignInHeader from "./header"
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button';
import { isBlank, isEmail } from "../helpers/common";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import { updateSignInUserData } from "../store/sign_in/actions";

const ResetPassword = ({ signIn, updateSignInUserData }) => {
  const [email, setEmail] = useState(signIn.email)
  const [submit, setSubmit] = useState(false)

  const submitForm = useCallback((e) => {
    e.preventDefault();
    setSubmit(true)
    formRef.current.submit();
    return false;
  }, [])
  const cacheEmail = useCallback(() => updateSignInUserData(email), [email])
  const authenticityToken = useMemo(() =>
    document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  , [])

  const formRef = useRef(null)

  return <Fragment>
    <SignInHeader />
    <Container>
      <div className="bg-white rounded p-3 my-3 mx-auto sign_in">
        <div className="header text-center">
          <h2>Reset password</h2>
        </div>
        <form action={Routes.submit_reset_password} method={`post`} acceptCharset={`UTF-8`} onSubmit={submitForm} ref={formRef}>
          <input type={`hidden`} name={'authenticity_token'} value={authenticityToken}/>
          <div className="row">
            <div className="col">
              <div className="mb-2">
                <input id="user-email" name="user[email]" type={`email`}
                       className={`form-control rounded`}
                       placeholder={`Email address`} value={email} autoFocus={true}
                       readOnly={submit} onBlur={cacheEmail}
                       onChange={(e) => { setEmail(e.target.value) }}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="mb-3">
                <Button type="submit" className="btn btn-primary w-100"
                        onClick={submitForm} disabled={isBlank(email) || !isEmail(email) || submit}>
                  Send reset password instructions
                </Button>
              </div>
            </div>
          </div>
        </form>
        <div className="row" >
          <div className="col">
            <Link to={Routes.login_link} className={`pointer`}>
              Send log-in link
            </Link>
          </div>
          <div className="col text-end">
            <Link to={Routes.sign_in} className={`pointer`}>
              Log in
            </Link>
          </div>
        </div>
      </div>
    </Container>
  </Fragment>
}
const mapStateToProps = ({ signIn }) => ({ signIn });
const mapDispatchToProps = (dispatch) => ({
  updateSignInUserData: (email) => {
    dispatch(updateSignInUserData({ email }))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
