import React from 'react';
import { connect } from "react-redux";
import { TotalDecisionsChart, TotalDecisionsName } from "./sections/TotalDecisions";
import { AvgCollaboratorsChart, AvgCollaboratorsName } from "./sections/AvgCollaborators";
import { ActivityByPersonSection } from "./sections/ActivityByPerson";
import { AvgDecisionTimeChart, AvgDecisionTimeName } from "./sections/AvgDecisionTime";
import { TreeCompletionChart, TreeCompletionDetails, TreeCompletionName } from "./sections/TreeCompletion";
import { DecisionsSuccessChart, DecisionsSuccessName } from "./sections/DecisionsSuccess";
import { DecisionsResultsChart, DecisionsResultsName } from "./sections/DecisionsResults";
import {isBlank, isPresent} from "../../helpers/common";

export const StatisticSection = ({ children }) =>
  <div className={'row mb-3'}>
    <div className={`col col-xs-12`}>
      <div className={'statistic-section-max-h bg-white rounded p-3'}>
        {children}
      </div>
    </div>
  </div>

const StatisticChartSection = ({ chart, name, details, textInCenter=true }) =>
  <>
    <div className={'row mb-3'}>
      <div className={`col-xs-12 col-xl-4 mb-2 mb-xl-0`}>
        <div className={`${textInCenter ? 'd-flex align-items-center' : ''} h-100 bg-white rounded p-3`}>
          <div className={`mx-auto ${textInCenter ? 'text-center' : ''}`}>
            {name}
          </div>
        </div>
      </div>
      <div className={`col-xs-12 col-xl-8`}>
        <div className={'h-100 bg-white rounded p-3'}>
          {chart}
        </div>
      </div>
    </div>
    <div className={'row mb-3'} hidden={isBlank(details)}>
      {details}
    </div>
  </>

const PageContent = ({ learn, isIndividualDecision = false }) =>
  <div className='home container learn-container mx-auto mt-3'>
    <h1 className="mt-3">
      {isPresent(learn.template_name) ? learn.template_name : 'All decisions'}
    </h1>
    <StatisticChartSection name={<TotalDecisionsName learn={learn} />} chart={<TotalDecisionsChart learn={learn} />} />
    <StatisticChartSection name={<AvgCollaboratorsName learn={learn} />} chart={<AvgCollaboratorsChart learn={learn} />} />
    <ActivityByPersonSection learn={learn} />
    <StatisticChartSection name={<AvgDecisionTimeName learn={learn} />} chart={<AvgDecisionTimeChart learn={learn} />} />
    <StatisticChartSection name={<TreeCompletionName learn={learn} />} chart={<TreeCompletionChart learn={learn}/>} />
    <TreeCompletionDetails learn={learn} isIndividualDecision={isIndividualDecision} />
    <StatisticChartSection name={<DecisionsSuccessName learn={learn} />} chart={<DecisionsSuccessChart learn={learn} />} />
    <StatisticChartSection
      name={<DecisionsResultsName learn={learn} />}
      chart={<DecisionsResultsChart learn={learn} />}
      textInCenter={false}
    />
  </div>

const mapStateToProps = ({ learn }) => ({ learn })
export default connect(mapStateToProps)(PageContent);
