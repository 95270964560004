import { isPresent, protocolHost } from '../helpers/common'

// TODO: Implement transferring ACCEPT_FILE_TYPES data from back-end to front-end
// app/models/concerns/attached_documents.rb:5
export const ACCEPT_FILE_TYPES = [
  'video/mp4', 'video/m4v', 'video/x-m4v', 'video/mow', 'video/x-ms-wmv', 'video/avi', 'video/mpeg', 'video/mpg',
  'video/quicktime', 'video/ogv', 'video/ogg', 'video/3gpp', 'video/3gpp2', 'video/x-msvideo',
  'application/x-zip-compressed', 'application/zip', 'application/pdf','audio/aac', 'audio/mp3', 'audio/m4a',
  'audio/x-m4a', 'audio/ogg', 'audio/wav', 'audio/x-wav', 'audio/mpeg', 'text/csv', 'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document','image/jpg', 'image/jpeg', 'image/png',
  'image/gif', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint', 'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.ms-excel',
  'application/vnd.oasis.opendocument.presentation','application/keynote', 'application/x-iwork-keynote-sffkey',
  'application/vnd.apple.keynote', 'application/x-tika-ooxml', 'text/plain',
  'application/vnd.ms-excel.sheet.macroenabled.12', 'application/vnd.ms-excel.sheet.macroEnabled.12'
];

export const AI_ALLOWED_TYPES = ['application/pdf', 'text/plain',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

export const PDF_IMAGE_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png']
export const IMAGE_FILE_TYPES =  ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
export const MAX_FILE_SIZE = 104857500;

// allow multiple files upload
export const DEFAULT_MAX_FILES_SIZE = 0;

export const SourceableTypes = {
  DECISION: 'decision',
  DECISION_SET: 'decision_set',
  REPORT: 'report',
  FORECAST_SCENARIO: 'forecast_scenario',
  WIZARD: 'wizard'
}

export const Type = {
  DECISION_DATA_SOURCE: 'DecisionDataSource',
  DECISION_WIZARD_DATA_SOURCE: 'DecisionWizardDataSource',
  REPORT_DATA_SOURCE: 'ReportDataSource',
  PLAYBOOK_DATA_SOURCE: 'PlaybookDataSource',
  LINKED_REPORT_DATA_SOURCE: 'LinkedReportDataSource',
  LINKED_FORECAST_SCENARIO_DATA_SOURCE: 'LinkedForecastScenarioDataSource',
  PLAYBOOK_TEMPLATE_DATA_SOURCE: 'PlaybookTemplateDataSource',
  TEMPLATE_DATA_SOURCE: 'TempDataSource',
  LINKED_D_SIGHT_TEMPLATE_DATA_SOURCE: 'LinkedDSightTemplateDataSource',
  LINKED_D_SIGHT_DECISION_DATA_SOURCE: 'LinkedDSightDecisionDataSource',
  LINKED_REPORT_TEMPLATE_DATA_SOURCE: 'LinkedReportTemplateDataSource',
  LINKED_DECISION_WIZARD_REPORT_DATA_SOURCE: 'LinkedDecisionWizardReportDataSource',
  LINKED_DECISION_WIZARD_FORECAST_SCENARIO_DATA_SOURCE: 'LinkedDecisionWizardForecastScenarioDataSource',
  DECISION_SET_DATA_SOURCE: 'DecisionSetDataSource',
  TEMPLATE_SET_DATA_SOURCE: 'TemplateSetDataSource'
};

export const LinkedReportTemplateDataSourcesType = [Type.LINKED_REPORT_TEMPLATE_DATA_SOURCE];
export const LinkedReportDataSourcesType = [Type.LINKED_REPORT_DATA_SOURCE, Type.LINKED_REPORT_TEMPLATE_DATA_SOURCE, Type.LINKED_DECISION_WIZARD_REPORT_DATA_SOURCE];
export const LinkedForecastScenariosDataSourcesType = [Type.LINKED_FORECAST_SCENARIO_DATA_SOURCE, Type.LINKED_DECISION_WIZARD_FORECAST_SCENARIO_DATA_SOURCE];
export const TemplateDataSourcesTypes = [Type.TEMPLATE_DATA_SOURCE, Type.LINKED_REPORT_TEMPLATE_DATA_SOURCE];
export const DecisionDataSourcesTypes = [Type.DECISION_DATA_SOURCE, Type.LINKED_REPORT_DATA_SOURCE, Type.DECISION_WIZARD_DATA_SOURCE, Type.LINKED_FORECAST_SCENARIO_DATA_SOURCE];
export const DecisionWizardDataSourcesTypes = [Type.LINKED_DECISION_WIZARD_REPORT_DATA_SOURCE, Type.DECISION_WIZARD_DATA_SOURCE, Type.LINKED_DECISION_WIZARD_FORECAST_SCENARIO_DATA_SOURCE];
export const PlaybookDataSourcesTypes = [Type.PLAYBOOK_TEMPLATE_DATA_SOURCE, Type.PLAYBOOK_DATA_SOURCE];
export const DSightSourcesTypes = [Type.LINKED_D_SIGHT_TEMPLATE_DATA_SOURCE, Type.LINKED_D_SIGHT_DECISION_DATA_SOURCE];
export const DecisionSetSourcesTypes = [Type.DECISION_SET_DATA_SOURCE];
export const TemplateSetSourcesTypes = [Type.TEMPLATE_SET_DATA_SOURCE];
export const TemplateOrDSightDataSourcesTypes = [...TemplateDataSourcesTypes, ...DSightSourcesTypes, ...LinkedForecastScenariosDataSourcesType];
export const DecisionOrDSightDataSourcesTypes = [...DecisionDataSourcesTypes, ...DSightSourcesTypes];
export const DecisionOrTemplateOrDSightDataSourcesTypes = [...DecisionDataSourcesTypes, ...TemplateDataSourcesTypes, ...DSightSourcesTypes];

export default class DataSource {
  constructor(data_source) {
    this.data_source = data_source;
  }

  get actionIconClass() {
    if(this.isLink || this.isLinkedReport || this.isImage){
      return 'fas fa-external-link-alt';
    } else {
      return 'fas fa-download';
    }
  }

  get fileName() {
    if(this.isLink) return '';

    const filename = this.data_source.title || this.data_source.filename;
    const fileNames = filename.split('.');
    if (fileNames.length < 2) return filename;

    fileNames.pop()

    return fileNames.join('.');
  }

  get fileExtension() {
    if(this.isLink) return '';

    return this.data_source.filename.split('.').pop();
  }

  get iconBlock() {
    if(this.isDSight) return 'dsight-datasource-block';
    if(this.isLinkedReport) return 'text-purple';
    if(this.isLink || this.isKeynote || this.isOpenDocument || this.isWord) return 'text-primary';
    if(this.isPdf) return 'text-danger';
    if(this.isExcel || this.isCsv) return 'text-success';
    if(this.isPowerPoint || this.isZip) return 'text-orange';
    if(this.isVideo || this.isAudio || this.isImage) return 'text-muted';

    return 'text-light';
  }

  get sourceIconClass() {
    if(this.isDSight) return 'fas fa-link';
    if(this.isLinkedReport) return 'fas fa-files';
    if(this.isLink) return 'fas fa-link';
    if(this.isPdf) return 'fas fa-file-pdf';
    if(this.isZip) return 'fas fa-file-zipper';
    if(this.isVideo) return 'fas fa-file-video';
    if(this.isAudio) return 'fas fa-file-audio';
    if(this.isWord) return 'fas fa-file-word';
    if(this.isExcel) return 'fas fa-file-excel';
    if(this.isCsv) return 'fas fa-file-csv';
    if(this.isImage) return 'fas fa-file-image';
    if(this.isPowerPoint) return 'fas fa-file-powerpoint';
    if(this.isOpenDocument || this.isKeynote) return 'fas fa-file-alt';
  }

  get isFile() {
    return !this.isLink;
  }

  get isLink() {
    return isPresent(this.data_source.link_url);
  }

  contentUrl(decision_slug = '', isDriverDetailsModal = false) {
    if(this.isLinkedReport && isPresent(decision_slug)) {
      return `${protocolHost()}${this.data_source.link_url}?decision_slug=${decision_slug}`;
    } else if(this.isImage && !isDriverDetailsModal) {
      return null;
    } else if(this.isLink) {
      return this.data_source.link_url;
    } else {
      return this.data_source.url;
    }
  }

  get contentTargetName() {
    return this.isLink ? "_blank" : "_self"
  }

  get isDSight() {
    return [
      Type.LINKED_D_SIGHT_DECISION_DATA_SOURCE,
      Type.LINKED_D_SIGHT_TEMPLATE_DATA_SOURCE
    ].includes(this.data_source.type);
  }

  get isPdf() {
    return this.data_source.content_type === 'application/pdf'
  }

  get isZip() {
    return ['application/x-zip-compressed', 'application/zip', 'application/vnd.oasis.opendocument.text'].includes(this.data_source.content_type)
  }

  get isVideo() {
    return ['video/mp4', 'video/m4v', 'video/x-m4v', 'video/mow', 'video/x-ms-wmv', 'video/avi', 'video/mpeg', 'video/mpg', 'video/quicktime',
            'video/ogv', 'video/ogg', 'video/3gpp', 'video/3gpp2', 'video/x-msvideo'].includes(this.data_source.content_type)
  }

  get isAudio() {
    return ['audio/aac', 'audio/mp3', 'audio/m4a', 'audio/x-m4a', 'audio/ogg', 'audio/wav', 'audio/x-wav', 'audio/mpeg'].includes(this.data_source.content_type)
  }

  get isCsv() {
    return 'text/csv' === this.data_source.content_type;
  }

  get isWord() {
    return ['application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'].includes(this.data_source.content_type);
  }

  get isImage() {
    return IMAGE_FILE_TYPES.includes(this.data_source.content_type);
  }

  get isPdfImage() {
    return PDF_IMAGE_FILE_TYPES.includes(this.data_source.content_type);
  }

  get isPowerPoint() {
    return ['application/vnd.openxmlformats-officedocument.presentationml.slideshow',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.ms-powerpoint'].includes(this.data_source.content_type)
  }

  get isAiSupported() {
    return AI_ALLOWED_TYPES.includes(this.data_source.content_type)
  }

  get isOpenDocument() {
    return ['application/vnd.oasis.opendocument.text',
            'application/vnd.oasis.opendocument.spreadsheet',
            'application/vnd.oasis.opendocument.presentation'].includes(this.data_source.content_type)
  }

  get isKeynote() {
    return ['application/keynote', 'application/x-iwork-keynote-sffkey', 'application/vnd.apple.keynote'].includes(this.data_source.content_type)
  }

  get isExcel() {
    return ['application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel.sheet.macroenabled.12',
            'application/vnd.ms-excel.sheet.macroEnabled.12'].includes(this.data_source.content_type)
  }

  get isLinkedReport() {
    return LinkedReportDataSourcesType.includes(this.data_source.type);
  }

  get isLinkedForecastScenario() {
    return LinkedForecastScenariosDataSourcesType.includes(this.data_source.type);
  }
}
