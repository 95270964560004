import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import Analysis from "../../../models/d_sight/Analysis";
import {HeadingLevel} from "docx";
import {isBlank} from "../../../helpers/common";
import {baseHeadParagraph, baseRichBlock, bulletParagraph, paragraphBorder} from "./wrappers";

export const dSightAnalysisBlock = (decision) => {
  if (!isDSightDecision(decision)) return [];

  const insightsData = new Analysis(decision.d_sight).insightData

  return [
    paragraphBorder(),
    baseHeadParagraph({text: 'D-Sight analysis'}),
    baseHeadParagraph({text: decision.description, heading: HeadingLevel.HEADING_4}),
    ...dSightAnswerBlock(insightsData),
    ...dSightInsightsBlock(insightsData)
  ]
}

const dSightAnswerBlock = (insightsData) => {
  if (isBlank(insightsData.answerInsight)) return [];

  return [
    ...baseRichBlock({text: insightsData.answer, instance: 10})
  ]
}

export const dSightInsightsBlock = (insightsData) => {
  if (isBlank(insightsData.orderedInsights())) return [];

  return insightsData.orderedInsights().map(insight => bulletParagraph({text: insight.insight, style: 'normalPara2'}))
}