import React, { Fragment } from 'react';
import { LeftAlignedSemaphoreIcon } from "../../dsight/analysis/AnalysisContent";
import { SEMAPHORES } from "../../models/d_sight/insight";

export default () =>
  <Fragment>
    <h3>D-Sight</h3>
    <p>
      Decisions powered by D-Sight include assessment and recommendations from AI analysis of insights, KPIs, and metrics.
    </p>
    <p className="mb-3">
      Chat with customer success to add, modify, or remove D-Sight decisions.
    </p>
    <h3>Legend</h3>
    <div className="my-1">
      <LeftAlignedSemaphoreIcon semaphore={SEMAPHORES.green}/> No issues
    </div>
    <div className="my-1">
      <LeftAlignedSemaphoreIcon semaphore={SEMAPHORES.yellow}/> Needs investigation
    </div>
    <div className="my-1">
      <LeftAlignedSemaphoreIcon semaphore={SEMAPHORES.red}/> Needs attention
    </div>
    <div className="my-1">
      <LeftAlignedSemaphoreIcon semaphore={SEMAPHORES.grey}/> Not enough data
    </div>
  </Fragment>
