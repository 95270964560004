import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateHomepageCurrentTab } from "../../store/homepage/actions";
import { isDecisionsCenter, TABS, TABS_URLS } from "../../helpers/home_helpers";

export const DecisionCenterItem = ({ displayCurrent, home, updateHomepageCurrentTab }) =>
  <li className={displayCurrent && isDecisionsCenter(home) ? 'active' : ''}>
    <Link to={`/homepage/${TABS_URLS.decisions_center}`}
          onClick={() => updateHomepageCurrentTab(TABS.decisions_center)}
          className="text-nowrap">
      <span>
        <span className="icon d-inline-block text-center ">
          <i className="fas fa-home fa-lg"/>
        </span>
        <span className="d-none d-lg-inline tab-title">Decision center</span>
      </span>
    </Link>
  </li>

const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps, { updateHomepageCurrentTab })(DecisionCenterItem);
