import React, {Fragment, useEffect} from 'react';
import { connect } from "react-redux";
import {RichTextSection} from "../../../common/RichTextEditor";
import DotsLoader from "../helpers/DotsLoader";
import {isBlank, isPresent} from "../../../helpers/common";
import {AssistantContentSwitcher} from "./ChatGptDriverSummarizeSection";
import {getInitFilesContext} from "../FilesSummarizePanel";

export const filesApiSummarizeRequest = (aiSummarizeFilesRequest, setLoaded, setError, context, refineMessage,
                                         setContext, objectSlug, setRefineMessage, type) => {
  setLoaded(false)
  const callback = (success) => {
    if (success) {
      setLoaded(true)
      setRefineMessage('')
    } else {
      setLoaded(true)
      setError('Oops! Something happened. Please try again.')
    }
  }

  if (isPresent(refineMessage)) {
    context.push({role: 'user', content: refineMessage})
  }

  aiSummarizeFilesRequest({context, generated_for: {type, slug: objectSlug}}, callback, setContext)
}

const FilesSummarizeSection = ({sidebar, loaded, setLoaded, error, context, setContext, assistantContent, index, setIndex,
                                apiSummarizeCallback, decision, driverData, type}) => {
  const {recommendationInputSidebar, decisionInputSidebar, driverInputSidebar} = sidebar

  useEffect(() => {
    if (driverInputSidebar || recommendationInputSidebar || decisionInputSidebar) {
      if (isPresent(context)) return setLoaded(true);

      const initialContext = getInitFilesContext(type, decision, driverData);
      if (isBlank(initialContext)) {
        apiSummarizeCallback()
      } else {
        setContext(initialContext);
        setLoaded(true)
      }
    }
  }, [])

  useEffect(() => {
    assistantContent.length > 0 && setIndex(assistantContent.length - 1)
  }, [assistantContent.length])

  if(!loaded) return <DotsLoader text='CloverpopAI is reviewing the data sources' addClass={'mx-3 my-2'} />
  if(error) return <p className='text-muted mx-3 my-2'>Error: {error}</p>

  return <Fragment>
    <div className='mx-3'>
      <RichTextSection key={`assistant-${index}`} text={assistantContent[index]?.content} />
    </div>
    <AssistantContentSwitcher {...{assistantContent, index, setIndex}} />
  </Fragment>
}

const mapStateToProps = ({ sidebar, decision }) => ({ sidebar, decision })

export default connect(mapStateToProps, {})(FilesSummarizeSection);
