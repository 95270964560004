import {
  LOAD_ORG_DRIVER_TYPES_DATA,
  RESET_DRIVER_TYPES,
  UPDATE_ORG_DRIVER_TYPES_DATA,
  UPDATE_ORG_DRIVER_TYPES_FAILED
} from "./types";
import { loadOrgDriverTypesData } from "../../utils/Api";
import { dispatchErrorWithCallback, isResponseFailed } from "../../helpers/store_helpers";

export function loadOrgDriverTypes(data = {}) {
  return (dispatch) => {
    dispatch(updateOrgDriverTypesData({ loaded: false, loading: true }))
    loadOrgDriverTypesData(data).then((response) => {
      const { data } = response;
      if (isResponseFailed(response)) return dispatchErrorWithCallback(dispatch, { data, callback: loadOrgDriverTypesFailure });

      dispatch(loadOrgDriverTypesSuccess({ ...data, loaded: true, loading: false }));
    })
  }
}

export const resetOrgDriverTypesState = () => ({
  type: RESET_DRIVER_TYPES
});

export const loadOrgDriverTypesSuccess = (data) => ({
  type: LOAD_ORG_DRIVER_TYPES_DATA,
  payload: {
    ...data
  }
});

export const updateOrgDriverTypesData = (data) => ({
  type: UPDATE_ORG_DRIVER_TYPES_DATA,
  payload: {
    ...data
  }
});

export const loadOrgDriverTypesFailure = error => ({
  type: UPDATE_ORG_DRIVER_TYPES_FAILED,
  payload: {
    error
  }
});
