import React, { useEffect, useState } from 'react';
import { setTreeSidebarOpen } from "../../store/sidebar/actions";
import { connect } from "react-redux";
import TreeIndexIconSmall from "../../../../../assets/images/tree_index_icon_small.svg";
import TreeIndexIconSmallBlack from "../../../../../assets/images/tree_index_icon_small_black.svg";

const DecisionTreeItem = ({ isTemplate = false, isWizard = false, sidebar, openSideBar, treeMode }) => {
  if (isTemplate && isWizard)  return null

  const isTreeIndexIconActive = sidebar.treeSidebar
  const treeIndexDefaultImg = isTreeIndexIconActive ? `${TreeIndexIconSmall}` : `${TreeIndexIconSmallBlack}`
  const [treeIndexIconSmall, setTreeIndexIconSmall] = useState(treeIndexDefaultImg)

  useEffect(() => {
    setTreeIndexIconSmall(treeIndexDefaultImg);
  }, [isTreeIndexIconActive])

  return <li className={sidebar.treeSidebar ? 'active' : ''}>
    <div className="side-row text-nowrap pointer" onClick={() => openSideBar(!sidebar.treeSidebar, treeMode)}
        onMouseOver={()=>setTreeIndexIconSmall(`${TreeIndexIconSmall}`)}
        onMouseOut={()=>setTreeIndexIconSmall(treeIndexDefaultImg)}>
      <div className="icon d-inline-block text-center">
        <img src={treeIndexIconSmall} alt="Decision Tree" />
      </div>
      <span className="d-none d-lg-inline tab-title">Decision tree</span>
    </div>
  </li>
}

const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options, treeMode) => {
    dispatch(setTreeSidebarOpen(options, treeMode));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DecisionTreeItem);
