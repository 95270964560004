import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { updateDSightGoal } from "../../../../store/d_sight_goal/actions";
import { BtnDropdownToggleInlineShadowText } from "../../../../common/dropdowns";
import { BaseDropdownBtn } from "../../../../common/BaseHamburgerBtn";
import { filteredProcessDataRecord } from "../../../../models/d_sight/metric_chart_helpers";
import { isBlank, isPresent } from "../../../../helpers/common";
import { openModal } from "../../../../store/modals/actions";
import Dropdown from 'react-bootstrap/Dropdown';
import { MODAL_KEY, openSegmentationModal } from "../../modals/SegmentationCriteriaModal";
import { useHistory } from "react-router-dom";

const DEFAULT_OPTION = {
  display_name: 'All',
  id: null,
  value: null
}

const isOptionsAvailable = (selected_demographic, availableDemographics) => {
  if (selected_demographic == null || availableDemographics == null) return true;

  return availableDemographics.every(arr => arr.indexOf(selected_demographic) > -1);
}
const SegmentationOption = () =>
  <>
    <Dropdown.Divider />
    <BaseDropdownBtn {...{ eventKey: MODAL_KEY, title: 'Segmentation criteria', active: false, bsPrefix: 'text-primary on-hover-secondary' }}
                     key={MODAL_KEY}/>
  </>

const DemographicFilter = ({
                             d_sight_goal,
                             options, display_name, name, availableDemographics = null,
                             updateDSightGoal, openModal,
                             className = 'me-2 mb-2 mb-md-0',
                             segmentation_desc = [], question = ''
                          }) => {
  if (isBlank(options)) return null;

  const history = useHistory();
  const queryParameters = new URLSearchParams(document.location.search)
  const demographicOption = queryParameters.get(display_name)

  useEffect(() => {
    if (isPresent(demographicOption)) {
      updateDSightGoal({ selected_demographic: checkDefaultOption(options, demographicOption)?.id })
    }
  }, []);

  const checkDefaultOption = (options, selectedOption) => {
    return options.find(o => o.display_name.toLowerCase() === selectedOption.toLowerCase())
  }

  const isSelectedOption =  (id) => filteredProcessDataRecord({ demographic_id: id }, d_sight_goal)
  const onSelect = (selected_demographic) => {
    if (selected_demographic === MODAL_KEY) return openSegmentationModal({ openModal }, { segmentation_desc, question })
    if (isSelectedOption(selected_demographic)) return false;
    if (selected_demographic !== DEFAULT_OPTION.value && !isOptionsAvailable(parseInt(selected_demographic), availableDemographics)) return false;

    updateDSightGoal({ selected_demographic })
  }
  const selectedOption = options.find(({ id }) => isSelectedOption(id)) || DEFAULT_OPTION

  return <div className={className}>
    <div className="d-inline-flex text-nowrap me-1">{display_name || name}:</div>
    <BtnDropdownToggleInlineShadowText id={`${name}-dropdown`} title={selectedOption.display_name} onSelect={onSelect} bsPrefix="text-capitalize pe-0">
      {[DEFAULT_OPTION, ...options].map(({ value, id, display_name }) =>
        <BaseDropdownBtn {...{ eventKey: id, title: display_name, active: id === selectedOption.id }}
                         disabled={!isOptionsAvailable(id, availableDemographics)}
                         key={`${name}-${value}`} bsPrefix="text-capitalize"/>
      )}
      { isPresent(segmentation_desc) ? <SegmentationOption /> : null }
    </BtnDropdownToggleInlineShadowText>
  </div>
}
export const mapStateToProps = ({ d_sight_goal }) => ({ d_sight_goal });
export default connect(mapStateToProps, { updateDSightGoal, openModal })(DemographicFilter);
