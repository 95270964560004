import React from 'react';
import { currencyFormat, isBlank } from "../../../helpers/common";

export const ExpectedOpportunitySection = ({ object }) => {
  if (isBlank(object.expected_opportunity)) return null;

  return <div className="px-3 mb-3">
    <h3>Expected opportunity value</h3>
    <div>{currencyFormat(object.expected_opportunity)}</div>
  </div>
}
export default ExpectedOpportunitySection;
