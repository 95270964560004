import React from "react";
import { isBlank } from "../../../helpers/common";
import * as moment from "moment";

export default ({ object, isOverdue }) => {
  if(isBlank(object.due_date)) return null;

  return <div className={`d-inline-flex ${isOverdue ? 'text-danger' : 'text-muted' }`}>
    Due by {moment(object.due_date).format('DD MMM, yyyy')}
  </div>
}
