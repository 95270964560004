import {
  LOAD_ORG_DRIVER_TYPES_DATA,
  RESET_DRIVER_TYPES,
  UPDATE_ORG_DRIVER_TYPES_DATA,
  UPDATE_ORG_DRIVER_TYPES_FAILED
} from "./types";

const initialState = {
  error: '',
  loaded: false,
  loading: false,
  available_types: []
};

export function orgDriverTypesReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case LOAD_ORG_DRIVER_TYPES_DATA: {
      return {
        ...state,
        ...action.payload,
        error: ''
      };
    }
    case UPDATE_ORG_DRIVER_TYPES_DATA: {
      return {
        ...state,
        ...action.payload
      };
    }
    case UPDATE_ORG_DRIVER_TYPES_FAILED: {
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    }
    case RESET_DRIVER_TYPES: {
      return {
        ...state,
        ...initialState
      };
    }
    default:
      return state;
  }
}
