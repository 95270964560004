import React from 'react';
import Wrapper from '../Wrapper';
import DriverInfo from "../driver/DriverInfo";
import AggregatedData from "../driver/AggregatedData";
import Header from "../driver/Header";
import WizardContent from "../driver/WizardContent";
import { assignedDriverUser } from "./DriverCard";
import { driverInitData } from "../shared/helpers";
import { ArrowUpIcon } from "../../../icons/custom_icons";
import WizardDriverStatus from "../driver/WizardDriverStatus";

const PreviewDecisionWizardDriverCard = ({ driverData, decision, tree, indentStep = 1, decisionObject, user,
                                           collaborators, openModal, shortBorderToArrow = false }) => {
  const { driverObject, childrenDrivers, setShowChildren, showChildren } = driverInitData(tree, driverData, decisionObject);
  const assignedUser = assignedDriverUser(collaborators, {}, driverObject);

  const onEditClick = () => openModal({
    decision, drivers: tree.drivers, data_sources: tree.data_sources,
    slug: driverData.driver.slug, type: 'DriverDetailsModal'
  })

  return <>
    <Wrapper indentStep={indentStep} hasSingleChild={shortBorderToArrow}>
      <div className={`d-flex shortBorderToArrow-${shortBorderToArrow}`}>
        <WizardDriverStatus {...{ driverObject } }/>
        <div className="d-flex flex-column my-auto lh-sm w-100 me-1" style={{minHeight: 36}}>
          <Header {...{user, driverData, driverObject, childrenDrivers, setShowChildren, onEditClick}} />
          <DriverInfo {...{driverData, driverObject, decisionObject, user}} />
          <AggregatedData {...{driverData}} />
        </div>
        <WizardContent {...{driverData, driverObject, assignedUser}} />
      </div>
      <div className={`arrow-up-icon-block ${showChildren && childrenDrivers.length > 0 ? 'd-block' : 'd-none'}`}>
        <ArrowUpIcon/>
      </div>
    </Wrapper>
    {
      showChildren && childrenDrivers.length > 0 ?
        <div className={`position-relative ml-border-${indentStep} ${shortBorderToArrow ? '' : 'border-to-arrow'}`}>
          {childrenDrivers.map((child, index) => {
            return <PreviewDecisionWizardDriverCard key={`driver-card-${child.driver.slug}`}
                                                    {...{driverData: child, indentStep: indentStep + 1, decisionObject,
                                                      user, tree, openModal, collaborators,
                                                      shortBorderToArrow: childrenDrivers.length === 1 || index === childrenDrivers.length - 1
                                                    } } />
          })}
        </div> : null
    }
  </>
}
export default PreviewDecisionWizardDriverCard;
