import React from 'react';

export default ({ hidden = false, hideRecommendation, setHideRecommendation, classNames = 'mt-2' }) =>
  <div className={`w-100 ms-3 ${classNames} lh-checkbox`} hidden={hidden}>
    <input type="checkbox" id="hide-reccomendation-checkbox" name="hide-reccomendation-checkbox"
           className="custom-checkbox"
           checked={hideRecommendation}
           onChange={() => { setHideRecommendation(!hideRecommendation) }}/>
    <label htmlFor="hide-reccomendation-checkbox" className="mb-0">
      Hide this recommendation checkbox when starting decisions
    </label>
  </div>

