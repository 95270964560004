import React, { useMemo } from 'react';
import Select from 'react-select';
import { BorderedFilterStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import { scenariosOptions } from "../../helpers/helpers";

const SourceScenario = ({ forecast_simulator_scenario, selectedScenario, setSelectedScenario }) => {
  const options = useMemo(() => scenariosOptions(forecast_simulator_scenario).map(scenarioData => ({
    label: scenarioData.display_name,
    value: scenarioData.id
  })), [forecast_simulator_scenario.config_scenarios])

  return <div className="mt-3 mb-0">
    <h3>Source scenario</h3>
    <Select
      defaultValue={selectedScenario}
      onChange={setSelectedScenario}
      components={{ IndicatorSeparator: () => null }}
      styles={BorderedFilterStyle}
      placeholder="Make a selection"
      options={options} />
  </div>
};
export default SourceScenario;
