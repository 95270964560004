import React from 'react';
import { connect } from "react-redux";
import ReportPropertyInput from "./ReportPropertyInput";

export const ReportPropertiesFormInput = ({ className, property, ...opts }) =>
  <div className={className}>
    <h3>{property.name}</h3>
    <ReportPropertyInput key={`property-input-${property.slug}`} {...{...opts, property}} />
  </div>

export const ReportPropertiesForm = ({ org_report_properties, ...opts }) =>
  <>
    {
      org_report_properties.available_report_properties.map(property =>
        <ReportPropertiesFormInput key={`property-input-${property.slug}-wrapper`} {...{ ...opts, property }} />
      )
    }
  </>
const mapStateToProps = ({ org_report_properties }) => ({ org_report_properties });
export default connect(mapStateToProps)(ReportPropertiesForm);
