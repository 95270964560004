import React from 'react';
import {Circle, Link, Svg, Text, View} from '@react-pdf/renderer';
import { isBlank } from "../../helpers/common";
import { regex } from "../../helpers/html_parser";
import { v4 as uuidv4 } from 'uuid'
import styles from '../styles/common'
import { create } from 'apisauce';
import {MAX_CHARACTERS} from "../../docx/helpers/common";

export const DOCUMENT_FORMAT = 'letter';
const URL_REGEX = /(?:<a[^>]*?href=[^>]*?>(.*?)<\/a>)|(https?:\/\/[^\s'"<>\]]+(?![^>]*?href=))/gm;

export const CustomBullet = () => {
  return <View style={[styles.mr2]}>
    <Svg height="6" width="6" style={[{position: 'absolute', top: 3}]}>
      <Circle
        cx="4"
        cy="4"
        r="1.5"
        fill="black"
        stroke="black"
      />
    </Svg>
  </View>
}

export const CustomEmptyBubble = () => {
  return <View style={[styles.mr2]}>
    <Svg height="6" width="6" style={[{position: 'absolute', top: 3}]}>
      <Circle
        cx="4"
        cy="4"
        r="1.5"
        fill="white"
        stroke="black"
      />
    </Svg>
  </View>
}

export function pdfTextParser(text, displayBullet = false) {
  if (isBlank(text)) return null;
  const random = uuidv4();

  return text.split('\n').map((item, key) => {
    return <View key={`string-part-${random}-${key}`} style={styles.row}>
      {displayBullet ? <CustomBullet /> : null}
      <Text>{linkTag(item)}</Text>
    </View>
  })
}

export function linkTag(words) {
  return words.split(' ').map((item, key) => {
    const random = uuidv4();
    if (item.match(regex) !== null) {
      return <Link key={`link-${random}-${key}`} src={`${item.replace('www', '//www')}`} style={styles.link}>
        {item}
      </Link>
    } else {
      return (isBlank(item) ? ' ' : `${item} `)
    }
  })
}

export const analyzeAndReplaceLinks = (text) => {
  return text.replace(URL_REGEX, function (match, linkContent, url) {
    if (url) {
      const truncatedLink = url.length > MAX_CHARACTERS ? url.substring(0, MAX_CHARACTERS) + '...' : url;
      return `<a href="${url}" target="_blank" title="${url}">${truncatedLink}</a>`;
    } else if (linkContent) {
      return match;
    }
    return match;
  });
};

export function paragraphTag(text) {
  if (isBlank(text)) return null;

  const modifiedText = text.replace(/(<p><br><\/p>)/g, '<br>').replace(/(<br\/>)$/g, '').replace(/(<p>)/g, '').replace(/(<\/?p>)/g, '<br>')
    .replace(/style="[^"]*"|style='[^']*'/g, "")

  return analyzeAndReplaceLinks(modifiedText)
}
