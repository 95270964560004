import React, {Fragment, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import {connect} from "react-redux";
import {
  loadQuickPollData, updateQuickPoll
} from "../store/quick_entry_pages/actions";
import ChoicesSection from "./ChoicesSection";
import {Loader} from "../common/Loader";
import QuickPageHeader from "../quick_driver_entry_page/header/QuickPageHeader";
import { userVotesChoicesCallback } from "../helpers/decision_votes_helpers";

const loadDataCallback = (loadQuickPollData, setLoaded, setData) => {
  useEffect(() => {
    const callback = (success, data) => {
      if (success) {
        setData(data)
        setLoaded(true)
      }
    }
    loadQuickPollData(callback)
  }, []);
}

export const QuickPollPage = ({loadQuickPollData, updateQuickPoll}) => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const {invite_email, decision} = data
  const user = {email: invite_email}
  const [choicesVotes, setChoicesVotes] = useState([]);
  const [votesFeedback, setVotesFeedback] = useState({})

  loadDataCallback(loadQuickPollData, setLoaded, setData)

  useEffect(() => {
    if (!loaded) return;

    const choices = userVotesChoicesCallback(decision).map(c_v => ({...c_v, vote: {}}))
    setChoicesVotes(choices);
  }, [loaded]);

  if (!loaded) return <Loader />

  return loaded && <Fragment>
    <QuickPageHeader basePath={'/quick_poll_entry/'} />
    <Container>
      <div className="container home my-3 mx-auto">
        <div className="mx-auto quick-driver-container">
          <div className="header h3">
            <span className='text-primary me-1'>Welcome to Cloverpop!</span>
            <span className='text-dark-gray'>
              {decision.user.full_name} assigned you a decision poll for "{decision.description}"
            </span>
          </div>
        </div>
       <ChoicesSection {...{decision, updateQuickPoll, choicesVotes, setChoicesVotes, user, votesFeedback, setVotesFeedback}} />
      </div>
    </Container>
  </Fragment>
}

export default connect(null, {loadQuickPollData, updateQuickPoll})(QuickPollPage);
