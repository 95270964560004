import React, {useState, useEffect, Fragment} from 'react';
import { connect } from "react-redux";
import { saveWizardStepData, updateWizardData } from "../../../store/wizard/actions";
import { Button, Table } from 'react-bootstrap'
import {generateRandomSlug, isBlank, isPresent, qSortArray, uniqueBy} from "../../../helpers/common";
import Help from "../../help/index";
import {
  isLastStep,
  stepWithDrivers,
  WIZARD_STEPS,
  wizardStepDataBy
} from "../../../helpers/wizard_helpers";
import * as moment from "moment";
import DecisionDate, {DATEPICKER_TYPES} from '../../../common/DecisionDate';
import SubmitStepButton from "./SubmitStepButton";
import DecisionTitleRow from "../../helpers/DecisionTitleRow";
import DriversBlock from "./DriversBlock";
import {checkLoadingEffect} from "../../../helpers/callbacks_helpers";
import {loadOrgDriverTypes} from "../../../store/org_driver_types/actions";
import CurrencyInput from "../../../common/CurrencyInput";
import {
  buildDrivers,
  onCopyDriverCallback,
  onRemoveDriverCallback,
  useAddedRootDriverEffect
} from "./helpers/tree_builder_step";
import DecisionAssignToBlock from "../../../tree_view/modals/helpers/DecisionAssignToBlock";
import DataSourcesBlock from "./DataSourcesBlock";
import { saveUploadingSources } from "../../../store/tree/actions";
import {assignAssignedUserCallback} from "../../../tree_view/side_panel/decision_input/AssignedDeciderInput";
import CollaboratorsBlock from "./CollaboratorsBlock";
import { submitTreeStep } from "../../helpers/decision_wizard_helpers";
import AccordionWrapper from "../../../common/AccordionWrapper";
import {DEFAULT_CHOICES, onChangeChoiceCallback, onPasteChoiceCallback} from "./FramingDecisionStep";
import {ChoiceRow} from "../../../template_wizard/steps_wizard/steps/FramingDecisionStep";
import Decision, {CHOICE_ENTRY_TYPE_DATA} from "../../../models/decision";
import HistoricalChoicesSection from "./sections/HistoricalChoicesSection";
import HistoricalChoicesModal from "../../modals/HistoricalChoicesModal";
import {EditIcon} from "../../../common/EditIcon";
import {isEditedChoices} from "../../../helpers/drivers_helpers";
import { JoditTextEditor } from '../../../common/JoditTextEditor';

export const InputCurrencyHeader = ({ headerText, className = "mb-0" }) => <h3 className={className}>{headerText}</h3>

export const HistoricalDecisionStep = ({
                                         wizard, tree, decision, playbook_notes, org_driver_types,
                                         saveWizardStepData, updateWizardData,
                                         stepRef, loadOrgDriverTypes, saveUploadingSources, current_user
                                      }) => {
  checkLoadingEffect(org_driver_types, loadOrgDriverTypes);

  const stepData = wizardStepDataBy(wizard, WIZARD_STEPS.tree_historical.key)
  const [submitState, setSubmitState] = useState(false)
  const [description, setDescription] = useState(decision.description || '')
  const initDate = isPresent(decision.historical_decided_at) ? moment(decision.historical_decided_at) : null;
  const [historicalDecidedAt, setHistoricalDecidedAt] = useState(initDate || moment());
  const [reasons, setReasons] = useState(decision.final_decision_reasons || '');
  const [expectedResults, setExpectedResults] = useState(decision.expected_results || '');
  const [recommendationChoice, setRecommendationChoice] = useState(stepData?.choices?.recommendation_choice || '');
  const [finalDecision, setFinalDecision] = useState(stepData?.choices?.final_decision || '');
  const [assignedToUser, setAssignedToUser] = useState(decision.assigned_decider_email || '')
  const [expectedOpportunity, setExpectedOpportunity] = useState(decision.expected_opportunity);
  const [expectedInvestment, setExpectedInvestment] = useState(decision.expected_investment);
  const [newAddedRootDriver, setNewAddedRootDriver] = useState(false)
  const [nextStepsDescription, setNextStepsDescription] = useState(decision.next_steps_description || '');
  const prevConsideredChoices = stepData?.considered_choices
  const prevRecommendationChoices = stepData?.choices?.recommendation_choices || []
  const prevDecisionChoices = stepData?.choices?.decision_choices || []
  const defaultConsideredChoices = () =>
    isBlank(prevConsideredChoices) ? DEFAULT_CHOICES : prevConsideredChoices

  const [consideredChoices, setConsideredChoices] = useState(defaultConsideredChoices())
  const [decisionChoices, setDecisionChoices] = useState(prevDecisionChoices)
  const [recommendationChoices, setRecommendationChoices] = useState(prevRecommendationChoices)
  const [newChoice, setNewChoice] = useState(false)
  const [showChoicesModal, setShowChoicesModal] = useState(false);
  const [isRecommendation, setIsRecommendation] = useState(false);
  const decisionObj = new Decision(decision)
  const onChangeChoice = onChangeChoiceCallback(consideredChoices, setConsideredChoices)
  const onPasteChoice = onPasteChoiceCallback(consideredChoices, setConsideredChoices, setNewChoice, onChangeChoice);
  const addNewChoice = () => {
    setNewChoice(true)
    setConsideredChoices([...consideredChoices, ''])
  }

  const removeChoice = (index) => {
    $(`#decisionChoice-${index}`).removeClass('d-flex').addClass('d-none');
    onChangeChoice('', index)
  }

  const [drivers, setDrivers] = useState(buildDrivers({
    tree, wizard,
    newAddedRootDriver, setNewAddedRootDriver,
    step: WIZARD_STEPS.tree_historical.key
  }))
  const [invites, setInvites] = useState([])
  const [rateSupport, setRateSupport] = useState(decision.rate_support || false)

  const onRemoveDriver = onRemoveDriverCallback(drivers, setDrivers);
  const onCopyDriver = onCopyDriverCallback(drivers, setDrivers);

  useAddedRootDriverEffect({
    step: stepWithDrivers(wizard),
    submitDrivers: saveWizardStepData,
    newAddedRootDriver, drivers
  })
  const disabledStep = () => isBlank(description) || (isBlank(finalDecision) && !decisionChoices.some(choice => choice.final_decision))

  const submitStep = (nav_data = {}, callback = () => {}) => {
    setSubmitState(true)
    if(isPresent(nav_data.drivers)) setDrivers(nav_data.drivers)
    saveWizardStepData(WIZARD_STEPS.tree_historical.key, {
      complete: isLastStep(wizard),
      ...nav_data,
      response: { step_index: wizard.step_index },
      final_decision: finalDecision,
      recommendation_choice: recommendationChoice,
      decision_choices: decisionChoices,
      recommendation_choices: recommendationChoices,
      considered_choices: consideredChoices,
      decision: {
        description,
        historical_decided_at: historicalDecidedAt.toString(),
        final_decision_reasons: reasons,
        expected_results: expectedResults,
        expected_opportunity: expectedOpportunity,
        expected_investment: expectedInvestment,
        assigned_decider_email: assignedToUser,
        next_steps_description: nextStepsDescription,
        rate_support_on_slack: rateSupport,
      },
      collaborators: uniqueBy(invites, 'email').map(({ email }) => email),
      next_step: true
    }, callback)
  }

  stepRef.current.submitStep = (additional_data = {}, callback = () => {}) => {
    if (disabledStep()) additional_data.finish_later = true

    submitStep(additional_data, (success, wizardData) => {
      setSubmitState(false)
      callback(success, additional_data.finish_later, wizardData)
    })
  };
  stepRef.current.submitDrivers = (new_drivers) => setDrivers(new_drivers);

  useEffect(() => {
    updateWizardData({ disabledSubmit: disabledStep() })
  }, [description, finalDecision, decisionChoices])

  useEffect(() => {
    const sortedDecisionChoices = qSortArray(decisionChoices, true, c => c.description)
    const sortedRecommendationChoices = qSortArray(recommendationChoices, true, c => c.description)
    const isEdited = isEditedChoices(sortedDecisionChoices, sortedRecommendationChoices)
    const isAddChoice = sortedDecisionChoices.length > sortedRecommendationChoices.length
    if (!isEdited) return;

    const newRecommendationChoices = decisionChoices.map((decisionChoice, i) => {
      const recommendationChoice = sortedRecommendationChoices[i]
      return {
        ...recommendationChoice,
        description: decisionChoice.description,
        slug: isAddChoice ? generateRandomSlug(12) : recommendationChoice.slug,
        final_decision: isEdited ? false : recommendationChoice.final_decision,
        created_at: decisionChoice.created_at
      };
    });
    setRecommendationChoices(newRecommendationChoices)
  }, [decisionChoices, recommendationChoices.length])

  assignAssignedUserCallback({
    isFinalChoices: isPresent(finalDecision) || decisionChoices.some(choice => choice.final_decision),
    current_user, assignedToUser, setAssignedToUser
  })

  const accordionNextSteps = {
    header: <h3 className="mb-0"> Next steps</h3>,
    body: <JoditTextEditor className="jodit-container-default"
                           value={nextStepsDescription}
                           placeholder="Enter any actions to take"
                           setValue={setNextStepsDescription}
                           autofocus={false} />,
    defaultExpand: isPresent(decision.next_steps_description)
  };

  const accordionExpectedResults = {
    header: <h3 className="mb-0">Expected results</h3>,
    body: <JoditTextEditor className="jodit-container-default"
                           value={expectedResults}
                           placeholder="Describe what is expected to happen"
                           setValue={setExpectedResults}
                           hasOnChangeAction={true}
                           autofocus={false} />,
    defaultExpand: isPresent(decision.expected_results)
  };

  const accordionExpectedOpportunity = {
    header: <InputCurrencyHeader headerText={'Expected opportunity value'}/>,
    body: <CurrencyInput key={'currency-input-opportunity'}
                         value={expectedOpportunity}
                         onChange={(value) => setExpectedOpportunity(value || '')}
                         placeholder={'Enter a dollar amount'}
                         name={'expected-opportunity'}
                         id={'expected-opportunity'}/>,
    defaultExpand: isPresent(decision.expected_opportunity)
  };

  const accordionExpectedInvestment = {
    header: <InputCurrencyHeader headerText={'Expected investment or cost'}/>,
    body: <CurrencyInput key={'currency-input-investment'}
                         value={expectedInvestment}
                         onChange={(value) => setExpectedInvestment(value || '')}
                         placeholder={'Enter a dollar amount'}
                         name={'expected-investment'}
                         id={'expected-investment'} />,
    defaultExpand: isPresent(decision.expected_investment)
  };

  const accordionRecommendation = {
    header: <h3 className="mb-0">What was recommended</h3>,
    body: decisionObj.isOpenEnded ?
      <JoditTextEditor className="jodit-container-default"
                       value={recommendationChoice}
                       placeholder="Enter the recommendation"
                       setValue={setRecommendationChoice}
                       autofocus={false} /> :
      <>
        <div className="mb-2 text-muted">
          {CHOICE_ENTRY_TYPE_DATA[decision.choice_entry_widget_type]}
        </div>
        <HistoricalChoicesSection {...{ decision, choicesData: recommendationChoices, setChoices: setRecommendationChoices, namePrefix: "recommendation" }} />
      </>,
    defaultExpand: isPresent(recommendationChoice) || prevRecommendationChoices.some(choice => choice.final_decision)
  };

  const accordionConsideredChoices = {
    header: <h3 className="mb-0">Other choices considered</h3>,
    body: <>
      {
        consideredChoices.map((description, index) =>
          <ChoiceRow key={`decision-choice-${index}`}
                     {...{ wizard, index, description, submitState, newChoice, setNewChoice, onChangeChoice, removeChoice, onPasteChoice }}/>
        )
      }
      <Button onClick={addNewChoice} disabled={submitState} className="btn-secondary w-100">Add another choice</Button>
    </>,
    defaultExpand: isPresent(prevConsideredChoices)
  };

  const onChangeDate = (date) => setHistoricalDecidedAt(moment(date));
  const onRemoveInvite = (invite) => {
    const buildDriver = ({ driver, children, ...opts }) => ({
      driver: {
        ...driver,
        assign_to_user: driver.assign_to_user === invite.email ? null : driver.assign_to_user,
      },
      children: children.map(hash => buildDriver(hash)),
      ...opts
    })
    const newDrivers = drivers.map(hash => buildDriver(hash))
    setDrivers(newDrivers)
    saveWizardStepData(WIZARD_STEPS.tree_historical.key, {
      ...(stepData?.assign_to === invite.email ? { assigned_collaborator: '' } : {}),
      drivers: newDrivers,
      finish_later: true
    })

    if (assignedToUser === invite.email) setAssignedToUser('')
  }

  return <Fragment>
    <div className="d-flex">
      <div className="mx-auto overflow-auto">
        <div className={`bg-white rounded p-3 mt-3 mb-3 mb-xl-5 historical-tree-wizard d-inline-block`}>
          <Table borderless className="mb-2">
            <tbody>
            <tr>
              <td className="d-flex p-0">
                <h1 className="d-table-cell">
                  <span className="text-primary">Let’s record a decision! </span>
                  Enter what was decided.
                </h1>
                <div className="d-table-cell ms-auto ">
                  <Help />
                </div>
              </td>
            </tr>
            </tbody>
          </Table>

          <DecisionTitleRow {...{ playbook_notes, description, setDescription, submit: wizard.submit || submitState }} />

          <div className="w-100 mb-3">
            <div className='d-flex justify-content-between'>
              <h3>What was decided <span className="required-muted-text text-muted">(required)</span></h3>
              <EditIcon onClick={() => setShowChoicesModal(true)} title="Edit choices" hidden={decisionObj.isOpenEnded} />
            </div>

            {decisionObj.isOpenEnded?
              <JoditTextEditor className="jodit-container-default"
                               value={finalDecision}
                               placeholder="Enter the decision and resulting actions"
                               setValue={setFinalDecision}
                               hasOnChangeAction={true}
                               autofocus={false} /> :
              <>
                <div className="mb-2 text-muted">
                  {CHOICE_ENTRY_TYPE_DATA[decision.choice_entry_widget_type]}
                </div>
                <HistoricalChoicesSection {...{ decision, choicesData: decisionChoices, setChoices: setDecisionChoices, namePrefix: "decision" }} />
              </>
            }
          </div>

          <div className="w-100 mb-3">
            <h3>Rationale</h3>
            <JoditTextEditor className="jodit-container-default"
                             value={reasons}
                             placeholder="Enter a detailed explanation"
                             setValue={setReasons}
                             autofocus={false} />
          </div>
          <div className="w-100 mb-3">
            <DecisionDate readOnly={wizard.submit || submitState}
                          id={DATEPICKER_TYPES.historical_decided_at}
                          name={DATEPICKER_TYPES.historical_decided_at}
                          maxDate={new Date()}
                          header="Decision date"
                          date={historicalDecidedAt} onChangeDate={onChangeDate} />
          </div>
          <DecisionAssignToBlock {...{ assignedToUser, setAssignedToUser, className: 'w-100 mb-3', title: 'Decider' }} />
          <AccordionWrapper accordionObject={accordionConsideredChoices} hidden={!decisionObj.isOpenEnded} className='w-100 mb-3' />
          <AccordionWrapper accordionObject={accordionRecommendation} className='w-100 mb-3' />
          <AccordionWrapper accordionObject={accordionNextSteps} className='w-100 mb-3' />
          <AccordionWrapper accordionObject={accordionExpectedResults} className='w-100 mb-3' />
          <AccordionWrapper accordionObject={accordionExpectedOpportunity} className='w-100 mb-3' />
          <AccordionWrapper accordionObject={accordionExpectedInvestment} className='w-100 mb-3' />
          <div className="w-100">
            <SubmitStepButton onClick={() => submitTreeStep(wizard, stepRef)} disabled={wizard.submit || submitState || disabledStep()} />
          </div>
        </div>
        <div className={`d-xl-inline-block align-top historical-decision-side_panel mt-xl-3 mb-5`}>
          <DriversBlock stepRef={stepRef} onRemoveDriver={onRemoveDriver} onCopyDriver={onCopyDriver} drivers={drivers} />
          <DataSourcesBlock saveUploadingSources={saveUploadingSources} />
          <CollaboratorsBlock {...{invites, setInvites, rateSupport, setRateSupport, onRemoveInvite}} />
        </div>
      </div>
    </div>
    <div className="modals">
      <HistoricalChoicesModal shown={showChoicesModal} choices={isRecommendation ? recommendationChoices : decisionChoices}
                              setChoices={isRecommendation ? setRecommendationChoices : setDecisionChoices} isRecommendation={isRecommendation}
                              setShowChoicesModal={setShowChoicesModal} decision={decision} setIsRecommendation={setIsRecommendation} />
    </div>
  </Fragment>
}
const mapStateToProps = ({ wizard, decision, playbook_notes, org_driver_types, tree, current_user }) => ({ wizard, decision, playbook_notes, org_driver_types, tree, current_user });
const mapDispatchToProps = (dispatch) => ({
  saveWizardStepData: (step, data, callback) => dispatch(saveWizardStepData(step, data, callback)),
  updateWizardData: (data) => dispatch(updateWizardData(data)),
  loadOrgDriverTypes: () => dispatch(loadOrgDriverTypes()),
  saveUploadingSources: (sources) => dispatch(saveUploadingSources(sources))
});
const wrapper = React.forwardRef((props, ref) => <HistoricalDecisionStep {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
