import Decision from "../models/decision";
import { isPresent } from "./common";
import * as moment from "moment";
import { DSIGHT_TREE_FLOWS } from "./decision_helpers";
import { isDraftDecision, isUnstartedDecision } from "../models/decision_state";

export const isInFlightDecision = (decision) => {
  const object = new Decision(decision)
  if(object.isEnteredRecommendation && object.isDecisionWithResults) return false;
  if (object.showAsCompleted) return false;
  if(object.isEnteredRecommendation && !object.isDecisionRecorded && !object.isDecisionWithResults) return true;
  return !isDraftDecision(decision) && !isUnstartedDecision(decision) && !object.isDecisionRecorded;
}
export const isDecisionWithResults = (decision) => {
  if(isDraftDecision(decision)) return false;

  const object = new Decision(decision)
  
  if (object.isLegacy)
    return object.isDecisionWithResults;

  return object.isRecordedOrRecommended && object.isDecisionWithResults
}
export const isFollowupOnResultsDecision = (decision) => {
  if(isDraftDecision(decision)) return false;

  const object = new Decision(decision)
  return object.isRecordedOrRecommended && !object.isDecisionWithResults &&
    isPresent(object.scheduledFollowup.followup_due_date) && moment(object.scheduledFollowup.followup_due_date).isSameOrBefore(moment())
}

export const isDSightDecision = (decision) => DSIGHT_TREE_FLOWS.includes(decision.flow_enum)
