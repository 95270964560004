import React from 'react';

export default ({ hidden, duplicateResponses, setDuplicateResponses, classNames = 'mt-2' }) =>
  <div className={`w-100 ${classNames} lh-checkbox`} hidden={hidden}>
    <input type="checkbox" id="duplicateResponses-checkbox" name="duplicateResponses"
           className="custom-checkbox"
           checked={duplicateResponses}
           onChange={() => { setDuplicateResponses(!duplicateResponses) }}/>
    <label htmlFor="duplicateResponses-checkbox" className="mb-0">Copy and pre-fill any driver responses as drafts</label>
  </div>
