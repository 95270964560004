import React, { useState } from 'react';
import { connect } from "react-redux";
import { CategoryBlockRow } from "../../../decision_categories/CategoryBlockRow";
import NAElement from "../../../tree_view/side_panel/sections/NAElement";
import TemplateCategoriesModal from "../../modals/TemplateCategoriesModal";
import { decisionSelectedCategories } from "../../../models/decision";
import { CategorizationPBSection } from "./PlaybookNotesSection";
import { isPresent } from "../../../../ms_teams/helpers/common";
import {EditCategorySection, HeaderCategoriesElement} from "../../../tree_view/side_panel/sections/CategoriesSection";

export const CategoriesSection = ({ template, org_categories, playbook_notes, isDSight = false }) => {
  const [showEditCategoriesModal, setShowEditCategoriesModal] = useState(false)
  const openEditModal = () => { setShowEditCategoriesModal(true) }

  const selectedCategories = decisionSelectedCategories(org_categories, template.selected_categories)

  return <div className="px-3 pb-3">
    <HeaderCategoriesElement />
    <CategorizationPBSection playbook_notes={playbook_notes} sidePanelStyle={true} showNA={false}/>
    <div className="d-table me-auto">
      <div hidden={selectedCategories.length || isPresent(playbook_notes?.categorization)}>
        <NAElement />
      </div>
      {selectedCategories.map(category =>
        <CategoryBlockRow category={category} selectedCategories={template.category_options} key={`category-block-row-${category.slug}`} />
      )}
    </div>
    <EditCategorySection openEditModal={openEditModal} hideEditIcon={isDSight} />
    <div className="modals">
      <TemplateCategoriesModal shown={showEditCategoriesModal} onClose={() => setShowEditCategoriesModal(false)} />
    </div>
  </div>
}
const mapStateToProps = ({ template, org_categories, playbook_notes }) => ({
  template, org_categories, playbook_notes
});
export default connect(mapStateToProps)(CategoriesSection);
