import React from "react";
import {connect} from "react-redux";
import {isBlank} from "../../../helpers/common";
import Decision from "../../../models/decision";
import {copyRecommendationFields} from "../../../store/sidebar/actions";
import {BaseDropdownBtn} from "../../../common/BaseHamburgerBtn";

const DuplicateRecommendation = ({sidebar, copyRecommendationFields, isDsight = false}) => {
  const decision = sidebar?.decision
  if(isBlank(decision)) return null;

  const decisionObj = new Decision(decision);
  if(!decisionObj.isEnteredRecommendation) return null;

  return isDsight ?
    <div className='px-3 mb-1'>
      <div className='d-inline-flex'>
        <a className='text-primary pointer' onClick={() => copyRecommendationFields()}>
          Click here
        </a>
        <div className='text-muted'>&nbsp;to duplicate the recommendation.</div>
      </div>
    </div> :
    <BaseDropdownBtn onSelect={copyRecommendationFields}>
      Duplicate recommendation
    </BaseDropdownBtn>
}

const mapStateToProps = ({ sidebar }) => ({ sidebar });
export default connect(mapStateToProps, {copyRecommendationFields})(DuplicateRecommendation);
