import React, {useCallback, useEffect, useState} from 'react';
import { connect } from "react-redux";
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";
import { IconDropdown } from "../../../common/dropdowns";
import { setLargeScalePanelOpen, setImportValuesPanelOpen } from "../../../store/sidebar/actions";
import { resetScenarioValues, updateScenarioData } from "../../../store/forecast_simulator_scenario/actions";
import { isBlank } from "../../../helpers/common";
import { performUpdateCells } from "../../helpers/common";
import { hideOverlay, MESSAGES, showOverlayWithMessage } from "../../helpers/custom_loading_overlay";
import { generateExcelFile } from "../../helpers/helpers";

const ScenarioActions = ({
                           gridRef,
                           forecastScenario,
                           btnId,
                           forecast_simulator_scenario,
                           setLargeScalePanelOpen,
                           setImportValuesPanelOpen,
                           resetScenarioValues,
                           updateScenarioData
                         }) => {
  const [generateExcelDisabled, setGenerateExcelDisabled] = useState(true);

  useEffect(() => {
    setGenerateExcelDisabled(!forecast_simulator_scenario.scenario_loaded);
  }, [forecast_simulator_scenario.scenario_loaded]);

  const iconDropdownEvents = {
    applyLargeScaleInputHandler: 'applyLargeScaleInputHandler',
    resetInputsHandler: 'resetInputsHandler',
    importValuesHandler: 'importValuesHandler',
    generateExcelHandler: 'generateExcelHandler',
  }

  const resetInputs = useCallback(() => {
    showOverlayWithMessage(gridRef.current?.api, updateScenarioData, MESSAGES.updating_scenario);
    resetScenarioValues(forecast_simulator_scenario.scenario_id, (success = false, default_values = false) => {
      if(success) {
        if (default_values) {
          const groupedUpdateData = forecastScenario.updateData.edited_cells.reduce((acc, row) => {
            if (isBlank(acc[row.nodeId])) acc[row.nodeId] = {};
            acc[row.nodeId][row.field] = row.default_value
            return acc;
          }, {})
          performUpdateCells(gridRef, groupedUpdateData)
        }
      }
      hideOverlay(gridRef.current?.api);
    }, forecast_simulator_scenario.scenario.run_model_happened)
  }, [forecast_simulator_scenario.scenario_id, forecastScenario] )

  const iconDropdownSelect = (eventKey) => {
    switch (eventKey) {
      case iconDropdownEvents.applyLargeScaleInputHandler:
        return setLargeScalePanelOpen(true)
      case iconDropdownEvents.resetInputsHandler:
        return resetInputs()
      case iconDropdownEvents.importValuesHandler:
        return setImportValuesPanelOpen(true)
      case iconDropdownEvents.generateExcelHandler:
        return generateExcelFile(gridRef)
    }
  }

  return <IconDropdown id={btnId} bsPrefix="h5 m-0 btn-link pt-2"
                       className="btn btn-secondary btn-lg-round d-flex p-0 float-end"
                       onSelect={iconDropdownSelect}>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.applyLargeScaleInputHandler} title="Apply large-scale input"/>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.resetInputsHandler} title="Reset all inputs to defaults"/>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.importValuesHandler} title="Import values"/>
    <BaseHamburgerBtn disabled={generateExcelDisabled} eventKey={iconDropdownEvents.generateExcelHandler} title="Generate Excel file"/>
  </IconDropdown>;
}
const mapStateToProps = ({ forecast_simulator_scenario }) => ({ forecast_simulator_scenario });
const mapDispatchToProps = (dispatch) => ({
  setLargeScalePanelOpen: (open) => dispatch(setLargeScalePanelOpen(open)),
  setImportValuesPanelOpen: (open) => dispatch(setImportValuesPanelOpen(open)),
  resetScenarioValues: (scenario_id, callback, showLoading) =>
    dispatch(resetScenarioValues(scenario_id, callback, showLoading)),
  updateScenarioData: (data) => dispatch(updateScenarioData(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(ScenarioActions);
