import { useMemo } from 'react';
import { isBlank } from "../../helpers/common";

const formatFromOption = ({ label, start_date }) => ({ label, value: start_date })
const formatToOption = ({ label, end_date }) => ({ label, value: end_date })

export const getMemorizedOptions = ({ from, to, options }) => {
  const validToOptions = useMemo(() => {
    return (isBlank(from) ? options : options.slice(options.map(option => option.value).indexOf(from) + 1)).map(formatToOption)
  }, [options, from])
  const validFromOptions = useMemo(() => {
    return (isBlank(to) ? options : options.slice(0, options.map(option => option.value).indexOf(to))).map(formatFromOption)
  }, [options, to])

  return {
    validToOptions, validFromOptions
  }
}
