import React from 'react';
import { connect } from "react-redux";
import { isHomepageSectionLoading, loadDashboardTemplates } from "../../../store/homepage/actions";
import TemplateTile from "../dsight/tiles/TemplateTile";
import { isDSightTemplate } from "../../../helpers/template_helpers";
import { Link } from "react-router-dom";
import { genNullResultsSection, TemplateCombinedList } from "../helpers/CombinedList";

export const templateSortValue = (template) =>
  isDSightTemplate(template) ?
    [0, template.created_at] :
    [1, -1*template.created_at]

const TemplateSetTile = ({ template_set, startDecisionSet }) =>
  <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <div className="text-dark text-center">
      <div className="position-absolute top-0 ps-2">
        <i className="text-muted fas fa-list-check fa-lg" />
      </div>
      <div className="set-folder tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title mb-3">{template_set.name}</h4>
        <Link to={`/template_sets/${template_set.slug}/preview`}>
          <div className="btn btn-secondary align-center h-36 py-1 w-100 mb-2">
            <span>Preview decision flow</span>
          </div>
        </Link>
        <div className="btn btn-primary align-center h-36 py-1 w-100 pointer" onClick={() => startDecisionSet(template_set)}>
          <i className="fas fa-plus fa-lg me-2" />
          <span>Create decision flow</span>
        </div>
      </div>
    </div>
  </div>

const TemplatesList = ({
                         showNullResults, setSearchQuery, setLoading,
                         home, filteredTemplates,
                         loadDashboardTemplates, isDecisionSet, selectedDecisionSet,
                         filtersUsed = false,
                         ...opts
                       }) => {
  if (isHomepageSectionLoading(home, 'create_decision') || isHomepageSectionLoading(home, 'template_sets')) return null;

  const renderRow = ({ type, ...t }) =>
    type === 'Template' ?
      <TemplateTile template={t} key={`homepage-template-tile-${t.slug}`}
                    isDecisionSet={isDecisionSet} {...opts} /> :
      type === 'TemplateSet' ?
        <TemplateSetTile key={`homepage-template-set-tile-${t.slug}`} template_set={t} {...opts} /> :
        null

  return <TemplateCombinedList
    getTemplates={() => filteredTemplates}
    getTemplateSets={() => home.template_sets.data}
    renderNullResults={() => genNullResultsSection({ home, setSearchQuery, setLoading, action: loadDashboardTemplates })}
    {...{ showNullResults, renderRow, isDecisionSet, selectedDecisionSet, filtersUsed }}
  />
}
const mapStateToProps = ({ home }) => ({ home });
const mapDispatchToProps = (dispatch) => ({
  loadDashboardTemplates: (callback) => { dispatch(loadDashboardTemplates(callback)) }
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplatesList);
