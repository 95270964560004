import { isBlank } from "../../helpers/common";
import { N_A_AG_GRID_VALUE } from "./ag_grid_vars";

export const validatedResult = (result) => {
  return isNaN(result) || !isFinite(result) || isBlank(result) ? N_A_AG_GRID_VALUE : result;
}

export const calcSimVsValue = (simRoot, compareRoot) => {
  return (simRoot - compareRoot) / compareRoot * 100;
}

export const calcYARootFromPrevSimVsYa = (prevRootValue, prevSimVsYaValue) => {
  return prevRootValue / (1 + parseFloat(prevSimVsYaValue) / 100);
}

export const calcRootFromSimVs = (rootValue, simVs) => {
  return rootValue + rootValue * (parseFloat(simVs) / 100);
}

export const calcRootBySimVsRootValue = (rootValue, simVsRootValue) => {
  return (parseFloat(rootValue) - simVsRootValue) / simVsRootValue * 100
}
