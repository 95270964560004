import React, { Fragment, useCallback, useState } from 'react';
import { connect } from "react-redux";
import SignInHeader from "./header"
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button';
import { isBlank, isEmail, isPresent } from "../helpers/common";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import { sendLoginLink, updateSignInUserData } from "../store/sign_in/actions";
import { isEnter } from "../helpers/keys_helpers";

const LoginLink = ({ signIn, sendLoginLink, updateSignInUserData }) => {
  const [email, setEmail] = useState(signIn.email)
  const [submit, setSubmit] = useState(false)
  const [loginLinkEmail, setLoginLinkEmail] = useState(false)

  const onSubmit = useCallback(() => {
    setSubmit(true)
    sendLoginLink(email, (email) => {
      setSubmit(false)
      if (isPresent(email)) {
        setLoginLinkEmail(email)
      }
    })
  }, [email])

  const onKeyDown = useCallback((e) => {
    if(isEnter(e.keyCode) && isPresent(email)) { onSubmit(); }
  }, [email])

  const cacheEmail = useCallback(() => updateSignInUserData(email), [email])

  return <Fragment>
    <SignInHeader />
    <Container>
      <div className="bg-white rounded p-3 my-3 mx-auto sign_in">
        <div className="header text-center">
          <h2>Send log-in link</h2>
        </div>
        <div className="row" hidden={isPresent(loginLinkEmail)}>
          <div className="col text-center mb-3">
            <span className={`text-muted`}>
              If you have a Cloverpop account or an invite to join we will send a link to your email that will grant you access.
            </span>
          </div>
        </div>
        <div className="row" hidden={isPresent(loginLinkEmail)}>
          <div className="col">
            <div className="mb-2">
              <input id="user-email" name="user[email]" type={`email`}
                     className={`form-control rounded`}
                     placeholder={`Email address`} value={email} autoFocus={true}
                     disabled={submit} onKeyDown={onKeyDown} onBlur={cacheEmail}
                     onChange={(e) => { setEmail(e.target.value) }}/>
            </div>
          </div>
        </div>
        <div className="row" hidden={isPresent(loginLinkEmail)}>
          <div className="col">
            <div className="mb-3">
              <Button type="button" className="btn btn-primary w-100"
                      onClick={onSubmit}
                      disabled={isBlank(email) || !isEmail(email) || submit}>
                Send log-in link
              </Button>
            </div>
          </div>
        </div>
        <div hidden={isBlank(loginLinkEmail)}>
          <div className='alert alert-success text-center'>
            <b className="fw-bold">Success! </b>
            <span>We sent a log-in link to {loginLinkEmail}.</span>
          </div>
          <div className="text-center mb-2">
            <span>Wrong email?  </span>
            <a className={`pointer`} onClick={() => setLoginLinkEmail(false)}>Re-enter your address.</a>
          </div>
        </div>
        <div className="row" >
          <div className="col">
            <Link to={Routes.reset_password} className={`pointer`}>
              Reset password
            </Link>
          </div>
          <div className="col text-end">
            <Link to={Routes.sign_in} className={`pointer`}>
              Log in
            </Link>
          </div>
        </div>
      </div>
    </Container>
  </Fragment>
}
const mapStateToProps = ({ signIn }) => ({ signIn });
const mapDispatchToProps = (dispatch) => ({
  sendLoginLink: (email, callback) => {
    dispatch(sendLoginLink(email, callback))
  },
  updateSignInUserData: (email) => {
    dispatch(updateSignInUserData({ email }))
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginLink);
