import React from 'react';
import { connect } from "react-redux";
import { setHelpPanelOpen } from "../../store/sidebar/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";

const HelpHamburgerButton = ({ openHelpPanel, hidden = false }) =>
  !hidden && <BaseHamburgerBtn onSelect={openHelpPanel} title="Help & resources" />

const mapDispatchToProps = (dispatch) => ({
  openHelpPanel: () => dispatch(setHelpPanelOpen(true))
});
export default connect(null, mapDispatchToProps)(HelpHamburgerButton);
