// Helpers
import {
  TEMPLATE_LOAD_DATA,
  TEMPLATE_LOAD_DATA_FAILED,
  TEMPLATE_LOAD_DATA_STARTED,
  TEMPLATE_RESET_DATA,
  TEMPLATE_UPDATE_DATA
} from "./types";

export const resetTemplate = (data = {}) => ({
  type: TEMPLATE_RESET_DATA,
  payload: {
    ...data
  }
});
export const loadTemplateSuccess = (data) => ({
  type: TEMPLATE_LOAD_DATA,
  payload: {
    ...data
  }
});
export const updateTemplateData = (data) => ({
  type: TEMPLATE_UPDATE_DATA,
  payload: {
    ...data
  }
});
export const loadTemplateStarted = (query = {}) => ({
  type: TEMPLATE_LOAD_DATA_STARTED,
  payload: {
    ...query
  }
});
export const loadTemplateFailure = error => ({
  type: TEMPLATE_LOAD_DATA_FAILED,
  payload: {
    error
  }
});
