import { UPDATE_USER_DATA, UPDATE_USER_FAILED } from "./types";

const initialState = {
    error: '',
    email: '',
    first_name: '',
    last_name: '',
    full_name: '',
    avatar_url: '',
    large_avatar_url: '',
    decision_role: 'visitor',
    org_role: '',
    notifier: '',
    group_slugs: [],
    loaded: false,
    loading: false,
    signedIn: false
};

export function currentUserReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case UPDATE_USER_DATA: {
            return {
                ...state,
                ...action.payload,
                error: ''
            };
        }
        case UPDATE_USER_FAILED: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
}
