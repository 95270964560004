import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import Recommendation from "../../../../models/recommendation";
import { isBlank, isPresent } from "../../../../helpers/common";
import {
  createRecommendationChoice,
  destroyRecommendationChoice,
  setRecommendationInputSidebarOpen, updateDecisionAssignedRecommender,
  updateRecommendationChoice,
  updateRecommendationFinalChoices
} from "../../../../store/sidebar/actions";
import { JoditTextEditor } from '../../../../common/JoditTextEditor';
import { isDSightDecision } from "../../../../helpers/home_decision_helpers";
import { saveSideBarData } from "../../../../store/sidebar/common_actions";
import { isDoneClicked } from "../../../../helpers/sidebar_helpers";
import Decision from "../../../../models/decision";

const OpenEndedChoices = ({ sidebar,
                            decision, current_user,
                            createRecommendationChoice,
                            updateRecommendationChoice,
                            destroyRecommendationChoice,
                            updateRecommendationFinalChoices,
                            updateDecisionAssignedRecommender,
                            setRecommendationInputSidebarOpen }) => {
  const recommendation = decision.recommendation;
  if (isBlank(recommendation)) return null;

  const decisionObj = decision && new Decision(decision);
  const recommendationObj = new Recommendation(recommendation);
  const openEndedChoices = recommendationObj.openEndedChoice;
  const previousOpenEndedChoice = openEndedChoices[0];
  const previousOpenEndedChoiceDescription = previousOpenEndedChoice?.description || '';
  const [loading, setLoading] = useState(true);
  const [openEndedChoice, setOpenEndedChoice] = useState(previousOpenEndedChoiceDescription);
  const focusElement = useRef(null);
  const recommenderEmail = recommendation?.assigned_recommender_email
  const skipUpdate = !decisionObj.isOpenEndedRecommendation || (decisionObj.isEnteredRecommendation && isPresent(openEndedChoice))

  useEffect(() => {
    if(!loading && !sidebar.recommendationInputSidebar) {
      return () => {
        onChangeOpenEndedChoice(_, true);
      }
    }
    setLoading(false)
  }, [sidebar.recommendationInputSidebar, focusElement.current]);

  const sidebarSubmitted = () => isDoneClicked('recommendation-input');

  const onChangeOpenEndedChoice = (e, ignoreSidebarUpdate = true) => {
    if (openEndedChoice.trim() === previousOpenEndedChoiceDescription.trim()) return e;

    if(isBlank(openEndedChoice) && isPresent(previousOpenEndedChoice)) {
      destroyRecommendationChoice(previousOpenEndedChoice.slug, ignoreSidebarUpdate);
      if(isDSightDecision(decision)) setRecommendationInputSidebarOpen(false);
    } else if(isPresent(openEndedChoice) && isBlank(previousOpenEndedChoice)) {
      createRecommendationChoice({choice: {description: openEndedChoice, ignore_line_breaks: true, open_ended: true }}, updateRecommendationFinalChoices, ignoreSidebarUpdate, !sidebarSubmitted());
    } else if(isPresent(previousOpenEndedChoice)) {
      updateRecommendationChoice(previousOpenEndedChoice.slug, {choice: {description: openEndedChoice, ignore_line_breaks: true }}, updateRecommendationFinalChoices, ignoreSidebarUpdate);
    }
  };

  useEffect(() => {
    if (skipUpdate) return;

    if (isPresent(openEndedChoice) && isBlank(recommenderEmail)) {
      updateDecisionAssignedRecommender(current_user.email)
    }
    if (isBlank(openEndedChoice) && recommenderEmail === current_user.email) {
      updateDecisionAssignedRecommender(null)
    }
  }, [openEndedChoice]);

  return <JoditTextEditor className="jodit-container-default"
                          ref={focusElement}
                          value={openEndedChoice}
                          setValue={setOpenEndedChoice}
                          placeholder="Enter the recommendation" />
};

const mapStateToProps = ({sidebar,current_user}) => ({ decision: sidebar.decision, sidebar, current_user });
export default connect(mapStateToProps, {
  createRecommendationChoice, updateRecommendationChoice, destroyRecommendationChoice, updateRecommendationFinalChoices,
  setRecommendationInputSidebarOpen, saveSideBarData, updateDecisionAssignedRecommender
})(OpenEndedChoices);
