import React from "react";
import { Link } from "react-router-dom";
import { decisionLink } from "./helpers";
import {safeDescription} from "../../../helpers/decision_helpers";
import {ButtonText, buttonTextColor} from "./DecisionTile";


const DecisionPollTile = ({decision}) => {
  const subTitle = 'Decision poll'
  const link =`${decisionLink(decision)}?left_side_bar_open=decision_poll_panel`

  return <div className="col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <Link to={link} target="_self" className="text-dark text-center">
      <div className="tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title">
          {safeDescription(decision)}
        </h4>
        <div className="fw-bolder lh-normal assignedSubTitleRow mb-3">
          {subTitle}
        </div>
        <h5 className="rounded-pill bg-light w-100">
          <span className={buttonTextColor(decision, false, false, {}, true)}>
            <ButtonText {...{ decision, isPoll: true }}/>
          </span>
        </h5>
      </div>
    </Link>
  </div>
}
export default DecisionPollTile;
