import TreeChannel, { TREE_CHANNEL_ACTIONS } from "../../../channels/tree_channel";
import EntryPoint from "../EntryPoint";
import { isBlank, isPresent } from "./common";

// TreeChannel helpers
export const treeChannelIsConnected = () => isPresent(EntryPoint.instance.TreeChannelSubscription)

export const isDriverInEditModeByUser = (channels, action, user) => {
  return channels.tree[action]?.some((data) => data.editing_by_email === user.email)
}

export const isDriverInEditModeByOthers = (channels, action, slug, user) => {
  if(isBlank(channels.tree[action])) return false;

  const driverData = channels.tree[action].find((driverData) => driverData.driver_slug === slug && driverData.edit_mode)
  return isPresent(driverData) && driverData.editing_by_email !== user.email
}

export const performEditDriverDetailsAction = (user = {}, slug = '', editMode = false) => {
  const actionData = {
    action: TREE_CHANNEL_ACTIONS.edit_driver_details,
    editing_by: user.full_name || '',
    editing_by_email: user.email || '',
    driver_slug: slug,
    edit_mode: editMode
  }
  EntryPoint.instance.TreeChannelSubscription.send(actionData)
}

export const performEditDriverAnswerAction = (user = {}, slug = '', editMode = false) => {
  const actionData = {
    action: TREE_CHANNEL_ACTIONS.edit_driver_answer,
    editing_by: user.full_name || '',
    editing_by_email: user.email || '',
    driver_slug: slug,
    edit_mode: editMode
  }
  EntryPoint.instance.TreeChannelSubscription.send(actionData)
}

export const performToggleSidebarAction = (user = {}, slug = '', editMode = false) => {
  const actionData = {
    action: TREE_CHANNEL_ACTIONS.edit_driver_answer,
    editing_by: user.full_name || '',
    editing_by_email: user.email || '',
    driver_slug: slug,
    edit_mode: editMode
  }
  EntryPoint.instance.TreeChannelSubscription.send(actionData)
}

export const initTreeChannelSubscription = (decision, user, updateTreeChannelCallback, closeDriverInputSidebarBySlug,
                                            closeAndResetDriverDetailsModal) => {
  if(isBlank(EntryPoint.instance.TreeChannelSubscription)) {
    EntryPoint.instance.TreeChannelSubscription = TreeChannel.initInstance(decision.slug).initSubscription()
    EntryPoint.instance.TreeChannelSubscription.received = (data) => {
      if(needCloseDriverInput(data, user)) {
        closeDriverInputSidebarBySlug(data['last_action_data']['driver_slug']);
      }
      if(needCloseDriverDetailsModal(data, user)) {
        closeAndResetDriverDetailsModal(data['last_action_data']['driver_slug'])
      }
      updateTreeChannelCallback(data);
    }
  }
}

const needCloseDriverInput = (data, user) => {
  return isPresent(data['last_action_data']) && user.email === data['last_action_data']['editing_by_email']
    && !data['last_action_data']['edit_mode']
    && data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.edit_driver_answer
}

const needCloseDriverDetailsModal = (data, user) => {
  return isPresent(data['last_action_data']) && user.email === data['last_action_data']['editing_by_email']
    && !data['last_action_data']['edit_mode']
    && data['last_action_data']['action'] === TREE_CHANNEL_ACTIONS.edit_driver_details
}

export const isLastActionMadeByUser = (channels, user) => {
  if(isBlank(channels.tree.last_action_data)) return false;

  return channels.tree.last_action_data.editing_by_email === user.email
}
