
// Common functions
import { RESET_DECISION_SET_DATA, UPDATE_DECISION_SET_DATA, UPDATE_SETS_FAILED } from "./types";

export const updateDecisionSetData = (data) => ({
  type: UPDATE_DECISION_SET_DATA,
  payload: {
    ...data
  }
});

export const resetDecisionSetData = () => ({
  type: RESET_DECISION_SET_DATA
});

export const updateSetsFailure = error => ({
  type: UPDATE_SETS_FAILED,
  payload: {
    error,
    loading: false
  }
});
