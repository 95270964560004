import { linkedForecastScenarioDataSources, linkedReportDataSources } from "../../../../models/decision";
import { Type } from "../../../../models/data_source";

export const sourcesData = (current_org, finalRelatedDataSources) => {
  const allLinkedReports = linkedReportDataSources(finalRelatedDataSources);
  const allLinkedReportsSlugs = allLinkedReports.map((source) => source.report_slug)
  const allLinkedReportsOnDecisionSlugs = allLinkedReports.filter((source) => !source.hide_in_decision_panel).map((source) => source.report_slug)
  const allLinkedReportsOnRecommendationSlugs = allLinkedReports.filter((source) => !source.hide_in_recommendation_panel).map((source) => source.report_slug)
  const orgReportsForDetailsPanel = current_org.reports.filter((r) => !allLinkedReportsSlugs.includes(r.slug))
  const orgReportsForDecisionPanel = current_org.reports.filter((r) => !allLinkedReportsOnDecisionSlugs.includes(r.slug))
  const orgReportsForRecommendationPanel = current_org.reports.filter((r) => !allLinkedReportsOnRecommendationSlugs.includes(r.slug))

  const allLinkedForecastScenarios = linkedForecastScenarioDataSources(finalRelatedDataSources);
  const allLinkedForecastScenariosSlugs = allLinkedForecastScenarios.map((source) => source.scenario_id)
  const allLinkedForecastScenariosOnDecisionSlugs = allLinkedForecastScenarios.filter((source) => !source.hide_in_decision_panel).map((source) => source.scenario_id)
  const allLinkedForecastScenariosOnRecommendationSlugs = allLinkedForecastScenarios.filter((source) => !source.hide_in_recommendation_panel).map((source) => source.scenario_id)
  const orgForecastScenariosForDetailsPanel = current_org.forecast_simulator_scenarios.filter((r) => !allLinkedForecastScenariosSlugs.includes(r.id))
  const orgForecastScenariosDecisionPanel = current_org.forecast_simulator_scenarios.filter((r) => !allLinkedForecastScenariosOnDecisionSlugs.includes(r.slug))
  const orgForecastScenariosRecommendationPanel = current_org.forecast_simulator_scenarios.filter((r) => !allLinkedForecastScenariosOnRecommendationSlugs.includes(r.slug))

  return {
    orgReportsForDecisionPanel, orgReportsForRecommendationPanel, orgReportsForDetailsPanel,
    decision_sources: finalRelatedDataSources.filter((source) => source.hide_in_decision_panel && source.type !== Type.LINKED_REPORT_DATA_SOURCE),
    recommendation_sources: finalRelatedDataSources.filter((source) => source.hide_in_recommendation_panel && source.type !== Type.LINKED_REPORT_DATA_SOURCE),

    orgForecastScenariosForDetailsPanel, orgForecastScenariosDecisionPanel, orgForecastScenariosRecommendationPanel
  }
}
