import React, {Fragment} from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { isBlank, qSortArray } from "../../helpers/common";
import * as moment from "moment";
import { DataSourcesRow } from "./DriverShowModal";
import { findDriverData } from "../accordion_tree/shared/helpers";
import { ModalDoneFooter } from "../../common/modals";
import { RichTextSection } from "../../common/RichTextEditor";
import { DEFAULT_DRIVER_TYPE_NAME } from "../../models/driver";
import ModalHeaderTitle from "../../common/ModalHeaderTitle";

export const showPreviewDriverDetailsModal = (modal) => modal.type === "PreviewDriverDetailsModal" && modal.shown

export const mapModalDriverWithSourcesToProps = ({ modal, org_driver_types }) => {
  const driverData = findDriverData(modal, modal.slug);
  const driver = driverData.driver || {};
  const driverType = org_driver_types?.available_types.find(({ slug }) => slug === driver.driver_type_slug)
  const dataSources = modal.data_sources || [];
  const driverSourcesSlugs = driverData.driver_sources_slugs || [];
  const driverDataSources = dataSources.filter((source) => driverSourcesSlugs.includes(source.slug));
  const sortedDriverDataSources = qSortArray(driverDataSources, true,(o) => moment(o.created_at));
  return {
    driver,
    driverType,
    driverDataSources: sortedDriverDataSources
  }
}

const NotesSection = ({ driver }) =>
  isBlank(driver?.notes) ? null :
    <div>
      <h3>Notes</h3>
      <RichTextSection className="note_paragraph lh-sm" text={driver.notes} />
    </div>

export const PreviewDriverBody = ({ driver, driverDataSources }) =>
  <Fragment>
    <NotesSection driver={driver} />
    <DataSourcesRow driverDataSources={driverDataSources} driver={driver} applyAccordion={false}/>
  </Fragment>

const PreviewDriverDetailsModal = ({
                                     driver, driverDataSources, driverType,
                                     shown, onClose
                                  }) => {
  return <Modal size="lg" backdrop="static" show={shown} onHide={onClose}>
    <Modal.Body>
      <ModalHeaderTitle {...{ onClose, title: driverType?.name || DEFAULT_DRIVER_TYPE_NAME }} />
      <div className="h3 mb-3">{driver.question}</div>
      <PreviewDriverBody driver={driver} driverDataSources={driverDataSources} />
    </Modal.Body>
    <ModalDoneFooter onClose={onClose} />
  </Modal>}

const mapStateToProps = (state) => mapModalDriverWithSourcesToProps({modal: state.modal, org_driver_types: state.org_driver_types});
export default connect(mapStateToProps)(PreviewDriverDetailsModal);
