import React from 'react';
import { isBlank } from "./common";
import { v4 as uuidv4 } from 'uuid'

export const regex = /((ed2k|ftp|http|https|irc|mailto|news|gopher|nntp|telnet|webcal|xmpp|callto|feed|svn|urn|aim|rsync|tag|ssh|sftp|rtsp|afs|file):\/\/|www)(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim;
// const regex = /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;

export function htmlParser(text, truncate = true) {
  if (isBlank(text)) return ""
  const random = uuidv4();

  return text.split('\n').map((item, key) => {
      return <div key={`string-part-${random}-${key}`} className="d-inline">{linkTag(item, random, key, truncate)}<br/></div>
    }
  )
};

export function htmlParserNoBrakeLine(text, truncate = true) {
    if (isBlank(text)) return ""
    const random = uuidv4();

    return text.split('\n').map((item, key) => {
            return <div key={`string-part-${random}-${key}`} className="d-inline">{linkTag(item, random, key, truncate)}</div>
        }
    )
};

export function linkTag(words, line_key, parent_key, truncate) {
   const words_list = words.split(' ').map((item, key) => {
    if (item.match(regex) !== null) {
      const random = uuidv4();
      return <span  key={`link-${random}-${key}`} className={`words-list-link`}><a target="_blank" className={item.length>64 && truncate ? 'truncated' : ''} title={item} href={`${item.replace('www', '//www')}`}>{item} </a></span>
    } else {
      return (isBlank(item) ? ' ' : `${item} `)
    }
  })

  return (<div key={`words-list-${line_key}-${parent_key}`} className="d-inline">{words_list}</div>)
}

export const isHtmlIncludesBody = (htmlString) => {
  return /<[^>]*>[^<]+<\/[^>]*>/i.test(htmlString);
}

