import React from 'react';
import { StatusAndQuestion } from "../../tree_view/nodes/ZTreeDriverNode";
import AddDriver from './actions/AddDriver';
import EditDriver from './actions/EditDriver';
import EditDecision from './actions/EditDecision';
import RemoveDriver from './actions/RemoveDriver';
import { connect } from "react-redux";

const ZTreeHoverDriverNode = ({ stepRef, key, tree, treeNode, ...opts }) => {
  // jsx
  return <div className="d-flex align-items-center h-inherit" key={key}>
    <StatusAndQuestion treeNode={treeNode}
                       classes={`selectable-area ${tree.copied_ztree_node.slug === treeNode.slug ? 'copied' : ''} text-truncate`} />
    <div className="ms-auto">
      <AddDriver treeNode={treeNode} stepRef={stepRef} {...opts}  />
      {
        treeNode.isDecision ?
          <EditDecision treeNode={treeNode} {...opts} /> :
          <EditDriver stepRef={stepRef} treeNode={treeNode} {...opts} />}
      <RemoveDriver treeNode={treeNode} stepRef={stepRef} {...opts} />
    </div>
  </div>
}
const mapStateToProps = ({ tree }) => ({ tree });
export default connect(mapStateToProps)(ZTreeHoverDriverNode);
