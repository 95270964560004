import React from 'react';
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import {
  setDecisionFilesSummarizeSidebarOpen,
  setDriverFilesSummarizeSidebarOpen,
  setRecommendationFilesSummarizeSidebarOpen
} from "../../../store/sidebar/actions";
import { isBlank, } from "../../../helpers/common";
import { isDSightDecision } from "../../../helpers/home_decision_helpers";
import DataSource, { Type } from "../../../models/data_source";

const SummarizeFilesButton = ({ sidebar, setDriverFilesSummarizeSidebarOpen, driverData, decision,
                                type, setRecommendationFilesSummarizeSidebarOpen, dataSources,
                                setDecisionFilesSummarizeSidebarOpen, current_org, addClass = '' }) => {
  if (isBlank(driverData) || !current_org.allow_gpt_suggestions || isBlank(dataSources)) return null;
  if (isDSightDecision(decision)) return null;

  const openFilesSummarizePanel = () => {
    if (type === 'decision') setDecisionFilesSummarizeSidebarOpen(true);
    if (type === 'recommendation') setRecommendationFilesSummarizeSidebarOpen(true);
    if (type === 'driver') setDriverFilesSummarizeSidebarOpen(true, sidebar.driverSlug);
  };

  return <div className={`${addClass}`}>
    <Button onClick={openFilesSummarizePanel} className="btn-secondary btn w-100 h-auto mb-2" style={{ minHeight: 48 }}>
      Summarize data sources with CloverpopAI
    </Button>
  </div>
};

export const filterAndSortDataSources = (allDataSources) => {
  const selectableDataSources = allDataSources.filter(source => Type.DECISION_DATA_SOURCE === source.type);
  const allowedDataSources = selectableDataSources.filter(source => new DataSource(source).isAiSupported);
  return allowedDataSources.sort((x, y) => ((x.title || x.link_url) > (y.title || y.link_url) ? 1 : -1));
}
export const getFilteredDataSources = (allowedDataSources, hideInPanel) => {
  return allowedDataSources.filter(source => !hideInPanel || !source[hideInPanel]);
};

const mapStateToProps = ({ sidebar, decision, current_org }) => ({ decision, current_org, sidebar });

export default connect(mapStateToProps, {
  setRecommendationFilesSummarizeSidebarOpen, setDecisionFilesSummarizeSidebarOpen, setDriverFilesSummarizeSidebarOpen
})(SummarizeFilesButton);
