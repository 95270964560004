import React from 'react';
import { isBlank } from "../../helpers/common";
import { iframeLinkPrepare, transformSelfLink } from "../../helpers/link_helpers";
import Tableau from "../side_panel/Tableau";

export default ({isTableau, source, maximize = false}) => {
  if(isBlank(source?.link_url)) return null

  const preparedLink = iframeLinkPrepare(source);

  return<>
    {
      isTableau ?
        <div className='tableau-iframe'>
          <Tableau link={source.link_url}/>
        </div> :
        <div className='iframe-view'>
          <iframe src={preparedLink || transformSelfLink(source.link_url)} className={`${maximize ? 'iframe-view maximize' : 'iframe-view minimize'}`} />
        </div>
    }
  </>
}
