import React from 'react';
import { connect } from "react-redux";
import { Highlighter, Token } from 'react-bootstrap-typeahead';
import BaseTypehead from "./BaseTypehead";

const renderNewItemTag = (option, { onRemove, tabIndex, disabled }, index) =>
  <Token {...{ key: index, onRemove, tabIndex, disabled, option }}>
    {option.name}
  </Token>

const renderNewItem = (option, { text }, _index) =>
  <Highlighter search={text}>{option.name}</Highlighter>

export const GroupsTypehead = ({
                                 id = "groups-input",
                                 groups,
                                 values = [], setValues = (_) => {},
                                 ...opts
                               }) =>
  <BaseTypehead
    {...opts}
    id={id} labelKey="name" filterBy={['name']}
    selected={values} onChange={setValues} options={groups}
    renderMenuItemChildren={renderNewItem}
    renderToken={renderNewItemTag}
  />
const mapStateToProps = ({ home }) => ({ groups: home.groups.data });
export default connect(mapStateToProps)(GroupsTypehead);
