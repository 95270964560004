import { isBlank } from "../../../helpers/common";
import {
  chartBasesValueFor, filteredProcessData,
  findProcessDataFor, isImpressionDifference, isSegmentationSupported, lastSurveys
} from "../../../models/d_sight/metric_chart_helpers";

export const reFlowCharts = (ref, useEffect) => {
  useEffect(() => {
    const chart = ref.current?.chart;
    if (chart) chart.reflow(false);
  }, [ref.current])
}

export const findSelectedDemographic = (d_sight_goal) => {
  if (isBlank(d_sight_goal.selected_demographic)) return {};
  if (isBlank(d_sight_goal.demographics)) return {};

  const selectedSegment = d_sight_goal.demographics.find(segment =>
    segment.options.some(({id}) => parseInt(id) === parseInt(d_sight_goal.selected_demographic))
  )
  if (isBlank(selectedSegment)) return {};

  const selectedDemographic = selectedSegment.options.find(({id}) => parseInt(id) === parseInt(d_sight_goal.selected_demographic))
  return {
    segment: selectedSegment,
    option: selectedDemographic
  }
}

export const filtersSurveysBySubMetrics = (d_sight_goal, timeNode, metrics) =>
  lastSurveys(d_sight_goal, timeNode).filter(survey =>
    metrics?.every(h => {
      const filtered_process_data = filteredProcessData(h, d_sight_goal)
      const targetProcessData = findProcessDataFor(filtered_process_data, parseInt(d_sight_goal.target.name), survey.node_index)
      return chartBasesValueFor(survey, targetProcessData) > 0
    })
  )

export const transformsMetricsBy = (metrics, lever, d_sight_goal) => {
  if (!isSegmentationSupported(d_sight_goal)) return metrics;

  return metrics.map(metric => {
    if (isImpressionDifference(metric)) {
      if (lever.isProductPerformance) {
        metric.data.chain_data.display_name = 'Product Attributes'
      }
      if (lever.isImageryAttributes) {
        metric.data.chain_data.display_name = 'Imagery Attributes'
      }
    }
    return metric;
  })
}
