import React, { useState } from "react";
import { isBlank, isPresent } from "../../helpers/common";

export const DEFAULT_SCOPES = {
  simulated: 'simulated',
  any_simulated: 'any_simulated',
  visible: 'visible',
  custom: 'custom'
}
export const SCOPES_LABELS = {
  simulated: 'simulated items',
  any_simulated: 'any simulated',
  visible: 'visible in table',
}

export const cmuScopes = (config, forecastScenario, prevScopes, initDefaultScope = DEFAULT_SCOPES.visible) => {
  const prevDefaultScope = forecastScenario.viewOptions.defaultScope;
  const scopesData = prevScopes || {}
  const [defaultScope, setDefaultScope] = useState(prevDefaultScope || initDefaultScope);
  const [scopes, setScopes] = useState(
    config.cmuColumns.reduce((result, cmuColumn) => {
      result[cmuColumn.id.toString()] = scopesData[cmuColumn.id] || { scope: initDefaultScope, cmus: [] }
      return result;
    }, {})
  )

  const onChangeDefaultScope = (newDefaultScope) => {
    if (defaultScope === newDefaultScope) return;

    setScopes(
      {
        ...scopes,
        ...Object.keys(scopes).filter(cmuId => scopes[cmuId].scope === defaultScope).reduce((result, cmuId) => {
          result[cmuId] = { scope: newDefaultScope, cmus: [] }
          return result;
        }, {})
      }
    )
    setDefaultScope(newDefaultScope)
  }

  const updateScope = (columnId, cmus) => {
    const newScopesData = {}
    if (isBlank(cmus)) {
      newScopesData[columnId] = { cmus: [], scope: defaultScope }
    } else {
      newScopesData[columnId] = { cmus, scope: DEFAULT_SCOPES.custom }
    }
    setScopes({...scopes, ...newScopesData})
  }

  return { defaultScope, scopes, onChangeDefaultScope, updateScope }
}

export const selectedCmuCombinations = (scopes) => {
  const result  = []
  const scopesArray = Object.values(scopes).map(h => h.cmus).filter(isPresent)
  const iterateRow = (resRow = [], rowIndex = 0) => {
    const scopeSubArray = scopesArray[rowIndex]
    if (isPresent(scopeSubArray)) {
      scopeSubArray.forEach(cmu => iterateRow([...resRow, cmu], rowIndex+1))
    } else {
      result.push(resRow)
    }
  }
  iterateRow()
  return result;
}
