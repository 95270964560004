import React from 'react';
import {connect} from "react-redux";
import Button from 'react-bootstrap/Button';
import {deleteAndStartActions, mapStartDeleteDispatchToProps} from "../../tree_view/header/helpers";
import {isDecider} from "../../helpers/user_helpers";

const DeleteSection = ({decision, modal, current_org, user, ...opts}) => {
  if (!isDecider(user) || decision.flow_enum_type !== 'Cloverpop') return null;
  if (current_org.slug !== user.org_slug) return null;

  const { deleteDecisionHandler } = deleteAndStartActions({decision, ...opts})

  return <div className={'p-3'}>
    <Button onClick={deleteDecisionHandler} className="btn-light text-danger w-100 mb-0">
      Delete decision
    </Button>
  </div>
}

const mapStateToProps = ({ current_user, decision, modal, current_org }) => ({ user: current_user, decision, modal, current_org });
export default connect(mapStateToProps, mapStartDeleteDispatchToProps)(DeleteSection);
