import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import Select from 'react-select';
import { ChoiceEntryStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import { isBlank } from "../../../helpers/common";
import { updateViewOptions } from "../../../store/forecast_simulator_scenario/actions";

const TotalsSelect = ({
                        aggregatedType, setAggregatedType,
                        config,
                        updateViewOptions,
                        loading = false
                      }) => {
  const cmusForSelect = useMemo(() => config.cmuColumns.map(dr => dr.selectHash), [config])
  const onChangeTotal = (selectedHash) => {
    updateViewOptions({ selectedSubTotal: selectedHash.value }, (status) => {
      if(status) setAggregatedType(selectedHash.value);
    })
  }
  useEffect(() => {
    if (isBlank(aggregatedType)) {
      setAggregatedType(cmusForSelect[0].value)
    }
  }, [config])
  return <>
    <div>
      <div className='mb-1'>Totals</div>
      <Select onChange={onChangeTotal}
              defaultValue={cmusForSelect.find(hash => hash.value === aggregatedType)}
              components={{ IndicatorSeparator:() => null }}
              options={cmusForSelect}
              className ={"DriversSelect"}
              classNamePrefix={"DriversSelect"}
              value={cmusForSelect.find(hash => hash.value === aggregatedType)}
              isDisabled={loading}
              styles={ChoiceEntryStyle} />
    </div>
  </>;
}
const mapStateToProps = ({ forecast_simulator_scenario }) => ({ forecast_simulator_scenario });
export default connect(mapStateToProps, { updateViewOptions })(TotalsSelect);
