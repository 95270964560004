import React, { useEffect, useRef, useState, useMemo } from 'react';
import { connect } from "react-redux";
import Decision from "../../../../models/decision";
import { isBlank, isPresent } from "../../../../helpers/common";
import {
  createChoice,
  destroyChoice,
  updateChoice, updateDecisionAssignedDecider,
  updateFinalChoices
} from "../../../../store/sidebar/actions";
import { JoditTextEditor } from '../../../../common/JoditTextEditor';
import { saveSideBarData } from "../../../../store/sidebar/common_actions";
import { isDoneClicked } from "../../../../helpers/sidebar_helpers";

const OpenEndedChoices = ({ sidebar,
                            decision,
                            current_user,
                            createChoice,
                            updateChoice,
                            destroyChoice,
                            updateDecisionAssignedDecider,
                            updateFinalChoices }) => {
  if (isBlank(decision)) return null;

  const decisionObj = new Decision(decision);
  const openEndedChoices = decisionObj.openEndedChoice;
  const previousOpenEndedChoice = openEndedChoices[0];
  const previousOpenEndedChoiceDescription = previousOpenEndedChoice?.description || ''
  const [loading, setLoading] = useState(true);
  const [openEndedChoice, setOpenEndedChoice] = useState(previousOpenEndedChoiceDescription);
  const focusElement = useRef(null);
  const deciderEmail = decision?.assigned_decider_email
  const skipUpdate = !decisionObj.isOpenEnded || (decisionObj.isDecisionRecorded && isPresent(openEndedChoice))

  useEffect(() => {
    if(!loading && !sidebar.decisionInputSidebar) {
      return () => {
        onChangeOpenEndedChoice()
      }
    }
    setLoading(false)
  }, [sidebar.decisionInputSidebar, focusElement.current]);

  const sidebarSubmitted = () => isDoneClicked('decision-input');

  const onChangeOpenEndedChoice = (ignoreSidebarUpdate = true) => {
    if (openEndedChoice.trim() === previousOpenEndedChoiceDescription.trim())
      return;

    if (isBlank(openEndedChoice) && isPresent(previousOpenEndedChoice)) {
      destroyChoice(previousOpenEndedChoice.slug, ignoreSidebarUpdate);
    } else if (isPresent(openEndedChoice) && isBlank(previousOpenEndedChoice)) {
      createChoice({choice: {description: openEndedChoice, ignore_line_breaks: true, open_ended: true }}, updateFinalChoices, ignoreSidebarUpdate, !sidebarSubmitted());
    } else if (isPresent(previousOpenEndedChoice)) {
      updateChoice(previousOpenEndedChoice.slug, {choice: {description: openEndedChoice, ignore_line_breaks: true }}, updateFinalChoices, ignoreSidebarUpdate);
    }
  };

  useEffect(() => {
    if (skipUpdate) return;

    if (isPresent(openEndedChoice) && isBlank(deciderEmail)) {
      updateDecisionAssignedDecider(current_user.email)
    }
    if (isBlank(openEndedChoice) && deciderEmail === current_user.email) {
      updateDecisionAssignedDecider(null)
    }
  }, [openEndedChoice]);

  return <JoditTextEditor className="jodit-container-default"
                          ref={focusElement}
                          value={openEndedChoice}
                          setValue={setOpenEndedChoice}
                          placeholder={'Enter what was decided'}/>
};

const mapStateToProps = ({sidebar, current_user}) => ({ decision: sidebar.decision, sidebar, current_user });
export default connect(mapStateToProps, {
  createChoice, updateChoice, destroyChoice, updateFinalChoices, saveSideBarData, updateDecisionAssignedDecider
})(OpenEndedChoices);
