import React from 'react';
import { connect } from "react-redux";
import TemplateCard from './cards/TemplateCard';
import TemplatePreviewDriverCard from './cards/TemplatePreviewDriverCard';
import { isPresent } from '../../helpers/common';
import { openModal } from "../../store/modals/actions";
import Driver from "../../models/driver";
import { treeInitData } from "./shared/helpers";

export const displayDriverPreviewData = (driverData) => isPresent(driverData?.driver?.notes) || isPresent(driverData?.driver_sources_slugs);
export const displayDSightDriverPreviewData = (driverData) => {
  const insightsData = new Driver(driverData.driver).insightsData;
  return isPresent(insightsData);
};


const TemplatePreviewTree = ({ tree, template, playbook_notes, user, contactsData, openModal }) => {
  const { rootDrivers } = treeInitData(tree, {})

  const clickableTemplate = () => isPresent(playbook_notes?.decision_title) || isPresent(playbook_notes?.how_to)

  const onTemplateClick = () => {
    if (clickableTemplate()) {
      openModal({
        decision: template,
        drivers: tree.drivers,
        data_sources: tree.data_sources,
        slug: template.slug,
        type: 'PreviewDecisionDetailsModal'
      })
    }
  }

  return <div className="container-fluid p-0">
    <div className="px-2 my-3 mx-auto vertical-tree">
      <TemplateCard {... { template, onTemplateClick, clickable: clickableTemplate(), showArrow: rootDrivers.length > 0 } } />
      {
        rootDrivers.map((driverData, index) =>
          <TemplatePreviewDriverCard key={`template-preview-driver-card-${driverData.driver.slug}`}
                              {...{ driverData, tree, contactsData, user, template, openModal,
                                shortBorderToArrow: rootDrivers.length === 1 || index === rootDrivers.length - 1 } } />
        )
      }
    </div>
  </div>
}
const mapStateToProps = ({ tree, template, current_user, playbook_notes, contacts }) => ({
  tree, template, playbook_notes,
  user: current_user,
  contactsData: contacts
});
export default connect(mapStateToProps, { openModal })(TemplatePreviewTree);
