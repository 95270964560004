import React, {useState} from "react";
import { connect } from "react-redux";
import { isBlank } from "../../helpers/common";
import { updateTreeData } from "../../store/tree/common_actions";
import { closeModal, openModal, resetModal } from "../../store/modals/actions";
import { ActionButtons, resetCopyPaste } from "../tree_view/DriverActions";
import GptSuggestionsModal from "../../tree_view/modals/GptSuggestionsModal";
import {saveTemplateWizardStepData, saveWizardStepData} from "../../store/wizard/actions";

const DriverActions = ({
                         stepRef,
                         modal,
                         tree,
                         drivers,
                         setDrivers,
                         decision,
                         openModal,
                         closeModal,
                         resetModal,
                         updateTreeData,
                         onCopyDriver,
                         current_org,
                         saveWizardStepData,
                         saveTemplateWizardStepData,
                         isTemplate,
                         wizard,
                         ...opts
                      }) => {
  const [showGptSuggestionModal, setShowGptSuggestionModal] = useState(false)
  const emptyRootDriver = drivers.find((driverData) => isBlank(driverData.driver.question))
  const [isPastDriver, setIsPastDriver] = useState(false)

  const onAddDriver = () => openModal({
    decision,
    drivers: drivers,
    data_sources: tree.data_sources,
    slug: emptyRootDriver.driver.slug,
    type: 'WizardAddDriverDetailsModal'
  })

  const onPasteDriver = () => {
    const selectedSlug = tree.selected_ztree_node.slug
    const { slug } = tree.copied_ztree_node
    onCopyDriver(selectedSlug, slug, (success, newDrivers) => {
      if(success) {
        stepRef?.current.submitStep({ drivers: newDrivers, finish_later: true }, (success) => {
          if(success && tree.loaded) updateTreeData({ drivers: newDrivers })
        })
        setIsPastDriver(true)
        resetCopyPaste(updateTreeData)
      }
    });
  }

  return <>
    <ActionButtons {...{ current_org, tree, onAddDriver, onPasteDriver, updateTreeData, drivers, isTemplate,
                         setShowGptSuggestionModal, saveWizardStepData, saveTemplateWizardStepData, wizard,
                         setIsPastDriver, isPastDriver}} {...opts} />
    <div className="modals">
      <GptSuggestionsModal shown={showGptSuggestionModal} onClose={() => setShowGptSuggestionModal(false)}
                           drivers={drivers} setDrivers={setDrivers} />
    </div>
  </>
}

const mapStateToProps = ({ current_org, decision, tree, template, modal, wizard },  { isTemplate }) => ({
  current_org, modal, tree, decision: isTemplate ? template : decision, wizard
});
export default connect(mapStateToProps, {
  openModal, closeModal, resetModal, updateTreeData, saveWizardStepData, saveTemplateWizardStepData
})(DriverActions);
