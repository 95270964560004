import React, { useCallback, useMemo } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ActionsDropdown from "./ActionsDropdown";
import { resetSidebars } from "../../store/sidebar/actions";
import { goToHomepageCallback } from "../../tree_wizard/header/helpers";
import { resetReportData } from "../../store/report/actions";
import { forceReloadHomepageReportInsights } from "../../store/homepage/actions";
import HamburgerDropdown from "./HamburgerDropdown";
import { isAdmin, isMember } from "../../helpers/home_helpers";
import OrgLogo from "../../tree_view/header/OrgLogo";

const Header = ({ report, current_user, current_org, resetSidebars, resetReport, forceReloadHomepageReportInsights }) => {
  const history = useHistory();
  const isAdminOrMember = useMemo(() => isAdmin(current_user) || isMember(current_user), [current_user]);

  const processBackClick = useCallback(() => {
    goToHomepageCallback([resetSidebars, resetReport, forceReloadHomepageReportInsights], history)()
  }, [])

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo current_org={current_org} processBackClick={processBackClick}/>
        </div>
        <h5>Report insights</h5>
      </div>
      <div className="navbar-nav ms-auto" hidden={!isAdminOrMember}>
        <div className='d-lg-none'>
          <HamburgerDropdown processBackClick={processBackClick} />
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          <li className="nav-item ms-2">
            <ActionsDropdown btnId="header-actions-dropdown" />
          </li>
        </ul>
      </div>
    </nav>
  </header>
}

const mapStateToProps = ({ report, current_user, current_org }) => ({ report, current_user, current_org });

const mapDispatchToProps = (dispatch) => ({
  resetSidebars: () => dispatch(resetSidebars()),
  resetReport: () => dispatch(resetReportData()),
  forceReloadHomepageReportInsights: () => dispatch(forceReloadHomepageReportInsights()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
