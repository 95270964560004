import {useEffect} from "react";
import DataSource from "../../models/data_source";
import {isPresent} from "../../helpers/common";

export const MAX_CHARACTERS = 80

const headers = {
  Accept: 'image/jpg, image/jpeg, image/png',
  'Content-Type': 'image/jpg, image/jpeg, image/png',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': '*',
  'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With',
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

const getImageData = (url) => {
  // timestamp param is needed for cache-busting purposes, ensuring that the browser fetches a fresh copy of the resource.
  const urlWithTimestamp = `${url}?timestamp=${Date.now()}`;
  return fetch(urlWithTimestamp, { headers })
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          resolve({
            dataUrl: reader.result,
            dimensions: {
              width: img.width,
              height: img.height
            }
          });
        };
        img.onerror = () => {
          reject("Failed to load the image.");
        };
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob)
    }));
};

export const base64DataSourcesCallback = (sortedDriverDataSources, setDataSources, setExtraImages, current_org, setShow) => {
  useEffect(() => {
    const updateDataSources = async () => {
      try {
        const updatedDataSources = await Promise.all(
          sortedDriverDataSources.map(async (dataSource) => {
            try {
              if (new DataSource(dataSource).isPdfImage) {
                const { dataUrl, dimensions } = await getImageData(dataSource.url);
                return {...dataSource, base64: dataUrl.split(',')[1], dimensions};
              } else {
                return {...dataSource, base64: "", dimensions: {}};
              }
            } catch (error) {
              console.error(`Error processing dataSource: ${dataSource.url}`, error);
              return {...dataSource, base64: "", dimensions: {}};
            }
          })
        );
        const { dataUrl, dimensions } = await getImageData(current_org.logo_horizontal_direct_link);
        setExtraImages({base64: dataUrl.split(',')[1], dimensions: dimensions});
        setDataSources(updatedDataSources.filter(source => isPresent(source)));
        setShow(true);
      } catch (error) {
        console.error('Error updating data sources', error);
        setShow(false);
      }
    };

    updateDataSources().then();
  }, [sortedDriverDataSources.length, current_org.logo_horizontal_direct_link])
}

export const truncateTextWithDots = (text) => {
  if (text.length <= MAX_CHARACTERS) return text;

  return text.slice(0, MAX_CHARACTERS) + '...';
}