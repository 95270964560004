import React, {useEffect, useState} from 'react';
import ChoiceRow from "../../modals/helpers/ChoiceRow";
import {Button} from "react-bootstrap";
import {isPresent} from "../../../helpers/common";
import AccordionWrapper from "../../../common/AccordionWrapper";

const ConsideredChoiceSection = ({ choices, newChoice, onChangeChoice, setNewChoice,
                                   onSaveConsideredChoice = () => {}, deleteConsideredChoice = () => {},
                                   addNewChoice, submitted, prevConsideredChoices, className='px-3 mb-3',
                                   setMouseOverRemoveSlug = () => {} }) => {

  const [isMouseOverButton, setIsMouseOverButton] = useState(false);
  const [isMouseOverInput, setIsMouseOverInput] = useState(false);

  const handleFocusNewInput = () => {
    const slug = choices.find(({ slug }) => slug && slug.includes('new-'))?.slug;
    slug && isMouseOverInput && $(`#decisionChoice-${slug}`).focus()
  }

  useEffect(() => {
    if (!submitted && isMouseOverButton && (prevConsideredChoices.length === choices.length)) {
      addNewChoice()
    }
    handleFocusNewInput()
  }, [submitted])

  const accordionObject = {
    header: <h3 className="mb-0">Other choices considered</h3>,
    body: <>
      {
        choices.map(({ slug, ...hash }) =>
          <ChoiceRow key={`rowDecisionChoice-${slug}`}
                     {...{...hash, slug, newChoice, onChangeChoice, setNewChoice, onSaveConsideredChoice, deleteConsideredChoice, setIsMouseOverInput, setMouseOverRemoveSlug}}
          />
        )
      }
      <Button onClick={addNewChoice} onMouseEnter={() => setIsMouseOverButton(true)} onMouseLeave={() => setIsMouseOverButton(false)}
              disabled={submitted} className="btn-secondary w-100">
        Add another choice
      </Button>
    </>,
    defaultExpand: isPresent(prevConsideredChoices)
  };

  return <AccordionWrapper accordionObject={accordionObject} className={className} />
}
export default ConsideredChoiceSection;