import React, {useCallback, useMemo, useEffect, useState} from 'react';
import { connect } from "react-redux";
import {Accordion, Card, Table} from 'react-bootstrap';
import EditDriverWeightsModal from "../../../tree_view/modals/EditDriverWeightsModal";
import { saveWizardStepData, saveTemplateWizardStepData } from "../../../store/wizard/actions";
import { updateTreeData } from "../../../store/tree/common_actions";
import { ratingAndWeightChoicesData, sortKeyDrivers } from "./helpers";
import { isVisitor } from "../../../helpers/user_helpers";
import CustomHeaderToggle from "../../../tree_view/side_panel/CustomHeaderToggle";
import {
  openAllActionUseEffect,
  setAccordionDropdownToggleState, toggleClickAction
} from "../../../tree_view/side_panel/helpers/accordion_helpers";

const CustomToggle = ({ driver, children, eventKey, openIndexes, setOpenIndexes }) => {
  const [openState, setOpenState] = useState(false);

  setAccordionDropdownToggleState(openIndexes, eventKey, {setOpenState})

  return (
    <div onClick={() => toggleClickAction(openIndexes, eventKey, {setOpenIndexes})}
         className="d-flex fw-bolder border-0 align-items-baseline">
      <div className="note_paragraph text-ellipsis me-1">{children}</div>
      <div className={`dropdown-toggle ms-auto ${openState ? 'rotate-after-180' : ''}`}>
        <span>{driver.weightPercentage}% ({driver.weightScore})</span>
      </div>
    </div>
  );
}

const DriverWeightRow = ({ driver, openIndexes, setOpenIndexes }) =>
  <div className='mb-2'>
    <Accordion>
      <Card className="border-0">
        <Card.Header className="bg-white border-0 pointer p-0 mb-1">
          <CustomToggle eventKey={driver.slug} {...{driver, openIndexes, setOpenIndexes}} >
            <span className={`button me-1 ${driver.isAnswered ? 'answered' : 'non-answered'}`} />
            {driver.question}
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={driver.slug} className={`${openIndexes.includes(driver.slug) ? 'show' : ''}`}>
          <Card.Body className="p-0">
            <Table borderless className="mb-0">
              <thead>
              <tr>
                <th className="col-10 fw-bolder text-gray p-0 pe-2">Choice</th>
                <th className="col-1 fw-bolder text-gray p-0 text-end">Rating</th>
              </tr>
              </thead>
              <tbody className="note_paragraph">
              {
                driver.sortedDriverChoicesRatings.map(rating =>
                  <tr key={`driver-weights-${driver.slug}-choice-${rating.choice_slug}`}>
                    <td  className="p-0 pe2">{rating.choiceDescription}</td>
                    <td  className="p-0 text-end">
                      <span className="text-muted">N/A</span>
                    </td>
                  </tr>
                )
              }
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </div>


const DriverWeightsSection = ({ isTemplate = false, decision, wizard, saveWizardStepData, saveTemplateWizardStepData, updateTreeData, current_user }) => {
  const { keyDrivers, decisionObj, driversStep, drivers } = ratingAndWeightChoicesData(decision, wizard);
  const submissionCallback = isTemplate ? saveTemplateWizardStepData : saveWizardStepData;
  const [openAll, setOpenAll] = useState(false);
  const [openIndexes, setOpenIndexes] = useState([]);
  const [editModalShown, setEditModalShown] = useState(false);
  const openEditDriverWeightsModal = useCallback(() => {
    setEditModalShown(true);
  }, [decision, wizard]);
  const allRateChoicesSlugs = useMemo(() => keyDrivers.map((driver) => driver.driver.slug), [keyDrivers]);
  const sortedKeyDrivers = useMemo(() => sortKeyDrivers(keyDrivers), [keyDrivers]);
  const updateWeights = ({ weights }) => {
    const updateData = {
      drivers: drivers.map(h => {
        const weight = weights.find(({slug}) => h.driver.slug === slug)?.weight
        return { ...h, driver: { ...h.driver, weight } }
      })
    }
    submissionCallback(driversStep, {
      ...updateData,
      finish_later: true
    }, (success) => {
      if (success) {
        updateTreeData({ ...updateData })
      }
    })
  };

  openAllActionUseEffect(openAll, openIndexes, allRateChoicesSlugs, {setOpenIndexes, setOpenAll});

  if (keyDrivers.length === 0) return null;

  return <>
    <div className="px-3 pb-3">
      <CustomHeaderToggle {...{openAll, setOpenAll}}>
        <h3>Driver weights</h3>
      </CustomHeaderToggle>
      {
        sortedKeyDrivers.map(driver =>
          <DriverWeightRow {...{driver, openIndexes, setOpenIndexes}} key={`driver-row-${driver.driver.slug}`} />)
      }
      <div className="btn btn-secondary w-100 py-2 pointer" onClick={openEditDriverWeightsModal} hidden={isTemplate ? false : isVisitor(current_user)}>
        <span>Edit driver weights</span>
      </div>
    </div>
    <div className="modals">
      <EditDriverWeightsModal shown={editModalShown} decisionObj={decisionObj}
                              onClose={() => setEditModalShown(false)}
                              onSubmit={updateWeights}/>
    </div>
  </>
};

const mapStateToProps = ({ decision, wizard, current_user }) => ({ decision, wizard, current_user });
export default connect(mapStateToProps, { saveWizardStepData, saveTemplateWizardStepData, updateTreeData })(DriverWeightsSection);
