import { UPDATE_SIGN_IN_DATA, UPDATE_SIGN_IN_FAILED } from "./types";

const initialState = {
    error: '',
    email: '',
    loading: false,
    signedIn: false
};

export function signInReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case UPDATE_SIGN_IN_DATA: {
            return {
                ...state,
                ...action.payload,
                error: ''
            };
        }
        case UPDATE_SIGN_IN_FAILED: {
            return {
                ...state,
                ...action.payload
            };
        }
        default:
            return state;
    }
}
