import { isBlank } from "../helpers/common";

const ORG_TYPE = 'Org';
const SET_TYPE = 'DecisionSet';

export const itemIdentifier = (item, idField = 'admittable_id') => `${item.admittable_type}-${item[idField]}`

export default class ShareData {
  constructor(share_data) {
    this.share_data = share_data
  }

  get isShared() {
    return this.share_data.sharable;
  }

  get availableItems() {
    return this.share_data.available_items;
  }

  get availableDecisionShareItems() {
    return this.share_data.available_decision_shared_items;
  }

  get availableOrgShareItems() {
    return this.share_data.available_org_shared_items;
  }

  get entireShareOrg() {
    return this.availableOrgShareItems.find(row => row.admittable_type === ORG_TYPE)
  }

  get selectedItems() {
    return this.share_data.values;
  }

  get entireOrg() {
    return this.availableItems.find(row => row.admittable_type === ORG_TYPE)
  }

  get entireDecisionSet() {
    return this.availableItems.find(row => row.admittable_type === SET_TYPE)
  }

  get entireOrgLabel() {
    return this.entireOrg?.label?.replace('All', 'all')
  }

  get couldShareOrg() {
    if (isBlank(this.entireOrg)) return false;

    return !this.selectedItems.some(row => row.admittable_type === ORG_TYPE);
  }

  get couldShareSet() {
    if (isBlank(this.entireDecisionSet)) return false;

    return !this.selectedItems.some(row => row.admittable_type === SET_TYPE);
  }
}
