import { useState } from 'react';

export const homeSearchState = (home) => {
  const [searchQuery, setSearchQuery] = useState(home.search);
  const [loading, setLoading] = useState(true);

  const resetSearch = () => { setSearchQuery(''); };

  return {
    searchQuery, setSearchQuery,
    loading, setLoading, resetSearch
  }
}
