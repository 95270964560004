import { sortNodes as sortAdvNodes } from "../dsight/analysis/analysis_metrics/charts/advertising/helpers";
import { sortNodes as sortNonBrandNodes } from "../dsight/analysis/analysis_metrics/charts/other_types/helpers";
import { sortBrands } from "../models/d_sight/metric_chart_helpers";

export const getNodeName = ({ nodes }, nodeId) => nodes.find(n => n.id === nodeId)?.display_name || ''

export const sortNodesByType = (metric, nodes, d_sight_goal) => {
  if (metric.isAdvertisingMetric(d_sight_goal)) {
    return sortAdvNodes(nodes, d_sight_goal);
  } else if (metric.isNonBrandMetric(d_sight_goal)) {
    return sortNonBrandNodes(nodes)
  } else {
    return sortBrands(d_sight_goal.target.name, nodes);
  }
}

export const chartYAxis = (metric, yAxisOpts = {}, labelOpts = {}) => ({
  yAxis: {
    labels: {
      format: `{text}${metric.measure}`,
      style: {
        fontSize: '0.75rem',
        color: '#1F2F3F'
      },
      ...labelOpts
    },
    gridLineColor: '#DCE8F3',
    title: { text: '' },
    ...yAxisOpts
  }
})
