import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { Highlighter, Token, Typeahead } from 'react-bootstrap-typeahead';
import { loadContacts } from "../../../../store/contacts/actions";
import { loadAdmissions } from "../../../../store/decision_admissions/actions";
import { autoFocusRef } from "../../../../common/BaseTypehead";
import { checkLoadingEffect } from "../../../../helpers/callbacks_helpers";

const MEMBER_ROLE = 'member'

const renderNewItemTag = (option, { onRemove, tabIndex, disabled = false, org_role }, index) =>
  <Token {...{ onRemove, tabIndex, option, key: index }} disabled={disabled || (org_role === MEMBER_ROLE && index === 0)}>
    {option.name}
  </Token>

const renderNewItem = (option, { text }, _index) =>
  <React.Fragment>
    <Highlighter search={text}>{option.full_name || option.value}</Highlighter>
  </React.Fragment>

const SharedWithTypeahead = (
  {
    allowNew = false,
    multiple = true,
    autoFocus = false,
    disabled = false,
    placeholder = '',
    id="invites-input",
    values = [],
    setValues = (_) => {},
    setLoaded = () => {},
    minLength = 1,
    setErrors = () => {},
    flip = true,
    // From redux
    contactsData,
    decision_admissions,
    loadAdmissions,
  }) => {
  const allContactsData = decision_admissions.decision_admissions.map((admission) => {
    return {...admission, value: admission.name };
  });
  const ref = autoFocusRef({ autoFocus, setLoaded })

  checkLoadingEffect(decision_admissions, loadAdmissions)

  const findItemBySlug = useCallback((slug) =>
      (allContactsData.find(i => i.slug === slug))
    , [contactsData])

  const onChangeNewItems = useCallback((selected) => {
    const newItems = [];
    selected.forEach(item => {
      newItems.push(findItemBySlug(item.slug))
    })
    setValues(newItems)
    setErrors({});
  }, [decision_admissions, setValues])

  const filteredNewUsersItems = useCallback(() => {
    return allContactsData.filter(contact => {
      return !values.some(({slug}) => slug === contact.value)
    })
  }, [allContactsData, values])

  return <Typeahead
    {...{ multiple, allowNew, placeholder, id, ref, disabled, minLength, flip }}
    allowNew={allowNew}
    labelKey="value"
    filterBy={['value', 'name']}
    selected={values}
    options={filteredNewUsersItems()}
    onChange={onChangeNewItems}
    renderMenuItemChildren={renderNewItem}
    renderToken={renderNewItemTag} />
}
const mapStateToProps = ({ contacts, current_user, decision_admissions }) => ({
  contactsData: contacts,
  current_user,
  decision_admissions
});
export default connect(mapStateToProps, { loadContacts, loadAdmissions })(SharedWithTypeahead);
