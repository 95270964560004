import React from "react";
import UserAvatarImage from "../../../common/UserAvatarImage";
import { TooltipWrapper } from "../../../bubble_tooltip/BubbleTooltip";
import { isBlank } from "../../../helpers/common";
import { isPendingContact } from "../../../helpers/decision_helpers";
import { userName } from "../../../helpers/user_helpers";

export default ({ assignedUser, bubbleIcon }) => {
  if(isBlank(assignedUser)) return null;

  return <TooltipWrapper bubbleIcon={bubbleIcon} tooltipText={`Assigned to ${userName(assignedUser)}`} isDriver={true}>
    <div className={`ms-auto ${isBlank(assignedUser) ? 'hidden' : ''}`}>
      <UserAvatarImage user={assignedUser} isPendingUser={isPendingContact(assignedUser) || isBlank(assignedUser.full_name)} size='sm' />
    </div>
  </TooltipWrapper>
}
