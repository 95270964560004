import React from "react";
import { connect } from "react-redux";
import { removeDecisionSetSection } from "../../../../store/decision_set/actions";
import { removeTemplateSetSection } from "../../../../store/template_set/actions";
import BaseHamburgerBtn from "../../../../common/BaseHamburgerBtn";

const RemoveSection = ({
                         treeNode, isTemplateSet = false,
                         removeDecisionSetSection, removeTemplateSetSection
                      }) => {
  const removeSection = isTemplateSet ? removeTemplateSetSection : removeDecisionSetSection
  const onRemove = () => removeSection(treeNode.slug)

  return <BaseHamburgerBtn onSelect={onRemove}
                           className="text-danger"
                           title={`Ungroup section`} />
}
export default connect(null, {removeDecisionSetSection, removeTemplateSetSection })(RemoveSection);
