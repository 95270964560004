import React, {useMemo, useState} from 'react';
import { connect } from "react-redux";
import { Accordion, Card, Table } from 'react-bootstrap'
import { ChoiceWeights } from "../../../models/DriverChoiceRating";
import { ratingAndWeightChoicesData } from "./helpers";
import CustomHeaderToggle from "../../../tree_view/side_panel/CustomHeaderToggle";
import {
  openAllActionUseEffect,
  setAccordionDropdownToggleState, toggleClickAction
} from "../../../tree_view/side_panel/helpers/accordion_helpers";

const CustomToggle = ({ children, eventKey, openIndexes, setOpenIndexes }) => {
  const [openState, setOpenState] = useState(false);

  setAccordionDropdownToggleState(openIndexes, eventKey, {setOpenState})

  return (
    <div onClick={() => toggleClickAction(openIndexes, eventKey, {setOpenIndexes})}
         className="d-flex fw-bolder border-0 align-items-baseline">
      <div className="note_paragraph text-ellipsis me-1">{children}</div>
      <div className={`dropdown-toggle ms-auto ${openState ? 'rotate-after-180' : ''}`}>
        <span>0</span>
      </div>
    </div>
  );
}

const WeightedChoiceRow = ({ choice, keyDrivers, openIndexes, setOpenIndexes }) => {
  const obj = new ChoiceWeights(choice, keyDrivers)

  return <div className='mb-2'>
    <Accordion>
      <Card className="border-0">
        <Card.Header className="bg-white border-0 pointer p-0 mb-1">
          <CustomToggle eventKey={choice.slug} {...{obj, openIndexes, setOpenIndexes}}>{choice.description}</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={choice.slug} className={`${openIndexes.includes(choice.slug) ? 'show' : ''}`}>
          <Card.Body className="p-0">
            <Table borderless className="mb-0">
              <thead>
              <tr>
                <th className="col-10 fw-bolder text-gray p-0 pe-2">Driver</th>
                <th className="col-1 fw-bolder text-gray p-0 pe-2 text-end">Weight</th>
                <th className="col-1 fw-bolder text-gray p-0 text-end">Rating</th>
              </tr>
              </thead>
              <tbody className="note_paragraph">
              {
                obj.ratings.map(rating =>
                  <tr key={`choice-weights-${choice.slug}-driver-${rating.driverSlug}`}>
                    <td className="pointer p-0 pe-2">
                      <span className={`button me-1 non-answered`} />
                      <span>{rating.driverQuestion}</span>
                    </td>
                    <td className="p-0 pe-2 text-end">
                      {rating.driverWeight}%
                    </td>
                    <td className="p-0 text-end">
                      <span className="text-muted">N/A</span>
                    </td>
                  </tr>
                )
              }
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </div>
}

const WeightedRatingOfChoicesSection = ({ decision, wizard, ...opts }) => {
  const { keyDrivers, choices } = ratingAndWeightChoicesData(decision, wizard)
  const [openAll, setOpenAll] = useState(false);
  const [openIndexes, setOpenIndexes] = useState([]);
  const allRateChoicesSlugs = useMemo(() => choices.map((choice) => choice.slug), [choices]);

  openAllActionUseEffect(openAll, openIndexes, allRateChoicesSlugs, {setOpenIndexes, setOpenAll});

  return <div className="px-3 pb-3">
    <CustomHeaderToggle {...{openAll, setOpenAll}}>
      <h3>Weighted rating of choices</h3>
    </CustomHeaderToggle>
    {
      choices.map(choice =>
        <WeightedChoiceRow key={`choice-row-${choice.slug}`} {...{openIndexes, setOpenIndexes, choice, keyDrivers, decision}} {...opts}/>
      )
    }
  </div>
}

const mapStateToProps = ({ decision, wizard }) => ({ wizard, decision });
export default connect(mapStateToProps)(WeightedRatingOfChoicesSection);
