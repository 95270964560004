import React from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import {headerDispatchProps} from "../../tree_wizard/header";
import {
  headerActionsDispatchProps
} from "../../tree_wizard/header/ActionsDropdown";
import {useHistory} from "react-router-dom";
import {successActions} from "../../helpers/common";
import {redirectToHomeCallback} from "../../helpers/decision_helpers";

const cancelDecision = ({deleteDecision, actions, history}) => () => {
  deleteDecision((success) => {
    redirectToHomeCallback(success, history);
    successActions(success, actions);
  })
}

const CancelHeaderButton = ({ deleteDecision, forceReloadHomepageDecisions,
                              resetSidebars, resetWizard, resetTree, resetPlaybookNotes,
                              resetSearchAndFilters}) => {

  const history = useHistory();

  const deleteDecisionHandler = () => cancelDecision({deleteDecision, actions: [forceReloadHomepageDecisions, resetSidebars, resetWizard, resetTree, resetPlaybookNotes, resetSearchAndFilters], history})();


  return <li className="nav-item ms-2">
    <Button onClick={deleteDecisionHandler} className="btn btn-light btn btn-light-primary">Cancel</Button>
  </li>
}

const mapStateToProps = ({ wizard, decision }) => ({ wizard, decision });
const mapDispatchToProps = (dispatch) => ({
  ...headerDispatchProps(dispatch),
  ...headerActionsDispatchProps(dispatch)
});
const wrapper = React.forwardRef((props, ref) => <CancelHeaderButton {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
