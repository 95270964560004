import React, { useEffect, useRef, useState } from 'react';

function OutsideClickDetector({ children, onOutsideClick }) {
  const ref = useRef(null);
  const [isClickOutside, setIsClickOutside] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsClickOutside(true);
        if (onOutsideClick) onOutsideClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick]);

  return (
    <div className="d-flex align-items-center w-100" ref={ref}>
      {children}
    </div>
  );
}

export default OutsideClickDetector;
