import React from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../../helpers/common";
import Alerts from "../../../../js/alerts";
import { removeSetInvite, transferFlowTo } from "../../../store/decision_set/actions";
import UserAvatarImage from "../../../common/UserAvatarImage";
import { BaseDropdownBtn } from "../../../common/BaseHamburgerBtn";
import { IconBtnDropdown } from "../../../common/dropdowns";
import { userName } from "../../../helpers/user_helpers";

const removeInvite = (removeSetInvite, slug, name) => {
  Alerts.warning({
    title: `Are you sure you want to remove ${name}?`,
  }).then(confirmed => {
    if (confirmed) {
      removeSetInvite(slug)
    }
  }, () => {})
}


const makeManager = (user, transferFlowTo) => {
  Alerts.warning({
    title: `Are you sure you want ${user.full_name} to replace you as the manager of this decision flow?`,
    text: "You will no longer be able to manage other members.",
  }).then(confirmed => {
    if (confirmed) {
      transferFlowTo(user?.email)
    }
  }, () => {})
}

const flowMemberMenuEvents = {
  makeManager: 'makeManager',
  removeInvite: 'removeInvite'
}

export const InviteRow = ({
                            slug, user, email, removeSetInvite, transferFlowTo, isManager
                          }) => {
  const name = userName(user, email)
  const isUserManager = slug === 'manager'

  const flowMemberMenuSelect = (eventKey) => {
    switch (eventKey) {
      case flowMemberMenuEvents.makeManager:
        return makeManager(user, transferFlowTo)
      case flowMemberMenuEvents.removeInvite:
        return removeInvite(removeSetInvite, slug, user?.full_name || email)
    }
  }

  return <div className="d-flex mb-1 align-items-center">
    <span className="collaborator_img">
      <UserAvatarImage user={user} size='md' />
    </span>
    <span className="px-2 text-truncate">{name} { isUserManager ? <span className="text-muted">(manager)</span> : '' }</span>

    <div className="ms-auto" hidden={isUserManager || !isManager}>
      <IconBtnDropdown id={`members-menu-dropdown-${slug}`} onSelect={flowMemberMenuSelect}>
        <BaseDropdownBtn eventKey={flowMemberMenuEvents.makeManager} title="Make manager" hidden={isBlank(user)} />
        <BaseDropdownBtn eventKey={flowMemberMenuEvents.removeInvite} title="Remove" bsPrefix="text-danger" />
      </IconBtnDropdown>
    </div>
  </div>
}
const mapStateToProps = ({ current_user, tree }) => ({
  current_user, default_user_avatar_url: tree.default_user_avatar_url
});
export default connect(mapStateToProps, { removeSetInvite, transferFlowTo })(InviteRow);
