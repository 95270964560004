import React from 'react';
import { isBlank } from "../../../helpers/common";
import { RichTextSection } from "../../../common/RichTextEditor";

export const NextStepsSection = ({ object }) => {
  if (isBlank(object.next_steps_description)) return null;

  return <div className="px-3 mb-3">
    <h3>Next steps</h3>
    <RichTextSection text={object.next_steps_description}/>
  </div>;
}
export default NextStepsSection;
