import React from "react";
import WizardTreeEditor from "../../../tree_editor/wizard/WizardTreeEditor";

export default ({ stepRef, ...opts }) => {
  const refOpts = { ...opts, ref: stepRef };

  return <div className={`bg-white rounded pt-3 pb-2 px-3 ms-xl-3 mb-3`}>
    <h3>Decision tree</h3>
    <WizardTreeEditor {...refOpts} isHistoricalDecision={true}/>
  </div>
}
