import { UPDATE_ALERTS_DATA } from "./types";

export function showAlert(newAlertData) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_ALERTS_DATA,
            payload: {
                ...newAlertData,
                shown: true,
            }
        })
    };
}

export function hideAlert(dismissable=true) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_ALERTS_DATA,
            payload: {
                shown: false,
                text: '',
                type: '',
                addClass: '',
                duration: 0,
                dismissible: dismissable
            }
        })
    };
}

