import React, { useState } from 'react';
import { isBlank } from "../../helpers/common";
import { RichMutedText } from "../../common/RichTextEditor";
import CharCounter from "../../common/CharCounter";
import { DESCRIPTION_INPUT_LIMIT } from "../../models/decision";

export default React.forwardRef(({
                                   description, setDescription,
                                   playbook_notes = null, submit = false, createTemplateModal = false
                                 }, ref) => {
  const [showChar, setShowChar] = useState(false);

  return <div className="w-100">
    <h3>Decision</h3>
    <div className={'mb-2'} hidden={isBlank(playbook_notes?.decision_title)}>
      <RichMutedText text={playbook_notes?.decision_title}/>
    </div>
    <div className={createTemplateModal ? '' : 'mb-3'}>
      <CharCounter show={showChar} field={description} limit={DESCRIPTION_INPUT_LIMIT}/>
      <input className="form-control"
             id="decisionDescription"
             placeholder="Enter the decision question or title"
             value={description}
             readOnly={submit} ref={ref}
             maxLength={DESCRIPTION_INPUT_LIMIT}
             onChange={(e) => setDescription(e.target.value)}
             onBlur={() => setShowChar(false)}
             onFocus={() => setShowChar(true)}
      />
    </div>
  </div>
})
