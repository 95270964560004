import React from 'react';
import HelpButtons from "../../common/HelpButtons";
import DecisionSetHelpText from "./DecisionSetHelpText";
import TemplateSetHelpText from "./TemplateSetHelpText";

export default ({ isDecisionSet }) =>
  <>
    <div className="px-3 mb-3">
      { isDecisionSet ? <DecisionSetHelpText /> : <TemplateSetHelpText /> }
    </div>
    <HelpButtons />
  </>
