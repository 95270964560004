import React, { useState } from 'react';
import { connect } from "react-redux";
import { saveTemplateWizardStepData } from "../../../store/wizard/actions";
import { loadOrgDriverTypes } from "../../../store/org_driver_types/actions";
import Help from "../../help";
import WizardTreeEditor from "../../../tree_editor/wizard/WizardTreeEditor";
import { Table } from "react-bootstrap";
import { isLastStep, stepWithDrivers } from "../../../helpers/wizard_helpers";
import SubmitStepButton from "./SubmitStepButton";
import { checkLoadingEffect } from "../../../helpers/callbacks_helpers";
import {
  buildDrivers, onCopyDriverCallback, onRemoveDriverCallback,
  submitBuilderStepDataCallback, useAddedRootDriverEffect
} from "../../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";

const V1Header = () =>
  <>
    <span className="text-primary">Almost there! </span>
    Create a tree supporting the decision with data and insights.
  </>

const V2Header = () =>
  <>
    Start with a tree including supporting data and insights.
  </>

export const TreeBuilderStep = ({
                                  tree, wizard, template, stepRef,
                                  saveTemplateWizardStepData, org_driver_types,
                                  loadOrgDriverTypes
                                }) => {
  checkLoadingEffect(org_driver_types, loadOrgDriverTypes);
  const [submitState, setSubmitState] = useState(false)
  const [newAddedRootDriver, setNewAddedRootDriver] = useState(false)
  const [drivers, setDrivers] = useState(buildDrivers({ tree, wizard, setNewAddedRootDriver, newAddedRootDriver }))

  const onRemoveDriver = onRemoveDriverCallback(drivers, setDrivers);
  const onCopyDriver = onCopyDriverCallback(drivers, setDrivers);

  useAddedRootDriverEffect({ step: stepWithDrivers(wizard), newAddedRootDriver, submitDrivers: saveTemplateWizardStepData, drivers })

  const submitStep = submitBuilderStepDataCallback({
    drivers,
    template: { description: template.description },
    wizard: { step_index: wizard.step_index },
    complete: isLastStep(wizard)
  }, stepWithDrivers(wizard), saveTemplateWizardStepData, setSubmitState, setDrivers)

  stepRef.current = {
    submitStep: (additional_data = {}, callback = () => {}) => {
      submitStep(additional_data, (success, wizardData) => {
        setSubmitState(false)
        callback(success, false, wizardData)
      })
    },
    submitDrivers: (new_drivers) => setDrivers(new_drivers)
  };

  return <div className="bg-white rounded p-3 my-3 mx-auto tree_wizard">
    <div>
      <Table borderless className="mb-2">
        <tbody>
          <tr>
            <td className="d-flex p-0">
              <h1 className="d-table-cell">
                { isLastStep(wizard) ? <V1Header/> : <V2Header/> }
              </h1>
              <div className="d-table-cell ms-auto">
                <Help isTemplate={true} />
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      <WizardTreeEditor ref={stepRef}
                        isTemplate={true}
                        onRemoveDriver={onRemoveDriver}
                        onCopyDriver={onCopyDriver}
                        drivers={drivers}
                        setDrivers={setDrivers} />
      <SubmitStepButton onClick={() => submitStep()} disabled={wizard.submit || submitState} className="w-100 mt-4" />
    </div>
  </div>
}
const mapStateToProps = ({ tree, wizard, template, org_driver_types }) => ({
  tree, wizard, template, org_driver_types
});
const mapDispatchToProps = (dispatch) => ({
  saveTemplateWizardStepData: (step, data, callback) => { dispatch(saveTemplateWizardStepData(step, data, callback)) },
  loadOrgDriverTypes: () => { dispatch(loadOrgDriverTypes())}
});
const wrapper = React.forwardRef((props, ref) => <TreeBuilderStep {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
