import React, {Fragment} from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import {downloadDecisionCSV, downloadDriverCSV} from "../../utils/Api";
import Decision from "../../models/decision";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

const DownloadCSV = ({ decisionSlug, decision, isButton = false }) => {
  const DownloadCombinedCSV = () => {
    Promise.all([
      downloadDecisionCSV(decisionSlug),
      downloadDriverCSV(decisionSlug)
    ])
      .then(([decisionSummaryResponse, decisionDriverSummaryResponse]) => {
        const decisionBlob = decisionSummaryResponse.data;
        const driverBlob = decisionDriverSummaryResponse.data;

        const decisionTextPromise = decisionBlob.text();
        const driverTextPromise = driverBlob.text();

        return Promise.all([decisionTextPromise, driverTextPromise]);
      })
      .then(([decisionText, driverText]) => {

        const decisionData = Papa.parse(decisionText, { header: true }).data;
        const driverData = Papa.parse(driverText, { header: true }).data;

        const decisionArray = decisionData.map(row => Object.values(row));
        const driverArray = driverData.map(row => Object.values(row));

        const decisionHeaders = decisionData.length > 0 ? Object.keys(decisionData[0]) : [];
        const driverHeaders = driverData.length > 0 ? Object.keys(driverData[0]) : [];

        const modifiedDriverHeaders = driverHeaders.map(header => `Driver ${header}`);

        const combinedData = [
          decisionHeaders,
          ...decisionArray,
          [],
          modifiedDriverHeaders,
          ...driverArray
        ];

        const csv = Papa.unparse(combinedData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${decision.description}.csv`);
      })
      .catch(error => console.error('Error downloading CSV:', error));
  };

  const decisionObj = new Decision(decision);
  if (!decisionObj.isRateAndCompareDriverChoicesHidden) return null;

    return <Fragment>
      {
        isButton ?
          <Button onClick={DownloadCombinedCSV} className="btn btn-secondary w-100 mb-2">
              Download decision CSV
          </Button>:
          <Dropdown.Item as="button" disabled={!show} onClick={DownloadCombinedCSV}>Download decision CSV</Dropdown.Item>
      }
    </Fragment>
};

const mapStateToProps = ({ decision }) => ({
  decisionSlug: decision.slug,
  decision,
});

export default connect(mapStateToProps)(DownloadCSV);
