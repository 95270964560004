import React from 'react';

import { BtnDropdownToggleInlineShadowText } from "../../../common/dropdowns";
import { BaseDropdownBtn } from "../../../common/BaseHamburgerBtn";

export const SortByTypes = {
  MOST_RECENT: 'most_recent_activity',
  NEWEST: 'newest',
  OLDEST: 'oldest',
};

const SortByData = [
  { value: SortByTypes.MOST_RECENT, label: 'Most recent activity' },
  { value: SortByTypes.NEWEST, label: 'Newest' },
  { value: SortByTypes.OLDEST, label: 'Oldest' },
];


export default ({ store, onChangeSortingCriteria }) =>
  <div className="ms-auto text-end py-3">
    <div className="d-inline-flex me-1">Sort by</div>
    <BtnDropdownToggleInlineShadowText id="sort-by-dropdown" bsPrefix="pe-0" onSelect={onChangeSortingCriteria}
                                       title={SortByData.find((sortBy) => sortBy.value === store.sort_order)?.label?.toLowerCase()}>
      {SortByData.map((sortBy) =>
        <BaseDropdownBtn key={`sort-order-${sortBy.value}`} eventKey={sortBy.value} title={sortBy.label} />
      )}
    </BtnDropdownToggleInlineShadowText>
  </div>;
