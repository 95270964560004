import React from 'react';
import { connect } from "react-redux";
import { setDecisionSidebarOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import SetDescriptionSection from "./sections/SetDescriptionSection";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import { isMobile } from "react-device-detect";
import { updateTemplateSet } from "../../store/template_set/actions";

export const TemplateSetDetailsPanel = ({
                                          sidebar, template_set,
                                          openSideBar, updateTemplateSet,
                                          ...opts
                                        }) =>
  <SlidingPanel
    type="left"
    isOpen={sidebar.decisionDetailsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper details-panel-width template-panel left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
    size={52}
  >
    <PanelHeader headerName={'Details'} openSideBar={openSideBar} preview={true} />
    <SetDescriptionSection updateSet={updateTemplateSet} set={template_set} set_key='template_set' {...opts} classNames='mb-3' />
  </SlidingPanel>

const mapStateToProps = ({ sidebar, template_set }) => ({ sidebar, template_set });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => { dispatch(setDecisionSidebarOpen(options)); },
  updateTemplateSet: (value, callback) => { dispatch(updateTemplateSet(value, callback)) },
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateSetDetailsPanel);
