import React, {useMemo} from 'react';
import {DRIVER_CHOICE_ENTRY_TYPE_DATA} from "../../../models/driver";
import ChoicesSection from "./ChoicesSection";
import Decision from "../../../models/decision";
import {connect} from "react-redux";
import {updateDriverFinalChoices} from "../../../store/sidebar/actions";

export const SelectedChoiceEntryType = ({driver}) =>
  <div className="mb-2 text-muted">
    {DRIVER_CHOICE_ENTRY_TYPE_DATA[driver.choice_entry_widget_type]}
  </div>

export const DriverHeader = ({driver}) =>
  <div className='d-flex'>
    <h3 className="text-ellipsis">{ driver.question }</h3>
  </div>

const ChoiceDriverInput = ({driver, decision, drivers, updateDriverFinalChoices}) => {
  const decisionObject = useMemo(() => new Decision(decision, drivers), [decision]);
  if (!decisionObject.isRateAndCompareDriverChoicesHidden) return null;

  return <div className="mb-2 px-3">
    <DriverHeader driver={driver} />
    <SelectedChoiceEntryType driver={driver}  />
    <ChoicesSection {...{driver, updateDriverFinalChoices}} />
  </div>
}

const mapStateToProps = ({ decision, tree }) => ({ decision, drivers: tree.drivers })
export default connect(mapStateToProps, {updateDriverFinalChoices})(ChoiceDriverInput);
