import React, {useState, useMemo, useEffect, useCallback} from "react";
import DropdownWithSearch from "../../common/DropdownWithSearch"
import { isBlank, isPresent, qSortArray } from "../../helpers/common";
import { SmallLoader } from "../../common/Loader";
import { isStoreLoading } from "../../helpers/callbacks_helpers";
import { removeAllComparisonRows } from "../helpers/FactsCellRenderer";
import { scenariosOptions } from "../helpers/helpers";

const BenchmarkScenario = ({ gridRef,
                             forecast_simulator_scenario, forecastScenario,
                             loadForecastSimulatorDBenchmarkScenario, updateViewOptions,
                             updateScenario, updateScenarioData
                           }) => {
  const allOptions = useMemo(() => scenariosOptions(forecast_simulator_scenario), [forecast_simulator_scenario.config_scenarios])
  const [selectedOption, setSelectedOption] = useState(
    forecast_simulator_scenario.scenario?.view_options?.benchmark
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredScenarios, setFilteredScenarios] = useState(allOptions)
  useEffect(() => {
    if(isBlank(selectedOption)) {
      setSelectedOption(allOptions[0]?.id)
    }
  }, [selectedOption, allOptions]);
  useEffect(() => {
    if (isPresent(forecast_simulator_scenario.benchmark_error)) return;
    if (!forecast_simulator_scenario.loaded) return;
    if (!forecast_simulator_scenario.scenario_loaded) return;
    if (!forecast_simulator_scenario.ag_grid_rendered) return;
    if (forecast_simulator_scenario.benchmark_loading) return;
    if (isBlank(selectedOption)) return;

    if (selectedOption !== forecast_simulator_scenario.benchmark_id) {
      loadForecastSimulatorDBenchmarkScenario(selectedOption)
    }
  }, [forecast_simulator_scenario.loaded, forecast_simulator_scenario.scenario_loaded, selectedOption, forecast_simulator_scenario.benchmark_id, forecast_simulator_scenario.benchmark_loading, forecast_simulator_scenario.ag_grid_rendered]);
  useEffect(() => {
    setFilteredScenarios(
      allOptions.filter(hash =>
        isBlank(searchQuery.trim()) || hash.display_name.toLowerCase().includes(searchQuery.trim().toLowerCase())
      )
    )
  }, [searchQuery, allOptions]);
  const onSelectBenchmark = useCallback(scenario_id => {
    updateViewOptions({ benchmark: scenario_id }, (status) => {
      if(status) updateScenarioData({ benchmark_loaded: false })
    })
    updateScenario(forecast_simulator_scenario.scenario_id, { update_data: { opened_groups: [] } }, (status) => {
      if(status) {
        const openedGroups = forecastScenario.openedGroups || [];
        removeAllComparisonRows(openedGroups, gridRef);
      }
    }, false);
    return false;
  }, [forecast_simulator_scenario, forecastScenario])
  useEffect(() => {
    const newBenchmark = forecast_simulator_scenario.scenario?.view_options?.benchmark
    if (isPresent(newBenchmark) && newBenchmark !== selectedOption) { setSelectedOption(newBenchmark) }
  }, [forecast_simulator_scenario.scenario?.view_options?.benchmark]);

  return <div className="text-start me-2">
    <div className="fs-6 lh-1 mb-1">Benchmark scenario</div>
    {
      isPresent(forecast_simulator_scenario.benchmark_error) ?
        <span>{forecast_simulator_scenario.benchmark_error}</span> :
        isPresent(forecast_simulator_scenario.benchmark_hint) ?
          <span>{forecast_simulator_scenario.benchmark_hint}</span> :
          isStoreLoading(forecast_simulator_scenario, 'benchmark') ?
            <SmallLoader /> :
            <div className="fs-5 lh-lg" style={{ marginBottom: '-6px' }}>
              <DropdownWithSearch {...{
                selectedOption: allOptions.find(hash => hash.id === selectedOption)?.display_name,
                setSelectedOption, searchQuery, setSearchQuery,
                options: filteredScenarios,
                onClick: onSelectBenchmark
              }}/>
            </div>
    }
  </div>
}
export default BenchmarkScenario;
