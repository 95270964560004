import React from "react";
import { connect } from "react-redux";
import { isBlank, pluralize } from "../helpers/common";
import { Loader } from "../common/Loader";
import {
  formatAvgCollaborators, formatCost, formatSuccessDecisions,
  formatTotalCount,
  StatisticBlock
} from "../homepage/tabs/learning/AllDecisionsStatisticsPanel";
import { Pagination } from "../common/Pagination";
import SortByActivity from "../homepage/tabs/search/SortByActivity";
import DecisionTile from "../homepage/tabs/tiles/DecisionTile";
import { loadReportDecisions } from "../store/report/actions";
import { StartFromScratchBlock } from "../homepage/tabs/create_decision_tab/StartFromScratch";
import { closeModal, openModal, resetModal } from "../store/modals/actions";
import StartDecisionModal, { showStartDecisionModal } from "../homepage/modals/StartDecisionModal";
import DraftTile from "../homepage/tabs/create_decision_tab/DraftTile";
import { isDraftDecision } from "../models/decision_state";

const DECISIONS_PER_PAGE = 23
const openStartDecisionModal = ({ openModal, report, ...opts }) => openModal({ ...opts, type: 'StartDecisionModal', report })

const DecisionsCount = ({ report }) =>
  <div className="decisions-count text-nowrap py-3 me-3">
    {pluralize(report.total_count, 'viewable decision', 'viewable decisions')}
  </div>

const StatisticsPanel = ({ statistics }) =>
  isBlank(statistics) ? <Loader /> :
    <div className="pointer px-2">
      <div className="row all-decisions-row rounded bg-white p-3">
        <StatisticBlock number={formatTotalCount(statistics)}
                        label={'Decisions <br/>using this report'} />
        <StatisticBlock number={formatAvgCollaborators(statistics)}
                        label={'Avg users<br/>per decision'} />
        <StatisticBlock number={formatCost({ total_cost: statistics.decision_value })}
                        label={'Recorded<br/>decision value'} />
        <StatisticBlock number={formatSuccessDecisions(statistics)}
                        label={'Decision<br/>success'} />
      </div>
    </div>

const DecisionsList = ({ decisions }) => {
  if(isBlank(decisions)) return null;

  return decisions.map((decision) =>
    isDraftDecision(decision) ?
      <DraftTile decision={decision} key={`report-decision-tile-${decision.slug}`} /> :
      <DecisionTile decision={decision} isDecisionSet={false} section={'search'}
                    key={`report-decision-tile-${decision.slug}`}/>
  )
}

const DecisionsSection = ({ report, modal, loadReportDecisions, closeModal, openModal, resetModal }) => {
  const onChangeSortingCriteria = (eventKey) => loadReportDecisions({ sort_order: eventKey });
  const handlePageClick = (new_page) => loadReportDecisions({ current_page: new_page });
  const onCloseModal = () => {
    closeModal()
    resetModal()
  }

  return <>
    <h2>Decisions</h2>
    <StatisticsPanel statistics={report.statistics} />
    <div className="row">
      <div className="col d-flex">
        <DecisionsCount report={report} />
        <SortByActivity store={report} onChangeSortingCriteria={onChangeSortingCriteria}/>
      </div>
    </div>
    <div className="row loading mb-3" hidden={!report.decisions.loading}>
      <div className="col">
        <Loader/>
      </div>
    </div>
    <div className="row" hidden={report.decisions.loading}>
      <StartFromScratchBlock title="Add a decision or action taken"  addClass="py-5"
                             onClick={() => openStartDecisionModal({ openModal, report })} />
      <DecisionsList decisions={report.decisions.data} />
    </div>
    <div className="row report-decisions-pagination pb-4" hidden={report.decisions.loading || report.total_pages === 1}>
      <Pagination page={report.current_page} totalPages={report.total_pages} setPage={handlePageClick}
                  totalCount={report.total_count} perPage={DECISIONS_PER_PAGE} />
    </div>
    <div className="modals">
      <StartDecisionModal shown={showStartDecisionModal(modal)} onClose={onCloseModal} report={report} />
    </div>
  </>
}
const mapStateToProps = ({ report, modal }) => ({ report, modal });
const mapDispatchToProps = (dispatch) => ({
  loadReportDecisions: (data) => { dispatch(loadReportDecisions(data)) },
  openModal: (data) => dispatch(openModal(data)),
  closeModal: () => { dispatch(closeModal()) },
  resetModal: () => { dispatch(resetModal()) }
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionsSection);
