import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import ShareData, { itemIdentifier } from "../../../models/share_data";
import { isBlank, isPresent } from "../../../helpers/common";
import { Typeahead } from 'react-bootstrap-typeahead';
import { filterNewShared, onChangeUseStateCallback } from "../../../template_wizard/steps_wizard/steps/shared_helpers";
import {
  DEFAULT_TYPEHEAD_ATTRIBUTES,
  filteredItems,
  renderContactItem, renderTag
} from "../../../tree_view/modals/helpers/share_helpers";

const defaultShareTo = (shareObject, template_admissions) =>
  template_admissions.map(a =>
    shareObject.availableItems.find(item =>
      itemIdentifier(a, 'admittable_slug') === itemIdentifier(item)
    )
  ).filter(a => isPresent(a));

const CreateAdmissionsInput = ({
                                 template_admissions, share_data,
                                 createObjTitle = 'decisions', createFromTitle = 'this template',
                                 inputRef
                              }) => {
  const shareObject = new ShareData(share_data);
  const [shareToSelection, setShareToSelection] = useState(defaultShareTo(shareObject, template_admissions))

  useEffect(() => {
    setShareToSelection(defaultShareTo(shareObject, template_admissions))
  }, [template_admissions.length])

  const onChangeShared = onChangeUseStateCallback(setShareToSelection, shareObject)
  const addNewOrgShare = () => {
    const newShare = filterNewShared([...shareToSelection, shareObject.entireOrg])
    setShareToSelection(newShare)
  }

  inputRef.current.identifiers = () => shareToSelection.map(a => itemIdentifier(a))
  inputRef.current.resets.resetIdentifiers = () => setShareToSelection(defaultShareTo(shareObject, template_admissions))

  return <div className="w-100 mb-3">
    <h3>Who can create {createObjTitle} from {createFromTitle}?</h3>
    <div className="mb-2 text-gray" hidden={isBlank(shareObject.entireOrg)}>
      <a className="pointer" onClick={addNewOrgShare}>Click here</a> to add “{shareObject.entireOrg?.label}”.
    </div>
    <Typeahead
      {...DEFAULT_TYPEHEAD_ATTRIBUTES}
      selected={shareToSelection}
      onChange={onChangeShared}
      options={filteredItems(shareObject, shareToSelection)}
      renderMenuItemChildren={renderContactItem}
      renderToken={renderTag}
    />
  </div>
}
const mapStateToProps = ({ share_data }) => ({ share_data });
const wrapper = React.forwardRef((props, ref) => <CreateAdmissionsInput {...props} inputRef={ref} />)
export default connect(mapStateToProps, null, null, { forwardRef: true })(wrapper);
