import React from 'react';
import { connect } from "react-redux";
import Select from 'react-select';
import { BorderedFilterStyle } from "../../../tree_wizard/styles/choice_entry_styles";

export const BASIS_CHANGE_OPTIONS_KEYS = {
  current: 'current',
  simVsBenchmark: 'sim_vs_benchmark',
  simVsYearAgo: 'sim_vs_year_ago'
}

const OPTIONS = [
  { label: 'Current value', value: BASIS_CHANGE_OPTIONS_KEYS.current },
  { label: 'Sim vs BM, % (simulation vs benchmark)', value: BASIS_CHANGE_OPTIONS_KEYS.simVsBenchmark },
  { label: 'Sim vs YA, % (simulation change from a year ago)', value: BASIS_CHANGE_OPTIONS_KEYS.simVsYearAgo }
]

const BasisChange = ({ forecastScenario, basisChange, setBasisChange }) => {
  const options = forecastScenario.isAnnualTimeScale ?
    OPTIONS.filter(option => option.value !== BASIS_CHANGE_OPTIONS_KEYS.sim_vs_year_ago) :
    OPTIONS
  return <div className="mt-3 mb-0">
    <h3>Basis for change</h3>
    <Select
      defaultValue={options.find(option => option.value === basisChange) || options[0]}
      onChange={option => setBasisChange(option.value)}
      components={{ IndicatorSeparator: () => null }}
      styles={BorderedFilterStyle}
      placeholder="Make a selection"
      options={options} />
  </div>
};
const mapStateToProps = ({ sidebar }) => ({ sidebar });
export default connect(mapStateToProps)(BasisChange);
