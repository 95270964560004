import {
  AlignmentType,
  BorderStyle
} from "docx";
import {DARK, DARK_GRAY, LIGHT_COLOR, MUTED, PRIMARY_COLOR} from "../../pdf/styles/common";

export const DOCX_FONT = 'Arial'
export const FONT_LG = 33 // eq 16.5pts
export const FONT_MD = 27 // eq 13.5pts
export const FONT_SM = 21 // eq 10.5pts
export const PTS_36 = 720 // eq 36pts
export const PTS_18 = 360 // eq 18pts
export const PTS_13 = 270 // eq 13.5pts
export const PTS_4 = 90 // eq 4.5pts

export const space18pts = {
  spacing: { line: 320, before: 0, after: PTS_18 } // eq 18pts
}

export const space4d5pts = {
  spacing: { line: 320, before: 0, after: PTS_4 } // eq 4.5pts
}

export const space4d13pts = {
  spacing: { line: 276, before: PTS_13, after: PTS_4 } // eq 13.5 and 4.5pts,
}

export const spaceAll4pts = {
  spacing: { line: 276, before: PTS_4, after: PTS_4 } // eq 4.5pts,
}

export const space0pts = {
  spacing: { line: 320, before: 0, after: 0 }
}

export const borderProps = {
  border: {
    bottom: {
      color: LIGHT_COLOR,
      space: 0,
      style: BorderStyle.SINGLE,
      size: 2 * 8, //2pt;
    },
  },
}

export const mutedProps = {
  size: FONT_SM,
  font: DOCX_FONT,
  color: MUTED
}

export const tableCellProps = {
  margins: {
    left: 0,
    right: 0,
  }
}

export const docxStyles = {
  default: {
    heading1: {
      run: {
        font: DOCX_FONT,
        size: FONT_LG,
        bold: true,
        color: DARK_GRAY,
      },
      paragraph: {
        alignment: AlignmentType.LEFT,
        ...space18pts,
      },
    },
    heading2: {
      run: {
        font: DOCX_FONT,
        size: FONT_MD,
        bold: true,
        color: DARK,
      },
      paragraph: {
       ...space4d13pts,
      },
    },
    heading3: {
      run: {
        font: DOCX_FONT,
        size: FONT_MD,
        bold: false,
        color: DARK,
      },
      paragraph: {
        ...space4d13pts,
      },
    },
    heading4: {
      run: {
        font: DOCX_FONT,
        size: FONT_MD,
        bold: false,
        color: DARK,
      },
      paragraph: {
        ...space4d5pts,
      },
    },
    heading5: {
      run: {
        font: DOCX_FONT,
        size: FONT_MD,
        bold: true,
        color: DARK,
      },
      paragraph: {
        ...spaceAll4pts
      },
    }
  },
  paragraphStyles: [
    {
      id: "normalPara",
      name: "Normal Para",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        font: DOCX_FONT,
        size: FONT_SM,
        bold: false,
      },
      paragraph: {
        ...space0pts
      },
    },
    {
      id: "normalPara2",
      name: "Normal Para",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      run: {
        font: DOCX_FONT,
        size: FONT_SM,
        bold: false,
      },
      paragraph: {
        spacing: { line: 320, before: PTS_4, after: 0 } // eq 4.5pts
      },
    },
    {
      id: "mutedText",
      name: "Muted Text",
      basedOn: "Normal",
      next: "Normal",
      quickFormat: true,
      paragraph: {
        ...space0pts
      },
      run: {
        ...mutedProps,
        bold: false,
      }
    },
  ]
}