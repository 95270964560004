import React from 'react';
import Decision from "../../../models/decision";
import Recommendation from "../../../models/recommendation";

export const MIN_DECISION_OTHER_CHOICES_COUNT = 3

const Choices = ({ object }) => {
  if (object.otherChoices.length === 1) return object.otherChoices[0].description

  return <ul className="mb-0 ps-3">
    {object.otherChoices.map(c => <li key={`choice-${c.slug}`}>{c.description}</li>)}
  </ul>
}

export default ({ object, decision }) => {
  const decisionObj = new Decision(decision);
  if (object instanceof Decision && decisionObj.isOpenEnded) return null;
  if (object instanceof Recommendation && (object.isOpenEnded || decisionObj.isDecisionRecorded)) return null;
  if (object.otherChoices.length < MIN_DECISION_OTHER_CHOICES_COUNT) return null;

  return <div className="px-3 mb-3">
    <h3>Other choices considered</h3>
    <Choices object={object} />
  </div>;
}
