import React from 'react';
import { connect } from "react-redux";
import { setDecisionOrderSidebarOpen } from "../../store/sidebar/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";

const DecisionSetPanelHamburgerButton = ({ openDecisionOrderSideBar, hidden = false }) =>
  <BaseHamburgerBtn onSelect={openDecisionOrderSideBar} title={`Decision flow`} hidden={hidden} />

const mapDispatchToProps = (dispatch) => ({
  openDecisionOrderSideBar: () => dispatch(setDecisionOrderSidebarOpen(true))
});
export default connect(null, mapDispatchToProps)(DecisionSetPanelHamburgerButton);
