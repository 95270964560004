import React from "react";
import { CHANGE_TYPE_TABS } from "../../helpers/ag_grid_vars";
import CurrencyInput from "react-currency-input-field";
import { BASIS_CHANGE_OPTIONS_KEYS } from "./BasisChange";

const Amount = ({ selectedItem, amount, setAmount, factToUpdate, basisChange }) => {
  const prefix = factToUpdate?.measure === '$' ? '$' : '';
  const suffix = prefix === '' && basisChange === BASIS_CHANGE_OPTIONS_KEYS.current ? (factToUpdate?.measure || '') : '%';
  return <div className="mt-3 mb-0">
    <h3>Amount</h3>
    <div className="text-muted mb-1" hidden={selectedItem !== CHANGE_TYPE_TABS.increaseDecreaseCurrentValue}>
      Enter a negative amount to decrease the current value.
    </div>
    <CurrencyInput
      className="form-control"
      id={'fact-to-update-amount'}
      placeholder={'Enter the value'}
      decimalsLimit={factToUpdate.decimal}
      prefix={prefix}
      suffix={suffix.replace(/\./g, '')}
      disableAbbreviations={true}
      maxLength={20}
      decimalScale={factToUpdate.decimal}
      defaultValue={amount}
      value={amount}
      onValueChange={(value, name) => setAmount(value)}
    />
  </div>
}
export default Amount;
