import React from 'react';

export default ({ hidden, sameCollaborators, setSameCollaborators, classNames = 'mt-2' }) =>
  <div className={`w-100 ${classNames} lh-checkbox`} hidden={hidden}>
    <input type="checkbox" id="sameCollaborators-checkbox" name="sameCollaborators"
           className="custom-checkbox"
           checked={sameCollaborators}
           onChange={() => { setSameCollaborators(!sameCollaborators) }}/>
    <label htmlFor="sameCollaborators-checkbox" className="mb-0">Invite the same collaborators to this decision</label>
  </div>
