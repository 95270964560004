import { stepWithChoices, stepWithDrivers, wizardStepDataBy } from "../../../helpers/wizard_helpers";
import Decision from "../../../models/decision";

export const ratingAndWeightChoicesData = (decision, wizard) => {
  const choicesStepData = wizardStepDataBy(wizard, stepWithChoices(decision));
  const driversStep = stepWithDrivers(wizard);
  const { drivers } = wizardStepDataBy(wizard, driversStep);

  const choices = choicesStepData.choices.map((description, slug) => ({ description, slug }));
  const decisionObj = new Decision(decision, drivers);
  decisionObj.choicesOrRecommendations = (_) => choices;
  const keyDrivers =  decisionObj.keyDrivers;

  return {
    keyDrivers, choices, decisionObj,
    driversStep, drivers
  };
};

export const sortKeyDrivers = (keyDrivers) => {
  return keyDrivers.slice().sort((a, b) => {
    const weightA = a.driver.weight !== null ? a.driver.weight : 100;
    const weightB = b.driver.weight !== null ? b.driver.weight : 100;

    if (weightB !== weightA) {
      return weightB - weightA;
    }
    return a.driver.treeOrder - b.driver.treeOrder;
  });
};
