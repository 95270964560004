import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import { isBlank, successActions } from "../../../helpers/common";
import { isEnter, isEsc } from "../../../helpers/keys_helpers";
import Button from "react-bootstrap/Button";
import { postUserMessage } from "../../../store/decision/actions";
import DiscussionMentionsInput from "./DiscussionMentionsInput";
import { FEEDBACK_REPLY_TYPE, USER_SUPPORT_REPLY_TYPE } from "../../../helpers/discussion_helpers";
import { reloadContacts } from "../../../store/contacts/actions";
import { mentioneeWarning } from "../../../helpers/alert_helpers";
import { resetDecisionSetData } from "../../../store/decision_set/common_actions";

export const AddReplyForm = ({
                               message, hidden, className,
                               onClose, postUserMessage, reloadContacts, resetDecisionSetData
                            }) => {
  const [userMessage, setUserMessage] = useState('')
  const [submit, setSubmit] = useState(false)
  const postUserMessageRequest = (make_mentionee_participant = false) => {
    const postMessageData = {
      feedback: {
        description: ref.current.userMessage(),
        feedback_reply_id: message.id,
        reply_type: message.type === USER_SUPPORT_REPLY_TYPE ? USER_SUPPORT_REPLY_TYPE : FEEDBACK_REPLY_TYPE,
        notify_all: false
      },
      mentions: ref.current.newMentioned().map(h => h['email']),
      all_new_mentions: ref.current.mentioned().map(h => h['email']),
      make_mentionee_participant
    }
    const callback = (success) => {
      setSubmit(false)
      if (success) {
        successActions(make_mentionee_participant, [reloadContacts, resetDecisionSetData]);
        ref.current.clear();
        if (onClose) onClose(success)
        setUserMessage('');
      }
    }
    postUserMessage(postMessageData, callback)
  }
  const postNewMessage = () => {
    setSubmit(true)
    mentioneeWarning(ref.current.newMentioned(), postUserMessageRequest);
  }
  const onKeyDown = (e) => {
    if(isEnter(e.keyCode)) postNewMessage();
    if(isEsc(e.keyCode)) {
      setUserMessage('');
      if (onClose) onClose();
    }
  }
  const onChange = ({userMessage}) => {
    setUserMessage(userMessage)
  }

  const ref = useRef(null);
  return <div className={`reply_row ${className || ''}`} hidden={hidden}>
    <div className="d-flex">
      <div className="w-100 pe-2 message-input-max-width">
        <DiscussionMentionsInput ref={ref} placeholder="Enter a reply" onKeyDown={onKeyDown} onChange={onChange} initialValue={userMessage} />
      </div>
      <div>
        <Button disabled={isBlank(userMessage) || submit} className="w-100 border-0" onClick={postNewMessage}>Send</Button>
      </div>
    </div>
  </div>
}
export default connect(null, {
  postUserMessage, reloadContacts, resetDecisionSetData
})(AddReplyForm);
