import React, { createRef, useCallback, useMemo, useState } from 'react';
import { connect } from "react-redux";
import { saveWizardStepData } from "../../../store/wizard/actions";
import { Button, Table } from 'react-bootstrap'
import { isBlank, isEmail, uniqueBy } from "../../../helpers/common";
import { isEnter } from "../../../helpers/keys_helpers";
import { loadContacts } from "../../../store/contacts/actions";
import CollaboratorsInviteRow from "./CollaboratorsInviteRow";
import { Typeahead } from 'react-bootstrap-typeahead';
import { isGroupContact, isHistoricalDecision } from "../../../helpers/decision_helpers";
import {
  buildInviteDataBy,
  checkSetInvitesEffect,
  isHistoricalFlow,
  isLastStep,
  stepKeyWithInvites
} from "../../../helpers/wizard_helpers";
import Help from "../../help/index";
import { RichMutedText } from "../../../common/RichTextEditor";
import { resetTreeDrivers } from "../../../store/tree/common_actions";
import SubmitStepButton from "./SubmitStepButton";
import Switcher from "../../../common/Switcher";
import { DEFAULT_TYPEHEAD_ATTRIBUTES } from "../../../tree_view/modals/helpers/share_helpers";
import { renderContactItem } from "../../../tree_view/side_panel/helpers/collaborators_helpers";
import DecisionSet from "../../../models/decision_set";
import { onChangeInvites, renderTag } from "./helpers/collaboration_helpers";
import { submitTreeStep } from "../../helpers/decision_wizard_helpers";

export const onKeyDownCollaborator = (e, ref, invites, setInvites, contactsData) => {
  if (isEnter(e.keyCode)) {
    const value = ref.current.getInput().value;
    if (isEmail(value)) {
      setTimeout(() => {
        setInvites(uniqueBy([...invites, buildInviteDataBy(value, contactsData.contacts)], 'email'))
      }, 50)
      ref.current.clear()
    }
  }
}

const Header = ({ wizard }) =>
  <>
    {
      isHistoricalFlow(wizard) ? <>
          <span className="text-primary">Great! </span>
          Enter the people involved in this decision.
        </> :
        <>
          <span className="text-primary">Create a rockstar team! </span>
          Get the right people involved in this decision.
        </>
    }
  </>

export const fetchAddSetMembers = (decision, set, invites) => {
  if (isBlank(decision.decision_set_slug)) return [];

  const setObj = new DecisionSet(set)

  return useMemo(() =>
      setObj.collaborators.filter(i =>
        !invites.some(({ email }) => i.email === email) && decision.user.email !== i.email
      ).map(user =>
        ({ user, email: user.email, slug: `invite-slug-${user.email}` })
      )
    , [set.invites.length, invites.length, decision.user?.email])
}

const CollaboratorsDecisionStep = ({
                                     wizard, decision, contactsData, playbook_notes, decision_set,
                                     stepRef,
                                     loadContacts, saveWizardStepData, resetTree
                                   }) => {
  const [submitState, setSubmitState] = useState(false)
  const [rateSupport, setRateSupport] = useState(decision.rate_support || false)
  const ref = createRef();

  const [invites, setInvites] = useState([])
  const filteredInvites = invites.filter(invite => invite.email !== decision.user.email)

  checkSetInvitesEffect(wizard, contactsData, loadContacts, setInvites);

  const submitStep = (nav_data = {}, callback = () => null) => {
    setSubmitState(true)
    saveWizardStepData(stepKeyWithInvites(wizard), {
      complete: isLastStep(wizard),
      ...nav_data,
      response: { step_index: wizard.step_index },
      decision: { rate_support_on_slack: rateSupport },
      collaborators: uniqueBy(invites, 'email').map(({ email }) => email),
      next_step: true
    }, callback)
    resetTree()
  }
  const removeInvite = (invite) => setInvites(invites.filter(i => i.email !== invite.email))

  const filterSetInvitesToAdd = fetchAddSetMembers(decision, decision_set, invites)

  const addSetMembers = () => setInvites([...invites, ...filterSetInvitesToAdd])

  const filteredContacts = useCallback(() =>
      contactsData.contacts.filter(contact => {
        if (isGroupContact(contact)) return true;
        if (contact.value === decision.user.email) return false;

        return !invites.some(invite => invite.email === contact.value);
      })
    , [contactsData.contacts.length, invites.length, decision.user.email]
  )

  const InputField = () => <Typeahead
    {...DEFAULT_TYPEHEAD_ATTRIBUTES}
    ref={ref} selected={[]}
    id="collaboration-invite-input" labelKey="value"
    onChange={(selected) => onChangeInvites(selected, invites, setInvites, contactsData)}
    options={filteredContacts()}
    renderMenuItemChildren={renderContactItem}
    onKeyDown={(e) => onKeyDownCollaborator(e, ref, invites, setInvites, contactsData)}
    renderToken={(option, events, index) => renderTag(option, events, index, contactsData)}
  />

  stepRef.current.submitStep = (additional_data = {}, callback = () => null) => {
    submitStep(additional_data, (success, wizardData) => {
      setSubmitState(false)
      callback(success, false, wizardData)
    })
  }

  return <div className="bg-white rounded p-3 my-3 mx-auto tree_wizard">
    <Table borderless className="mb-2">
      <tbody>
      <tr>
        <td className="d-flex p-0">
          <h1 className="d-table-cell">
            <Header wizard={wizard}/>
          </h1>
          <div className="d-table-cell ms-auto">
            <Help/>
          </div>
        </td>
      </tr>
      </tbody>
    </Table>
    <div className="w-100 mb-2">
      <RichMutedText text={playbook_notes.collaborators}/>
    </div>
    <div className="w-100 mb-2">
      <h3>Add collaborators</h3>
      <CollaboratorsInviteRow user={decision.user} email={decision.user.email} slug={'decider'}/>
      {filteredInvites.map(invite => <CollaboratorsInviteRow {...invite} key={`invite-row-${invite.slug}`}
                                                             onRemoveClick={() => removeInvite(invite)}/>)}
      <Button onClick={addSetMembers} hidden={isBlank(filterSetInvitesToAdd)} disabled={wizard.submit || submitState}
              className="btn-secondary w-100 mt-1">
        Add all decision flow members
      </Button>
    </div>

    <div className="w-100 mb-3">
      <InputField/>
    </div>

    {
      isHistoricalDecision(decision) &&
      <div className="w-100 mb-3">
        <Switcher label={'Allow collaborators to rate their level of support for what was decided.'}
                  value={rateSupport} onChange={(checked) => setRateSupport(checked)}/>
      </div>
    }
    <div className="w-100">
      <SubmitStepButton onClick={() => submitTreeStep(wizard, stepRef)} disabled={wizard.submit || submitState}/>
    </div>
  </div>
}
const mapStateToProps = ({ wizard, decision, contacts, playbook_notes, decision_set }) => ({
  wizard, decision, playbook_notes, decision_set,
  contactsData: contacts
});
const mapDispatchToProps = (dispatch) => ({
  saveWizardStepData: (step, data, callback) => {
    dispatch(saveWizardStepData(step, data, callback))
  },
  loadContacts: (callback) => {
    dispatch(loadContacts(callback));
  },
  resetTree: () => {
    dispatch(resetTreeDrivers())
  }
});
const wrapper = React.forwardRef((props, ref) => <CollaboratorsDecisionStep {...props} stepRef={ref}/>)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
