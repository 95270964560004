import {
  DSIGHT_GOALS_LOAD_DATA,
  DSIGHT_GOALS_LOAD_DATA_FAILED,
  DSIGHT_GOALS_LOAD_DATA_STARTED,
  DSIGHT_GOALS_RESET_DATA,
  DSIGHT_GOALS_UPDATE_DATA
} from "./types";

const initialState = {
    loading: false,
    loaded: false,
    error: '',
    slug: '',
    goal_chain: {},
    target: {},
    product: {},
    surveys: [],
    selected_demographic: null,
    demographics: [],
    nodes: [],
    dimensions: [],
    attribute_segments: [],
    survey_type: {},
    survey_vendor: {},
    default_time_scale: {},
    confidence_interval: false
};

export function dSightGoalReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case DSIGHT_GOALS_LOAD_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case DSIGHT_GOALS_UPDATE_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case DSIGHT_GOALS_RESET_DATA: {
            return {
                ...initialState,
                ...action.payload,
                loading: false
            };
        }
        case DSIGHT_GOALS_LOAD_DATA_STARTED: {
            return {
                ...state,
                ...initialState,
                ...action.payload,
                loading: true
            }
        }
        case DSIGHT_GOALS_LOAD_DATA_FAILED: {
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        }
        default:
            return state;
    }
}
