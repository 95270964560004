import React from 'react';
import { connect } from "react-redux";
import { setHelpPanelOpen } from "../store/sidebar/actions";
import { hasParticipantAdmission, isVisitor } from "../helpers/user_helpers";

export const Help = ({ sidebar, current_user, openSideBar }) => {
  if (isVisitor(current_user) || hasParticipantAdmission(current_user)) return null;

  return <button className={`btn btn-primary btn-sm btn-sm-round sticky-help-button`}
              onClick={() => { openSideBar(!sidebar.helpSidebar) }}>
    <i className="fas fa-question text-white h3" />
  </button>;
}
const mapStateToProps = ({ sidebar, current_user }) => ({ sidebar, current_user });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setHelpPanelOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Help);
