import React, { useRef, useEffect } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {
  BASE_CHARTS_OPTIONS,
  CHART_COLORS,
  dataRowWithSignificancePerCompetitor
} from "../../../../../models/d_sight/metric_chart_helpers";
import { isMobile } from "react-device-detect";
import { reFlowCharts } from "../../helpers";
import { chartYAxis } from "../../../../../helpers/metric_helpers";

const baseColumnChartOptions = (metric) =>  ({
  ...BASE_CHARTS_OPTIONS,
  ...chartYAxis(metric),
  chart: {
    type: 'column',
    spacing: [5, 0, 0, 5],
    style: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },
  xAxis: {
    type: 'category',
    labels: {
      style: {
        fontSize: '0.75rem',
        color: '#1F2F3F'
      }
    }
  },
  legend: { enabled: false },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: '{point.y:.1f}{series.options.custom.measure}{point.sigUp}{point.sigDown}&nbsp;<span style="font-weight:normal;color:#8a949f">{point.nValText}</span>'
      }
    }
  }
})

const BASE_LAST_PERIOD_TOOLTIP = {
  pointFormat: '<tr><td style="color:{point.color};padding:0">{point.name}:&nbsp;</td>' +
    '<td style="padding:0"><b>{point.y:.1f}%</b>{point.sigUp}{point.sigDown}&nbsp;</td>' +
    '<td style="padding:0;color:#8a949f">{point.nValText}</td></tr>',
  footerFormat: '</table>',
  style: {
    color: '#1F2F3F',
  },
  useHTML: true
}

const vsCompetitorsChartOptions = ({ selectedSurvey, metric, data }) => {
  // Name limit for the first chart on Mobiles
  if (isMobile) data[0].name = data[0].name.substring(0, 10) + '...'

  return {
    ...baseColumnChartOptions(metric),
    colors: CHART_COLORS,
    tooltip: {
      ...BASE_LAST_PERIOD_TOOLTIP,
      headerFormat: `<span style="font-size:12px">${selectedSurvey.short_name}</span><hr style="margin:0.375rem 0"/><table>`,
    },
    series: [{
      name: metric.name,
      custom: { measure: metric.measure },
      colorByPoint: true,
      data: data.map(({ name, ...row }) => ({
        name,
        ...dataRowWithSignificancePerCompetitor(row, data[0], selectedSurvey)
      }))
    }],
  }
}

export default ({ selectedSurvey, metric, ...opts }) => {
  const ref = useRef(null)
  reFlowCharts(ref, useEffect)

  const chartOptions = vsCompetitorsChartOptions({ selectedSurvey, metric, ...opts })

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={ref} key={`vs-competitors-line-chart-${metric.id}`} />
}
