import { isBlank, isPresent } from "../../helpers/common";
import { ForecastBaseModel } from "./Base";
import { AGGREGATION_TYPES, ignoreAggregation } from "./aggregastion";

const TYPES = {
  cmu: 'cmu',
  output: 'output',
  driver: 'driver'
}
export const forecastColumnFactory = (data) => {
  switch (data.attributes.col_type) {
    case TYPES.cmu:
      return new ForecastCmuColumn(data);
    case TYPES.output:
      return new ForecastOutputColumn(data);
    case TYPES.driver:
      return new ForecastDriverColumn(data);
    default:
      return new ForecastColumn(data);
  }
}

export class ForecastColumn extends ForecastBaseModel {

  get displayName() {
    return this.attributes.name;
  }

  get name() {
    return this.attributes.name;
  }

  get sortOrder() {
    return parseInt(this.attributes.sort_order);
  }

  get coefficientColumn() {
    return null;
  }

  get driverRules() {
    return this.attributes.dr_rul;
  }

  get decompRules() {
    return this.attributes.dec_rul;
  }

  get selectHash() {
    return { value: this.id , label: this.selectName }
  }

  get metricSelectHash() {
    return { value: this.id , label: this.metricSelectName }
  }

  get selectName() {
    return [this.displayName, this.measure].filter(isPresent).join(', ');
  }

  get metricSelectName() {
    return [this.displayName, this.measure].filter(isPresent).join(', ');
  }

  decompName(metric) {
    return this.decompRules[metric.id]?.display_name
  }

  decompInternalName(metric) {
    return this.decompRules[metric.id]?.name
  }

  decompId(metric) {
    return parseInt(this.decompRules[metric.id]?.id)
  }

  get measure() {
    return this.driverRules?.metric;
  }

  get decompMeasure() {
    return this.decompRules?.metric;
  }

  get decimal() {
    return this.driverRules?.decimal;
  }

  get decompDecimal() {
    return this.driverRules?.decimal;
  }

  isUsedForAggregatingSubTotals(timeScale, timeScaleConfig = null) {
    if (timeScaleConfig?.isInitData) return true;

    try {
      return !ignoreAggregation(this.driverRules[AGGREGATION_TYPES.by_cmu][timeScale]) &&
        !ignoreAggregation(this.driverRules[AGGREGATION_TYPES.by_time][timeScale])
    } catch (e) {
      return false;
    }
  }

  visibleFor(_timeScale) {
    return false
  }

  fetchValue(row) {
    const driverValues = row.fetchDriverData(this)
    return parseFloat(driverValues?.base)
  }
}

export class ForecastCmuColumn extends ForecastColumn {
  get displayName() {
    return this.driverRules?.display_name;
  }

  visibleFor(_timeScale) {
    return true;
  }

  fillValues(cmuValues) {
    this.values = cmuValues.filter(({ attributes }) => parseInt(attributes.col_id) === this.id).reduce((res, hash) => {
      res[hash.id] = hash.attributes.value;
      return res;
    }, {})
  }
}
export class ForecastOutputColumn extends ForecastColumn {
  get displayName() {
    return this.driverRules?.display_name;
  }

  get selectName() {
    return `${super.selectName} (Output)`
  }

  visibleFor(timeScale) {
    return isPresent(this.driverRules?.visible[timeScale]);
  }

  editableFor(timeScale) {
    return isPresent(this.driverRules?.editable[timeScale]);
  }

  fetchValue(row) {
    const driverValues = row.fetchDriverData(this)
    if (driverValues?.forecast) return parseFloat(driverValues?.forecast)

    return parseFloat(driverValues?.base)
  }
}

export class ForecastDriverColumn extends ForecastColumn {

  isAllOtherImpacts(metric) {
    if (isBlank(this.decompRules)) return false;

    return this.decompInternalName(metric) === 'impact_All Other Drivers';
  }

  get isViewInTable() {
    return isPresent(this.driverRules);
  }

  get selectName() {
    return `${super.selectName} (Driver)`
  }

  get isUsedForDecomposition() {
    return isPresent(this.decompRules?.visible)
  }

  get coefficientColumn() {
    if (isBlank(this.attributes.coef_rul)) return null;

    const { id, attributes } = this;
    return new ForecastCoefficientColumn({ id, attributes }, this);
  }

  visibleFor(timeScale) {
    return isPresent(this.driverRules?.visible[timeScale]);
  }

  editableFor(timeScale) {
    return isPresent(this.driverRules?.editable[timeScale]);
  }

  get displayName() {
    return this.driverRules?.display_name;
  }

  fetchImpact(row, metric, valueIndex) {
    const driverValues = row.fetchDriverData(this)
    try {
      return parseFloat(driverValues.impact[metric.id][valueIndex]);
    } catch(e) {
      return 0.0;
    }
  }
}

export class ForecastCoefficientColumn extends ForecastColumn {
  constructor({ id, attributes }, driverColumn) {
    const { coef_rul } = attributes
    super({ id: coef_rul.id, attributes })
    this.driverColumn = driverColumn;
  }

  get sortOrder() {
    return parseInt(this.driverRules?.id);
  }

  get selectName() {
    return `${super.selectName} (Coefficient)`
  }

  get displayName() {
    return this.driverRules?.display_name;
  }

  get driverRules() {
    return this.attributes.coef_rul;
  }

  fetchValue(row) {
    const driverValues = row.fetchDriverData(this)

    return parseFloat(driverValues?.coefficient)
  }
}
