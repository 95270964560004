import React, {useMemo} from 'react';
import {arrayToSentence, isBlank, isPresent} from "../../helpers/common";
import SectionWrapper from "../../tree_view/side_panel/sections/SectionWrapper";
import { UserSupportCommentTile } from "../../tree_view/modals/BuyInDetailsModal";
import Decision from "../../models/decision";
import { collectUsersVotes } from "../../helpers/decision_helpers";
import { htmlParser } from "../../helpers/html_parser";

export const getOrdinal = (n) => {
  const ords = ["th", "st", "nd", "rd"];
  const v = n % 100;

  return n + (ords[(v - 20) % 10] || ords[v] || ords[0]);
}

export const rankedByUsersCallback = (choice) => {
  const userVoteData = (vote) => isBlank(vote.rank) ? null : `${vote.user.full_name || vote.user_email} (${getOrdinal(vote.rank)})`;

  const usersData = choice.votes.map(vote => userVoteData(vote)).filter(vote => isPresent(vote));
  return arrayToSentence(usersData);
};

const RankedChoiceRow = ({choice, index, isTie = false}) => {
  const rankedByUsers = useMemo(() => rankedByUsersCallback(choice), [choice.votes])

  return <div className="mb-2" key={`choice-${choice.slug}`}>
    <div className="d-flex m-0">
      <div className="support-details w-100">
        <div>
          <span className="fw-bold me-1" hidden={isBlank(rankedByUsers)}>
            {getOrdinal(index)}{isTie ? ' (tie)' : ''}:
          </span>
          <span>
            {htmlParser(choice.description)}
          </span>
        </div>
        <div className={`supported-users text-muted`} hidden={isBlank(rankedByUsers)}>
          Ranked by {rankedByUsers}
        </div>
      </div>
    </div>
    <div className="support-details ms-lg-4">
      {
        choice.votes.map(vote => {
          const userSupport = {user: vote.user, support_comment: vote.feeling_overall}
          return vote.feeling_overall && <UserSupportCommentTile {...{userSupport}} key={`user-support-${vote.slug}-comment-${vote.user_email}`} />
        })
      }
    </div>
  </div>
}

const QuestionnaireChoiceRow = ({choice}) => {
  return <div className="mb-2" key={`choice-${choice.slug}`}>
    <div className="d-flex m-0">
      <div className="support-details w-100">
        {htmlParser(choice.description)}
      </div>
    </div>
    <div className="support-details ms-lg-4">
      { choice.votes.map(vote => {
        const userSupport = {user: vote.user, support_comment: vote.feeling_overall}

        return vote.feeling_overall && <UserSupportCommentTile {...{userSupport}} key={`user-support-${vote.slug}-comment-${vote.user_email}`} />
      })}
    </div>
  </div>
}

const ChoiceRow = ({choice, decision, totalUsersVotes}) => {
  const userPicked = (vote) => isBlank(vote.picked) && isBlank(vote.rank) ? null : `${vote.user.full_name}`
  const pickedByUser = choice.votes.map(
    vote => userPicked(vote)
  ).filter(name => isPresent(name));
  const supportPercentage = parseFloat((pickedByUser.length/totalUsersVotes * 100).toFixed(0))

  return <div className="mb-2" key={`choice-${choice.slug}`}>
    <div className="d-flex m-0">
      <div className="support-details w-100">
        <div>
          <span className="fw-bold me-1">
            {
              isBlank(pickedByUser) ?
                'No votes:' :
                `${pickedByUser.length} ${pickedByUser.length === 0 ? 'vote' : 'votes'} (${supportPercentage}%):`
            }
          </span>
          <span>
            {htmlParser(choice.description)}
          </span>
        </div>
        <div className={`supported-users text-muted`} hidden={isBlank(pickedByUser)}>
          Picked by {pickedByUser.join(', ')}
        </div>
      </div>
    </div>
    <div className="support-details ms-lg-4">
      { choice.votes.map(vote => {
        const userSupport = {user: vote.user, support_comment: vote.feeling_overall}

        return vote.feeling_overall && <UserSupportCommentTile {...{userSupport}} key={`user-support-${vote.slug}-comment-${vote.user_email}`} />
      })}
    </div>
  </div>
}

export const ChoicesList = ({decision}) => {
  const object = new Decision(decision);
  if (isBlank(object.decisionChoices)) return null;

  const usersChoices = collectUsersVotes(decision);

  if (object.isQuestionnaire) {
    return usersChoices.map((choice) =>
      <QuestionnaireChoiceRow  key={`choice-${choice.slug}`} {...{choice}} />
    )
  }

  const formatRankedChoices = () => {
    let index = 1;
    let prevRankPoints = usersChoices[0].rank_points;
    const choices = usersChoices.map((choice) => {
      const isTie = choice.rank_points > 0 && usersChoices?.filter(c => c.rank_points === choice.rank_points).length > 1

      if (choice.rank_points !== prevRankPoints) {
        index = index + 1;
        prevRankPoints = choice.rank_points
      }

      return <RankedChoiceRow  key={`choice-${choice.slug}`} {...{choice, index, isTie}} />
    })

    return choices;
  }

  const formatFinalDecision = () => {
    if (object.isRankedList)
      return formatRankedChoices();

    let totalUsersVotes = 0;
    usersChoices.map(choice => choice.votes.map(vote => (vote.picked || isPresent(vote.rank)) && ++totalUsersVotes )).flat()

    return usersChoices.map((choice) =>
        <ChoiceRow  key={`choice-${choice.slug}`} {...{choice, decision, totalUsersVotes}} />
      )
  }

  return formatFinalDecision()
}

export default ({ decision, decisionObject, hidden }) => {
  if (hidden || isBlank(decision) || isBlank(decisionObject)) return null;

  const hidePollType = decision.flow_enum_type === 'Cloverpop';
  return <SectionWrapper>
    <div className="px-3">
      <div className="d-flex">
        <h3>Poll choices <span className="fw-normal" hidden={hidePollType}>({decisionObject.pollType})</span></h3>
      </div>
    </div>
    <div className="px-3 mb-3">
      <ChoicesList {...{decision}}/>
    </div>
  </SectionWrapper>
}
