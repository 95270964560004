import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { updateRecordDecision } from "../../../store/sidebar/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import CurrencyInput from "../../../common/CurrencyInput";
import { InputCurrencyHeader } from "../../../tree_wizard/steps_wizard/steps/HistoricalDecisionStep"
import AccordionWrapper from "../../../common/AccordionWrapper";

const ExpectedOpportunityInput = ({ decision, updateRecordDecision }) => {
  if (isBlank(decision)) return null;

  const [expectedOpportunity, setExpectedOpportunity] = useState(decision.expected_opportunity);
  useEffect(() => {
    if (expectedOpportunity !== decision.expected_opportunity) {
      setExpectedOpportunity(decision.expected_opportunity)
    }
  }, [decision.expected_opportunity])

  const onChangeExpectedOpportunity = (_e) => { updateRecordDecision({ expected_opportunity: expectedOpportunity }); };

  const accordionObject = {
    header: <InputCurrencyHeader headerText={'Expected opportunity value'}/>,
    body: <CurrencyInput key={'decision-currency-input-opportunity'}
                         value={expectedOpportunity || ''}
                         onChange={(value) => setExpectedOpportunity(value || '')}
                         placeholder={'Enter a dollar amount'}
                         name={'expected-opportunity'}
                         id={'expected-opportunity'} />,
    defaultExpand: isPresent(decision.expected_opportunity)
  };

  return <div onBlur={onChangeExpectedOpportunity}>
    <AccordionWrapper accordionObject={accordionObject} className='px-3 mb-3' />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecordDecision })(ExpectedOpportunityInput);
