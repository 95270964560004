import React from 'react';
import { htmlParser } from "../../../helpers/html_parser";
import Button from 'react-bootstrap/Button';
import { isCollaborator, isDecider } from "../../../helpers/user_helpers";
import { RichTextSection } from "../../../common/RichTextEditor";

const EditButton = ({ user, onEdit, isLegacy }) => {
  if (isLegacy || (!isDecider(user) && !isCollaborator(user))) return null;

  return <span className="icon pe-3 ms-auto mt-2-negative">
    <Button onClick={onEdit} bsPrefix="btn btn-sm btn-secondary btn-sm-round py-1">
      <i className="fas fa-pencil-alt fa-lg w-100"/>
    </Button>
  </span>
}

const RankedChoices = ({ object }) =>
  <ol className="ps-3 mb-0">
    {object.finalDecisions.map(c => <li key={`choice-${c.slug}`}>{htmlParser(c.description)}</li>)}
  </ol>

const FinalDecision = ({ object, isLegacy = false }) => {
  if (object.finalDecisions.length === 1)
    return <RichTextSection text={object.finalDecisions[0].description}/>

  if ((isLegacy && object.isFinalDecisionRanked) || (!isLegacy && object.isRankedList))
    return <RankedChoices {...{ object }} />

  return <ul className="ps-3 mb-0">
    {object.finalDecisions.map(c => <li key={`choice-${c.slug}`}><RichTextSection text={c.description}/></li>)}
  </ul>
}

export default ({ user, object, onEdit = () => {}, headerText, isLegacy = false }) => {
  if (object.finalDecisions.length < 1) return null;

  return <div>
    <div className="d-flex">
      <h3 className="px-3">{headerText}</h3>
      <EditButton {...{ user, onEdit, isLegacy }} />
    </div>
    <div className="px-3 mb-3">
      <div hidden={object.finalDecisions.length < 1}>
        <FinalDecision object={object} isLegacy={isLegacy} />
      </div>
    </div>
  </div>;
}
