import React from 'react';
import { setHelpPanelOpen } from "../../store/sidebar/actions";
import { connect } from "react-redux";

export const HelpTemplateItem = ({sidebar, openHelpPanelOpen}) =>
  <li className={sidebar.helpSidebar ? 'active' : ''}>
    <div className="side-row text-nowrap pointer" onClick={() => {
      openHelpPanelOpen(!sidebar.helpSidebar)
    }}>
      <span className="icon d-inline-block text-center ">
        <i className="fas fa-question fa-lg"/>
      </span>
      <span className="d-none d-lg-inline tab-title">Help & resources</span>
    </div>
  </li>

const mapStateToProps = ({ sidebar, current_user }) => ({ sidebar, current_user });
const mapDispatchToProps = (dispatch) => ({
    openHelpPanelOpen: (flag) => dispatch(setHelpPanelOpen(flag))
});
export default connect(mapStateToProps, mapDispatchToProps)(HelpTemplateItem);
