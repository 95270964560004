import { StyleSheet } from '@react-pdf/renderer';
import {DARK_GRAY, LIGHT_COLOR} from "./common";

const styles = StyleSheet.create({
  body: {
    paddingTop: 230,
    marginHorizontal: 141,
    fontFamily: 'Montserrat',
    lineHeight: 1.6
  },
  title: {
    marginTop: 5,
    marginBottom: 15,
    lineHeight: 1.6,
    fontSize: 16.5,
    fontWeight: 500,
    textAlign: 'center',
    color: DARK_GRAY
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  footer: {
    position: 'absolute',
    bottom: 16,
    width: '100%'
  },
});
export default styles
