import React from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../helpers/common";
import {PlaybookDataSourcesTypes, Type} from "../../../models/data_source";
import {
  createSource,
  destroySource,
  replaceSource,
  saveUploadingSources,
  updateSource
} from "../../../store/tree/actions";
import { decisionDataSources } from "../../../models/decision";
import { AddSourceRows, DataSourcesList, UploadingSourcesList } from "./DataSourcesSection";
import { generateBatchDataSourceUploadState } from "../../../helpers/uploads_callbacks";

export const sortedPlaybookDataSources = (tree) => decisionDataSources( tree.data_sources || [], [], [...PlaybookDataSourcesTypes])
export const PlaybookDataSourcesSection = ({
                                             isTemplate = true,
                                             data_sources, edit,
                                             uploading_sources,
                                             createSource,
                                             saveUploadingSources,
                                             user, isPlaybook,
                                             ...opts
                                          }) => {
  const dataSourceType = isTemplate ? Type.PLAYBOOK_TEMPLATE_DATA_SOURCE : Type.PLAYBOOK_DATA_SOURCE
  const submitLink = (value, callback) => {
    const data = { decision_data_source: { link_url: value, type: dataSourceType } }
    createSource(data, {}, callback, true);
  }
  const uploadOptions = generateBatchDataSourceUploadState(data_sources, uploading_sources, saveUploadingSources, user)

  return <div className="p-3">
    <div className="d-flex flex-column" hidden={isBlank(data_sources)}>
      <DataSourcesList data_sources={data_sources} hideMenu={!edit} isPlaybook={isPlaybook} {...uploadOptions} {...opts} />
      <UploadingSourcesList uploading_sources={uploading_sources} {...uploadOptions} {...opts} />
    </div>
    <div hidden={!edit}>
      <AddSourceRows submitLink={submitLink}
                     createSource={createSource}
                     dataSourceType={dataSourceType} isTemplate={isTemplate}
                     {...uploadOptions} />
    </div>
  </div>
};
const mapStateToProps = ({ tree, current_user }) => ({
  data_sources: sortedPlaybookDataSources(tree),
  uploading_sources: tree.uploading_sources,
  user: current_user
});
export default connect(mapStateToProps, {
  createSource, updateSource, destroySource, saveUploadingSources, replaceSource
})(PlaybookDataSourcesSection);
