import React, {useCallback, useState, useMemo} from 'react';
import { connect } from "react-redux";
import Decision from "../../../models/decision";
import {Accordion, Card, Table} from 'react-bootstrap';
import { RatingOverlayTrigger } from "../helpers/RatingOverlayTrigger";
import EditDriverWeightsModal from "../../modals/EditDriverWeightsModal";
import { updateDriversWeights } from "../../../store/tree/actions";
import {isDeciderReadOnly, isVisitor} from "../../../helpers/user_helpers";
import CustomHeaderToggle from "../CustomHeaderToggle"
import { toggleClickAction, openAllActionUseEffect, setAccordionDropdownToggleState } from "../helpers/accordion_helpers.js"
import { sortKeyDrivers } from "../../../tree_wizard/side_panel/sections/helpers.js";

const CustomToggle = ({ driver, children, eventKey, openIndexes, setOpenIndexes }) => {
  const [openState, setOpenState] = useState(false);

  setAccordionDropdownToggleState(openIndexes, eventKey, {setOpenState})

  return (
    <div onClick={() => toggleClickAction(openIndexes, eventKey, {setOpenIndexes})}
         className="d-flex fw-bolder border-0 align-items-baseline">
      <div className="note_paragraph text-ellipsis me-1">{children}</div>
      <div className={`dropdown-toggle ms-auto ${openState ? 'rotate-after-180' : ''}`}>
        {driver.weightPercentage}% ({driver.weightScore})
      </div>
    </div>
  );
};

const DriverWeightRow = ({ driver, openIndexes, setOpenIndexes }) =>
  <div className='mb-2'>
    <Accordion>
      <Card className="border-0">
        <Card.Header className="bg-white border-0 pointer p-0 mb-1">
          <CustomToggle eventKey={driver.slug} {...{driver, openIndexes, setOpenIndexes}} >
            <span className={`button me-1 ${driver.isCompleted ? 'answered' : 'non-answered'}`} />
            {driver.question}
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={driver.slug} className={`${openIndexes.includes(driver.slug) ? 'show' : ''}`}>
          <Card.Body className="p-0">
            <Table borderless className="mb-0">
              <thead>
              <tr>
                <th className="col-10 fw-bolder text-gray p-0 pe-2">Choice</th>
                <th className="col-1 fw-bolder text-gray p-0 text-end">Rating</th>
              </tr>
              </thead>
              <tbody className="note_paragraph">
              {
                driver.sortedDriverChoicesRatings.map(rating =>
                  <tr key={`driver-weights-${driver.slug}-choice-${rating.choice_slug}`}>
                    <td  className="p-0 pe2">{rating.choiceDescription}</td>
                    <td  className="p-0 text-end">
                      {
                        rating.isAnswered ?
                          <RatingOverlayTrigger rating={rating} />:
                          <span className="text-gray">N/A</span>
                      }
                    </td>
                  </tr>
                )
              }
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </div>


const DriverWeightsSection = ({ isTemplate = false, decision, drivers, updateDriversWeights, current_user, hideEditDriverWeights = false }) => {
  const decisionObj = useMemo(() => new Decision(decision, drivers), [decision, drivers]);
  const keyDrivers =  useMemo(() => decisionObj.keyDrivers, [decisionObj]);

  const [openAll, setOpenAll] = useState(false);
  const [openIndexes, setOpenIndexes] = useState([]);
  const [editModalShown, setEditModalShown] = useState(false);
  const openEditDriverWeightsModal = useCallback(() => {
    setEditModalShown(true);
  }, [decision, drivers]);
  const allRateChoicesSlugs = useMemo(() => keyDrivers.map(driver => driver.slug), [keyDrivers])
  const sortedKeyDrivers = useMemo(() => sortKeyDrivers(keyDrivers), [keyDrivers]);

  openAllActionUseEffect(openAll, openIndexes, allRateChoicesSlugs, {setOpenIndexes, setOpenAll});

  if (keyDrivers.length === 0) return null;

  return <>
    <div className="px-3 mb-3">
      <CustomHeaderToggle {...{openAll, setOpenAll}}>
        <h3>Driver weights</h3>
      </CustomHeaderToggle>
      {
        sortedKeyDrivers.map(driver =>
          <DriverWeightRow key={`driver-row-${driver.driver.slug}`} {...{driver, openIndexes, setOpenIndexes}} />)
      }
      <div className="btn btn-secondary w-100 py-2 pointer" onClick={openEditDriverWeightsModal} hidden={isTemplate ? hideEditDriverWeights : (isVisitor(current_user) || isDeciderReadOnly(current_user))}>
        <span>Edit driver weights</span>
      </div>
    </div>
    <div className="modals">
      <EditDriverWeightsModal shown={editModalShown} decisionObj={decisionObj}
                              onClose={() => setEditModalShown(false)}
                              onSubmit={ updateDriversWeights }
      />
    </div>
  </>
};

const mapStateToProps = ({ decision, tree, current_user }) => ({
  drivers: tree.drivers,
  decision, current_user
});
export default connect(mapStateToProps, { updateDriversWeights })(DriverWeightsSection);
