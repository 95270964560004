import React, { useState, useEffect } from 'react';
import { getLastSurvey } from "../../../../../models/d_sight/metric_chart_helpers";
import { findSelectedDemographic } from "../../helpers";
import { TimePeriodDropdown } from "../TimePeriodDropdown";
import DemographicSegmentDropdown from "../../filters/DemographicSegmentDropdown";
import { isBlank, isPresent } from "../../../../../helpers/common";
import { openSegmentationModal } from "../../../modals/SegmentationCriteriaModal";
import ChartSourcesLabels from "../ChartSourcesLabels";
import DemographicSegmentColumnCharts from "./DemographicSegmentColumnCharts";
import VsCompetitorsColumnsChart from "./VsCompetitorsColumnsChart";

const SegmentationLink = ({ segment, opts }) =>
  <span className="text-primary pointer active" onClick={() => openSegmentationModal(opts, segment)}>Segmentation criteria</span>

export default ({
                  metric, surveys, sourceLabels, chartData, timeNode,
                  d_sight_goal, ...opts
                }) => {
  const queryParameters = new URLSearchParams(document.location.search)
  const searchKeys = Array.from(queryParameters.keys());

  const [selectedSurvey, setSelectedSurvey] = useState(getLastSurvey(surveys))
  const [selectedDemographicSegment, setSelectedDemographicSegment] = useState(null)
  const selectedSegment = d_sight_goal.demographics.find(({id}) => parseInt(id) === parseInt(selectedDemographicSegment))
  const { segment } = findSelectedDemographic(d_sight_goal)
  useEffect(() => setSelectedSurvey(getLastSurvey(surveys)), [timeNode])

  useEffect(() => {
    const searchKey = searchKeys[0];
    if(!!searchKey && queryParameters.get(searchKey) === 'All')  {
      const selSegment =  d_sight_goal.demographics.find(({name}) => {
        return name.toLowerCase() === searchKey.toLowerCase()
      });
      if (!!selSegment) setSelectedDemographicSegment(selSegment.id);
    }
  }, []);

  return <div className="row mb-3">
    <div className="col">
      <div className="h-100 bg-white rounded p-3">
        <TimePeriodDropdown {...{ surveys, selectedSurvey, setSelectedSurvey }}
                            header={[`${metric.name} by period`, metric.subTitle].filter(isPresent).join(' - ')}>
          <DemographicSegmentDropdown {...{ ...opts, selectedDemographicSegment, setSelectedDemographicSegment }}  />
        </TimePeriodDropdown>
        {
          isBlank(selectedDemographicSegment) ?
            <VsCompetitorsColumnsChart {...{ ...opts, selectedSurvey, metric }} /> :
            <DemographicSegmentColumnCharts {...{
              chartData, timeNode, d_sight_goal, selectedDemographicSegment,
              metric, selectedSurvey,
              ...opts
            }} />
        }
        {
          !sourceLabels ? null :
            <ChartSourcesLabels chartData={chartData} surveys={surveys} hideSignificanceLegend={isPresent(selectedDemographicSegment)}>
              {
                isPresent(selectedSegment?.segmentation_desc) ? <SegmentationLink segment={selectedSegment} opts={opts} /> : null
              }
              {
                isBlank(selectedSegment) && isPresent(segment?.segmentation_desc) ? <SegmentationLink segment={segment} opts={opts} /> : null
              }
            </ChartSourcesLabels>
        }
      </div>
    </div>
  </div>
}
