import React from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../helpers/common";
import NAElement from "./NAElement";
import { RichTextSection } from "../../../common/RichTextEditor";

export const ContextSection = ({ template }) => {
  const HeaderElement = () => <h3>Context</h3>

  if (isBlank(template.collaboration_context))
    return <div className="px-3 pb-3">
      <HeaderElement />
      <NAElement />
    </div>;

  return <div className="px-3 pb-3">
    <HeaderElement />
    <div className="d-flex">
      <div className="me-auto overflow-auto">
        <RichTextSection text={template.collaboration_context} />
      </div>
    </div>
  </div>
}
const mapStateToProps = ({ template }) => ({ template });
const mapDispatchToProps = (_dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ContextSection);
