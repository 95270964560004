import React from 'react';
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import { setLearnFiltersSidebarOpen } from "../../../store/sidebar/actions";
import CloseIcon from "../../../common/CloseIcon";
import { isMobile } from "react-device-detect";

export const FiltersPanel = ({ sidebar, setLearnFiltersSidebarOpen }) => {
  return <SlidingPanel
    type="right"
    isOpen={sidebar.filtersSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
  >
    <div className="mt-2 me-3 float-end"><CloseIcon onClose={() => { setLearnFiltersSidebarOpen(false) }} /></div>
    To be continued
  </SlidingPanel>
}
const mapStateToProps = ({ sidebar }) => ({ sidebar });
export default connect(mapStateToProps, { setLearnFiltersSidebarOpen })(FiltersPanel);
