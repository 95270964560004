import React from 'react';
import { connect } from "react-redux";
import { loadMetric } from "../../../../store/analysis/actions";
import { mapStateToProps as commonMapStateToProps } from "../../index";
import { isBlank, isPresent } from "../../../../helpers/common";
import BrandFunnel from "./BrandFunnel";
import LineChart from "./LineChart";
import NPSChart from "./NPSChart";
import TableTypeCharts from "./TableTypeCharts";
import { loadGoalMetric } from "../../../../store/metrics/actions";
import {
  isAttributesMetric,
  isBrandFunnel, isEmotionalConnectionAttr,
  isNPSChart, isSegmentationSupported
} from "../../../../models/d_sight/metric_chart_helpers";
import { NestedDynamicChainsCharts } from "./NestedDynamicChainsCharts";
import { openModal } from "../../../../store/modals/actions";
import EmotionalConnectionAttrs from "./brand/EmotionalConnectionAttrs";
import { chartDataState, LoaderBlock, timeNodesState } from "./helpers/chart_data_helpers";
import AllAttributesMetric from "./brand/AllAttributesMetric";

const nestedDynamicChains = (metric) => {
  if (metric.hasDynamicChains) return false;
  if (metric.hasChartData) return false;

  return metric.metrics.every(m => m.hasDynamicChains);
}

const loadFunction = ({ goal_metrics = false, loadMetric, loadGoalMetric }) =>
  goal_metrics ? loadGoalMetric : loadMetric

export const MetricChart = ({
                              d_sight_goal, metrics, metric,
                              resetAvailableOptions = () => null,
                              parentLoading = false,
                              lever = null,
                              ...opts
                           }) => {
  const {
    chartData, loading
  } = chartDataState({ metrics, metric, parentLoading, loadFunction: () => loadFunction(opts) })
  const {
    timeNode, setTimeNode, timeNodes
  } = timeNodesState({ d_sight_goal })

  if (isBlank(metric)) return null;
  const isLoading = (parentLoading || loading || isBlank(timeNode)) ||
    (isBlank(chartData) && nestedDynamicChains(metric)) ||
    (chartData && chartData.metric_id !== metric.id)
  if (isLoading) return <LoaderBlock />;

  const onSetTimeNode = (newTimeNode) => {
    metric.insightType = newTimeNode;
    setTimeNode(newTimeNode)
    resetAvailableOptions()
  }
  const passOpts = {
    d_sight_goal, chartData, timeNode, metric, lever,
    timeNodes, setTimeNode: onSetTimeNode
  }

  if (isAttributesMetric(metric) && isSegmentationSupported(d_sight_goal))
    return <AllAttributesMetric  {...{ ...opts, ...passOpts }} />
  if (metric.hasDynamicChains) return <TableTypeCharts {...{ ...opts, ...passOpts }} />
  if(nestedDynamicChains(metric)) return <NestedDynamicChainsCharts {...{ ...opts, ...passOpts, metrics, parentLoading: loading }} />
  if (isBrandFunnel(metric)) return <BrandFunnel {...{ ...opts, ...passOpts }} />
  if (isNPSChart(metric)) return <NPSChart {...{ ...opts, ...passOpts }} />
  if (isEmotionalConnectionAttr(metric))
    return <EmotionalConnectionAttrs {...{ ...opts, ...passOpts, metrics, leverMetrics: lever?.metrics, parentLoading: loading, loadFunction: () => loadFunction(opts) }} />;

  return isPresent(chartData?.process_data) && <LineChart {...{ ...opts, ...passOpts }} />
}

export const mapStateToProps = ({ metrics, ...opts }) => ({ ...commonMapStateToProps(opts), metrics });
export const mapDispatchToProps = { loadMetric, loadGoalMetric, openModal };
export default connect(mapStateToProps, mapDispatchToProps)(MetricChart);
