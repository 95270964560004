import React from 'react';
import ChatInput from "./AskCloverpop/components/ChatInput";
import { createMessage } from "../../../utils/Api";
import { useParams, useHistory } from "react-router-dom";

const AskCloverpopSection = () => {
  let { id: goalId } = useParams();
  const history = useHistory();
  const [disabledInput, setDisabledInput] = React.useState(false);

  const handleSubmit = async (content) => {
    if(!content) {
      history.push(`/ask_cloverpop/goals/${goalId}/conversations`);
      return;
    }
    setDisabledInput(true);
    const response = await createMessage(content, goalId);
    if (response.status === 200) {
      setDisabledInput(false);
      const { message: { dsight_conversation_id } } = response.data;
      history.push(`/ask_cloverpop/goals/${goalId}/conversations/${dsight_conversation_id}`);
    }
  };

  return (
    <div className="mt-3">
      <div className="d-flex flex-column flex-xl-row">
        <div className="w-100">
          <div className="bg-white rounded h-100 p-3">
            <ChatInput defaultValue={''} submit={handleSubmit} disabledInput={disabledInput} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskCloverpopSection;
