import React from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../../helpers/common";

const FilteredChartPlaceholder = ({ d_sight_goal }) =>
  isBlank(d_sight_goal.selected_demographic) ? null :
    <div className="mb-2">
      Not enough data
    </div>

export const mapStateToProps = ({ d_sight_goal }) => ({ d_sight_goal });
export default connect(mapStateToProps)(FilteredChartPlaceholder);
