import React from "react";
import { connect } from "react-redux";
import { setRecommendationInputSidebarOpen, transferDecisionFromSidebar } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import Footer from "./recommendation_input/Footer";
import DecisionInputBody from "./recommendation_input/DecisionInputBody";
import RationaleInput from "./recommendation_input/RationaleInput";
import AssignedToSection from "./recommendation_input/AssignedToSection";
import { isMobile } from "react-device-detect";
import Header from "./sections/RecommendationHeader";
import DataSourcesSection from "./sections/DataSourcesSection";
import { saveSideBarData } from "../../store/sidebar/common_actions";
import AssignedRecommenderInput from "./recommendation_input/AssignedRecommenderInput";
import MoreActionsFinalSection from "./recommendation_input/MoreActionsFinalSection";
import NextStepsInput from "./recommendation_input/NextStepsInput";
import ExpectedResultsInput from "./recommendation_input/ExpectedResultsInput";
import ExpectedOpportunityInput from "./recommendation_input/ExpectedOpportunityInput";
import ExpectedInvestmentInput from "./recommendation_input/ExpectedInvestmentInput";
import OtherConsideredChoicesInput from "./recommendation_input/OtherConsideredChoicesInput";
import EditAssignBtn from "../../common/EditAssignBtn";
import {showDecisionAssign} from "../modals/DecisionAssignModal";

const RecommendationInputRightPanel = ({
                                         sidebar, recommendationData, modal,
                                         openSideBar, transferDecisionFromSidebar, saveSideBarData,
                                         hideAssignee = false, isDsight = false
                                      }) => {
  const assignUser = recommendationData.assigned_collaborator_email ? recommendationData.assigned_collaborator_email : '';

  return <SlidingPanel
    type="right"
    isOpen={sidebar.recommendationInputSidebar}
    panelContainerClassName={`right-sidebar-panel-wrapper recommendation-input-panel-wrapper`}
    panelClassName={`right-sidebar-panel-wrapper-content recommendation-input-panel-content ${isMobile ? '' : 'd-flex flex-column'}`}
    onClose={transferDecisionFromSidebar}
    onClosed={() => {
      if (!sidebar.decisionInputSidebar && !sidebar.pollPanelSidebar) {
        saveSideBarData({ decision: null, isOpened: false  })
      } else {
        saveSideBarData({ isOpened: false })
      }
    }}
    onOpened={() => setTimeout(() => saveSideBarData({ isOpened: true }), 200)}
  >
    {
      sidebar.decision && <>
        <Header decision={sidebar.decision}
                headerText={"Enter recommendation"}
                openSideBar={() => openSideBar(false)} >
          <EditAssignBtn isDSight={isDsight} assignUser={assignUser} openAssignModal={() => showDecisionAssign(modal)} modalType='RecommendationAssignModal'/>
          {isDsight ?
            <></> :
            <MoreActionsFinalSection /> }
        </Header>
        <AssignedToSection />
        <DecisionInputBody />
        <RationaleInput />
        { hideAssignee ? null : <AssignedRecommenderInput /> }
        <OtherConsideredChoicesInput />
        <NextStepsInput />
        <ExpectedResultsInput />
        <ExpectedOpportunityInput />
        <ExpectedInvestmentInput />
        <DataSourcesSection addClass="px-3" fromRecommendationPanel={true}/>
        <Footer />
      </>
    }

  </SlidingPanel>
}

const mapStateToProps = ({ sidebar, decision, modal }) => ({ sidebar, decision, modal,
recommendationData: decision.recommendation });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => { dispatch(setRecommendationInputSidebarOpen(options)) },
  transferDecisionFromSidebar: () => { dispatch(transferDecisionFromSidebar()) },
  saveSideBarData: (data = {}) => { dispatch(saveSideBarData(data)) }
});
export default connect(mapStateToProps, mapDispatchToProps)(RecommendationInputRightPanel);
