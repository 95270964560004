import React from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../../helpers/common";
import { JoditTextEditor } from '../../../../common/JoditTextEditor';

const RationaleInput = ({ decision, reasons, setReasons }) => {
  if (isBlank(decision)) return null;

  return <div className="mb-3">
    <h3>Rationale</h3>
    <JoditTextEditor className="jodit-container-default"
                     value={reasons}
                     placeholder="Enter a detailed explanation"
                     setValue={setReasons}
                     autofocus={false} />
  </div>
};

const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps)(RationaleInput);
