import {failedResponseHandler, isResponseFailed} from "../../helpers/store_helpers";
import {
  createSourceQuickDriverRequest,
  destroyDataSourceQuickDriverRequest,
  loadQuickDriverDataRequest, loadQuickVotesDataRequest,
  updateDataSourceQuickDriverRequest,
  updateQuickDriverData, updateQuickVotesData
} from "../../utils/Api";
import EntryPoint from "../../EntryPoint";

export const loadQuickDriverData = (callback = () => {}) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance
  loadQuickDriverDataRequest(objectSlug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, callback)

    const { data } = response;
    callback(true, data);
  })
}

export function updateQuickDriver(data, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance;
    updateQuickDriverData({ objectSlug, data }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, callback)

      callback(true);
    })
  }
}

export const loadQuickPollData = (callback = () => {}) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance
  loadQuickVotesDataRequest(objectSlug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, callback)

    const { data } = response;
    callback(true, data);
  })
}

export function updateQuickPoll(data, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance;
    updateQuickVotesData({ objectSlug, data }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, callback)

      const { data } = response;
      const { success, redirect } = data;
      callback(success, redirect);
    })
  }
}

export function createSourceQuickDriver(data, config = {}, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance;
    createSourceQuickDriverRequest({ objectSlug, data, config }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, callback)

      const { data } = response;
      callback(true, data);
    })
  }
}

export function destroySourceQuickDriver(slug, data, callback) {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance;
    const driverData = {decision_data_source: {slug}, driver_slug: data.driver_slug}
    destroyDataSourceQuickDriverRequest({ objectSlug, driverData }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, response);

      callback(true);
    })
  }
}

export function updateSourceQuickDriver(slug, data, callback) {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance;
    const driverData = {decision_data_source: {...data, slug}, driver_slug: data.driver_slug}
    updateDataSourceQuickDriverRequest({ objectSlug, driverData }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, response);

      const { data } = response;
      const { data_source } = data
      callback(true, data_source);
    })
  }
}
