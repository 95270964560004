import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { TABS } from "../index";
import BaseHamburgerBtn, { BaseHamburgerItem, DeleteItemElement } from "../../common/BaseHamburgerBtn";
import { IconDropdown } from "../../common/dropdowns";
import { openModal } from "../../store/modals/actions";
import { openEditNameModal } from "../modals/EditNameModal";
import { openSaveAsNewModalModal } from "../modals/SaveAsNewModal";
import { createDecisionTabWithScenario } from "../helpers/helpers";

export const ActionsDropdown = ({ forecast_simulator_scenario, btnId, openModal, deleteCallback }) => {
  const iconDropdownEvents = {
    editHandler: 'editHandler',
    deleteHandler: 'deleteHandler',
    saveAsNewHandler: 'saveAsNewHandler',
    createDecisionHandler: 'createDecisionHandler'
  }

  const [generateExcelDisabled, setGenerateExcelDisabled] = useState(true);

  useEffect(() => {
    if(forecast_simulator_scenario.scenario_loaded) setGenerateExcelDisabled(false);
  }, [forecast_simulator_scenario.scenario_loaded]);

  const iconDropdownSelect = (eventKey) => {
    switch (eventKey) {
      case iconDropdownEvents.editHandler:
        return openEditNameModal({ openModal });
      case iconDropdownEvents.deleteHandler:
        return deleteCallback()
      case iconDropdownEvents.saveAsNewHandler:
        return openSaveAsNewModalModal({ openModal });
      case iconDropdownEvents.createDecisionHandler:
        return createDecisionTabWithScenario(forecast_simulator_scenario)
    }
  }

  return <IconDropdown id={btnId} bsPrefix="h5 m-0 btn-link pt-2"
                       className="btn btn-secondary btn-lg-round d-flex p-0"
                       onSelect={iconDropdownSelect}>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.saveAsNewHandler} title="Save as a new scenario"/>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.editHandler} title="Edit scenario name"/>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.createDecisionHandler} title="Create a decision"/>
    <BaseHamburgerItem eventKey={iconDropdownEvents.deleteHandler}>
      <DeleteItemElement name="scenario" />
    </BaseHamburgerItem>
  </IconDropdown>;
}
const mapStateToProps = ({ forecast_simulator_scenario }) => ({ forecast_simulator_scenario });
export default connect(mapStateToProps, { openModal })(ActionsDropdown);
