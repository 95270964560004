import React from 'react';
import { connect } from "react-redux";

const CategoryItem = ({category, key = ''}) => {
  return (
    <div className="d-flex">
      <div className={`btn text-start mb-2 w-100 btn-light`} key={key} style={{ cursor: 'grab' }}>
        <div className={`category-description`}>
          {category.name}
        </div>
      </div>
    </div>
  )
};
export default connect(null, {})(CategoryItem);
