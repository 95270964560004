import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { hideCreateDecisionTab, TABS_URLS } from "../../../helpers/home_helpers";

export const HeaderSection = ({current_user}) => {
  return <React.Fragment>
    <div className="row">
     <div className="col">
       <h1>What would you like to do?</h1>
     </div>
    </div>
    <div className="row">
     <div className="col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3" hidden={hideCreateDecisionTab(current_user)}>
       <Link to={`/homepage/${TABS_URLS.create_decision}`} className="text-dark text-center">
         <div className="tile-content bg-white px-2 py-3">
           <div className="btn btn-primary btn-sm btn-sm-round d-inline-flex justify-content-center align-items-center mb-2">
             <i className="fas fa-plus h4 m-0" />
           </div>
           <div className="h3 my-0 ">Create decisions</div>
         </div>
       </Link>
     </div>
     <div className="col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
       <Link to={`/homepage/${TABS_URLS.search_decisions}`} className="text-dark text-center">
         <div className="tile-content bg-white px-2 py-3">
           <div className="btn btn-primary btn-sm btn-sm-round d-inline-flex justify-content-center align-items-center mb-2">
             <i className="fas fa-search h4 m-0" />
           </div>
           <h4 className="my-0">Search decision bank</h4>
         </div>
       </Link>
     </div>
    </div>
  </React.Fragment>
}
const mapStateToProps = ({ current_user }) => ({ current_user });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderSection);
