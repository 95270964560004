import React from 'react';
import {isBlank, isPresent} from "../../../helpers/common";

export const OtherConsideredChoicesSection = ({ object, decision = {}, recommendation = {} }) => {
  if (!object.isOpenEnded) return null;

  const consideredChoices = isPresent(recommendation) ? recommendation.considered_choices : decision.considered_choices
  if (isBlank(consideredChoices)) return null;

  return <div className="px-3 mb-3">
    <h3>Other choices considered</h3>
    <ul>
      { consideredChoices.map(choice => <li key={choice.slug}> {choice.description} </li>) }
    </ul>
  </div>;
}
export default OtherConsideredChoicesSection;
