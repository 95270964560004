import React from 'react';
import {isBlank, isPresent} from "../../../helpers/common";
import {PickListChoiceItem} from "../decision_input/choices/ChoiceItem";

export const pickedRankedVotes = (choicesVotes) => choicesVotes?.filter(choice => isPresent(choice?.vote?.rank))

const DisplayChoiceItem = ({ choice, selected, onSelectChoice }) =>
  <div className="d-flex">
    <div className={`mx-3 btn text-start mb-2 sidebar-choice-width w-100 h-auto ${selected ? 'btn-primary' : 'btn-secondary'}`} onClick={onSelectChoice}>
      <div className={`choice-description`}>
        {choiceRank(choice)}
        {choice.description}
      </div>
    </div>
  </div>

const addNewChoiceVote = (choicesVote, user, selected, rank, isRankChoice) => {
  const newVote = {
    user_email: user?.email || choicesVote.user_email,
    picked: isRankChoice ? false : !selected,
    rank
  }
  return {...choicesVote, vote: newVote}
}

const generateChoiceItemState = ({ choice, selected, choicesVotes, setChoicesVotes, object, user }) => {
  const isRankChoice = object.isRankedList;
  const isTopChoice = object.isTopChoice;
  const finalRankedChoices = pickedRankedVotes(choicesVotes);
  const rank = isRankChoice && !selected ? finalRankedChoices.length + 1 : null;

  const onSelectChoice = () => {
    const updatedChoicesVotes = choicesVotes.map(choicesVote => {
      if (choicesVote.slug === choice.slug) {
        const currentUserVoteBlank = choicesVote.vote;
        if (currentUserVoteBlank) {
          return addNewChoiceVote(choicesVote, user, selected, rank, isRankChoice);
        } else {
          return { ...choicesVote, vote: { ...choicesVote.vote, picked: isRankChoice ? false : !selected, rank } };
        }
      } else {
        return { ...choicesVote, vote: { ...choicesVote.vote, picked: isTopChoice || isRankChoice ? false : choicesVote.vote?.picked } };
      }
    });
    setChoicesVotes(updatedChoicesVotes);
  };

  return { onSelectChoice };
};


const choiceRank = (choice) => {
  const vote = choice.vote
  if(isBlank(vote)) return null;

  return vote.rank ? `${vote.rank}. ` : ''
}

export const ChoiceItem = ({object, choice, choicesVotes, setChoicesVotes, selected = false, user, ...opts}) => {

  const { ...choiceProps } = generateChoiceItemState({ choice, selected, choicesVotes, setChoicesVotes, object, user,  ...opts })

  if(object.isPicky) {
    return <PickListChoiceItem choice={choice} selected={selected} boxType="checkbox" {...choiceProps} />
  } else if(object.isTopChoice) {
    return <PickListChoiceItem choice={choice} selected={selected} boxType="radio" {...choiceProps} />
  } {
    return <DisplayChoiceItem choice={choice} selected={selected} {...choiceProps} />;
  }
};

export default ChoiceItem;
