import React from 'react';
import DriversPage from "./DriversPage";
import KeyDriverPage from "./KeyDriverPage";
import DecisionDetailsPage from "./DecisionDetailsPage";
import {isPresent} from "../../../helpers/common";
import Decision, { decisionDataSources } from "../../../models/decision";
import DataSource, { DecisionOrDSightDataSourcesTypes } from "../../../models/data_source";
import ContinuousPage from "./ContinuousPage";
import ImagesSupplement from "./ImagesSupplement";
import Cover from "./Cover";
import {driversToArray} from "../../../helpers/drivers_helpers";
import KeyPerDriverPage from "./KeyPerDriverPage";

// Create Document Component
const TreeDocument = ({ decision, org, org_categories, org_driver_types, drivers = [], data_sources = [], format = '' }) => {
    const filteredDrivers = drivers.filter(d => isPresent(d.driver) && isPresent(d.driver.question))
    const sortedDriverDataSources = decisionDataSources(data_sources, filteredDrivers, DecisionOrDSightDataSourcesTypes)
    const imagesDataSources = sortedDriverDataSources.filter(source => new DataSource(source).isPdfImage)
    const decisionObj = new Decision(decision, drivers)
    const driversData = isPresent(drivers) ? driversToArray(drivers).filter(d => d.driver.question) : [];

    return <>
        <Cover decision={decision} org={org} format={format} />
        <ContinuousPage decision={decision} org={org} format={format} data_sources={sortedDriverDataSources} drivers={filteredDrivers} />
        <DriversPage decision={decision} drivers={filteredDrivers} org={org} org_driver_types={org_driver_types} format={format} />
        {
          decisionObj.isRateAndCompareDriverChoicesHidden ?
            driversData.map(d => <KeyDriverPage {...d} decisionObj={decisionObj} org={org} decision={decision} data_sources={sortedDriverDataSources} org_driver_types={org_driver_types} key={`driver-page-${d.driver.slug}`} format={format} />) :
            driversData.map(d => <KeyPerDriverPage {...d} decisionObj={decisionObj} org={org} decision={decision} data_sources={sortedDriverDataSources} org_driver_types={org_driver_types} drivers={driversData} key={`per-driver-page-${d.driver.slug}`} format={format} />)
        }
        <DecisionDetailsPage org={org} decision={decision} org_categories={org_categories} data_sources={sortedDriverDataSources} format={format} />
        <ImagesSupplement org={org} data_sources={imagesDataSources} format={format} />
    </>
}

export default TreeDocument;
