import React from 'react';
import { connect } from "react-redux";
import DataSource from "../../models/data_source";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { MAX_PROGRESS } from "../../helpers/uploads_helpers";
import SourceDescription from "./SourceDescription";
import SourceIcon from "./SourceIcon";
import {isPresent} from "../../helpers/common";

const UploadingItem = ({
                         source, users, uploadProgress,
                         key = '', hidden = false, isTemplate = false,
                         onCancelUpload = () => {
                         }
                       }) => {
  if (hidden) return null;
  const sourceObj = new DataSource(source);

  const onCancelUploadSource = () => {
    onCancelUpload(source)
  };

  return (
    <div className="mb-2" key={key}>
      <div className="d-flex">
        <div className="d-flex source-icon">
          <div className={`align-text-top fa-layers fa-3x ${sourceObj.iconBlock}`}>
            <SourceIcon sourceObj={sourceObj} inUpload={isPresent(uploadProgress)}/>
          </div>
        </div>
        <div className="source-description ms-2 my-auto lh-sm">
          <SourceDescription {...{ sourceObj, isTemplate, users }} />
        </div>
        <div className="d-flex source-actions ms-auto">
          <div className="source-link">
            <a onClick={onCancelUploadSource} className="btn btn-sm btn-sm-round btn-secondary btn-light ms-1"
               hidden={uploadProgress === MAX_PROGRESS.toString()}>
              <i className="text-danger fas fa-times w-100"/>
            </a>
            <span className="text-muted" hidden={uploadProgress !== MAX_PROGRESS.toString()}>Saving...</span>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <ProgressBar animated now={uploadProgress} style={{ height: '2px' }}/>
      </div>
    </div>
  )
};
const mapStateToProps = ({ template, decision, report }, { driver, source, isTemplate, isReport }) => ({
  driver, source, report,
  users: isReport ? [] : isTemplate ? template.users : decision.users
});
export default connect(mapStateToProps)(UploadingItem);
