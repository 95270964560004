import React, { createRef, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

export const autoFocusRef = ({ autoFocus, setLoaded }) => {
  const ref = createRef();
  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
      setLoaded(true);
    }
  }, [autoFocus]);
  return ref;
}

export default ({
                  multiple = true,
                  disabled = false,
                  autoFocus = false,
                  minLength = 0,
                  placeholder = '', id = '',
                  setLoaded = () => {
                  },
                  flip = true,
                  ...opts
                }) => {
  const ref = autoFocusRef({ autoFocus, setLoaded })

  return <Typeahead
    {...{ multiple, placeholder, id, ref, key: id, disabled, minLength, flip, ...opts }}
  />
}
