import { isBlank } from "../../helpers/common";

const TYPES = {
  image: 'image',
  product: 'product',
  emotional_connection: 'emotional_connection',
  key_selling_point: 'key_selling_point',
  advertisement: 'advertisement',
  custom: 'custom'
};

export const initAttrSegmentBy = ({ attribute_segment }, d_sight_goal) => {
  const segmentHash = d_sight_goal.attribute_segments.find(s => s.id === attribute_segment)
  if (isBlank(segmentHash)) return false;

  return new AttributeSegment(segmentHash)
}

export class AttributeSegment {
  constructor(data) {
    this.data = data
  }

  get category() {
    return this.data.category;
  }

  get group() {
    return this.data.group;
  }

  get name() {
    return this.data.name;
  }

  get isImage() {
    return this.category === TYPES.image;
  }

  get isCustomCategory() {
    return this.category === TYPES.custom;
  }

  get isProduct() {
    return this.category === TYPES.product;
  }

  get isEmotionalConnection() {
    return this.category === TYPES.emotional_connection;
  }

  get isKeySellingPoint() {
    return this.category === TYPES.key_selling_point;
  }

  get isAdvertisement() {
    return this.category === TYPES.advertisement;
  }
}
