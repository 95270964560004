import React from 'react';
import { connect } from "react-redux";
import { setMembersSidebarOpen } from "../../store/sidebar/actions";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";

const MembersHamburgerButton = ({ openSideBar }) =>
  <BaseHamburgerBtn onSelect={openSideBar} title="Members" />

const mapDispatchToProps = (dispatch) => ({
  openSideBar: () => dispatch(setMembersSidebarOpen(true))
});
export default connect(null, mapDispatchToProps)(MembersHamburgerButton);
