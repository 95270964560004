import React, { useMemo } from "react";
import Wrapper from "../Wrapper";
import DecisionStatus from "../decision/DecisionStatus";
import Description from "../decision/Description";
import AssignedUser from "../shared/AssignedUser";
import DecisionInfo from "../decision/DecisionInfo";
import RecordedDecisionCard from "./RecordedDecisionCard";
import RecommendedDecisionCard from "./RecommendedDecisionCard";
import {isDeciderReadOnly, isVisitor} from "../../../helpers/user_helpers";
import {isBlank} from "../../../helpers/common";
import {ArrowUpIcon} from "../../../icons/custom_icons";

export const assignedDecisionUser = (collaborators = [], contactsData = {}, decisionObject) => {
  if (isBlank(decisionObject.assignedCollaboratorUser)) return {};

  return decisionObject.assignee(contactsData.contacts, decisionObject.isTreeHistorical) ||
  collaborators.find((collaborator) =>
    decisionObject.assignedCollaboratorUser?.toLowerCase() === collaborator.email) ||
  { email: decisionObject.assignedCollaboratorUser }
}

export default ({ decision, sidebar, collaborators = [], contactsData, decisionObject, user, setDecisionInputSidebarOpen, setRecommendationInputSidebarOpen, showArrow = false }) => {
  if(decisionObject.isDecisionRecorded) return <RecordedDecisionCard />
  if(decisionObject.isEnteredRecommendation) return <RecommendedDecisionCard />

  const assignedUser = assignedDecisionUser(collaborators, contactsData,  decisionObject);
  const recommendationObject =  useMemo(() => decisionObject.recommendation, [decisionObject])

  const showRecommendationInput = () =>
    ((decisionObject.isTreeHistorical && decisionObject.withRecommendation) || decisionObject.isTreeRecommendation) &&
      !recommendationObject.isEnteredRecommendation

  const onEditDecision = () => {
    if(isVisitor(user) || isDeciderReadOnly(user)) return false;

    showRecommendationInput() ?
      setRecommendationInputSidebarOpen(!sidebar.recommendationInputSidebar) :
      setDecisionInputSidebarOpen(!sidebar.decisionInputSidebar)
  }

  return <Wrapper>
    <div className="d-flex">
      <DecisionStatus {...{ decisionObject } } />
      <div className="d-flex flex-column my-auto lh-sm w-100 me-1" style={{minHeight: 36}}>
        <Description {...{user, description: decision.description, onClick: onEditDecision}} />
        <DecisionInfo {...{user, decision, decisionObject}} />
      </div>
      <AssignedUser bubbleIcon={`decision-assignment-${decision.slug}`} assignedUser={assignedUser}/>
    </div>
    <div className={`arrow-up-icon-block ${showArrow ? 'd-block' : 'd-none'}`}>
      <ArrowUpIcon/>
    </div>
  </Wrapper>
}
