import React, { useMemo } from "react";
import Wrapper from "../Wrapper";
import DecisionStatus from "../decision/DecisionStatus";
import Description from "../decision/Description";
import AssignedUser from "../shared/AssignedUser";
import DecisionInfo from "../decision/DecisionInfo";
import { assignedDecisionUser } from "./DecisionCard";
import {ArrowUpIcon} from "../../../icons/custom_icons";

export default ({ decision, user, decisionObject, collaborators, onDecisionClick, showArrow = false }) => {
  const assignedUser = assignedDecisionUser(collaborators, {}, decisionObject);

  return <Wrapper>
    <div className="d-flex">
      <DecisionStatus {...{decisionObject}} />
      <div className="d-flex flex-column my-auto lh-sm w-100 me-1" style={{minHeight: 36}}>
        <Description {...{user, description: decision.description, onClick: onDecisionClick}} />
        <DecisionInfo {...{user, decision, decisionObject}} />
      </div>
      <AssignedUser bubbleIcon={`decision-assignment-${decision.slug}`} assignedUser={assignedUser}/>
    </div>
    <div className={`arrow-up-icon-block ${showArrow ? 'd-block' : 'd-none'}`}>
      <ArrowUpIcon/>
    </div>
  </Wrapper>
}
