import React from 'react';
import { currencyFormat, isBlank } from "../../../helpers/common";

export const ExpectedInvestmentSection = ({ object }) => {
  if (isBlank(object.expected_investment)) return null;

  return <div className="px-3 mb-3">
    <h3>Expected investment or cost</h3>
    <div>{currencyFormat(object.expected_investment)}</div>
  </div>
}
export default ExpectedInvestmentSection;
