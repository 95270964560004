import React, { useState } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateHomepageCurrentTab } from "../../store/homepage/actions";
import {
  hideSettingsTab,
  isCategoriesCenter,
  isSettingsTab,
  isTemplatesCenter,
  isUsersCenter,
  TABS,
  TABS_URLS
} from "../../helpers/home_helpers";
import { isMobile } from "react-device-detect";

export const SettingsItem = ({
                               displayCurrent,
                               home, current_user,
                               updateHomepageCurrentTab
                            }) => {
  if (hideSettingsTab(current_user)) return null;

  const expandedByDefault = isSettingsTab(home, current_user)
  const [expanded, setExpanded] = useState(expandedByDefault)

  return <li className={displayCurrent && isMobile && !expanded && isSettingsTab(home, current_user) ? 'active' : '' }>
    <a aria-expanded={expanded ? 'true' : 'false'}
       className={`text-nowrap dropdown-toggle pointer ${expanded ? 'rotate-after-180' : ''}`}
       onClick={() => { setExpanded(!expanded) }}>
      <span className={`icon d-inline-block text-center ${isSettingsTab(home, current_user) ? 'active' : ''}`}>
        <i className="fas fa-cog fa-lg" />
      </span>
      <span className="d-none d-lg-flex pointer">Settings</span>
    </a>
    <ul className={`collapse list-unstyled list-settings ${expanded ? 'show' : ''}`}>
      <li className={displayCurrent && isUsersCenter(home, current_user) ? 'active' : ''}>
        <Link to={`/homepage/${TABS_URLS.users}`}
              onClick={() => updateHomepageCurrentTab(TABS.users)}
              hidden={hideSettingsTab(current_user)}>
          <span className="tab-title">Users</span>
        </Link>
      </li>
      <li className={`${displayCurrent && isCategoriesCenter(home, current_user) ? 'active' : ''} settings-menu`}>
        <Link to={`/homepage/${TABS_URLS.categories}`} onClick={() => updateHomepageCurrentTab(TABS.categories)}>
          <span className="tab-title">Categories</span>
        </Link>
      </li>
      <li className={displayCurrent && isTemplatesCenter(home, current_user) ? 'active' : ''}>
        <Link to={`/homepage/${TABS_URLS.templates}`} onClick={() => updateHomepageCurrentTab(TABS.templates)}>
          <span className="tab-title">Templates</span>
        </Link>
      </li>
    </ul>
  </li>
}

const mapStateToProps = ({ home, current_user }) => ({ home, current_user });
export default connect(mapStateToProps, { updateHomepageCurrentTab })(SettingsItem);
