import React from 'react';
import { connect } from "react-redux";
import HelpContent from "../help_content";
import { isLearningTab } from "../../helpers/home_helpers";
import BaseHelpPanel from "../../common/BaseHelpPanel";
import { isMobile } from "react-device-detect";

const HelpPanel = ({ home, current_user }) => {
  if (isLearningTab(home, current_user)) return null;

  return <BaseHelpPanel addClass="left-side-panel-position home-page"
    panelClassName={`${isMobile ? '' : 'd-flex flex-column'} sidebar-panel-wrapper-content help-panel-content`}>
    <HelpContent />
  </BaseHelpPanel>
}

const mapStateToProps = ({ home, current_user }) => ({ home, current_user });
export default connect(mapStateToProps)(HelpPanel);
