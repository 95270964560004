import { findNodeName } from "../../../../../models/d_sight/nodes";
import {
  chartBasesValueFor,
  chartSignificanceChangeValueFor,
  chartValueFor,
  findProcessDataFor, processDataForTypeNode,
  surveyProcessDataIndex,
  TIME_NODE_INDEXES
} from "../../../../../models/d_sight/metric_chart_helpers";
import { getKeyByValue } from "../../../../../helpers/common";

export const chartDataForNode = (node_id, { d_sight_goal, dataByType, timeNode, surveys, metric }) => {
  const nodeName = findNodeName(d_sight_goal, node_id) || ''
  const processData = findProcessDataFor(dataByType, node_id, parseInt(getKeyByValue(TIME_NODE_INDEXES, timeNode)));
  const data = [], significance_changes = [], samples = [];
  surveys.forEach(s => {
    data[surveyProcessDataIndex(s)] = chartValueFor(s, processData, metric)
    significance_changes[surveyProcessDataIndex(s)] = chartSignificanceChangeValueFor(s, processData)
    samples[surveyProcessDataIndex(s)] = chartBasesValueFor(s, processData)
  });
  return { name: nodeName, node_id, custom: { measure: metric.measure }, data, significance_changes, samples }
}
export const filterSurveysByBases = (survey, data) => !data.every(({samples}) => samples[surveyProcessDataIndex(survey)] === 0)

export const prepareChartData = ({
                                   process_data, surveys, timeNode, sortedNodeNames, d_sight_goal, metric
                                 }) => {
  const dataByType = processDataForTypeNode({ process_data }, timeNode)
  const data = sortedNodeNames.map(node_id =>
    chartDataForNode(node_id, { d_sight_goal, dataByType, timeNode, surveys, metric })
  )

  return {
    data,
    surveys: surveys.filter(survey => filterSurveysByBases(survey, data))
  }
}
