import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateHomepageCurrentTab } from "../../store/homepage/actions";
import { hideLearningTab, isLearningTab, TABS, TABS_URLS } from "../../helpers/home_helpers";

export const LearnItem = ({
                            displayCurrent,
                            home, current_user,
                            updateHomepageCurrentTab
                         }) => {
  if (hideLearningTab(current_user)) return null;

  return <li className={displayCurrent && isLearningTab(home, current_user) ? 'active' : ''}>
    <Link to={`/homepage/${TABS_URLS.learn}`}
          onClick={() => updateHomepageCurrentTab(TABS.learn)}
          className="text-nowrap">
      <span>
        <span className="icon d-inline-block text-center">
          <i className="fas fa-chart-column fa-lg" />
        </span>
        <span className="d-none d-lg-inline tab-title">Decision learning</span>
      </span>
    </Link>
  </li>
}

const mapStateToProps = ({ home, current_user, current_org }) => ({ home, current_user, current_org });
export default connect(mapStateToProps, { updateHomepageCurrentTab })(LearnItem);
