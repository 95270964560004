import { isBlank, isPresent } from "./common";
import { DSightSourcesTypes } from "../models/data_source";

const GOOGlE_DOCS = [
  'https://docs.google.com/document',
  'https://docs.google.com/spreadsheets',
  'https://docs.google.com/presentation'
];
const youtubeFormat = 'https://www.youtube.com/watch?v=';
const googleDriveFile = 'https://drive.google.com/file';
const googleDriveFolder = 'https://drive.google.com/drive';
const powerbiLink = 'https://app.powerbi.com/reportEmbed?'
const dSightLinkUrlPattern = /\/dsight\/(decisions|templates)\/([^/]+)\/analysis(?:\/metrics)?/;
const dSightGoalMetricsUrlPattern = /\/dsight\/goals\/\d+\/metrics(?:\/\d+)?/;

export function linkTitlePrepare(source) {
  const title = source?.title
  const link = String(source?.link_url).replace(/(http:\/\/)|(https:\/\/)|(\/$)/g, '')
  return(
    title ? title : link
  )
}
export function iframeLinkPrepare(source) {
  if (isBlank(source)) return null;

  const link = String(source.link_url);
  if ( link.includes(youtubeFormat) ){
    return link.replace("watch?v=", "embed/").replace(/&.*/, "");
  } else if(link.includes(googleDriveFile)) {
    return link.replace("view?usp=sharing", "preview");
  } else if(link.includes(googleDriveFolder)) {
    return link.replace("drive/", "")
      .replace("folders/", "embeddedfolderview?id=")
      .replace(/\?resourcekey=.*/, "");
  } else if(GOOGlE_DOCS.some(GOOGlE_DOCS => link.includes(GOOGlE_DOCS))) {
    return link;
  } else if(link.includes(powerbiLink)) {
    return link;
  } else {
    return null;
  }
}

export const isOwnHostLink = (link) => link.includes(document.location.host);
export const transformSelfLink = (link) => {
  if (isOwnHostLink(link)) {
    const url_link = new URL(link)
    const getParams = new URLSearchParams(url_link.search);
    getParams.set('i_frame', 'true')
    url_link.search = getParams.toString();
    return url_link.toString();
  }
  return link;
}

export function isEnteredDSightLink(source) {
  if (isBlank(source.link_url)) return false;

  return !DSightSourcesTypes.includes(source.type) &&
    (source.link_url.match(dSightLinkUrlPattern) || source.link_url.match(dSightGoalMetricsUrlPattern))
}

export function isEnteredTemplateDSightLink(source) {
  return isPresent(source.link_url.match(dSightLinkUrlPattern))
}

export function isEnteredDSightGoalMetricsLink(source) {
  return isPresent(source.link_url.match(dSightGoalMetricsUrlPattern))
}
