import React from 'react';

export default () =>
  <>
    <p>
      Decision flows allow grouping decisions by process or topic.
    </p>
    <p>
      Open the decision flow and click on the edit button to add, remove, or sort decisions. Add, remove, or sort sections to create and manage sub-groupings or parallel tracks for decisions.
    </p>
    <p>
      Decision flow managers can optionally number decisions from More actions.
    </p>
  </>
