import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsMore from "highcharts/highcharts-more.js"
import { formatTotalCount } from "../../../homepage/tabs/learning/AllDecisionsStatisticsPanel";
import { isBlank, isPresent } from "../../../helpers/common";
import TreeExpandedIcon from "../../../../../../assets/images/tree_expanded_icon_muted.svg";
import { CHART_COLORS, POINT_HEADER, SERIES_NAME_ROW } from "../../../models/d_sight/metric_chart_helpers";

highchartsMore(Highcharts);

const isBlankMonthData = (month_data) => {
 if (month_data.number < 1) return false;
 if (isBlank(month_data)) return true;
 if (isBlank(month_data?.statistics?.total_count)) return true;

 return parseInt(month_data?.statistics?.total_count) < 1;
}

export const BASE_CHART_OPTIONS = {
  chart: {
    type: 'spline',
    spacing: [5, 0, 0, 5],
    style: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },
  credits: { enabled: false },
  yAxis: {
    title: { text: null },
    labels: {
      format: '{text}',
      style: {
        fontSize: '0.75rem',
        color: '#1F2F3F'
      }
    },
  },
  tooltip: {
    headerFormat: `${POINT_HEADER}<table>`,
    pointFormat: `<tr>${SERIES_NAME_ROW}<td style="padding:0"><b>{point.y}</b></td></tr>`,
    footerFormat: '</table>',
    shared: true,
    style: {
      color: '#1F2F3F',
    },
    useHTML: true
  },
  legend: {
    enabled: false,
    itemStyle: {
      color: '#1F2F3F',
      fontWeight: '500',
      fontSize: '0.875rem'
    }
  }
}

export const filterChartsData = (by_month_data) => {
  const result = []
  by_month_data.forEach(month_data => {
    if (!isBlankMonthData(month_data) || isPresent(result)) result.push(month_data)
  })
  return result;
}

export const xAxisByMonth = (dataByMonth) => {
  const categories = dataByMonth.map(hash => hash.date_name)
  return {
    categories,
    labels: {
      style: {
        fontSize: '0.75rem',
        color: '#1F2F3F'
      }
    }
  }
}

export const TotalDecisionsName = ({ learn }) =>
  <div>
    <div className="icon no-pointer mb-2">
      <div className="d-flex justify-content-center align-items-center bg-light text-gray rounded-circle mx-auto">
        <img src={TreeExpandedIcon} className={'p-2'} alt="Total decisions" />
      </div>
    </div>
    <h3 className="mb-0">{formatTotalCount(learn.statistics?.all_statistics)} </h3>
    <div className="text-gray lh-sm">Total<br/>decisions</div>
  </div>

export const TotalDecisionsChart = ({ learn }) => {
  const dataByMonth = filterChartsData(learn.statistics.by_month)
  const recentValues = dataByMonth.findIndex(hash => hash.number < 1);
  const data = dataByMonth.map(hash => parseInt(hash.statistics.total_count))

  const chartOptions = {
    ...BASE_CHART_OPTIONS,
    colors: CHART_COLORS,
    xAxis: xAxisByMonth(dataByMonth),
    title: {
      text: 'All decisions',
      align: 'left',
      style: {
        fontSize: '1.125rem',
        color: '#1F2F3F',
        fontWeight: 500
      }
    },
    series: [{
      name: 'Total decisions',
      data,
      lineWidth: 3,
      zoneAxis: 'x',
      zones: isBlank(recentValues) ? [] :
        [
          { value: recentValues - 1 },
          { dashStyle: 'dash' }
        ]
    }]
  }
  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />
}
