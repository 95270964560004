import React from 'react';
import { JoditTextEditor } from '../../../common/JoditTextEditor';


export default ({ isTemplate, titleNotes, setTitleNotes }) =>
  <div className="w-100 mb-3" hidden={!isTemplate}>
    <h3>Title notes</h3>
    <JoditTextEditor className="jodit-container-default"
                     value={titleNotes}
                     placeholder="Enter any comments or instructions for the decision question"
                     setValue={setTitleNotes}
                     autofocus={false} />
  </div>
