import React, {useEffect, useMemo, useState} from "react";
import Decision from "../../../models/decision";
import Driver from "../../../models/driver";
import { isPresent } from "../../../helpers/common";
import { driversToArray } from "../../../helpers/drivers_helpers";
import Alerts from "../../../../js/alerts";

export const treeInitData = (tree, decision) => {
  const rootDrivers = useMemo(() =>
      tree.drivers.filter(driverData => isPresent(driverData.driver.question)),
    [tree.drivers]
  )
  const decisionObject = useMemo(() => new Decision(decision, rootDrivers), [decision, rootDrivers])
  return {
    decisionObject,
    rootDrivers
  }
}

export const driverInitData = (tree, driverData, decisionObject = {}) => {
  const [showChildren, setShowChildren] = useState(true);
  const childrenDrivers = useMemo(() =>
      driverData.children.filter(childDriverData => isPresent(childDriverData.driver.question)),
    [tree.drivers, driverData.children]
  )
  const driverObject = useMemo(() =>
      new Driver(driverData.driver, driverData.driver_sources_slugs, [], decisionObject),
    [driverData, decisionObject]
  )

  return { childrenDrivers, driverObject, showChildren, setShowChildren }
}

export const findDriverDataInArray = (driversArray, slug) => driversArray.find((driverData) => driverData.driver.slug === slug) || {}
export const findDriverData = (store, slug) => findDriverDataInArray(driversToArray(store.drivers), slug)

export const editingDriverAlert = (channels, action, slug) => {
  const editingByUser = channels.tree[action]
    .find((driverData) => driverData.driver_slug === slug)?.editing_by
  Alerts.warningWithOk({
    title: "Sorry, this driver can not be updated at this time.",
    text: `${editingByUser || 'Somebody'} is currently editing this driver. Please try again later.`
  })
}

export const $driverInputSidebarWrapper = () => $('.driver-input-panel-wrapper');

export const openDriverInput = (value, slug, resetDriverInputSidebar, setDriverInputSidebarOpen, opts = {}) => {
  if($driverInputSidebarWrapper().hasClass(`driver-slug-${slug}`)) {
    resetDriverInputSidebar();
    return;
  }
  const timeout = $driverInputSidebarWrapper().length ? 600 : 100;
  if($driverInputSidebarWrapper().length) resetDriverInputSidebar();
  setTimeout(() => setDriverInputSidebarOpen(true, slug, opts), timeout)
};

export const calcAnswered = (isDecision, object, driver_sources_slugs, decision) =>
  isDecision ?
    new Decision(object).isCompleted :
    new Driver(object, driver_sources_slugs, {}, new Decision(decision)).isCompleted

export const calcIsInFlight = (isDecision, object, driver_sources_slugs, decision) =>
  isDecision ?
    (new Decision(object).isInFlight) :
    new Driver(object, driver_sources_slugs, {}, new Decision(decision)).isInFlight

export const displayDriverPreviewData = (driverData) => isPresent(driverData?.driver?.notes) || isPresent(driverData?.driver_sources_slugs);
export const displayDSightDriverPreviewData = (driverData) => {
  const insightsData = new Driver(driverData.driver).insightsData;
  return isPresent(insightsData);
};

export const useDecisionRecordedSidebarHandlerEffect = (sidebar, decisionObject, setFinalDecisionSidebarOpen) => {
  useEffect(() => {
    if (sidebar.decisionInputSidebar === true || sidebar.recommendationInputSidebar === true) return;

    if(!decisionObject.withRecommendation && decisionObject.isDecisionRecorded) {
      setFinalDecisionSidebarOpen(true);
    }

    if(!decisionObject.isRecordedOrRecommended) {
      setFinalDecisionSidebarOpen(false);
    }
  }, [decisionObject.isDecisionRecorded, decisionObject.isRecordedOrRecommended])
}

export const useRecommendationFlowChangedEffect = (sidebar, decisionObject, setRecommendationInputSidebarOpen,
                                                   setDecisionInputSidebarOpen) => {
  useEffect(() => {
    if(!decisionObject.isTreeHistorical) {
      if (sidebar.recommendationInputSidebar && !decisionObject.isRecommendationFlow) {
        setRecommendationInputSidebarOpen(false);
        setDecisionInputSidebarOpen(true);
      }
      if (sidebar.decisionInputSidebar && decisionObject.isRecommendationFlow &&
        !decisionObject.isDSightTreeRecommendation) {
        setDecisionInputSidebarOpen(false);
        setRecommendationInputSidebarOpen(true);
      }
    }
  }, [decisionObject.isRecommendationFlow])
}
