export const NAME_INPUT_LIMIT = 100;

export const reportSelectedProperties = (selectedPropertyOptions, org_report_properties) =>
  org_report_properties.available_report_properties.filter(property =>
    property.options.some(option => selectedPropertyOptions.includes(option.id))
  );

export const reportSelectedOpenProperties = (selectedOpenPropertyOptions, org_report_properties) =>
  org_report_properties.available_report_properties.filter(property =>
    property.options.some(option => selectedOpenPropertyOptions.map(optionData => optionData.id).includes(option.id))
  );