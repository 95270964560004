import React from "react";
import {connect} from "react-redux";
import {isBlank} from "../../../../ms_teams/helpers/common";
import UserAvatarImage from "../../../common/UserAvatarImage";
import { userName } from "../../../helpers/user_helpers";
import {openModal} from "../../../store/modals/actions";

export const AssignmentSection = ({assignedUser, addClass = '', assignedCollaboratorEmail, modalType = '', openModal, isDSight = false}) => {
  if (isBlank(assignedUser) && isBlank(assignedCollaboratorEmail)) return null
  const name = userName(assignedUser, assignedCollaboratorEmail)

  return (
    <div className={`${addClass} mb-3`}>
      <div className={`d-inline-flex align-items-center ${isDSight ? "cursor-default" : "text-primary pointer"}`} onClick={isDSight ? null : () => openModal({ type: modalType })}>
        <UserAvatarImage user={assignedUser} pendingUserEmail={assignedCollaboratorEmail} />
        <div className='ms-2'>
          Assigned to {name}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ tree }) =>
  ({ default_user_avatar_url: tree.default_user_avatar_url });
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentSection);
