import React, { useMemo } from 'react';
import {
  formatAvgTime,
  formatSuccessDecisions,
  formatTotalCount
} from "../../../homepage/tabs/learning/AllDecisionsStatisticsPanel";
import { isBlank } from "../../../helpers/common";
import { columnBgColor, DEFAULT_CELL, DEFAULT_COLUMN, Table, WHITE_COLUMN } from "../../../common/Table";
import { StatisticSection } from "../PageContent";

const prepareTableRowData = (hash) => ({
  fullName: hash?.user,

  decisionsAsCollaborator: parseInt(hash?.statistics?.as_collaborator?.total_count),
  formattedDecisionsAsCollaborator: formatTotalCount(hash?.statistics?.as_collaborator),

  avgTimeAsCollaborator: parseInt(hash?.statistics?.as_collaborator?.avg_decision_time),
  formattedAvgTimeAsCollaborator: formatAvgTime(hash?.statistics?.as_collaborator),

  decisionsAsManager: parseInt(hash?.statistics?.as_manager?.total_count),
  formattedDecisionsAsManager: formatTotalCount(hash?.statistics?.as_manager),

  avgTimeAsManager: parseInt(hash?.statistics?.as_manager?.avg_decision_time),
  formattedAvgTimeAsManager: formatAvgTime(hash?.statistics?.as_manager),

  decisionSuccess: hash?.statistics?.successful_decisions,
  formattedDecisionSuccess: formatSuccessDecisions(hash?.statistics)
})

const columns = [
  {
    Header: 'Name',
    accessor: 'fullName',
    cellClassName: WHITE_COLUMN,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${DEFAULT_COLUMN}`}>{row.original.fullName}</div>
  },
  {
    Header: 'Decisions as collaborator',
    accessor: 'decisionsAsCollaborator',
    cellClassName: WHITE_COLUMN,
    sortDescFirst: true,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${DEFAULT_COLUMN}`}>{row.original.formattedDecisionsAsCollaborator}</div>
  },
  {
    Header: 'Avg time as collaborator',
    accessor: 'avgTimeAsCollaborator',
    cellClassName: WHITE_COLUMN,
    sortDescFirst: true,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${DEFAULT_COLUMN} text-nowrap`}>{row.original.formattedAvgTimeAsCollaborator}</div>
  },
  {
    Header: 'Decisions as manager',
    accessor: 'decisionsAsManager',
    cellClassName: WHITE_COLUMN,
    sortDescFirst: true,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${DEFAULT_COLUMN}`}>{row.original.formattedDecisionsAsManager}</div>
  },
  {
    Header: 'Avg time as manager',
    accessor: 'avgTimeAsManager',
    cellClassName: WHITE_COLUMN,
    sortDescFirst: true,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${DEFAULT_COLUMN} text-nowrap`}>{row.original.formattedAvgTimeAsManager}</div>
  },
  {
    Header: 'Decision success',
    accessor: 'decisionSuccess',
    cellClassName: WHITE_COLUMN,
    sortDescFirst: true,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${columnBgColor(row.original.decisionSuccess)}`}>{row.original.formattedDecisionSuccess}</div>
  }
]

const initialState = { sortBy: [{ id: 'fullName', desc: false }] }

export const ActivityByPersonSection = ({ learn }) => {
  const data = useMemo(() =>
      learn.statistics?.all_statistics?.persons_activity?.map(h => prepareTableRowData(h)) || []
    , [learn])

  if (isBlank(data)) return null;

  return <StatisticSection>
      <div>
        <h3 className="title">Activity by person</h3>
        <div className={`answers-table-max-h overflow-auto`}>
          <Table columns={columns} data={data} responsive initialState={initialState}/>
        </div>
    </div>
  </StatisticSection>
}
