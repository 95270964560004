import React from 'react';
import { connect } from "react-redux";
import { setDiscussionPanelOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import DiscussionSection from "./discussion";
import { isMobile } from "react-device-detect";
import PanelHeader from "./PanelHeader";

export const DiscussionPanel = ({ sidebar, openSideBar }) =>
  <SlidingPanel
    type="left"
    isOpen={sidebar.discussionSidebar}
    panelContainerClassName={`sidebar-panel-wrapper discussion-panel-wrapper left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content discussion-panel-content`}
    size={50}
  >
    <PanelHeader headerName="Comments" openSideBar={openSideBar} preview={true} />
    <DiscussionSection />
  </SlidingPanel>

const mapStateToProps = (state) => ({
  sidebar: state.sidebar
});
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setDiscussionPanelOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(DiscussionPanel);
