import React from 'react';
import { setMembersSidebarOpen } from "../../store/sidebar/actions";
import { connect } from "react-redux";

const MembersItem = ({sidebar, openDecisionMembersSideBar, membersInvitesLength = 0}) => {
  return <li className={sidebar.decisionMembersSidebar ? 'active' : ''}>
    <div className="side-row text-nowrap pointer"
         onClick={() => openDecisionMembersSideBar(!sidebar.decisionMembersSidebar)}>
      <span className="icon d-inline-block text-center ">
        <i className="fas fa-users fa-lg"/>
      </span>
      <span className="d-none d-lg-inline tab-title">Members</span>
      <div className="rounded-pill badge-pill-char comments me-2"
           hidden={membersInvitesLength < 1}>{membersInvitesLength}</div>
    </div>
  </li>
}

const mapStateToProps = ({sidebar}) => ({sidebar});
const mapDispatchToProps = (dispatch) => ({
  openDecisionMembersSideBar: (flag) => dispatch(setMembersSidebarOpen(flag))
});
export default connect(mapStateToProps, mapDispatchToProps)(MembersItem);
