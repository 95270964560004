import React  from 'react';
import {DEFAULT_DRIVER_CHOICE_DATA} from "../../../models/DriverChoiceRating";
import Button from 'react-bootstrap/Button';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {isBlank, isPresent} from "../../../helpers/common";


export const getSliderStyles = ({hideHandle, startScale, endScale, markLabels = ()=>{}, height = 6}) => ({
  min: startScale,
  max: endScale,
  defaultValue: null,
  step: 1,
  marks: markLabels(),
  trackStyle: {
    backgroundColor: '#0E93C4',
    height
  },
  handleStyle: {
    height: 24,
    width: 24,
    marginLeft: 0,
    marginTop: -9,
    backgroundColor: '#0E93C4',
    border: 'none',
    opacity: 1,
    display: hideHandle ? 'none' : 'd-block'
  },
  railStyle:{
    height
  }
});

export const RatingSlider = ({ sliderTitle, isSubDriver, showResetButton = false, rating, setRating, startScale, endScale, ratingLabels }) => {
  if (isSubDriver) return null;

  const clickReset = () => setRating(DEFAULT_DRIVER_CHOICE_DATA.rating);
  const noRating = isBlank(rating);
  const ratingValueDisplay = noRating ? '' : rating

  const handleRatingChange = (e) => {
    let result = e.target.value;
    if (isBlank(result)) return setRating(DEFAULT_DRIVER_CHOICE_DATA.rating);

    result = result.replace(/\D/g, '');
    if (result < startScale) return setRating(startScale);

    result = result.replace(/^0/g, '');
    if (result > endScale) return setRating(endScale);

    setRating(Number(result));
  };

  const markLabels = () => {
    let  markLabels = {};
    if (isPresent(ratingLabels)) {
      markLabels[startScale] = <span className="d-flex align-items-center text-gray fs-5 pt-1">{ratingLabels.low}</span>;
      markLabels[endScale] = <span className="d-flex align-items-center text-gray fs-5 pt-1">{ratingLabels.high}</span>;
    }
    else {
      markLabels[startScale] = <span className="d-flex align-items-center text-gray fs-5 pt-1">{startScale}</span>;
      markLabels[endScale] = <span className="d-flex align-items-center text-gray fs-5 pt-1">{endScale}</span>;
    }
    return  markLabels
  }


  return <div className="rating-slider">
    <div className="d-flex align-items-center mt-2 h-auto" style={{minHeight: "24px"}}>
      <span className="fw-bolder">{sliderTitle}</span>
      <div className="ms-auto">
        <Button hidden={!showResetButton} onClick={clickReset} bsPrefix="btn btn-link text-decoration-none p-0 shadow-none">
          Reset <i className="fas fa-undo text-primary" />
        </Button>
      </div>
    </div>
    <div className="d-flex mt-2">
      <input className="numbered-square" type="text" placeholder="N/A" value={ratingValueDisplay} onChange={handleRatingChange} />
      <div className="rate-slider px-2">
        <Slider {...getSliderStyles({hideHandle: noRating, startScale, endScale, markLabels})} onChange={setRating} value={rating} />
      </div>
    </div>
  </div>
}
