import React from 'react';
import ReactPaginate from 'react-paginate';

const NavigationButton = ({ icon, onClick = () => {}, fontSize = '1.275rem', addClass = '' }) => (
  <div className={`btn btn-sm btn-pagination d-flex justify-content-center align-items-center ${addClass}`} onClick={onClick}>
    <i className={`fa-solid ${icon}`} style={{ fontSize }} />
  </div>
);

const FirstPage = ({ page, setPage }) =>
  page !== 1 && <NavigationButton icon="fa-backward-step" addClass='me-1' onClick={() => setPage(1)} fontSize="1.063rem" />

const LastPage = ({ page, setPage, totalPages }) =>
  page !== totalPages && <NavigationButton icon="fa-forward-step" onClick={() => setPage(totalPages)} addClass='ms-1' fontSize="1.063rem" />

const pageLabel = (perPage, totalCount, page) => {
  const firstElement = page * perPage - (perPage - 1);
  const lastElement = page * perPage > totalCount ? totalCount : page * perPage;
  if (firstElement === lastElement && page !== 1) return `${firstElement} of ${totalCount}`;

  return `${firstElement}-${lastElement} of ${totalCount}`;
};

export const Pagination = ({ page, totalPages, setPage, totalCount = '', perPage = 24 }) => {
  return (
    <div className="d-flex">
      <FirstPage page={page} setPage={setPage} />
      <ReactPaginate
        pageCount={totalPages}
        pageRangeDisplayed={0}
        marginPagesDisplayed={0}
        previousLabel={<NavigationButton icon="fa-caret-left" addClass='me-2' />}
        previousLinkClassName={'page-link shadow-none border-0 p-0'}
        nextLabel={<NavigationButton icon="fa-caret-right" addClass='ms-2' />}
        nextLinkClassName={'page-link shadow-none border-0 p-0'}
        onPageChange={data => setPage(data.selected + 1)}
        containerClassName={'pagination home-page-pagy mx-0 justify-content-left mb-0'}
        subContainerClassName={'pages pagination'}
        activeClassName={'mt-1'}
        pageLinkClassName={'text-dark fw-normal'}
        pageLabelBuilder={() => pageLabel(perPage, totalCount, page)}
        forcePage={page - 1}
        previousClassName={`page-item previous ${page === 1 ? 'd-none' : ''}`}
        nextClassName={`page-item next ${page === totalPages ? 'd-none' : ''}`}
        disableInitialCallback={true}
        extraAriaContext={'aria-label'}
      />
      <LastPage page={page} setPage={setPage} totalPages={totalPages} />
    </div>
  );
};

export default Pagination;
