import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import { openModal } from "../../store/modals/actions";
import { openDecisionSetModal } from "../../homepage/modals/DecisionSetModal";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { successActions } from "../../helpers/common";
import { TABS_URLS } from "../../helpers/home_helpers";
import { resetSidebars } from "../../store/sidebar/actions";
import { resetTemplateSetData } from "../../store/template_set/common_actions";
import DetailsHamburgerButton from "../../template_view/header/DetailsHamburgerButton";
import DecisionSetPanelHamburgerButton from "../../template_view/header/DecisionSetPanelHamburgerButton";
import MembersHamburgerButton from "../../template_set_view/header/MembersHamburgerButton";
import DataSourcesHamburgerButton from "../../template_view/header/DataSourcesHamburgerButton";
import Dropdown from "react-bootstrap/Dropdown";

export const HamburgerDropdown = ({
                                    resetSet, resetSidebars,
                                    ...opts
                                  }) => {
  const history = useHistory();
  const processBackClick = useCallback(() => {
    successActions(true, [resetSet, resetSidebars]);
    redirectToHomeCallback(true, history, TABS_URLS.create_decision);
  }, [])

  return <HamburgerMenuIconBtn>
    <DetailsHamburgerButton />
    <MembersHamburgerButton />
    <DataSourcesHamburgerButton />
    <DecisionSetPanelHamburgerButton />
    <Dropdown.Divider />
    <BaseHamburgerBtn onSelect={() => openDecisionSetModal(opts)} title='Create decision flow'/>
    <BaseHamburgerBtn onSelect={processBackClick} title={'Go to homepage'}/>
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({ template_set }) => ({ template_set });
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  resetSidebars: () => dispatch(resetSidebars()),
  resetSet: () => dispatch(resetTemplateSetData())
});
export default connect(mapStateToProps, mapDispatchToProps)(HamburgerDropdown);
