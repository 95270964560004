import $ from "jquery";
import { useEffect } from "react";

const attachClickEvent = (target, callback) => $(target).off('click', callback).on('click', callback);
const detachClickEvent = (target, callback) => $(target).off('click', callback)

export const attachEventEffect = (ref, callback) => {
  useEffect(() => {
    attachClickEvent(ref.current, callback);
    return () => detachClickEvent(ref.current, callback);
  }, [])
}
