import React from 'react';
import { connect } from "react-redux";
import { setDecisionSidebarOpen } from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import ContextSection from "../../template_view/side_panel/sections/ContextSection";
import DataSourcesSection from "../../template_view/side_panel/sections/DataSourcesSection";
import CategoriesSection from "./sections/CategoriesSection";
import PanelHeader from "../../tree_view/side_panel/PanelHeader";
import { isMobile } from "react-device-detect";

export const DecisionDetailsPanel = ({ sidebar, openSideBar }) => {
  return <SlidingPanel
    type="left"
    isOpen={sidebar.decisionDetailsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper details-panel-width decision-panel left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
    size={52}
  >
    <PanelHeader headerName={'Details'} openSideBar={openSideBar} preview={true} />
    <ContextSection />
    <CategoriesSection />
    <DataSourcesSection isWizard={true} addClass="px-3 pb-3" />
  </SlidingPanel>
}
const mapStateToProps = (state) => ({
  sidebar: state.sidebar
});
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setDecisionSidebarOpen(options));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionDetailsPanel);
