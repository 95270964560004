import React, {useState} from 'react';
import { connect } from "react-redux";
import {
  createConsideredChoice,
  destroyChoice, updateChoice,
} from "../../../store/sidebar/actions";
import {isBlank, isPresent} from "../../../helpers/common";
import Decision from "../../../models/decision";
import {addNewChoiceCallback, defaultChoices, onChangeChoiceCallback} from "../../modals/entry_modal/helpers";
import {onSaveConsideredChoiceCallback} from "../recommendation_input/OtherConsideredChoicesInput";
import ConsideredChoiceSection from "../sections/ConsideredChoiceSection"

export const deleteConsideredChoiceCallback = (slug, consideredChoices, destroyChoice, setChoices, prevConsideredChoices) => {
  if (prevConsideredChoices.some(choice => choice.slug === slug)) {
    destroyChoice(slug)
  }
  const filteredChoices = consideredChoices.filter(c => c.slug !== slug)
  setChoices([...filteredChoices])
}

const OtherConsideredChoicesInput = ({ decision, createConsideredChoice,
                                       destroyChoice, updateChoice }) => {
  if (isBlank(decision) || !new Decision(decision).isOpenEnded) return null;

  const consideredChoices = decision.considered_choices
  const [choices, setChoices] = useState(isPresent(consideredChoices) ? consideredChoices : defaultChoices())
  const [prevConsideredChoices, setPrevConsideredChoices] = useState(consideredChoices)
  const [newChoice, setNewChoice] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const onChangeChoice = onChangeChoiceCallback(choices, setChoices);
  const addNewChoice = addNewChoiceCallback(setNewChoice, choices, setChoices)
  const deleteConsideredChoice = (slug) => deleteConsideredChoiceCallback(slug, consideredChoices, destroyChoice, setChoices, prevConsideredChoices)
  const [mouseOverRemoveSlug, setMouseOverRemoveSlug] = useState('');

  const onSaveConsideredChoice = (description, slug) =>
    onSaveConsideredChoiceCallback(description, slug, newChoice, choices, prevConsideredChoices, setPrevConsideredChoices,
      setChoices, createConsideredChoice, updateChoice, setSubmitted, mouseOverRemoveSlug)

  return <ConsideredChoiceSection {...{choices, newChoice, onChangeChoice, setNewChoice, onSaveConsideredChoice,
                                       deleteConsideredChoice, addNewChoice, submitted, prevConsideredChoices, setMouseOverRemoveSlug }} />
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, {  createConsideredChoice, destroyChoice, updateChoice })(OtherConsideredChoicesInput);
