import React from 'react';
import {View, Text, Link} from '@react-pdf/renderer';
import styles from '../../styles/common'
import imageStyles from "../../styles/images";

export const DummyHeader = ({text, id = '', oneLineHeader = false}) =>
  <>
    <View id={id} style={[styles.header]}>
      <View style={[imageStyles.cover_white, {left: 0}]}>
        <Text style={[styles.h1, styles.body, oneLineHeader ? {height: 28} : {height: 54}, {marginTop: 30, zIndex: 20}]}>{text}</Text>
      </View>
    </View>
  </>

const DocumentHeader = ({ title = '', description = '', src_id = '' }) =>
  <View fixed style={[styles.header, {zIndex: 2}]}>
    <View style={[styles.row, styles.body, { height: 43, width: '100%', left: 0 }]}>
      <Link style={[styles.linkText, {marginTop: 28, textDecoration: 'none', maxWidth: '20%'}]} src={src_id}>{title}</Link>
      <Text style={[styles.pageNumber, {marginTop: 28, marginLeft: 'auto', maxWidth: '80%', overflow: 'hidden'}]}>{description}</Text>
    </View>
    <View style={[imageStyles.cover_light, { height: 2, marginHorizontal: 36 }]}/>
  </View>

export default DocumentHeader;
