import React from 'react';
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import {openModal} from "../../../store/modals/actions";

const RenderFollowupResults = ({ openModal}) =>
  <Button onClick={() => openModal({ type: 'FollowupResultsModal'})} className="btn btn-secondary w-100">
    Enter the results & actions taken
  </Button>

export default connect(null, {openModal})(RenderFollowupResults);
