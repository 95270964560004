import React from 'react';
import { SEMAPHORES } from "../../models/d_sight/insight";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from 'react-bootstrap/Tooltip';
import { isBlank } from "../../helpers/common";

const GeneralIcon = ({ icon, color, title, className = '' }) =>
  <OverlayTrigger placement='top' overlay={<Tooltip>{title}</Tooltip>}>
    <div className="d-inline-block">
      <i className={`fa-solid fa-circle-${icon} text-${color} fa-lg ${className}`} />
    </div>
  </OverlayTrigger>

const GreyIcon = ({ children }) =>
  <>
    <GeneralIcon icon="minus" color="muted" title="Not enough data" />
    {children}
  </>

const RedIcon = (props) =>
  <GeneralIcon icon="exclamation" color="danger" title="Needs attention" {...props} />

const YellowIcon = (props) =>
  <GeneralIcon icon="question" color="warning" title="Needs investigation" {...props} />

const GreenIcon = (props) =>
  <GeneralIcon icon="check" color="success" title="No issues" {...props} />

export const semaphoreData = (semaphore) => {
  switch (semaphore) {
    case SEMAPHORES.g_null: return { color: 'gray', text: 'Not enough data' }
    case SEMAPHORES.grey: return { color: 'gray', text: 'Not enough data' }
    case SEMAPHORES.red: return { color: 'danger', text: 'Needs improvements' }
    case SEMAPHORES.g_red: return { color: 'danger', text: 'Needs improvements' }
    case SEMAPHORES.yellow: return { color: 'warning', text: 'Needs investigation' }
    case SEMAPHORES.g_yellow: return { color: 'warning', text: 'Needs investigation' }
    case SEMAPHORES.green: return { color: 'success', text: 'No issues' }
    case SEMAPHORES.g_green: return { color: 'success', text: 'No issues' }
    case SEMAPHORES.placeholder: return { color: 'gray', text: 'Powered by D-Sight' }
  }

  return { color: null, text: '' };
}
export const SemaphoreIcon = ({ semaphore }) => {
  switch (semaphore) {
    case SEMAPHORES.g_null: return <GreyIcon />
    case SEMAPHORES.grey: return <GreyIcon />
    case SEMAPHORES.red: return <RedIcon />
    case SEMAPHORES.g_red: return <GreyIcon>
      <RedIcon className="ms-1" />
    </GreyIcon>
    case SEMAPHORES.yellow: return <YellowIcon />
    case SEMAPHORES.g_yellow: return <GreyIcon>
      <YellowIcon className="ms-1" />
    </GreyIcon>
    case SEMAPHORES.green: return <GreenIcon />
    case SEMAPHORES.g_green: return <GreyIcon>
      <GreenIcon className="ms-1" />
    </GreyIcon>
  }

  return null;
}

export const DSightBadge = ({ color, text, addClass = '' }) => {
  if (isBlank(color)) return null;

  return <span className={`badge rounded-pill semaphore-badge bg-${color} fw-bolder px-2 ${addClass}`}>
    {text}
  </span>;
}
export default DSightBadge;
