import React from 'react';
import { connect } from "react-redux";
import Image from "react-bootstrap/Image";
import EntryPoint from "../../EntryPoint";

const SignInHeader = ({}) => {
  return <header className="homepage-header decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center m-auto">
        <ul className="nav">
          <li className="nav-item logo-dropdown">
            <Image src={EntryPoint.instance.org_default_horizontal_logo} alt={EntryPoint.instance.org_default_alt_text}/>
          </li>
        </ul>
      </div>
    </nav>
  </header>;
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = (_) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SignInHeader);
