import {
  AlignmentType, BorderStyle,
  convertInchesToTwip,
  convertMillimetersToTwip, Footer,
  PageOrientation,
  Paragraph, TextRun, VerticalAlign
} from "docx";
import {DARK_GRAY, LIGHT_COLOR} from "../../pdf/styles/common";
import {isBlank} from "../../helpers/common";
import {logoImageRow} from "./sections/PageFooter";
import {baseRichBlock} from "./sections/wrappers";
import {DOCX_FONT, FONT_LG, PTS_36} from "../styles/docx_styles";
import {pageSize} from "../index";

const pageProperties = (format) => {
  return {
    properties: {
      page: {
        size: pageSize(format),
        margin: {
          top: convertInchesToTwip(230/72),
          bottom: 0,
          right: convertInchesToTwip(141/72),
          left: convertInchesToTwip(141/72),
          footer: convertInchesToTwip(18/72),
        }
      },
    }
  }
}

const titleDescription = (decision) =>
  new Paragraph({
    border: {
      top: {
        color: LIGHT_COLOR,
        space: 18,
        style: BorderStyle.SINGLE,
        size: 16,
      },
      bottom: {
        color: LIGHT_COLOR,
        space: 10,
        style: BorderStyle.SINGLE,
        size: 16,
      },
    },
    alignment: AlignmentType.CENTER,
    spacing: { line: 360 },
    children: [
      new TextRun({
        text: decision.description,
        font: DOCX_FONT,
        size: FONT_LG,
        bold: true,
        color: DARK_GRAY,
      })
    ]
  })

const logoImage = (extraImages) => {
  if (isBlank(extraImages.base64)) return {};

  return new Paragraph({
    alignment: AlignmentType.CENTER,
    spacing: { line: 276, before: PTS_36 },
    children: [
      logoImageRow(extraImages)
    ]
  })
}

export const CoverPage = (decision, format, extraImages) => {
  return {
    ...pageProperties(format),
    footers: {
      default: new Footer({
        properties: {
          verticalAlign: VerticalAlign.CENTER,
        },
        children: [
          ...baseRichBlock({text: 'Cloverpop decision memo', style: "mutedText", alignment: AlignmentType.CENTER})
        ]
      })
    },
    children: [
      titleDescription(decision),
      logoImage(extraImages),
    ]
  }
}
