import React from 'react';
import { ContributingKPISection } from "../analysis_insight/ContributingInsightContent";
import MetricChart from "../analysis_metrics/charts/MetricChart";
import { AnalysisRecordHeaderLink, RecordInsights, RelatedRecordBlock } from "../AnalysisContent";
import { isBlank } from "../../../helpers/common";
import { skipAnalysisRecord } from "../../../models/d_sight/Analysis";
import DemographicFilters, { availableDemographicsState } from "../analysis_metrics/DemographicFilters";
import { transformsMetricsBy } from "../analysis_metrics/helpers";

const ContributingMetricSection = ({ metric }) =>
  <div className={`w-100`}>
    <div className={'h-100 bg-white rounded p-3'}>
      <AnalysisRecordHeaderLink title={metric.name} link={metric.link} hideLink={false} />
      <RecordInsights insights={metric.orderedInsights(true)} />
    </div>
  </div>

const ContributingInsightKPIMetricBlock = ({ metric }) =>
  metric.visible &&
  <div className={'d-flex flex-column flex-md-row mb-3'}>
    <ContributingMetricSection metric={metric} addClass={''}/>
    <div className="mt-2 mt-md-0 ms-0 ms-md-3 sidepanel-minwidth" hidden={isBlank(metric.chartMetrics)}>
      <div className={'h-100 bg-white rounded p-3'}>
        <h3>Contributing metrics</h3>
        { metric.chartMetrics.map(con_metric =>
          <RelatedRecordBlock record={con_metric} key={`contributing-metric-${con_metric.id}`} />
        )}
      </div>
    </div>
  </div>

const ContributingInsightKPIMetricChartBlock = (opts) =>
  <div>
    <MetricChart {...opts} sourceLabels={true} />
  </div>

export default ({ kpi, kpiId, analysisObj, d_sight_goal }) => {
  const {
    availableDemographics, addAvailableOptions
  } = availableDemographicsState(kpiId)
  const chartMetrics = transformsMetricsBy(
    kpi.chartMetrics.filter(m => !skipAnalysisRecord(m)),
    analysisObj, d_sight_goal
  )

  return <div className={`container mt-3`}>
    <h1>KPI</h1>
    <div className={'mb-3'}>
      <ContributingKPISection {...{ kpi, hideLink: true, filterDemographic: true }} />
    </div>
    <h1>Contributing metrics</h1>
    <div>
      {
        chartMetrics.map(metric =>
          <ContributingInsightKPIMetricBlock metric={metric}
                                              key={`contributing-kpi-${kpi.id}-metric-${metric.id}`}/>
        )
      }
      {
        isBlank(chartMetrics) ? null : <DemographicFilters {...{availableDemographics}}/>
      }
      {
        chartMetrics.map(metric =>
          <ContributingInsightKPIMetricChartBlock key={`contributing-kpi-${kpi.id}-metric-${metric.id}-charts`}
                                                  {...{ metric, addAvailableOptions, availableDemographics, lever: analysisObj }} />
        )
      }
    </div>
  </div>
}
