import React, { Fragment, useEffect } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadTemplate } from "../store/template/actions";
import { updateTemplateData } from "../store/template/common_actions";
import AlertsSection from "../alerts";
import TemplateViewHeader from "./header";
import TemplateDetailsPanel from "./side_panel/TemplateDetailsPanel";
import DataSourcesSection from "./side_panel/sections/DataSourcesSection";
import TemplateUsersModal from "./modals/TemplateUsersModal";
import { loadOrgCategories } from "../store/org_categories/actions";
import { loadOrgDriverTypes } from "../store/org_driver_types/actions";
import { Loader } from "../common/Loader";
import { checkLoadedTemplateInfo } from "../template_preview";
import { isAdmin } from "../helpers/home_helpers";
import { closeModal } from "../store/modals/actions";
import {
  setDecisionSidebarOpen, setPlaybookSidebarOpen, setTreeSidebarOpen,
  setCollaboratorsSidebarOpen, setDataSourcesSidebarOpen, setMoreActionsSidebarOpen
} from "../store/sidebar/actions";
import { getWindowDimensions, LARGE_SCREEN_WIDTH } from "../store";
import HelpPanel from "./side_panel/HelpPanel";
import TemplateTreePanel from "../template_preview/side_panel/TemplateTreePanel";
import TemplatePlaybookPanel from "./side_panel/TemplatePlaybookPanel";
import { isBlank, isPresent } from "../helpers/common";
import { isDSightTemplate } from "../helpers/template_helpers";
import SidebarTreeNavigationTemplate from "./navigation";
import { loadTemplateSet } from "../store/template_set/actions";
import DecisionOrderPanel from "../template_set_view/side_panel/DecisionOrderPanel";
import { checkLoadingEffect, isStoreLoading } from "../helpers/callbacks_helpers";
import LinkPanel from "../tree_view/side_panel/link_panel/LinkPanel";
import RatingsAndWeightsPanel from "../tree_view/side_panel/RatingsAndWeightsPanel";
import TemplateTree from "../tree_view/accordion_tree/TemplateTree";
import TemplatePollNotesModal from "./modals/TemplatePollNotesModal";
import { TREE_MODES } from "../helpers/drivers_helpers";
import CollaboratorsPanel from "../tree_view/side_panel/CollaboratorsPanel";
import CollaboratorsSection from "./side_panel/sections/CollaboratorsSection";
import { loadContacts } from "../store/contacts/actions";
import DataSourcesPanel from "../tree_view/side_panel/DataSourcesPanel";
import MoreActionsPanel from "../tree_view/side_panel/MoreActionsPanel";
import MoreActionsSection from "./side_panel/sections/MoreActionsSection";

export const showTemplateEntry = (modal) => modal.type === "TemplateUsersModal" && modal.shown;
export const showTemplatePollModal = (modal) => modal.type === "TemplatePollNotesModal" && modal.shown;

export const Sidebars = ({ isDSight = false, showDecisionSet = false }) =>
  <Fragment>
    <div className="sidebars">
      <RatingsAndWeightsPanel isTemplate={true} />
      <TemplateDetailsPanel key="decision-details-side-panel" isDSight={isDSight} />
      {
        !isDSight && <CollaboratorsPanel key="template-collaborators-side-panel" isTemplate={true}>
          <CollaboratorsSection classNames="px-3 pb-3"/>
        </CollaboratorsPanel>
      }
      <DataSourcesPanel>
        <DataSourcesSection addClass="px-3 pb-3" />
      </DataSourcesPanel>
      { 
        !isDSight && <TemplateTreePanel key="template-tree-panel" preview={false} />
      }
      <TemplatePlaybookPanel preview={false} addClass={'left-side-panel-position tree-page'} />
      {
        showDecisionSet ?
          <DecisionOrderPanel addClass="left-side-panel-position tree-page" isTemplate={true} preview={false} /> :
          null
      }
      <LinkPanel key="link-side-panel" />
      <MoreActionsPanel>
        <MoreActionsSection {...{isDSight}} />
      </MoreActionsPanel>
      <div className="help-side-panels">
        <HelpPanel />
      </div>
    </div>
  </Fragment>

export const Wrapper = ({children, modal, closeModal, ...opts}) =>
  <Fragment>
    <AlertsSection />
    <TemplateViewHeader {...opts} />
    <div className="d-flex vertical-tree-container">
      <SidebarTreeNavigationTemplate {...opts}  />
      {children}
    </div>
    <Sidebars {...opts} />
    <div className="modals">
      <TemplateUsersModal shown={showTemplateEntry(modal)} onClose={closeModal} />
      <TemplatePollNotesModal shown={showTemplatePollModal(modal)} onClose={closeModal} />
    </div>
  </Fragment>

export const loadTemplateCallbacks = ({
                                        org_categories, org_driver_types, match,
                                        updateTemplateData, loadOrgCategories, loadOrgDriverTypes
                                     }) => {
  useEffect(() => { checkLoadedTemplateInfo(match, updateTemplateData) }, [match?.params?.id])
  checkLoadingEffect(org_categories, loadOrgCategories)
  checkLoadingEffect(org_driver_types, loadOrgDriverTypes);
}

export const isTemplateLoaded = (template, match) =>
  template.loaded && !template.loading && template.slug === match?.params?.id

export const templateViewSidebarCallbacks = ({
                                               checkDetailsPanel = true,
                                               template, setTreeSidebarOpen, loadTemplate, openDecisionDetailsSideBar,
                                               setPlaybookSidebarOpen, openCollaboratorsSideBar, openDataSourcesSideBar,
                                               openMoreActionsSideBar
                                            }) => {
  useEffect(() => {
    const { width } = getWindowDimensions();
    const getParams = new URLSearchParams(document.location.search)
    const side_bar_open = getParams.get('side_bar_open')
    if (!template.loaded) {
      loadTemplate(success => {
        if (!success) return;
        switch (side_bar_open) {
          case 'details':
            if (checkDetailsPanel) openDecisionDetailsSideBar();
            break;
          case 'collaborators':
            if (checkDetailsPanel) openCollaboratorsSideBar();
            break;
          case 'data_sources':
            if (checkDetailsPanel) openDataSourcesSideBar();
            break;
          case 'decision_tree':
            if (checkDetailsPanel) setTreeSidebarOpen();
            break;
          case 'playbook':
            if (checkDetailsPanel) setPlaybookSidebarOpen();
            break;
          case 'more_actions':
            if (checkDetailsPanel) openMoreActionsSideBar();
            break;
        }
      })
    }
  }, [template.loaded])
}
export const templateViewAdminPermissionsCallback = ({ current_user }) => {
  useEffect(() => { if (current_user.loaded && !isAdmin(current_user)) document.location.href = '/'; }, [current_user.loaded])
}

export const TemplateView = ({
                               template, template_set, modal, match, closeModal, loadTemplateSet,
                               ...opts
                            }) => {
  loadTemplateCallbacks({ match, ...opts })
  templateViewSidebarCallbacks({ template, ...opts, checkDetailsPanel: true })
  templateViewAdminPermissionsCallback({ ...opts })

  if (isTemplateLoaded(template, match)) {
    if (isDSightTemplate(template)) {
      const history = useHistory();
      history.push(`/dsight/templates/${template.slug}`);
    }
  }
  checkLoadingEffect(template_set, () => loadTemplateSet({
    scope: 'template_sets', setSlug: template.template_set_slug
  }), {
    condition: () => template.loaded && isPresent(template.template_set_slug),
    another_stores: [template]
  })

  if (isStoreLoading(template)) {
    return <Wrapper {...{ modal, closeModal}}>
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper {...{ modal, closeModal, template}}
                    isTemplateLoaded={true}
                    showDecisionSet={isPresent(template.template_set_slug)}>
      <TemplateTree />
    </Wrapper>;
  }
}
export const mapStateToProps = ({ template, org_categories, current_user, modal, org_driver_types, template_set }) => ({
  template, org_categories, current_user, modal, org_driver_types, template_set
});
export const mapDispatchToProps = (dispatch) => ({
  loadTemplate: (callback) => {
    dispatch(loadTemplate({ edit_template: true }, callback))
  },
  loadTemplateSet: (hash) => dispatch(loadTemplateSet(hash)),
  updateTemplateData: (data) => {
    dispatch(updateTemplateData(data))
  },
  loadOrgCategories: () => {
    dispatch(loadOrgCategories());
  },
  openDecisionDetailsSideBar: () => {
    dispatch(setDecisionSidebarOpen(true));
  },
  openCollaboratorsSideBar: () => {
    dispatch(setCollaboratorsSidebarOpen(true));
  },
  openDataSourcesSideBar: () => {
    dispatch(setDataSourcesSidebarOpen(true));
  },
  openMoreActionsSideBar: () => {
    dispatch(setMoreActionsSidebarOpen(true));
  },
  setPlaybookSidebarOpen: () => {
    dispatch(setPlaybookSidebarOpen(true));
  },
  closeModal: () => {
    dispatch(closeModal())
  },
  loadOrgDriverTypes: () => {
    dispatch(loadOrgDriverTypes())
  },
  setTreeSidebarOpen: () => {
    dispatch(setTreeSidebarOpen(true, TREE_MODES.edit));
  },
  loadContacts: () => {
    dispatch(loadContacts())
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateView);
