import React, { Fragment } from 'react';
import { connect } from "react-redux";
import EntryPoint from "../../EntryPoint";
import HelpButtons, { CUSTOMER_CHAT } from "../../common/HelpButtons";

export const Index = () => {
  const { objectSlug, controllerName } = EntryPoint.instance;

  return <Fragment>
    <div className="px-3 mb-3">
      <h3>Manage profile</h3>
      <p>
        Use this page to update your profile picture, name, password, and notification preferences.
      </p>
      <p>
        Your email address is not editable for security reasons. If you need to update your email address, please <a href={CUSTOMER_CHAT} target="_blank">contact customer success</a>.
      </p>
    </div>
    <HelpButtons />
  </Fragment>;
}
const mapStateToProps = (state) => ({
});
export default connect(mapStateToProps)(Index);
