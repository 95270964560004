import React from 'react';
import DetailsItem from "../../tree_view/navigation/DetailsItem";
import HelpTemplateItem from "../../template_view/navigation/HelpTemplateItem";
import PlaybookWizardItem from "./PlaybookWizardItem";
import RatingsAndWeightsItem from "../../tree_view/navigation/RatingsAndWeightsItem";
import { TREE_MODES } from "../../helpers/drivers_helpers";
import DecisionTreeItem from "../../tree_view/navigation/DecisionTreeItem";
import CollaboratorsItem from "../../tree_view/navigation/CollaboratorsItem";
import DataSourcesItem from "../../tree_view/navigation/DataSourcesItem";

const NavigationItems = ({wizard}) => {
  const collaboratorsInvitesLength = wizard && wizard.flow_steps[0].collaborators.length + 1 || 0;

  return <>
    <div className="sidebar-top">
      <ul className="list-unstyled m-0 p-3">
        <RatingsAndWeightsItem />
        <DetailsItem />
        <CollaboratorsItem {...{collaboratorsInvitesLength}}/>
        <DataSourcesItem/>
        <DecisionTreeItem treeMode={TREE_MODES.edit} isWizard={true}/>
        <PlaybookWizardItem />
      </ul>
    </div>
  </>
}

export const SidebarTreeNavigationWizard = ({...opts}) =>
  <div id="sidebar-navigation" className="tree-sidebar bg-light-gray d-none d-lg-block">
    <NavigationItems {...opts}/>
  </div>

export default SidebarTreeNavigationWizard;
