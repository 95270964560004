import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import {isBlank, isEmail, isPresent, uniqArray, uniqueBy} from "../../../helpers/common";
import {
  isGuestContact,
  isPendingContact
} from "../../../helpers/decision_helpers";
import { loadContacts } from "../../../store/contacts/actions";
import {InputCollaboratorsField} from "../../../tree_view/side_panel/sections/CollaboratorsSection";
import {CollaboratorsInviteRow} from "../../../template_view/side_panel/sections/CollaboratorsInviteRow";
import TemplateSet from "../../../models/template_set";
import {addTemplateSetCollaborators, removeTemplateSetCollaborator} from "../../../store/template_set/actions";
import {submitCollaboratorInvite} from "../../../template_view/side_panel/sections/CollaboratorsSection";
import {filteredContactsCallback} from "../../../decision_set_view/side_panel/sections/InviteInput";
import NAElement from "../../../tree_view/side_panel/sections/NAElement";
import {Loader} from "../../../common/Loader";

export const filterNewCollaboratorInvites = (newInvites, contactsData, obj) =>
  uniqArray(
    newInvites.filter(email => isEmail(email))
      .filter(email => !isPendingContact(contactsData.contacts.find(c => c.value === email)) )
      .filter(email => !isGuestContact(contactsData.contacts.find(c => c.value === email)) )
      .filter(email => contactsData.contacts.find(c => c.value === email) )
      .filter(email => !obj.collaborator_admissions.some(i => i.user_email === email))
  )

const fakeAdmissionsItem = (index, template, user_email) => {
  return {
    admittable_slug: `${template.slug}-${index}`,
    admittable_type: "OrgUser",
    share_type: "collaborator",
    user: template.users.find(user => user.email === template.assigned_collaborator_email),
    user_email: template.assigned_collaborator_email
  }
}

const buildAdmissionsCallback = (preview, template_set, admissions, setAdmissions, templateSetObj) => {
  useEffect(() => {
    if (!preview || !template_set.loaded) return setAdmissions(templateSetObj.collaborator_admissions);

    const fakeAdmissions = template_set.templates.map((template, i) => {
      isPresent(template.assigned_collaborator_email) && fakeAdmissionsItem(i, template)
    }).filter(a => isPresent(a))
    setAdmissions(uniqueBy([...fakeAdmissions, ...admissions], 'user_email'))
  }, [template_set.admissions])
}

export const CollaboratorsSection = ({
                                       template_set, contactsData, removeCollaborator,
                                       loadContacts, addTemplateSetCollaborators, current_user,
                                       preview = false
                                     }) => {
  if (!template_set.loaded) return <Loader />

  useEffect(() => {
    if (!contactsData.loaded || (contactsData.loaded && !contactsData.loading && !contactsData.invite_yourself)) {
      setTimeout(loadContacts(() => {}, { invite_yourself: true }), 50)
    }
  }, [contactsData.loaded, contactsData.invite_yourself])

  const [invites, setInvites] = useState([])
  const [submit, setSubmit] = useState(false)
  const templateSetObj = new TemplateSet(template_set)
  const [admissions, setAdmissions] = useState(templateSetObj.collaborator_admissions || [])

  buildAdmissionsCallback(preview, template_set, admissions, setAdmissions, templateSetObj)

  const submitInvite = () => submitCollaboratorInvite(setSubmit, invites, setInvites, addTemplateSetCollaborators)
  const filteredContacts = filteredContactsCallback({
    contactsData, current_user, invites,
    anotherCondition: (contact) => !admissions.some(admission => admission.user_email === contact.value),
    excludeCurrentUser: false
  })
  const filterNewInvites = (newInvites) => filterNewCollaboratorInvites(newInvites, contactsData, templateSetObj)

  if (preview && isBlank(admissions)) {
    return <div className="px-3 pb-3">
      <NAElement />
    </div>
  }

  return <div className="px-3 pb-3">
    {
      admissions.map(admission =>
        <CollaboratorsInviteRow user={admission.user}
                                key={`collaborator-row-${admission.admittable_slug}`}
                                slug={admission.slug}
                                {...{preview, removeCollaborator}} />)
    }
    {
      !preview && <InputCollaboratorsField {...{
        submit, filteredContacts, contactsData,
        invites, setInvites, filterNewInvites,
        submitInvite, isTemplate: true, isBlankAdmissions: admissions.length === 0
      }} />
    }
  </div>
}
const mapStateToProps = ({ template_set, contacts, current_user }) => ({
  template_set, contactsData: contacts, current_user
});
const mapDispatchToProps = (dispatch) => ({
  loadContacts: (callback, data) => dispatch(loadContacts(callback, data)),
  addTemplateSetCollaborators: (data, callback) => {
    dispatch(addTemplateSetCollaborators(data, callback));
  },
  removeCollaborator: (data, callback) => {
    dispatch(removeTemplateSetCollaborator(data, callback));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorsSection);
