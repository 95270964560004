import React, { useState } from 'react';
import { connect } from "react-redux";
import Switcher from "../../../common/Switcher";
import { updateRecordDecision } from "../../../store/sidebar/actions";
import { isBlank } from "../../../helpers/common";
import {saveSideBarData} from "../../../store/sidebar/common_actions";

const RateSupportSwitcher = ({ decision, updateRecordDecision, saveSideBarData }) => {
  if (isBlank(decision)) return null;

  const [rateSupport, setRateSupport] = useState(decision.rate_support || false);

  const onChangeRateSupport = (checked) => {
    setRateSupport(checked)
    updateRecordDecision({ rate_support_on_slack: checked });
  };

  return <div className="mb-3 px-3">
    <Switcher label={'Allow collaborators to rate their level of support for what was decided.'}
              value={rateSupport} onChange={onChangeRateSupport} />
  </div>
}
const mapStateToProps = (state) => ({ decision: state.sidebar.decision });
export default connect(mapStateToProps, { updateRecordDecision, saveSideBarData })(RateSupportSwitcher);
