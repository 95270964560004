import { isBlank } from "./common";
import { DEFAULT_SORT_ORDER } from "../store/homepage/reducers";

export const DEFAULT_STATE = 'all';
export const STATE_FILTER_SEPARATOR = ',';
export const OWNERS_FILTER_SEPARATOR = ';';

export const IN_FLIGHT_STATUS = 'In-flight';
export const DECIDED_STATUS = 'Completed';
export const RESULTS_STATUS = 'Results';
export const ALL_STATUS = 'All';

export const CATEGORY_TO_STATUS = {
  'created': IN_FLIGHT_STATUS,
  'recorded': DECIDED_STATUS,
  'results': RESULTS_STATUS,
  'all': ALL_STATUS
};

export const isDefaultFilters = (home) => isBlank(home.owners) && isBlank(home.shared_with_admissions) && home.state === DEFAULT_STATE && isBlank(home.category_options) && isBlank(home.d_sight_only);
export const isMoreFilters = (home) => isBlank(home.owners) && isBlank(home.shared_with_admissions) && home.state === DEFAULT_STATE && isBlank(home.d_sight_only);
export const isDefaultCategoriesFilters = (home) => isBlank(home.category_options);
export const isDefaultTemplateFilters = (home) => isBlank(home.category_options) && !home.d_sight_only && isBlank(home.template_users);
export const isDefaultReportFilters = (home) => isBlank(home.property_options) && isBlank(home.report_property_options);
export const isDefaultSortOrder = (home) => home.sort_order === DEFAULT_SORT_ORDER;

export const selectedCategoryOptionsByCategory = (category, home) => {
  return category.options.filter((option) => home.category_options.includes(option.id)).map((option) => option.description)
};

export const selectedPropertyOptionsByProperty = (property, home) => {
  return property.options.filter((option) => home.property_options.includes(option.id)).map((option) => option.description)
};

export const selectedOpenPropertyOptionsByProperty = (property, home) => {
  return (property.options[0]?.report_property_options || []).filter((option) => home.report_property_options.includes(option.id))
    .map((option) => option.description)
};

export const allContacts = (contactsData, user, allAsOrg = null) => {
  user.value = user.email;
  return [
    ...contactsData.contacts.map(c =>
      allAsOrg && c.type === 'All' ? { ...c, full_name: `All ${allAsOrg.name}`, slug: allAsOrg.slug, type: 'Org' } : c
    ), user
  ]
};

export const resetAllFilters = (setSearchQuery, setLoading, action) => {
  setLoading(true);
  setSearchQuery('');
  action({ d_sight_only: false, category_options: [], template_users: [], search: '' })
};

export const setFilterAutofocus = (setOpenedSection, section) =>
  setTimeout(() => setOpenedSection(section), 400)
