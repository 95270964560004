import React from 'react';
import { connect } from "react-redux";
import { openStartDecisionModal } from "../../modals/StartDecisionModal";

export const StartFromScratchBlock = ({ showNullResults, ...opts }) =>
  showNullResults ? null : <StartFromScratchTile {...opts} />

const StartFromScratchTile = ({ title, onClick, iconClass = 'fas fa-plus', addClass = '' }) =>
  <div className="col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <div className="text-dark text-center pointer" onClick={onClick}>
      <div className={`tile-content bg-white px-2 ${addClass}`}>
        <div className="btn btn-primary btn-sm btn-sm-round d-inline-flex justify-content-center align-items-center mb-2">
          <i className={`${iconClass} h4 m-0`} />
        </div>
        <div className="h3 my-0">{title}</div>
      </div>
    </div>
  </div>

const StartFromScratch = ({
                            decision_set, current_org,
                            closeModal, startDecisionSet, openModal,
                            isDecisionSet = false, ...opts
                          }) =>
  <>
    <StartFromScratchBlock title="Create a decision"  addClass="py-5"
                           onClick={() => openStartDecisionModal({ openModal })} {...opts} />
    {
      (isDecisionSet || !current_org.allow_decision_sets) ? null :
        <StartFromScratchBlock title="Create a decision flow" iconClass="fas fa-list-check" addClass="py-5"
                               onClick={() => startDecisionSet({})} {...opts} />
    }
  </>
const mapStateToProps = ({ home, org_categories, decision_set, current_org }) => ({ home, org_categories, decision_set, current_org });
export default connect(mapStateToProps)(StartFromScratch);
