import React from 'react';
import LineChart from "../LineChart";
import { isAttributesMetric, isSegmentationSupported } from "../../../../../models/d_sight/metric_chart_helpers";
import { chartDataState, LoaderBlock } from "../helpers/chart_data_helpers";
import TableTypeCharts from "../TableTypeCharts";
import { AnalysisMetric } from "../../../../../models/d_sight/Analysis";
import { isBlank, isPresent } from "../../../../../helpers/common";
import { AttributeSegment } from "../../../../../models/d_sight/AttributeSegment";

const EmotionalAttributesTable = ({ parentLoading, metric, masterMetric, d_sight_goal, ...opts }) => {
  const {
    chartData, loading
  } = chartDataState({ ...opts, d_sight_goal, metric, parentLoading })

  if (parentLoading || loading) return <LoaderBlock />

  return <TableTypeCharts {...{
    ...opts,
    metric, chartData, masterMetric, d_sight_goal,
    header: (masterMetric || metric).name,
    filterAttributes: (dc) => {
      const attribute_segment = d_sight_goal.attribute_segments.find(s => s.id === dc.attribute_segment)
      if (isBlank(attribute_segment)) return false;
      return (new AttributeSegment(attribute_segment)).isEmotionalConnection;
    }
  }} />
}


export default ({ chartData, metrics, leverMetrics = null, metric, d_sight_goal, ...opts }) => {
  const metricsRecords = (leverMetrics?.map(h => h.data) || metrics.metrics).map(hash => new AnalysisMetric(hash))
  const attributesMetric = metricsRecords.find(m => isAttributesMetric(m))

  return <>
    <LineChart {...{ ...opts, d_sight_goal, chartData, metric }} />
    {
      attributesMetric && isSegmentationSupported(d_sight_goal) &&
      <EmotionalAttributesTable {...{ ...opts, d_sight_goal, metric: attributesMetric, metrics, masterMetric: metric }} />
    }
  </>
}
