import React from 'react';
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import { capitalize, isBlank, isPresent } from "../../../../helpers/common";
import { formatPercentage } from "../../../../homepage/tabs/learning/AllDecisionsStatisticsPanel";
import { getLastSurvey, SigDown, SigUp } from "../../../../models/d_sight/metric_chart_helpers";
import { getNodeName } from "../../../../helpers/metric_helpers";

const getMetricSourceText = (d_sight_goal, surveys) => {
  const result = [];
  if (isPresent(d_sight_goal.survey_vendor)) result.push(`${d_sight_goal.survey_vendor.name} -`)
  if (isPresent(d_sight_goal.survey_type)) result.push(`${d_sight_goal.survey_type.name} Report.`)
  if (isPresent(surveys)) {
    if (surveys.length === 1) {
      result.push(surveys[0].full_name)
    } else {
      const firstSurvey = surveys[0]
      const lastSurvey = getLastSurvey(surveys)
      result.push(`${firstSurvey.full_name} - ${lastSurvey.full_name}`)
    }
  }
  return result.join(' ');
}

const QuestionRow = ({question}) => {
  if (isBlank(question)) return null;

  return <div>
    <span className="fw-bold">Question</span>: {capitalize(question)}
  </div>
}
const QuestionsRows = ({ chartData, hideQuestion = false }) => {
  if (hideQuestion || isBlank(chartData.question)) return null;

  return chartData.question.split(';').map((question, index) =>
    <QuestionRow question={question.trim()} key={`question-${index}`}/>
  )
}

const SourceRow = ({d_sight_goal, surveys}) => {
  if (isBlank(d_sight_goal.survey_type)) return null;

  return <div className="text-muted">
    <span className="fw-bold">Source</span>: {getMetricSourceText(d_sight_goal, surveys)}
  </div>
}

const SourceNotesRow = ({d_sight_goal}) => {
  if (isBlank(d_sight_goal.survey_notes)) return null;

  return <div>
    <i>{d_sight_goal.survey_notes}</i>
  </div>
}

const SignificanceLegend = ({ d_sight_goal, hideSignificanceLegend = false, legendHint = null }) => {
  if (hideSignificanceLegend) return null;

  const mainBrandName = getNodeName(d_sight_goal, parseInt(d_sight_goal.target.name))
  const confidenceLabel = isPresent(d_sight_goal.confidence_interval) ? ` at ${formatPercentage(d_sight_goal.confidence_interval)} confidence` : ''
  return <div className="text-muted">
    {ReactHtmlParser(SigUp)} / {ReactHtmlParser(SigDown)} Significantly higher/lower vs {legendHint || mainBrandName}{confidenceLabel}
  </div>
}

const ChartSourcesLabels = ({ children, isFunnel = false, ...opts }) =>
  <div className={`w-100 text-center ${isFunnel ? '' : 'mt-2'}`}>
    <QuestionsRows {...opts} />
    <SourceRow {...opts} />
    <SourceNotesRow {...opts} />
    <SignificanceLegend {...opts} />
    { children }
  </div>

export const mapStateToProps = ({ d_sight_goal }) => ({ d_sight_goal });
export default connect(mapStateToProps)(ChartSourcesLabels);
