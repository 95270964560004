import React, {forwardRef} from 'react';
import Form from "react-bootstrap/Form";
import { flow } from "../../helpers/decision_flows";

const AVAILABLE_FLOWS = [
  { label: 'Start a decision tree', className: 'mb-2', value: flow.TREE_RECOMMENDATION, name: 'flow' },
  { label: 'Record a decision', className: 'mb-0', value: flow.TREE_HISTORICAL, name: 'flow' }
]

export const CheckForm = forwardRef(({
                                       type = 'radio', label = '', onClick = () => {},
                                       name = 'group', className = '',
                                       checked = false, disabled = false,
                                       labelExtra = '', extraClassName, id=''
                                     }, ref) => {
  const generatedId = id || `${type}-${label}`;
  return (
    <Form.Check
      id={generatedId}
      ref={ref}
      className={`d-flex align-items-start ${className}`}
      disabled={disabled}
      checked={checked}
      type={type}
      name={name}
      label={
        <>
          <span>{label}</span>
          <span className={extraClassName}>{labelExtra}</span>
        </>
      }
      onChange={() => {}}
      onClick={onClick}
    />
  )
});

export default ({ flowEnum, setFlowEnum, availableFlows = AVAILABLE_FLOWS, className = 'w-100' }) =>
  <div className={className}>
    <div className="row">
      <h3>What do you want to do?</h3>
      <Form>
        {
          availableFlows.map(({ value, label, ...hash }) =>
            <CheckForm {...hash} key={`check-form-${value}`} id={`${value}-${label}`} label={label}
                       checked={flowEnum === value} onClick={() => setFlowEnum(value)}/>
          )
        }
      </Form>
    </div>
  </div>
