import React from "react";
import { isBlank, isPresent } from "../../helpers/common";
import {analyzeAndReplaceLinks, paragraphTag} from "./common";
import { PRIMARY_COLOR, BORDER_COLOR } from "../styles/common";

const MAX_INDENT_LEVEL = 9
const BASE_INDENT_SIZE = 20
const JODIT_INDENT_SIZE = 10
const LOWER_ALPHA_CLASSES = ['ql-indent-1', 'ql-indent-4', 'ql-indent-7'];
const LOWER_ROMAN_CLASSES = ['ql-indent-2', 'ql-indent-5', 'ql-indent-8'];
const DECIMAL_CLASSES = ['ql-indent-3', 'ql-indent-6', 'ql-indent-9'];
const ROMAN_MATCHES = {
  m: 1000, cm: 900, d: 500, cd: 400,
  c: 100, xc: 90, l: 50, xl: 40,
  x: 10, ix: 9, v: 5, iv: 4, i: 1
};
const APPROVED_NODE_NAMES_LIST = ['EM', 'STRONG', 'U', 'S', 'TABLE', 'A', 'BR'];

export function modifyRichText(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(paragraphTag(html), 'text/html');
  const orderedLists = doc.querySelectorAll('ol');

  if(isBlank(orderedLists)) return doc.body.innerHTML

  orderedLists.forEach((ol) => {
    const listItems = ol.querySelectorAll('li');
    let letter = 'a';
    let indexRoman = 1;
    let indexDecimal = 1;

    listItems.forEach((li) => {
      const klass = li.classList.value;
      if (LOWER_ALPHA_CLASSES.includes(klass)) {
        indexRoman = 1;
        indexDecimal = 1;
        li.replaceWith(createListItem(letter, li.textContent, klass));
        letter = getNextLetter(letter);
      } else if (LOWER_ROMAN_CLASSES.includes(klass)) {
        letter = 'a';
        indexDecimal = 1;
        li.replaceWith(createListItem(romanize(indexRoman), li.textContent, klass));
        indexRoman += 1;
      } else if (DECIMAL_CLASSES.includes(klass)) {
        letter = 'a';
        indexRoman = 1;
        li.replaceWith(createListItem(indexDecimal, li.textContent, klass));
        indexDecimal += 1;
      } else {
        letter = 'a';
        indexRoman = 1;
        indexDecimal = 1;
      }
    });
  });

  return doc.body.innerHTML;
}

// check the a tag in first child element
const isApprovedElementInChildNodes = (element) => {
  return isPresent(element.childNodes) && APPROVED_NODE_NAMES_LIST.includes(element.childNodes[0]?.nodeName)
}

export function modifyJoditText(html, forDoc = false) {
  const parseHtmlToDesiredFormat = ({htmlString, isUl = false, number = 1, indent = 1, isTbody = false, isTr = false, inTD = false}) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    const isInTable = isTbody || isTr;
    const tbody = isTbody && doc.querySelector('tbody');
    const tr = isTr && doc.querySelector('tr');
    const elements = Array.from(isInTable ? tbody?.childNodes || tr?.childNodes : doc.body.childNodes);
    let result = '';

    elements.forEach(element => {
      if (element.nodeName === 'A') {
        result += element.outerHTML;
      } else if (element.nodeName === 'TABLE' && !isInTable) {
        const tableOuterHtml = `<table class="table-bordered" style="${element.getAttribute('style')}" >
          <tbody>
            ${parseHtmlToDesiredFormat({htmlString: element.outerHTML, number: number, isTbody: true, inTD: inTD})}
          </tbody>
        </table>`;

        result += tableOuterHtml
      } else if (element.nodeName === 'TR' && isTbody) {
        const tableOuterHtml = `<tr style="${element.getAttribute('style')}">
          ${parseHtmlToDesiredFormat({htmlString: `<table>${element.outerHTML}</table>`, number: number, isTr: true, inTD: inTD})}
        </tr>`;

        result += tableOuterHtml
      } else if (element.nodeName === 'TD' && isTr) {
        const tableOuterHtml = `<td style="${element.getAttribute('style')}">
          ${element.innerHTML === '<br>' ? "<p>\n</p>" : parseHtmlToDesiredFormat({htmlString: element.innerHTML, number: number, inTD: true})}
        </td>`;

        result += tableOuterHtml;
      } else if (APPROVED_NODE_NAMES_LIST.includes(element.nodeName) && isPresent(element.childNodes)) {
        result += `<${element.nodeName}>${parseHtmlToDesiredFormat({htmlString: element.innerHTML, number: number, inTD: inTD})}</${element.nodeName}>`;
      } else if (APPROVED_NODE_NAMES_LIST.includes(element.nodeName)) {
        result += element.outerHTML;
      } else if (element.nodeName === 'P' && isApprovedElementInChildNodes(element)) {
        result += `<p>${element.innerHTML}</p>`;
      } else if (element.nodeName === 'P') {
        const marginLeft = element.getAttribute('style') || '';
        const marginLeftValue = marginLeft.match(/margin-left:\s*(\d+)px/);
        const showMarginLeft = isPresent(marginLeftValue) && parseInt(marginLeftValue[1]) !== 0;
        const pTagIndent = showMarginLeft ? parseInt(marginLeftValue[1])/10 : 0;

        result += `<p class="ms-indent-${pTagIndent}">${analyzeAndReplaceLinks(element.innerHTML)}</p>`;
      } else if (element.nodeName === 'OL') {
        result += parseHtmlToDesiredFormat({htmlString: element.innerHTML, number: number, indent: 1, inTD: inTD});
      } else if (element.nodeName === 'UL') {
        result += parseHtmlToDesiredFormat({htmlString: element.innerHTML, isUl: true, number: number, indent: 1, inTD: inTD});
      } else if (element.nodeName === 'LI') {
        const textContent = isApprovedElementInChildNodes(element) ?
          element.innerHTML.split(/(<ol[^>]*>|<ul[^>]*>|<table[^>]*>)/i)[0] :
          element.innerHTML.replace(/<[^>]*>/, '').split('<')[0];
        const numbering = isUl ? '•' : `${number}.`;

        result += `<p class="ms-indent-${indent}">${numbering} ${textContent}</p>`;
        number = number + 1;

        if (element.childNodes) {
          element.childNodes.forEach(childElement => {
            // Handle nested <ol> tags
            if (childElement.nodeName === 'OL' ) {
              result += parseHtmlToDesiredFormat({htmlString: childElement.innerHTML, isUl: false, number: 1, indent: indent+1, inTD: inTD});
            }
            // Handle nested <ul> tags
            if (childElement.nodeName === 'UL' ) {
              result += parseHtmlToDesiredFormat({htmlString: childElement.innerHTML, isUl: true, number: 1, indent: indent+1, inTD: inTD});
            }
            // Handle nested <Table> tags
            if (childElement.nodeName === 'TABLE' || childElement.nodeName === 'P' ) {
              result += parseHtmlToDesiredFormat({htmlString: childElement.outerHTML, number: 1, indent: indent+1, inTD: inTD});
            }
          })
        }
      } else if (element.nodeName === 'BR') {
        result += '<br>';
      } else if ( forDoc || inTD){
        result += element.innerHTML || element.textContent || ''
      } else {
        result += `<p>${element.innerHTML || element.textContent || ''}</p>`;
      }
    });

    return result;
  }

  return parseHtmlToDesiredFormat({htmlString: forDoc ? html.replace(/<p[^>]*><br><\/p>/g, "<p>\n</p>") : html})
}

function createListItem(index, content, klass) {
  const p = document.createElement('p');
  p.textContent = `${index}. ${content}`;
  p.classList.add(klass);
  return p;
}

function getNextLetter(letter) {
  return String.fromCharCode(letter.charCodeAt(0) + 1);
}

const romanize = (num) => {
  let lookup = ROMAN_MATCHES, roman = '', i;
  for ( i in lookup ) {
    while ( num >= lookup[i] ) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
}

export const quillIndentStyles = () => {
  const styles = {
    p: {
      margin: 0,
    },
    ol: {
      margin: 0
    },
    ul: {
      margin: 0
    },
    li: {
      margin: 0
    },
    a: {
      color: PRIMARY_COLOR,
      fontSize: 10.5,
      textDecoration: 'none'
    },
    table: {
      display: "table",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: BORDER_COLOR,
      height: 'auto'
    },
    td: {
      minHeight: 20,
      height: 'auto',
      overflow: 'hidden',
      padding: 2
    }
  };

  for (let i = 1; i <= MAX_INDENT_LEVEL; i++) {
    styles[`.ql-indent-${i}`] = {
      paddingLeft: BASE_INDENT_SIZE * (i + 1)
    };
  }

  for (let i = 1; i <= 20; i++) {
    styles[`.ms-indent-${i}`] = {
      paddingLeft: JODIT_INDENT_SIZE * (i + 1)
    };
  }

  return styles;
};
