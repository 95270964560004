import {
  LOAD_ORG_CATEGORIES_DATA,
  RESET_ORG_CATEGORIES,
  UPDATE_ORG_CATEGORIES_DATA,
  UPDATE_ORG_CATEGORIES_FAILED
} from "./types";
import {
  createNewCategoryData,
  deleteCategoryData,
  loadOrgCategoriesData,
  sortCategoriesData,
  updateCategoryData
} from "../../utils/Api";
import {
  dispatchErrorWithCallback,
  failedResponseHandlerWithDispatchCallback,
  isResponseFailed
} from "../../helpers/store_helpers";

export function loadOrgCategories(data = {}) {
  return (dispatch) => {
    dispatch(updateOrgCategoriesData({ loaded: false, loading: true }))
    loadOrgCategoriesData(data).then((response) => {
      const { data } = response;
      if (isResponseFailed(response)) return dispatchErrorWithCallback(dispatch, { data, callback: loadOrgCategoriesFailure });

      dispatch(loadOrgCategoriesSuccess({ ...data, loaded: true, loading: false }));
    })
  }
}

export const createCategory = (name, settings, categoryOptions, callback = () => {}) => (dispatch) => {
  createNewCategoryData({ name, ...settings, category_options: categoryOptions }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadOrgCategoriesFailure }, callback)

    dispatchUpdatedCategories(dispatch, response, callback)
  })
}

export const updateCategory = (slug, name, settings, categoryOptions, callback = () => {}) => (dispatch) => {
  updateCategoryData(slug,{ name, ...settings, category_options: categoryOptions }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadOrgCategoriesFailure }, callback)

    dispatchUpdatedCategories(dispatch, response, callback)
  })
}

export const deleteCategory = (slug, callback = () => {}) => (dispatch, getState) => {
  deleteCategoryData(slug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadOrgCategoriesFailure }, callback)

    const categories = [...getState().org_categories.available_categories.filter(g => g.slug !== slug)]
    dispatch(loadOrgCategoriesSuccess({ available_categories: [...categories] }));
    callback(true)
  })
}

export const sortCategories = (data = {}, callback = () => {}) => (dispatch) => {
  sortCategoriesData(data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandlerWithDispatchCallback(dispatch, { ...response, callback: loadOrgCategoriesFailure }, callback)

    const { data } = response;
    callback(true);
    dispatch(loadOrgCategoriesSuccess({ ...data }));
  })
}

export const loadOrgCategoriesSuccess = (data) => ({
  type: LOAD_ORG_CATEGORIES_DATA,
  payload: {
    ...data
  }
});

export const updateOrgCategoriesData = (data) => ({
  type: UPDATE_ORG_CATEGORIES_DATA,
  payload: {
    ...data
  }
});

export const loadOrgCategoriesFailure = error => ({
  type: UPDATE_ORG_CATEGORIES_FAILED,
  payload: {
    error
  }
});

export const resetOrgCategoriesState = () => ({
  type: RESET_ORG_CATEGORIES
});

const dispatchUpdatedCategories = (dispatch, response, callback = () => {}) => {
  const { data } = response;
  const { available_categories } = data
  callback(true);
  dispatch(loadOrgCategoriesSuccess({ available_categories }));
}
