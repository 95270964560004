import React from 'react';
import { BtnDropdownToggleInlineShadowText } from "../../../../common/dropdowns";
import { capitalizeAllWords, isBlank } from "../../../../helpers/common";
import { BaseDropdownBtn } from "../../../../common/BaseHamburgerBtn";
import { getLastSurvey } from "../../../../models/d_sight/metric_chart_helpers";

export const TimePeriodDropdown = ({ selectedSurvey, setSelectedSurvey, surveys, header, children }) => {
  if (isBlank(selectedSurvey)) return null;

  const selectSurvey = (value) => {
    setSelectedSurvey(surveys.find(s => s.value === value) || getLastSurvey(surveys))
  }

  return <div className='d-block d-md-flex'>
    <h3 className="me-2">{ header }</h3>
    <div className="text-nowrap ms-auto">
      { children }
      <div className={`${surveys.length > 1 ? 'd-none' : 'd-block d-md-inline-block'} text-gray ps-1 mb-2 mb-md-0`}>
        <div className="ms-auto text-md-end">
          {selectedSurvey.full_name}
        </div>
      </div>
      <div className={`${surveys.length < 2 ? 'd-none' : 'd-block d-md-inline-block'} text-md-end mb-2 mb-md-0`}>
        <BtnDropdownToggleInlineShadowText id="time-period-dropdown"
                                           title={capitalizeAllWords(selectedSurvey.full_name)}
                                           onSelect={selectSurvey} bsPrefix="text-capitalize pe-0">
          {surveys.map(survey =>
            <BaseDropdownBtn key={`survey-${survey.value}`}
                             eventKey={survey.value}
                             bsPrefix="text-capitalize"
                             title={capitalizeAllWords(survey.full_name)}
                             active={selectedSurvey.full_name===survey.full_name} />
          )}
        </BtnDropdownToggleInlineShadowText>
      </div>
    </div>
  </div>
}
