import React, { useState } from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import { Typeahead } from 'react-bootstrap-typeahead';
import { loadContacts } from "../../../store/contacts/actions";
import { addSetInvites } from "../../../store/decision_set/actions";
import { isBlank, isEmail, uniqArray } from "../../../helpers/common";
import { isExistedUserContact, isGroupContact, isPendingContact } from "../../../helpers/decision_helpers";
import { isGuest } from "../../../helpers/home_helpers";
import { isEnter } from "../../../helpers/keys_helpers";
import { checkLoadingEffect } from "../../../helpers/callbacks_helpers";
import { DEFAULT_TYPEHEAD_ATTRIBUTES } from "../../../tree_view/modals/helpers/share_helpers";
import { renderContactItem, onChangeInvites } from "../../../tree_view/side_panel/helpers/collaborators_helpers";
import { renderTag } from "../../../tree_wizard/steps_wizard/steps/helpers/collaboration_helpers";

export const decisionSetInputKeyDown = ({
                                          ref, filteredContacts, filterNewInvites,
                                          invites, setInvites
                                       }) => (e) => {
  if (isEnter(e.keyCode)) {
    const value = ref.current.getInput().value.trim();
    if (isEmail(value)) {
      if (filteredContacts().some(i => i.email === value.toLowerCase())) { invites.push(value) }
      setTimeout(() => { setInvites(filterNewInvites(invites)) }, 50)
      ref.current.clear()
    }
  }
}

export const filteredContactsCallback = ({ contactsData, current_user, invites, anotherCondition = (_) => true, excludeCurrentUser = true }) => () =>
  contactsData.contacts.filter(contact => {
    if(isGroupContact(contact)) return true;
    if(isPendingContact(contact)) return false;
    if(isGuest(contact)) return false;
    if (excludeCurrentUser && contact.value === current_user.email) return false;
    if (invites.indexOf(contact.value) > -1) return false;

    return anotherCondition(contact)
  })

export const filterNewInvitesCallback = ({ contactsData, filterInvites = (_) => true } = {}) => (newInvites) =>
  uniqArray(
    newInvites.filter(email => isEmail(email))
      .filter(email => {
        const contact = contactsData.contacts.find(c => c.value === email)
        if (isBlank(contact) || contact.type !== 'User' || isPendingContact(contact)) return false;
        return isExistedUserContact(contact)
      })
      .filter(filterInvites)
  )

const InviteInput = ({
                       decision_set, current_user, contactsData,
                       loadContacts, addSetInvites,
                     }) => {
  checkLoadingEffect(contactsData, loadContacts)

  const ref = React.createRef();
  const [invites, setInvites] = useState([]);
  const [submit, setSubmit] = useState(false);

  const submitInvite = () => {
    setSubmit(true)
    addSetInvites(invites, (success) => {
      if (success) {
        setInvites([]);
      }
      setSubmit(false);
    })
  }

  const filteredContacts =  filteredContactsCallback({
    contactsData, current_user, invites, anotherCondition: (contact) => !decision_set.invites.some(invite => invite.email === contact.value)
  })

  const filterNewInvites = filterNewInvitesCallback({
    contactsData,
    filterInvites: email => !decision_set.invites.some(i => i.email === email)
  })

  const onKeyDown = decisionSetInputKeyDown({
    ref, filteredContacts, filterNewInvites,
    invites, setInvites
  })

  return <div className="d-flex flex-column flex-sm-row mt-2">
    <div className="me-2 w-100">
      <Typeahead
        {...DEFAULT_TYPEHEAD_ATTRIBUTES} labelKey="value" filterBy={['value','full_name']}
        id="invite-input" selected={invites} ref={ref}
        onChange={(selected) => onChangeInvites(selected, setInvites, filterNewInvites)}
        options={filteredContacts()}
        renderMenuItemChildren={renderContactItem} onKeyDown={onKeyDown}
        renderToken={(option, events, index) => renderTag(option, events, index, contactsData)}
      />
    </div>
    <Button onClick={submitInvite} className="mt-2 mt-sm-0" disabled={isBlank(invites) || submit}>Invite</Button>
  </div>
}
const mapStateToProps = ({ decision_set, contacts, current_user }) => ({
  decision_set, contactsData: contacts, current_user
});
const mapDispatchToProps = (dispatch) => ({
  loadContacts: () => { dispatch(loadContacts()) },
  addSetInvites: (invites, callback) => { dispatch(addSetInvites(invites, callback)) },
});
export default connect(mapStateToProps, mapDispatchToProps)(InviteInput);
